'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasConnect', [
    'BAS_API',
    'BAS_CONNECT',
    'BAS_ERRORS',
    'BasAppDevice',
    'BasLiveAccount',
    'BasLiveConnect',
    'BasCoreClientHelper',
    'BasError',
    BasConnect
  ])

/**
 * @callback CBasConnectCallback
 * @param {?BasError} error
 * @param {TBasConnect} result
 */

/**
 * @callback CBasConnectRetryCallback
 * @param {?BasError} error
 * @param {TBasConnectRetry} result
 */

/**
 * @callback CBasConnectStart
 * @returns {TBasConnect}
 */

/**
 * @callback CBasConnectAbort
 * @returns {TBasConnect}
 */

/**
 * @callback CBasConnectDestroy
 * @returns {TBasConnect}
 */

/**
 * @typedef {Object} TBasConnectRetryOptions
 * @property {number} [initialTimeout] in milliseconds
 * @property {number} [retries]
 * @property {number} [totalTimeout] in milliseconds
 * @property {number} [retryDelay] in milliseconds
 */

/**
 * @callback CBasConnectTakeBasServer
 * @returns {?BasServer}
 */

/**
 * @typedef {Object} TBasConnect
 * @property {CBasConnectStart} start
 * @property {CBasConnectAbort} abort
 * @property {CBasConnectDestroy} destroy
 * @property {CBasConnectTakeBasServer} takeBasServer
 * @property {BasConnectInfo} options
 * @property {boolean} started
 * @property {boolean} finished
 * @property {(string|number)} [macAddress]
 * @property {string} [cid]
 * @property {string} [address]
 * @property {BasServer} [basServer]
 * @property {TCoreCredentials} [credentials]
 * @property {BasError} [error]
 */

/**
 * @typedef {Object} TBasConnectRetry
 * @property {CBasConnectAbort} abort
 * @property {CBasConnectDestroy} destroy
 * @property {boolean} finished
 * @property {TBasConnect} [basConnect]
 * @property {BasError} [error]
 */

/**
 * @typedef {Object} TBasLiveIceCandidateStats
 * @property {number} offsetTime
 * @property {RTCIceCandidate} iceCandidate
 */

/**
 * @typedef {Object} TBasConnectStats
 * @property {number} [numberOfCandidates]
 * @property {number} [numberOfRetries]
 * @property {number} totalTime
 * @property {number} [connectRetryTotal]
 * @property {number} [connectTotal]
 * @property {number} [liveSubscriptionsReady]
 * @property {number} [liveIceServersReceived]
 * @property {number} [liveSubscriptionsAndIceServer]
 * @property {number} [liveWebRTCTime]
 * @property {number} [liveTotal]
 * @property {number} [liveAnswer]
 * @property {number} [liveLocalIceCandidatesCached]
 * @property {TBasLiveIceCandidateStats[]} [liveLocalIceCandidates]
 * @property {TBasLiveIceCandidateStats[]} [liveRemoteIceCandidates]
 */

/**
 * @constructor
 * @param BAS_API
 * @param {BAS_CONNECT} BAS_CONNECT
 * @param {BAS_ERRORS} BAS_ERRORS
 * @param {BasAppDevice} BasAppDevice
 * @param {BasLiveAccount} BasLiveAccount
 * @param {BasLiveConnect} BasLiveConnect
 * @param {BasCoreClientHelper} BasCoreClientHelper
 * @param BasError
 */
function BasConnect (
  BAS_API,
  BAS_CONNECT,
  BAS_ERRORS,
  BasAppDevice,
  BasLiveAccount,
  BasLiveConnect,
  BasCoreClientHelper,
  BasError
) {
  this.connect = connect
  this.connectRetry = connectRetry

  const basLiveAccountState = BasLiveAccount.get()

  /**
   * The connection needs to be started with the .start() method on TBasConnect!
   *
   * Possible BasError types
   * - BasLiveConnect.connect errors
   * - T_INVALID_INPUT
   * - T_INTERNAL
   * - T_API_VERSION
   * - T_AUTHENTICATION
   * - T_CONNECTION
   * - T_ABORT
   *
   * @param {BasConnectInfo} info
   * @param {CBasConnectCallback} [callback]
   * @returns {TBasConnect}
   */
  function connect (info, callback) {

    /**
     * @type {TBasConnect}
     */
    var _result
    var _aborted, _cbCalled
    var _macAddress, _address, _cid
    var _username, _password, _credentialHash
    var _basDeviceMac, _demoData
    var _basServer
    var _basLiveConnect
    var _tConnectionStart
    var _connectStats
    var _noLogin

    _aborted = false
    _cbCalled = false

    _result = {
      start: start,
      abort: abort,
      destroy: destroy,
      takeBasServer: takeBasServer,
      started: false,
      finished: false,
      options: info
    }

    _macAddress = 0
    _address = ''
    _cid = ''

    _username = ''
    _password = ''
    _credentialHash = ''

    _basDeviceMac = 0
    _demoData = null
    _noLogin = false

    _basServer = null

    /**
     * @type {TBasConnectStats}
     */
    _connectStats = {}
    _connectStats.totalTime = 0

    if (BasUtil.isObject(info) && BasUtil.isObject(info.server)) {

      if (BasUtil.isPNumber(info.server.macAddress) ||
        BasUtil.isNEString(info.server.macAddress)) {

        _macAddress = _result.macAddress = info.server.macAddress
      }

      if (BasUtil.isNEString(info.server.address)) {

        _address = _result.address = info.server.address
      }

      if (BasUtil.isNEString(info.server.cid)) {

        _cid = _result.cid = info.server.cid
      }

      if (BasUtil.isObject(info.credentials)) {

        if (BasUtil.isNEString(info.credentials.username)) {

          _username = info.credentials.username
        }

        if (BasUtil.isNEString(info.credentials.password)) {

          _password = info.credentials.password
        }

        if (BasUtil.isNEString(info.credentials.credentialHash)) {

          _credentialHash = info.credentials.credentialHash
        }
      }

      if (BasUtil.isObject(info.options)) {

        if (BasUtil.isPNumber(info.options.coreClientDeviceMac)) {

          _basDeviceMac = info.options.coreClientDeviceMac
        }

        if (BasUtil.isObject(info.options.demo)) {

          _demoData = info.options.demo
        }

        if (BasUtil.isBool(info.options.noLogin)) {

          _noLogin = info.options.noLogin
        }
      }

    } else {

      _cb(new BasError(
        BAS_ERRORS.T_INVALID_INPUT,
        info,
        BAS_ERRORS.M_INVALID_INPUT
      ))
    }

    return _result

    /**
     * @returns {TBasConnect}
     */
    function start () {

      if (_aborted || _cbCalled) return _result
      if (_result.started || _result.finished) return _result

      _tConnectionStart = Date.now()

      // Check input

      if (info.basServer) {

        _basServer = info.basServer
        _onBasServer()

      } else {

        switch (info.type) {
          case BAS_CONNECT.T_LOCAL:

            if (BasUtil.isValidHostname(_address)) {

              _basServer = new BAS_API.BasHttpServer(
                _address,
                {
                  macAddress: _macAddress,
                  cid: _cid,
                  name: info.derivedProjectName,
                  useSubscriptionSocket: BasAppDevice.isCoreClient()
                }
              )

              _onBasServer()

            } else {

              _cb(new BasError(
                BAS_ERRORS.T_INVALID_INPUT,
                info,
                'Invalid address for local server'
              ))
            }

            break
          case BAS_CONNECT.T_REMOTE:

            if (BasUtil.isNEString(_cid)) {

              /**
               * @type {TBasLiveConnect}
               */
              _basLiveConnect = BasLiveConnect.connect(
                _cid,
                _onBasLiveConnect,
                {
                  relayOnly: BasAppDevice.isWebRTCOnlyRelay()
                }
              )

            } else {

              _cb(new BasError(
                BAS_ERRORS.T_INVALID_INPUT,
                info,
                'Invalid CID remote server'
              ))
            }

            break
          case BAS_CONNECT.T_DEMO:

            if (_demoData) {

              _basServer = new BAS_API.BasDemoServer(_demoData)

              _onBasServer()

            } else {

              _cb(new BasError(
                BAS_ERRORS.T_INVALID_INPUT,
                info,
                'Invalid demo data for demo server'
              ))
            }

            break
          default:

            _cb(new BasError(
              BAS_ERRORS.T_INVALID_INPUT,
              info,
              BAS_ERRORS.M_INVALID_INPUT
            ))
        }
      }

      return _result
    }

    /**
     * @private
     * @param {?BasError} error
     * @param {TBasLiveConnect} result
     */
    function _onBasLiveConnect (error, result) {

      if (_aborted || _cbCalled) return _result
      if (_result.finished) return _result

      if (result === _basLiveConnect) {

        if (error) {

          _cleanup(true)

          _cb(error)

        } else {

          _basServer = _result.basServer = result.takeBasServer()

          if (_basServer) {

            _basServer.setDerivedName(info.derivedProjectName)
            _onBasServer()

          } else {

            // Should not occur

            _cleanup(true)

            _cb(new BasError(
              BAS_ERRORS.T_INTERNAL,
              result,
              'connect - onBasLiveConnect invalid result'
            ))
          }
        }
      }
    }

    function _onBasServer () {

      _basServer.getProjectInfo().then(_onProjectInfo, _onProjectInfoError)
    }

    function _onProjectInfo () {

      var master

      if (_aborted || _cbCalled) return

      master = _basServer.getMasterState()

      if (BasUtil.isBool(master)) {

        if (master) {

          _onValidBasServer()

        } else {

          _cleanup(true)

          _cb(new BasError(
            BAS_ERRORS.T_NO_LEADER,
            null,
            BAS_CONNECT.ERR_NO_LEADER
          ))
        }

      } else {

        // No Project info property "master"
        // => assume "leader"
        _onValidBasServer()
      }
    }

    function _onProjectInfoError () {

      if (_aborted || _cbCalled) return

      // Retrieving project info failed
      // Could be legacy server without support for project info
      _basServer.getUsers().then(_onUsersForCheck, _onUsersForCheckError)
    }

    function _onUsersForCheck () {

      if (_aborted || _cbCalled) return

      // - No project info
      // - Valid users
      // => legacy server, assume "leader"
      _onValidBasServer()
    }

    function _onUsersForCheckError (error) {

      if (_aborted || _cbCalled) return

      _cleanup(true)

      _cb(new BasError(
        BAS_ERRORS.T_CONNECTION,
        error,
        BAS_CONNECT.ERR_USERS
      ))
    }

    function _onValidBasServer () {

      if (_basServer.apiVersionKnown) {

        // - API version is known
        // - Server should be "leader"
        // BasServer should be reachable and valid
        _result.basServer = _basServer
      }

      if (_basDeviceMac) {

        if (
          _basServer.apiVersionKnown &&
          _basServer.coreClientDeviceInfo[_basDeviceMac] === _username
        ) {

          _basServerLogin(_username, _password)

        } else {

          _basServer.getCoreClientDeviceInfo(_basDeviceMac)
            .then(_checkApiVersion, _onCoreClientDeviceInfoError)
            .then(_onCoreClientDeviceInfo, _empty)
        }

      } else {
        // Users can be fetched either way, but we only need to wait for it's
        //  result when the server does not support JWT login or we don't have
        //  a JWT
        const getUsersPromise = _basServer.getUsers()
        const onSupportsResult = () => {
          if (_aborted) return
          // If we don't know for sure that the project is NOT linked to our
          //  user (e.g. when internet or AWS is down, and we can't request our
          //  linked projects), try logging in with JWT anyway.
          // Only if we know for sure that the project is NOT linked to our
          //  account, we should try logging in with a local profile.
          const isServerPossiblyLinkedToAccount = (
            !basLiveAccountState.projectsChecked ||
            Object.keys(basLiveAccountState.projects).includes(_basServer.cid)
          )

          const { token, isOverride } = BasLiveAccount.getJWT()

          // Log in using the override JWT, even if server says that it doesn't
          //  support JWT (this actually only means it doesn't have any cloud
          //  profiles)
          const useOverrideJWT = token && isOverride

          // Log in using the cloud JWT
          const useCloudJWT =
            token &&
            _basServer.supportsJwtLogin &&
            isServerPossiblyLinkedToAccount

          if (useCloudJWT || useOverrideJWT) {
            _basServer.setJWT(token)
            _result.credentials = { jwt: token }
            _cb(null)
          } else {
            // Legacy: wait on result of users requests
            getUsersPromise
              .then(_checkApiVersion, _onUsersError)
              .then(_onUsers, _empty)
          }
        }
        _basServer.getSupports().then(onSupportsResult, onSupportsResult)
      }

      if (!_cid) {

        _basServer.getProjectInfo().catch(_empty)
      }
    }

    /**
     * @private
     * @param {TBasServerResponse} result
     * @returns {(TBasServerResponse|Promise|undefined)}
     */
    function _checkApiVersion (result) {

      var _unsupportedApi

      // Return Rejected Promise for subsequent Promise handler
      if (_aborted || _cbCalled) {
        return Promise.reject(new Error('BasConnect aborted'))
      }

      // Request succeeded, set BasServer reference on result
      _result.basServer = _basServer

      _unsupportedApi = _basServer.unsupportedApi

      if (_unsupportedApi === 0) return result

      _cleanup()

      _cb(new BasError(
        BAS_ERRORS.T_API_VERSION,
        _unsupportedApi,
        BAS_CONNECT.ERR_INCOMPATIBLE_API
      ))

      // Return Rejected Promise for subsequent Promise handler
      return Promise.reject(new Error('API incompatible'))
    }

    /**
     * @private
     * @param {TBasServerDeviceInfoResponse} result
     */
    function _onCoreClientDeviceInfo (result) {

      var _coreClientInfo

      if (_aborted || _cbCalled) return

      _coreClientInfo = BasCoreClientHelper.processCoreClientInfo(result.data)

      if (_coreClientInfo) {

        _basServerLogin(
          _coreClientInfo.username,
          _coreClientInfo.password
        )

      } else {

        _basServer.getUsers().then(_onUsers, _onUsersError)
      }
    }

    /**
     * @private
     * @param {(BasError|*)} _error
     * @returns {Promise}
     */
    function _onCoreClientDeviceInfoError (_error) {

      // Return Rejected Promise for subsequent Promise handler
      if (_aborted || _cbCalled) {
        return Promise.reject(new Error('BasConnect aborted'))
      }

      // No core client device info found, continue with regular login flow

      _basServer.getUsers()
        .then(_checkApiVersion, _onUsersError)
        .then(_onUsers, _empty)

      // Return Rejected Promise for subsequent Promise handler
      return Promise.reject(_error)
    }

    /**
     * @private
     * @param {TBasServerUsersResponse} result
     */
    function _onUsers (result) {

      var _users, length, i, user

      if (_aborted || _cbCalled) return

      /**
       * @type {?(BasProfile[])}
       */
      _users = result.data

      if (Array.isArray(_users)) {

        length = _users.length

        if (length) {

          /**
           * @type {BasProfile}
           */
          user = _users[0]

          if (length === 1 && !user.hasPassword) {

            _basServerLogin(user.username)

          } else {

            if (_username) {

              for (i = 0; i < length; i++) {

                /**
                 * @type {BasProfile}
                 */
                user = _users[i]

                if (user.username === _username) {

                  if (_credentialHash) {

                    _basServerLogin(
                      _username,
                      '',
                      _credentialHash
                    )

                    return

                  } else {

                    if (user.hasPassword) {

                      if (_password) {

                        _basServerLogin(
                          _username,
                          _password
                        )

                        return

                      } else {

                        _cleanup()

                        _cb(new BasError(
                          BAS_ERRORS.T_AUTHENTICATION,
                          _users,
                          BAS_CONNECT.ERR_INVALID_CREDENTIALS
                        ))

                        return
                      }

                    } else {

                      _basServerLogin(_username)

                      return
                    }
                  }
                }
              }
            }

            _cleanup()

            _cb(null)
          }

        } else {

          _cleanup()

          _cb(new BasError(
            BAS_ERRORS.T_CONNECTION,
            _users,
            BAS_CONNECT.ERR_USERS
          ))
        }

      } else {

        _cleanup()

        _cb(new BasError(
          BAS_ERRORS.T_CONNECTION,
          result,
          BAS_CONNECT.ERR_USERS
        ))
      }
    }

    /**
     * @private
     * @param {*} error
     * @returns {Promise}
     */
    function _onUsersError (error) {

      // Return Rejected Promise for subsequent Promise handler
      if (_aborted || _cbCalled) {
        return Promise.reject(new Error('BasConnect aborted'))
      }

      _cleanup(true)

      const e = new BasError(
        BAS_ERRORS.T_CONNECTION,
        error,
        BAS_CONNECT.ERR_USERS
      )
      _cb(e)

      // Return Rejected Promise for subsequent Promise handler
      return Promise.reject(e)
    }

    /**
     * @private
     * @param {string} username Username
     * @param {string} [password] Password
     * @param {string} [credentialsHash] Credential hash
     */
    function _basServerLogin (
      username,
      password,
      credentialsHash
    ) {

      if (_noLogin) {

        _cleanup()
        _cb(null)

      } else {

        _basServer.login(
          username,
          password,
          credentialsHash
        ).then(_onLogin, _onLoginError)
      }
    }

    /**
     * @private
     * @param {Array<TBasServerCredentialsResponse>} result
     */
    function _onLogin (result) {

      var _credentials

      if (_aborted || _cbCalled) return

      _credentials = result[0].data

      _cleanup()

      if (_credentials) {

        _result.credentials = _credentials

        _connectStats.connectTotal = Date.now() - _tConnectionStart

        if (_result.basServer) {

          if (_result.basServer.basConnectStats) {

            _result.basServer.basConnectStats.connectTotal =
              _connectStats.connectTotal

          } else {

            _result.basServer.basConnectStats = _connectStats
          }

        } else {

          // Should not occur
        }

        _cb(null)

      } else {

        _cb(new BasError(
          BAS_ERRORS.T_AUTHENTICATION,
          result,
          BAS_CONNECT.ERR_LOGIN
        ))
      }
    }

    /**
     * @private
     * @param {*} error
     */
    function _onLoginError (error) {

      if (_aborted || _cbCalled) return

      _cleanup()

      if (error === BAS_API.CONSTANTS.ERR_CREDENTIALS) {

        _cb(new BasError(
          BAS_ERRORS.T_AUTHENTICATION,
          error,
          BAS_CONNECT.ERR_INVALID_CREDENTIALS
        ))

      } else {

        _cb(new BasError(
          BAS_ERRORS.T_AUTHENTICATION,
          error,
          BAS_CONNECT.ERR_LOGIN
        ))
      }
    }

    /**
     * @private
     * @param {boolean} [cleanupBasServer = false]
     */
    function _cleanup (cleanupBasServer) {

      var _basLiveConnectToClean

      if (_basLiveConnect) {

        // Capture instance we want cleanup
        _basLiveConnectToClean = _basLiveConnect
        // Remove "global" reference for callback check
        _basLiveConnect = null

        _basLiveConnectToClean.abort()
        _basLiveConnectToClean = null
      }

      if (cleanupBasServer) {

        if (_basServer) _basServer.destroy()
        _basServer = null

        _result.basServer = null
      }
    }

    /**
     * @returns {?BasServer}
     */
    function takeBasServer () {

      var basServer

      basServer = _result.basServer
      _result.basServer = null

      return basServer
    }

    /**
     * @returns {TBasConnect}
     */
    function destroy () {

      return abort(true)
    }

    /**
     * @private
     * @param {boolean} [cleanupBasServer = false]
     * @returns {TBasConnect}
     */
    function abort (cleanupBasServer) {

      _aborted = true

      _cleanup(cleanupBasServer)

      _cb(new BasError(
        BAS_ERRORS.T_ABORT,
        undefined,
        BAS_ERRORS.M_ABORTED
      ))

      return _result
    }

    /**
     * @private
     * @param {?BasError} error
     */
    function _cb (error) {

      if (!_cbCalled) {

        _cbCalled = true

        // Make sure the state is started as well
        _result.started = true
        _result.finished = true

        // Remove local reference to BasServer instance
        _basServer = null

        if (error) _result.error = error

        if (BasUtil.isFunction(callback)) {

          callback(error, _result)
        }
      }
    }
  }

  /**
   * Possible BasError types
   * - BasConnect.connect errors
   * - T_INVALID_INPUT
   * - T_ABORT
   *
   * @param {BasConnectInfo} options
   * @param {TBasConnectRetryOptions} retryOptions
   * @param {CBasConnectRetryCallback} [callback]
   * @returns {TBasConnectRetry}
   */
  function connectRetry (
    options,
    retryOptions,
    callback
  ) {
    var _result, _aborted, _cbCalled
    var _initialTimeout, _retries, _retryDelay, _totalTimeout
    var _initialTimeoutId, _currentRetries, _delayTimeoutId, _totalTimeoutId
    var _tConnectStart
    var _connectStats

    _aborted = false
    _cbCalled = false

    _result = {
      abort: abort,
      destroy: destroy,
      finished: false
    }

    _initialTimeout = 0
    _retries = 0
    _retryDelay = 0
    _totalTimeout = 0

    _delayTimeoutId = 0
    _totalTimeoutId = 0

    _currentRetries = 0

    _tConnectStart = Date.now()

    /**
     * @type {TBasConnectStats}
     */
    _connectStats = {}
    _connectStats.totalTime = 0

    if (BasUtil.isObject(options) && BasUtil.isObject(retryOptions)) {

      if (BasUtil.isPNumber(retryOptions.initialTimeout)) {

        _initialTimeout = retryOptions.initialTimeout
      }

      if (BasUtil.isPNumber(retryOptions.retries)) {

        _retries = retryOptions.retries
      }

      if (BasUtil.isPNumber(retryOptions.retryDelay)) {

        _retryDelay = retryOptions.retryDelay
      }

      if (BasUtil.isPNumber(retryOptions.totalTimeout)) {

        _totalTimeout = retryOptions.totalTimeout
      }

      if (_totalTimeout) {

        _totalTimeoutId = setTimeout(
          _onTotalTimeout,
          _totalTimeout
        )
      }

      _result.basConnect = connect(options, _onConnect)

      if (_initialTimeout) {

        _initialTimeoutId = setTimeout(
          _onInitialTimeout,
          _initialTimeout
        )

      } else {

        _result.basConnect.start()
      }

    } else {

      _cb(new BasError(
        BAS_ERRORS.T_INVALID_INPUT,
        {
          options: options,
          retryOptions: retryOptions
        },
        BAS_ERRORS.M_INVALID_INPUT
      ))
    }

    return _result

    function _onInitialTimeout () {

      if (_aborted || _cbCalled) return

      _result.basConnect.start()
    }

    /**
     * @private
     * @param {?BasError} error
     * @param {TBasConnect} result
     */
    function _onConnect (error, result) {

      if (_aborted || _cbCalled) return

      // Check if event is still relevant
      if (_result.basConnect !== result) return

      if (error) {

        if (error.basType === BAS_ERRORS.T_INVALID_INPUT) {

          // End reached

          _cleanup()
          _cb(error)

        } else if (error.basType === BAS_ERRORS.T_AUTHENTICATION) {

          // End reached

          // Failed to login, but server should be available

          _cleanup()
          _cb(error)

        } else if (error.basType === BAS_ERRORS.T_LIVE_AUTHENTICATION) {

          // End reached

          // Basalte Live not logged in but necessary

          _cleanup()
          _cb(error)

        } else if (error.basType === BAS_ERRORS.T_CONNECTION) {

          _attemptRetry()

        } else if (error.basType === BAS_ERRORS.T_LIVE_CONNECTION) {

          _attemptRetry()

        } else if (error.basType === BAS_ERRORS.T_LIVE_NOT_AUTHORIZED) {

          // End reached

          // Not authorized to connect with server

          _cleanup()
          _cb(error)

        } else if (error.basType === BAS_ERRORS.T_LIVE_NO_INTEGRATOR_ACCESS) {

          // End reached

          // Integrator access is not enabled on the server
          // you want to connect to with a Pro Live account

          _cleanup()
          _cb(error)

        } else if (
          error.basType === BAS_ERRORS.T_LIVE_NO_ACTIVE_SERVERS_FOUND
        ) {

          // End reached

          // No active server

          _cleanup()
          _cb(error)

        } else if (error.basType === BAS_ERRORS.T_LIVE_WAF) {

          // End reached

          // Web Application Firewall exception

          _cleanup()
          _cb(error)

        } else if (error.basType === BAS_ERRORS.T_API_VERSION) {

          // End reached

          // API mismatch

          _cleanup()
          _cb(error)

        } else if (error.basType === BAS_ERRORS.T_INTERNAL) {

          _attemptRetry()

        } else if (error.basType === BAS_ERRORS.T_TIMEOUT) {

          _attemptRetry()

        } else if (error.basType === BAS_ERRORS.T_USER_DECLINE) {

          // End reached

          // Should not occur here

          _cleanup()
          _cb(error)

        } else if (error.basType === BAS_ERRORS.T_NOT_SUPPORTED) {

          // End reached

          // Should not occur here

          _cleanup()
          _cb(error)

        } else if (error.basType === BAS_ERRORS.T_NO_CORE) {

          // End reached

          // Should not occur here

          _cleanup()
          _cb(error)

        } else if (error.basType === BAS_ERRORS.T_ABORT) {

          // End reached

          // Should not occur here because of "_aborted" check

          _cleanup()
          _cb(error)

        } else if (error.basType === BAS_ERRORS.T_NO_LEADER) {

          // End reached

          // Server is slave

          _cleanup()
          _cb(error)

        } else {

          _attemptRetry()
        }

      } else {

        if (result) {

          _cleanup()

          _connectStats.connectRetryTotal = Date.now() - _tConnectStart
          _connectStats.numberOfRetries = _currentRetries

          if (_result.basConnect && _result.basConnect.basServer) {

            if (_result.basConnect.basServer.basConnectStats) {

              _result.basConnect.basServer.basConnectStats
                .numberOfRetries =
                _connectStats.numberOfRetries
              _result.basConnect.basServer.basConnectStats
                .connectRetryTotal =
                _connectStats.connectRetryTotal

            } else {

              _result.basConnect.basServer.basConnectStats =
                _connectStats
            }
          }

          _cb(null)

        } else {

          // Should not occur

          _attemptRetry()
        }
      }
    }

    function _attemptRetry () {

      _cleanup(true)

      if (_currentRetries < _retries) {

        _currentRetries++

        if (_retryDelay) {

          _clearDelayTimeout()

          _delayTimeoutId = setTimeout(
            _onDelayTimeout,
            _retryDelay
          )

        } else {

          _result.basConnect = connect(options, _onConnect)
          _result.basConnect.start()
        }

      } else {

        _cb(new BasError(
          BAS_ERRORS.T_TIMEOUT,
          undefined,
          'Maximum number of retries reached'
        ))
      }
    }

    function _onDelayTimeout () {

      if (_aborted || _cbCalled) return

      _result.basConnect = connect(options, _onConnect)
      _result.basConnect.start()
    }

    function _onTotalTimeout () {

      if (_aborted || _cbCalled) return

      _cleanup(true)

      _cb(new BasError(
        BAS_ERRORS.T_TIMEOUT,
        undefined,
        'Total timeout expired'
      ))
    }

    function _clearInitialTimeout () {

      clearTimeout(_initialTimeoutId)
      _initialTimeoutId = 0
    }

    function _clearDelayTimeout () {

      clearTimeout(_delayTimeoutId)
      _delayTimeoutId = 0
    }

    function _clearTotalTimeout () {

      clearTimeout(_totalTimeoutId)
      _totalTimeoutId = 0
    }

    /**
     * @private
     * @param {boolean} [cleanupBasConnect = false]
     */
    function _cleanup (cleanupBasConnect) {

      var _basConnectToDestroy

      _clearInitialTimeout()
      _clearDelayTimeout()
      _clearTotalTimeout()

      if (cleanupBasConnect) {

        if (_result.basConnect) {

          // Capture instance we want to destroy
          _basConnectToDestroy = _result.basConnect
          // Remove "global" reference for callback check
          _result.basConnect = null

          _basConnectToDestroy.destroy()
          _basConnectToDestroy = null
        }

        _result.basConnect = null
      }
    }

    function destroy () {

      abort(true)
    }

    /**
     * @private
     * @param {boolean} [cleanupBasConnect = false]
     */
    function abort (cleanupBasConnect) {

      _aborted = true

      _cleanup(cleanupBasConnect)

      _cb(new BasError(
        BAS_ERRORS.T_ABORT,
        undefined,
        BAS_ERRORS.M_ABORTED
      ))
    }

    /**
     * @private
     * @param {?BasError} error
     */
    function _cb (error) {

      if (!_cbCalled) {

        _cbCalled = true

        _result.finished = true

        if (error) _result.error = error

        if (BasUtil.isFunction(callback)) {

          callback(error, _result)
        }
      }
    }
  }

  function _empty () {

    // Empty
  }
}
