'use strict'

angular
  .module('basalteApp')
  .constant('BAS_ENERGY', {
    EVT_UI_UPDATE: 'evtBasEnergyUiUpdate',
    EVT_DATA_UPDATE: 'evtBasEnergyDataUpdate',
    EVT_STATE_UPDATED: 'evtBasEnergyMeterStateUpdated',
    T_ID_DETAIL: 'detail',
    T_ID_ENERGY: 'energy',
    T_ID_GAS: 'gas',
    T_ID_WATER: 'water',
    T_ID_HIST_TODAY: 'today',
    T_ID_HIST_LAST_WEEK: 'last_week',
    T_ID_HIST_MONTH: 'month',
    T_ID_HIST_YEAR: 'year',
    U_WH: 'Wh',
    U_KWH: 'kWh',
    U_L: 'l',
    U_M3: 'm³'
  })
