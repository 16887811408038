'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('mainModalCtrl', [
    '$rootScope',
    '$scope',
    '$uiRouterGlobals',
    'ModalService',
    'BAS_STATE',
    'STATES',
    'BAS_API',
    'BAS_CURRENT_CORE',
    'BAS_SOURCE',
    'BAS_MODAL',
    'BAS_ROOMS',
    'BAS_SPLASH',
    'BasCoreConnection',
    'CurrentBasCore',
    'CurrentRoom',
    'BasCurrentAppProfile',
    'BasStateHelper',
    'BasIntercomHelper',
    'BasVolume',
    'BasModal',
    'BasCleanup',
    'BasAppDevice',
    'BasCameras',
    'BasOpenCloseDevices',
    'BasLiveAccount',
    'UiHelper',
    'modalHelperService',
    'canChangeUserFull',
    'close',
    'event',
    mainModalCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param $uiRouterGlobals
 * @param ModalService
 * @param {BAS_STATE} BAS_STATE
 * @param {STATES} STATES
 * @param BAS_API
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_SPLASH} BAS_SPLASH
 * @param {BasCoreConnection} BasCoreConnection
 * @param {CurrentBasCore} CurrentBasCore
 * @param {CurrentRoom} CurrentRoom
 * @param {BasCurrentAppProfile} BasCurrentAppProfile
 * @param {BasStateHelper} BasStateHelper
 * @param {BasIntercomHelper} BasIntercomHelper
 * @param {BasVolume} BasVolume
 * @param {BasModal} BasModal
 * @param {BasCleanup} BasCleanup
 * @param {BasAppDevice} BasAppDevice
 * @param {BasCameras} BasCameras
 * @param {BasOpenCloseDevices} BasOpenCloseDevices
 * @param {BasLiveAccount} BasLiveAccount
 * @param {UiHelper} UiHelper
 * @param {modalHelperService} modalHelperService
 * @param {boolean} canChangeUserFull
 * @param close
 * @param event
 */
function mainModalCtrl (
  $rootScope,
  $scope,
  $uiRouterGlobals,
  ModalService,
  BAS_STATE,
  STATES,
  BAS_API,
  BAS_CURRENT_CORE,
  BAS_SOURCE,
  BAS_MODAL,
  BAS_ROOMS,
  BAS_SPLASH,
  BasCoreConnection,
  CurrentBasCore,
  CurrentRoom,
  BasCurrentAppProfile,
  BasStateHelper,
  BasIntercomHelper,
  BasVolume,
  BasModal,
  BasCleanup,
  BasAppDevice,
  BasCameras,
  BasOpenCloseDevices,
  BasLiveAccount,
  UiHelper,
  modalHelperService,
  canChangeUserFull,
  close,
  event
) {
  var modal = this

  var basButtonClass = 'modal-target'

  var CSS_PASSWORD_INPUT = 'login-credentials-password-input'
  var CSS_MODAL_MENU_ITEM_BIG_2 = 'modal-menu-item-big-2'

  var _listeners = []

  var _isCoreClient = BasAppDevice.isCoreClient()

  /**
   * @type {TOpenCloseDevicesState}
   */
  var basOpenCloseDevices = BasOpenCloseDevices.get()

  // Modal properties
  modal.can = {
    changeHome: !_isCoreClient,
    currentUser: false,
    otherUser: false,
    liveUser: false,
    changeUserFull: canChangeUserFull,
    alarms: false,
    openCloseDevices: false,
    cameras: false,
    settings: false,
    tvOffSingle: false,
    musicOffSingle: false,
    musicOffAll: false,
    lightsOffAll: false,
    doNotDisturb: false,
    rooms: false,
    music: false,
    thermostats: false,
    intercom: false
  }

  /**
   * @type {BAS_MODAL}
   */
  modal.BAS_MODAL = BAS_MODAL

  /**
   * @type {TCurrentBasCoreState}
   */
  modal.currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {TBasVolumeState}
   */
  modal.basVolumeState = BasVolume.get()

  /**
   * @type {TBasLiveAccountState}
   */
  modal.basLiveAccountState = BasLiveAccount.get()

  modal.selectedProfileIndex = -1

  modal.password = ''

  modal.currentUserText = ''

  modal.doNotDisturbText = 'do_not_disturb'

  /**
   * @type {BasCurrentAppProfileState}
   */
  modal.currentUser = BasCurrentAppProfile.get()

  // Set modal style
  modal.style = modalHelperService.getModalStyle()

  // Actions
  modal.close = close
  modal.changeUser = changeUser
  modal.submit = submit
  modal.selectCurrentProfile = selectCurrentProfile
  modal.selectLiveUser = selectLiveUser

  init()

  function init () {

    $scope.$on('$destroy', _onDestroy)

    _syncModalCan()

    _listeners.push($rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_SYSTEM,
      _onSystemProperties
    ))
    _listeners.push($rootScope.$on(
      BAS_ROOMS.EVT_ROOMS_UPDATED,
      _onRoomsUpdated
    ))
    _listeners.push($rootScope.$on(
      BAS_SPLASH.EVT_SPLASH_VISIBILITY_CHANGED,
      _onSplashVisibility
    ))

    _syncMuted()

    modalHelperService.calcPosition(
      event,
      basButtonClass,
      modalHelperService.type.verticalRight
    )
  }

  function selectCurrentProfile () {

    modal.close(
      _isCoreClient
        ? BAS_MODAL.C_SETTINGS_ABOUT
        : BAS_MODAL.C_CHANGE_USER
    )
  }

  function selectLiveUser () {
    const basCoreContainer = CurrentBasCore.has()
      ? modal.currentBasCoreState.core
      : null

    if (basCoreContainer) {

      BasCoreConnection.setDisconnectExpected(true)

      const { token } = BasLiveAccount.getJWT()

      basCoreContainer.changeProfile({
        jwt: token
      }).then(_onConnect, _onConnectError)
    }
  }

  /**
   * @param {number} index
   * @param _event
   */
  function changeUser (index, _event) {

    var _basServer, _users, _user

    if (index === modal.selectedProfileIndex) {

      modal.selectedProfileIndex = -1

      setPasswordFieldFocussed(_event, false)

    } else {

      modal.selectedProfileIndex = index

      // Clear password
      modal.password = ''

      _basServer = _getBasServer()

      if (_basServer) {

        _users = _getUsers()

        _user = _users[modal.selectedProfileIndex]

        if (_user) {

          if (_user.hasPassword) {

            setPasswordFieldFocussed(_event)

          } else {

            _user.isCloudAccount ? selectLiveUser() : _login(_user.username)
          }
        }
      }
    }
  }

  /**
   * @param {number} _index
   */
  function submit (_index) {

    var _basServer, _users, _user

    _basServer = _getBasServer()

    if (_basServer) {

      _users = _getUsers()

      _user = _users[modal.selectedProfileIndex]

      if (_user) {

        _login(_user.username, modal.password)

      } else {

        BasModal.show(BAS_MODAL.T_UNABLE_TO_CONNECT)
      }
    }
  }

  /**
   * @private
   * @param {string} username
   * @param {string} [password]
   */
  function _login (username, password) {

    var _basCoreContainer

    /**
     * @type {?BasCoreContainer}
     */
    _basCoreContainer = CurrentBasCore.has()
      ? modal.currentBasCoreState.core
      : null

    if (_basCoreContainer && _basCoreContainer.basServer) {

      _basCoreContainer.basServer.login(username, password)
        .then(_onLogin, _onLoginError)
    }
  }

  /**
   * @private
   * @param {Array<TBasServerCredentialsResponse>} result
   */
  function _onLogin (result) {

    var _basCoreContainer

    /**
     * @type {?BasCoreContainer}
     */
    _basCoreContainer = CurrentBasCore.has()
      ? modal.currentBasCoreState.core
      : null

    if (_basCoreContainer) {

      BasCoreConnection.setDisconnectExpected(true)

      _basCoreContainer.changeProfile(result[0].data)
        .then(_onConnect, _onConnectError)
    }
  }

  function _onConnect () {

    BasCoreConnection.setDisconnectExpected(false)

    BasCleanup.onProfileChange()

    ModalService.closeModals()
  }

  function _onConnectError () {

    BasCoreConnection.setDisconnectExpected(false)

    BasModal.show(BAS_MODAL.T_UNABLE_TO_CONNECT)
      .then(_onUnableToConnectModal)
  }

  function _onUnableToConnectModal (unableToConnectModal) {

    unableToConnectModal.close.then(_onUnableToConnectModalClose)
  }

  function _onUnableToConnectModalClose () {

    ModalService.closeModals()

    BasCoreConnection.prepareChangeBasCore()

    CurrentRoom.go(STATES.CONNECT_DISCOVERY)
  }

  /**
   * @private
   * @param {*} error
   */
  function _onLoginError (error) {

    // Clear password
    modal.password = ''

    if (error === BAS_API.CONSTANTS.ERR_CREDENTIALS) {

      // Show modal wrong password
      BasModal.show(BAS_MODAL.T_WRONG_PASSWORD)
    }
  }

  /**
   * @private
   * @param _event
   * @param {boolean} [focussed = true]
   */
  function setPasswordFieldFocussed (_event, focussed) {

    var targetElement, elements, _focussed

    _focussed = focussed !== false

    targetElement = UiHelper.getTargetParentWithClassName(
      _event,
      CSS_MODAL_MENU_ITEM_BIG_2
    )

    if (targetElement) {

      elements = targetElement.getElementsByClassName(CSS_PASSWORD_INPUT)

      if (elements[0]) {

        if (_focussed) {

          elements[0].focus()

        } else {

          elements[0].blur()
        }
      }
    }
  }

  function _onSystemProperties () {

    _syncModalCanAlarms()
    modal.can.openCloseDevices = basOpenCloseDevices.hasOpenCloseDevices
    modal.can.cameras = BasCameras.shouldShowCameras()

    $scope.$applyAsync()
  }

  function _onRoomsUpdated () {

    _syncModalCanMusicRooms()
    _syncModalCanMusicOff()
    _syncModalCanTvOff()

    $scope.$applyAsync()
  }

  function _onSplashVisibility () {

    close()
  }

  function _syncModalCan () {

    // Set modal properties
    modal.can.settings = true

    _syncModalCanUsers()
    _syncModalCanAlarms()
    _syncModalCanCameras()
    _syncModalCanOpenCloseDevices()
    _syncModalCanMusicRooms()
    _syncModalCanTvOff()
    _syncModalCanMusicOff()
    _syncModalCanLightsOfAll()
    _syncModalCanDoNotDisturb()
    _syncModalCanProperties()
    $scope.$applyAsync()
  }

  function _syncModalCanUsers () {

    const currentServer = modal.currentBasCoreState.core?.core?.server
    const loggedInUsingJWT = currentServer?.connectedUsingCloudAccount
    const serverSupportsJWT = !!currentServer?.supportsJwtLogin
    const canSwitchUsers = serverSupportsJWT
      ? !loggedInUsingJWT && modal.currentBasCoreState.core?.core?.profile?.uuid
      : true

    modal.can.currentUser = canSwitchUsers
    modal.can.otherUser = canSwitchUsers

    const { token } = BasLiveAccount.getJWT()
    const hasLiveUser = currentServer?.users.some(user => user.jwt === token)

    modal.can.liveUser = (
      serverSupportsJWT &&
      !loggedInUsingJWT &&
      hasLiveUser
    )

    if (modal.can.changeUserFull) {

      modal.currentUserText = 'log_out'

    } else {

      modal.currentUserText = 'change_user'
    }
  }

  function _syncModalCanAlarms () {

    modal.can.alarms = CurrentBasCore.has()
      ? modal.currentBasCoreState.core.hasAlarmsAccess()
      : false
  }

  function _syncModalCanCameras () {

    modal.can.cameras = BasCameras.shouldShowCameras()
  }

  function _syncModalCanOpenCloseDevices () {

    modal.can.openCloseDevices = (
      basOpenCloseDevices.hasOpenCloseDevices &&
      !BasStateHelper.hasBaseState($uiRouterGlobals.current.name, STATES.DOORS)
    )
  }

  function _syncModalCanMusicRooms () {

    var audioOnly, singleAudioRoomId

    audioOnly = CurrentBasCore.isAudioOnly()
    singleAudioRoomId = CurrentBasCore.hasCore()
      ? modal.currentBasCoreState.core.core.singleAudioRoomId
      : ''

    modal.can.music = (
      BAS_ROOMS.ROOMS.info.hasMusicRooms &&
      !(audioOnly && singleAudioRoomId) &&
      (
        ($uiRouterGlobals.current.name !== STATES.ROOMS) ||
        ($uiRouterGlobals.params.view !== BAS_STATE.S_ROOMS_VIEW_MUSIC)
      )
    )
  }

  function _syncModalCanLightsOfAll () {

    modal.can.lightsOffAll = $uiRouterGlobals.current.name === STATES.LIGHTS
  }

  function _syncModalCanTvOff () {

    var currentState, roomVideo

    currentState = $uiRouterGlobals.current.name
    roomVideo = CurrentRoom.getRoomVideo()

    modal.can.tvOffSingle = (
      BasStateHelper.hasBaseState(currentState, STATES.VIDEO) &&
      roomVideo.on
    )
  }

  function _syncModalCanMusicOff () {

    var currentState, roomMusic, basSource

    currentState = $uiRouterGlobals.current.name
    roomMusic = CurrentRoom.getRoomMusic()
    basSource = CurrentRoom.getBasSource()

    if (
      (
        (currentState === STATES.ROOM) ||
        BasStateHelper.hasBaseState(currentState, STATES.MUSIC)
      ) &&
      roomMusic
    ) {

      modal.can.musicOffAll = true
      modal.can.musicOffSingle = basSource
        ? !(
            basSource.type === BAS_SOURCE.T_EMPTY ||
            basSource.type === BAS_SOURCE.T_UNKNOWN_SOURCE
          )
        : false

    } else {

      modal.can.musicOffAll = BAS_ROOMS.ROOMS.info.hasMusicRooms
      modal.can.musicOffSingle = false
    }

    if (
      modal.can.musicOffAll &&
      CurrentBasCore.hasCore() &&
      modal.currentBasCoreState.core.core.singleAudioRoomId
    ) {

      modal.can.musicOffAll = false
    }
  }

  function _syncModalCanDoNotDisturb () {

    modal.can.doNotDisturb = _isCoreClient
  }

  function _syncMuted () {

    modal.doNotDisturbText =
      modal.basVolumeState.uiMuted
        ? 'do_not_disturb'
        : 'do_not_disturb'
  }

  function _syncModalCanProperties () {
    if (BasUtil.isObject(BAS_ROOMS.ROOMS.info)) {

      modal.can.rooms = BAS_ROOMS.ROOMS.info.hasHomeRooms
      modal.can.music = BAS_ROOMS.ROOMS.info.hasMusicRooms
      modal.can.thermostats = BAS_ROOMS.ROOMS.info.hasThermostatRooms
      modal.can.intercom = BasIntercomHelper.shouldShowIntercom()

    } else {

      modal.can.rooms = false
      modal.can.music = true
      modal.can.thermostats = false
      modal.can.intercom = false
    }
  }

  /**
   * @private
   * @returns {BasProfile[]}
   */
  function _getUsers () {

    var _basServer

    _basServer = _getBasServer()

    return _basServer
      ? _basServer.users
      : []
  }

  /**
   * @private
   * @returns {?BasServer}
   */
  function _getBasServer () {

    return CurrentBasCore.has()
      ? modal.currentBasCoreState.core.basServer
      : null
  }

  function _onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
