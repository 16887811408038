'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasModalConfig', [
    'BasString',
    basModalConfigFactory
  ])

function basModalConfigFactory (BasString) {

  var CSS_IS_SPLASH_MODAL = 'bas-modal-is-splash-modal'
  var CSS_HAS_HEADER_ICON = 'bas-modal-has-header-icon'
  var CSS_HAS_TITLE = 'bas-modal-has-title'
  var CSS_HAS_SUBTITLE = 'bas-modal-has-subtitle'
  var CSS_HAS_EXTRA_OPTION = 'bas-modal-has-extra-button'
  var CSS_HAS_BUTTON = 'bas-modal-has-button'
  var CSS_HAS_BUTTON_ACTIVE = 'bas-modal-has-button-active'
  var CSS_BUTTON_ACTIVE_DISABLED = 'bas-modal-button-active--disabled'

  /**
   * A configuration object for a BasModal
   *
   * @constructor
   */
  function BasModalConfig () {

    /**
     * @type {BasIcon}
     */
    this.icon = null

    /**
     * @type {string}
     */
    this.title = ''

    /**
     * @type {BasString}
     */
    this.subtitle = new BasString()

    /**
     * Array of text lines, translation IDs
     *
     * @type {string[]}
     */
    this.bodyLines = []

    /**
     * Array of literal text. Shown after translated body lines.
     *
     * @type {string[]}
     */
    this.bodyLinesLiteral = []

    /**
     * @type {string}
     */
    this.extraOption = ''

    /**
     * @type {string}
     */
    this.cancelButton = ''

    /**
     * @type {string}
     */
    this.okButton = ''

    /**
     * @type {boolean}
     */
    this.allowDismiss = true

    /**
     * @type {boolean}
     */
    this.closeOnSplash = true

    /**
     * @type {number}
     */
    this.okButtonDelay = 0

    /**
     * CSS Classes
     *
     * @type {Object}
     */
    this.css = {}

    /**
     * Options for the ModalService
     *
     * @instance
     * @type {Object}
     */
    this.modalOptions = null
  }

  // Instance methods

  /**
   * Enable/disable a property with corresponding class
   *
   * @param {string} className
   * @param {string} property
   * @param {string} value
   */
  BasModalConfig.prototype.setProperty = function (
    className,
    property,
    value
  ) {
    this.css[className] = BasUtil.isNEString(value)

    this[property] = this.css[className] ? value : ''
  }

  /**
   * Enable or disable the modal header icon
   *
   * @param {boolean} value
   */
  BasModalConfig.prototype.enableHeaderIcon = function (value) {
    this.css[CSS_HAS_HEADER_ICON] = value === true
  }

  /**
   * Enable or disable isSplash modal property
   *
   * @param {boolean} value
   */
  BasModalConfig.prototype.enableSplashModal = function (value) {
    this.css[CSS_IS_SPLASH_MODAL] = value === true
  }

  /**
   * Set icon for the modal. Warning icon is shown as default.
   *
   * @param {BasIcon} icon
   */
  BasModalConfig.prototype.setIcon = function (icon) {

    this.icon = icon
  }

  /**
   * Set title text for the modal. Disable by passing an empty string.
   *
   * The text needs to be a translate key.
   *
   * @param {string} titleText
   */
  BasModalConfig.prototype.setTitle = function (titleText) {
    this.setProperty(
      CSS_HAS_TITLE,
      'title',
      titleText
    )
  }

  /**
   * Set subtitle key for the modal. Disable by passing an empty BasString.
   *
   * @param {BasString} subtitle
   */
  BasModalConfig.prototype.setSubtitle = function (subtitle) {

    if (subtitle instanceof BasString) {

      this.subtitle = subtitle

      this.css[CSS_HAS_SUBTITLE] =
        BasUtil.isNEString(subtitle.getString())

    } else {

      this.subtitle.clear()
    }
  }

  /**
   * Add a line of text to the body of the modal.
   *
   * The text needs to be a translation key/id.
   *
   * @param {string} line
   */
  BasModalConfig.prototype.addBodyLine = function (line) {

    if (BasUtil.isNEString(line)) this.bodyLines.push(line)
  }

  /**
   * Add a line of text to the body of the modal.
   *
   * @param {string} line
   */
  BasModalConfig.prototype.addBodyLineLiteral = function (line) {

    if (BasUtil.isNEString(line)) this.bodyLinesLiteral.push(line)
  }

  /**
   * Enable the extra option by setting its text.
   * Disable by setting an empty string.
   *
   * The text needs to be translate key.
   *
   * @param {string} extraOptionText
   */
  BasModalConfig.prototype.setExtraOption = function (extraOptionText) {
    this.setProperty(
      CSS_HAS_EXTRA_OPTION,
      'extraOption',
      extraOptionText
    )
  }

  /**
   * Enable the cancel button and set text for the cancel button.
   * Disable the cancel button by passing an empty string.
   *
   * The text needs to be translate key.
   *
   * @param {string} cancelButtonText
   */
  BasModalConfig.prototype.setCancelButton = function (cancelButtonText) {
    this.setProperty(
      CSS_HAS_BUTTON,
      'cancelButton',
      cancelButtonText
    )
  }

  /**
   * Enable the ok button and set text for the ok button.
   * Disable the ok button by passing an empty string.
   *
   * The text needs to be translate key.
   *
   * @param {string} okButtonText
   */
  BasModalConfig.prototype.setOkButton = function (okButtonText) {
    this.setProperty(
      CSS_HAS_BUTTON_ACTIVE,
      'okButton',
      okButtonText
    )
  }

  /**
   * Delay the ok button from becoming "active"
   *
   * @param {number} delay in ms
   */
  BasModalConfig.prototype.setOkButtonDelay = function (delay) {

    this.okButtonDelay = BasUtil.isPNumber(delay, true) ? delay : 0
    this.setOkButtonDisabled(BasUtil.isPNumber(this.okButtonDelay))
  }

  /**
   * @param {boolean} disabled
   */
  BasModalConfig.prototype.setOkButtonDisabled = function (disabled) {

    this.css[CSS_BUTTON_ACTIVE_DISABLED] = !!disabled
  }

  return BasModalConfig
}
