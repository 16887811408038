'use strict'

angular
  .module('basalteApp')
  .service('BasFavouritesHelper', [
    'BAS_FAVOURITE',
    BasFavouritesHelper
  ])

/**
 * @constructor
 * @param {BAS_FAVOURITE} BAS_FAVOURITE
 */
function BasFavouritesHelper (
  BAS_FAVOURITE
) {
  this.getUuid = getUuid
  this.getKey = getKey
  this.getFavouriteIconImage = getFavouriteIconImage

  /**
   * Generates the favourite UUID that will match with a real favourite
   *
   * @param {number} type
   * @param {(string|number)} id
   * @returns {string}
   */
  function getUuid (type, id) {

    var uuid = ''

    switch (type) {
      case BAS_FAVOURITE.T_LOCAL_PLAYLIST:

        uuid = BAS_FAVOURITE._K_PLAYLIST + id

        break
      case BAS_FAVOURITE.T_RADIO:

        uuid = BAS_FAVOURITE._K_GID + id

        break
      case BAS_FAVOURITE.T_DEEZER:

        uuid = BAS_FAVOURITE._K_DEEZER_ID + id

        break
      case BAS_FAVOURITE.T_TIDAL:

        uuid = BAS_FAVOURITE._K_TIDAL_ID + id

        break
      case BAS_FAVOURITE.T_SPOTIFY_CONNECT:

        uuid = BAS_FAVOURITE._K_URI + id

        break
      case BAS_FAVOURITE.T_SONOS:
      case BAS_FAVOURITE.T_BOSPEAKER:

        uuid = BAS_FAVOURITE._K_AUDIO + id

        break
    }

    return uuid
  }

  /**
   * Returns the key for the type given.
   * This key can be used to query the favourites of that type
   * on a BasSourceFavourites instance.
   *
   * @param {number} type
   * @returns {string}
   */
  function getKey (type) {

    switch (type) {
      case BAS_FAVOURITE.T_LOCAL_PLAYLIST:
        return 'local'
      case BAS_FAVOURITE.T_RADIO:
        return 'radio'
      case BAS_FAVOURITE.T_DEEZER:
        return 'deezer'
      case BAS_FAVOURITE.T_TIDAL:
        return 'tidal'
      case BAS_FAVOURITE.T_SPOTIFY:
        return 'spotify'
      case BAS_FAVOURITE.T_SPOTIFY_CONNECT:
        return 'spotifyConnect'
      default:
        return ''
    }
  }

  /**
   * @param {string} uri
   * @returns {ICON}
   */
  function getFavouriteIconImage (uri) {

    // 1. Download icon as png
    //    [tip] Apple candidate icon can be downloaded as png from:
    //    - https://toolbox.marketingtools.apple.com/en-us/app-store/us
    //    - https://toolbox.marketingtools.apple.com/en-us/apple-arcade/us
    // 2. Add icon to img directory
    // 3. Add uri as new 'case' and return the correct image

    switch (uri) {
      case 'video:app:com.apple.Arcade':
        return 'img/localAssets/appleFavourites/apple_arcade.png'
      case 'video:app:com.apple.TVAppStore':
        return 'img/localAssets/appleFavourites/apple_app_store.png'
      case 'video:app:com.apple.TVMusic':
        return 'img/localAssets/appleFavourites/apple_music.png'
      case 'video:app:com.apple.TVPhotos':
        return 'img/localAssets/appleFavourites/apple_photos.png'
      case 'video:app:com.apple.TVSettings':
        return 'img/localAssets/appleFavourites/apple_settings.png'
      case 'video:app:com.apple.TVSearch':
        return 'img/localAssets/appleFavourites/apple_search.png'
      case 'video:app:com.apple.TVWatchList':
        return 'img/localAssets/appleFavourites/apple_tv.png'
      case 'video:app:com.apple.facetime':
        return 'img/localAssets/appleFavourites/apple_facetime.png'
      case 'video:app:com.apple.podcasts':
        return 'img/localAssets/appleFavourites/apple_podcasts.png'
      case 'video:app:com.disney.disneyplus':
        return 'img/localAssets/appleFavourites/apple_disney_plus.png'
      case 'video:app:com.google.ios.youtube':
        return 'img/localAssets/appleFavourites/apple_youtube.png'
      case 'video:app:com.google.ios.youtubekids':
        return 'img/localAssets/appleFavourites/apple_youtube_kids.png'
      case 'video:app:com.netflix.Netflix':
        return 'img/localAssets/appleFavourites/apple_netflix.png'
      case 'video:app:com.spotify.client':
        return 'img/localAssets/appleFavourites/apple_spotify.png'
      case 'video:app:com.wbd.hbomax':
        return 'img/localAssets/appleFavourites/apple_hbo_max.png'
      case 'video:app:com.amazon.aiv.AIVApp':
        return 'img/localAssets/appleFavourites/apple_amazon_prime.png'

      // No icon availbe, but a default icon is needed
      case 'video:app:com.apple.TVHomeSharing':
      case 'video:app:com.apple.TVMovies':
        return 'img/localAssets/appleFavourites/apple_default.png'

      // No default to avoid adding an icon in the wrong place
      //  (e.g. demo mode > set-top box source > favourites > tv icon)
      default:
        return null
    }
  }
}
