'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasUtilities', [
    '$window',
    '$translate',
    'BAS_UTILITIES',
    'LANGUAGES',
    'BAS_API',
    'BAS_ERRORS',
    'BasSupports',
    'Logger',
    BasUtilities
  ])

/**
 * @typedef {Object} TBasEmitterOptions
 * @property {boolean} [emit]
 */

/**
 * @callback CBasAbort
 */

/**
 * @callback CBasSmoothScrollVerticalCallback
 * @param {TBasSmoothScrollVerticalOperation}
 */

/**
 * @typedef {Object} TBasSmoothScrollVerticalOperation
 * @property {boolean} finished
 * @property {boolean} aborted
 * @property {CBasAbort} abort
 * @property {HTMLElement} scrollable
 * @property {number} startTime
 * @property {number} startY
 * @property {number} y
 * @property {number} rafId
 */

/**
 * @typedef {Object} TParsedUrl
 * @property {string} protocol
 * @property {string} host
 * @property {string} pathname
 * @property {string} search
 */

/**
 * @constructor
 * @param $window
 * @param $translate
 * @param {BAS_UTILITIES} BAS_UTILITIES
 * @param LANGUAGES
 * @param BAS_API
 * @param {BAS_ERRORS} BAS_ERRORS
 * @param {BasSupports} BasSupports
 * @param Logger
 */
function BasUtilities (
  $window,
  $translate,
  BAS_UTILITIES,
  LANGUAGES,
  BAS_API,
  BAS_ERRORS,
  BasSupports,
  Logger
) {
  var serviceName = 'Bas Utility'

  var ERROR_INVALID_INPUT = 'Invalid input'
  var DEFAULT_LANGUAGE = 'en_GB'

  var idCounter = 0

  var now = $window.performance && $window.performance.now
    ? $window.performance.now.bind($window.performance)
    : Date.now

  /**
   * @type {TBasSupports}
   */
  var basSupports = BasSupports.get()

  this.isScope = isScope
  this.closeModal = closeModal
  this.getAppUniqueId = getAppUniqueId
  this.getMacNumber = getMacNumber
  this.parseUrl = parseUrl
  this.handleHttpResponse = handleHttpResponse
  this.getTranslateKey = getTranslateKey
  this.translate = translate
  this.basTranslate = basTranslate
  this.translateMonth = translateMonth
  this.translateMonthShort = translateMonthShort
  this.basWaitForFrame = basWaitForFrame
  this.basClearWaitForFrame = basClearWaitForFrame
  this.waitForFrames = waitForFrames
  this.waitFrames = waitFrames
  this.hasClassInClassName = hasClassInClassName
  this.generateClassName = generateClassName
  this.smoothScrollVertical = smoothScrollVertical
  this.getWebLocale = getWebLocale
  this.getSystemLanguage = getSystemLanguage
  this.isKLanguage = isKLanguage
  this.getLocale = getLocale
  this.getLocaleParameter = getLocaleParameter
  this.extractIsoDateString = extractIsoDateString
  this.extractIsoDateTimeString = extractIsoDateTimeString
  this.extractFromIsoDateString = extractFromIsoDateString
  this.getShort2DayForDayIdx = getShort2DayForDayIdx
  this.getShortDay = getShortDay
  this.getCountry = getCountry
  this.getDeviceLocationStr = getDeviceLocationStr
  this.getTelPhone = getTelPhone
  this.isHttpProtocol = isHttpProtocol
  this.compareAscending = compareAscending
  this.compareDescending = compareDescending
  this.getAPITranslation = getAPITranslation

  /**
   * Checks whether the given value is a $scope.
   *
   * @param value
   * @returns {boolean}
   */
  function isScope (value) {

    return BasUtil.isObject(value) && value.$evalAsync && value.$watch
  }

  /**
   * @param {Object} modal
   * @param {*} [result]
   */
  function closeModal (modal, result) {

    if (modal &&
      modal.controller &&
      BasUtil.isFunction(modal.controller.close)) {

      modal.controller.close(result)
    }
  }

  /**
   * @param {string} [prefix]
   * @returns {string}
   */
  function getAppUniqueId (prefix) {

    var _prefix = BasUtil.isString(prefix) ? prefix : 'BasAppUnique'

    return _prefix + idCounter++
  }

  /**
   * @param {(number|string)} mac
   * @returns {number}
   */
  function getMacNumber (mac) {

    var value

    if (BasUtil.isPNumber(mac)) return mac

    if (BasUtil.isNEString(mac)) {

      value = BAS_API.MacAddressUtil.convertToNumber(mac)
      if (BasUtil.isPNumber(value)) return value
    }

    return 0
  }

  /**
   * Properties
   *
   * parser.protocol; => 'http:'
   * parser.host;     => 'example.com:3000'
   * parser.pathname; => '/pathname/'
   * parser.search;   => '?search=test'
   *
   * All properties can be found here:
   * https://developer.mozilla.org/en-US/docs/Web/API/HTMLAnchorElement
   *
   * @param {string} url
   * @returns {TParsedUrl}
   */
  function parseUrl (url) {

    var parser, parsed

    parser = document.createElement('a')
    parser.href = url

    parsed = {}

    parsed.protocol = parser.protocol
    parsed.host = parser.host
    parsed.pathname = parser.pathname
    parsed.search = parser.search

    return parsed
  }

  /**
   * Retrieve current language key
   *
   * @returns {string}
   */
  function getTranslateKey () {
    var translateUse

    // Get current used language
    translateUse = $translate.use()

    // Check current language
    if (BasUtil.isNEString(translateUse)) {

      return translateUse.substring(0, 2)

    } else {
      Logger.warn(serviceName +
                    ' - getTranslateKey' +
                    ' - Invalid key', translateUse)

      return ''
    }
  }

  /**
   * Angular translate wrapper
   *
   * @param {string} key
   * @param {?Object} [interpolateParams]
   * @param {string} [interpolateId]
   * @param {string} [forceLanguage]
   * @param {string} [sanitizeStrategy]
   * @returns {string}
   */
  function translate (
    key,
    interpolateParams,
    interpolateId,
    forceLanguage,
    sanitizeStrategy
  ) {
    // Default parameters
    var _interpolateParams = null
    var _interpolateId = ''
    var _forceLanguage = ''
    var _sanitizeStrategy = ''

    // Check parameters

    if (BasUtil.isObject(interpolateParams)) {
      _interpolateParams = interpolateParams
    }

    if (BasUtil.isString(interpolateId)) {
      _interpolateId = interpolateId
    }

    if (BasUtil.isString(forceLanguage)) {
      _forceLanguage = forceLanguage
    }

    if (BasUtil.isString(sanitizeStrategy)) {
      _sanitizeStrategy = sanitizeStrategy
    }

    // Check parameters

    return $translate.instant(
      // Translation ID
      key,

      // Interpolate Params
      _interpolateParams,

      // Interpolation ID
      _interpolateId,

      // Force language
      _forceLanguage,

      // Sanitize strategy
      _sanitizeStrategy
    )
  }

  /**
   * Translates a string, or parts of a string, or nothing at all.
   *
   * String starting with "$$"
   * will not fallback to regular translate if no "${}" blocks were found
   *
   * @param {string} str
   * @returns {string}
   */
  function basTranslate (str) {

    var _str, _translateFallback, idx, result, prevIdx

    if (BasUtil.isNEString(str)) {

      _translateFallback = str.substring(0, 2) !== '$$'

      _str = _translateFallback
        ? str
        : str.substring(2)

      idx = _str.indexOf('${')

      if (idx < 0) {

        return _translateFallback ? translate(_str) : _str
      }

      prevIdx = 0
      result = ''

      while (idx > -1) {

        result += _str.substring(prevIdx, idx)
        prevIdx = idx
        idx = _str.indexOf('}', prevIdx)

        if (idx < 0) {

          // No closing bracket, probably wrong input
          return _translateFallback ? translate(_str) : _str

        } else {

          result += translate(_str.substring(prevIdx + 2, idx))
        }

        prevIdx = idx + 1
        idx = _str.indexOf('${', prevIdx)
      }

      result += _str.substring(prevIdx)

      return result
    }
  }

  /**
   * Get system language
   *
   * @returns {string} language
   */
  function getSystemLanguage () {

    var _locale, lang, i, length

    _locale = getLocale()

    if (_locale) {

      _locale = _locale.toLowerCase()

      length = LANGUAGES.length
      for (i = 0; i < length; i++) {

        lang = LANGUAGES[i]
        if (_locale.indexOf(lang.langCode) === 0) {

          return lang.code
        }
      }
    }

    return DEFAULT_LANGUAGE
  }

  /**
   * Checks if given language is a known one
   *
   * @param {string} language
   * @returns {boolean}
   */
  function isKLanguage (language) {

    var i, length, lang

    if (!BasUtil.isNEString(language)) return false

    length = LANGUAGES.length
    for (i = 0; i < length; i++) {

      lang = LANGUAGES[i]

      if (BasUtil.isObject(lang) &&
        BasUtil.isNEString(lang.code) &&
        lang.code === language) {

        return true
      }
    }

    return false
  }

  /**
   * @param {number} monthNumber [0 - 11]
   * @returns {string}
   */
  function translateMonth (monthNumber) {
    switch (monthNumber) {
      case 0: return translate('month_january')
      case 1: return translate('month_february')
      case 2: return translate('month_march')
      case 3: return translate('month_april')
      case 4: return translate('month_may')
      case 5: return translate('month_june')
      case 6: return translate('month_july')
      case 7: return translate('month_august')
      case 8: return translate('month_september')
      case 9: return translate('month_october')
      case 10: return translate('month_november')
      case 11: return translate('month_december')
    }
    return ''
  }

  /**
   * @param {number} monthNumber [0 - 11]
   * @returns {string}
   */
  function translateMonthShort (monthNumber) {
    switch (monthNumber) {
      case 0: return translate('month_short_january')
      case 1: return translate('month_short_february')
      case 2: return translate('month_short_march')
      case 3: return translate('month_short_april')
      case 4: return translate('month_short_may')
      case 5: return translate('month_short_june')
      case 6: return translate('month_short_july')
      case 7: return translate('month_short_august')
      case 8: return translate('month_short_september')
      case 9: return translate('month_short_october')
      case 10: return translate('month_short_november')
      case 11: return translate('month_short_december')
    }
    return ''
  }

  /**
   * @param callback
   * @returns {*|number}
   */
  function basWaitForFrame (callback) {

    // Fallback to setTimeout with 50ms
    // Usually when we wait for frames
    // it is because something needs to be rendered
    // When rendering it is possible that the framerate disp below 60fps
    // 50ms matches 20fps.

    return basSupports.requestAnimationFrame
      ? $window.requestAnimationFrame(callback)
      : setTimeout(callback, 50)
  }

  /**
   * @param id
   * @returns {*|void}
   */
  function basClearWaitForFrame (id) {

    return basSupports.requestAnimationFrame
      ? $window.cancelAnimationFrame(id)
      : clearTimeout(id)
  }

  /**
   * @param {number} number
   * @param callback
   * @returns {CBasAbort}
   */
  function waitForFrames (number, callback) {

    var aborted, count

    aborted = false
    count = 0

    if (BasUtil.isPNumber(number)) {

      basWaitForFrame(onFrame)

    } else {

      if (BasUtil.isFunction(callback)) callback()
    }

    return abort

    function onFrame () {

      if (!aborted) {

        count++

        if (count >= number) {

          if (BasUtil.isFunction(callback)) callback()

        } else {

          basWaitForFrame(onFrame)
        }
      }
    }

    function abort () {

      aborted = true
    }
  }

  /**
   * Return a Promise which resolves after
   * the given number of frames have passed
   *
   * @param {number} numberOfFrames
   * @returns {Promise}
   */
  function waitFrames (numberOfFrames) {

    return BasUtil.isPNumber(numberOfFrames)
      ? new Promise(rafPromiseConstructor)
      : Promise.reject(ERROR_INVALID_INPUT)

    function rafPromiseConstructor (resolve) {

      var count = 0

      waitForFrame()

      function onFrame () {

        // Increment count
        count += 1

        // Check count
        if (count >= numberOfFrames) {

          // Reached number of frames
          resolve()

        } else {

          // Request another frame
          waitForFrame()

        }
      }

      function waitForFrame () {

        basWaitForFrame(onFrame)
      }
    }
  }

  /**
   * @param result
   * @returns {(string|Object)}
   */
  function handleHttpResponse (result) {

    return BasUtil.isObject(result)
      ? result.data
      : Promise.reject(BAS_ERRORS.T_INVALID_RESULT)
  }

  /**
   * @param {string} className
   * @param {string} classToFind
   * @returns {boolean}
   */
  function hasClassInClassName (className, classToFind) {

    var cn, classNames, length, i

    cn = BasUtil.isString(className) ? className : ''
    classNames = cn.split(' ')
    length = classNames.length
    for (i = 0; i < length; i++) if (classNames[i] === classToFind) return true
    return false
  }

  /**
   * @param {string} current
   * @param {(Object<string, boolean>|(Object<string, boolean>)[])} classes
   * @returns {string}
   */
  function generateClassName (
    current,
    classes
  ) {
    var _current, _classes, _variableClasses, length, i, item
    var currentClasses, originalClasses

    _current = BasUtil.isString(current) ? current : ''

    _classes = []
    _variableClasses = []

    if (Array.isArray(classes)) {

      length = classes.length
      for (i = 0; i < length; i++) {

        item = classes[i]

        if (BasUtil.isObject(item)) {

          _variableClasses = _variableClasses.concat(Object.keys(item))
          _classes = _classes.concat(BasUtil.getKeys(item, true))
        }
      }

    } else if (BasUtil.isObject(classes)) {

      _variableClasses = Object.keys(classes)
      _classes = BasUtil.getKeys(classes, true)
    }

    // Get original classes

    currentClasses = _current.split(BAS_UTILITIES.REGEX_WHITESPACES)
    originalClasses = []
    length = currentClasses.length
    for (i = 0; i < length; i++) {

      item = currentClasses[i]
      if (_variableClasses.indexOf(item) < 0) originalClasses.push(item)
    }

    return originalClasses.join(' ') + (
      _classes.length
        ? ' ' + _classes.join(' ')
        : ''
    )
  }

  /**
   * @param {HTMLElement} el
   * @param {number} top
   * @param {CBasSmoothScrollVerticalCallback} [callback]
   * @returns {TBasSmoothScrollVerticalOperation}
   */
  function smoothScrollVertical (el, top, callback) {

    var result, scrollTop

    result = {
      finished: false,
      aborted: false,
      abort: abort
    }

    if (el) {

      scrollTop = el.scrollTop

      if (BasUtil.isVNumber(scrollTop)) {

        result.scrollable = el
        result.startTime = now()
        result.startY = scrollTop
        result.y = top

        _step(result, callback)

      } else {

        result.finished = true
        BasUtil.exec(callback, result)
      }
    }

    return result

    function abort () {

      if (
        !result.aborted &&
        !result.finished
      ) {

        result.aborted = true

        basClearWaitForFrame(result.rafId)

        result.rafId = 0

        BasUtil.exec(callback, result)
      }
    }
  }

  /**
   * @private
   * @param {TBasSmoothScrollVerticalOperation} context
   * @param {CBasSmoothScrollVerticalCallback} finishCallback
   */
  function _step (context, finishCallback) {

    _doStep()

    function _doStep () {

      var currY

      if (!context.aborted && !context.finished) {

        currY = _process()

        // Scroll more if we have not reached our destination
        if (currY !== context.y) {
          context.rafId = basWaitForFrame(_doStep)
        } else {
          context.finished = true
          context.rafId = 0
          BasUtil.exec(finishCallback, context)
        }
      }
    }

    /**
     * @private
     * @returns {number}
     */
    function _process () {

      var time, value, currentY, elapsed

      time = now()

      // Magic number :)
      elapsed = (time - context.startTime) / 468

      // Avoid elapsed times higher than one
      elapsed = elapsed > 1 ? 1 : elapsed

      // Apply easing to elapsed time
      value = 0.5 * (1 - Math.cos(Math.PI * elapsed))

      currentY = context.startY + (context.y - context.startY) * value

      context.scrollable.scrollTop = currentY

      return currentY
    }
  }

  /**
   * Locale in BCP-47 form.
   *
   * @returns {string}
   */
  function getWebLocale () {

    return $window.navigator.language || $window.navigator.userLanguage
  }

  /**
   * Return BCP 47 tag.
   * return empty string if invalid.
   *
   * @param {string} language
   * consisting of an ISO 639-1 language code and
   * an ISO 3166-1 alpha-2 country code, joined by an underscore.
   * @returns {string}
   */
  function getLocaleParameter (language) {

    var split, length, part1, part2

    if (BasUtil.isNEString(language)) {

      split = language.split('_')

      if (Array.isArray(split)) {

        length = split.length

        if (length > 1) {

          part1 = split[0]
          part2 = split[1]

          if (BasUtil.isNEString(part1) &&
            BasUtil.isNEString(part2)) {

            return part1 + '-' + part2
          }
        }
      }
    }

    return ''
  }

  /**
   * Consisting of an ISO 639-1 language code and
   * an ISO 3166-1 alpha-2 country code, joined by an underscore.
   *
   * return empty string if invalid.
   *
   * @returns {string}
   */
  function getLocale () {

    var _locale, split, length, part1, part2

    _locale = getWebLocale()

    if (BasUtil.isNEString(_locale)) {

      split = _locale.split('-')

      if (Array.isArray(split)) {

        length = split.length
        if (length > 1) {

          part1 = split[0]
          part2 = split[1]

          if (BasUtil.isNEString(part1) &&
            BasUtil.isNEString(part2)) {

            return part1 + '_' + part2.toUpperCase()
          }
        }
      }
    }

    return ''
  }

  /**
   * ISO 3166-1 alpha-2 country code. Empty string if invalid.
   *
   * @returns {string}
   */
  function getCountry () {

    var _locale, split, length

    _locale = getWebLocale()

    if (BasUtil.isNEString(_locale)) {

      split = _locale.split('-')

      if (Array.isArray(split)) {

        length = split.length
        if (length > 1) {

          if (BasUtil.isNEString(split[1])) return split[1]
        }
      }
    }

    return ''
  }

  /**
   * Expects "YYYY-MM-DDTHH:MM:SS+HHMM"
   * returns "YYYY-MM-DD"
   *
   * @param {string} input
   * @returns {string}
   */
  function extractIsoDateString (input) {

    var _input, idx

    if (BasUtil.isNEString(input)) {

      _input = input.trim()

      idx = _input.indexOf('T')

      if (idx < 0) {
        idx = _input.indexOf(' ')
      }

      return _input.substring(0, idx)
    }

    return ''
  }

  /**
   * Expects "YYYY-MM-DDTHH:MM:SS+HHMM"
   * returns "YYYY-MM-DDTHH:MM:SS"
   *
   * @param {string} input
   * @returns {string}
   */
  function extractIsoDateTimeString (input) {

    var _input, idx, offsetIdx

    if (BasUtil.isNEString(input)) {

      _input = input.trim()

      idx = _input.indexOf('T')

      if (idx < 0) {
        idx = _input.indexOf(' ')
      }

      if (idx > -1) {
        offsetIdx = _input.indexOf('+', idx)
        if (offsetIdx < 0) offsetIdx = _input.indexOf('-', idx)
        if (offsetIdx > -1) {
          return _input.substring(0, offsetIdx)
        }
      }
    }

    return ''
  }

  /**
   * Expects "YYYY-MM-DDTHH:MM:SS+HHMM" and "HH"
   * Returns "HH"
   *
   * @param {string} input
   * @param {string} pattern
   * @returns {string}
   */
  function extractFromIsoDateString (input, pattern) {

    var _input, idx, secondDashIdx, firstColonIdx

    if (BasUtil.isNEString(input) && pattern === 'HH') {

      _input = input.trim()

      idx = _input.indexOf('T')
      if (idx < 0) {
        idx = _input.indexOf(' ')
      }

      secondDashIdx = _input.indexOf('-')
      if (secondDashIdx < idx) {
        secondDashIdx = _input.indexOf('-')
      } else {
        secondDashIdx = -1
      }

      if (idx > -1) {
        firstColonIdx = _input.indexOf(':', idx)
      }

      if (pattern === 'DD') {
        if (secondDashIdx > -1) {
          return _input.substring(secondDashIdx + 1, secondDashIdx + 3)
        }
      } else if (pattern === 'HH') {
        if (firstColonIdx > -1) {
          return _input.substring(idx + 1, firstColonIdx)
        }
      }
    }

    return ''
  }

  /**
   * @param {Date} date
   */
  function getShortDay (date) {
    return getShort2DayForDayIdx(date.getDay())
  }

  /**
   * @param {number} idx 0 is sunday, 1 is monday, ...
   * @returns {string}
   */
  function getShort2DayForDayIdx (idx) {
    switch (idx) {
      case 0: return translate('day_short2_sunday')
      case 1: return translate('day_short2_monday')
      case 2: return translate('day_short2_tuesday')
      case 3: return translate('day_short2_wednesday')
      case 4: return translate('day_short2_thursday')
      case 5: return translate('day_short2_friday')
      case 6: return translate('day_short2_saturday')
      default: return ''
    }
  }

  /**
   * Get translated device location string
   *
   * @param {number} key
   * @returns {string}
   */
  function getDeviceLocationStr (key) {

    switch (key) {
      case BAS_API.Device.LOC.LOC_NORTH:
        return translate('location_north')
      case BAS_API.Device.LOC.LOC_EAST:
        return translate('location_east')
      case BAS_API.Device.LOC.LOC_SOUTH:
        return translate('location_south')
      case BAS_API.Device.LOC.LOC_WEST:
        return translate('location_west')
      case BAS_API.Device.LOC.LOC_LEFT:
        return translate('location_left')
      case BAS_API.Device.LOC.LOC_RIGHT:
        return translate('location_right')
      case BAS_API.Device.LOC.LOC_TOP:
        return translate('location_top')
      case BAS_API.Device.LOC.LOC_BOTTOM:
        return translate('location_bottom')
      case BAS_API.Device.LOC.LOC_CENTER:
        return translate('location_center')
      case BAS_API.Device.LOC.LOC_MID:
        return translate('location_mid')
      case BAS_API.Device.LOC.LOC_LOW:
        return translate('location_low')
      case BAS_API.Device.LOC.LOC_HIGH:
        return translate('location_high')
      case BAS_API.Device.LOC.LOC_FRONT:
        return translate('location_front')
      case BAS_API.Device.LOC.LOC_BACK:
        return translate('location_back')
      case BAS_API.Device.LOC.LOC_CORNER:
        return translate('location_corner')
      case BAS_API.Device.LOC.LOC_CHAIR:
        return translate('location_chair')
      case BAS_API.Device.LOC.LOC_TABLE:
        return translate('location_table')
      case BAS_API.Device.LOC.LOC_DESK:
        return translate('location_desk')
      case BAS_API.Device.LOC.LOC_STOVE:
        return translate('location_stove')
      case BAS_API.Device.LOC.LOC_ISLAND:
        return translate('location_island')
      case BAS_API.Device.LOC.LOC_SHELVES:
        return translate('location_shelves')
      case BAS_API.Device.LOC.LOC_NICHE:
        return translate('location_niche')
    }

    return ''
  }

  /**
   * Attempt to convert phone number for href="tel:PHONE_NUMBER"
   *
   * @param {string} phone
   * @returns {string}
   */
  function getTelPhone (phone) {

    var result

    result = ''

    if (BasUtil.isNEString(phone)) {

      result = phone.trim()

      // Remove spaces
      result = result.replace(
        BAS_UTILITIES.REGEX_SPACES,
        ''
      )

      // Replace "+" with "00"
      result = result.replace(
        BAS_UTILITIES.REGEX_PLUS_CHARS,
        '00'
      )
    }

    return result
  }

  /**
   * Checks for "http:" protocol
   *
   * @param {string} protocol
   * @returns {boolean}
   */
  function isHttpProtocol (protocol) {

    return (
      BasUtil.isNEString(protocol) &&
      protocol.toLowerCase() === 'http:'
    )
  }

  /**
   * @param {number} a
   * @param {number} b
   * @returns {number}
   */
  function compareAscending (a, b) {
    return a - b
  }

  /**
   * @param {number} a
   * @param {number} b
   * @returns {number}
   */
  function compareDescending (a, b) {
    return b - a
  }

  /**
   * @param {string} key
   * @param {string} fallback
   * @returns {string}
   */
  function getAPITranslation (key, fallback) {
    switch (key) {
      case BAS_API.TRANSLATION_KEYS.HEIGHT:
        return 'height'
      case BAS_API.TRANSLATION_KEYS.BALANCE:
        return 'balance'
      case BAS_API.TRANSLATION_KEYS.VERTICAL:
        return 'vertical'
      default:
        return fallback
    }
  }
}
