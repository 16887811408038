'use strict'

angular
  .module('basCircleProgress', [])
  .directive('basCircleProgress', basCircleProgress)

function basCircleProgress () {

  return {
    restrict: 'AE',
    scope: {
      minValue: '=',
      maxValue: '=',
      value: '=',
      mainText: '=',
      secondaryText: '@'
    },
    template: '<div class="bas-progress">' +
      '<svg viewBox="0 0 36 36" class="bas-progress-svg">' +
      '<path class="bas-progress-circle-bg"' +
      ' d="' +
      'M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831' +
      ' a 15.9155 15.9155 0 0 1 0 -31.831' +
      '"/>' +
      '<path class="bas-progress-circle"' +
      ' ng-style="' +
      '{' +
      '\'stroke-dasharray\': (value/(maxValue-minValue)*100) + \' 100\'' +
      '}' +
      '"' +
      ' d="' +
      'M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831' +
      ' a 15.9155 15.9155 0 0 1 0 -31.831' +
      '"/>' +
      '<text class="bas-progress-text-main bas-font-roboto-regular"' +
      ' x="18"' +
      ' ng-attr-y="{{secondaryText ? 16 : 18.5}}"' +
      '>{{mainText}}</text>' +
      '<text x="18" y="23" ' +
      'class="bas-progress-text-secondary bas-font-roboto-regular"' +
      ' ng-bind="secondaryText"></text>' +
      '</svg>' +
      '</div>'
  }
}
