'use strict'

var _count = 1

angular
  .module('basalteApp')
  .constant('BAS_MODAL', {
    EVT_ADD_SERVER_MODAL_VISIBILITY_CHANGED:
      'evtAddServerModalVisibilityChanged',
    T_QUEUE_CLEAR: 'basModalQueueClear',
    T_ABOUT_TO_GO_TO_LIVE_LOGIN: 'basModalAboutToGoToLiveLogin',
    T_ABOUT_TO_GO_TO_LIVE_MANAGE: 'basModalAboutToGoToLiveManage',
    T_ABOUT_TO_CHANGE_PASSWORD: 'basModalAboutToChangePassword',
    T_ABOUT_TO_LOG_OUT_EVERYWHERE: 'basModalAboutToLogOutEverywhere',
    T_ABOUT_TO_LOG_OUT: 'basModalAboutToLogOut',
    T_ABOUT_TO_DELETE_ACCOUNT: 'basModalAboutToDeleteAccount',
    T_PASSWORD_CHANGED: 'basModalPasswordChanged',
    T_WRONG_PASSWORD: 'basModalWrongPassword',
    T_UNABLE_TO_CONNECT: 'basModalUnableToConnect',
    T_RESTART_SERVER: 'basModalRestartServer',
    T_DEEZER_DISCONNECT: 'basModalDeezerDisconnect',
    T_DEEZER_OAUTH_ERROR: 'basModalDeezerOAuthError',
    T_SPOTIFY_DISCONNECT: 'basModalSpotifyDisconnect',
    T_SPOTIFY_FREE_USER: 'basModalSpotifyFreeUser',
    T_SPOTIFY_ERROR: 'basModalSpotifyError',
    T_SPOTIFY_OATH_ERROR: 'basModalSpotifyOauthError',
    T_TIDAL_DISCONNECT: 'basModalTidalDisconnect',
    T_TIDAL_OAUTH_ERROR: 'basModalTidalOAuthError',
    T_REMOVE: 'basModalRemove',
    T_NO_PERMISSION: 'basModalNoPermission',
    T_DEFAULT_ROOMS_WARNING: 'basModalDefaultRoomsWarning',
    T_UNSUPPORTED_BROWSER: 'basModalUnsupportedBrowser',
    T_UNSUPPORTED_API_INFO: 'basModalUnsupportedApiInfo',
    T_UNSUPPORTED_API_ACTION: 'basModalUnsupportedApiAction',
    T_UNSUPPORTED_APP: 'basModalUnsupportedApp',
    T_UNSUPPORTED_APP_ANDROID: 'basModalUnsupportedAppAndroid',
    T_UNSUPPORTED_APP_IOS: 'basModalUnsupportedAppIos',
    T_UNSUPPORTED_APP_BROWSER: 'basModalUnsupportedBrowserApp',
    T_DEMO_WARNING: 'basModalDemoWarning',
    T_DEMO_FUNCTIONALITY_WARNING: 'basModalDemoFunctionalityWarning',
    T_BLUETOOTH_PAIRING: 'basModalBluetoothPairing',
    T_SAVE_LEARN_SCENE: 'basModalSaveLearnScene',
    T_UNSAVED_CHANGES: 'basModalUnsavedChanges',
    T_DSP_SHORTCUT: 'basModalDspShortcut',
    T_CORE_CLIENT_UPDATE: 'basModalCoreClientUpdate',
    T_APP_RESTART: 'basModalAppRestart',
    T_ERROR: 'basModalError',
    T_ERROR_USER_EXISTS: 'basModalErrorUsersExists',
    T_ERROR_USER_NOT_CONFIRMED: 'basModalErrorUserNotConfirmed',
    T_ERROR_INVALID_CODE: 'basModalErrorInvalidCode',
    T_ERROR_INVALID_USER: 'basModalErrorInvalidUser',
    T_ERROR_UNKNOWN_USER: 'basModalErrorUnknownUser',
    T_SCENE_ERROR: 'basModalSceneError',
    T_SCENE_IMAGE_ERROR: 'basModalSceneImageError',
    T_OPEN_SPOTIFY: 'basModalOpenSpotify',
    T_FAVOURITE_SCENES_FULL: 'basModalFavouriteScenesFull',
    T_CONFIRMATION_CODE_SENT: 'basModalConfirmationCodeSend',
    T_LINK_SERVER: 'basModalLinkServer',
    T_LINK_SERVER_ERROR: 'basModalLinkServerError',
    T_UNLINK_SERVER: 'basModalUnlinkServer',
    T_UNLINK_SERVER_ERROR: 'basModalUnlinkServerError',
    T_ROOM_UNREACHABLE: 'basModalRoomUnavailable',
    T_ROOM_UNAVAILABLE: 'basModalRoomBusy',
    T_PERMISSION_DENIED_ALWAYS: 'basModalPermissionDeniedAlways',
    T_CONNECTION_LOST: 'basModalConnectionLost',
    T_REMOTE_ELLIE_OUTDATED: 'basModalRemoteEllieOutdated',
    T_CONNECT_TO_INTEGRATOR_BLOCKED: 'basModalConnectIntegratorBlocked',
    T_CONNECT_TO_OFFLINE_SERVER: 'basModalConnectOfflineServer',
    T_EMPTY_QUEUE: 'basModalEmptyQueue',
    T_SONOS_UNREACHABLE: 'basModalSonosUnreachable',
    T_GENERIC_UNREACHABLE: 'basModalUnreachable',
    T_TIDAL_LEGACY_AUTH: 'basModalTidalLegacyAuth',
    T_RESCAN_MUSIC_LIBRARY: 'basModalRescanMusicLibrary',
    T_SCENE_ACTIVATED: 'basModalSceneActivated',
    T_OVERWRITE: 'basModalOverwrite',
    T_CLEAR_SCHEDULES: 'basModalClearSchedules',
    T_LOGGED_OUT: 'basModalLoggedOut',
    T_VERIFICATION_MAIL_SENT: 'basModalVerificationMailSent',
    T_SECURITY_ALARM: 'basModalSecurityAlarm',
    T_NO_MORE_FAVOURITES: 'basModalNoMoreFavourites',
    CSS_BODY_MODAL_ON_TOP: 'root-body-modal-on-top',
    C_YES: 'yes',
    C_NO: 'no',
    C_EXTRA: 'extra',
    C_CHANGE_USER: _count++,
    C_ROOMS_HOME: _count++,
    C_ROOMS_MUSIC: _count++,
    C_ROOMS_THERMOSTATS: _count++,
    C_ROOMS_INTERCOM: _count++,
    C_ROOMS_VIDEO: _count++,
    C_ALARMS: _count++,
    C_OPEN_CLOSE_DEVICES: _count++,
    C_CAMERAS: _count++,
    C_SETTINGS: _count++,
    C_SETTINGS_ABOUT: _count++,
    C_TV_OFF_SINGLE: _count++,
    C_MUSIC_OFF_SINGLE: _count++,
    C_MUSIC_OFF_ALL: _count++,
    C_LIGHTS_OFF_ALL: _count++,
    C_MUTE_DEVICE: _count++,
    C_SELECT_SERVER: _count++,
    C_SELECT_LIVE: _count++,
    C_SELECT_DISCOVERY: _count++,
    C_SELECT_DEMO: _count++,
    C_ADD_SERVER: _count++
  })
