'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasDevicePreferences', [
    'BAS_PREFERENCES',
    'BasStorage',
    'CurrentBasCore',
    BasDevicePreferences
  ])

/**
 * @typedef {Object} TDeviceSetting
 * @property {string} [language]
 * @property {boolean} [keepAlive]
 * @property {boolean} [showStatusBar]
 * @property {string} [temperatureUnit]
 * @property {boolean} [showBackground]
 * @property {boolean} [debug]
 * @property {boolean} [onlyWebRTC]
 * @property {boolean} [webRTCOnlyRelay]
 * @property {number} [pauseDisconnectTimeoutMs]
 * @property {number} [timeFormat]
 * @property {boolean} [enableNotifications]
 * @property {number} [volume]
 * @property {number} [mute]
 * @property {number} [startPageWidget]
 * @property {number} [cloudEnvironment]
 * @property {number} [screenOrientation]
 */

/**
 * @typedef {Object} TEllieSetting
 * @property {number} [sleepTime]
 * @property {number} [brightness]
 * @property {Object} [network]
 * @property {string} [network.ip]
 * @property {string} [network.subnet]
 * @property {string} [network.gateway]
 * @property {string} [network.dns]
 * @property {boolean} [network.dhcp]
 * @property {number} [playbackGain]
 * @property {number} [microphoneGain]
 * @property {boolean} [echoCancellation]
 * @property {number} [proximity]
 * @property {number} [defaultView]
 */

/**
 * @constructor
 * @param {BAS_PREFERENCES} BAS_PREFERENCES
 * @param {BasStorage} BasStorage
 * @param {CurrentBasCore} CurrentBasCore
 */
function BasDevicePreferences (
  BAS_PREFERENCES,
  BasStorage,
  CurrentBasCore
) {

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {TDeviceSetting}
   */
  var device = {}

  /**
   * @type {TEllieSetting}
   */
  var ellie = {}

  this.syncSettingsFromStorage = syncSettingsFromStorage

  this.getDevice = getDevice
  this.getEllie = getEllie

  this.getLanguage = getLanguage
  this.getKeepAlive = getKeepAlive
  this.getShowStatusBar = getShowStatusBar
  this.getTemperatureUnit = getTemperatureUnit
  this.getShowBackground = getShowBackground
  this.getDebug = getDebug
  this.getOnlyWebRTC = getOnlyWebRTC
  this.getWebRTCOnlyRelay = getWebRTCOnlyRelay
  this.getPauseDisconnectTimeoutMs = getPauseDisconnectTimeoutMs
  this.getEnableNotifications = getEnableNotifications
  this.getSleepTime = getSleepTime
  this.getBrightness = getBrightness
  this.getVolume = getVolume
  this.getMute = getMute
  this.getNetwork = getNetwork
  this.getPlaybackGain = getPlaybackGain
  this.getMicrophoneGain = getMicrophoneGain
  this.getEchoCancellation = getEchoCancellation
  this.getProximitySensitivity = getProximitySensitivity
  this.getDefaultView = getDefaultView
  this.getTimeFormat = getTimeFormat
  this.getStartPageWidget = getStartPageWidget
  this.getCloudEnvironment = getCloudEnvironment
  this.getScreenOrientation = getScreenOrientation
  this.getDefaultWeather = getDefaultWeather

  this.setLanguage = setLanguage
  this.setKeepAlive = setKeepAlive
  this.setShowStatusBar = setShowStatusBar
  this.setTemperatureUnit = setTemperatureUnit
  this.setShowBackground = setShowBackground
  this.setDebug = setDebug
  this.setOnlyWebRTC = setOnlyWebRTC
  this.setWebRTCOnlyRelay = setWebRTCOnlyRelay
  this.setPauseDisconnectTimeoutMs = setPauseDisconnectTimeoutMs
  this.setEnableNotifications = setEnableNotifications
  this.setSleepTime = setSleepTime
  this.setBrightness = setBrightness
  this.setVolume = setVolume
  this.setMute = setMute
  this.setNetwork = setNetwork
  this.setPlaybackGain = setPlaybackGain
  this.setMicrophoneGain = setMicrophoneGain
  this.setEchoCancellation = setEchoCancellation
  this.setProximitySensitivity = setProximitySensitivity
  this.setDefaultView = setDefaultView
  this.setTimeFormat = setTimeFormat
  this.setStartPageWidget = setStartPageWidget
  this.setCloudEnvironment = setCloudEnvironment
  this.setScreenOrientation = setScreenOrientation
  this.setDefaultWeather = setDefaultWeather

  function syncSettingsFromStorage () {

    var settings

    settings = BasStorage.get(BAS_PREFERENCES.K_DEVICE)
    device = BasUtil.isObject(settings) ? settings : {}

    settings = BasStorage.get(BAS_PREFERENCES.K_ELLIE)
    ellie = BasUtil.isObject(settings) ? settings : {}
  }

  /**
   * @returns {TDeviceSetting}
   */
  function getDevice () {
    return device
  }

  /**
   * @returns {TEllieSetting}
   */
  function getEllie () {
    return ellie
  }

  // region Getters

  function getLanguage () {
    return device.language
  }

  function getKeepAlive () {
    return device.keepAlive
  }

  function getShowStatusBar () {
    return device.showStatusBar
  }

  function getTemperatureUnit () {
    return device.temperatureUnit
  }

  function getShowBackground () {
    return device.showBackground
  }

  function getDebug () {
    return device.debug
  }

  function getOnlyWebRTC () {
    return device.onlyWebRTC
  }

  function getWebRTCOnlyRelay () {
    return device.webRTCOnlyRelay
  }

  function getPauseDisconnectTimeoutMs () {
    return device.pauseDisconnectTimeoutMs
  }

  function getEnableNotifications () {
    return device.enableNotifications
  }

  function getVolume () {
    return device.volume
  }

  function getMute () {
    return device.mute
  }

  function getSleepTime () {
    return ellie.sleepTime
  }

  function getBrightness () {
    return ellie.brightness
  }

  function getNetwork () {
    return ellie.network
  }

  function getPlaybackGain () {
    return ellie.playbackGain
  }

  function getMicrophoneGain () {
    return ellie.microphoneGain
  }

  function getEchoCancellation () {
    return ellie.echoCancellation
  }

  function getProximitySensitivity () {
    return ellie.proximity
  }

  function getDefaultView () {
    return ellie.defaultView
  }

  function getTimeFormat () {
    return device.timeFormat
  }

  function getDefaultWeather () {
    const projectId = currentBasCoreState.core?.basServer?._cid

    if (projectId) return device?.projects?.[projectId]?.defaultWeather
  }

  /**
   * @returns {number}
   */
  function getStartPageWidget () {
    return device.startPageWidget
  }

  /**
   * @returns {number}
   */
  function getCloudEnvironment () {
    return device.cloudEnvironment
  }

  /**
   * @returns {number}
   */
  function getScreenOrientation () {
    return device.screenOrientation
  }

  // endregion

  // region Setters

  function setLanguage (value) {

    device.language = value
    _setDeviceSettings()
  }

  function setKeepAlive (value) {
    device.keepAlive = value
    _setDeviceSettings()
  }

  function setShowStatusBar (value) {
    device.showStatusBar = value
    _setDeviceSettings()
  }

  function setTemperatureUnit (value) {
    device.temperatureUnit = value
    _setDeviceSettings()
  }

  function setShowBackground (value) {
    device.showBackground = value
    _setDeviceSettings()
  }

  function setDebug (value) {
    device.debug = value
    _setDeviceSettings()
  }

  function setOnlyWebRTC (value) {
    device.onlyWebRTC = value
    _setDeviceSettings()
  }

  function setWebRTCOnlyRelay (value) {
    device.webRTCOnlyRelay = value
    _setDeviceSettings()
  }

  function setPauseDisconnectTimeoutMs (value) {
    device.pauseDisconnectTimeoutMs = value
    _setDeviceSettings()
  }

  function setEnableNotifications (value) {
    device.enableNotifications = value
    _setDeviceSettings()
  }

  function setVolume (value) {
    device.volume = value
    _setDeviceSettings()
  }

  function setMute (value) {
    device.mute = value
    _setDeviceSettings()
  }

  function setSleepTime (value) {
    ellie.sleepTime = value
    _setEllieSettings()
  }

  function setBrightness (value) {
    ellie.brightness = value
    _setEllieSettings()
  }

  function setNetwork (value) {
    ellie.network = value
    _setEllieSettings()
  }

  function setPlaybackGain (value) {
    ellie.playbackGain = value
    _setEllieSettings()
  }

  function setMicrophoneGain (value) {
    ellie.microphoneGain = value
    _setEllieSettings()
  }

  function setEchoCancellation (value) {
    ellie.echoCancellation = value
    _setEllieSettings()
  }

  function setProximitySensitivity (value) {
    ellie.proximity = value
    _setEllieSettings()
  }

  function setDefaultView (value) {
    ellie.defaultView = value
    _setEllieSettings()
  }

  function setTimeFormat (value) {

    device.timeFormat = value
    _setDeviceSettings()
  }

  function setStartPageWidget (value) {

    device.startPageWidget = value
    _setDeviceSettings()
  }

  function setCloudEnvironment (value) {

    device.cloudEnvironment = value
    _setDeviceSettings()
  }

  function setScreenOrientation (value) {

    device.screenOrientation = value
    _setDeviceSettings()
  }

  function setDefaultWeather (value, core) {
    const projectId = core?.basServer?._cid

    if (projectId !== null) {

      // Create if key doesn't exist yet
      if (!device.projects) {
        device.projects = {}
      }

      device.projects[projectId] = { defaultWeather: value }
      _setDeviceSettings()
    }
  }

  // endregion

  function _setDeviceSettings () {

    BasStorage.set(BAS_PREFERENCES.K_DEVICE, device)
  }

  function _setEllieSettings () {

    BasStorage.set(BAS_PREFERENCES.K_ELLIE, ellie)
  }
}
