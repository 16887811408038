'use strict'

angular
  .module('basalteApp')
  .constant('STATES', {
    CONNECT: 'connect',
    CONNECT_UNSUPPORTED: 'connect.unsupported',
    CONNECT_LIVE: 'connect.live',
    CONNECT_LIVE_LOGIN: 'connect.live.login',
    CONNECT_LIVE_REGISTER: 'connect.live.register',
    CONNECT_LIVE_VERIFY: 'connect.live.verify',
    CONNECT_LIVE_FORGOT: 'connect.live.forgot',
    CONNECT_LIVE_CHANGE: 'connect.live.change',
    CONNECT_DISCOVERY: 'connect.discovery',
    CONNECT_PROFILES: 'connect.profiles',
    DEVICE_SETTINGS_ABSTRACT: 'connect.devicesettings',
    DEVICE_SETTINGS: 'connect.devicesettings.settings',
    DEVICE_SETTINGS_GENERAL: 'connect.devicesettings.settings.general',
    DEVICE_SETTINGS_LIVE: 'connect.devicesettings.settings.live',
    DEVICE_SETTINGS_NETWORK: 'connect.devicesettings.settings.network',
    DEVICE_SETTINGS_DOOR_PHONE: 'connect.devicesettings.settings.doorphone',
    DEVICE_SETTINGS_ABOUT: 'connect.devicesettings.settings.about',
    MAIN: 'main',
    HOME: 'main.home',
    SCENES: 'main.scenes',
    SCENE: 'main.scenes.scene',
    SCENE_PRESETS: 'main.scenes.scene.presets',
    SCENE_PICTURE: 'main.scenes.scene.presets.picture',
    SCENE_ROOMS: 'main.scenes.scene.rooms',
    SCENE_ROOM_FUNCTIONS: 'main.scenes.scene.rooms.functions',
    SCENE_STEP: 'main.scenes.scene.step',
    SCENE_STEP_LIGHTS: 'main.scenes.scene.step.lights',
    SCENE_STEP_DEVICES: 'main.scenes.scene.step.devices',
    SCENE_STEP_SHADES: 'main.scenes.scene.step.shades',
    SCENE_STEP_SCENES: 'main.scenes.scene.step.scenes',
    SCENE_STEP_THERMOSTAT_PICKER: 'main.scenes.scene.step.thermostat',
    SCENE_STEP_THERMOSTAT: 'main.scenes.scene.step.thermostat.thermostat',
    SCENE_STEP_MUSIC: 'main.scenes.scene.step.music',
    SCENE_STEP_MUSIC_FAVOURITES: 'main.scenes.scene.step.music.favourites',
    SCENE_STEP_VIDEO: 'main.scenes.scene.step.video',
    SCENE_STEP_VIDEO_FAVOURITES: 'main.scenes.scene.step.video.favourites',
    ENERGY: 'main.energy',
    ENERGY_DETAIL: 'main.energy.detail',
    SCHEDULES: 'main.schedules',
    SCHEDULE: 'main.schedules.schedule',
    SCHEDULE_SCENES: 'main.schedules.schedule.scenes',
    SCHEDULE_TIMING: 'main.schedules.schedule.timing',
    DOORS: 'main.doors',
    ROOMS: 'main.rooms',
    ROOM: 'main.rooms.room',
    LIGHTS: 'main.rooms.room.lights',
    GENERIC_DEVICES: 'main.rooms.room.genericdevices',
    GENERIC_DEVICE: 'main.rooms.room.genericdevice',
    SHADES: 'main.rooms.room.shades',
    THERMOSTAT: 'main.rooms.room.thermostat',
    THERMOSTAT_WEEK_SCHEDULER: 'main.rooms.room.thermostat.week',
    THERMOSTAT_DAY_SCHEDULER: 'main.rooms.room.thermostat.week.day',
    MUSIC_A: 'main.rooms.room.music',
    MUSIC: 'main.rooms.room.music.media',
    MUSIC_PLAYER: 'main.rooms.room.music.media.player',
    MUSIC_SOURCES: 'main.rooms.room.music.media.sources',
    MUSIC_FAVOURITES: 'main.rooms.room.music.media.favourites',
    MUSIC_LIBRARY: 'main.rooms.room.music.media.library',
    MUSIC_LIBRARY_LOCAL: 'main.rooms.room.music.media.library.local',
    MUSIC_LIBRARY_RADIO: 'main.rooms.room.music.media.library.radio',
    MUSIC_LIBRARY_DEEZER: 'main.rooms.room.music.media.library.deezer',
    MUSIC_LIBRARY_SPOTIFY: 'main.rooms.room.music.media.library.spotify',
    MUSIC_LIBRARY_TIDAL: 'main.rooms.room.music.media.library.tidal',
    VIDEO_A: 'main.rooms.room.video',
    VIDEO: 'main.rooms.room.video.media',
    VIDEO_PLAYER: 'main.rooms.room.video.media.player',
    VIDEO_SOURCES: 'main.rooms.room.video.media.sources',
    VIDEO_FAVOURITES: 'main.rooms.room.video.media.favourites',
    ROOM_SCENES: 'main.rooms.room.scenes',
    ROOM_SCENE: 'main.rooms.room.scenes.scene',
    ROOM_SCENE_PRESETS: 'main.rooms.room.scenes.scene.presets',
    ROOM_SCENE_PICTURE: 'main.rooms.room.scenes.scene.presets.picture',
    ROOM_SCENE_FUNCTIONS: 'main.rooms.room.scenes.scene.functions',
    ROOM_SCENE_STEP: 'main.rooms.room.scenes.scene.step',
    ROOM_SCENE_STEP_LIGHTS: 'main.rooms.room.scenes.scene.step.lights',
    ROOM_SCENE_STEP_DEVICES: 'main.rooms.room.scenes.scene.step.devices',
    ROOM_SCENE_STEP_SHADES: 'main.rooms.room.scenes.scene.step.shades',
    // Disabled because of LGC-5277, scene loop
    // ROOM_SCENE_STEP_SCENES: 'main.rooms.room.scenes.scene.step.scenes',
    ROOM_SCENE_STEP_THERMOSTAT_PICKER:
      'main.rooms.room.scenes.scene.step.thermostat',
    ROOM_SCENE_STEP_THERMOSTAT:
      'main.rooms.room.scenes.scene.step.thermostat.thermostat',
    ROOM_SCENE_STEP_MUSIC: 'main.rooms.room.scenes.scene.step.music',
    ROOM_SCENE_STEP_MUSIC_FAVOURITES:
      'main.rooms.room.scenes.scene.step.music.favourites',
    ROOM_SCENE_STEP_VIDEO: 'main.rooms.room.scenes.scene.step.video',
    ROOM_SCENE_STEP_VIDEO_FAVOURITES:
      'main.rooms.room.scenes.scene.step.video.favourites',
    ALARMS: 'main.alarms',
    ALARMS_ALARM: 'main.alarms.alarm',
    TIMERS: 'main.timers',
    TIMER_WEEK: 'main.timers.week',
    TIMER_DAY: 'main.timers.week.day',
    SETTINGS: 'main.settings',
    SETTINGS_GENERAL: 'main.settings.general',
    SETTINGS_GENERAL_LISA_ORDER: 'main.settings.general.lisa-order',
    SETTINGS_LIVE: 'main.settings.live',

    SETTINGS_MUSIC: 'main.settings.music',
    SETTINGS_MUSIC_STREAMING: 'main.settings.music.streaming',
    SETTINGS_MUSIC_STREAMING_DEEZER: 'main.settings.music.streaming.deezer',
    SETTINGS_MUSIC_STREAMING_TIDAL: 'main.settings.music.streaming.tidal',
    SETTINGS_MUSIC_STREAMING_SPOTIFY_WEB:
      'main.settings.music.streaming.spotifyweb',
    SETTINGS_MUSIC_DEFAULT_ROOMS: 'main.settings.music.defaultrooms',
    SETTINGS_MUSIC_DEFAULT_ROOMS_ROOMS:
      'main.settings.music.defaultrooms.rooms',
    SETTINGS_MUSIC_KNX_PRESETS: 'main.settings.music.knxpresets',
    SETTINGS_MUSIC_KNX_PRESETS_PRESETS:
      'main.settings.music.knxpresets.presets',
    SETTINGS_MUSIC_KNX_PRESETS_PRESET:
      'main.settings.music.knxpresets.presets.preset',

    SETTINGS_PROJECT: 'main.settings.project',
    SETTINGS_PROJECT_PROJECT_IMAGE: 'main.settings.project.projectImage',
    SETTINGS_PROJECT_ROOM_IMAGES: 'main.settings.project.roomImages',
    SETTINGS_PROJECT_ROOM_IMAGES_ROOM: 'main.settings.project.roomImages.room',

    SETTINGS_DOOR_PHONE: 'main.settings.doorphone',
    SETTINGS_CAMERAS: 'main.settings.cameras',
    SETTINGS_NETWORK: 'main.settings.network',
    SETTINGS_ABOUT: 'main.settings.about',
    CAMERAS: 'main.cameras',
    CAMERAS_DETAIL: 'main.cameras.detail',
    WEATHER: 'main.weather',
    SECURITY: 'main.security',
    CALL_HISTORY: 'main.callhistory',
    CALL_HISTORY_DETAIL: 'main.callhistory.detail',
    INTERCOM: 'main.intercom',
    LISA: 'main.lisa',
    LISA_TILES: 'main.lisa.tiles',
    LISA_SCENES: 'main.lisa.scenes',
    LISA_THERMOSTAT: 'main.lisa.thermostat',
    LISA_LIGHTS: 'main.lisa.lights',
    LISA_LIGHT_DETAIL: 'main.lisa.lights.detail',
    LISA_MUSIC: 'main.lisa.music',
    LISA_SHADES: 'main.lisa.shades',
    LISA_SHADE_DETAIL: 'main.lisa.shades.detail',
    LISA_CAMERAS: 'main.lisa.cameras',
    LISA_INTERCOM: 'main.lisa.intercom'
  })
