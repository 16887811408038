'use strict'

/* global dataBasalteApp */
/* eslint-disable vars-on-top, no-unused-vars */

// Include a polyfill for all stable ES features and Web Standards
import 'core-js/stable'

// Music App configuration
import basalteAppConfig from '../app-config.json'

// Git describe
import gitInfo from 'virtual:git-info'

// Required for Iro color picker to support older Android WebViews
import './app/polyfills/object_assign'

// region External dependencies

import iro from '@jaames/iro'

import qs from 'querystringify'
import urlParse from 'url-parse'
import * as swiper from 'swiper'
import moment from 'moment'
import 'moment/min/locales.min'
import 'moment-timezone'
import RangeSlider from '@basalte/range-slider'
import 'angular'
import 'angular-animate'
import 'angular-sanitize'
import 'angular-translate'
import 'angular-translate-interpolation-messageformat'
import '@uirouter/angularjs'
import 'angular-modal-service'
import 'hammerjs'
import './3rdparty/angular-hammer.min'
import './app/bas-slip/slip'
import './app/bas-slip/angular-slip'

// endregion

import basGlobalErrorInit from './app/errors/init.basglobalerror'

import basRemoteJsInit from './app/remotejs/init.basremotje'
import basFirebaseInit from './app/firebase/init.basfirebase'
import basSupportsInit from './app/bas-supports/init.bassupports'
import basStorageInit from './app/bas-storage/init.basstorage'
import basTelemetry from './app/telemetry/bastelemetry'

// region Internal Angular modules

// Bas components
import './app/bas-supports/bassupports.module'

import './app/errors/baserror.module'
import './app/remotejs/basremotejs.module'
import './app/components/bascache'
import './app/bas-storage/basstorage.module'
import './app/components/baslogger'
import './app/components/bascomponents'
import './app/components/basdevicepermissions'
import './app/components/basdevicecamera'
import './app/components/basdevicevolume'
import './app/components/basslider'
import './app/components/basscrub'
import './app/components/basswiper'
import './app/components/basstopswipepropagation'
import './app/components/basroundslider'
import './app/resources/basicons'
import './app/bas-marquee/basmarquee.module'
import './app/bas-image-transition/basimagetransition.module'
import './app/bas-blur/basblur.module'
import './app/bas-circle-progress/bas-circle-progress.module'
import './app/bas-compass/bas-compass.module'
import './app/bas-image-resize/basimageresize.module'
// Add swiper modules that we need
swiper.Swiper.use([
  swiper.Navigation,
  swiper.Pagination
])
// Reset locale to default en-US
moment.locale('en')

// endregion

// region Main module

// Application module
angular.module('basalteApp', [
  'ngAnimate',
  'ngSanitize',
  'pascalprecht.translate',
  'ui.router',
  'hmTouchEvents',
  'angularModalService',
  'basSupports',
  'basCache',
  'basError',
  'basIcons',
  'basRemoteJs',
  'basSlip',
  'basStorage',
  'basLogger',
  'basComponents',
  'basDevicePermissions',
  'basDeviceCamera',
  'basDeviceVolume',
  'basSlider',
  'basVideo',
  'basRoundSlider',
  'basScrub',
  'basSwiper',
  'basStopSwipePropagation',
  'basMarquee',
  'basImageTransition',
  'basBlur',
  'basCircleProgress',
  'basCompass',
  'basFileSelector',
  'basFileDropZone',
  'basImageResize'
])

// endregion

angular
  .module('basalteApp')
  .constant('APP_CONFIG', basalteAppConfig)
  .constant('BAS_BUILD', dataBasalteApp)
  .constant('GIT_INFO', gitInfo)
  .constant('BAS_API', require('basalte-core-js'))
  .service('Iro', function () { return iro })
  .service('Querystringify', function () { return qs })
  .service('urlParse', function () { return urlParse })
  .service('moment', function () { return moment })
  .service('Swiper', function () { return swiper.Swiper })
  .service('RangeSlider', function () { return RangeSlider })
  .config(['$provide', function ($provide) {
    $provide.decorator(
      '$exceptionHandler',
      [
        '$window',
        '$delegate',
        function ($window, $delegate) {
          return function (exception, cause) {

            $window['Sentry']?.captureException(exception)

            $delegate(exception, cause)
          }
        }
      ]
    )
  }])

// region Angular objects

// Constants

require('./app/util/baserrors.constant')
require('./app/basapp.constant')
require('./app/resources/html.constant')
require('./app/util/basintl.constant')
require('./app/languages/baslanguages.constant')
require('./app/util/ui.helper.constant')
require('./app/state/states.constant')
require('./app/live/basliveaccount.constant')
require('./app/scenes/scene.states.constant')
require('./app/media/media.states.constant')
require('./app/state/basstate.constant')
require('./app/ellie/basellie.constant')
require('./app/splash/bassplash.constant')
require('./app/main/basmain.constant')
require('./app/header/basappheader.constant')
require('./app/storage/basappstorage.constant')
require('./app/discovery/basdiscovery.constant')
require('./app/discovery/basdiscoverylocal.constant')
require('./app/discovery/basdiscoverycloud.constant')
require('./app/bas-core-api/bascore.constant')
require('./app/bas-core-api/current.bascore.constant')
require('./app/bas-core-api/bas-home.constant')
require('./app/bas-core-api/basapicore.constant')
require('./app/bas-core-api/basdemo.constant')
require('./app/connection/basconnection.constant')
require('./app/connection/basconnect.constant')
require('./app/app-profile/basappprofile.constant')
require('./app/home/bashomedashboard.constant')
require('./app/devices/basconnecteddevices.constant')
require('./app/util/baslibraryerrors.constant')
require('./app/util/basutilities.constant')
require('./app/library/template/baslibrary.constant')
require('./app/sources/bassource.constant')
require('./app/rooms/basrooms.constant')
require('./app/sources/bassources.constant')
require('./app/rooms/basroom.constant')
require('./app/scheduler/basscheduler.constant')
require('./app/thermostat/thermostat.constant')
require('./app/home/basmessages.constant')
require('./app/lights/baslight.constant')
require('./app/open_close/basopenclosedevices.constant')
require('./app/open_close/basopenclosedevice.constant')
require('./app/energy/basenergy.constant')
require('./app/devices/basdevice.constant')
require('./app/scenes/presets/basscenepresets.constant')
require('./app/now-playing/basplayerdrawer.constant')
require('./app/sources/favourites/basfavourite.constant')
require('./app/sources/tunein/bastunein.constant')
require('./app/sources/deezer/basdeezer.constant')
require('./app/sources/tidal/bastidal.constant')
require('./app/sources/favourites/basfavourites.constant')
require('./app/sources/spotify/basspotify.constant')
require('./app/sources/knxpresets/knxpresets.constant')
require('./app/sharedserverstorage/bassharedserverstorage.constant')
require('./app/modals/basmodal.constant')
require('./app/modals/basinputmodal.constant')
require('./app/util/basmessage.constant')
require('./app/scenes/basscene.constant')
require('./app/preferences/baspreferences.constant')
require('./app/camera/bascameras.constant')
require('./app/bas-units/bas-units.constant')
require('./app/weather/basweather.constant')
require('./app/resources/bassound.constant')
require('./app/volume/basvolume.constant')
require('./app/lisa/baslisa.constant')
require('./app/settings/music/streaming/basstreaming.constant')
require('./app/screen/basscreen.constant')
require('./app/discovery/basdiscoveredservices.constant')
require('./app/discovery/basstoredservers.constant')
require('./app/webserver/baswebserver.constant')
require('./app/sip/bassip.constant')
require('./app/room-dashboard/basmusicwidget.constant')
require('./app/library/local/baslocallibrary.constant')
require('./app/core-client/bascoreclient.constant')
require('./app/core-client/basupdater.constant')
require('./app/network/basnetwork.constant')
require('./app/media/videobutton.constant')
require('./app/media/playerstate.constant')
require('./app/time/bastime.constant')
require('./app/settings/settings.states.constants')
require('./app/music/dsp.constant')
require('./app/genericdevice/basgenericdevice.constant')
require('./app/call-history/callhistory.constant')
require('./app/security/security.constant')

// Factories

require('./app/languages/missing-translation-handler.factory')
require('./app/discovery/basdiscoveredcore.factory')
require('./app/discovery/baslocalcore.factory')
require('./app/discovery/bascloudcore.factory')
require('./app/modals/basinputmodalconfig.factory')
require('./app/modals/basmodalconfig.factory')
require('./app/tiles/bastile.factory')
require('./app/util/basinappbrowser.factory')
require('./app/util/basfilter.factory')
require('./app/util/bascollection.factory')
require('./app/util/basstring.factory')
require('./app/util/basuisortablehelper.factory')
require('./app/bas-core-api/bascorecontainer.factory')
require('./app/connection/basconnectinfo.factory')
require('./app/live/basliveproject.factory')
require('./app/storage/basstoredserver.factory')
require('./app/storage/basstoredserversettings.factory')
require('./app/storage/basstoreduser.factory')
require('./app/thermostat/scheduler/basthermostatscheduler.factory')
require('./app/lights/baslight.factory')
require('./app/scenes/bassceneheader.factory')
require('./app/shades/basshade.factory')
require('./app/scenes/basscenectrl.factory')
require('./app/scenes/basscene.factory')
require('./app/scenes/basstep.factory')
require('./app/scenes/presets/basscenepreset.factory')
require('./app/scheduler/entry.basscheduler.factory')
require('./app/scheduler/point.basscheduler.factory')
require('./app/scheduler/line.basscheduler.factory')
require('./app/scheduler/day.basscheduler.factory')
require('./app/scheduler/filter.basscheduler.factory')
require('./app/scheduler/type.point.basscheduler.factory')
require('./app/timers/point.bastimer.factory')
require('./app/thermostat/basthermostat.factory')
require('./app/thermostat/basthermostatcontrol.factory')
require('./app/thermostat/scheduler/day.basthermostatscheduler.factory')
require('./app/thermostat/scheduler/point.basthermostatscheduler.factory')
require('./app/weather/ui.basweather.factory')
require('./app/weather/basweatherexternal.factory')
require('./app/weather/basweatherstation.factory')
require('./app/rooms/basroom.factory')
require('./app/rooms/basroombuilding.factory')
require('./app/rooms/basroomfloor.factory')
require('./app/rooms/roomfilter.factory')
require('./app/rooms/basroomscomponent.factory')
require('./app/rooms/music.basroom.factory')
require('./app/rooms/video.basroom.factory')
require('./app/rooms/ellies.basroom.factory')
require('./app/rooms/lights.basroom.factory')
require('./app/rooms/scenes.basroom.factory')
require('./app/rooms/scheduler.basroom.factory')
require('./app/rooms/cameras.basroom.factory')
require('./app/rooms/doorphonegateways.basroom.factory')
require('./app/rooms/doorphones.basroom.factory')
require('./app/rooms/thermostats.basroom.factory')
require('./app/music/baseq.factory')
require('./app/rooms/shades.basroom.factory')
require('./app/rooms/energy.basroom.factory')
require('./app/rooms/genericdevices.basroom.factory')
require('./app/rooms/timers.basroom.factory')
require('./app/rooms/openclosedevices.basroom.factory')
require('./app/rooms/weatherstations.basroom.factory')
require('./app/camera/bascamera.factory')
require('./app/door_phone/basdoorphonegateway.factory')
require('./app/door_phone/basdoorphone.factory')
require('./app/scenes/scheduler/bassceneschedule.factory')
require('./app/devices/basdevice.factory')
require('./app/devices/basserverdevice.factory')
require('./app/app-profile/basappprofile.factory')
require('./app/energy/basenergymeter.factory')
require('./app/timers/bastimer.factory')
require('./app/timers/day.bastimer.factory')
require('./app/home/basmessage.factory')
require('./app/genericdevice/basgenericdevice.factory')
require('./app/genericdevice/basgenericdevicev1.factory')
require('./app/genericdevice/basgenericdevicev2.factory')
require('./app/genericdevice/basgenericdevicecontrol.factory')
require('./app/open_close/basopenclosedevice.factory')
require('./app/sources/bassource.factory')
require('./app/sources/state.bassource.factory')
require('./app/sources/favourites/favourites.bassource.factory')
require('./app/sources/favourites/basfavourite.factory')
require('./app/sources/deezer/deezer.bassource.factory')
require('./app/sources/playlists/playlists.bassource.factory')
require('./app/sources/queue/queue.bassource.factory')
require('./app/sources/defaultrooms/defaultrooms.bassource.factory')
require('./app/sources/tidal/tidal.bassource.factory')
require('./app/sources/spotify/basspotifyuri.factory')
require('./app/sources/playlists/basplaylist.factory')
require('./app/sources/tunein/tunein.bassource.factory')
require('./app/sources/spotify/spotify.bassource.factory')
require('./app/sources/knxpresets/knxpresets.bassource.factory')
require('./app/sources/knxpresets/basknxpreset.factory')
require('./app/sources/database/database.bassource.factory')
require('./app/library/playerlibrarystate.factory')
require('./app/library/selection.factory')
require('./app/library/template/header.bas-library.factory')
require('./app/library/template/detail.bas-library.factory')
require('./app/library/template/body.bas-library.factory')
require('./app/library/template/page.bas-library.factory')
require('./app/library/template/tab.bas-library.factory')
require('./app/library/template/collection.bas-library.factory')
require('./app/library/template/element.bas-library.factory')
require('./app/library/template/manager.bas-library.factory')
require('./app/library/local/page.local.factory')
require('./app/library/local/collection.local.factory')
require('./app/library/local/collection.playlist.local.factory')
require('./app/library/local/element.local.factory')
require('./app/library/local/manager.local.factory')
require('./app/library/deezer/element.deezer.factory')
require('./app/library/deezer/manager.deezer.factory')
require('./app/library/deezer/collection.deezer.factory')
require('./app/library/deezer/page.deezer.factory')
require('./app/library/radio/request.radio.factory')
require('./app/library/radio/element.radio.factory')
require('./app/library/radio/collection.radio.factory')
require('./app/library/radio/manager.radio.factory')
require('./app/library/spotify/page.spotify.factory')
require('./app/library/spotify/collection.spotify.factory')
require('./app/library/spotify/element.spotify.factory')
require('./app/library/spotify/manager.spotify.factory')
require('./app/library/tidal/manager.tidal.factory')
require('./app/library/tidal/page.tidal.factory')
require('./app/library/tidal/collection.tidal.factory')
require('./app/library/tidal/element.tidal.factory')
require('./app/bas-units/bas-unit-value.factory')
require('./app/discovery/basuicore.factory')
require('./app/core-client/bascoreclientdevice.factory')
require('./app/call-history/callhistoryentry.factory')
require('./app/security/security.alarm.factory')

// Services

require('./app/util/basutilities.service')
require('./app/util/basappdevice.service')
require('./app/util/basfullscreen.service')
require('./app/util/basappshortcuts.service')
require('./app/util/bassplashscreen.service')
require('./app/util/basstatusbar.service')
require('./app/util/baswidgets.service')
require('./app/util/basinsomnia.service')
require('./app/util/basintl.service')
require('./app/util/applink.service')
require('./app/util/baslocalisation.service')
require('./app/util/basnetwork.service')
require('./app/util/basapptemperature.service')
require('./app/state/basstate.service')
require('./app/tiles/ui.tiles.service')
require('./app/basapp.service')
require('./app/main/tomainstate.service')
require('./app/header/basappheader.service')
require('./app/header/controls.basappheader.service')
require('./app/header/back.controls.basappheader.service')
require('./app/music/state.music.service')
require('./app/music/basuiqueue.service')
require('./app/music/helper.basmessage.service')
require('./app/music/helper.basmusic.service')
require('./app/splash/bassplash.service')
require('./app/storage/basserverstorage.service')
require('./app/storage/helper.basstorage.service')
require('./app/live/basliveaccount.service')
require('./app/live/helper.baslive.service')
require('./app/live/tokenrevoked.baslive.service')
require('./app/connection/basliveconnect.service')
require('./app/connection/basconnect.service')
require('./app/connection/helper.basconnect.service')
require('./app/connection/bassmartconnect.service')
require('./app/connection/basserverrestart.service')
require('./app/connection/basapimismatch.service')
require('./app/connection/bascoreconnection.service')
require('./app/connection/states.bascoreconnection.service')
require('./app/bas-core-api/current.bascore.service')
require('./app/camera/bascameras.service')
require('./app/main/mainstartupstate.service')
require('./app/state/basstatehistory.service')
require('./app/state/reset.basstatehistory.service')
require('./app/state/state.coreclient.service')
require('./app/state/conditions.state.service')
require('./app/state/helper.basstate.service')
require('./app/state/helper.basconditions.service')
require('./app/bas-core-api/bascorehelper.service')
require('./app/bas-core-api/bascoreapihelper.service')
require('./app/discovery/basdiscovery.service')
require('./app/discovery/basdiscoverylocal.service')
require('./app/discovery/basdiscoverycloud.service')
require('./app/discovery/basstoredservers.service')
require('./app/devices/basconnecteddevices.service')
require('./app/scenes/state.scene.helper.service')
require('./app/music/state.media.helper.service')
require('./app/main/state.main.service')
require('./app/main/main.drawer.service')
require('./app/rooms/rooms.service')
require('./app/sources/sources.service')
require('./app/security/security.service')
require('./app/current-room/current.room.service')
require('./app/util/ui.helper.service')
require('./app/modals/modal.helper.service')
require('./app/library/librarystate.service')
require('./app/library/local/parser.local.service')
require('./app/library/local/library.service')
require('./app/library/local/helper.local.service')
require('./app/library/local/model.local.service')
require('./app/library/radio/helper.radio.service')
require('./app/library/deezer/model.deezer.library.service')
require('./app/library/deezer/helper.deezer.service')
require('./app/library/spotify/model.spotify.service')
require('./app/library/spotify/helper.spotify.service')
require('./app/library/tidal/model.tidal.service')
require('./app/library/tidal/helper.tidal.service')
require('./app/lights/lights.service')
require('./app/alarms/alarms.service')
require('./app/alarms/state.alarms.service')
require('./app/main/bascleanup.service')
require('./app/preferences/basprofilepreferences.service')
require('./app/preferences/basdevicepreferences.service')
require('./app/preferences/baspreferences.service')
require('./app/app-profile/bascurrentappprofile.service')
require('./app/modals/basmodal.service')
require('./app/modals/basinputmodal.service')
require('./app/modals/bascontextmodal.service')
require('./app/main/basbackground.service')
require('./app/scenes/presets/basscenespresets.service')
require('./app/scenes/presets/helper.basscenepresets.service')
require('./app/scenes/scheduler/state.basscenescheduler.service')
require('./app/timers/state.bastimers.service')
require('./app/rooms/ui.basroom.factory')
require('./app/scenes/basscenehelper.service')
require('./app/open_close/basopenclosedevices.service')
require('./app/core-client/bascoreclientstate.service')
require('./app/lisa/baslisa.ui.service')
require('./app/weather/weather.service')
require('./app/sources/bassourcehelper.service')
require('./app/sources/sourceshelper.service')
require('./app/sources/tidal/helper.bastidal.service')
require('./app/sources/favourites/helper.basfavourites.service')
require('./app/rooms/helper.rooms.service')
require('./app/rooms/helper.basrooms.service')
require('./app/home/bashomedashboard.service')
require('./app/scheduler/init.basscheduler.service')
require('./app/thermostat/init.basthermostat.service')
require('./app/scenes/state.scenes.service')
require('./app/home/basmessages.service')
require('./app/sharedserverstorage/bassharedserverstorage.service')
require('./app/settings/state.settings.service')
require('./app/settings/music/streaming/state.streaming.settings.service')
require('./app/settings/music/defaultrooms/state.defaultrooms.settings.service')
require('./app/settings/basnativesettings.service')
require('./app/settings/ellieip.service')
require('./app/rooms/state.rooms.service')
require('./app/room-dashboard/basmusicwidget.service')
require('./app/resources/basresource.service')
require('./app/intercom/helper.basintercom.service')
require('./app/energy/basenergy.service')
require('./app/energy/helper.basenergy.service')
require('./app/resources/bassound.service')
require('./app/volume/basvolume.service')
require('./app/time/bastime.service')
require('./app/basnetwork/basdevicenetwork.service')
require('./app/heartbeat/basheartbeat.service')
require('./app/discovery/basdiscoveredservices.service')
require('./app/sip/sip.basdevice.service')
require('./app/sip/bassip.service')
require('./app/screen/screen.service')
require('./app/basnetwork/basdevicenetwork.service')
require('./app/webserver/baswebserver.service')
require('./app/tidal/baslegacytidalcheck.service')
require('./app/lisa/baslisastate.service')
require('./app/core-client/helper.coreclient.service')
require('./app/core-client/basupdater.service')
require('./app/network/basnetworkconfig.service')
require('./app/core-client/bascoreclient.service')
require('./app/util/coverart.helper.service')
require('./app/library/local/helper.library.bassource.service')
require('./app/music/helper.playuri.service')
require('./app/media/basuiremote.service')
require('./app/media/helper.basvideo.service')
require('./app/media/state.video.service')
require('./app/media/helper.playerstate.service')
require('./app/util/bascommandqueue.service')
require('./app/settings/state.settings.helper.service')
require('./app/call-history/bascallhistory.service')
require('./app/audio-alert/basaudioalert.service')

// Controllers

require('./app/app.controller')
require('./app/live/live.controller')
require('./app/live/login/login.live.controller')
require('./app/live/register/register.live.controller')
require('./app/live/verify/verify.live.controller')
require('./app/live/forgot/forgot.live.controller')
require('./app/live/change/change.live.controller')
require('./app/discovery/discovery.controller')
require('./app/profiles/profiles.controller')
require('./app/main/main.controller')
require('./app/home/home.controller')
require('./app/room-dashboard/room-dashboard.controller')
require('./app/now-playing/now-playing.controller')
require('./app/media/remote.controller')
require('./app/rooms/rooms.controller')
require('./app/sources/sources.controller')
require('./app/favourites/favourites.controller')
require('./app/library/library.controller')
require('./app/library/local/local.library.controller')
require('./app/library/radio/radio.library.controller')
require('./app/library/deezer/deezer.library.controller')
require('./app/library/spotify/spotify.library.controller')
require('./app/library/tidal/tidal.library.controller')
require('./app/alarms/alarms.controller')
require('./app/settings/devicesettings.controller')
require('./app/settings/settings.controller')
require('./app/settings/general/general.settings.controller')
require('./app/settings/live/live.settings.controller')
require('./app/settings/about/about.settings.controller')
require('./app/settings/lisa/lisa.order.settings.controller')
require('./app/settings/music/deezer/deezer.settings.controller')
require('./app/settings/music/tidal/tidal.settings.controller')
require('./app/settings/music/defaultrooms/defaultrooms.settings.controller')
require(
  './app/settings/music/defaultrooms/rooms.defaultrooms.settings.controller'
)
require('./app/settings/music/spotify/spotify.settings.controller')
require('./app/settings/music/knxpresets/knxpresets.settings.controller')
require('./app/settings/music/knxpresets/presets.knxpresets.controller')
require('./app/settings/music/knxpresets/preset.knxpresets.controller')
require('./app/settings/music/streaming/streaming.settings.controller')
require('./app/settings/cameras/cameras.settings.controller')
require('./app/settings/music/music.settings.controller')
require('./app/settings/project/project.settings.controller')
require('./app/modals/connect.modal.controller')
require('./app/modals/template.modal.controller')
require('./app/modals/main.modal.controller')
require('./app/modals/mutedevice.modal.controller')
require('./app/modals/message.modal.controller')
require('./app/modals/context.modal.controller')
require('./app/modals/addserver.modal.controller')
require('./app/modals/addtoplaylist.modal.controller')
require('./app/modals/login.modal.controller')
require('./app/modals/login.profiles.modal.controller')
require('./app/modals/dsp.modal.controller')
require('./app/modals/text-input.modal.controller')
require('./app/modals/multi-screen-input.modal.controller')
require('./app/modals/time-input.modal.controller')
require('./app/modals/avroom-select.modal.controller')
require('./app/modals/number-input.modal.controller')
require('./app/modals/notification.modal.controller')
require('./app/audio-alert/audioalert.modal.controller')
require('./app/modals/bascontext.modal.controller')
require('./app/modals/settings.streamingservice.modal.controller')
require('./app/library/template/context.bas-library.controller')
require('./app/lights/lights.controller')
require('./app/shades/shades.controller')
require('./app/scenes/home.scenes.controller')
require('./app/scenes/scenes.controller')
require('./app/thermostat/thermostat.controller')
require('./app/thermostat/scheduler/week.thermostat.controller')
require('./app/thermostat/scheduler/day.thermostat.controller')
require('./app/music/music.controller')
require('./app/media/video.controller')
require('./app/modals/colorpicker.modal.controller')
require('./app/modals/scenepicker.modal.controller')
require('./app/modals/favouritespicker.modal.controller')
require('./app/modals/roomtypes.modal.controller')
require('./app/scenes/scene.basscenes.controller')
require('./app/scenes/shades/shades.detail.scene.controller')
require('./app/scenes/lights/lights.detail.scene.controller')
require('./app/scenes/scenes/scenes.scene.basscenes.controller')
require('./app/scenes/thermostat/thermostat.detail.scene.controller')
require('./app/scenes/thermostat/thermostat.picker.scene.controller')
require('./app/scenes/music/music.detail.scene.controller')
require('./app/scenes/music/music.favourites.detail.scene.controller')
require('./app/scenes/video/video.detail.scene.controller')
require('./app/scenes/video/video.favourites.detail.scene.controller')
require('./app/scenes/room.scenes.controller')
require('./app/energy/energy.controller')
require('./app/energy/detail.energy.controller')
require('./app/scenes/scheduler/schedules.bassceneschedule.controller')
require('./app/scenes/scheduler/schedule.bassceneschedule.controller')
require('./app/modals/serverstatus.modal.controller')
require('./app/room-dashboard/basmusicwidget.controller')
require('./app/modals/shade.modal.controller')
require('./app/timers/timers.controller')
require('./app/timers/week.timers.controller')
require('./app/timers/day.timers.controller')
require('./app/camera/cameras.controller')
require('./app/camera/detail.cameras.controller')
require('./app/genericdevice/genericdevices.controller')
require('./app/thermostat/detail.thermostat.controller')
require('./app/scenes/genericdevice/generic.device.scene.controller')
require('./app/open_close/openclosedevices.controller')
require('./app/weather/weather.controller')
require('./app/security/security.controller')
require('./app/call-history/callhistory.controller')
require('./app/call-history/detail.callhistory.controller')
require('./app/modals/roomimagepicker.modal.controller')
require('./app/modals/number-input-security.modal.controller')
require('./app/settings/project/roomimages/roomimages.settings.controller')
require('./app/settings/project/roomimages/roomimages.room.settings.controller')
require('./app/settings/project/image/image.project.settings.controller')
require('./app/lisa/lisa.controller')
require('./app/lisa/detail-pages/lisa.lights.controller')
require('./app/lisa/detail-pages/lisa.light.details.controller')
require('./app/lisa/detail-pages/lisa.music.controller')
require('./app/lisa/detail-pages/lisa.scenes.controller')
require('./app/lisa/detail-pages/lisa.thermostat.controller')
require('./app/lisa/detail-pages/lisa.shades.controller')
require('./app/lisa/detail-pages/lisa.shade.details.controller')
require('./app/lisa/detail-pages/lisa.cameras.controller')
require('./app/lisa/detail-pages/lisa.intercom.controller')
require('./app/lisa/lisa.tiles.controller')

// Components

require('./app/thermostat/detail.thermostat.component')
require('./app/thermostat/detail.thermostat.scene.component')
require('./app/thermostat/detail.tabs.thermostat.component')
require('./app/thermostat/detail.slider.thermostat.component')
require('./app/thermostat/detail.settings.thermostat.component')

// Directives

require('./app/components/infinite-scroll.directive')
require('./app/components/basimagesize.directive')
require('./app/components/bascolorpicker.directive')
require('./app/components/bascolortemperatureslider.directive')
require('./app/components/bashm.directive')
require('./app/components/basselectactivehighlight.directive')
require('./app/components/basnetworkdebug.directive')
require('./app/util/bas-click.directive')
require('./app/util/bas-collapsible.directive')
require('./app/util/bas-multi-click.directive')
require('./app/util/baspressinput.directive')
require('./app/util/ui.helper.directive')
require('./app/util/basmessage.directive')
require('./app/bas-input/bas-input.directive')
require('./app/bas-input/bas-button-input.directive')
require('./app/splash/bassplash.directive')
require('./app/profiles/basprofilelogin.directive')
require('./app/header/basappheader.directive')
require('./app/rooms/basroomcollection.directive')
require('./app/rooms/basroomname.directive')
require('./app/music/bassourcename.directive')
require('./app/music/basplayermessage.directive')
require('./app/media/basmediafooter.directive')
require('./app/now-playing/mini.player.directive')
require('./app/now-playing/basqueue.directive')
require('./app/library/template/header.bas-library.directive')
require('./app/library/template/scroll.bas-library.directive')
require('./app/library/template/page.bas-library.directive')
require('./app/library/templateDirectives/baslibraryimage.directive')
require('./app/scheduler/main.basscheduler.directive')
require('./app/scheduler/entry.basscheduler.directive')
require('./app/scheduler/point.basscheduler.directive')
require('./app/library/template/focus.bas-library.directive')
require('./app/shades/shade.directive')
require('./app/lights/light.directive')
require('./app/thermostat/widget.thermostat.directive')
require('./app/components/bashm.directive')
require('./app/room-dashboard/basmusicwidget.directive')
require('./app/room-dashboard/basmusicwidgettitle.directive')
require('./app/home/bashomescroller.directive')
require('./app/home/bashomesizer.directive')
require('./app/home/bashomebannersizer.directive')
require('./app/home/bashomeweatherwidgetsize.directive')
require('./app/home/basmessagescarousel.directive')
require('./app/scenes/basscenes.directive')
require('./app/scenes/basstep.directive')
require('./app/energy/basenergychart.directive')
require('./app/genericdevice/genericdevice.directive')
require('./app/open_close/basopenclosedevice.directive')
require('./app/settingscomponents/baslive.devicesettings.directive')
require('./app/settingscomponents/language.devicesettings.directive')
require('./app/settingscomponents/temperature.devicesettings.directive')
require('./app/settingscomponents/fullscreen.devicesettings.directive')
require('./app/settingscomponents/statusbar.devicesettings.directive')
require('./app/settingscomponents/keepalive.devicesettings.directive')
require('./app/settingscomponents/volume.devicesettings.directive')
require('./app/settingscomponents/brightness.devicesettings.directive')
require('./app/settingscomponents/sleep.devicesettings.directive')
require('./app/settingscomponents/ellieip.devicesettings.directive')
require('./app/settingscomponents/elliesip.devicesettings.directive')
require('./app/settingscomponents/adelantesip.devicesettings.directive')
require('./app/settingscomponents/dsp.devicesettings.directive')
require('./app/settingscomponents/microphonegain.devicesettings.directive')
require('./app/settingscomponents/playbackgain.devicesettings.directive')
require('./app/settingscomponents/echocancellation.devicesettings.directive')
require('./app/settingscomponents/background.devicesettings.directive')
require('./app/settingscomponents/ellieproximity.devicesettings.directive')
require('./app/settingscomponents/elliedefaultview.devicesettings.directive')
require('./app/settingscomponents/cloudenvironment.devicesettings.directive')
require('./app/settingscomponents/elliemuteareaoncall.devicesettings.directive')
require('./app/settingscomponents/debug.devicesettings.directive')
require('./app/settingscomponents/remotejs.devicesettings.directive')
require('./app/settingscomponents/startupview.devicesettings.directive')
require('./app/settingscomponents/timeformat.devicesettings.directive')
require('./app/settingscomponents/baslivenotifications.directive')
require('./app/settingscomponents/lisastartpage.devicesettings.directive')
require('./app/settingscomponents/screenorientation.devicesettings.directive')
require('./app/settingscomponents/restartserver.settings.directive')
require('./app/settingscomponents/updatelibrary.settings.directive')
require('./app/settingscomponents/header.settings.directive')
require('./app/components/basvideo.directive')
require('./app/weather/basweathercarousel.directive')
require('./app/weather/basweathertopwidget.directive')
require('./app/components/basfileselector.directive')
require('./app/components/basfiledropzone.directive')
require('./app/imagepicker/basimagepicker.directive')
require('./app/imagepicker/room.basimagepicker.directive')
require('./app/imagepicker/scene.basimagepicker.directive')
require('./app/lisa/basgenericlistelement.directive')
require('./app/components/bastouchstart.directive')
require('./app/components/basscrollhide.directive')
require('./app/tiles/bastile.directive')
require('./app/tiles/time.tile.directive')
require('./app/tiles/thermostat.tile.directive')
require('./app/tiles/weather.tile.directive')
require('./app/media/basvideobuttongrids.directive')

// endregion

// region Main module configuration

angular.module('basalteApp')

  .config([
    'APP_CONFIG',
    'BAS_API',
    'LoggerProvider',
    function (
      APP_CONFIG,
      BAS_API,
      LoggerProvider
    ) {

      if (window['basalteCordova']) {

        // Logger - Cordova Plugin NSD
        if (
          window['basalteCordova']['nsd'] &&
          window['basalteCordova']['nsd']['setLog']
        ) {
          window['basalteCordova']['nsd']['setLog'](false)
        }

        // Logger - Ellie
        if (
          window['basalteCordova']['ellie'] &&
          window['basalteCordova']['ellie']['setLog']
        ) {
          window['basalteCordova']['ellie']['setLog'](false)
        }

        // Logger - Cordova SIP
        if (
          window['basalteCordova']['basSip'] &&
          window['basalteCordova']['basSip']['setLog']
        ) {
          window['basalteCordova']['basSip']['setLog'](false)
        }
      }

      // Logger - Api
      BAS_API.Logger.useTimestamp = true
      BAS_API.Logger.customTag = 'API'
      BAS_API.Logger.useColor = true

      // Logger - Angular App
      LoggerProvider.enableTimestamp(true)
      LoggerProvider.setCustomTag('APP')
      LoggerProvider.enableTypeTag(false)
      LoggerProvider.setStyle('color: blue')
    }
  ])

  .config([
    '$compileProvider',
    '$locationProvider',
    '$anchorScrollProvider',
    'BasStorageProvider',
    'BasCacheProvider',
    function (
      $compileProvider,
      $locationProvider,
      $anchorScrollProvider,
      BasStorageProvider,
      BasCacheProvider
    ) {

      $compileProvider.debugInfoEnabled(false)
      $compileProvider.onChangesTtl(10)
      $compileProvider.commentDirectivesEnabled(false)
      $compileProvider.cssClassDirectivesEnabled(false)

      $locationProvider.html5Mode({
        enabled: false,
        requireBase: true,
        rewriteLinks: true
      })

      $anchorScrollProvider.disableAutoScrolling()

      BasStorageProvider.setPrefix('BASALTE')

      BasCacheProvider.setPrefix('BASALTE')
    }
  ])

  .config([
    '$sceDelegateProvider',
    'BAS_TUNE_IN',
    'BAS_DEEZER',
    function (
      $sceDelegateProvider,
      BAS_TUNE_IN,
      BAS_DEEZER
    ) {
      $sceDelegateProvider.trustedResourceUrlList([
        'self',
        BAS_TUNE_IN.BASE_URL + '/**',
        BAS_DEEZER.BASE_URL + '/**'
      ])
    }
  ])

require('./app/state/state.config')
require('./app/languages/languages.constant')
require('./app/languages/languages.config')

// endregion

// region Main module run

angular.module('basalteApp')

  .run([
    'BAS_SCREEN',
    'BasTime',
    'BasApp',
    'BasIcons',
    'BasState',
    'BasStateHistory',
    'BasStateHistoryReset',
    'StatesBasCoreConnection',
    'ToMainState',
    'MainState',
    'MusicState',
    'VideoState',
    'ScenesState',
    'SettingsState',
    'StreamingSettingsState',
    'DefaultRoomsSettingsState',
    'RoomsState',
    'BasSceneSchedulerState',
    'BasTimersState',
    'AlarmsState',
    'CurrentRoom',
    'BasNetwork',
    'BasAppDevice',
    'BasIntl',
    'BasScreen',
    'BasServerStorage',
    'BasCoreClient',
    'BasLisaState',
    'BasCoreClientDefaultState',
    'BasHeartbeat',
    'BasWebServer',
    'BasSourceHelper',
    'BasScenesPresets',
    'BasSchedulerInit',
    'BasThermostatInit',
    'Weather',
    'BasPreferences',
    'BasVolume',
    'BasSharedServerStorage',
    'BasLegacyTidalCheck',
    'BasNetworkConfig',
    'BasCoreClientState',
    'BasCallHistory',
    'BasAudioAlert',
    'BasSip',
    'BasLiveTokenRevoked',
    'BasSplashScreen',
    'BasAppShortcuts',
    'BasWidgets',
    'Lights',
    'Logger',
    'BAS_API',
    function (
      BAS_SCREEN,
      BasTime,
      BasApp,
      BasIcons,
      BasState,
      BasStateHistory,
      BasStateHistoryReset,
      StatesBasCoreConnection,
      ToMainState,
      MainState,
      MusicState,
      VideoState,
      ScenesState,
      SettingsState,
      StreamingSettingsState,
      DefaultRoomsSettingsState,
      RoomsState,
      BasSceneSchedulerState,
      BasTimersState,
      AlarmsState,
      CurrentRoom,
      BasNetwork,
      BasAppDevice,
      BasIntl,
      BasScreen,
      BasServerStorage,
      BasCoreClient,
      BasLisaState,
      BasCoreClientDefaultState,
      BasHeartbeat,
      BasWebServer,
      BasSourceHelper,
      BasScenesPresets,
      BasSchedulerInit,
      BasThermostatInit,
      Weather,
      BasPreferences,
      BasVolume,
      BasSharedServerStorage,
      BasLegacyTidalCheck,
      BasNetworkConfig,
      BasCoreClientState,
      BasCallHistory,
      BasAudioAlert,
      BasSip,
      BasLiveTokenRevoked,
      BasSplashScreen,
      BasAppShortcuts,
      BasWidgets,
      Lights,
      Logger,
      BAS_API
    ) {
      // Injected services are created and run some initialization code

      if (BasAppDevice.isCoreClient()) {

        BasHeartbeat.start()

        BasScreen.setProximitySensitivity(
          BasAppDevice.isLisa()
            ? BAS_SCREEN.SENSITIVITY_HIGH
            : BAS_SCREEN.SENSITIVITY_MEDIUM
        )
      }

      if (BasAppDevice.isLena()) {

        // No burn-in or heat issues for Lena
        // Screen can stay on indefinitely

        BasCoreClient.stopScreenOnMonitor()
      }

      // Hide splashscreen after 10 seconds as fallback
      setTimeout(onTimeout, 10000)

      function onTimeout () {

        BasSplashScreen.hide()
      }

      const shouldLog = BasAppDevice.get().debugBuild
      BAS_API.Logger.level =
        shouldLog
          ? BAS_API.Logger.Logger.DEBUG
          : BAS_API.Logger.Logger.WARN
      Logger.setLevel(
        shouldLog
          ? Logger.DEBUG
          : Logger.WARN
      )
    }
  ])

// endregion

// region Cordova, Angular bootstrap

function onDeviceReady () {
  const Sentry = window['Sentry']

  if (Sentry) {
    if (basalteAppConfig.config !== 'debug') {
      Sentry.init({
        dsn: 'https://32a66116c8bd41d0a53d151b3c5dff65@o405048.ingest.sentry.io/4504673284063232',
        release: `be.basalte.musicapp@${basalteAppConfig.major + '.' +
                                        basalteAppConfig.minor + '.' +
                                        basalteAppConfig.patch + '.' +
                                        basalteAppConfig.build}`,
        beforeSend: function (event) {
          // Filter out UnhandledRejection errors that have no information
          const e = event?.exception?.values?.[0]
          if (e) {
            const t = 'UnhandledRejection'
            const m = 'Non-Error promise rejection captured with value:'
            if (e.type === t && e.value.startsWith(m)) {
              return null
            }
          }

          return event
        }
      })
    } else {
      // Init with empty DSN, which signals to Sentry that we want
      // to disable it.
      // Not initting results in runtime errors.
      Sentry.init({
        dsn: ''
      })
    }
  }

  window.basTModule.tDeviceReady = Date.now()

  basGlobalErrorInit.initBasGlobalError(window)
  basRemoteJsInit.initBasRemoteJs(window)
  basFirebaseInit.initBasFirebase(window)

  basSupportsInit.initBasSupports(window)

  basStorageInit.initBasStorageCordova(
    window,
    _onBasStorageInit
  )

  function _onBasStorageInit () {

    window.basTModule.tStorageReady = Date.now()

    basTelemetry.initBasTelemetry(
      window,
      dataBasalteApp,
      basalteAppConfig,
      gitInfo
    )

    startApp()
  }
}

function startApp () {

  window.angular.bootstrap(
    document,
    [
      'basalteApp'
    ],
    {
      strictDi: true
    }
  )
}

window.basTModule = {
  tJsStart: Date.now(),
  T_APP_START: 'APP_START',
  T_APP_STARTED: 'APP_STARTED',
  T_APP_RESUMED: 'APP_RESUMED',
  T_APP_INTERCOM: 'APP_INTERCOM',
  T_APP_SIP: 'APP_SIP',
  T_APP_IMAGE_PICKER: 'APP_IMAGE_PICKER',
  T_APP_IP_CAMERAS: 'APP_IP_CAMERAS',
  T_APP_UNHANDLED_REJECTION: 'APP_UNHANDLED_REJECTION',
  T_APP_ERROR: 'APP_ERROR',
  T_APP_CONNECTED_TO_SERVER: 'APP_CONNECTED_TO_SERVER',
  T_CORE_CLIENT_SERIAL: 'CORE_CLIENT_SERIAL'
}

// Start Angular app when Cordova Device Ready event fires
// noinspection JSUnresolvedFunction
document.addEventListener('deviceready', onDeviceReady, false)

// endregion
