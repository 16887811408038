{
  "12_hr": "12-часовой",
  "24_hr": "24-часовой",
  "24_hr_time": "24-часовой формат",
  "about": "Информация",
  "account": "Аккаунт",
  "account_exists": "Аккаунт существует",
  "account_exists_l1": "Этот аккаунт уже существует",
  "account_exists_l2": "Пожалуйста, выберите другой адрес электронной почты или войдите с этим адресом электронной почты.",
  "account_not_confirmed": "Аккаунт не подтвержден",
  "account_not_confirmed_l1": "Этот аккаунт еще не подтвержден.",
  "account_not_confirmed_l2": "Пожалуйста, проверьте ваш почтовый ящик для подтверждения по электронной почте.",
  "account_not_confirmed_l3": "Подтвердить этот аккаунт?",
  "act_in": "B",
  "activate": "Aктивировать",
  "active": "Текущее воспроизведение",
  "act_out": "ИЗ",
  "act_push": "НАЖАТЬ",
  "adaptive_brightness": "Адаптивная яркость",
  "add": "Добавлять",
  "add_favourite": "Добавить в избранное",
  "add_group": "Добавить группу",
  "add_home": "Добавить дом",
  "address": "Адрес",
  "add_to_fav": "Добавить в избранное",
  "add_to_playlist": "Добавить в плейлист",
  "add_to_queue": "Добавить в очередь",
  "air_conditioning": "Кондиционирование воздуха",
  "air_pressure": "Давление воздуха",
  "alarm": "Сигнализация",
  "alarm_at": "Будильник на",
  "alarm_away": "В отсутствии",
  "alarm_disarm": "Отключить",
  "alarm_good_night": "Доброй ночи",
  "alarm_missing_txt": "Не хватает одного или несколько полей",
  "alarm_not_selected_l1": "Будильник не установлен.",
  "alarm_not_selected_l2": "Пожалуйста, установите будильник.",
  "alarms": "Будильники",
  "album": "Альбом",
  "albums": "Альбомы",
  "alert": "Предупреждение",
  "all": "Все",
  "all_off": "Все выключено",
  "all_off_txt_l1": "Вы хотите выключить звук во всех комнатах.",
  "all_off_txt_l2": "Вы уверены, что хотите продолжить?",
  "all_on": "Включить все",
  "almost_done": "Почти готово.",
  "almost_done_txt_l1": "У вас еще нет избранных плейлистов или радиостанций.",
  "almost_done_txt_l2": "Добавьте любой плейлист/радио в избранное.",
  "already_linked": "Подключен к учетной записи Basalte",
  "already_verified": "Проверено",
  "alt_power": "Включить/выключить",
  "alt_user": "Пользователь",
  "application": "Приложение",
  "application_version": "Версия приложения",
  "app_update_required": "Необходимо обновить приложение",
  "app_update_required_android_l2": "Пожалуйста, обновите приложение в Play Store.",
  "app_update_required_ios_l2": "Пожалуйста, обновите приложение в App Store.",
  "app_update_required_l2": "Пожалуйста, обновите приложение.",
  "arming_the_alarm": "Включение сигнализации...",
  "artist": "Артист",
  "artists": "Артисты",
  "attention": "Внимание!",
  "audio_zones": "Аудио зоны",
  "author": "Автор",
  "average": "Средний",
  "back": "назад",
  "basalte_account": "Аккаунт Basalte",
  "basalte_info_l1": "С учетной записи Basalte вы можете контролировать свой дом удаленно.",
  "basalte_link_server_l1": "Подключить этот дом к учетную запись Basalte?",
  "basalte_link_server_l2": "Вы сможете управлять этим домом за пределами вашей локальной сети.",
  "basalte_unlink_server_l1": "Удалить вашу учетную запись с Basalte?",
  "basalte_unlink_server_l2": "Вы больше не можете контролировать ваш дом удаленно.",
  "bass": "Низкие частоты",
  "BE": "Бельгия",
  "beta": "бета",
  "bluetooth": "Bluetooth",
  "bluetooth_pairing_txt": "Вы теперь сможете подключить устройство через bluetooth.",
  "bospeaker": "B&O",
  "brightness": "Яркость",
  "browse": "Поиск",
  "browse_deezer": "Избранное Deezer",
  "browse_library": "Библиотека",
  "browser_app_update_required_l2": "Перезагрузите страницу или обновите сервер.",
  "browse_stations": "Радиостанции",
  "buffering": "Буферизация",
  "building_building": "Здание",
  "building_garage": "Гараж",
  "building_main_house": "Дом",
  "building_pool_house": "Бассейн",
  "building_stables": "Конюшня",
  "cameras": "Камеры",
  "cancel": "Отменить",
  "categories": "Категории",
  "category": "Категория",
  "celsius": "Цельсию",
  "change_account": "Изменить учетную запись",
  "change_home": "Выберите дом",
  "change_image": "Изменить изображение",
  "change_password": "Сменить пароль",
  "change_password_l1": "Вы собираетесь изменить свой пароль.",
  "change_user": "Изменить профиль",
  "channel_number": "Номер канала",
  "charts": "Чарты",
  "check_for_updates": "Проверить наличие обновлений",
  "checking": "Проверка",
  "checking_for_updates": "Проверка обновлений",
  "choose_favourite": "Хотите выбрать любимого исполнителя?",
  "choose_home": "Выберите дом",
  "choose_music": "Хотите выбрать музыку?",
  "choose_preset": "Выберите пресеты",
  "choose_source": "Хотите выбрать источник звука?",
  "clear": "Очистить",
  "cleared": "Очищено",
  "clear_queue_txt_l1": "Вы хотите очистить очередь воспроизведения.",
  "clear_queue_txt_l2": "Вы уверены, что хотите продолжить?",
  "clear_selection": "Очистить подборку",
  "clear_selection_txt_l1": "Вы собираетесь очистить подборку.",
  "clear_selection_txt_l2": "Вы уверены, что хотите продолжить?",
  "close": "Закрыть",
  "closed": "Закрыто",
  "code": "Код",
  "color": "Цвет",
  "color_temperature": "Цветовая температура",
  "color_theme": "Цвет темы",
  "color_white": "Белый",
  "comfort": "Комфорт",
  "configure": "Конфигурировать",
  "confirmation_code_sent": "Код подтверждения отправлен",
  "confirmation_code_sent_l1": "Код подтверждения был отправлен на почту:",
  "confirm_continue": "Продолжить?",
  "connect": "Подключить",
  "connected": "Подключенный",
  "connecting": "Подключение",
  "connecting_small": "подключение",
  "connecting_stop_and_retry_text": "Не удалось подключиться к серверу. Повторная попытка через 10 секунд.",
  "connecting_stop_text": "Не удалось подключиться к серверу",
  "connecting_text": "Подключение…",
  "connecting_text_small": "подключение…",
  "connection_lost": "Нет подключения",
  "connection_lost_text": "Переподключение к серверу",
  "connection_unable": "Невозможно подключиться к указанному серверу.",
  "connect_offline_server_l1": "Этот дом привязан к сервису, но, похоже, не в сети.",
  "connect_offline_server_l2": "Все еще пытаетесь подключиться?",
  "connect_server": "Подключиться к серверу",
  "consumption": "Потребление",
  "continue_anyway": "Продолжить принудително",
  "continue_without_basalte_account": "Продолжить без учетной записи Basalte",
  "cover_art_background": "Обложка альбома",
  "current_password": "Действующий пароль",
  "current_temperature": "Текущий",
  "custom": "Режим \"Custom\"",
  "date": "Дата",
  "day": "День",
  "day_friday": "Пятница",
  "day_monday": "Понедельник",
  "day_saturday": "Суббота",
  "day_short2_friday": "Пт",
  "day_short2_monday": "Пн",
  "day_short2_saturday": "Сб",
  "day_short2_sunday": "Вс",
  "day_short2_thursday": "Чт",
  "day_short2_tuesday": "Вт",
  "day_short2_wednesday": "Ср",
  "day_sunday": "Воскресенье",
  "day_thursday": "Четверг",
  "day_tuesday": "Вторник",
  "day_wednesday": "Среда",
  "debug": "Отлаживать",
  "deezer": "Deezer",
  "deezer_disconnect_txt": "Вы собираетесь отключить учетную запись Deezer от устройства воспроизведения.",
  "deezer_favourite": "Избранное Deezer",
  "deezer_flow": "Поток",
  "deezer_flow_description": "Ваш саундтрек",
  "deezer_flow_full": "Поток Deezer",
  "deezer_no_link": "Учетная запись Deezer еще не привязана",
  "deezer_oauth_error": "Ошибка аутентификации Deezer",
  "default_rooms": "Комнаты по-умолчанию",
  "default_rooms_hint": "Чтобы функция in-home streaming корректно работала, убедитесь, чтобы по-умолчанию было выбрано не менее 1 комнаты.",
  "default_rooms_info_l1": "Комнаты по умолчанию для сторонних аудиопотоков (например, Spotify Connect).",
  "default_rooms_info_l2": "Активируйте нужные комнаты, если они не подключены к потоку.",
  "default_rooms_spotify_hint": "Чтобы функция Spotify Connect корректно работала, убедитесь, чтобы по-умолчанию было выбрано не менее 1 комнаты.",
  "default_rooms_spotify_warning_l1": "Установите по крайней мере одну комнату по-умолчанию для каждого потока",
  "default_rooms_spotify_warning_l2": "для завершения настройки.",
  "default_rooms_spotify_warning_title": "Spotify Connect теперь поддерживается",
  "default_view": "Вид по умолчанию",
  "delay": "Задержка",
  "delay_seconds_b": "Задержка (секунды)",
  "delete": "Удалить",
  "delete_account": "Удалить учетную запись",
  "demo_enter": "Демонстрационный режим",
  "demo_functionality_warning_txt": "Эта функция не поддерживается в демонстрационном режиме.",
  "demo_location": "Ghent",
  "demo_server_name": "Демо режим",
  "demo_title": "Демо",
  "demo_warning_txt": "Вы хотите включить демонстрационный режим.",
  "detail": "детали",
  "device": "Прибор",
  "devices": "Приборы",
  "device_settings": "Настройки устройства",
  "device_volume": "Громкость устройства",
  "dhcp": "DHCP",
  "direction_east": "Восток",
  "direction_east-northeast": "Восток-Северо-Восток",
  "direction_east-southeast": "Восток - Юго-Восток",
  "direction_north": "Север",
  "direction_northeast": "Северо-Восток",
  "direction_north-northeast": "Север - Северо-Восток",
  "direction_north-northwest": "Север - Северо-Запад",
  "direction_northwest": "Северо-Запад",
  "direction_short_east": "В",
  "direction_short_east-northeast": "ВСВ",
  "direction_short_east-southeast": "ВЮВ",
  "direction_short_north": "С",
  "direction_short_northeast": "СВ",
  "direction_short_north-northeast": "ССВ",
  "direction_short_north-northwest": "ССЗ",
  "direction_short_northwest": "СЗ",
  "direction_short_south": "Ю",
  "direction_short_southeast": "ЮВ",
  "direction_short_south-southeast": "ЮЮВ",
  "direction_short_south-southwest": "ЮЮЗ",
  "direction_short_southwest": "ЮЗ",
  "direction_short_west": "З",
  "direction_short_west-northwest": "ЗСЗ",
  "direction_short_west-southwest": "ЗЮЗ",
  "direction_south": "Юг",
  "direction_southeast": "Юго-Восток",
  "direction_south-southeast": "Юг - Юго-Восток",
  "direction_south-southwest": "Юг - Юго-Запад",
  "direction_southwest": "Юго-Запад",
  "direction_west": "Запад",
  "direction_west-northwest": "Запад - Северо-Запад",
  "direction_west-southwest": "Запад - Юго-Запад",
  "disband_group": "Расформировать группу",
  "dns": "DNS",
  "domain": "Домен",
  "done": "Готово",
  "do_not_disturb": "Не беспокоить",
  "do_not_disturb_off_l1": "Все звуковые уведомления в данный момент включены.",
  "do_not_disturb_on_l1": "Все звуковые уведомления в настоящее время отключены.",
  "do_not_show_again": "Больше не показывать",
  "door": "Дверь",
  "door_phone": "Домофон",
  "doors": "Двери",
  "down": "Вниз",
  "downloading": "идет загрузка",
  "downloading_updates": "Загрузка обновлений",
  "do_you_want_to_continue": "Вы хотите продолжить?",
  "drag_and_drop_images": "Выберите изображение или поместите его сюда",
  "drag_and_drop_lights": "Перетащите свет сюда",
  "drag_to_reorder": "Перетащите, чтобы изменить порядок",
  "dsp": "DSP",
  "dsp_configuration": "Конфигурация DSP",
  "dsp_profile_club": "Клуб",
  "dsp_profile_custom": "пользовательский",
  "dsp_profile_flat": "плоский",
  "dsp_profile_jazz": "джаз",
  "dsp_profile_live": "живой",
  "dsp_profile_pop": "поп",
  "dsp_profile_rock": "рока",
  "dsp_shortcut_l1": "Вы собираетесь открыть настройки DSP.",
  "echo_cancellation": "Эхо подавление",
  "echo_limit": "Эхо-лимит",
  "edit": "Редактировать",
  "edit_dns": "редактировать DNS",
  "edit_gateway": "Изменить шлюз",
  "edit_ip_address": "изменить IP-адрес",
  "edit_subnet_mask": "Изменить маска подсети",
  "ellie_room": "Комната Ellie",
  "ellie_system_update": "Ellie обновление системмы",
  "ellie_system_update_l1": "Для обновления, перезагрузите Ellie",
  "ellie_update": "Ellie обновление",
  "email": "Email Адрес",
  "empty": "Очистить",
  "empty_queue": "Очередь пустая",
  "empty_queue_txt_l1": "Кажется, у вас нет песен в очереди.",
  "empty_queue_txt_l2": "Добавьте несколько песен в очередь.",
  "empty_song": "Устройство воспроизведения остановлено",
  "empty_song_txt_l1": "Очередь воспроизведения закончилась.",
  "empty_song_txt_l2": "Нажмите старт для воспроизведения.",
  "empty_src": "Не выбрано ни одного источника",
  "empty_src_txt_l1": "Кажется, вы не выбрали источник воспроизведения.",
  "empty_src_txt_l2": "Сначала выберите источник воспроизведения.",
  "empty_zone": "Комната не выбрана",
  "empty_zone_txt_l1": "Кажется, вы не выбрали комнату",
  "empty_zone_txt_l2": "Сначала выберите комнату.",
  "energy": "Потребление энергии",
  "enter_keycode": "Введите ключевой код",
  "eq": "EQ",
  "error": "Ошибка",
  "external": "Внешний",
  "extra_permissions_required": "Для некоторых функций могут потребоваться дополнительные разрешения",
  "ext_src": "Внешний источник",
  "fahrenheit": "Фаренгейт",
  "failed": "Не удалось",
  "fan": "Вентилятор",
  "fan_auto": "Авто",
  "fan_high": "Высокая",
  "fan_low": "Низкая",
  "fan_medium": "Средняя",
  "fan_off": "Выкл",
  "favourite": "Избранное",
  "favourites": "Избранное",
  "favourite_scenes_full": "Любимые сцены заполнены",
  "favourite_scenes_full_sub": "Вы можете установить максимум 4 любимых сцены в комнате",
  "favourite_songs": "Любимые песни",
  "featured_playlists": "Избранные плейлисты",
  "floor_attic": "Чердак",
  "floor_basement": "Подвал",
  "floor_floor": "Пол",
  "floor_garden": "Сад",
  "floor_heating": "Теплый пол",
  "floor_loft": "Dachboden",
  "floor_lower": "Нижний этаж",
  "floor_lvl_0": "Первый этаж",
  "floor_lvl_1": "Второй этаж",
  "floor_lvl_2": "Третий этаж",
  "floor_lvl_3": "Четвёртый этаж",
  "floor_lvl_4": "Пятый этаж",
  "floor_lvl_5": "Шестой этаж",
  "floor_lvl_6": "Седьмой этаж",
  "floor_lvl_7": "Восьмой этаж",
  "floor_lvl_8": "Девятый этаж",
  "floor_lvl_9": "Десятый этаж",
  "floor_lvl_10": "Одиннадцатый этаж",
  "floor_lvl_11": "Двенадцатый этаж",
  "floor_lvl_12": "Тринадцатый этаж",
  "floor_lvl_13": "Четырнадцатый этаж",
  "floor_lvl_14": "Пятнадцатый этаж",
  "floor_lvl_15": "Шестнадцатый этаж",
  "floor_lvl_16": "Семнадцатый этаж",
  "floor_lvl_17": "Восемнадцатый этаж",
  "floor_lvl_18": "Девятнадцатый этаж",
  "floor_lvl_19": "Двадцатый этаж",
  "floor_lvl_20": "Двадцать первый этаж",
  "floor_upper": "Верхний этаж",
  "forgot_password": "Забыли пароль?",
  "frequency": "Частота",
  "full_screen": "Полноэкранный",
  "functions": "Функции",
  "gateway": "Шлюз",
  "general": "Общее",
  "generic_devices": "Девайсы",
  "generic_devices_appliances": "Приборы",
  "generic_devices_artwork": "Произведение искусства",
  "generic_devices_car_charger": "Автомобильное зарядное устройство",
  "generic_devices_energy": "Энергия",
  "generic_devices_fire_place": "Камин",
  "generic_devices_fountain": "Фонтан",
  "generic_devices_garage": "Гараж",
  "generic_devices_garden": "Сад",
  "generic_devices_irrigation": "Орошение",
  "generic_devices_pool": "Бассейн",
  "generic_devices_security": "Безопасность",
  "generic_devices_socket": "Розетка",
  "generic_devices_ventilation": "Вентиляция",
  "generic_devices_wellness_spa": "Оздоровительный центр и спа",
  "genre": "Жанр",
  "genre_moods": "Жанры и Настроения",
  "genres": "Жанры",
  "go_to_login_l1": "Войдите или зарегистрируйтесь с помощью учетной записи Basalte.",
  "go_to_manage_l1": "Управляйте своей учетной записью Basalte.",
  "group": "Группа",
  "groups": "Группы",
  "home": "Домой",
  "humidity": "влажность",
  "hz": "Hz",
  "images": "Картинки",
  "image_smaller_than_5mb": "Изображения должны быть менее 5 МБ",
  "in": "B",
  "incorrect_album": "Альбом не найден",
  "incorrect_artist": "Артист не найден",
  "install": "Установка",
  "installing": "Идет установка",
  "install_update": "Установить обновление",
  "install_updates": "Установить обновления",
  "integrator": "Интегратор",
  "integrator_access_allow": "Разрешить удаленную настройку",
  "intercom": "Интерком",
  "internal": "внутренний",
  "invalid_address": "Неправильный адрес",
  "invalid_code": "Неверный код",
  "invalid_code_l1": "Код подтверждения неверен.",
  "invalid_code_l2": "Убедитесь, что код правильный или запросите новый.",
  "invalid_input": "Неверная запись",
  "invalid_user": "Неправильное имя пользователя",
  "invalid_user_l1": "Убедитесь, что адрес электронной почты правильный.",
  "ios_plist_string_NSCameraUsageDescription": "Камера может быть использована для съемки комнат",
  "ios_plist_string_NSLocalNetworkUsageDescription": "Доступ к локальной сети можно использовать для поиска серверов, IP-камер, ...",
  "ios_plist_string_NSPhotoLibraryAddUsageDescription": "Библиотека может быть использована для хранения фотографий комнат",
  "ios_plist_string_NSPhotoLibraryUsageDescription": "Библиотека может быть использована для выбора фотографий комнат",
  "ip": "IP",
  "ip_address": "IP адрес",
  "join": "+ Добавить",
  "join_alt": "+ Добавить",
  "keep_awake": "Оставить iPad включенным",
  "keep_awake_device": "Оставить устройство включенным",
  "kelvin": "Кельвин",
  "Key": "ru_RU",
  "knx_presets": "Предварительные настройки KNX",
  "knx_presets_info": "Измените существующие пресеты KNX, чтобы воспроизвести что-то еще.",
  "language": "Язык",
  "language_system": "Язык устройства",
  "last-7-days": "Последние 7 дней",
  "last_hour": "Последний час",
  "last_room": "Последняя комната",
  "last_view": "Последняя страница",
  "last_week": "Последние 7 дней",
  "learn_more": "Подробнее",
  "leave_group": "Покинуть группу",
  "left": "Оставил",
  "lena_system_update": "Lena обновление системмы",
  "lena_system_update_l1": "Для обновления, перезагрузите Lena",
  "library": "Библиотека",
  "library_empty": "Библиотека пуста",
  "library_scanning": "Сканирование библиотеки",
  "light_accent_light": "Акцентный свет",
  "light_art_light": "Художественный свет",
  "light_cabinet": "Кабинет",
  "light_ceiling_mounted_fixture": "Потолочные светильники",
  "light_chandelier": "Люстра",
  "light_desk": "Стол",
  "light_down": "Вниз",
  "light_floor_lamp": "Торшер",
  "light_lampshade": "Абажур",
  "light_led": "LED подсветка",
  "light_led_strip": "LED полоса",
  "light_mirror": "Зеркало",
  "light_mood_light": "Освещение для настроения",
  "light_pendant": "Подвесной светильник",
  "light_reading_light": "Лампа для чтения",
  "lights": "Светильники",
  "lights_off_all": "Выключить весь свет",
  "light_spot": "Точечный светильник",
  "light_table_lamp": "Настольная лампа",
  "light_track": "Трек",
  "light_via": "Ночная подсветка",
  "light_wall_mounted_fixture": "Бра",
  "link": "Ссылка",
  "link_deezer_info_l1": "Свяжите учетную запись Deezer с потоком.",
  "link_deezer_info_l2": "Это позволит просматривать и воспроизводить медиафайлы Deezer в потоке.",
  "linked_homes": "Связанные дома",
  "linked_projects": "Связанные проекты",
  "linked_with": "связан с",
  "link_home": "Откройте приложение Basalte на локальном устройстве и привяжите свой дом в настройках.",
  "link_server": "Подключен к Basalte",
  "link_server_full_sub": "Свяжите этот проект со следующей учетной записью Basalte?",
  "link_spotify_info_l1": "Свяжите учетную запись Spotify с потоком.",
  "link_spotify_info_l2": "Это позволит просматривать и воспроизводить медиафайлы Spotify в потоке.",
  "link_tidal_info_l1": "Свяжите учетную запись TIDAL с потоком.",
  "link_tidal_info_l2": "Это позволит просматривать и воспроизводить медиафайлы TIDAL в потоке.",
  "lisa_system_update": "Lisa обновление системмы",
  "lisa_system_update_l1": "Для обновления, перезагрузите Lisa",
  "listen_in": "Слушать в",
  "liters": "литров",
  "live_adjustments": "Быстрые настройки",
  "loading": "Загрузка…",
  "local": "местный",
  "location": "Расположение",
  "location_back": "Назад",
  "location_bottom": "Внизу",
  "location_center": "Центр",
  "location_chair": "Кресло",
  "location_corner": "Угол",
  "location_desk": "Стол",
  "location_east": "Восток",
  "location_front": "Фронт",
  "location_high": "Высокая",
  "location_left": "Слева",
  "location_low": "Низкая",
  "location_mid": "Середина",
  "location_north": "Север",
  "location_right": "Правильно",
  "location_south": "Южная",
  "location_stove": "Плита",
  "location_table": "Таблица",
  "location_top": "Вверх",
  "location_west": "Запад",
  "logged_in_as": "Вы вошли как",
  "log_out": "Выйти",
  "log_out_everywhere": "Выйти из системы везде",
  "long-press-save-scene": "Длительно нажмите для сохранения сцены",
  "louver_30": "30 градусов",
  "louver_45": "45 градусов",
  "louver_60": "60 градусов",
  "louver_horizontal": "горизонтальный",
  "louver_mode": "режим жалюзи",
  "louver_off": "выключенный",
  "louver_swing": "качать",
  "louver_vertical": "вертикальный",
  "mac_address": "MAC-Адрес",
  "manage": "Управление",
  "manage_account": "Управление счетом",
  "manage_users": "Управление пользователями",
  "manual_conn": "Ручное подключение",
  "manual_text": "Пожалуйста, введите адрес нужного сервера.",
  "max": "Максимум",
  "menu": "Меню",
  "mf_device": "{count, plural, =0 {Прибор} =1 {Прибор} other {Приборы}}",
  "mf_feels_like": "По ощущениям {value}",
  "mf_minute": "{count} {count, plural, =1 {минута} other {минуты}}",
  "mf_powered_by": "Питание от {value}",
  "mf_second": "{count} {count, plural, =1 {секунда} other {секунды}}",
  "mf_server": "{count, plural, =0 {Сервер} =1 {Сервер} other {Серверы}}",
  "mf_song": "{count} {count, plural, =1 {песня} other {песни}}",
  "mf_source": "Источник {value}",
  "microphone_gain": "Усиление микрофона",
  "min": "Мин",
  "mix": "Перемешать",
  "mixed_sources": "Несколько источников",
  "mixed_sources_txt_l1": "Группа содержит комнаты с несколькими источниками.",
  "mixed_sources_txt_l2": "Выберите один источник.",
  "mixes": "Перемешать",
  "mixes_small": "перемешать",
  "mode": "Режим",
  "mode_auto": "Авто",
  "mode_cooling": "Охлаждение",
  "mode_heating": "Обогрев",
  "mode_off": "Off",
  "month": "Месяц",
  "month_april": "Апрель",
  "month_august": "Август",
  "month_december": "Декабрь",
  "month_february": "Февраль",
  "month_january": "Январь",
  "month_july": "Июль",
  "month_june": "Июнь",
  "month_march": "Март",
  "month_may": "Май",
  "month_november": "Ноябрь",
  "month_october": "Октябрь",
  "month_september": "Сентябрь",
  "month_short_april": "Апр",
  "month_short_august": "Авг",
  "month_short_december": "Дек",
  "month_short_february": "Фев",
  "month_short_january": "Янв",
  "month_short_july": "Июл",
  "month_short_june": "Июн",
  "month_short_march": "Мар",
  "month_short_may": "Май",
  "month_short_november": "Ноя",
  "month_short_october": "Окт",
  "month_short_september": "Сен",
  "mood": "Настроение",
  "moods": "Настроения",
  "music": "Музыка",
  "music_choice": "Выбор музыки",
  "music_off": "выключить музыку",
  "music_off_all": "Выключить все комнаты",
  "music_off_all_rooms": "Музыка выключена, все комнаты",
  "music_off_current": "Выключить текущую комнату",
  "music_off_current_room": "Музыка выключена, эта комната",
  "music_off_txt_l1": "Вы собираетесь отключить одну или все комнаты.",
  "music_off_txt_l2": "Вы действительно хотите продолжить?",
  "music_on_current": "Включить в этой комнате",
  "music_server": "Сервер музыки",
  "mute_area_on_call": "Приглушить музыку во время разговора",
  "my_favorites": "Моё избранное",
  "my_music": "Моя музыка",
  "name": "Имя",
  "nds": "Поиск серверов",
  "nds_music": "Поиск серверов музыки",
  "nds_none": "Серверы не были найдены!",
  "nds_none_music": "Сервера музыки не найдены!",
  "nds_text_l1": "Это может занять несколько секунд…",
  "nds_text_l2": "Убедитесь, что ваше устройство подключено к домашней сети Wi-Fi.",
  "network": "Cеть",
  "network_input_invalid": "Все поля ввода должны быть введены правильно",
  "never": "Никогда",
  "new": "Новое",
  "new_alarm": "Новый будильник",
  "new_image": "Новое изображение",
  "new_password": "Новый пароль",
  "new_password_confirmation": "Подтвердите новый пароль",
  "new_playlist": "новый плейлист…",
  "new_releases": "Новые версии",
  "new_releases_small": "новые версии",
  "new_scene": "Новый сценарий",
  "new_schedule": "Новое расписание",
  "new_step": "Следующий шаг",
  "next": "Следующий",
  "next_song": "следующая песня",
  "no": "Нет",
  "no_active_rooms": "Нет активных комнат",
  "no_all_off": "Нет, не выключать",
  "no_av": "Нет AV входа",
  "no_configuration": "Конфигурация недоступна",
  "no_content_available": "Нет доступных песен",
  "no_data": "Нет данных",
  "no_deezer_dis": "Нет, не отключать Deezer",
  "no_devices_capabilities": "Нет настраиваемых устройств",
  "no_doors_available": "Нет доступных дверей",
  "no_favourites": "Нет избранного",
  "no_keep_it": "Нет, сохранить",
  "no_lights_capabilities": "Не регулируемые светильники",
  "no_linked_homes": "Смежных домов не найдено.",
  "no_more_favourites": "Больше не нужно добавлять избранное",
  "none": "Нет",
  "no_network": "Нет подключения к сети",
  "no_preview_available": "Предварительный просмотр недоступен",
  "no_rooms_available": "Нет доступных комнат",
  "no_room_selected": "не выбрана комната",
  "no_scene": "Нет сцены",
  "no_scenes_favourites": "Нет избранных сцен",
  "no_scenes_txt": "Выберите сцены для отображения здесь",
  "no_search_results": "Нет результатов поиска",
  "no_servers": "Сервера не найдены",
  "no_shades_capabilities": "Не регулируемые жалюзи",
  "no_source": "- выключить -",
  "no_sources_available": "Нет доступных источников",
  "no_steps": "Эта сцена не содержит никаких шагов",
  "not_allowed": "Не допускается",
  "not_allowed_l1": "У вас нет разрешения для этого",
  "notification": "Уведомление",
  "notifications": "Уведомления",
  "not_linked": "Не привязана",
  "not_now": "Не сейчас",
  "not_set": "Не задано",
  "no_users_l1": "Пользователей не обнаружено.",
  "no_users_l2": "Выберите сервер с пользователями.",
  "number_of_songs": "песен",
  "off": "Выкл",
  "offline_server": "Автономный дом",
  "offset_minutes": "Смещение (минуты)",
  "ok": "ОК",
  "on": "включить",
  "onix_black": "Черный",
  "online": "онлайн",
  "only_png_jpeg_supported": "Поддерживаются только изображения PNG и JPEG.",
  "open": "Открыть",
  "open_close_door": "Дверь",
  "open_close_gate": "Ворота",
  "open_close_window": "Окно",
  "open_settings": "Открыть параметры",
  "open_spotify": "Открыть Spotify",
  "open_spotify_l1": "Вы собираетесь открыть Spotify",
  "open_state": "Oткрытый",
  "or": "Или",
  "order_cameras_by_holding_and_draging": "Переставьте камеры в нужном порядке",
  "other": "Другое",
  "out": "ИЗ",
  "pair": "Подключение",
  "password": "Пароль",
  "password_changed": "Пароль изменен",
  "password_confirmation": "Подтверждение пароля",
  "password_requirements": "Ваш пароль должен содержать не менее 8 символов и как минимум одну заглавную букву, одну строчную букву и одну цифру.",
  "permission_denied": "Доступ запрещен",
  "permission_denied_go_to_settings_l1": "Эта функция требует разрешения (ий) для правильной работы.",
  "permission_denied_go_to_settings_l2": "Разрешить приложению вносить изменения в настройки вашего устройства, чтобы использовать все функции.",
  "photo_from_camera": "Сфотографировать",
  "photo_from_library": "Выберите картинку из библиотеки",
  "pick_room_function": "Выберите комнату и функции",
  "play": "Воспроизвести",
  "playback_gain": "Усиление воспроизведения",
  "playing_in": "проигрывается в",
  "playlist": "Плейлист",
  "playlists": "Плейлисты",
  "playlists_itunes": "Плейлисты iTunes",
  "playlists_l": "плейлисты",
  "playlists_local": "Локальные плейлисты",
  "playlists_shared": "Общие плейлисты",
  "play_next": "Проигрывать следующую",
  "play_now": "Проигрывать сейчас",
  "please_fill_in_keycode": "Пожалуйста, введите ключевой код",
  "please_try_a_different_image": "Пожалуйста, попробуйте другую картинку",
  "please_try_again": "Пожалуйста, попробуйте снова",
  "popular": "Популярное",
  "position": "Позиция",
  "precipitation": "Атмосферные осадки",
  "previous": "Предыдущий",
  "project": "Проект",
  "project_image": "Изображение проекта",
  "proximity_activation": "Активация датчика приближения",
  "proximity_far": "Далекая",
  "proximity_medium": "Средняя",
  "proximity_near": "Близкая",
  "queue": "Очередь",
  "radio": "Радио",
  "radio_error": "Произошла ошибка при загрузке радиостанций",
  "radio_stations": "Радиостанции",
  "radio_stations_other": "Другие Станции",
  "recently_played": "Недавно воспроизведенные",
  "related_artists": "Похожие артисты",
  "reload": "Перезагрузка",
  "remember_me": "Запомнить меня",
  "remote": "Удаленный доступ",
  "remote_ellie_intercom_outdated": "Программное обеспечение Ellie устарело",
  "remote_ellie_intercom_outdated_l1": "Программное обеспечение Ellie, которой вы пытались позвонить, требует обновления.",
  "remote_ellie_intercom_outdated_l2": "Повторите попытку после обновления.",
  "remove": "Удалить",
  "remove_alarm": "Удалить будильник",
  "remove_basalte_favourite": "Удалить избранное Basalte",
  "remove_deezer_favourite": "Удалить избранное Deezer",
  "remove_from_fav": "удалить из избранного",
  "remove_schedule": "Удалить список",
  "remove_spotify_favourite": "Удаление избранного Spotify",
  "remove_spotify_preset": "Удалить настройки Spotify",
  "remove_tidal_favourite": "Удалить избранное TIDAL",
  "remove_txt_l1": "Вы собираетесь удалить этот элемент.",
  "remove_txt_l2": "Вы действительно хотите продолжить?",
  "rename": "переименовать",
  "repeat": "Повторить",
  "replace_queue": "Изменить очередь",
  "resend_code": "Отправить код еще раз",
  "resend_confirmation_code": "Отправить заново код подтверждения",
  "reset_image": "Сбросить изображение",
  "reset_password": "Сбросить пароль",
  "reset_password_l1": "Вы уверены, что хотите сбросить пароль?",
  "restart": "Повторный запуск",
  "restart_app": "Перезапустить",
  "restart_app_q": "Перезапустить?",
  "restarting_server": "Перезапуск сервера",
  "restart_music_server": "Перезапустить музыкальный сервер",
  "restart_music_server_txt_l1": "Вы собираетесь перезапустить музыкальный сервер.",
  "restart_server": "Перезапустите сервер",
  "restart_server_txt_l1": "Вы собираетесь перезапустить сервер.",
  "restart_server_txt_l2": "Приложение автоматически подключится.",
  "retrieving": "Получение…",
  "retrieving_users_stop_text": "Не удалось найти пользователей, изменить сервер?",
  "retrieving_users_text": "Поиск пользователей…",
  "right": "Право",
  "room": "Комната",
  "room_attic": "Чердак",
  "room_ball_room": "Танцевальный зал",
  "room_bar": "Бар",
  "room_basement": "Подвал",
  "room_bathroom": "Ванная комната",
  "room_bedroom": "Спальня",
  "room_corridor": "Коридор",
  "room_dining_room": "Столовая",
  "room_dressing_room": "Гардеробная",
  "room_driveway": "Въезд в гараж",
  "room_entry": "Вход",
  "room_family_room": "Семейный номер",
  "room_foyer": "Камин",
  "room_garage": "Гараж",
  "room_garden": "Сад",
  "room_guest_room": "Гостевой номер",
  "room_gym": "Тренажерный зал",
  "room_hallway": "Коридор",
  "room_hobby_room": "Мастерская",
  "room_home_cinema": "Домашний кинотеатр",
  "room_home_office": "Домашний офис",
  "room_images": "Изображения комнаты",
  "room_intercom_lost": "Соединение прервано",
  "room_intercom_lost_sub": "Соединение потеряно, попробуйте еще раз",
  "room_intercom_unavailable": "Комната недоступна",
  "room_intercom_unavailable_sub": "Эта комната в настоящее время недоступна",
  "room_intercom_unreachable": "Комната недоступна",
  "room_intercom_unreachable_sub": "Эта комната в настоящее время недоступна",
  "room_kitchen": "Кухня",
  "room_laundry_room": "Прачечная",
  "room_library": "Библиотека",
  "room_living_room": "Гостиная",
  "room_master_bedroom": "Хозяйская спальня",
  "room_media_room": "Комната для прослушивания музыки",
  "room_meeting_room": "Комната для переговоров",
  "room_nook": "Угол",
  "room_nursery": "Детская",
  "room_pantry": "Кладовая",
  "room_parking_place": "Парковочное место",
  "room_patio": "Веранда",
  "room_pool": "Бассейн",
  "room_pool_house": "Дом у бассейна",
  "room_rack": "Стеллаж",
  "room_room": "Комната",
  "rooms": "Комнаты",
  "room_scene_no_functions": "В этом номере нет функций",
  "room_settings": "Настройки комнаты",
  "room_spa": "СПА",
  "room_stable": "Cтабильный",
  "room_staircase": "Лестница",
  "room_terrace": "Терасса",
  "room_toilet": "Туалет",
  "room_utility_room": "Подсобное помещение",
  "room_wine_cellar": "Винный погреб",
  "room_workshop": "Рабочее помещение",
  "rotation": "Повторение",
  "save": "Сохранить",
  "save_as": "сохранить как",
  "save_basalte_favourite": "Сохранить избранное Basalte",
  "save_changes": "Сохранить изменения",
  "save_changes_l1": "Некоторые изменения еще не были сохранены",
  "save_changes_l2": "Вы хотите сохранить их сейчас?",
  "save_deezer_favourite": "Сохранить избранное Deezer",
  "save_spotify_favourite": "Сохранение избранного Spotify",
  "save_spotify_preset": "Сохранить настройки Spotify",
  "save_tidal_favourite": "Сохранить избранное TIDAL",
  "scene": "Сценарий",
  "scene_activated": "Сцена активирована",
  "scene_away": "В отсутствии",
  "scene_cooking": "Готовка",
  "scene_dinner": "Обед",
  "scene_error": "Ошибка сцены",
  "scene_evening": "Вечер",
  "scene_good_morning": "Доброе утро",
  "scene_good_night": "Доброй ночи",
  "scene_guests": "Гостиная",
  "scene_home": "Дом",
  "scene_image_error": "Ошибка изображения сцены",
  "scene_movie": "Фильм",
  "scene_not_available": "Нет доступных сцен.",
  "scene_not_selected_l1": "Сценарий не выбран",
  "scene_not_selected_l2": "Пожалуйста, выберите сценарий",
  "scene_party": "Вечеринка",
  "scene_relax": "Отдых",
  "scenes": "Сценарии",
  "scene_select_or_new": "Пожалуйста, создайте или выберите сценарий",
  "scene_vacation": "Отпуск",
  "scene_welcome": "Добро пожаловать",
  "scene_workout": "Тренировка",
  "schedule": "Таймер",
  "schedule_at": "Расписание на",
  "schedule_not_selected_l1": "Расписание не выбрано",
  "schedule_not_selected_l2": "Выберите расписание",
  "scheduler": "Планировщик",
  "schedules": "Расписания",
  "schedule_select_or_new": "Пожалуйста, создайте или выберите расписание",
  "screen_orientation": "Ориентация экрана",
  "search": "Найти",
  "search_again": "Найти снова",
  "search_ph": "найти артиста, песню или альбом…",
  "search_result": "Результаты поиска для",
  "security": "Безопасность",
  "select_admin": "Выберите профиль администратора, чтобы продолжить процесс обновления",
  "select_home": "Выберите свой дом",
  "selection": "Выбор",
  "selection_all": "добавить всё в выбранное",
  "select_photo": "Выберите изображение",
  "select_server": "Выбрать сервер",
  "select_user": "Выберите профиль для входа в систему",
  "serial_number": "Серийный номер",
  "server": "Сервер",
  "server_address": "Адрес сервера",
  "server_basalte_cloud_no_connection": "Сервер не может подключиться к Basalte Cloud",
  "server_exists_already": "Сервер уже существует!",
  "server_mac": "Адрес сервера MAC",
  "servers": "Серверы",
  "server_status": "Состояние сервера",
  "server_update_info_l2": "обновите сервер Basalte до последней версии.",
  "server_update_required": "Необходимо обновить сервер",
  "server_version": "Версия сервера",
  "set": "Установить",
  "set_new_password": "Установить новый пароль",
  "setpoint": "Предустановленное значение",
  "settings": "Настройки",
  "shade_blind": "Шторы",
  "shade_center": "Центр",
  "shade_curtain": "Штора",
  "shade_left": "Левая сторона",
  "shade_pool_shutter": "Жалюзи бассейна",
  "shade_right": "Правая сторона",
  "shade_roman_blind": "Римские шторы",
  "shades": "Жалюзи",
  "shade_shade": "Жалюзи",
  "shade_shutter": "Оконные ставни",
  "shade_solar_protection": "Защита от солнца",
  "shade_venetian_blind": "Жалюзи",
  "shade_vertical_blind": "Вертикальные жалюзи",
  "share": "поделиться",
  "shared_playlist": "Поделиться плейлистом",
  "show_album": "Показать альбом",
  "show_artist": "Показать артиста",
  "show_background_art": "Показывать фон",
  "show_more": "Показать больше…",
  "show_status_bar": "Показать панель состояния iPad",
  "show_status_bar_device": "Показать панель состояния",
  "sign_in": "Войти",
  "sign_in_info_tidal_l1": "Введите учетные данные пользователя TIDAL",
  "sign_up": "Зарегистрироваться",
  "single_room": "Комната",
  "sleep": "Спящий режим",
  "software_update": "Обновление программного обеспечения",
  "something_went_wrong": "Что-то пошло не так",
  "songs": "Песни",
  "sonos": "Sonos",
  "sound_settings": "Настройки звука",
  "source": "Источник",
  "sources": "Источники",
  "spotify": "Spotify",
  "spotify_beta": "Spotify (beta)",
  "spotify_connect": "Spotify Connect",
  "spotify_connect_instructions": "Spotify Connect",
  "spotify_connect_instructions_1": "Убедитесь, что Core сервер и устройство с приложением Spotify находятся в одной сети.",
  "spotify_connect_instructions_2": "Выберите поток Core сервер из выбора устройства в приложении Spotify.",
  "spotify_connect_instructions_body": "Вы можете слушать музыку на своих динамиках или на телевизоре. Вы используете свое устройство с приложением Spotify в качестве пульта дистанционного управления.",
  "spotify_connect_instructions_title": "Готов слушать музыку?",
  "spotify_disconnect_txt": "Вы собираетесь отключить учетную запись Spotify от этого проигрывателя.",
  "spotify_free_user_txt": "Spotify Премиум подписка не требуется",
  "spotify_no_link": "Учетная запись Spotify еще не подключена",
  "spotify_oauth_error": "Ошибка аутентификации Spotify",
  "spotify_preset_error": "Ошибка Spotify",
  "spotify_preset_error_l1": "Не удалось загрузить настройки Spotify.",
  "spotify_preset_error_l2": "Перед включением этого потока убедитесь, что учетная запись Spotify связана с этим потоком.",
  "spotify_presets": "Настройки Spotify",
  "start": "Старт",
  "startup_view": "Домашняя страничка",
  "startup_volume": "Громкость по умолчанию",
  "state_active": "Активный",
  "state_armed": "Поставлено на охрану",
  "state_closed": "Закрыто",
  "state_disabled": "Отключено",
  "state_disarmed": "Снято с охраны",
  "state_down": "Вниз",
  "state_enabled": "Включено",
  "state_false": "Не верно",
  "state_free": "Доступно",
  "state_high": "Высокий",
  "state_in": "В",
  "state_inactive": "Неактивный",
  "state_left": "Осталось",
  "state_low": "Низкие",
  "state_occupied": "Занятый",
  "state_off": "Выкл",
  "state_on": "Вкл",
  "state_opened": "Открыто",
  "state_out": "Из",
  "state_right": "Верно",
  "state_started": "Пуск",
  "state_stopped": "Стоп",
  "state_true": "Нет",
  "state_up": "Вверх",
  "static": "Статический",
  "stations": "Станции",
  "status": "Состояние",
  "stop": "Stop",
  "stream": "Поток",
  "streaming": "Потоковая передача",
  "streaming_services": "Потоковый сервис",
  "streams": "Потоки",
  "streams_beta": "Потоки (beta)",
  "subnet_mask": "Маска подсети",
  "sun": "сумма",
  "sunrise": "Восход солнца",
  "sunset": "Заход солнца",
  "swipe_to_pan": "Проведите для перемещения",
  "switch_server": "Сменить сервер",
  "synchronise": "Сканировать сейчас",
  "system": "Система",
  "system_setting": "Системные настройки",
  "temperature": "Температура",
  "temperature_unit": "Измерение температуры",
  "theme": "Темы",
  "thermostat": "Термостат",
  "this_cannot_be_undone": "Отменить это действие невозможно.",
  "this_week": "на этой неделе",
  "tidal": "TIDAL",
  "tidal_disconnect_txt": "Вы хотите отключиться от аккаунта TIDAL с этого устройства.",
  "tidal_legacy_auth": "Требуется вход в TIDAL",
  "tidal_legacy_auth_l1": "Чтобы продолжить использование TIDAL, пожалуйста, войдите снова.",
  "tidal_no_link": "Учетная запись TIDAL еще не подключена",
  "tidal_oauth_error": "Ошибка аутентификации TIDAL",
  "tidal_rising": "TIDAL Rising",
  "time": "Время",
  "time_format": "Формат времени",
  "timer": "Таймер",
  "timers": "Таймеры",
  "title": "Название",
  "today": "Сегодня",
  "tomorrow": "Завтра",
  "top_tracks": "Топ-треки",
  "treble": "Высокие частоты",
  "try_again": "Попробуйте снова",
  "turned_off": "Выключено",
  "turned_on": "Включено",
  "turn_off": "Выключить",
  "turn_on": "Включить",
  "turn_on_music": "Включи музыку",
  "turn_on_tv": "Включите телевизор",
  "tv": "ТВ",
  "unavailable": "Недоступно",
  "unavailable_src": "Источник не доступны",
  "unavailable_src_txt": "Текущий источник не доступен.",
  "ungroup": "Разгруппировать",
  "unknown": "Неизвестно",
  "unknown_account": "Аккаунт не найден",
  "unknown_account_l1": "Этот адрес электронной почты не является учетной записью Basalte.",
  "unknown_av": "Неизвестный AV вход",
  "unknown_src": "Неизвестный источник",
  "unknown_src_txt": "Текущий источник неизвестен этому серверу.",
  "unlink": "Удалить ссылку",
  "unlink_server": "Отключить учетной записи Basalte",
  "unlink_server_full_sub": "Отключить этот проект от учетной записи Basalte? Он больше не доступен за пределами вашей локальной сети.",
  "unreachable": "Недоступен",
  "unshare": "отменить общий доступ",
  "unsupported_browser": "Неподдерживаемый браузер",
  "unsupported_browser_l1": "Некоторые функции могут работать некорректно.",
  "unsupported_browser_l2": "Для лучшего использования используйте Chrome или Safari.",
  "unsupported_browser_live": "Этот браузер не поддерживает учетной записи Basalte. Попробуйте новую версию Chrome или Safari.",
  "up": "вверх",
  "update": "Обновить",
  "update_available": "Обновить",
  "update_available_full": "Доступно обновление",
  "update_info_l1": "Чтобы гарантировать лучший опыт использования,",
  "update_library": "Повторно сканировать музыкальную библиотеку",
  "update_music_server": "Обновить музыкальный сервер",
  "update_scene": "Обновление сцены",
  "update_scene_l1": "Сохранение текущего состояния для этой сцены.",
  "update_server": "Обновление сервера",
  "updating_server": "Обновление сервера",
  "updating_server_info": "Не отключать сервер",
  "up_to_date": "Своевременно",
  "username": "Имя пользователя",
  "uv": "ультрафиолетовый",
  "value_invalid": "Значение, данное для, недопустимо",
  "verify": "Проверьте",
  "verifying": "проверка",
  "version": "Версия",
  "video_background": "Заставка",
  "view_details": "Посмотреть детали",
  "volume": "Громкость",
  "warning": "Внимание",
  "water": "Вода",
  "weather": "Погода",
  "weather_daily_data_unavailable": "В настоящее время нет ежедневной информации о погоде",
  "weather_hourly_data_unavailable": "В настоящее время нет почасовой информации о погоде",
  "weather_information": "Информация о погоде",
  "week": "Неделя",
  "weekly": "Еженедельно",
  "what": "Что",
  "what_music": "Какая музыка",
  "when": "Когда",
  "where": "Где",
  "who": "Кто",
  "wind": "ветер",
  "window": "Окно",
  "windows": "Окна",
  "wrong_password": "Неверный пароль",
  "wrong_password_txt": "Введенный пароль не соответствует паролю пользователя",
  "wrong_username_and_or_password": "Неправильное имя пользователя и/или пароль",
  "year": "Год",
  "yes": "Да",
  "yes_all_off": "Да, выключить всё",
  "yes_cur_off": "Да, выключить текущее",
  "yes_deezer_dis": "Да, отключить аккаунт Deezer",
  "yes_delete_it": "Да, удалить",
  "yesterday": "Вчера",
  "zone": "Комната",
  "zones": "Комнаты"
}
