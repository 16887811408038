{
  "avSources": {
    "audio": {
      "sourceGeorge": {
        "reachable": true,
        "state": {
          "nowPlaying": {},
          "volume": 50,
          "mute": false,
          "playback": "playing"
        },
        "attributes": {
          "streamingService": [
            "deezer",
            "tidal",
            "spotify"
          ]
        },
        "name": "George",
        "type": "asano",
        "subType": "stream",
        "colour": "#00ff00",
        "uuid": "sourceGeorge"
      },
      "sourceJane": {
        "reachable": true,
        "state": {
          "nowPlaying": {},
          "volume": 50,
          "mute": false,
          "playback": "playing"
        },
        "attributes": {
          "streamingService": [
            "deezer",
            "tidal",
            "spotify"
          ]
        },
        "name": "Jane",
        "type": "asano",
        "subType": "stream",
        "colour": "#ff0000",
        "uuid": "sourceJane"
      },
      "sourceJudy": {
        "reachable": true,
        "state": {
          "nowPlaying": {},
          "volume": 50,
          "mute": false,
          "playback": "playing"
        },
        "attributes": {
          "streamingService": [
            "deezer",
            "tidal",
            "spotify"
          ]
        },
        "name": "Judy",
        "type": "asano",
        "subType": "stream",
        "colour": "#ffff00",
        "uuid": "sourceJudy"
      },
      "sourceElroy": {
        "reachable": true,
        "state": {
          "nowPlaying": {},
          "volume": 50,
          "mute": false,
          "playback": "playing"
        },
        "attributes": {
          "streamingService": [
            "deezer",
            "tidal",
            "spotify"
          ]
        },
        "name": "Elroy",
        "type": "asano",
        "subType": "stream",
        "colour": "#0000ff",
        "uuid": "sourceElroy"
      },
      "sourceTurntable": {
        "reachable": true,
        "state": {
          "nowPlaying": {},
          "volume": 50,
          "mute": false,
          "playback": "playing"
        },
        "attributes": {},
        "name": "Turntable Living Room",
        "uuid": "sourceTurntable",
        "type": "asano",
        "subType": "external"
      },
      "sourceIpodJudy": {
        "reachable": true,
        "state": {
          "nowPlaying": {},
          "volume": 50,
          "mute": false,
          "playback": "playing"
        },
        "attributes": {},
        "name": "iPod Dock Bedroom Judy",
        "uuid": "sourceIpodJudy",
        "type": "asano",
        "subType": "external"
      },
      "sourceIpodElroy": {
        "reachable": true,
        "state": {
          "nowPlaying": {},
          "volume": 50,
          "mute": false,
          "playback": "playing"
        },
        "attributes": {},
        "name": "iPod Dock Bedroom Elroy",
        "uuid": "sourceIpodElroy",
        "type": "asano",
        "subType": "external"
      },
      "sourceHomeCinemaTV": {
        "reachable": true,
        "state": {
          "volume": 52,
          "mute": false,
          "playback": "playing"
        },
        "type": "asano",
        "subType": "tv",
        "uuid": "sourceHomeCinemaTV",
        "followRoomNameUuid": "BasementHomeCinema"
      },
      "sourceMasterBedroomTV": {
        "reachable": true,
        "state": {
          "volume": 33,
          "mute": false,
          "playback": "playing"
        },
        "type": "asano",
        "subType": "tv",
        "uuid": "sourceMasterBedroomTV",
        "followRoomNameUuid": "FirstFloorMasterBedroom"
      },
      "sourceLivingRoomTV": {
        "reachable": true,
        "state": {
          "volume": 80,
          "mute": false,
          "playback": "playing"
        },
        "type": "asano",
        "subType": "tv",
        "uuid": "sourceLivingRoomTV",
        "followRoomNameUuid": "GroundFloorLivingRoom"
      }
    },
    "video": {
      "sourceHomeCinemaSetTopBox": {
        "uuid": "sourceHomeCinemaSetTopBox",
        "name": "Set-top box",
        "reachable": true,
        "capabilities": {
          "back": "x",
          "channelDown": "x",
          "channelUp": "x",
          "down": "x",
          "enter": "x",
          "favourites": {
            "list": "x"
          },
          "left": "x",
          "menu": "x",
          "mute": "rw",
          "on": "rw",
          "playPause": "x",
          "playUri": "x",
          "right": "x",
          "up": "x",
          "volume": "rw",
          "fastForward": "x",
          "rewind": "x"
        },
        "attributes": {
          "customGrid": [
            {
              "index": 0,
              "text": "GUIDE",
              "uri": "button:custom:0"
            },
            {
              "index": 1,
              "text": "LIST",
              "uri": "button:custom:1"
            },
            {
              "index": 2,
              "text": "FORMAT",
              "uri": "button:custom:2"
            },
            {
              "index": 3,
              "text": "TEXT",
              "uri": "button:custom:3"
            },
            {
              "index": 4,
              "text": "EXTRA",
              "uri": "button:custom:4"
            },
            {
              "index": 5,
              "text": "INPUT ",
              "uri": "button:custom:5"
            },
            {
              "icon": 6,
              "index": 6,
              "uri": "button:custom:6"
            },
            {
              "index": 7,
              "text": "YAHOO ",
              "uri": "button:custom:7"
            },
            {
              "icon": 13,
              "index": 8,
              "uri": "button:custom:8"
            },
            {
              "color": "#801b19",
              "index": 9,
              "uri": "button:custom:9"
            },
            {
              "icon": 3,
              "index": 10,
              "uri": "button:custom:10"
            },
            {
              "icon": 1,
              "index": 11,
              "uri": "button:custom:11"
            }
          ],
          "numericGrid": [
            {
              "index": 0,
              "uri": "button:numeric:0"
            },
            {
              "index": 1,
              "uri": "button:numeric:1"
            },
            {
              "index": 2,
              "uri": "button:numeric:2"
            },
            {
              "index": 3,
              "uri": "button:numeric:3"
            },
            {
              "index": 4,
              "uri": "button:numeric:4"
            },
            {
              "index": 5,
              "uri": "button:numeric:5"
            },
            {
              "index": 6,
              "uri": "button:numeric:6"
            },
            {
              "index": 7,
              "uri": "button:numeric:7"
            },
            {
              "index": 8,
              "uri": "button:numeric:8"
            },
            {
              "index": 9,
              "uri": "button:numeric:9"
            }
          ]
        }
      },
      "sourceHomeCinemaAppleTV": {
        "uuid": "sourceHomeCinemaAppleTV",
        "name": "Apple TV",
        "reachable": true,
        "capabilities": {
          "back": "x",
          "down": "x",
          "enter": "x",
          "favourites": {
            "list": "x",
            "remove": "x",
            "add": "x",
            "listCandidates": "x"
          },
          "menu": "x",
          "left": "x",
          "mute": "rw",
          "on": "rw",
          "playPause": "x",
          "right": "x",
          "up": "x",
          "volume": "rw"
        }
      },
      "sourceHomeCinemaBluRay": {
        "uuid": "sourceHomeCinemaBluRay",
        "name": "Blu-Ray",
        "reachable": true,
        "capabilities": {
          "back": "x",
          "down": "x",
          "enter": "x",
          "menu": "x",
          "left": "x",
          "rewind": "x",
          "fastForward": "x",
          "skipNext": "x",
          "skipPrevious": "x",
          "mute": "rw",
          "on": "rw",
          "playPause": "x",
          "right": "x",
          "up": "x",
          "volume": "rw"
        }
      },
      "sourceMasterBedroomSonyTVInternal": {
        "uuid": "sourceMasterBedroomSonyTVInternal",
        "name": "Sony TV",
        "reachable": true,
        "capabilities": {
          "back": "x",
          "channelDown": "x",
          "channelUp": "x",
          "down": "x",
          "enter": "x",
          "menu": "x",
          "left": "x",
          "rewind": "x",
          "fastForward": "x",
          "skipNext": "x",
          "skipPrevious": "x",
          "mute": "rw",
          "on": "rw",
          "playPause": "x",
          "right": "x",
          "up": "x",
          "volume": "rw"
        },
        "attributes": {
          "customGrid": [
            {
              "index": 0,
              "text": "GUIDE",
              "uri": "button:custom:0"
            },
            {
              "index": 1,
              "text": "LIST",
              "uri": "button:custom:1"
            },
            {
              "index": 2,
              "text": "FORMAT",
              "uri": "button:custom:2"
            },
            {
              "index": 3,
              "text": "TEXT",
              "uri": "button:custom:3"
            },
            {
              "index": 4,
              "text": "EXTRA",
              "uri": "button:custom:4"
            },
            {
              "index": 5,
              "text": "INPUT ",
              "uri": "button:custom:5"
            },
            {
              "icon": 6,
              "index": 6,
              "uri": "button:custom:6"
            },
            {
              "index": 7,
              "text": "YAHOO ",
              "uri": "button:custom:7"
            },
            {
              "icon": 13,
              "index": 8,
              "uri": "button:custom:8"
            },
            {
              "color": "#801b19",
              "index": 9,
              "uri": "button:custom:9"
            },
            {
              "icon": 3,
              "index": 10,
              "uri": "button:custom:10"
            },
            {
              "icon": 1,
              "index": 11,
              "uri": "button:custom:11"
            }
          ],
          "numericGrid": [
            {
              "index": 0,
              "uri": "button:numeric:0"
            },
            {
              "index": 1,
              "uri": "button:numeric:1"
            },
            {
              "index": 2,
              "uri": "button:numeric:2"
            },
            {
              "index": 3,
              "uri": "button:numeric:3"
            },
            {
              "index": 4,
              "uri": "button:numeric:4"
            },
            {
              "index": 5,
              "uri": "button:numeric:5"
            },
            {
              "index": 6,
              "uri": "button:numeric:6"
            },
            {
              "index": 7,
              "uri": "button:numeric:7"
            },
            {
              "index": 8,
              "uri": "button:numeric:8"
            },
            {
              "index": 9,
              "uri": "button:numeric:9"
            }
          ]
        }
      },
      "sourceLivingRoomRoku": {
        "uuid": "sourceLivingRoomRoku",
        "name": "Roku",
        "reachable": true,
        "capabilities": {
          "back": "x",
          "down": "x",
          "enter": "x",
          "menu": "x",
          "left": "x",
          "rewind": "x",
          "fastForward": "x",
          "skipNext": "x",
          "skipPrevious": "x",
          "mute": "rw",
          "on": "rw",
          "playPause": "x",
          "right": "x",
          "up": "x",
          "volume": "rw"
        }
      },
      "sourceLivingRoomPlayStation": {
        "uuid": "sourceLivingRoomPlayStation",
        "name": "PlayStation",
        "reachable": true,
        "capabilities": {}
      },
      "sourceLivingRoomSamsungTVInternal": {
        "uuid": "sourceLivingRoomSamsungTVInternal",
        "name": "Samsung TV",
        "reachable": true,
        "capabilities": {
          "back": "x",
          "channelDown": "x",
          "channelUp": "x",
          "down": "x",
          "enter": "x",
          "menu": "x",
          "left": "x",
          "rewind": "x",
          "fastForward": "x",
          "mute": "rw",
          "on": "rw",
          "playPause": "x",
          "right": "x",
          "up": "x",
          "volume": "rw"
        },
        "attributes": {
          "customGrid": [
            {
              "index": 0,
              "text": "GUIDE",
              "uri": "button:custom:0"
            },
            {
              "index": 1,
              "text": "LIST",
              "uri": "button:custom:1"
            },
            {
              "index": 2,
              "text": "FORMAT",
              "uri": "button:custom:2"
            },
            {
              "index": 3,
              "text": "TEXT",
              "uri": "button:custom:3"
            },
            {
              "index": 4,
              "text": "EXTRA",
              "uri": "button:custom:4"
            },
            {
              "index": 5,
              "text": "INPUT ",
              "uri": "button:custom:5"
            },
            {
              "icon": 6,
              "index": 6,
              "uri": "button:custom:6"
            },
            {
              "index": 7,
              "text": "YAHOO ",
              "uri": "button:custom:7"
            },
            {
              "icon": 13,
              "index": 8,
              "uri": "button:custom:8"
            },
            {
              "color": "#801b19",
              "index": 9,
              "uri": "button:custom:9"
            },
            {
              "icon": 3,
              "index": 10,
              "uri": "button:custom:10"
            },
            {
              "icon": 1,
              "index": 11,
              "uri": "button:custom:11"
            }
          ],
          "numericGrid": [
            {
              "index": 0,
              "uri": "button:numeric:0"
            },
            {
              "index": 1,
              "uri": "button:numeric:1"
            },
            {
              "index": 2,
              "uri": "button:numeric:2"
            },
            {
              "index": 3,
              "uri": "button:numeric:3"
            },
            {
              "index": 4,
              "uri": "button:numeric:4"
            },
            {
              "index": 5,
              "uri": "button:numeric:5"
            },
            {
              "index": 6,
              "uri": "button:numeric:6"
            },
            {
              "index": 7,
              "uri": "button:numeric:7"
            },
            {
              "index": 8,
              "uri": "button:numeric:8"
            },
            {
              "index": 9,
              "uri": "button:numeric:9"
            }
          ]
        }
      }
    }
  }
}
