'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('basImagePicker', [
    'BAS_HTML',
    basImagePickerDirective
  ])

/**
 * @param {BAS_HTML} BAS_HTML
 * @returns basImagePicker
 */
function basImagePickerDirective (
  BAS_HTML
) {
  return {
    restrict: 'AE',
    template: BAS_HTML.imagePicker,
    controller: [
      '$scope',
      'BAS_IMAGE',
      'BAS_MODAL',
      'ICONS',
      'BasAppDevice',
      'BasDeviceCamera',
      'BasDevicePermissions',
      'BasImage',
      'BasImageTrans',
      'BasImageResize',
      'BasModal',
      controller
    ],
    controllerAs: 'imagePicker',
    bindToController: {
      oldImage: '<?',
      clearOption: '<?',
      getImage: '&?',
      getClearImage: '&?',
      onImageApproved: '&?',
      onRevertApproved: '&?',
      close: '&?'
    }
  }

  /**
   * @param $scope
   * @param {BAS_IMAGE} BAS_IMAGE
   * @param {BAS_MODAL} BAS_MODAL
   * @param {ICONS} ICONS
   * @param {BasAppDevice} BasAppDevice
   * @param {BasDeviceCamera} BasDeviceCamera
   * @param {BasDevicePermissions} BasDevicePermissions
   * @param BasImage
   * @param BasImageTrans
   * @param BasImageResize
   * @param {BasModal} BasModal
   */
  function controller (
    $scope,
    BAS_IMAGE,
    BAS_MODAL,
    ICONS,
    BasAppDevice,
    BasDeviceCamera,
    BasDevicePermissions,
    BasImage,
    BasImageTrans,
    BasImageResize,
    BasModal
  ) {
    var imagePicker = this

    var CSS_HAS_TWO_OPTIONS = 'bas-modal-has-two-options'
    var CSS_HAS_THREE_OPTIONS = 'bas-modal-has-three-options'
    var CSS_HAS_NO_CAMERA = 'bas-modal-no-camera'
    var CSS_HAS_NO_CLEAR = 'bas-modal-no-clear'
    var CSS_HAS_NEW_ROOM_IMAGE = 'bas-modal-has-new-image'
    var CSS_SHOW_SPINNER = 'bas-modal-spinner-show'
    var CSS_SHOW_DECLINE_DISABLED = 'bas-modal-has-button-disabled'
    var CSS_CAMERA_WARNING = 'bas-modal-show-camera-warning'
    var CSS_LIBRARY_WARNING = 'bas-modal-show-library-warning'

    var STATE_EXTRA_PERMISSION_REQUIRED = 'extra_permissions_required'
    var STATE_DIFFERENT_IMAGE = 'please_try_a_different_image'
    var STATE_SUPPORTED_EXTENSIONS = 'only_png_jpeg_supported'
    var STATE_IMAGE_TOO_BIG = 'image_smaller_than_5mb'
    var STATE_DRAG_AND_DROP = 'drag_and_drop_images'

    var MEDIA_TYPE_JPEG = 'image/jpeg'
    var MEDIA_TYPE_PNG = 'image/png'

    var ERROR_NO_IMAGE_SELECTED = 'No Image Selected'

    var _cancelTimeout = null

    var _lockHeight = false

    var biSvgOpts = {
      customClass: [
        BAS_IMAGE.C_BG_CONTAIN,
        BAS_IMAGE.C_COLOR_LIGHT_CONTROL,
        BAS_IMAGE.C_SIZE_60
      ]
    }

    var PLACEHOLDER = new BasImage(ICONS.roomType_custom, biSvgOpts)

    /**
     * Tile art
     *
     * @type {?BasImageTrans}
     */
    imagePicker.bigTile = null

    imagePicker.css = {}
    imagePicker.buttonCss = {}
    resetCss()

    imagePicker.platform = BasDevicePermissions.PLATFORM_BROWSER
    imagePicker.fileDropEnabled = true
    imagePicker.cameraEnabled = true

    imagePicker.imageResult = ''
    imagePicker.imageResultDataURI = ''

    imagePicker.selectImageTitle = 'select_photo'
    imagePicker.latestStatus = ''

    imagePicker.cancel = _cancel
    imagePicker.approve = _approve
    imagePicker.close = _close
    imagePicker.getPicture = _getPicture
    imagePicker.onPicture = _onPicture
    imagePicker.clearImage = _clearImage

    imagePicker.$postLink = init
    imagePicker.$onChanges = _optionCheck
    imagePicker.$onDestroy = _onDestroy

    function init () {

      _checkPlatform()

      imagePicker.bigTile = new BasImageTrans({
        transitionType: BasImageTrans.TRANSITION_TYPE_FADE
      })

      _permissionCheck()
    }

    function _checkPlatform () {

      if (BasAppDevice.isAndroid()) {

        imagePicker.platform = BasDevicePermissions.PLATFORM_ANDROID
        imagePicker.fileDropEnabled = false
        imagePicker.selectImageTitle = 'photo_from_library'

      } else if (BasAppDevice.isIos()) {

        imagePicker.platform = BasDevicePermissions.PLATFORM_IOS
        imagePicker.fileDropEnabled = false
        imagePicker.cameraEnabled = false
      }
    }

    function _getPicture (cameraSrc) {

      _lockHeight = true

      BasDeviceCamera.getPicture(
        _onPicture,
        imagePicker.platform,
        {
          cameraSrc: cameraSrc
        }
      )
    }

    /**
     * Used by file drop zone / file selector / camera plugin
     * Camera plugin only provides DATA URI body, no prefix
     *
     * @private
     * @param error
     * @param {string} body
     * @param {string} [prefix]
     */
    function _onPicture (error, body, prefix) {

      var dataUri

      if (BasUtil.isNEString(body)) {

        _lockHeight = true
        _resetAll(false)

        if (isSupportedPrefix(prefix)) {

          dataUri = BasUtil.isNEString(prefix)
            ? prefix + body
            : body

          BasImageResize.getResizedImage(dataUri, 2048, 5000000, _onResize)

          setState('', true, PLACEHOLDER, PLACEHOLDER)

        } else {

          setState(STATE_SUPPORTED_EXTENSIONS)
        }

        $scope.$applyAsync()

      } else {

        _permissionCheck(error !== ERROR_NO_IMAGE_SELECTED)
      }
    }

    function _onResize (_error, imageData) {

      var index, prefix, body
      var _basImage

      if (BasUtil.isNEString(imageData)) {

        index = imageData.indexOf(',')
        prefix = imageData.substring(0, index + 1)
        body = imageData.substring(index + 1)

        if (atob(body).length > 10000000) {

          _showErrorMessage(STATE_IMAGE_TOO_BIG, false)

        } else {

          imagePicker.imageResult = body
          imagePicker.imageResultDataURI = prefix + body

          if (BasUtil.isFunction(imagePicker.getImage)) {

            _basImage = imagePicker.getImage({
              imageDataURI: imagePicker.imageResultDataURI
            })

          } else {

            _basImage = new BasImage(imagePicker.imageResultDataURI)
          }

          setState('', false, _basImage, _basImage)
        }
      } else {

        _showErrorMessage(STATE_DIFFERENT_IMAGE, false)
      }

      $scope.$applyAsync()
    }

    /**
     * Check file type of data uri
     *
     * empty prefix will result in true
     * (camera plugin does not provide prefix)
     *
     * @param prefix
     * @returns {boolean}
     */
    function isSupportedPrefix (prefix) {

      var supported, mediaType, start, end

      supported = false

      if (BasUtil.isNEString(prefix)) {

        start = prefix.indexOf(':') + 1
        end = prefix.indexOf(';')

        if (start > -1 && end > -1) {

          mediaType = prefix.substring(start, end)

          if (MEDIA_TYPE_JPEG === mediaType ||
            MEDIA_TYPE_PNG === mediaType) {

            supported = true
          }
        }
      } else {

        supported = true
      }

      return supported
    }

    function _clearImage () {

      var _basImage

      _lockHeight = true

      if (BasUtil.isFunction(imagePicker.getClearImage)) {

        _basImage = imagePicker.getClearImage()
      }

      if (_basImage) {

        imagePicker.imageResult = ''
        imagePicker.imageResultDataURI = ''

        setState('', false, null, _basImage)

      } else {

        _showErrorMessage(STATE_DIFFERENT_IMAGE)
      }
    }

    function _close () {

      if (imagePicker.css[CSS_HAS_NEW_ROOM_IMAGE]) {

        _cancel()

      } else if (BasUtil.isFunction(imagePicker.close)) {

        imagePicker.close()
      }
    }

    function _cancel () {

      if (imagePicker.buttonCss[CSS_SHOW_DECLINE_DISABLED]) return

      _resetAll()

      _optionCheck()

      _permissionCheck()

      $scope.$applyAsync()
    }

    function _approve () {

      if (BasUtil.isNEString(imagePicker.imageResult)) {

        if (BasUtil.isFunction(imagePicker.onImageApproved)) {

          Promise.resolve()
            .then(
              imagePicker.onImageApproved.bind(null, {
                image: imagePicker.imageResult,
                imageDataURI: imagePicker.imageResultDataURI
              })
            ).then(_onServerSuccess, _onServerError)
        }

      } else if (BasUtil.isFunction(imagePicker.onRevertApproved)) {

        Promise.resolve()
          .then(imagePicker.onRevertApproved)
          .then(_onServerSuccess, _onServerError)
      }

      imagePicker.buttonCss[CSS_SHOW_DECLINE_DISABLED] = true

      setState('', true)
    }

    function _onServerSuccess () {

      setState('')

      $scope.$applyAsync()
    }

    function _onServerError () {

      _showErrorMessage(STATE_DIFFERENT_IMAGE)

      $scope.$applyAsync()
    }

    function _showErrorMessage (message, useTimeout) {

      var _useTimeout = BasUtil.isBool(useTimeout)
        ? useTimeout
        : true

      if (_useTimeout) {

        _cancelTimeout = setTimeout(_cancel, 3000)

      } else {

        _cancel()
      }

      setState(message, false)
    }

    /**
     * Permission check
     * Show modal if permissions are 'denied always' and modal is true
     *
     * @param {boolean} [modal]
     */
    function _permissionCheck (modal) {

      var cameraFinished, libraryFinished
      var cameraGranted, libraryGranted
      var cameraDeniedAlways, libraryDeniedAlways

      cameraFinished = false
      libraryFinished = false

      cameraGranted = false
      libraryGranted = false

      cameraDeniedAlways = false
      libraryDeniedAlways = false

      BasDevicePermissions.requestBasDeviceCameraPermissions(
        _permissionCameraCallback,
        true,
        false,
        imagePicker.platform
      )

      BasDevicePermissions.requestBasDeviceCameraPermissions(
        _permissionLibraryCallback,
        false,
        false,
        imagePicker.platform
      )

      function _permissionCameraCallback (_error, granted, deniedAlways) {

        cameraFinished = true
        cameraGranted = granted
        cameraDeniedAlways = deniedAlways

        _finished()
      }

      function _permissionLibraryCallback (_error, granted, deniedAlways) {

        libraryFinished = true
        libraryGranted = granted
        libraryDeniedAlways = deniedAlways

        _finished()
      }

      function _finished () {

        if (cameraFinished && libraryFinished) {

          if (!cameraGranted || !libraryGranted) {

            imagePicker.css[CSS_CAMERA_WARNING] = !cameraGranted
            imagePicker.css[CSS_LIBRARY_WARNING] = !libraryGranted
            setState(STATE_EXTRA_PERMISSION_REQUIRED)

            if (modal &&
              (cameraDeniedAlways || libraryDeniedAlways)) {

              BasModal.show(BAS_MODAL.T_PERMISSION_DENIED_ALWAYS)
            }

          } else {

            imagePicker.css[CSS_CAMERA_WARNING] = false
            imagePicker.css[CSS_LIBRARY_WARNING] = false
            setState(
              imagePicker.fileDropEnabled
                ? STATE_DRAG_AND_DROP
                : ''
            )
          }

          $scope.$applyAsync()
        }
      }
    }

    /**
     * @param {?string} status
     * @param {?boolean} [spinner]
     * @param {?Object} [basImage]
     * @param {?Object} [defaultImage]
     */
    function setState (
      status,
      spinner,
      basImage,
      defaultImage
    ) {

      imagePicker.latestStatus = BasUtil.isNEString(status) ? status : ''

      imagePicker.css[CSS_SHOW_SPINNER] = BasUtil.isBool(spinner)
        ? spinner
        : false

      if (defaultImage || basImage) {

        imagePicker.css[CSS_HAS_NEW_ROOM_IMAGE] = true

        imagePicker.bigTile = new BasImageTrans({
          transitionType: BasImageTrans.TRANSITION_TYPE_FADE
        })

        imagePicker.bigTile.setDefaultImage(
          defaultImage || basImage
        )

        if (basImage) {

          imagePicker.bigTile.setImage(
            basImage || ''
          )
        }
      }
    }

    function _optionCheck () {

      var numberOfOptions, clearOption, browser

      // Don't change modal height while it is locked
      if (_lockHeight) return

      resetOptionCss()

      numberOfOptions = 4
      clearOption = !!imagePicker.clearOption
      browser = BasAppDevice.isBrowser()

      if (!clearOption) {

        numberOfOptions--
        imagePicker.css[CSS_HAS_NO_CLEAR] = true
      }

      if (browser) {

        numberOfOptions--
        imagePicker.css[CSS_HAS_NO_CAMERA] = true
      }

      if (numberOfOptions === 2) {

        imagePicker.css[CSS_HAS_TWO_OPTIONS] = true

      } else if (numberOfOptions === 3) {

        imagePicker.css[CSS_HAS_THREE_OPTIONS] = true
      }
    }

    /**
     * @private
     * @param {boolean} [heightLock]
     */
    function _resetAll (heightLock) {

      if (heightLock) _lockHeight = false

      imagePicker.imageResult = ''
      imagePicker.imageResultDataURI = ''
      imagePicker.bigTile.setImage('')

      BasUtil.execute(_cancelTimeout)
      _cancelTimeout = null

      resetCss()
    }

    function resetCss () {

      if (!_lockHeight) resetOptionCss()

      imagePicker.css[CSS_HAS_NEW_ROOM_IMAGE] = false
      imagePicker.css[CSS_SHOW_SPINNER] = false
      imagePicker.css[CSS_CAMERA_WARNING] = false
      imagePicker.css[CSS_LIBRARY_WARNING] = false
      imagePicker.buttonCss[CSS_SHOW_DECLINE_DISABLED] = false
    }

    function resetOptionCss () {

      imagePicker.css[CSS_HAS_NO_CLEAR] = false
      imagePicker.css[CSS_HAS_NO_CAMERA] = false
      imagePicker.css[CSS_HAS_TWO_OPTIONS] = false
      imagePicker.css[CSS_HAS_THREE_OPTIONS] = false
    }

    function _onDestroy () {

      _resetAll()
    }
  }
}
