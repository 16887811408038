'use strict'

import BAS_API from '../../../lgc-core-api-js'

// "bsc" = Bas Source Css

angular
  .module('basalteApp')
  .constant('BAS_SOURCE', {
    EVT_CONNECTED: 'evtBasSourceConnected',
    EVT_CURRENT_SONG: 'evtBasSourceCurrentSong',
    EVT_DEEZER_LINK_CHANGED: 'evtDeezerLinkChanged',
    EVT_TIDAL_LINK_CHANGED: 'evtTidalLinkChanged',
    EVT_SPOTIFY_LINK_CHANGED: 'evtSpotifyLinkChanged',
    EVT_SPOTIFY_TOKEN_CHANGED: 'evtSpotifyTokenChanged',
    EVT_SPOTIFY_USER_UPDATED: 'evtSpotifyUserUpdated',
    EVT_TIDAL_TOKEN_CHANGED: 'evtTidalTokenChanged',
    EVT_DEEZER_TOKEN_CHANGED: 'evtTidalTokenChanged',
    EVT_QUEUE_CHANGE: 'evtQueueChanged',
    EVT_STATE_CHANGE: 'evtStateChanged',
    EVT_NAME_UPDATED: 'evtNameUpdated',
    EVT_AVAILABLE_CHANGE: 'evtAvailableChanged',
    EVT_LISTENING_ROOMS_CHANGE: 'evtListeningRoomsChanged',
    EVT_DEFAULT_ROOMS_UPDATED: 'evtDefaultRoomsUpdated',
    EVT_COMPATIBLE_ROOMS_CHANGE: 'evtCompatibleRoomsChanged',
    EVT_CAPABILITIES_CHANGED: 'evtCapabilitiesChanged',
    EVT_FAVOURITES_UPDATED: 'evtFavouritesUpdated',
    EVT_STATUS_UPDATED: 'evtStatusUpdated',

    // Legacy API
    V_EMPTY: 0,
    V_MIXED: -1,
    V_UNKNOWN: 300,
    V_AV_INPUT_UNKNOWN: -201,
    V_AV_INPUT_NONE: -200,
    T_UNKNOWN_ID: 'unknownId',
    T_UNKNOWN_SOURCE: 'unknownSource',
    T_EMPTY: 'empty',
    T_MIXED: 'mixed',
    T_AV_INPUT_UNKNOWN: 'avInputUnknown',
    T_AV_INPUT_NONE: 'avInputNone',
    T_PLAYER: 'player',
    T_BARP: 'barp',
    T_EXTERNAL: 'external',
    T_BLUETOOTH: 'bluetooth',
    T_NOTIFICATION: 'notification',
    T_AUDIO_ALERT: 'audio_alert',
    ST_UNKNOWN: 'unknown',
    ST_LOCAL: 'local',
    ST_TUNEIN: 'tunein',
    ST_DEEZER: 'deezer',
    ST_TIDAL: 'tidal',
    ST_AAP: BAS_API.Barp.TYPE_AAP,
    ST_SPOTIFY: BAS_API.Barp.TYPE_SPOTIFY,

    // New Music API
    T_ASANO: 'asano',
    T_SONOS: 'sonos',
    T_BOSPEAKER: 'bospeaker',
    T_VIDEO: 'video',
    ST_STREAM: 'stream',
    ST_EXTERNAL: 'external',
    ST_RECORD: 'record',
    ST_TV: 'tv',
    ST_BLUETOOTH: 'bluetooth',

    URI_PREFIX_SPOTIFY: 'spotify:',
    URI_PREFIX_DEEZER: 'deezer:',
    URI_PREFIX_TIDAL: 'tidal:',
    URI_PREFIX_LOCAL: 'local:',
    URI_PREFIX_AAP: 'aap:',
    URI_PREFIX_TUNEIN: 'tunein:',

    REPEAT_MODES: [
      BAS_API.CONSTANTS.REPEAT_OFF,
      BAS_API.CONSTANTS.REPEAT_CURRENT_CONTEXT,
      BAS_API.CONSTANTS.REPEAT_CURRENT_TRACK
    ],
    ID_EMPTY: 'empty',
    ID_MIXED: 'mixed',
    ID_AV_INPUT_NONE: 'avInputNone',
    ID_AV_INPUT_UNKNOWN: 'avInputUnknown',
    COBRANET_ID_MIN: 1,
    COBRANET_ID_MAX: 250,
    COL_EVT_PLAYER: 'playerEvents',
    COL_EVT_SIMPLE: 'simpleEvents',
    COL_EVT_DATABASE_CONTENT: 'databaseContentEvents',
    COL_EVT_LOCAL_PLAYLISTS: 'localPlaylistsEvents',
    COL_EVT_DEEZER: 'deezerEvents',
    COL_EVT_TIDAL: 'tidalEvents',
    COL_EVT_SPOTIFY: 'spotifyEvents',
    COL_EVT_FAVOURITES: 'favouriteEvents',
    COL_EVT_LIBRARY: 'libraryEvents',
    COL_EVT_DEFAULT_ROOMS: 'defaultRoomsEvents',
    COL_EVT_KNX_PRESETS: 'knxPresets',
    EVT_COLLECTIONS: [],
    CSS_CONNECTED: 'bsc-connected',
    CSS_HAS_CONTENT: 'bsc-has-content',
    CSS_DEEZER_LINKED: 'bsc-deezer-linked',
    CSS_TIDAL_LINKED: 'bsc-tidal-linked',
    CSS_SPOTIFY_WEB_LINKED: 'bsc-spotify-web-linked',
    CSS_SPOTIFY_SPINNER: 'bbci-spotify-spinner',
    CSS_DEEZER_SPINNER: 'bbci-deezer-spinner',
    CSS_TIDAL_SPINNER: 'bbci-tidal-spinner',
    CSS_HAS_SONG_TITLE: 'bsc-song-title',
    ERR_NO_RESULT: 'No result',
    ERR_INVALID_PARAM: 'Invalid param',
    ERR_FREE_SPOTIFY: 'BAS::ERROR::AccountNotPremium'
  })
