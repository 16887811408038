'use strict'

angular
  .module('basalteApp')
  .service('BasAppHeaderControls', [
    '$window',
    '$rootScope',
    '$uiRouterGlobals',
    'ModalService',
    'BAS_HTML',
    'STATES',
    'BAS_STATE',
    'BAS_MODAL',
    'BasAppDevice',
    'BasState',
    'BasAppHeaderControlsBack',
    'BasCoreConnection',
    'CurrentBasCore',
    'BasSplash',
    'Rooms',
    'CurrentRoom',
    'BasStateHelper',
    'BasCameras',
    'modalHelperService',
    BasAppHeaderControls
  ])

/**
 * @constructor
 * @param $window
 * @param $rootScope
 * @param $uiRouterGlobals
 * @param ModalService
 * @param {BAS_HTML} BAS_HTML
 * @param {STATES} STATES
 * @param {BAS_STATE} BAS_STATE
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BasAppDevice} BasAppDevice
 * @param {BasState} BasState
 * @param {BasAppHeaderControlsBack} BasAppHeaderControlsBack
 * @param {BasCoreConnection} BasCoreConnection
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasSplash} BasSplash
 * @param {Rooms} Rooms
 * @param {CurrentRoom} CurrentRoom
 * @param {BasStateHelper} BasStateHelper
 * @param {BasCameras} BasCameras
 * @param {modalHelperService} modalHelperService
 */
function BasAppHeaderControls (
  $window,
  $rootScope,
  $uiRouterGlobals,
  ModalService,
  BAS_HTML,
  STATES,
  BAS_STATE,
  BAS_MODAL,
  BasAppDevice,
  BasState,
  BasAppHeaderControlsBack,
  BasCoreConnection,
  CurrentBasCore,
  BasSplash,
  Rooms,
  CurrentRoom,
  BasStateHelper,
  BasCameras,
  modalHelperService
) {

  /**
   * @type {BasAppDeviceState}
   */
  var basAppDeviceState = BasAppDevice.get()

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {TBasStateObj}
   */
  var basState = BasState.get()

  this.handleBack = BasAppHeaderControlsBack.handleBack
  this.handleHomeLogo = handleHomeLogo
  this.handleTitle = handleTitle
  this.handleRooms = handleRooms
  this.handleHomeRooms = handleHomeRooms
  this.handleRight = handleRight
  this.handleLisaTiles = handleLisaTiles

  function handleTitle () {

    return BasAppHeaderControlsBack.handleBack()
  }

  function handleHomeLogo () {

    var state

    state = $uiRouterGlobals.current.name

    if (BasStateHelper.hasBaseState(state, STATES.MAIN)) {

      handleHomeRooms()
    }
  }

  function handleRooms () {

    var state

    state = $uiRouterGlobals.current.name

    if (BasStateHelper.hasBaseState(state, STATES.MAIN)) {

      if (BasStateHelper.hasBaseState(state, STATES.ROOM)) {

        if (BasStateHelper.isSubState(STATES.ROOM, state)) {

          // Room sub state

          CurrentRoom.go(STATES.ROOM)

        } else {

          // Room dashboard state

          if (CurrentBasCore.hasRoomsOverview()) {

            CurrentRoom.go(
              STATES.ROOMS,
              {
                view: BAS_STATE.S_ROOMS_VIEW_HOME
              }
            )

          } else {

            // No rooms overview to go to
          }
        }

      } else {

        CurrentRoom.go(STATES.ROOM)
      }
    }
  }

  function handleHomeRooms () {

    var state, audioOnly

    state = $uiRouterGlobals.current.name
    audioOnly = CurrentBasCore.isAudioOnly()

    if (!BasStateHelper.hasBaseState(state, STATES.ROOMS)) {

      audioOnly
        ? _goRoomsMusic()
        : CurrentRoom.go(STATES.ROOMS)

    } else if (CurrentBasCore.hasHomePage()) {

      CurrentRoom.go(STATES.HOME)
    }
  }

  /**
   * @param event
   * @param {TBasAppHeaderActionOptions} [opts]
   */
  function handleRight (event, opts) {

    var isBasSplashContext

    if (opts) {
      isBasSplashContext = opts.basSplashContext
    }

    if (isBasSplashContext) {

      _showConnectModal(event, opts)

    } else if (
      basState.current.CONNECT_LIVE ||
      basState.current.CONNECT_DISCOVERY ||
      basState.current.CONNECT_UNSUPPORTED
    ) {

      _showConnectModal(event, opts)

    } else {

      _showMainModal(event, opts)
    }

  }

  function handleLisaTiles () {

    CurrentRoom.go(STATES.LISA_TILES)
  }

  /**
   * @param event
   * @param {TBasAppHeaderActionOptions} [opts]
   */
  function _showConnectModal (event, opts) {

    var isBasSplashContext

    if (opts) {
      isBasSplashContext = opts.basSplashContext
    }

    ModalService.showModal({
      controller: 'connectModalCtrl',
      controllerAs: 'modal',
      inputs: {
        event: event,
        basAppHeaderActionOptions: opts
      },
      template: BAS_HTML.connectModal
    }).then(_onConnectModal)

    function _onConnectModal (modal) {

      modal.close.then(_onConnectModalClose)
      modal.closed.then(_onConnectModalClosed)
    }

    function _onConnectModalClose (result) {

      if (result === BAS_MODAL.C_SELECT_LIVE) {

        if (basAppDeviceState.isProLiveHosted) {

          // Redirect to Pro Live account settings

          if ($window.location) {

            $window.location.href = BasAppDevice.getProLiveAccountSettingsUrl()
          }

        } else {

          BasCoreConnection.prepareChangeBasCore(
            isBasSplashContext
              ? {
                  stopDiscovery: true,
                  stopAutoReconnect: true
                }
              : {
                  stopDiscovery: true
                }
          )

          BasState.go(STATES.CONNECT_LIVE)
          if (isBasSplashContext) BasSplash.hide()
        }

      } else if (result === BAS_MODAL.C_SELECT_SERVER) {

        BasCoreConnection.prepareChangeBasCore(
          isBasSplashContext
            ? {
                stopDiscovery: false,
                stopAutoReconnect: true
              }
            : undefined
        )

        BasState.go(STATES.CONNECT_DISCOVERY)
        if (isBasSplashContext) BasSplash.hide()

      } else if (result === BAS_MODAL.C_SELECT_DEMO) {

        BasCoreConnection.selectDemoCore()

      } else if (result === BAS_MODAL.C_ADD_SERVER) {

        ModalService.showModal({
          template: BAS_HTML.addServerModal,
          controller: 'addServerModalCtrl',
          controllerAs: 'modal'
        }).then(_onAddServerModal)

        $rootScope.$emit(
          BAS_MODAL.EVT_ADD_SERVER_MODAL_VISIBILITY_CHANGED,
          true
        )

      } else if (result === BAS_MODAL.C_SETTINGS) {

        if (isBasSplashContext) {
          BasCoreConnection.prepareChangeBasCore({
            stopDiscovery: true,
            stopAutoReconnect: true
          })
        }

        BasState.go(STATES.DEVICE_SETTINGS)
        if (isBasSplashContext) BasSplash.hide()
      }
    }

    function _onConnectModalClosed () {

      modalHelperService.resetModalStyle()
    }
  }

  function _onAddServerModal (addServerModal) {

    addServerModal.closed.then(_onAddServerModalClosed)
  }

  function _onAddServerModalClosed () {

    $rootScope.$emit(
      BAS_MODAL.EVT_ADD_SERVER_MODAL_VISIBILITY_CHANGED,
      false
    )
  }

  /**
   * @param event
   * @param {TBasAppHeaderActionOptions} [opts]
   */
  function _showMainModal (event, opts) {

    var state, modal, canChangeUserFull

    state = $uiRouterGlobals.current.name
    canChangeUserFull = BasAppDevice.isCoreClient()
      ? false
      : !BasStateHelper.hasBaseState(state, STATES.ROOM)

    ModalService.closeModals()

    modal = {
      controller: 'mainModalCtrl',
      controllerAs: 'modal',
      inputs: {
        event: event,
        basAppHeaderActionOptions: opts,
        canChangeUserFull: canChangeUserFull
      },
      template: BAS_HTML.mainModal
    }

    ModalService.showModal(modal)
      .then(_onMainModal)

    function _onMainModal (mainModal) {

      // Modal is ready, DOM manipulation is allowed
      if (
        mainModal.controller &&
        mainModal.controller.onModalReady &&
        typeof mainModal.controller.onModalReady === 'function'
      ) {
        mainModal.controller.onModalReady()
      }

      // Set Promises
      mainModal.close.then(_onMainModalClose)
      mainModal.closed.then(_onMainModalClosed)
    }

    function _onMainModalClose (result) {

      var _state, room

      _state = $uiRouterGlobals.current.name
      room = CurrentRoom.getRoom()

      switch (result) {
        case BAS_MODAL.C_ALARMS:

          if (!BasStateHelper.hasBaseState(_state, STATES.ALARMS_ALARM)) {

            CurrentRoom.go(STATES.ALARMS)
          }

          break
        case BAS_MODAL.C_CAMERAS:

          BasCameras.goToCameras()

          break
        case BAS_MODAL.C_OPEN_CLOSE_DEVICES:

          CurrentRoom.go(STATES.DOORS)

          break
        case BAS_MODAL.C_ROOMS_HOME:

          CurrentRoom.go(
            STATES.ROOMS,
            {
              view: BAS_STATE.S_ROOMS_VIEW_HOME
            }
          )

          break
        case BAS_MODAL.C_ROOMS_MUSIC:

          CurrentRoom.go(
            STATES.ROOMS,
            {
              view: BAS_STATE.S_ROOMS_VIEW_MUSIC
            }
          )

          break
        case BAS_MODAL.C_ROOMS_THERMOSTATS:

          CurrentRoom.go(
            STATES.ROOMS,
            {
              view: BAS_STATE.S_ROOMS_VIEW_THERMOSTATS
            }
          )

          break
        case BAS_MODAL.C_ROOMS_INTERCOM:

          CurrentRoom.go(
            STATES.ROOMS,
            {
              view: BAS_STATE.S_ROOMS_VIEW_INTERCOM
            }
          )

          break
        case BAS_MODAL.C_ROOMS_VIDEO:

          CurrentRoom.go(
            STATES.ROOMS,
            {
              view: BAS_STATE.S_ROOMS_VIEW_MUSIC
            }
          )

          break
        case BAS_MODAL.C_SETTINGS:

          CurrentRoom.go(STATES.SETTINGS)

          break
        case BAS_MODAL.C_TV_OFF_SINGLE:

          if (room && room.video && room.video.toggle) {

            room.video.toggle(false)
          }

          break
        case BAS_MODAL.C_MUSIC_OFF_SINGLE:

          if (room && room.music && room.music.toggle) {

            room.music.toggle(false)
          }

          break
        case BAS_MODAL.C_MUSIC_OFF_ALL:

          Rooms.turnOffAllZones()

          break

        case BAS_MODAL.C_LIGHTS_OFF_ALL:

          if (room && room.lights && room.lights.turnOffAllLights) {

            room.lights.turnOffAllLights()
          }

          break
        case BAS_MODAL.C_SETTINGS_ABOUT:

          CurrentRoom.go(STATES.SETTINGS_ABOUT)

          break

        case BAS_MODAL.C_SELECT_DISCOVERY:

          BasCoreConnection.prepareChangeBasCore()

          CurrentRoom.go(
            STATES.CONNECT_DISCOVERY,
            null,
            {
              custom:
                {
                  BasExitMainState: true
                }
            }
          )

          break

        case BAS_MODAL.C_MUTE_DEVICE:

          ModalService.showModal({
            controller: 'muteDeviceModalCtrl',
            controllerAs: 'modal',
            template: BAS_HTML.muteDeviceModal
          })

          break
      }
    }

    function _onMainModalClosed (result) {

      // Reset modal height
      modalHelperService.resetModalStyle()

      if (result === BAS_MODAL.C_CHANGE_USER) {

        BasCoreConnection.removeLoginCredentials()

        // Go to login
        CurrentRoom.go(
          STATES.CONNECT_PROFILES,
          null,
          {
            custom:
              {
                BasExitMainState: true
              }
          }
        )
      }
    }
  }

  function _goRoomsMusic () {

    if (
      !CurrentBasCore.hasCore() ||
      !(
        currentBasCoreState.core.core.singleRoomId &&
        currentBasCoreState.core.core.singleAudioRoomId
      )
    ) {
      return CurrentRoom.go(
        STATES.ROOMS,
        {
          view: BAS_STATE.S_ROOMS_VIEW_MUSIC
        }
      )
    }
  }
}
