'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasGenericDeviceV2', [
    'BAS_API',
    'BAS_GENERIC_DEVICE',
    'BasUtilities',
    'BasGenericDevice',
    'BasGenericDeviceControl',
    basGenericDeviceV2Factory
  ])

/**
 * @typedef {Object} TGenericDeviceCategory
 * @property {string} name
 * @property {BasGenericDeviceControl[]} controls
 */

/**
 * @param BAS_API
 * @param BAS_GENERIC_DEVICE
 * @param BasUtilities
 * @param BasGenericDevice
 * @param BasGenericDeviceControl
 * @returns {BasGenericDeviceV2}
 */
function basGenericDeviceV2Factory (
  BAS_API,
  BAS_GENERIC_DEVICE,
  BasUtilities,
  BasGenericDevice,
  BasGenericDeviceControl
) {

  /**
   * @constructor
   * @extends BasGenericDevice
   *
   * @param {GenericDevice} [device]
   */
  function BasGenericDeviceV2 (device) {
    BasGenericDevice.call(this)

    /**
     * @type {string}
     */
    this.subtitle = ''

    /**
     * @type {string}
     */
    this.icon = ''

    /**
     * @type {TGenericDeviceCategory[]}
     */
    this.categories = []

    if (device) this.parseGenericDevice(device)
  }

  BasGenericDeviceV2.prototype = Object.create(BasGenericDevice.prototype)

  BasGenericDeviceV2.prototype.clone = function () {

    return new BasGenericDeviceV2(this.device)
  }

  /**
   * TODO: Add single control support
   *
   */
  BasGenericDeviceV2.prototype.removeNonEditableControls = function () {

    for (const category of this.categories) {
      const newControls = []

      for (const control of category.controls) {

        if (
          control &&
          BasUtil.isFunction(control.isEditable) &&
          BasUtil.isFunction(control.clear)
        ) {

          control.isEditable()
            ? newControls.push(control)
            : control.clear()
        }
      }

      category.controls = newControls
    }
  }

  /**
   * TODO: Add single control support
   *
   * Checks whether the controls in a device are active
   *
   * @returns {boolean}
   */
  BasGenericDeviceV2.prototype.areControlsActive = function () {

    for (const category of this.categories) {

      for (const control of category.controls) {

        if (BasUtil.isFunction(control.isActive) && control.isActive()) {

          return true
        }
      }
    }

    return false
  }

  /**
   * @param {string} nameType
   * @returns {string}
   */
  BasGenericDeviceV2.prototype.getUiSubtitle = function (nameType) {
    if (nameType) {
      switch (nameType) {
        case BAS_GENERIC_DEVICE.NAME_TYPE_CUSTOM:
          return ''
        case BAS_GENERIC_DEVICE.NAME_TYPE_OTHER:
          return BasUtilities.translate('other')
        case BAS_GENERIC_DEVICE.NAME_TYPE_APPLIANCES:
          return BasUtilities.translate('generic_devices_appliances')
        case BAS_GENERIC_DEVICE.NAME_TYPE_ARTWORK:
          return BasUtilities.translate('generic_devices_artwork')
        case BAS_GENERIC_DEVICE.NAME_TYPE_CAR_CHARGER:
          return BasUtilities.translate('generic_devices_car_charger')
        case BAS_GENERIC_DEVICE.NAME_TYPE_ENERGY:
          return BasUtilities.translate('generic_devices_energy')
        case BAS_GENERIC_DEVICE.NAME_TYPE_FIRE_PLACE:
          return BasUtilities.translate('generic_devices_fire_place')
        case BAS_GENERIC_DEVICE.NAME_TYPE_FOUNTAIN:
          return BasUtilities.translate('generic_devices_fountain')
        case BAS_GENERIC_DEVICE.NAME_TYPE_GARAGE:
          return BasUtilities.translate('generic_devices_garage')
        case BAS_GENERIC_DEVICE.NAME_TYPE_GARDEN:
          return BasUtilities.translate('generic_devices_garden')
        case BAS_GENERIC_DEVICE.NAME_TYPE_IRRIGATION:
          return BasUtilities.translate('generic_devices_irrigation')
        case BAS_GENERIC_DEVICE.NAME_TYPE_POOL:
          return BasUtilities.translate('generic_devices_pool')
        case BAS_GENERIC_DEVICE.NAME_TYPE_SECURITY:
          return BasUtilities.translate('generic_devices_security')
        case BAS_GENERIC_DEVICE.NAME_TYPE_SOCKET:
          return BasUtilities.translate('generic_devices_socket')
        case BAS_GENERIC_DEVICE.NAME_TYPE_VENTILATION:
          return BasUtilities.translate('generic_devices_ventilation')
        case BAS_GENERIC_DEVICE.NAME_TYPE_WELLNESS_SPA:
          return BasUtilities.translate('generic_devices_wellness_spa')
        default:
          return BasUtilities.translate('other')
      }
    }
  }

  /**
   * @param {GenericDevice} device
   */
  BasGenericDeviceV2.prototype.parseGenericDevice = function (device) {

    if (device instanceof BAS_API.GenericDeviceV2) {
      this.device = device
      this.uuid = device.uuid
      this.name = device.name
      this.subtitle = this.getUiSubtitle(device.nameType)
      this.icon = device.icon
      this.type = device.type

      this.setDeviceListeners()
      this.syncDevice()
    }
  }

  /**
   * @param {string} uuid
   * @returns {?BasGenericDeviceControl}
   */
  BasGenericDeviceV2.prototype.getControlById = function (uuid) {

    for (const category of this.categories) {

      const foundControl =
        category.controls.find(control => {
          return BasUtil.isNEString(control.uuid) && control.uuid === uuid
        })

      if (foundControl) return foundControl
    }

    return null
  }

  /**
   * TODO: Add single control support
   *
   * @param {GenericDevice} [device]
   */
  BasGenericDeviceV2.prototype.syncDevice = function (device) {
    const _device = device || this.device

    this.clearControls()

    if (_device && Array.isArray(_device.categories)) {
      this.name = _device.name
      this.subtitle = this.getUiSubtitle(_device.nameType)
      this.categories = _device.categories.map(category => ({
        ...category,
        controls: category.controls.map(
          control => new BasGenericDeviceControl(control, this)
        )
      }))

      if (this.onlyInputControls) {
        this.removeNonEditableControls()
      }
    }

    this.updateTranslation()
  }

  /**
   * TODO: Add single control support
   *
   * @returns {boolean}
   */
  BasGenericDeviceV2.prototype.isEditable = function () {

    for (const category of this.categories) {

      for (const control of category.controls) {

        if (BasUtil.isFunction(control.isEditable)) {

          if (control.isEditable()) return true
        }
      }
    }

    return false
  }

  /**
   * TODO: Add single control support
   */
  BasGenericDeviceV2.prototype.updateTranslation = function () {

    for (const category of this.categories) {

      for (const control of category.controls) {

        if (BasUtil.isFunction(control.updateTranslation)) {
          control.updateTranslation()
        }
      }
    }
  }

  /**
   * @param {string} uuid
   * @param {*} value
   */
  BasGenericDeviceV2.prototype.commit = function (uuid, value) {

    if (this.canSync && this.device) {

      this.device.setValue(uuid, value)
    }
  }

  BasGenericDeviceV2.prototype.setOnlyInputControls = function (mode) {
    this.onlyInputControls = mode === true
    if (this.onlyInputControls) this.removeNonEditableControls()
  }

  BasGenericDeviceV2.prototype.clear = function () {
    this.clearDeviceListeners()
    this.device = null
    this.categories = []
  }

  /**
   * TODO: Add single control support
   */
  BasGenericDeviceV2.prototype.clearControls = function () {
    for (const category of this.categories) {

      for (const control of category.controls) {

        if (BasUtil.isFunction(control.clear)) control.clear()
      }

      if (category.controls) category.controls = []
    }
  }

  return BasGenericDeviceV2
}
