'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasResource', [
    'BAS_API',
    'BAS_IMAGE',
    'BasCache',
    'CurrentBasCore',
    BasResource
  ])

/**
 * @constructor
 * @param BAS_API
 * @param {BAS_IMAGE} BAS_IMAGE
 * @param {BasCache} BasCache
 * @param {CurrentBasCore} CurrentBasCore
 */
function BasResource (
  BAS_API,
  BAS_IMAGE,
  BasCache,
  CurrentBasCore
) {
  var CACHE_NAME = 'IMAGES'

  /**
   * "returnElement" determines the return value.
   * This can be an image element or a string.
   * If this is true, preloading is necessary
   *
   * @typedef {Object} TBasResourceOptions
   * @property {BasServer} [basServer]
   * @property {string} [crossOrigin]
   * @property {boolean} [enablePreload]
   * @property {boolean} [returnElement]
   */

  /**
   * @private
   * @type {Object<string, ?Promise<string>>}
   */
  var _imagePromises = {}

  this.getImage = getImage
  this.getImages = getImages
  this.getDomLoadedImage = getDomLoadedImage

  /**
   * Get image default with preloading and returns src
   * Unless options request otherwise
   *
   * @param {string} uri
   * @param {TBasResourceOptions} [resourceOptions]
   * @returns {Promise<string>}
   */
  function getImage (uri, resourceOptions) {

    var _promise
    var _basServer, _enablePreload, _crossOrigin, _returnElement

    _enablePreload = true
    _returnElement = false

    if (resourceOptions) {

      _basServer = resourceOptions.basServer

      _crossOrigin = resourceOptions.crossOrigin

      if (BasUtil.isBool(resourceOptions.enablePreload)) {
        _enablePreload = resourceOptions.enablePreload
      }

      if (BasUtil.isBool(resourceOptions.returnElement)) {
        _returnElement = resourceOptions.returnElement
      }
    }

    if (BasUtil.isNEString(uri)) {

      if (BasUtil.startsWith(uri, 'data:')) {

        // A 'data:' URI
        // Should NOT be cached
        // Should NOT be preloaded
        // Except if an image element is needed

        return _returnElement
          ? _preloadImage(uri, _crossOrigin).then(_onPreloaded)
          : Promise.resolve(uri)

      } else if (BasUtil.startsWith(uri, BAS_IMAGE.LOCAL_PREFIX)) {

        // Local asset is found in img directory

        const originalUri = uri.slice(BAS_IMAGE.LOCAL_PREFIX.length)
        return Promise.resolve(originalUri)

      } else if (BasUtil.hasValidKnownScheme(uri)) {

        // A known scheme means that this is a external uri
        // Should NOT be cached
        // Should be preloaded

        return _enablePreload
          ? _preloadImage(uri, _crossOrigin).then(_onPreloaded)
          : Promise.resolve(uri)
      }

      // Incomplete or processing required
      // Should be cached
      // Should NOT be preloaded
      // Except if an image element is needed

      _promise = _imagePromises[uri]

      if (!_promise) {

        _promise = _imagePromises[uri] =
          _checkCacheOrRetrieveImage(uri, _basServer)

        _promise.then(_onFinished, _onFinished)
      }

      return _returnElement
        ? _promise.then(__preloadImage).then(_onPreloaded)
        : _promise
    }

    return Promise.reject(new Error('not a valid uri'))

    function __preloadImage (imageUrl) {

      return _preloadImage(imageUrl, _crossOrigin)
    }

    /**
     * @param {HTMLImageElement} preloadResult
     * @returns {HTMLImageElement|string} src
     */
    function _onPreloaded (preloadResult) {

      return _returnElement ? preloadResult : preloadResult.src
    }

    function _onFinished () {

      _imagePromises[uri] = null
    }
  }

  /**
   * @param {Object<string, string>} obj
   * @returns {Promise<Object<string, string>>}
   */
  function getImages (obj) {

    var _result, _promises, keys, length, i, key, path

    if (BasUtil.isObject(obj)) {

      _result = {}

      _promises = []

      keys = Object.keys(obj)
      length = keys.length
      for (i = 0; i < length; i++) {

        key = keys[i]
        path = obj[key]

        if (BasUtil.isString(path)) {

          _result[key] = ''

          if (path) {

            _promises.push(_getImage(key, path))
          }

        } else {

          _result[key] = path
        }
      }

      return Promise.all(_promises).then(_onAllImages)
    }

    return Promise.reject(new Error('not a valid object'))

    /**
     * @private
     * @param {string} imageKey
     * @param {string} imagePath
     * @returns {Promise<string>}
     */
    function _getImage (imageKey, imagePath) {

      return getImage(imagePath).then(_onImage)

      /**
       * @private
       * @param {string} result
       */
      function _onImage (result) {

        _result[imageKey] = result
      }
    }

    /**
     * @private
     * @param {string[]} _allResult
     * @returns {Object}
     */
    function _onAllImages (_allResult) {

      return _result
    }
  }

  /**
   * Loads an Image object
   *
   * @param {string} imageUrl
   * @param {string} [crossOrigin]
   * @returns {Promise<HTMLImageElement>}
   */
  function getDomLoadedImage (imageUrl, crossOrigin) {

    return getImage(
      imageUrl,
      {
        basServer: null,
        crossOrigin: crossOrigin,
        enablePreload: true,
        returnElement: true
      }
    )
  }

  /**
   * Loads an Image object and can return object or imageUrl
   *
   * @param {string} imageUrl
   * @param {string} [crossOrigin]
   * @returns {Promise<HTMLImageElement>}
   */
  function _preloadImage (imageUrl, crossOrigin) {

    return new Promise(imagePromiseConstructor)

    function imagePromiseConstructor (resolve, reject) {

      var img

      // Create Image
      img = new Image()

      // Set listeners
      img.onload = _resolve
      img.onerror = reject

      if (BasUtil.isString(crossOrigin)) img.crossOrigin = crossOrigin

      img.src = imageUrl

      function _resolve () {

        resolve(img)
      }
    }
  }

  /**
   * @private
   * @param {string} uri
   * @param {BasServer} [basServer]
   * @returns {Promise<string>}
   */
  function _checkCacheOrRetrieveImage (uri, basServer) {

    var _uri

    // Check for Core proxy URI
    _uri = BasUtil.stripStart(
      uri,
      BAS_API.CONSTANTS.CORE_PROXY_SCHEME
    )

    return BasCache.get(CACHE_NAME, _uri).catch(_onCacheMiss)

    function _onCacheMiss () {

      return _retrieveImage(uri, basServer)
    }
  }

  /**
   * @private
   * @param {string} uri
   * @param {BasServer} [basServer]
   * @returns {Promise<string>}
   */
  function _retrieveImage (uri, basServer) {

    var _server

    _server = basServer || CurrentBasCore.getServer()

    return _server
      ? _server.retrieveImageSource(uri).then(_onImageSource)
      : Promise.reject(new Error('no server'))

    function _onImageSource (result) {

      var _uri

      if (BasUtil.startsWith(result, 'data:')) {

        // Check for Core proxy URI
        _uri = BasUtil.stripStart(
          uri,
          BAS_API.CONSTANTS.CORE_PROXY_SCHEME
        )

        BasCache.set(CACHE_NAME, _uri, result)
      }

      return result
    }
  }
}
