'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasSceneHeader', [
    '$rootScope',
    '$uiRouterGlobals',
    'SCENE_STATES',
    'BAS_STATE',
    'BasSceneStateHelper',
    'CurrentRoom',
    'BasSceneHelper',
    'BasUtilities',
    basSceneHeaderFactory
  ])

/**
 * @param $rootScope
 * @param $uiRouterGlobals
 * @param {SCENE_STATES} SCENE_STATES
 * @param {BAS_STATE} BAS_STATE
 * @param {BasSceneStateHelper} BasSceneStateHelper
 * @param {CurrentRoom} CurrentRoom
 * @param {BasSceneHelper} BasSceneHelper
 * @param {BasUtilities} BasUtilities
 * @returns BasSceneHeader
 */
function basSceneHeaderFactory (
  $rootScope,
  $uiRouterGlobals,
  SCENE_STATES,
  BAS_STATE,
  BasSceneStateHelper,
  CurrentRoom,
  BasSceneHelper,
  BasUtilities
) {
  var CSS_CAN_NAVIGATE = 'bah-can-navigate'
  var CSS_HAS_SUBTITLE = 'baht-has-sub'
  var CSS_HAS_ACTION_BTN = 'bah-can-finish'
  var CSS_CAN_EDIT = 'bah-can-edit'

  /**
   * @constructor
   * @param {Object} scope
   */
  function BasSceneHeader (scope) {

    /**
     * @private
     */
    this._scope = BasUtil.isObject(scope) ? scope : null

    /**
     * @type {string}
     */
    this.title = ''

    /**
     * @type {string}
     */
    this.subtitle = ''

    /**
     * @type {Object<string, boolean>}
     */
    this.css = {}
    this._resetCss()

    this._listeners = []

    this._handleStateChange = this._onStateChange.bind(this)
    this._handleTranslationChange = this._onTranslationChange.bind(this)
  }

  BasSceneHeader.prototype.back = function () {

    var _state

    _state = BasSceneStateHelper.getHelper()

    if (_state.isCurrent(SCENE_STATES.SCENE_STEP_MUSIC_FAVOURITES)) {

      CurrentRoom.go(_state.get(SCENE_STATES.SCENE_STEP_MUSIC))

    } else if (_state.isCurrent(SCENE_STATES.SCENE_STEP_VIDEO_FAVOURITES)) {

      CurrentRoom.go(_state.get(SCENE_STATES.SCENE_STEP_VIDEO))

    } else if (_state.isCurrent(SCENE_STATES.SCENE_ROOM_FUNCTIONS)) {

      CurrentRoom.go(_state.get(SCENE_STATES.SCENE_ROOMS))

    } else {

      CurrentRoom.go(
        _state.get(SCENE_STATES.SCENE),
        {
          scene: BasSceneHelper.getSceneId($uiRouterGlobals)
        }
      )
    }
  }

  BasSceneHeader.prototype.action = function () {

    CurrentRoom.go(
      BasSceneStateHelper.getSceneState(SCENE_STATES.SCENE),
      {
        scene: BasSceneHelper.getSceneId($uiRouterGlobals)
      }
    )
  }

  BasSceneHeader.prototype.resume = function () {

    this._setListeners()

    this._onStateChange()
  }

  BasSceneHeader.prototype.suspend = function () {

    this._clearListeners()
  }

  BasSceneHeader.prototype.destroy = function () {

    this.suspend()
    this._scope = null
  }

  BasSceneHeader.prototype._onStateChange = function () {

    this._syncTitle()
    this._syncActionButton()
  }

  BasSceneHeader.prototype._onTranslationChange = function () {

    this._syncTitle()
  }

  BasSceneHeader.prototype._syncTitle = function () {

    var _state, _sceneName

    _state = BasSceneStateHelper.getHelper()

    this.title = BasUtilities.translate('scene')
    this.subtitle = ''

    this.css[CSS_CAN_NAVIGATE] = false
    this.css[CSS_HAS_SUBTITLE] = false

    if (_state.isCurrent(SCENE_STATES.SCENE)) {

      _sceneName = this._getSceneName()

      if (_state.isCurrent(SCENE_STATES.SCENE_PRESETS) ||
        _state.isCurrent(SCENE_STATES.SCENE_PICTURE)) {

        this.title = _sceneName
        this.subtitle = BasUtilities.translate('scene')
        this.css[CSS_CAN_NAVIGATE] = true
        this.css[CSS_HAS_SUBTITLE] = true

      } else if (_state.isCurrent(SCENE_STATES.SCENE_ROOMS)) {

        this.title = _sceneName
        this.subtitle = BasUtilities.translate('rooms')
        this.css[CSS_CAN_NAVIGATE] = true
        this.css[CSS_HAS_SUBTITLE] = true

        if (_state.isCurrent(SCENE_STATES.SCENE_ROOM_FUNCTIONS)) {

          this.subtitle = BasUtilities.translate('functions')
        }

      } else if (_state.isCurrent(SCENE_STATES.SCENE_FUNCTIONS)) {

        this.title = _sceneName
        this.subtitle = BasUtilities.translate('functions')
        this.css[CSS_CAN_NAVIGATE] = true
        this.css[CSS_HAS_SUBTITLE] = true

      } else if (_state.isCurrent(SCENE_STATES.SCENE_STEP)) {

        this.title = _sceneName
        this.css[CSS_CAN_NAVIGATE] = true

        if (_state.isCurrent(SCENE_STATES.SCENE_STEP_MUSIC)) {

          this.subtitle = BasUtilities.translate('music')
          this.css[CSS_HAS_SUBTITLE] = true

          if (_state.isCurrent(
            SCENE_STATES.SCENE_STEP_MUSIC_FAVOURITES
          )) {

            this.subtitle =
              BasUtilities.translate('favourites')
            this.css[CSS_CAN_NAVIGATE] = true
            this.css[CSS_HAS_SUBTITLE] = true
          }
        } else if (_state.isCurrent(SCENE_STATES.SCENE_STEP_VIDEO)) {

          this.subtitle = BasUtilities.translate('tv')
          this.css[CSS_HAS_SUBTITLE] = true

          if (_state.isCurrent(
            SCENE_STATES.SCENE_STEP_VIDEO_FAVOURITES
          )) {

            this.title = BasUtilities.translate('source')
            this.subtitle =
              BasUtilities.translate('favourites')
            this.css[CSS_CAN_NAVIGATE] = true
            this.css[CSS_HAS_SUBTITLE] = true
          }
        }
      }
    }
  }

  BasSceneHeader.prototype._syncActionButton = function () {

    var _state, _scene, _canEdit

    _scene = BasSceneHelper.getScene(this._scope, $uiRouterGlobals)
    _state = BasSceneStateHelper.getHelper()

    _canEdit = _scene && (
      _scene.canRemove() ||
      _scene.canWriteContent() ||
      _scene.canWriteName() ||
      _scene.canWriteTemplate()
    )

    this.css[CSS_HAS_ACTION_BTN] =
      !!_state.isCurrent(SCENE_STATES.SCENE_STEP)
    this.css[CSS_CAN_EDIT] = _canEdit && !!_state.isExact(SCENE_STATES.SCENE)
  }

  /**
   * @private
   * @returns {string}
   */
  BasSceneHeader.prototype._getSceneName = function () {

    var _scene

    _scene = BasSceneHelper.getScene(this._scope, $uiRouterGlobals)

    return _scene ? _scene.name : ''
  }

  BasSceneHeader.prototype._setListeners = function () {

    this._clearListeners()

    this._listeners.push($rootScope.$on(
      BAS_STATE.EVT_STATE_SUCCESS,
      this._handleStateChange
    ))
    this._listeners.push($rootScope.$on(
      '$translateChangeSuccess',
      this._handleTranslationChange
    ))
  }

  BasSceneHeader.prototype._clearListeners = function () {

    BasUtil.executeArray(this._listeners)
    this._listeners = []
  }

  BasSceneHeader.prototype._resetCss = function () {

    this.css[CSS_CAN_NAVIGATE] = false
    this.css[CSS_HAS_SUBTITLE] = false
    this.css[CSS_HAS_ACTION_BTN] = false
    this.css[CSS_CAN_EDIT] = false
  }

  return BasSceneHeader
}
