'use strict'

angular
  .module('basalteApp')
  .service('BasBackground', [
    '$rootScope',
    '$uiRouterGlobals',
    'STATES',
    'SCENE_STATES',
    'BAS_STATE',
    'BAS_IMAGE',
    'BAS_ROOMS',
    'BasState',
    'BasImageTrans',
    'CurrentBasCore',
    'RoomsHelper',
    'CurrentRoom',
    BasBackground
  ])

/**
 * @typedef {Object} TBackgroundState
 * @property {BasImageTrans} bitBg
 * @property {BasImageTrans} roomBitBg
 */

/**
 * Helper service to keep track of the background
 *
 * @constructor
 * @param $rootScope
 * @param $uiRouterGlobals
 * @param {STATES} STATES
 * @param {SCENE_STATES} SCENE_STATES
 * @param {BAS_STATE} BAS_STATE
 * @param {BAS_IMAGE} BAS_IMAGE
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BasState} BasState
 * @param BasImageTrans
 * @param {CurrentBasCore} CurrentBasCore
 * @param {RoomsHelper} RoomsHelper
 * @param {CurrentRoom} CurrentRoom
 */
function BasBackground (
  $rootScope,
  $uiRouterGlobals,
  STATES,
  SCENE_STATES,
  BAS_STATE,
  BAS_IMAGE,
  BAS_ROOMS,
  BasState,
  BasImageTrans,
  CurrentBasCore,
  RoomsHelper,
  CurrentRoom
) {
  var _imgStyleBrightness0 = {}
  var _imgStyleBrightness30 = {}
  var _imgStyleBrightness50 = {}
  var _imgStyleBrightness75 = {}

  /**
   * @type {TBackgroundState}
   */
  var state = {
    bitBg: new BasImageTrans({
      transitionType: BasImageTrans.TRANSITION_TYPE_FADE,
      debounceMs: 1000,
      debounceMsNull: 200
    }),
    roomBitBg: new BasImageTrans({
      transitionType: BasImageTrans.TRANSITION_TYPE_FADE,
      debounceMs: 1000,
      debounceMsNull: 200
    })
  }

  /**
   * @type {TBasStateObj}
   */
  var basState = BasState.get()

  this.get = get

  init()

  function init () {

    _imgStyleBrightness0[BAS_IMAGE.C_BRIGHTNESS_0] = true
    _imgStyleBrightness30[BAS_IMAGE.C_BRIGHTNESS_30] = true
    _imgStyleBrightness50[BAS_IMAGE.C_BRIGHTNESS_50] = true
    _imgStyleBrightness75[BAS_IMAGE.C_BRIGHTNESS_75] = true

    $rootScope.$on(BAS_STATE.EVT_STATE_SUCCESS, trackRoomBg)
    $rootScope.$on(BAS_ROOMS.EVT_ROOMS_UPDATED, trackRoomBg)
    $rootScope.$on(BAS_ROOMS.EVT_CURRENT_ROOM_CHANGED, trackRoomBg)
  }

  /**
   * @returns {TBackgroundState}
   */
  function get () {
    return state
  }

  function trackRoomBg () {

    var currentState, audioOnly, roomMusic

    currentState = $uiRouterGlobals.current.name
    audioOnly = CurrentBasCore.isAudioOnly()
    roomMusic = CurrentRoom.getRoomMusic()

    if (currentState === STATES.HOME) {

      state.bitBg.changeImageStyle(null)
      _trackHomeBg()

    } else if (currentState === STATES.ROOMS) {

      if (audioOnly) {

        if (roomMusic) {

          state.bitBg.track(roomMusic.bit)
          state.bitBg.changeImageStyle(_imgStyleBrightness30)

        } else {

          state.bitBg.unTrack()
          state.bitBg.changeImageStyle(null)
          state.bitBg.setImage(null)
        }

      } else {

        state.bitBg.changeImageStyle(_imgStyleBrightness75)
        _trackHomeBg()
      }

    } else if (basState.current.ROOM) {

      if (basState.current.MUSIC ||
        basState.current.VIDEO ||
        basState.current.THERMOSTAT_WEEK_SCHEDULER ||
        basState.current.ROOM_SCENES) {

        state.bitBg.changeImageStyle(_imgStyleBrightness30)

      } else if (basState.current.THERMOSTAT) {

        state.bitBg.changeImageStyle(_imgStyleBrightness50)

      } else {

        state.bitBg.changeImageStyle(_imgStyleBrightness75)
      }

      if (audioOnly) {

        if (roomMusic) {

          state.bitBg.track(roomMusic.bit)

        } else {

          state.bitBg.unTrack()
        }

      } else {

        _trackRoomBg()
      }

    } else if (basState.current.CAMERAS_DETAIL) {

      if (audioOnly) {

        state.bitBg.unTrack()
        state.bitBg.changeImageStyle(null)
        state.bitBg.setImage(null)

      } else {

        _trackHomeBg()
        state.bitBg.changeImageStyle(_imgStyleBrightness0)
      }

    } else if (basState.current.ALARMS ||
      basState.current.ENERGY ||
      basState.current.CAMERAS ||
      basState.current.TIMERS ||
      basState.current.SCHEDULES ||
      basState.current.SETTINGS ||
      basState.current.WEATHER ||
      basState.current.LISA ||
      basState.current.CALL_HISTORY) {

      if (audioOnly) {

        state.bitBg.unTrack()
        state.bitBg.changeImageStyle(null)
        state.bitBg.setImage(null)

      } else {

        _trackHomeBg()
        state.bitBg.changeImageStyle(_imgStyleBrightness30)
      }

    } else if (basState.current.SECURITY) {

      _trackHomeBg()
      state.bitBg.changeImageStyle(_imgStyleBrightness50)

    } else if (currentState.indexOf(SCENE_STATES.SCENES) !== -1 &&
      !basState.current.SCHEDULE_SCENES) {

      if (audioOnly) {

        state.bitBg.unTrack()
        state.bitBg.changeImageStyle(null)
        state.bitBg.setImage(null)

      } else {

        _trackHomeBg()
        state.bitBg.changeImageStyle(_imgStyleBrightness30)
      }

    } else {

      if (audioOnly) {

        state.bitBg.unTrack()
        state.bitBg.changeImageStyle(null)

      } else {

        state.bitBg.changeImageStyle(
          currentState === STATES.DOORS
            ? _imgStyleBrightness75
            : null
        )
        _trackHomeBg()
      }
    }
  }

  function _trackHomeBg () {

    var home

    home = RoomsHelper.getHome()

    state.bitBg.track(home ? home.bitBg : null)
  }

  function _trackRoomBg () {

    var room, roomMusic

    room = CurrentRoom.getRoom()

    if (room) {

      state.roomBitBg.setDefaultImage(room.bitBg.defaultImage)

      roomMusic = CurrentRoom.getRoomMusic()

      state.roomBitBg.track(
        (basState.current.MUSIC && roomMusic)
          ? roomMusic.bit
          : room.bitBg
      )

    } else {

      state.roomBitBg.setDefaultImage(null)
      state.roomBitBg.unTrack()
      state.roomBitBg.setImage('')
    }

    state.bitBg.track(state.roomBitBg)
  }
}
