'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('homeCtrl', [
    '$rootScope',
    '$scope',
    '$uiRouterGlobals',
    'BAS_APP',
    'ICONS',
    'STATES',
    'BAS_SCENE',
    'BAS_STATE',
    'BAS_CURRENT_CORE',
    'BAS_HOME_DASHBOARD',
    'BAS_ROOM',
    'BAS_ROOMS',
    'BAS_WEATHER',
    'BasState',
    'BasAppDevice',
    'Weather',
    'BasTile',
    'RoomsHelper',
    'BasCameras',
    'BasHomeDashboard',
    'BasMessages',
    homeCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param $uiRouterGlobals
 * @param {BAS_APP} BAS_APP
 * @param {ICONS} ICONS
 * @param {STATES} STATES
 * @param {BAS_SCENE} BAS_SCENE
 * @param {BAS_STATE} BAS_STATE
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_HOME_DASHBOARD} BAS_HOME_DASHBOARD
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_WEATHER} BAS_WEATHER
 * @param {BasState} BasState
 * @param {BasAppDevice} BasAppDevice
 * @param {Weather} Weather
 * @param BasTile
 * @param {RoomsHelper} RoomsHelper
 * @param {BasCameras} BasCameras
 * @param {BasHomeDashboard} BasHomeDashboard
 * @param {BasMessages} BasMessages
 */
function homeCtrl (
  $rootScope,
  $scope,
  $uiRouterGlobals,
  BAS_APP,
  ICONS,
  STATES,
  BAS_SCENE,
  BAS_STATE,
  BAS_CURRENT_CORE,
  BAS_HOME_DASHBOARD,
  BAS_ROOM,
  BAS_ROOMS,
  BAS_WEATHER,
  BasState,
  BasAppDevice,
  Weather,
  BasTile,
  RoomsHelper,
  BasCameras,
  BasHomeDashboard,
  BasMessages
) {
  var home = this

  var _listeners = []
  var _connectedListener = null

  home.ICONS = ICONS

  home.$uiRouterGlobals = $uiRouterGlobals

  /**
   * @type {BasRooms}
   */
  home.rooms = BAS_ROOMS.ROOMS

  /**
   * @type {BasAppDeviceState}
   */
  home.basAppDeviceState = BasAppDevice.get()

  /**
   * @type {TWeatherState}
   */
  home.weather = Weather.get()

  /**
   * @type {TBasHomeDashboard}
   */
  home.dashboard = BasHomeDashboard.get()

  /**
   * @type {TMessagesState}
   */
  home.messages = BasMessages.get()

  /**
   * @type {number}
   */
  home.dpi = BasAppDevice.getDpi()

  home.onUpClick = onUpClick
  home.onDownClick = onDownClick
  home.onScrollViewChange = onScrollViewChange
  home.selectTile = selectTile
  home.showWeather = showWeather

  init()

  function init () {

    Weather.update()

    $scope.$on('$destroy', _onDestroy)

    _listeners.push($rootScope.$on(
      BAS_APP.EVT_RESUME,
      _onResume
    ))
    _listeners.push($rootScope.$on(
      BAS_HOME_DASHBOARD.EVT_HOME_DASHBOARD_UPDATED,
      _syncScope
    ))
    _listeners.push($rootScope.$on(
      BAS_WEATHER.EVT_WEATHER_STATION_STATE_UPDATED,
      _syncScope
    ))
    _listeners.push($rootScope.$on(
      BAS_SCENE.EVT_IMAGES_UPDATED,
      _onSceneImageUpdated
    ))
    _listeners.push($rootScope.$on(
      BAS_ROOM.EVT_SCENES_UPDATED,
      _onScenesUpdated
    ))
  }

  /**
   * @private
   */
  function _onResume () {

    // Wait for CONNECTED event
    _setConnectedListener()
  }

  /**
   * @private
   * @param {Object} _event
   * @param {boolean} state
   */
  function _onConnected (_event, state) {

    if (state === true) {

      _removeConnectedListener()
      Weather.update()
    }
  }

  /**
   * @private
   * @param {Object} _event
   * @param {BasRoom} room
   */
  function _onSceneImageUpdated (_event, room) {

    var _room

    _room = RoomsHelper.getHome()

    if (_room && room && _room.id === room.id) {

      $scope.$applyAsync()
    }
  }

  /**
   * @private
   * @param {Object} _event
   * @param {BasRoom} room
   */
  function _onScenesUpdated (_event, room) {
    const _room = RoomsHelper.getHome()

    if (_room && room && _room.id === room.id) {

      $scope.$applyAsync()
    }
  }

  function onDownClick () {

    if (
      !$uiRouterGlobals.transition &&
      $uiRouterGlobals.current.name === STATES.HOME
    ) {
      BasState.go(
        STATES.HOME,
        {
          view: BAS_STATE.S_HOME_VIEW_OPTIONS
        }
      )
    }
  }

  function onUpClick () {

    if (
      !$uiRouterGlobals.transition &&
      $uiRouterGlobals.current.name === STATES.HOME
    ) {
      BasState.go(
        STATES.HOME,
        {
          view: BAS_STATE.S_HOME_VIEW_DASHBOARD
        }
      )
    }
  }

  /**
   * @param {string} view
   */
  function onScrollViewChange (view) {

    if (
      BasUtil.isString(view) &&
      !$uiRouterGlobals.transition &&
      $uiRouterGlobals.current.name === STATES.HOME
    ) {
      BasState.go(
        STATES.HOME,
        {
          view: view
        }
      )
    }
  }

  function selectTile (item) {

    if (BasUtil.isObject(item)) {

      switch (item.id) {
        case BasTile.ID_SCHEDULER:

          BasState.go(STATES.SCHEDULES)

          break
        case BAS_ROOMS.FUNCTIONS.SCENES:

          BasState.go(STATES.SCENES)

          break
        case BasTile.ID_SETTINGS:

          BasState.go(STATES.SETTINGS)

          break
        case BasTile.ID_TIMERS:

          BasState.go(STATES.TIMERS)

          break
        case BasTile.ID_CAMERAS:

          BasCameras.goToCameras()

          break
        case BasTile.ID_INTERCOM:

          BasState.go(
            STATES.ROOMS,
            {
              view: BAS_STATE.S_ROOMS_VIEW_INTERCOM
            }
          )

          break
        case BasTile.ID_DOORS:

          BasState.go(STATES.DOORS)

          break
        case BasTile.ID_ENERGY:

          BasState.go(STATES.ENERGY)

          break
        case BasTile.ID_WEATHER:

          BasState.go(STATES.WEATHER)

          break

        case BasTile.ID_CALL_HISTORY:

          BasState.go(STATES.CALL_HISTORY)

          break

        case BasTile.ID_SECURITY:

          BasState.go(STATES.SECURITY)

          break

        case BAS_ROOMS.FUNCTIONS.MUSIC:

          BasState.go(
            STATES.ROOMS,
            {
              view: BAS_STATE.S_ROOMS_VIEW_MUSIC
            }
          )

          break
        case BAS_ROOMS.FUNCTIONS.THERMOSTAT:

          BasState.go(
            STATES.ROOMS,
            {
              view: BAS_STATE.S_ROOMS_VIEW_THERMOSTATS
            }
          )

          break
      }
    }
  }

  function showWeather () {
    BasState.go(STATES.WEATHER)
  }

  function _setConnectedListener () {

    _removeConnectedListener()
    _connectedListener = $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_CORE_CONNECTED,
      _onConnected
    )
  }

  function _removeConnectedListener () {

    BasUtil.execute(_connectedListener)
  }

  function _syncScope () {

    $scope.$applyAsync()
  }

  function _onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
    _removeConnectedListener()
  }
}
