'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('avRoomSelectModalController', [
    '$scope',
    '$rootScope',
    'BAS_ROOMS',
    'BAS_ROOM',
    'BAS_SOURCE',
    'BAS_SOURCES',
    'BAS_MODAL',
    'BAS_SPLASH',
    'RoomsHelper',
    'SourcesHelper',
    'BasRoom',
    'BasModal',
    'close',
    'basModalConfig',
    avRoomSelectModalController
  ])

/**
 * @typedef {Object} TRoomExtraInfo
 * @property {boolean} isMaster
 * @property {boolean} originallySelected
 * @property {boolean} selected
 */

/**
 * @param $scope
 * @param $rootScope
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BAS_SOURCES} BAS_SOURCES
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BAS_SPLASH} BAS_SPLASH
 * @param {RoomsHelper} RoomsHelper
 * @param {SourcesHelper} SourcesHelper
 * @param {BasRoom} BasRoom
 * @param {BasModal} BasModal
 * @param close
 * @param basInputModalConfig
 */
function avRoomSelectModalController (
  $scope,
  $rootScope,
  BAS_ROOMS,
  BAS_ROOM,
  BAS_SOURCE,
  BAS_SOURCES,
  BAS_MODAL,
  BAS_SPLASH,
  RoomsHelper,
  SourcesHelper,
  BasRoom,
  BasModal,
  close,
  basInputModalConfig
) {
  var modal = this

  var listeners = []

  var CSS_CAN_DISBAND = 'modal-avselect-can-disband'
  var CSS_CAN_LEAVE = 'modal-avselect-can-leave'

  modal.basInputModalConfig = basInputModalConfig

  /**
   * UUID for current room in this modal
   *
   * @type {string}
   */
  modal.roomUuid = ''

  /**
   * UUID for current source in this modal
   *
   * @type {string}
   */
  modal.sourceUuid = ''

  /**
   * Maps a room uuid to it's extra info object.
   * If room uuid is not available in the mapping, it should not be shown as
   * option.
   *
   * @type {Object<string, TRoomExtraInfo>}
   */
  modal.roomInfo = {}

  /**
   * Subtitle under main title, will be set to floor + room name
   *
   * @type {string}
   */
  modal.subtitle = ''

  /**
   * Whether loading indicator should be shown and all input disabled
   *
   * @type {boolean}
   */
  modal.isLoading = false

  /**
   * @type {BAS_ROOMS}
   */
  modal.BAS_ROOMS = BAS_ROOMS

  /**
   * @type {BAS_SOURCES}
   */
  modal.BAS_SOURCES = BAS_SOURCES

  modal.closeModal = closeModal
  modal.save = save
  modal.toggleRoom = toggleRoom
  modal.leaveGroup = leaveGroup
  modal.disbandGroup = disbandGroup

  modal.css = {}

  init()

  function init () {

    if (!syncRoom()) return
    if (!syncSource()) return
    syncRoomInfo()
    syncSelection()
    syncCanLeaveOrDisband()

    $scope.$on('$destroy', _onDestroy)
    listeners.push($rootScope.$on(
      BAS_ROOM.EVT_SOURCE_CHANGED,
      _onSourceChanged
    ))
    listeners.push($rootScope.$on(
      BAS_ROOM.EVT_AVAILABLE_CHANGED,
      _onRoomAvailableChanged
    ))
    listeners.push($rootScope.$on(
      BAS_SOURCES.EVT_SOURCES_UPDATED,
      _onSourcesUpdated
    ))
    listeners.push($rootScope.$on(
      BAS_SOURCE.EVT_AVAILABLE_CHANGE,
      _onSourceAvailableChanged
    ))
    listeners.push($rootScope.$on(
      BAS_SOURCE.EVT_LISTENING_ROOMS_CHANGE,
      _onSourceListeningRoomsChanged
    ))
    listeners.push($rootScope.$on(
      BAS_ROOMS.EVT_ROOMS_UPDATED,
      _onRoomsUpdated
    ))
    listeners.push($rootScope.$on(
      BAS_SPLASH.EVT_SPLASH_VISIBILITY_CHANGED,
      _onSplashVisibility
    ))
  }

  /**
   * Toggle selection for room with given uuid
   *
   * @param {string} roomUuid
   */
  function toggleRoom (roomUuid) {

    if (BasUtil.isNEString(roomUuid) &&
      modal.roomInfo[roomUuid]) {

      modal.roomInfo[roomUuid].selected =
        !modal.roomInfo[roomUuid].selected
    }
  }

  /**
   * Apply the current room selection, close modal after
   * selection is successfully saved.
   */
  function save () {

    handleSelection()
  }

  /**
   * If following another rooms source, this will reset the source for this
   * room to it's default source.
   */
  function leaveGroup () {

    var music, room

    if (!modal.css[CSS_CAN_LEAVE]) return

    room = getRoom()

    if (room &&
      room.hasAVMusic &&
      room.hasAVMusic()) {

      music = _getBasRoomMusic()

      if (music) {

        modal.isLoading = true
        music.setSource('').then(onResetSource, onResetSourceError)
      }
    }

    function onResetSource () {

      closeModal()
    }

    function onResetSourceError () {

      modal.isLoading = false
      BasModal.show(BAS_MODAL.T_ERROR)
    }
  }

  /**
   * If this room is a virtual source group, reset the source of all
   * listening rooms except for the room with the source of this virtual
   * source group .
   */
  function disbandGroup () {

    var room, music, listeningRoomsLength, roomSourceMap, i, roomId
    var defaultRoom, defaultRoomId

    if (!modal.css[CSS_CAN_DISBAND]) return

    room = getRoom()

    if (room &&
      room.hasAVSource &&
      room.hasAVSource()) {

      music = _getBasRoomMusic()

      if (music.basSource &&
        music.basSource.listeningRooms) {

        defaultRoom = RoomsHelper.getRoomWithDefaultSource(
          music.basSource.uuid
        )
        defaultRoomId = defaultRoom ? defaultRoom.id : ''

        // Reset all rooms' sources
        //  (except for room with source as default)

        roomSourceMap = {}
        listeningRoomsLength = music.basSource.listeningRooms.length

        for (i = 0; i < listeningRoomsLength; i++) {

          roomId = music.basSource.listeningRooms[i]

          if (roomId !== defaultRoomId) roomSourceMap[roomId] = ''
        }
      }

      modal.isLoading = true
      setSourcesOnRooms(roomSourceMap).then(onSetSources, onSetSourcesError)
    }

    function onSetSources () {

      modal.isLoading = false
      closeModal()
    }

    function onSetSourcesError () {

      modal.isLoading = false
      BasModal.show(BAS_MODAL.T_ERROR)
    }
  }

  /**
   * Close the modal without saving/applying anything.
   */
  function closeModal () {

    _onDestroy()

    // Close the modal
    close()
  }

  function syncRoomInfo () {

    var i, k, room, music, uiAll, floor, source, roomInfo, keepUuids, showFloor

    source = getSource()
    keepUuids = []

    if (BasUtil.isObject(source)) {

      uiAll = BAS_ROOMS.ROOMS.music.uiAllCollection

      // Loop over floors
      for (i = 0; i < uiAll.length; i++) {

        floor = uiAll[i]
        showFloor = false

        // Loop over rooms per floor
        for (k = 0; k < floor.items.length; k++) {

          room = BAS_ROOMS.ROOMS.rooms[floor.items[k]]

          if (room &&
            room.hasAVMusic &&
            room.hasAVMusic() &&
            room.music) {

            music = room.music

            // Only show rooms which have the given sourceUuid as
            //  compatible source
            if (music.isCompatibleSource(modal.sourceUuid)) {

              showFloor = true

              roomInfo = modal.roomInfo[room.id]

              if (!roomInfo) {

                roomInfo = {}
                modal.roomInfo[room.id] = roomInfo
              }

              roomInfo.isMaster = (
                music.basSource &&
                BasUtil.isNEString(modal.sourceUuid) &&
                modal.sourceUuid === music.getDefaultSource() &&
                modal.sourceUuid === music.basSource.uuid
              )
              roomInfo.originallySelected = (
                music.basSource &&
                music.basSource.uuid === modal.sourceUuid
              )

              keepUuids.push(room.id)
            }
          }

          // Don't show floor title if no rooms of that floor are shown
          if (showFloor) {

            // Empty object to make the object truthy (to check in template)
            modal.roomInfo[floor.id] = {}
            keepUuids.push(floor.id)
          }
        }
      }
    }

    modal.roomInfo = BasUtil.keepKeys(modal.roomInfo, keepUuids)
  }

  /**
   * @returns {boolean} valid room synced
   */
  function syncRoom () {

    var room

    modal.roomUuid = basInputModalConfig.roomId

    if (BasUtil.isNEString(modal.roomUuid)) {

      room = RoomsHelper.getRoomForId(modal.roomUuid)

      if (!room) {

        // Room no longer exists, close modal
        closeModal()
        return false
      }

      syncRoomName()
      return true
    }

    return false
  }

  function syncRoomName () {

    var room

    room = getRoom()

    if (room) modal.subtitle = RoomsHelper.getUniqueSourceIdentifier(room)
  }

  /**
   * @returns {boolean} valid source synced
   */
  function syncSource () {

    var music

    music = _getBasRoomMusic()

    if (music &&
      music.basSource &&
      music.basSource.groupable &&
      music.basSource.available) {

      modal.sourceUuid = music.basSource.uuid

      return true

    }

    if (BasUtil.isNEString(basInputModalConfig.sourceUuid) &&
      SourcesHelper.getBasSource(basInputModalConfig.sourceUuid)) {

      modal.sourceUuid = basInputModalConfig.sourceUuid

      return true

    }

    // Selected room no longer has a valid source, close the modal
    closeModal()
    return false
  }

  function syncCanLeaveOrDisband () {

    var music, room, minDisbandListeners, minLeaveListeners, listeningRoomsCount

    modal.css[CSS_CAN_LEAVE] = false
    modal.css[CSS_CAN_DISBAND] = false

    room = getRoom()

    if (
      room &&
      room.hasAV &&
      room.hasAV()
    ) {

      music = _getBasRoomMusic()

      if (
        music.basSource &&
        music.basSource.listeningRooms
      ) {
        listeningRoomsCount = music.basSource.listeningRooms.length

        switch (music.basSource.type) {

          case BAS_SOURCE.T_SONOS:
          case BAS_SOURCE.T_BOSPEAKER:

            minDisbandListeners = 2
            minLeaveListeners = 2
            break

          default:

            // Disable disbanding/grouping for asano or unknown music types
            minDisbandListeners = -1
            minLeaveListeners = -1
            break
        }

        // 'Leaving' a group can only be done as a room with av music
        //  (room context)
        modal.css[CSS_CAN_LEAVE] = (
          room.hasAVMusic &&
          room.hasAVMusic() &&
          minLeaveListeners >= 0 &&
          listeningRoomsCount >= minLeaveListeners
        )
        // 'Disbanding' a group can only be done as a room with av source
        //  (source context)
        modal.css[CSS_CAN_DISBAND] = (
          room.hasAVSource &&
          room.hasAVSource() &&
          minDisbandListeners >= 0 &&
          listeningRoomsCount >= minDisbandListeners
        )
      }
    }
  }

  function syncSelection () {

    var keys, info, i

    keys = Object.keys(modal.roomInfo)

    for (i = 0; i < keys.length; i++) {

      info = modal.roomInfo[keys[i]]

      info.selected = info.originallySelected
    }
  }

  function deselectUnavailableRooms () {

    var keys, room, i

    keys = Object.keys(modal.roomInfo)

    for (i = 0; i < keys.length; i++) {

      room = RoomsHelper.getRoomForId(keys[i])

      if (!room || !room.music || !room.music.isAvailable) {

        modal.roomInfo[keys[i]].selected = false
      }
    }
  }

  function handleSelection () {

    var i, room, keys, roomInfo, roomSourceMap

    keys = Object.keys(modal.roomInfo)

    roomSourceMap = {}

    for (i = 0; i < keys.length; i++) {

      room = RoomsHelper.getRoomForId(keys[i])
      roomInfo = modal.roomInfo[keys[i]]

      if (roomInfo.originallySelected !== roomInfo.selected &&
        room instanceof BasRoom &&
        room.hasAVMusic &&
        room.hasAVMusic() &&
        room.music) {

        roomSourceMap[room.id] = roomInfo.selected
          ? modal.sourceUuid
          : ''
      }
    }

    modal.isLoading = true
    setSourcesOnRooms(roomSourceMap).then(onSetSources, onSetSourcesError)

    function onSetSources () {

      modal.isLoading = false
      closeModal()
    }

    function onSetSourcesError () {

      modal.isLoading = false
      BasModal.show(BAS_MODAL.T_ERROR)
    }
  }

  /**
   * @param {Object<string, string>} roomSourceMap
   * @returns Promise
   */
  function setSourcesOnRooms (roomSourceMap) {

    var roomKeys, roomsLength, room, sourceUuid, i
    var promises

    if (!BasUtil.isObject(roomSourceMap)) {
      return Promise.reject(new Error('invalid roomSourceMap'))
    }

    roomKeys = Object.keys(roomSourceMap)
    roomsLength = roomKeys.length

    promises = []

    for (i = 0; i < roomsLength; i++) {

      room = RoomsHelper.getRoomForId(roomKeys[i])

      if (room &&
        room.hasAVMusic &&
        room.hasAVMusic() &&
        room.music) {

        sourceUuid = roomSourceMap[roomKeys[i]]

        if (BasUtil.isString(sourceUuid)) {

          promises.push(room.music.setSource(sourceUuid))
        }
      }
    }

    if (promises.length > 0) {

      modal.isLoading = true
      return BasUtil.promiseAll(promises).then(onAllSourcesSet)

    } else {

      return Promise.resolve()
    }

    /**
     * @param {BasPromiseResult[]} promiseResults
     * @returns {Promise}
     */
    function onAllSourcesSet (promiseResults) {

      var _i

      if (Array.isArray(promiseResults)) {

        for (_i = 0; _i < promiseResults.length; _i++) {

          if (promiseResults[_i].resolved === false) {

            return Promise.reject(new Error('not resolved'))
          }
        }

        return Promise.resolve()
      }

      return Promise.reject(new Error('promiseResults is not an array'))
    }
  }

  /**
   * @returns {?BasRoom}
   */
  function getRoom () {

    return RoomsHelper.getRoomForId(modal.roomUuid)
  }

  function getSource () {

    return SourcesHelper.getBasSource(modal.sourceUuid)
  }

  function _getBasRoomMusic () {

    var room = getRoom()

    return (
      room &&
      room.hasAV &&
      room.hasAV() &&
      room.music
    )
      ? room.music
      : null
  }

  function _onSourceChanged () {

    var oldSource, newSource

    oldSource = getSource()

    if (!syncRoom()) return
    if (!syncSource()) return
    syncRoomInfo()
    syncCanLeaveOrDisband()

    newSource = getSource()

    // If the source itself of this room has changed, reset selection to the
    //  'current' grouping state

    if (newSource && oldSource &&
      newSource.uuid !== oldSource.uuid) {

      syncSelection()
    }
  }

  function _onSourceListeningRoomsChanged () {

    syncRoomName()
    syncSelection()
    syncCanLeaveOrDisband()
  }

  function _onSourcesUpdated () {

    _onSourceChanged()
  }

  function _onSourceAvailableChanged () {

    if (!syncSource()) return
    syncRoomInfo()
    deselectUnavailableRooms()
  }

  function _onRoomAvailableChanged () {

    var room

    room = getRoom()

    if (!room ||
      !room.music ||
      !room.music.isAvailable) {

      closeModal()

    } else {

      deselectUnavailableRooms()
    }
  }

  function _onRoomsUpdated () {

    if (!syncRoom()) return
    if (!syncSource()) return
    syncCanLeaveOrDisband()
  }

  function _onSplashVisibility () {

    close()
  }

  function _onDestroy () {

    BasUtil.executeArray(listeners)
    listeners = []
  }
}
