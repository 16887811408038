'use strict'

import * as BasUtil from '@basalte/bas-util'
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  Tooltip,
  TimeSeriesScale
} from 'chart.js'
import 'chartjs-adapter-moment'
import ZoomPlugin from 'chartjs-plugin-zoom'
import AnnotationPlugin from 'chartjs-plugin-annotation'

Chart.register(
  CategoryScale,
  LinearScale,
  BarController,
  BarElement,
  TimeSeriesScale,
  Tooltip,
  ZoomPlugin,
  AnnotationPlugin
)

angular
  .module('basalteApp')
  .directive('basEnergyChart', basEnergyChartDirective)

const GRID_COLOR = 'rgba(48, 48, 48, 1)'
const BAR_COLOR = 'rgba(255, 136, 29, 1)'
const BAR_COLOR_GREEN = 'rgba(139, 195, 74, 1)'
const BAR_COLOR_BLUE = 'rgba(1, 169, 244, 1)'

/**
 * @typedef {Object} TBasEnergyChartData
 * @property {number[]} data
 * @property {string[]} labels
 */

function basEnergyChartDirective () {

  return {
    controller: [
      'moment',
      '$element',
      'BAS_ENERGY',
      'BAS_INTL',
      'BasEnergyHelper',
      'BasIntl',
      'BasUtilities',
      controller
    ],
    controllerAs: 'basEnergyChart',
    bindToController: {
      basEnergyMeter: '<?',
      basEnergyChartData: '<?',
      basEnergyChartType: '@?',
      basEnergyChartMode: '<?'
    }
  }

  /**
   * @param moment
   * @param element
   * @param {BAS_ENERGY} BAS_ENERGY
   * @param {BAS_INTL} BAS_INTL
   * @param {BasEnergyHelper} BasEnergyHelper
   * @param {BasIntl} BasIntl
   * @param {BasUtilities} BasUtilities
   */
  function controller (
    moment,
    element,
    BAS_ENERGY,
    BAS_INTL,
    BasEnergyHelper,
    BasIntl,
    BasUtilities
  ) {
    const basEnergyChart = this

    let _chart = null
    let _data = null
    let isDetail = false

    /**
     * @private
     * @type {?TBasEnergyChartData}
     */
    let _parsedData = null

    this.$onChanges = _onChanges
    this.$postLink = _onPostLink
    this.$onDestroy = _onDestroy

    function _onPostLink () {

      _createChart()
      _setCanvasStyles()
    }

    function _setCanvasStyles () {
      if (element[0]) {
        // Fix vertical scroll when charts can pan horizontally
        element[0].style.touchAction = 'pan-y'
      }
    }

    function _onChanges () {
      isDetail = basEnergyChart.basEnergyChartMode === BAS_ENERGY.T_ID_DETAIL

      if (_data !== basEnergyChart.basEnergyChartData) {
        _data = basEnergyChart.basEnergyChartData
        _onChartData(_data)
      }
    }

    function _onChartData (data) {
      let newData = data

      if (isDetail) {
        if (basEnergyChart.basEnergyChartType === BAS_ENERGY.T_ID_HIST_YEAR) {
          newData = BasEnergyHelper.groupByMonth(newData)
        }
      } else {

        if (
          basEnergyChart.basEnergyChartType === BAS_ENERGY.T_ID_HIST_LAST_WEEK
        ) {
          newData = BasEnergyHelper.groupByDay(newData)
        }

        if (basEnergyChart.basEnergyChartType === BAS_ENERGY.T_ID_HIST_MONTH) {
          newData = BasEnergyHelper.groupByMonth(newData)
        }

        if (basEnergyChart.basEnergyChartType === BAS_ENERGY.T_ID_HIST_YEAR) {
          newData = BasEnergyHelper.groupByYear(newData)
        }
      }

      _parsedData = _parseData(newData)

      if (_chart) {
        _chart.options.scales.x.time.unit = _getTimeUnit()
        _chart.options.scales.x.time.tooltipFormat = _getTooltipFormat()
        // Order is important here as the values depend on the _parsedData
        _chart.data = _parsedData
        _chart.options.scales.y.suggestedMax = _getYMax()
        _chart.options.scales.x.max = _getMaxTime()
        _chart.options.plugins.zoom.limits.x.min = _getMinTime()
        _chart.options.plugins.zoom.limits.x.max = _getMaxTime()
        // Min is determined using the max value
        _chart.options.scales.x.min = _getMinTime()
        _chart.options.plugins.annotation.annotations.annotation =
          _getAnnotation()
      }

      _updateChart()
    }

    function _getAnnotation () {
      let annotation = null

      if (_chart && _chart.data?.datasets[0]?.data) {
        const avrg = average(_chart.data.datasets[0].data)

        annotation = {
          type: 'line',
          borderColor: 'white',
          borderDash: [6, 6],
          borderDashOffset: 0,
          borderWidth: 1,
          label: {
            display: true,
            content: () =>
              BasUtilities.translate('average') +
              ': ' +
              _getYTick(avrg, 2),
            position: 'end',
            font: {
              size: 11
            }
          },
          scaleID: 'y',
          value: () => avrg
        }
      }

      return annotation

      function average (data) {
        return data.reduce((a, b) => a + b.y, 0) / data.length
      }
    }

    function _createChart () {

      const config = {
        type: 'bar',
        options: {
          maintainAspectRatio: false,
          backgroundColor: _getBarColor(),
          barPercentage: 0.95,
          categoryPercentage: 1,
          borderRadius: 4,
          scales: {
            x: {
              type: 'timeseries',
              min: _getMinTime(),
              max: _getMaxTime(),
              ticks: {
                autoSkipPadding: 4
              },
              time: {
                unit: _getTimeUnit(),
                displayFormats: {
                  month: 'MMM YY',
                  day: 'ddd, D MMM',
                  hour: BasIntl.get().timeFormat === BAS_INTL.TIME_FORMAT_12
                    ? 'h A'
                    : 'HH:mm'
                },
                tooltipFormat: _getTooltipFormat()
              },
              grid: {
                display: false
              },
              offsetAfterAutoskip: true
            },
            y: {
              beginAtZero: true,
              grid: {
                color: GRID_COLOR,
                lineWidth: 1,
                drawTicks: false
              },
              ticks: {
                padding: 12,
                callback: (v) => _getYTick(v, 0)
              }
            }
          },
          plugins: {
            tooltip: {
              callbacks: {
                label: _getTooltipLabel
              }
            },
            zoom: {
              pan: {
                enabled: true,
                mode: 'x'
              },
              limits: {
                x: {
                  max: _getMaxTime()
                }
              }
            }
          }
        }
      }

      if (!_chart && element[0]) {
        _chart = new Chart(element[0].getContext('2d'), config)
      }
    }

    function _updateChart () {

      if (_chart?.update) {
        _chart.update()
      }
    }

    function _getYMax () {
      if (_chart) {
        const data = _chart.data?.datasets[0]?.data

        if (data) {
          const values = data.map(d => d.y)

          return Math.max(...values)
        }
      }

      return null
    }

    function _getBarColor () {
      switch (basEnergyChart?.basEnergyMeter?.type) {
        case BAS_ENERGY.T_ID_ENERGY:
          if (!basEnergyChart.basEnergyMeter.consuming) {
            return BAR_COLOR_GREEN
          }
          break
        case BAS_ENERGY.T_ID_GAS:
          // No special color
          break
        case BAS_ENERGY.T_ID_WATER:
          return BAR_COLOR_BLUE
      }

      return BAR_COLOR
    }

    function _getMinTime () {

      // The oldest timestamp in the received data is our min time
      if (_chart) {
        const data = _chart.data?.datasets[0]?.data
        if (data) {
          const timestamps = data.filter(d => !!d.y).map(d => d.x)
          return moment(timestamps[0]).valueOf()
        }
      }

      return moment().valueOf()
    }

    function _getMaxTime () {
      if (_chart) {
        const data = _chart.data?.datasets[0]?.data

        if (data) {
          const timestamps = data.filter(d => !!d.y).map(d => d.x)

          return moment(timestamps[timestamps.length - 1]).valueOf()
        }
      }

      return moment().valueOf()
    }

    function _getTimeUnit () {
      if (isDetail) {
        switch (basEnergyChart.basEnergyChartType) {
          case BAS_ENERGY.T_ID_HIST_YEAR:
            return 'month'
          case BAS_ENERGY.T_ID_HIST_MONTH:
          default:
            return 'day'
        }
      } else {
        switch (basEnergyChart.basEnergyChartType) {
          case BAS_ENERGY.T_ID_HIST_TODAY:
            return 'hour'
          case BAS_ENERGY.T_ID_HIST_YEAR:
            return 'year'
          case BAS_ENERGY.T_ID_HIST_MONTH:
            return 'month'
          case BAS_ENERGY.T_ID_HIST_LAST_WEEK:
          default:
            return 'day'
        }
      }
    }

    function _getTooltipFormat () {
      if (isDetail) {
        switch (basEnergyChart.basEnergyChartType) {
          case BAS_ENERGY.T_ID_HIST_YEAR:
            return 'MMMM YYYY'
          case BAS_ENERGY.T_ID_HIST_MONTH:
          default:
            return 'dddd, MMM D YYYY'
        }
      } else {
        switch (basEnergyChart.basEnergyChartType) {
          case BAS_ENERGY.T_ID_HIST_TODAY:
            return BasIntl.get().timeFormat === BAS_INTL.TIME_FORMAT_12
              ? 'h A'
              : 'HH:mm'
          case BAS_ENERGY.T_ID_HIST_YEAR:
            return 'YYYY'
          case BAS_ENERGY.T_ID_HIST_MONTH:
            return 'MMMM YYYY'
          case BAS_ENERGY.T_ID_HIST_LAST_WEEK:
          default:
            return 'dddd, MMM D YYYY'
        }
      }
    }

    /**
     * @private
     * @param {(TBasEnergyMeterResult|TBasEnergyMeterGroupedResults)} input
     * @returns {Object}
     */
    function _parseData (input) {
      const unit = _getDataUnit()

      const result = {
        datasets: []
      }

      const data = input?.result?.map((entry) => ({
        x: BasUtil.stripTimezoneFromIso8601(entry[0]),
        y: unit === BAS_ENERGY.U_WH ? _divideBy1000(entry[1]) : entry[1]
      }))

      result.datasets.push({
        data: data
      })

      return result
    }

    function _getYTick (value, precision) {

      const unit = _getDisplayUnit()
      return value.toFixed(precision) + (unit
        ? ' ' + unit
        : '')
    }

    function _getTooltipLabel (context) {

      const unit = _getDisplayUnit()
      const value = context.parsed.y

      return unit
        ? value.toFixed(2) + ' ' + unit
        : context.formattedValue
    }

    function _divideBy1000 (x) {
      return BasUtil.isVNumber(x) ? x / 1000 : x
    }

    function _getDataUnit () {

      return basEnergyChart?.basEnergyMeter?.uiTotalUnit ?? ''
    }

    function _getDisplayUnit () {

      const unit = _getDataUnit()
      return unit === BAS_ENERGY.U_WH ? BAS_ENERGY.U_KWH : unit
    }

    function _destroyChart () {
      if (_chart?.destroy) _chart.destroy()
      _chart = null
    }

    function _onDestroy () {

      _destroyChart()
      _parsedData = null
    }
  }
}
