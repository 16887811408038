'use strict'

angular
  .module('basalteApp')
  .service('BasRoomsHelper', [
    'STATES',
    'BAS_SOURCE',
    'BAS_MESSAGE',
    'RoomsHelper',
    'CurrentRoom',
    'BasMessageHelper',
    BasRoomsHelper
  ])

/**
 * @constructor
 * @param {STATES} STATES
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BAS_MESSAGE} BAS_MESSAGE
 * @param {RoomsHelper} RoomsHelper
 * @param {CurrentRoom} CurrentRoom
 * @param {BasMessageHelper} BasMessageHelper
 */
function BasRoomsHelper (
  STATES,
  BAS_SOURCE,
  BAS_MESSAGE,
  RoomsHelper,
  CurrentRoom,
  BasMessageHelper
) {
  this.goToMusic = goToMusic
  this.goToVideo = goToVideo

  /**
   * @param {string} roomId
   * @returns {Promise}
   */
  function goToMusic (roomId) {

    var basRoom, basSource, messageType

    basRoom = RoomsHelper.getRoomForId(roomId)

    if (
      basRoom &&
      basRoom.isSourceGroup() &&
      basRoom.music
    ) {
      // Is the app going to a real room or a virtual (source group) room?

      basSource = basRoom.music.basSource

      // Targeted for the Sonos use-case,
      // only with Sonos there is a "default source"
      basRoom = basSource
        ? RoomsHelper.getRoomWithDefaultSource(basSource.uuid)
        : null

      if (!basRoom && basSource) {

        if ([BAS_SOURCE.T_SONOS, BAS_SOURCE.T_BOSPEAKER]
          .includes(basSource.type)) {

          // Sonos doesn't really do sources,
          // so go to the first room with this source
          basRoom = RoomsHelper.getFirstRoomWithSource(basSource.uuid)

        } else {

          basRoom = RoomsHelper.getRoomForId(basSource.uuid)
        }
      }
    }

    if (basRoom) {

      if (basRoom.music && basRoom.music.hasMusic()) {

        messageType = BasMessageHelper.getMessageType(
          basRoom,
          false
        )

        if (
          messageType !== BAS_MESSAGE.MESSAGE_VIDEO_IN_NON_VIDEO_CTX &&
          messageType !== BAS_MESSAGE.MESSAGE_SOURCE_EMPTY
        ) {

          return CurrentRoom.go(
            STATES.MUSIC_PLAYER,
            {
              room: basRoom.id
            }
          )

        } else {

          return CurrentRoom.go(
            STATES.MUSIC_SOURCES,
            {
              room: basRoom.id
            }
          )
        }

      } else {

        return CurrentRoom.go(
          STATES.ROOM,
          {
            room: basRoom.id
          }
        )
      }
    }

    return Promise.reject(new Error('no room'))
  }

  /**
   * @param {string} roomId
   * @returns {Promise}
   */
  function goToVideo (roomId) {

    var basRoom, messageType

    basRoom = RoomsHelper.getRoomForId(roomId)

    if (basRoom) {

      if (basRoom.video && basRoom.video.hasVideo()) {

        messageType = BasMessageHelper.getMessageType(
          basRoom,
          true
        )

        if (
          messageType !== BAS_MESSAGE.MESSAGE_AUDIO_IN_NON_AUDIO_CTX &&
          messageType !== BAS_MESSAGE.MESSAGE_SOURCE_EMPTY
        ) {

          return CurrentRoom.go(
            STATES.VIDEO_PLAYER,
            {
              room: basRoom.id
            }
          )

        } else {

          return CurrentRoom.go(
            STATES.VIDEO_SOURCES,
            {
              room: basRoom.id
            }
          )
        }

      } else {

        return CurrentRoom.go(
          STATES.ROOM,
          {
            room: basRoom.id
          }
        )
      }
    }

    return Promise.reject(new Error('no room'))
  }
}
