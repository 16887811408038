'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('AppLink', [
    '$window',
    '$rootScope',
    '$timeout',
    'BAS_APP',
    'CurrentBasCore',
    'BasAppDevice',
    'BasLiveAccount',
    AppLink
  ])

/**
 * @param $window
 * @param $rootScope
 * @param $timeout
 * @param {BAS_APP} BAS_APP
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasAppDevice} BasAppDevice
 * @param {BasLiveAccount} BasLiveAccount
 */
function AppLink (
  $window,
  $rootScope,
  $timeout,
  BAS_APP,
  CurrentBasCore,
  BasAppDevice,
  BasLiveAccount
) {
  var INAPPBROWSER_SYSTEM = '_system'

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  var APP_BASALTE = 'basalte'
  var APP_SPOTIFY = 'spotify'

  /**
   * @typedef {Object} TAppIds
   * @property {string} scheme
   * @property {string} appStore
   * @property {string} playStore
   * @property {string} web
   */

  /**
   * @private
   * @type {TAppIds}
   */
  var _BASALTE = {
    scheme: 'basalte-musicapp',
    appStore: 'https://itunes.apple.com/us/app/asano-music/id1092129961?mt=8',
    playStore: 'https://play.google.com/store/apps/details?id=be.basalte.musicapp',
    web: 'https://musicapp.basalte.be'
  }

  /**
   * @private
   * @type {TAppIds}
   */
  var _SPOTIFY = {
    scheme: 'spotify',
    appStore: 'https://itunes.apple.com/us/app/spotify-music/id324684580?mt=8',
    playStore: 'https://play.google.com/store/apps/details?id=com.spotify.music',
    web: 'https://spotify.com'
  }

  /**
   * @constant {string}
   */
  this.APP_BASASLTE = APP_BASALTE

  /**
   * @constant {string}
   */
  this.APP_SPOTIFY = APP_SPOTIFY

  this.openPage = openPage
  this.openApp = openApp
  this.openStore = openStore
  this.openDspPage = openDspPage
  this.openAppUri = openAppUri
  this.openBasalteLive = openBasalteLive

  /**
   * @param {string} pageAddress
   */
  function openPage (pageAddress) {

    if (!BasAppDevice.isCoreClient() && BasUtil.isNEString(pageAddress)) {

      _openInNewWindowOrTab(pageAddress)
    }
  }

  function openDspPage () {

    var _basServer, _host

    if (!BasAppDevice.isCoreClient() && CurrentBasCore.hasCore()) {

      _basServer = currentBasCoreState.core.core.server

      if (_basServer) {

        _host = _basServer.host

        if (_host) {
          let url = _basServer.host.getHTTPUrl('dsp')

          if (_basServer.connectedUsingCloudAccount) {
            const { token } = BasLiveAccount.getJWT()
            url += '#?jwt=' + token
          }

          _openInNewWindowOrTab(
            url
          )
        }
      }
    }
  }

  /**
   * @param {string} path
   * @param {Object?} qParams
   * @returns {void}
   */
  function openBasalteLive (path, qParams) {

    const host = BasLiveAccount.isUsingDevEnvironment()
      ? 'dev.basalte.live'
      : 'basalte.live'

    const isDemoMode = currentBasCoreState.core.isDemo()

    const params = {
      ...(isDemoMode
        ? { demoMode: 'true' }
        : {
            ...qParams,
            idToken: BasLiveAccount.getIdToken(),
            accessToken: BasLiveAccount.getAccessToken(),
            refreshToken: BasLiveAccount.getRefreshToken()
          }
      )
    }
    const searchParams = new URLSearchParams(params).toString()

    const url = `https://${host}/${path}?${searchParams}`

    return _openInNewWindowOrTab(url)
  }

  /**
   * @param {string} appName
   */
  function openApp (appName) {

    if (!BasAppDevice.isCoreClient()) {

      switch (appName) {
        case APP_SPOTIFY:
          _openAppWithFallback(_SPOTIFY)
          break
      }
    }
  }

  /**
   * @param {string} appUri
   * @param [callback]
   */
  function openAppUri (appUri, callback) {

    var _util

    if (BasUtil.isNEString(appUri)) {

      _util = _getUtil()

      if (_util) {

        _util.openApp(appUri, callback)
      }
    }
  }

  /**
   * @param {string} appName
   */
  function openStore (appName) {

    if (!BasAppDevice.isCoreClient()) {

      switch (appName) {
        case APP_BASALTE:
          _openStore(_BASALTE)
          break
      }
    }
  }

  /**
   * @private
   * @param {TAppIds} app
   */
  function _openStore (app) {

    if (BasAppDevice.isAndroid()) {

      _openInNewWindowOrTab(app.playStore)

    } else if (BasAppDevice.isIos()) {

      _openInNewWindowOrTab(app.appStore)

    } else {

      // Open new page
      _openInNewWindowOrTab(app.web)
    }
  }

  /**
   * @private
   * @param {TAppIds} app
   */
  function _openAppWithFallback (app) {

    var appHasOpened, deRegisterCordovaPause

    appHasOpened = false

    if (BasAppDevice.isAndroid()) {

      // Listen for pause event to indicate if app has launched
      deRegisterCordovaPause =
        $rootScope.$on(BAS_APP.EVT_PAUSE, _onCordovaPause)

      // Attempt to open the Spotify app
      _openInNewWindowOrTab(app.scheme + '://')

      // Wait if app did not launch, then open store
      $timeout(_onAndroidTimeout, 500, false)

    } else if (BasAppDevice.isIos()) {

      // Attempt to open the Spotify app
      _openInNewWindowOrTab(app.scheme + '://')

      // Wait 500ms and open iTunes Store
      $timeout(_onIosTimeout, 500, false)

    } else {

      // Open new page
      _openInNewWindowOrTab(app.web)
    }

    function _onCordovaPause () {

      // Make sure listener is de-registered
      BasUtil.execute(deRegisterCordovaPause)
      deRegisterCordovaPause = null

      // App link should have been opened
      appHasOpened = true
    }

    function _onAndroidTimeout () {

      // Make sure listener is de-registered
      BasUtil.execute(deRegisterCordovaPause)
      deRegisterCordovaPause = null

      // Check if app link has been opened
      if (!appHasOpened) {

        _openInNewWindowOrTab(app.playStore)
      }
    }

    function _onIosTimeout () {

      _openInNewWindowOrTab(app.appStore)
    }
  }

  /**
   * @private
   * @param url
   */
  function _openInNewWindowOrTab (url) {

    var _inAppBrowser

    _inAppBrowser = _getInAppBrowser()

    if (_inAppBrowser) {

      _inAppBrowser.open(url, INAPPBROWSER_SYSTEM)

    }
  }

  function _getInAppBrowser () {

    if ($window['cordova'] &&
      $window['cordova']['InAppBrowser']) {

      return $window['cordova']['InAppBrowser']
    }

    return null
  }

  function _getUtil () {

    if (BasUtil.isObject($window['basalteCordova']) &&
      BasUtil.isObject($window['basalteCordova']['util'])) {

      return $window['basalteCordova']['util']
    }

    return null
  }
}
