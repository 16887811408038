'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasAppHeader', [
    '$rootScope',
    '$uiRouterGlobals',
    'STATES',
    'BAS_STATE',
    'UI_HELPER',
    'BAS_CURRENT_CORE',
    'SETTINGS_STATES',
    'BAS_APP_HEADER',
    'CurrentBasCore',
    'BasStateHelper',
    'UiHelper',
    'CurrentRoom',
    'BasAppDevice',
    'RoomsHelper',
    'SourcesHelper',
    'BasSettingsStateHelper',
    'BasUtilities',
    BasAppHeader
  ])

/**
 * @typedef {Object} BasAppHeaderState
 * @property {Object<string, boolean>} css
 * @property {string} title
 */

/**
 * Helper service to keep track of the header state
 *
 * @constructor
 * @param $rootScope
 * @param $uiRouterGlobals
 * @param {STATES} STATES
 * @param {BAS_STATE} BAS_STATE
 * @param {UI_HELPER} UI_HELPER
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {SETTINGS_STATES} SETTINGS_STATES
 * @param {BAS_APP_HEADER} BAS_APP_HEADER
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasStateHelper} BasStateHelper
 * @param {UiHelper} UiHelper
 * @param {CurrentRoom} CurrentRoom
 * @param {BasAppDevice} BasAppDevice
 * @param {RoomsHelper} RoomsHelper
 * @param {SourcesHelper} SourcesHelper
 * @param {BasSettingsStateHelper} BasSettingsStateHelper
 * @param {BasUtilities} BasUtilities
 */
function BasAppHeader (
  $rootScope,
  $uiRouterGlobals,
  STATES,
  BAS_STATE,
  UI_HELPER,
  BAS_CURRENT_CORE,
  SETTINGS_STATES,
  BAS_APP_HEADER,
  CurrentBasCore,
  BasStateHelper,
  UiHelper,
  CurrentRoom,
  BasAppDevice,
  RoomsHelper,
  SourcesHelper,
  BasSettingsStateHelper,
  BasUtilities
) {
  var CSS_MH_HAS_BACK = 'mh-has-back'
  var CSS_MH_HAS_CONTEXT = 'mh-has-context'
  var CSS_MH_HAS_TILES = 'mh-has-tiles'
  var CSS_MH_HAS_HOME_ROOMS = 'mh-has-home-rooms'
  var CSS_MH_SHOW_HOME_LOGO = 'mh-show-home-logo'
  var CSS_MH_SHOW_STATE_TITLE = 'mh-show-state-title'
  var CSS_MH_SHOW_ROOMS_ICON = 'mh-show-rooms-icon'
  var CSS_MH_HAS_HOME_ACCESS = 'mh-show-home-icon'
  var CSS_MH_SHOW_MAIN = 'mh-show-main'
  var CSS_MH_TITLE_CENTER = 'mh-title-center'

  /**
   * @type {BasUi}
   */
  var basUi = UiHelper.get()

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {BasAppHeaderState}
   */
  var state = {
    css: {},
    title: ''
  }

  this.get = get
  this.setTitle = setTitle
  this.determineSettingsHeaderTitle = determineSettingsHeaderTitle

  init()

  function init () {
    _resetCss()

    $rootScope.$on(BAS_STATE.EVT_STATE_SUCCESS, _onStateChange)
    $rootScope.$on(UI_HELPER.EVT_RESIZE, _onUiSize)
    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CURRENT_CORE_PROJECT_INFO,
      _onCurrentCoreProjectInfo
    )
    $rootScope.$on(BAS_CURRENT_CORE.EVT_CORE_SYSTEM, _onSystemProperties)
    $rootScope.$on(BAS_CURRENT_CORE.EVT_CORE_PROFILE_CREATED, _onProfileCreated)
    $rootScope.$on(BAS_CURRENT_CORE.EVT_CORE_MUSIC_RECEIVED, _onMusic)
    $rootScope.$on(BAS_CURRENT_CORE.EVT_CORE_ROOMS_RECEIVED, _onRooms)

    $rootScope.$on('$translateChangeSuccess', _onTranslationChange)

    _syncTitle()
    _syncIcon()
    _syncBackButton()
  }

  /**
   * @returns {BasAppHeaderState}
   */
  function get () {
    return state
  }

  /**
   * @param {string} title
   */
  function setTitle (title) {
    state.title = title
    state.css[CSS_MH_SHOW_STATE_TITLE] = !!title
  }

  /**
   *
   * @returns {?string}
   */
  function determineSettingsHeaderTitle () {

    var _stateName, _stateHelper

    _stateName = $uiRouterGlobals.current.name
    _stateHelper = BasSettingsStateHelper.getHelper()

    if (_stateHelper.isCurrent(SETTINGS_STATES.GENERAL)) {

      switch (_stateName) {

        case STATES.SETTINGS_GENERAL_LISA_ORDER:

          return BasUtilities.translate('page_order')

        default:

          return BasUtilities.translate('general')
      }

    } else if (_stateHelper.isCurrent(SETTINGS_STATES.LIVE)) {

      return BasUtilities.translate('basalte_account')

    } else if (_stateHelper.isCurrent(SETTINGS_STATES.PROJECT)) {

      switch (_stateName) {

        case STATES.SETTINGS_PROJECT_PROJECT_IMAGE:

          return BasUtilities.translate('project_image')

        case STATES.SETTINGS_PROJECT_ROOM_IMAGES:

          return BasUtilities.translate('room_images')

        case STATES.SETTINGS_PROJECT_ROOM_IMAGES_ROOM:

          return _getRoomName($uiRouterGlobals.params.roomUuid)

        default:

          return BasUtilities.translate('project')
      }
    } else if (_stateHelper.isCurrent(SETTINGS_STATES.MUSIC)) {

      switch (_stateName) {

        case STATES.SETTINGS_MUSIC_DEFAULT_ROOMS:

          return BasUtilities.translate('default_rooms')

        case STATES.SETTINGS_MUSIC_DEFAULT_ROOMS_ROOMS:

          return _getSourceName($uiRouterGlobals.params.stream)

        case STATES.SETTINGS_MUSIC_KNX_PRESETS:

          return BasUtilities.translate('knx_presets')

        case STATES.SETTINGS_MUSIC_KNX_PRESETS_PRESETS:

          return _getSourceName($uiRouterGlobals.params.stream)

        case STATES.SETTINGS_MUSIC_KNX_PRESETS_PRESET:

          return _getPresetName(
            $uiRouterGlobals.params.stream,
            $uiRouterGlobals.params.presetId
          )

        case STATES.SETTINGS_MUSIC_STREAMING:

          return BasUtilities.translate('streaming_services')

        case STATES.SETTINGS_MUSIC_STREAMING_SPOTIFY_WEB:

          return BasUtilities.translate('spotify')

        case STATES.SETTINGS_MUSIC_STREAMING_DEEZER:

          return BasUtilities.translate('deezer')

        case STATES.SETTINGS_MUSIC_STREAMING_TIDAL:

          return BasUtilities.translate('tidal')

        default:

          return BasUtilities.translate('music')
      }
    } else if (_stateHelper.isCurrent(SETTINGS_STATES.CAMERAS)) {

      return BasUtilities.translate('cameras')

    } else if (_stateHelper.isCurrent(SETTINGS_STATES.DOOR_PHONE)) {

      return BasUtilities.translate('door_phone')

    } else if (_stateHelper.isCurrent(SETTINGS_STATES.NETWORK)) {

      return BasUtilities.translate('network')

    } else if (_stateHelper.isCurrent(SETTINGS_STATES.ABOUT)) {

      return BasUtilities.translate('about')
    }
  }

  function _onStateChange () {

    var oldStateHash

    oldStateHash = BasUtil.objectToHash(state)

    _syncTitle()
    _syncIcon()
    _syncBackButton()

    if (BasUtil.objectToHash(state) !== oldStateHash) {

      $rootScope.$emit(BAS_APP_HEADER.EVT_STATE_CHANGED)
    }
  }

  function _onUiSize () {

    var oldStateHash

    oldStateHash = BasUtil.objectToHash(state)

    _syncBackButton()
    _syncTitle()

    if (BasUtil.objectToHash(state) !== oldStateHash) {

      $rootScope.$emit(BAS_APP_HEADER.EVT_STATE_CHANGED)
    }
  }

  function _onCurrentCoreProjectInfo () {

    var oldStateHash

    oldStateHash = BasUtil.objectToHash(state)

    _syncTitle()

    if (BasUtil.objectToHash(state) !== oldStateHash) {

      $rootScope.$emit(BAS_APP_HEADER.EVT_STATE_CHANGED)
    }
  }

  function _onSystemProperties () {

    var oldStateHash

    oldStateHash = BasUtil.objectToHash(state)

    _syncIcon()
    _syncBackButton()

    if (BasUtil.objectToHash(state) !== oldStateHash) {

      $rootScope.$emit(BAS_APP_HEADER.EVT_STATE_CHANGED)
    }
  }

  function _onProfileCreated () {

    var oldStateHash

    oldStateHash = BasUtil.objectToHash(state)

    _syncIcon()

    if (BasUtil.objectToHash(state) !== oldStateHash) {

      $rootScope.$emit(BAS_APP_HEADER.EVT_STATE_CHANGED)
    }
  }

  function _onMusic () {

    var oldStateHash

    oldStateHash = BasUtil.objectToHash(state)

    if (BasUtil.objectToHash(state) !== oldStateHash) {

      $rootScope.$emit(BAS_APP_HEADER.EVT_STATE_CHANGED)
    }
  }

  function _onRooms () {

    var oldStateHash

    oldStateHash = BasUtil.objectToHash(state)

    if (BasUtil.objectToHash(state) !== oldStateHash) {

      $rootScope.$emit(BAS_APP_HEADER.EVT_STATE_CHANGED)
    }
  }

  function _onTranslationChange () {

    var oldStateHash

    oldStateHash = BasUtil.objectToHash(state)

    _syncTitle()

    if (BasUtil.objectToHash(state) !== oldStateHash) {

      $rootScope.$emit(BAS_APP_HEADER.EVT_STATE_CHANGED)
    }
  }

  function _syncIcon () {

    var stateName, audioOnly, inConnectState

    stateName = $uiRouterGlobals.current.name
    inConnectState = BasStateHelper.hasBaseState(stateName, STATES.CONNECT)
    audioOnly = CurrentBasCore.isAudioOnly()
    state.css[CSS_MH_SHOW_HOME_LOGO] = stateName === STATES.HOME
    state.css[CSS_MH_HAS_HOME_ROOMS] = !audioOnly && !inConnectState
    state.css[CSS_MH_SHOW_ROOMS_ICON] = (
      !BasStateHelper.hasBaseState(stateName, STATES.ROOMS) &&
      !inConnectState
    )

    state.css[CSS_MH_HAS_HOME_ACCESS] = (
      CurrentBasCore.hasHomePage() &&
      !inConnectState
    )

    state.css[CSS_MH_HAS_CONTEXT] = !(
      BasStateHelper.isSubState(STATES.CONNECT_LIVE, stateName) ||
      stateName === STATES.CONNECT_PROFILES ||
      BasStateHelper.hasBaseState(stateName, STATES.DEVICE_SETTINGS) ||
      BasStateHelper.hasBaseState(stateName, STATES.LISA) ||
      (
        BasAppDevice.isLisa() &&
        BasStateHelper.hasBaseState(stateName, STATES.SETTINGS)
      )
    )

    state.css[CSS_MH_HAS_TILES] = stateName === STATES.LISA
  }

  function _syncBackButton () {

    var stateName, audioOnly, singleAudioRoomId

    stateName = $uiRouterGlobals.current.name

    audioOnly = CurrentBasCore.isAudioOnly()
    singleAudioRoomId = CurrentBasCore.hasCore()
      ? currentBasCoreState.core.core.singleAudioRoomId
      : ''

    state.css[CSS_MH_HAS_BACK] = stateName === STATES.ROOMS

    if (BasStateHelper.hasBaseState(stateName, STATES.ROOM)) {

      if (!(singleAudioRoomId && audioOnly)) {

        state.css[CSS_MH_HAS_BACK] = true

      } else {

        if (basUi.prop.wMedium) {

          // No special cases for tablets

        } else {

          // Provide back button for phones in library view

          if (
            BasStateHelper.hasBaseState(stateName, STATES.MUSIC_LIBRARY) &&
            stateName.length > STATES.MUSIC_LIBRARY.length
          ) {

            state.css[CSS_MH_HAS_BACK] = true
          }
        }
      }

    } else if (BasStateHelper.hasBaseState(stateName, STATES.LISA)) {

      if (
        BasStateHelper.hasBaseState(stateName, STATES.LISA_LIGHTS) ||
        BasStateHelper.hasBaseState(stateName, STATES.LISA_SCENES) ||
        BasStateHelper.hasBaseState(stateName, STATES.LISA_SHADES) ||
        BasStateHelper.hasBaseState(stateName, STATES.LISA_MUSIC) ||
        BasStateHelper.hasBaseState(stateName, STATES.LISA_THERMOSTAT) ||
        BasStateHelper.hasBaseState(stateName, STATES.LISA_INTERCOM) ||
        BasStateHelper.hasBaseState(stateName, STATES.LISA_CAMERAS)
      ) {
        state.css[CSS_MH_HAS_BACK] = true
      }

    } else if (
      BasStateHelper.hasBaseState(stateName, STATES.SCENES) ||
      BasStateHelper.hasBaseState(stateName, STATES.HOME) ||
      BasStateHelper.hasBaseState(stateName, STATES.WEATHER) ||
      BasStateHelper.hasBaseState(stateName, STATES.SECURITY) ||
      BasStateHelper.hasBaseState(stateName, STATES.CALL_HISTORY) ||
      BasStateHelper.hasBaseState(stateName, STATES.SCHEDULES) ||
      BasStateHelper.hasBaseState(stateName, STATES.TIMERS) ||
      BasStateHelper.hasBaseState(stateName, STATES.ALARMS) ||
      BasStateHelper.hasBaseState(stateName, STATES.ENERGY) ||
      BasStateHelper.hasBaseState(stateName, STATES.DOORS) ||
      BasStateHelper.hasBaseState(stateName, STATES.SETTINGS) ||
      BasStateHelper.hasBaseState(stateName, STATES.CAMERAS) ||
      BasStateHelper.hasBaseState(stateName, STATES.DEVICE_SETTINGS)
    ) {

      state.css[CSS_MH_HAS_BACK] = true

    } else if (
      BasStateHelper.hasBaseState(stateName, STATES.CONNECT)
    ) {

      if (
        BasStateHelper.isSubState(STATES.CONNECT_LIVE, stateName) ||
        stateName === STATES.CONNECT_PROFILES
      ) {

        state.css[CSS_MH_HAS_BACK] = true
      }
    }
  }

  function _syncTitle () {

    var stateName

    stateName = $uiRouterGlobals.current.name

    state.title = ''
    state.css[CSS_MH_SHOW_STATE_TITLE] = false
    state.css[CSS_MH_SHOW_MAIN] = false
    state.css[CSS_MH_TITLE_CENTER] = false

    if (BasStateHelper.hasBaseState(stateName, STATES.SCENES)) {

      state.title = BasUtilities.translate('scenes')
      state.css[CSS_MH_SHOW_STATE_TITLE] = true

    } else if (BasStateHelper.hasBaseState(stateName, STATES.SCHEDULES)) {

      state.title = BasUtilities.translate('schedules')
      state.css[CSS_MH_SHOW_STATE_TITLE] = true

    } else if (BasStateHelper.hasBaseState(stateName, STATES.ENERGY)) {

      state.title = BasUtilities.translate('energy')
      state.css[CSS_MH_SHOW_STATE_TITLE] = true

    } else if (BasStateHelper.hasBaseState(stateName, STATES.CAMERAS)) {

      state.title = BasUtilities.translate('cameras')
      state.css[CSS_MH_SHOW_STATE_TITLE] = true

    } else if (BasStateHelper.hasBaseState(stateName, STATES.DOORS)) {

      state.title = BasUtilities.translate('doors')
      state.css[CSS_MH_SHOW_STATE_TITLE] = true

    } else if (BasStateHelper.hasBaseState(stateName, STATES.ALARMS)) {

      state.title = BasUtilities.translate('alarms')
      state.css[CSS_MH_SHOW_STATE_TITLE] = true

    } else if (BasStateHelper.hasBaseState(stateName, STATES.SETTINGS)) {

      if (!basUi.prop.wMedium) {

        state.title = determineSettingsHeaderTitle()

        if (!state.title) state.title = BasUtilities.translate('settings')

      } else {

        state.title = BasUtilities.translate('settings')
      }

      state.css[CSS_MH_SHOW_STATE_TITLE] = true

    } else if (
      BasStateHelper.hasBaseState(
        stateName,
        STATES.DEVICE_SETTINGS
      )
    ) {

      if (!basUi.prop.wMedium) {

        state.title = determineSettingsHeaderTitle()

        if (!state.title) {

          state.title = BasUtilities.translate('device_settings')
        }

      } else {

        state.title = BasUtilities.translate('device_settings')
      }

      state.css[CSS_MH_SHOW_STATE_TITLE] = true

    } else if (BasStateHelper.hasBaseState(stateName, STATES.TIMERS)) {

      state.title =
        BasUtilities.translate('timers')
      state.css[CSS_MH_SHOW_STATE_TITLE] = true

    } else if (BasStateHelper.hasBaseState(stateName, STATES.WEATHER)) {

      state.title = BasUtilities.translate('weather_information')
      state.css[CSS_MH_SHOW_STATE_TITLE] = true

    } else if (BasStateHelper.hasBaseState(stateName, STATES.SECURITY)) {

      state.title = BasUtilities.translate('security')
      state.css[CSS_MH_SHOW_STATE_TITLE] = true

    } else if (BasStateHelper.hasBaseState(stateName, STATES.CALL_HISTORY)) {

      state.title = BasUtilities.translate('call_history')
      state.css[CSS_MH_SHOW_STATE_TITLE] = true

    } else if (BasStateHelper.hasBaseState(stateName, STATES.LISA)) {

      if (BasStateHelper.hasBaseState(stateName, STATES.LISA_LIGHT_DETAIL)) {

        state.title = _getLightTitle($uiRouterGlobals.params.light)
        state.css[CSS_MH_SHOW_STATE_TITLE] = true

      } else if (BasStateHelper.hasBaseState(stateName, STATES.LISA_LIGHTS)) {

        state.title = BasUtilities.translate('lights')
        state.css[CSS_MH_SHOW_STATE_TITLE] = true

      } else if (
        BasStateHelper.hasBaseState(stateName, STATES.LISA_SHADE_DETAIL)
      ) {
        state.title = _getShadeTitle($uiRouterGlobals.params.shade)
        state.css[CSS_MH_SHOW_STATE_TITLE] = true

      } else if (BasStateHelper.hasBaseState(stateName, STATES.LISA_SHADES)) {

        state.title = BasUtilities.translate('shades')
        state.css[CSS_MH_SHOW_STATE_TITLE] = true

      } else if (
        BasStateHelper.hasBaseState(stateName, STATES.LISA_THERMOSTAT)
      ) {
        _setThermostatTitle()

      } else if (BasStateHelper.hasBaseState(stateName, STATES.LISA_CAMERAS)) {

        state.title = BasUtilities.translate('cameras')
        state.css[CSS_MH_SHOW_STATE_TITLE] = true

      } else if (BasStateHelper.hasBaseState(stateName, STATES.LISA_INTERCOM)) {

        state.title = BasUtilities.translate('intercom')
        state.css[CSS_MH_SHOW_STATE_TITLE] = true
      }
    } else if (stateName === STATES.CONNECT_PROFILES) {

      state.title = CurrentBasCore.getName()
      if (!state.title) state.title = BasUtilities.translate('home')
      state.css[CSS_MH_SHOW_STATE_TITLE] = true
      state.css[CSS_MH_TITLE_CENTER] = true

    } else if (BasStateHelper.hasBaseState(stateName, STATES.ROOMS)) {

      state.css[CSS_MH_SHOW_MAIN] = true
    }
  }

  function _setThermostatTitle () {
    const room = CurrentRoom.getRoom()

    if (room.thermostats?.thermostats?.length === 1) {
      setTitle(BasUtilities.translate('thermostat'))
    }
  }

  function _getLightTitle (lightUuid) {

    var room, light, words

    room = CurrentRoom.getRoom()

    if (room && room.lights && room.lights.getLight) {

      light = room.lights.getLight(lightUuid)

      if (light) {

        words = []

        if (light.name) words.push(light.name)
        if (light.typeStr) words.push(light.typeStr)
        if (light.location) words.push(light.locationStr)

        return words.join(' - ')
      }
    }

    return BasUtilities.translate('light')
  }

  function _getShadeTitle (shadeUuid) {

    var room, shade, words

    room = CurrentRoom.getRoom()

    if (room && room.shades && room.shades.getShade) {

      shade = room.shades.getShade(shadeUuid)

      if (shade) {

        words = []

        if (shade.name) words.push(shade.name)
        if (shade.typeStr) words.push(shade.typeStr)
        if (shade.location) words.push(shade.locationStr)

        return words.join(' - ')
      }
    }

    return BasUtilities.translate('shade')
  }

  function _getRoomName (roomId) {

    var room

    room = RoomsHelper.getRoomForId(roomId)

    if (room) {

      return room.basTitle.value
    }

    return BasUtilities.translate('room')
  }

  function _getSourceName (sourceUuid) {

    var source

    source = SourcesHelper.getBasSource(sourceUuid)

    if (source) {

      return source.name
    }

    return BasUtilities.translate('stream')
  }

  function _getPresetName (sourceUuid, presetId) {

    var source

    source = SourcesHelper.getBasSource(sourceUuid)

    if (source && source.knxPresets && source.knxPresets.presets[presetId]) {

      return source.knxPresets.presets[presetId].name
    }

    return BasUtilities.translate('knx_presets')
  }

  function _resetCss () {

    state.css[CSS_MH_HAS_BACK] = false
    state.css[CSS_MH_HAS_CONTEXT] = false
    state.css[CSS_MH_HAS_HOME_ROOMS] = false
    state.css[CSS_MH_SHOW_HOME_LOGO] = false
    state.css[CSS_MH_SHOW_STATE_TITLE] = false
    state.css[CSS_MH_SHOW_ROOMS_ICON] = false
    state.css[CSS_MH_HAS_HOME_ACCESS] = false
    state.css[CSS_MH_SHOW_MAIN] = false
    state.css[CSS_MH_TITLE_CENTER] = false
    state.css[CSS_MH_HAS_TILES] = false
  }
}
