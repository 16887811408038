'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('SourcesHelper', [
    'BAS_SOURCES',
    'BAS_SOURCE',
    'BasSourceHelper',
    SourcesHelper
  ])

/**
 * @callback CBasForEachSource
 * @param {BasSource} source
 * @param {number} index
 * @param {string[]} uuids
 */

/**
 * @constructor
 * @param {BAS_SOURCES} BAS_SOURCES
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BasSourceHelper} BasSourceHelper
 */
function SourcesHelper (
  BAS_SOURCES,
  BAS_SOURCE,
  BasSourceHelper
) {
  this.getPlayer = getPlayer
  this.getFirstAudioPlayer = getFirstAudioPlayer
  this.getBasSource = getBasSource
  this.getBaseSource = getBaseSource
  this.getActiveSource = getActiveSource
  this.getSpotifySource = getSpotifySource
  this.getAudioSource = getAudioSource
  this.getVideoSource = getVideoSource
  this.forEachSource = forEachSource

  /**
   * Returns a BasSource (type Player) if a player is found for ID
   *
   * @param {number} id
   * @returns {?BasSource}
   */
  function getPlayer (id) {

    var uuid, source

    if (BasUtil.isVNumber(id)) {

      uuid = BAS_SOURCES.SOURCES.players[id]

      if (BasUtil.isNEString(uuid)) {

        source = getSource(uuid)
        if (source) return source
      }
    }

    return null
  }

  /**
   * Returns the first BasSource (type Asano/Player(legacy))
   *
   * @param {BasRoom} [basRoom]
   * @returns {?BasSource}
   */
  function getFirstAudioPlayer (basRoom) {

    var length, i, players, keys, uuid, source, sourceIds, basSource

    if (
      basRoom &&
      basRoom.hasMusic &&
      basRoom.hasMusic() &&
      basRoom.hasAVMusic()
    ) {
      sourceIds = basRoom.music.getCompatibleSources()
      length = sourceIds.length
      for (i = 0; i < length; i++) {

        uuid = sourceIds[i]
        basSource = getAudioSource(uuid)

        if (basSource && basSource.isAudioSource) {

          if (basSource.type === BAS_SOURCE.T_ASANO) {

            if (basSource.subType === BAS_SOURCE.ST_STREAM) return basSource

          } else if (
            [BAS_SOURCE.T_SONOS, BAS_SOURCE.T_BOSPEAKER]
              .includes(basSource.type)
          ) {

            return basSource
          }
        }
      }

    } else {

      players = BAS_SOURCES.SOURCES.players

      if (players) {

        keys = Object.keys(players)
        if (BasUtil.isNEArray(keys)) {

          uuid = players[keys[0]]

          if (BasUtil.isNEString(uuid)) {

            source = getSource(uuid)
            if (source) return source
          }
        }
      }
    }

    return null
  }

  /**
   * Spotify Barp BasSource for CobraNet ID
   *
   * @param {number} id
   * @returns {?BasSource}
   */
  function getSpotifySource (id) {

    var ids, i, length, source

    if (BasUtil.isPNumber(id)) {

      ids = BAS_SOURCES.SOURCES.barps[id]

      if (Array.isArray(ids)) {

        length = ids.length
        for (i = 0; i < length; i++) {

          source = BAS_SOURCES.SOURCES.sources[ids[i]]

          if (BasSourceHelper.checkSpotifyBarp(source)) return source
        }

      } else if (BasUtil.isNEString(ids)) {

        source = BAS_SOURCES.SOURCES.sources[ids]

        if (BasSourceHelper.checkSpotifyBarp(source)) return source
      }

    } else if (BasUtil.isNEString(id)) {

      ids = BAS_SOURCES.SOURCES.audioSources[id]

      if (BasUtil.isNEString(ids)) {

        source = BAS_SOURCES.SOURCES.sources[ids]

        if (source.spotify) return source
      }
    }

    return null
  }

  /**
   * Returns one of the base sources for the matching ID.
   * Base source means a player instead of a Barp,
   * whether the Barp would be connected or not.
   * If no matching source is found, return null.
   *
   * @param {number} id
   * @returns {?BasSource}
   */
  function getBaseSource (id) {

    var uuid, source

    if (BasUtil.isNEString(id)) {

      uuid = id

      source = getSource(uuid)
      if (source) return source

    } else if (BasUtil.isVNumber(id)) {

      // Players

      uuid = BAS_SOURCES.SOURCES.players[id]

      if (BasUtil.isNEString(uuid)) {

        source = getSource(uuid)
        if (source) return source
      }

      // Externals

      uuid = BAS_SOURCES.SOURCES.externals[id]

      if (BasUtil.isNEString(uuid)) {

        source = getSource(uuid)
        if (source) return source
      }

      // Bluetooths

      uuid = BAS_SOURCES.SOURCES.bluetooths[id]

      if (BasUtil.isNEString(uuid)) {

        source = getSource(uuid)
        if (source) return source
      }
    }

    return null
  }

  /**
   * AudioSource BasSource for uuid
   *
   * @param {string} uuid
   * @returns {?BasSource}
   */
  function getAudioSource (uuid) {

    var _uuid, source

    _uuid = BAS_SOURCES.SOURCES.audioSources[uuid]

    if (BasUtil.isNEString(_uuid)) {

      source = getSource(_uuid)
      if (source) return source
    }

    return null
  }

  /**
   * VideoSource BasSource for uuid
   *
   * @param {string} uuid
   * @returns {?BasSource}
   */
  function getVideoSource (uuid) {

    var _uuid, source

    _uuid = BAS_SOURCES.SOURCES.videoSources[uuid]

    if (BasUtil.isNEString(_uuid)) {

      source = getSource(_uuid)
      if (source) return source
    }

    return null
  }

  /**
   * In the case of a stream, will check if a Barp is connected or not.
   * Otherwise will return the matching source.
   * Returns null if no real source is found.
   *
   * @param {(number|string)} id
   * @returns {?BasSource}
   */
  function getActiveSource (id) {

    var uuid, length, i, source

    if (BasUtil.isNEString(id)) {

      // AudioSources

      return getAudioSource(id)

    } else if (BasUtil.isVNumber(id)) {

      // (connected) Barps

      uuid = BAS_SOURCES.SOURCES.barps[id]

      if (Array.isArray(uuid)) {

        length = uuid.length
        for (i = 0; i < length; i++) {

          source = getSource(uuid[i])
          if (source && source.isConnected()) return source
        }

      } else if (BasUtil.isNEString(uuid)) {

        source = getSource(uuid)
        if (source && source.isConnected()) return source
      }

      // Players

      uuid = BAS_SOURCES.SOURCES.players[id]

      if (BasUtil.isNEString(uuid)) {

        source = getSource(uuid)
        if (source) return source
      }

      // Externals

      uuid = BAS_SOURCES.SOURCES.externals[id]

      if (BasUtil.isNEString(uuid)) {

        source = getSource(uuid)
        if (source) return source
      }

      // Bluetooths

      uuid = BAS_SOURCES.SOURCES.bluetooths[id]

      if (BasUtil.isNEString(uuid)) {

        source = getSource(uuid)
        if (source) return source
      }

      // Notifications

      uuid = BAS_SOURCES.SOURCES.notifications[id]

      if (BasUtil.isNEString(uuid)) {

        source = getSource(uuid)
        if (source) return source
      }
    }

    return null
  }

  /**
   * Returns a BasSource for the specific ID or UUID.
   * Will return a Barp BasSource if the Barp is active for the given ID.
   *
   * @param {(number|string)} id
   * @returns {?BasSource}
   */
  function getBasSource (id) {

    var uuid, source

    if (BasUtil.isNEString(id)) {

      uuid = id

      source = getSource(uuid)
      if (source) return source

    } else if (BasUtil.isVNumber(id)) {

      // First, check for constant sources (off, mixed, ...)

      uuid = BAS_SOURCES.SOURCES.constants[id]

      if (BasUtil.isNEString(uuid)) {

        source = getSource(uuid)
        if (source) return source
      }

      // Second, check for a real source

      source = getActiveSource(id)
      if (source) return source
    }

    return null
  }

  /**
   * @param {(string|number)} uuid
   * @returns {?BasSource}
   */
  function getSource (uuid) {

    var source

    source = BAS_SOURCES.SOURCES.sources[uuid]
    return (source && source.getId) ? source : null
  }

  /**
   * @param {CBasForEachSource} callback
   */
  function forEachSource (callback) {

    var keys, length, i, uuid, basSource

    keys = Object.keys(BAS_SOURCES.SOURCES.sources)
    length = keys.length
    for (i = 0; i < length; i++) {

      uuid = keys[i]

      if (uuid) {

        basSource = BAS_SOURCES.SOURCES.sources[uuid]

        if (basSource) callback(basSource, i, keys)
      }
    }
  }
}
