'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('basMusicWidgetCtrl', [
    '$rootScope',
    '$scope',
    'STATES',
    'BAS_CURRENT_CORE',
    'BAS_ROOM',
    'BAS_ROOMS',
    'BAS_SOURCES',
    'BAS_SOURCE',
    'BAS_MESSAGE',
    'BAS_APP',
    'BasAppDevice',
    'CurrentBasCore',
    'Sources',
    'SourcesHelper',
    'BasMusicHelper',
    'BasState',
    'BasImageTrans',
    'BasUtilities',
    'BasMusicWidgetService',
    'CurrentRoom',
    'BasAppHeaderControlsBack',
    basMusicWidgetCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param {STATES} STATES
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_SOURCES} BAS_SOURCES
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BAS_MESSAGE} BAS_MESSAGE
 * @param {BAS_APP} BAS_APP
 * @param {BasAppDevice} BasAppDevice
 * @param {CurrentBasCore} CurrentBasCore
 * @param {Sources} Sources
 * @param {SourcesHelper} SourcesHelper
 * @param {BasMusicHelper} BasMusicHelper
 * @param {BasState} BasState
 * @param BasImageTrans
 * @param {BasUtilities} BasUtilities
 * @param {BasMusicWidgetService} BasMusicWidgetService
 * @param {CurrentRoom} CurrentRoom
 * @param {BasAppHeaderControlsBack} BasAppHeaderControlsBack
 */
function basMusicWidgetCtrl (
  $rootScope,
  $scope,
  STATES,
  BAS_CURRENT_CORE,
  BAS_ROOM,
  BAS_ROOMS,
  BAS_SOURCES,
  BAS_SOURCE,
  BAS_MESSAGE,
  BAS_APP,
  BasAppDevice,
  CurrentBasCore,
  Sources,
  SourcesHelper,
  BasMusicHelper,
  BasState,
  BasImageTrans,
  BasUtilities,
  BasMusicWidgetService,
  CurrentRoom,
  BasAppHeaderControlsBack
) {
  var musicWidget = this

  /**
   * After how much ms without any actions on the widget, that the controls
   * should be hidden.
   *
   * @constant {boolean}
   */
  var HIDE_CONTROLS_OVERLAY_TIMEOUT = 5000

  var CSS_SHOW_CONTROLS = 'widget-player-controls-show'

  var _listeners = []

  var _hideControlsTimeoutId = 0

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * Used to flip the widget when determining it's state
   * for the first time it comes in to view.
   *
   * @type {boolean}
   */
  var firstSync = true

  /**
   * Variable to tell music widget it is allowed to sync.
   * Will be set true if server has emitted event
   * that base variables have been loaded.
   *
   * @type {boolean}
   */
  var configReady = false

  /**
   * Used to track which event listeners need to be set
   *
   * @type {(number|string)}
   */
  var currentSourceId = 0

  /**
   * @type {BAS_ROOM}
   */
  musicWidget.BAS_ROOM = BAS_ROOM

  /**
   * @type {BAS_SOURCES}
   */
  musicWidget.BAS_SOURCES = BAS_SOURCES

  /**
   * @type {BAS_SOURCE}
   */
  musicWidget.BAS_SOURCE = BAS_SOURCE

  /**
   * @type {BAS_MESSAGE}
   */
  musicWidget.BAS_MESSAGE = BAS_MESSAGE

  /**
   * @type {BasImageTrans}
   */
  musicWidget.coverArtBit = BasMusicHelper.musicWidgetBit

  /**
   * @type {TCurrentRoomState}
   */
  musicWidget.currentRoom = CurrentRoom.get()

  /**
   * @type {BasRooms}
   */
  musicWidget.rooms = BAS_ROOMS.ROOMS

  /**
   * @type {BasImageTrans}
   */
  musicWidget.coverArtBit = new BasImageTrans({
    transitionType: BasImageTrans.TRANSITION_TYPE_FADE_FAST,
    debounceMs: 0,
    debounceMsNull: 0
  })

  musicWidget.dpi = BasAppDevice.getDpi()

  musicWidget.musicWidgetState = BasMusicWidgetService.get()
  musicWidget.css = {}
  musicWidget.css[CSS_SHOW_CONTROLS] = false
  musicWidget.messageNoFavourites = {}
  musicWidget.messageNoFavourites.type = BAS_MESSAGE.MESSAGE_FAVOURITES_EMPTY
  musicWidget.messageNoFavourites.txtMsgTitle =
    BasUtilities.translate('no_favourites')

  /**
   * Controls which favourites are shown.
   *
   * @type {(number|string)}
   */
  musicWidget.sourceId = 0

  musicWidget.toggle = toggle
  musicWidget.next = next
  musicWidget.previous = previous
  musicWidget.togglePlay = togglePlay
  musicWidget.selectFavourite = selectFavourite
  musicWidget.selectSource = selectSource
  musicWidget.goToLibrary = goToLibrary
  musicWidget.toggleShowControls = toggleShowControls
  musicWidget.volumeChange = volumeChange
  musicWidget.toggleStereoWidening = toggleStereoWidening
  musicWidget.toggleMute = toggleMute
  musicWidget.toggleRepeat = toggleRepeat
  musicWidget.toggleShuffle = toggleShuffle
  musicWidget.toggleAdvancedFlipsideShowStream =
    toggleAdvancedFlipsideShowStream
  musicWidget.turnMusicOff = turnMusicOff
  musicWidget.goToStreams = goToStreams
  musicWidget.onCoverAreaTap = onCoverAreaTap

  musicWidget.$postLink = onPostLink
  musicWidget.$onChanges = onChanges
  musicWidget.$onDestroy = onDestroy

  init()

  function init () {

    _syncCoverArt()

    _listeners.push($rootScope.$on(
      BAS_APP.EVT_RESUME,
      _onResume
    ))
    _listeners.push($rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_CORE_CONNECTED,
      _onBasCurrentCoreConnected
    ))
    _listeners.push($rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_MUSIC_RECEIVED,
      _onMusicConfig
    ))
    _listeners.push($rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_ROOMS_RECEIVED,
      _onRooms
    ))
    _listeners.push($rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_AV_SOURCES_RECEIVED,
      _onAVSources
    ))
    _listeners.push($rootScope.$on(
      '$translateChangeSuccess',
      _onTranslation
    ))
    _listeners.push(
      $rootScope.$on(
        BAS_SOURCE.EVT_FAVOURITES_UPDATED,
        _onFavouritesUpdated
      )
    )

    _syncConfigReady()

    Sources.registerFor(BAS_SOURCE.COL_EVT_SIMPLE)
  }

  /**
   * @private
   * @param {Object} _event
   * @param {BasCoreContainer} _basCoreContainer
   * @param {boolean} isConnected
   */
  function _onBasCurrentCoreConnected (
    _event,
    _basCoreContainer,
    isConnected
  ) {
    if (!isConnected) {

      configReady = false
    }
  }

  function _onMusicConfig () {

    _syncConfigReady()
  }

  function _onRooms () {

    _syncConfigReady()
  }

  function _onAVSources () {

    _syncConfigReady()
  }

  function _syncConfigReady () {

    var core

    configReady = false

    if (CurrentBasCore.hasCore()) {

      core = currentBasCoreState.core.core

      if (core.supportsSystemProperties) {

        if (core.system && !core.system.propertiesDirty) {

          if (CurrentBasCore.hasAVFullSupport()) {

            // Ready when new music api info is available
            configReady = (
              core.roomsReceived &&
              core.avSourcesReceived
            )

          } else if (CurrentBasCore.hasAVPartialSupport()) {

            // Ready when both legacy and new music api info is available
            configReady = (
              core.roomsReceived &&
              core.avSourcesReceived &&
              core.musicConfigReceived
            )

          } else {

            // Ready when legacy music info is available
            configReady = core.musicConfigReceived
          }
        }

      } else {

        configReady = core.musicConfigReceived
      }
    }

    if (configReady) {

      _syncSources()
      _syncWidget()
      _syncWidgetAction()
    }
  }

  function _syncWidgetAction () {

    const room = musicWidget.rooms.rooms[musicWidget.currentRoom.roomId]
    const music = room?.music

    if (music) {
      const isNotAsano = [BAS_SOURCE.T_SONOS, BAS_SOURCE.T_BOSPEAKER]
        .includes(music.type)

      musicWidget.messageNoFavourites.txtAction =
        isNotAsano ? null : BasUtilities.translate('my_music')
    }
  }

  function _onResume () {

    firstSync = true
    _syncWidget()
  }

  function onPostLink () {

    _listeners.push($rootScope.$on(
      BAS_ROOM.EVT_SOURCE_CHANGED,
      onSourceChanged
    ))

    _listeners.push($rootScope.$on(
      BAS_SOURCE.EVT_CURRENT_SONG,
      onSongUpdated
    ))

    _syncSources()
    _syncWidget()
  }

  function onChanges (changes) {

    if (changes && changes.room) {

      _syncSources()
      _syncWidget()
    }
  }

  function onSourceChanged (_event, roomId) {

    var room

    room = _getRoom()

    // Check if source has changed for our current room
    if (room && room.id === roomId) {

      _syncSources()
      _syncWidget()
    }
  }

  /**
   * @param {*} _event
   * @param {BasSource} basSource
   */
  function onSongUpdated (_event, basSource) {

    var _currentBasSource

    _currentBasSource = _getMusicBasSource()

    if (_currentBasSource && basSource && _currentBasSource.isSame(basSource)) {

      _syncWidget()
    }
  }

  /**
   * @param {string} favouriteId
   */
  function selectFavourite (favouriteId) {

    var music, basSource

    if (BasUtil.isNEString(favouriteId)) {

      basSource = _getBasSourceForSourceId()

      if (basSource) {

        music = _getRoomMusic()

        // Check if the room source needs to be changed
        if (music) {

          if (music.basSource !== basSource) {

            music.setSource(basSource.getId())
          }
        }

        if (basSource.favourites && basSource.favourites.play) {

          basSource.favourites.play(favouriteId).then(onFavouritePlay)
        }
      }
    }

    function onFavouritePlay () {

      toggle(true)
      $rootScope.$applyAsync()
    }
  }

  function selectSource (sourceId) {

    var music, basSource

    music = _getRoomMusic()
    basSource = SourcesHelper.getBasSource(sourceId)

    if (
      music &&
      basSource &&
      basSource.available &&
      BasUtil.isObject(basSource.source)
    ) {

      // Assign source to zone
      musicWidget.room.music.setSource(basSource.getId())

      BasMusicWidgetService.toggleNowPlaying(true)
    }
  }

  function onCoverAreaTap () {

    musicWidget.useOverlayControls
      ? musicWidget.toggleShowControls()
      : selectPlayer()
  }

  function goToLibrary () {

    var basSource, music

    basSource = _getBasSourceForSourceId()

    if (basSource) {

      music = _getRoomMusic()

      // Check if the room source needs to be changed
      if (music) {

        if (music.basSource !== basSource) {

          music.setSource(basSource.getId())
        }
      }

      BasState.go(
        STATES.MUSIC_LIBRARY,
        {
          room: _getRoomId()
        },
        {
          custom: {
            sourceId: basSource.getId()
          }
        }
      )
    }
  }

  function toggleShowControls (force) {

    musicWidget.css[CSS_SHOW_CONTROLS] =
      BasUtil.isBool(force)
        ? force
        : !musicWidget.css[CSS_SHOW_CONTROLS]

    if (musicWidget.css[CSS_SHOW_CONTROLS]) {

      timeHideControlsConditionally()
    }

    _syncCoverArt()
  }

  function _syncCoverArt () {

    if (musicWidget.css[CSS_SHOW_CONTROLS]) {

      timeHideControlsConditionally()
    }
  }

  function volumeChange () {

    var music

    music = _getRoomMusic()
    if (music) music.volumeChange()

    timeHideControlsConditionally()
  }

  function toggleStereoWidening () {
    const music = _getRoomMusic()

    music?.toggleStereoWidening()

    timeHideControlsConditionally()
  }

  function toggleMute () {

    var music

    music = _getRoomMusic()
    if (music) music.toggleMute()

    timeHideControlsConditionally()
  }

  function toggleRepeat () {

    var basSource

    basSource = _getMusicBasSource()
    if (basSource) basSource.toggleRepeat()

    timeHideControlsConditionally()
  }

  function toggleShuffle () {

    var basSource

    basSource = _getMusicBasSource()
    if (basSource) basSource.toggleRandom()

    timeHideControlsConditionally()
  }

  function toggleAdvancedFlipsideShowStream (force) {

    BasMusicWidgetService.toggleAdvancedFlipsideShowStream(force)
  }

  function turnMusicOff () {

    var room

    BasAppHeaderControlsBack.handleBack()

    room = _getRoom()

    if (
      room &&
      room.music &&
      room.music.toggle
    ) {

      room.music.toggle(false)
    }
  }

  function timeHideControlsConditionally () {

    clearTimeout(_hideControlsTimeoutId)
    _hideControlsTimeoutId = setTimeout(
      _hideControlsConditionally,
      HIDE_CONTROLS_OVERLAY_TIMEOUT
    )
  }

  function _hideControlsConditionally () {

    var shouldHideControls, basSource

    shouldHideControls = true

    basSource = _getMusicBasSource()

    if (basSource && !basSource.isVideoSource) {

      shouldHideControls = !basSource.paused
    }

    if (shouldHideControls) {

      toggleShowControls(false)
      $rootScope.$applyAsync()
    }
  }

  function selectPlayer () {

    BasUtil.execute(musicWidget.selectPlayerHandler)
  }

  function goToStreams () {

    toggle(false)
    toggleAdvancedFlipsideShowStream(true)
  }

  function next () {

    var basSource

    basSource = _getMusicBasSource()

    if (basSource) {

      basSource.next()
      timeHideControlsConditionally()
    }
  }

  function previous () {

    var basSource

    basSource = _getMusicBasSource()

    if (basSource) {

      basSource.previous()
      timeHideControlsConditionally()
    }
  }

  function togglePlay () {

    var basSource

    basSource = _getMusicBasSource()

    if (basSource) {

      basSource.togglePlay()
      timeHideControlsConditionally()
    }

  }

  /**
   * "true" - Now playing
   * "false" - favourites
   *
   * @param {boolean} [force]
   */
  function toggle (force) {
    BasMusicWidgetService.toggleNowPlaying(force)
  }

  function _syncWidget () {

    var basSource

    if (configReady) {

      basSource = _getMusicBasSource()

      if (basSource) {

        // UI

        if (basSource.type === BAS_SOURCE.T_EMPTY) {

          // Should show the preferred player favourites

          toggleAdvancedFlipsideShowStream(true)
          BasMusicWidgetService.toggleNowPlaying(false)

        } else if (basSource.type === BAS_SOURCE.T_UNKNOWN_SOURCE) {

          // TODO Implement basic messages
          // There is no more check for state.hasSong,
          // a view can be showed where
          // there is no song playing (end of queue).
          // Basic messages should be visible instead (with action button)

          BasMusicWidgetService.toggleShowButton(false)
          BasMusicWidgetService.toggleNowPlaying(false)

        } else {

          BasMusicWidgetService.toggleShowButton(true)

          if (
            firstSync &&
            (
              basSource.type === BAS_SOURCE.T_SONOS ||
              basSource.type === BAS_SOURCE.T_BOSPEAKER ||
              basSource.type === BAS_SOURCE.T_ASANO ||
              basSource.type === BAS_SOURCE.T_VIDEO ||
              basSource.type === BAS_SOURCE.T_PLAYER ||
              basSource.type === BAS_SOURCE.T_BARP ||
              basSource.type === BAS_SOURCE.T_EXTERNAL ||
              basSource.type === BAS_SOURCE.T_NOTIFICATION ||
              basSource.type === BAS_SOURCE.T_AUDIO_ALERT ||
              basSource.type === BAS_SOURCE.T_BLUETOOTH
            )
          ) {
            firstSync = false
            BasMusicWidgetService.toggleNowPlaying(true)
          }
        }

        if (!basSource.isVideoSource && basSource.paused) {

          toggleShowControls(true)
        }

      } else {

        toggleAdvancedFlipsideShowStream(true)
        BasMusicWidgetService.toggleNowPlaying(false)
      }
    }
  }

  function _syncSources () {

    if (configReady) {

      _syncCurrentSourceId()
      _syncPlayerId()
    }
  }

  function _syncCurrentSourceId () {

    var basSource, sourceId

    sourceId = currentSourceId

    basSource = _getMusicBasSource()

    currentSourceId = basSource ? basSource.getId() : 0

    if (sourceId !== currentSourceId) {

      Sources.unregisterFor(BAS_SOURCE.COL_EVT_PLAYER, sourceId)
      Sources.registerFor(BAS_SOURCE.COL_EVT_PLAYER, currentSourceId)
    }
  }

  function _syncPlayerId () {

    var basSource, oldSourceId

    oldSourceId = musicWidget.sourceId

    basSource = _getPreferredPlayer()

    musicWidget.sourceId = basSource ? basSource.getId() : 0

    if (oldSourceId !== musicWidget.sourceId) {

      Sources.unregisterFor(
        BAS_SOURCE.COL_EVT_FAVOURITES,
        oldSourceId
      )
      Sources.registerFor(
        BAS_SOURCE.COL_EVT_FAVOURITES,
        musicWidget.sourceId
      )
    }
  }

  /**
   * Returns a Player BasSource:
   * - Current source is a valid AudioSource/Player
   * - Default source for current Profile is a valid AudioSource/Player
   * - First (compatible) AudioSource/Player
   *
   * Or null if no Player BasSource is available.
   *
   * @private
   * @returns {?BasSource}
   */
  function _getPreferredPlayer () {

    var basSource, source

    basSource = _getMusicBasSource()
    const room = _getRoom()

    // Check current source ID
    if (basSource) {

      source = SourcesHelper.getAudioSource(basSource.uuid)
      if (source) return source

      source = SourcesHelper.getPlayer(basSource.id)
      if (source) return source
    }

    // Check profile default source
    if (CurrentBasCore.has() && room?.music?.type === BAS_ROOM.MUSIC_T_ASANO) {

      source = currentBasCoreState.core.getDefaultSource()
      if (source) return source
    }

    // Check first player
    source = SourcesHelper.getFirstAudioPlayer(_getRoom())
    if (source) return source

    return null
  }

  function _getBasSourceForSourceId () {

    var sourceUuid, basSource

    sourceUuid = musicWidget.BAS_SOURCES.SOURCES
      .playersOrAVSources[musicWidget.sourceId]

    if (sourceUuid) {

      basSource = musicWidget.BAS_SOURCES.SOURCES.sources[sourceUuid]

      if (basSource && basSource.isSameSource) {

        return basSource
      }
    }

    return null
  }

  /**
   * @private
   * @returns {?BasSource}
   */
  function _getMusicBasSource () {

    var room

    room = _getRoom()

    return room ? room.getMusicBasSource() : null
  }

  /**
   * @private
   * @returns {?BasRoomMusic}
   */
  function _getRoomMusic () {

    var room

    room = _getRoom()

    return (room && room.music && room.music.volumeChange)
      ? room.music
      : null
  }

  /**
   * @private
   * @returns {string}
   */
  function _getRoomId () {

    var room

    room = _getRoom()

    return room ? room.id : ''
  }

  /**
   * @private
   * @returns {?BasRoom}
   */
  function _getRoom () {

    return (musicWidget.room && musicWidget.room.isRoom)
      ? musicWidget.room
      : null
  }

  function _onTranslation () {

    musicWidget.messageNoFavourites.txtMsgTitle =
      BasUtilities.translate('no_favourites')

    _syncWidgetAction()
  }

  function _onFavouritesUpdated (_evt, basSource) {

    if (basSource && basSource.getId() === musicWidget.sourceId) {

      $scope.$applyAsync()
    }
  }

  function onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []

    Sources.unregisterFor(BAS_SOURCE.COL_EVT_SIMPLE)
    Sources.unregisterFor(BAS_SOURCE.COL_EVT_PLAYER, currentSourceId)
    Sources.unregisterFor(BAS_SOURCE.COL_EVT_FAVOURITES, musicWidget.sourceId)
  }
}
