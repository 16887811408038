'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasRoomMusic', [
    '$rootScope',
    'ModalService',
    'BAS_HTML',
    'ICONS',
    'BAS_API',
    'BAS_DSP',
    'BAS_IMAGE',
    'BAS_SOURCE',
    'BAS_ROOM',
    'BAS_SOURCES',
    'CurrentBasCore',
    'RoomsHelper',
    'Sources',
    'BasCommandQueue',
    'BasImageTrans',
    'BasImage',
    'BasString',
    'BasCollection',
    'BasEq',
    basRoomMusicFactory
  ])

/**
 * @param $rootScope
 * @param ModalService
 * @param {BAS_HTML} BAS_HTML
 * @param {ICONS} ICONS
 * @param BAS_API
 * @param {BAS_DSP} BAS_DSP
 * @param {BAS_IMAGE} BAS_IMAGE
 * @param {BAS_SOURCE} BAS_SOURCE
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_SOURCES} BAS_SOURCES
 * @param {CurrentBasCore} CurrentBasCore
 * @param {RoomsHelper} RoomsHelper
 * @param {Sources} Sources
 * @param {BasCommandQueue} BasCommandQueue
 * @param BasImageTrans
 * @param BasImage
 * @param BasString
 * @param BasCollection
 * @param BasEq
 * @returns BasRoomMusic
 */
function basRoomMusicFactory (
  $rootScope,
  ModalService,
  BAS_HTML,
  ICONS,
  BAS_API,
  BAS_DSP,
  BAS_IMAGE,
  BAS_SOURCE,
  BAS_ROOM,
  BAS_SOURCES,
  CurrentBasCore,
  RoomsHelper,
  Sources,
  BasCommandQueue,
  BasImageTrans,
  BasImage,
  BasString,
  BasCollection,
  BasEq
) {
  var ROOM_SETTINGS_BUTTON_VISIBLE_TIME_MS = 5000

  var CSS_HAS_MUSIC = 'bas-room--music--has'
  var CSS_CAN_GROUP = 'bas-room--music--can-group'
  var CSS_IS_UNAVAILABLE = 'bas-room--music--is-unavailable'
  var CSS_MUTED = 'bas-room--music--is-muted'
  var CSS_CAN_ADJUST_VOLUME = 'bas-room--music--can-adjust-volume'
  var CSS_CAN_TOGGLE = 'bas-room--music--can-toggle'
  var CSS_HAS_SOURCE_NAME = 'bas-room--music--has-source-name'
  var CSS_HAS_ROOM_SETTINGS = 'bas-room--music--has-settings'
  var CSS_HAS_TREBLE = 'bas-room--music--has-treble'
  var CSS_HAS_BASS = 'bas-room--music--has-bass'
  var CSS_HAS_STARTUP_VOLUME = 'bas-room--music--has-startup-volume'
  var CSS_HAS_STEREO_WIDENING = 'bas-room--music--has-stereo-widening'
  var CSS_SHOW_ROOM_SETTINGS_BUTTON = 'bas-room--music--show-settings-button'

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  var biOnOff = new BasImage(
    ICONS.onOff,
    {
      customClass: [
        BAS_IMAGE.C_BG_CONTAIN,
        BAS_IMAGE.C_COLOR_MUTED,
        BAS_IMAGE.C_SIZE_50
      ]
    }
  )

  /**
   * @constructor
   * @param {BasRoom} basRoom
   * @param {Zone} [zone]
   */
  function BasRoomMusic (basRoom, zone) {

    /**
     * @type {string}
     */
    this.type = BAS_ROOM.MUSIC_T_ASANO

    /**
     * @type {boolean}
     */
    this.on = false

    /**
     * @type {number}
     */
    this.volume = 0

    /**
     * @type {boolean}
     */
    this.muted = false

    /**
     * @type {boolean}
     */
    this.stereoWidening = false

    /**
     * @type {number}
     */
    this.bass = 0

    /**
     * @type {number}
     */
    this.treble = 0

    /**
     * @type {BasEq[]}
     */
    this.equalisers = []

    /**
     * @type {number}
     */
    this.startupVolume = 0

    /**
     * UI helper
     *
     * @type {boolean}
     */
    this.canAdjustVolume = false

    /**
     * UI helper
     *
     * @type {boolean}
     */
    this.isAvailable = true

    /**
     * Icon
     *
     * @type {BasImageTrans}
     */
    this.bitIcon = new BasImageTrans({
      transitionType: BasImageTrans.TRANSITION_TYPE_FADE,
      defaultImage: biOnOff
    })

    /**
     * Background image
     *
     * @type {BasImageTrans}
     */
    this.bit = new BasImageTrans({
      transitionType: BasImageTrans.TRANSITION_TYPE_FADE,
      debounceMs: 1000,
      debounceMsNull: 200
    })

    /**
     * Source info object
     *
     * @type {?BasSource}
     */
    this.basSource = null

    /**
     * @type {BasString}
     */
    this.sourceName = new BasString()

    /**
     * Default source UUID
     *
     * @type {string}
     */
    this.defaultSource = ''

    /**
     * @type {string}
     */
    this.selectedDspProfile = BAS_DSP.DSP_PROFILE_CUSTOM_ID

    /**
     * @type {BasCollection[]}
     */
    this.basCompatibleSources = []

    /**
     * @type {Object<string, boolean>}
     */
    this.compatibleSourcesMap = {}

    /**
     * @private
     * @type {?AVAudio}
     */
    this._avAudio = null

    /**
     * @private
     * @type {Array}
     */
    this._avAudioListeners = []

    /**
     * @private
     * @type {?AudioSource}
     */
    this._audioSource = null

    /**
     * @private
     * @type {Array}
     */
    this._sourceListeners = []

    /**
     * @private
     * @type {?Zone}
     */
    this._zone = null

    /**
     * @private
     * @type {Array}
     */
    this._zoneListeners = []

    /**
     * @private
     * @type {?BasRoom}
     */
    this._basRoom = basRoom || null

    /**
     * @private
     * @type {Object<string, boolean>}
     */
    this._css = {}

    /**
     * @private
     * @type {number}
     */
    this._uiSettingsButtonTimeoutId = 0

    this._handleAvAudioCapabilities =
      this._onAvAudioCapabilities.bind(this)
    this._handleAvAudioAttribtues = this._onAvAudioAttributes.bind(this)
    this._handleAvAudioState = this._onAvAudioState.bind(this)
    this._handleAvAudioReachable = this._onAvAudioReachable.bind(this)

    this._handleAudioSourceCapabilities =
      this._onAudioSourceCapabilities.bind(this)
    this._handleAudioSourceVolume = this._onAudioSourceVolume.bind(this)
    this._handleAudioSourceMuted = this._onAudioSourceMuted.bind(this)
    this._handleAudioSourcePlayback =
      this._onAudioSourcePlayback.bind(this)
    this._handleAudioSourceListeningRooms =
      this._onAudioSourceListeningRooms.bind(this)
    this._handleAudioSourceIsOn =
      this._onAudioSourceIsOn.bind(this)

    this._handleToggleError = this._onToggleError.bind(this)

    this._handleZoneSource = this._onZoneSource.bind(this)
    this._handleZoneVolume = this._onZoneVolume.bind(this)
    this._handleZoneMuted = this._onZoneMuted.bind(this)
    this._handleZoneTreble = this._onZoneTreble.bind(this)
    this._handleZoneBass = this._onZoneBass.bind(this)
    this._handleZoneStartupVolume = this._onZoneStartupVolume.bind(this)

    this._handleUiToggleSettingsButtonTimeout =
      this._onUiToggleSettingsButtonTimeout.bind(this)

    if (zone) {

      this.setZone(zone, { emit: false })

    } else {

      this.parseRoom({ emit: false })
    }

    this._syncCompatibleSources()
  }

  /**
   * Only checks for AV support on a Room instance
   * Do not use to check for Music Zone!
   *
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoomMusic.hasAVMusic = function (room) {

    return !!(
      (
        CurrentBasCore.hasAVPartialSupport() ||
        CurrentBasCore.hasAVFullSupport()
      ) &&
      room &&
      room.room &&
      room.room.av &&
      room.room.av.audio && (
        room.room.av.audio.type !== BAS_API.AVAudio.T_ASANO ||
        CurrentBasCore.hasAVFullSupport()
      )
    )
  }

  /**
   * Only checks for AV support on a Room instance
   * Do not use to check for Music Zone!
   *
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoomMusic.hasAVSource = function (room) {

    var source

    if (room && BasUtil.isNEString(room.sourceUuid)) {

      source = Sources.getBasSource(room.sourceUuid)

      if (source) return source.isAudioSource
    }

    return false
  }

  /**
   * Convert API AVAudio type to BasRoomMusic.type
   *
   * @param {string} type
   * @returns {string}
   */
  BasRoomMusic.getType = function (type) {

    switch (type) {
      case BAS_API.AVAudio.T_SONOS:
        return BAS_ROOM.MUSIC_T_SONOS
      case BAS_API.AVAudio.T_BOSPEAKER:
        return BAS_ROOM.MUSIC_T_BOSPEAKER
      case BAS_API.AVAudio.T_ASANO:
        return BAS_ROOM.MUSIC_T_ASANO
      case BAS_API.AVAudio.T_AVR:
        return BAS_ROOM.MUSIC_T_AVR
      default:
        return BAS_ROOM.MUSIC_T_OTHER
    }
  }

  /**
   * @param {string} profileId
   * @returns {?Object}
   */
  BasRoomMusic.getDspProfileById = function (profileId) {
    var dspPreset

    dspPreset = BAS_DSP.DSP_PROFILE_PRESETS[profileId]

    return dspPreset || null
  }

  BasRoomMusic.prototype.suspend = function suspend () {

    this._clearUiSettingsButtonTimeout()
    this._clearAvAudioListeners()
    this._clearZoneListeners()
  }

  BasRoomMusic.prototype.resume = function resume () {

    this._setAvAudioListeners()
    this._setZoneListeners()
  }

  /**
   * @param {TBasEmitterOptions} [options]
   */
  BasRoomMusic.prototype.parseRoom = function parseRoom (
    options
  ) {
    var room

    this._clearAvAudioListeners()
    this._resetProperties()
    this._resetCss()

    if (this._basRoom && BasUtil.isNEString(this._basRoom.sourceUuid)) {

      // Find room with given source as default source. If found, set type
      //  to music type of that room, else take MUSIC_T_ASANO.
      room = RoomsHelper.getRoomWithDefaultSource(this._basRoom.sourceUuid)

      if (room) {

        this._basRoom.order = room.order
      }

      this.type = (
        room &&
        room.music
      )
        ? room.music.type
        : BAS_ROOM.MUSIC_T_ASANO

      this.syncSource()
      this.syncCanGroup()

      if (this._audioSource) {

        this._syncOnState()
        this._syncMuted()
        this._syncVolume()
      }

    } else {

      if (BasRoomMusic.hasAVMusic(this._basRoom)) {

        this._avAudio = this._basRoom.room.av.audio

        this.type = BasRoomMusic.getType(this._avAudio.type)
        this.defaultSource = this._avAudio.defaultSource

        this.on = this._avAudio.isOn
        this.startupVolume = this._avAudio.startupVolume

        this._syncEqualisers()
        this.selectedDspProfile = this.getMatchingDspProfile()

        this.bass = this._avAudio.bass
        this.treble = this._avAudio.treble
        this._syncMuted()
        this._syncStereoWidening()
        this._syncVolume()

        this._cssSet(CSS_HAS_MUSIC, true)

        this._cssSet(
          CSS_CAN_ADJUST_VOLUME,
          this._avAudio.allowsWrite(BAS_API.AVAudio.C_VOLUME) && this.on
        )

        this.syncSource(options)

        this._setAvAudioListeners()

      } else {

        this._clearBits()
        this._avAudio = null
      }
    }

    this._syncCompatibleSources()
    this._syncCssToBasRoom()
  }

  /**
   * @param {Zone} zone
   * @param {TBasEmitterOptions} [options]
   */
  BasRoomMusic.prototype.setZone = function setZone (
    zone,
    options
  ) {
    this._resetProperties()
    this._resetCss()

    this._cssSet(CSS_CAN_TOGGLE, true)

    if (zone instanceof BAS_API.Zone) {

      this._zone = zone

      this._syncMuted()
      this._syncVolume()

      this.bass = this._zone.bass
      this.treble = this._zone.treble
      this.startupVolume = this._zone.startupVolume

      this._cssSet(CSS_HAS_MUSIC, true)
      this._cssSet(CSS_HAS_ROOM_SETTINGS, this._zone.hasSettings)
      this._cssSet(CSS_HAS_TREBLE, BasUtil.isNumber(this._zone.treble))
      this._cssSet(CSS_HAS_BASS, BasUtil.isNumber(this._zone.bass))
      this._cssSet(
        CSS_HAS_STARTUP_VOLUME,
        BasUtil.isNumber(this._zone.startupVolume)
      )

      this.syncSource(options)

      this._setZoneListeners()

    } else {

      this._clearBits()
    }

    this._syncCssToBasRoom()
  }

  /**
   * @returns {boolean}
   */
  BasRoomMusic.prototype.hasMusic = function () {
    return !!(this._avAudio || this._zone)
  }

  /**
   * @returns {boolean}
   */
  BasRoomMusic.prototype.hasLegacyMusic = function () {
    return !!this._zone
  }

  /**
   * Is the (music) Room a group of music zones?
   *
   * @returns {boolean}
   */
  BasRoomMusic.prototype.isGroup = function () {

    return (
      this._zone &&
      Array.isArray(this._zone.group)
    )
  }

  /**
   * Is the (music) Room a group of rooms listening to the same source?
   *
   * @returns {boolean}
   */
  BasRoomMusic.prototype.isActiveSourceGroup = function () {

    // Show all Sonos rooms as a source group,
    //  no matter the amount of listening rooms.
    // Only show asano sources as a source group if at least 1 room is listening
    // If any of the listening rooms is not found (no access), DO NOT SHOW.

    if (this._audioSource && this._audioSource.reachable) {

      switch (this.type) {
        case BAS_ROOM.MUSIC_T_SONOS:
          return true
        case BAS_ROOM.MUSIC_T_BOSPEAKER:
        case BAS_ROOM.MUSIC_T_ASANO:
          return (
            this._audioSource.listeningRooms.length > 0 &&
            hasAccessToAllRooms(this._audioSource.listeningRooms)
          )
      }
    }

    return false

    function hasAccessToAllRooms (roomUuids) {

      var i, length

      if (Array.isArray(roomUuids)) {

        length = roomUuids.length
        for (i = 0; i < length; i++) {

          if (!RoomsHelper.getRoomForId(roomUuids[i])) return false
        }
      }
      return true
    }
  }

  /**
   * @returns {boolean}
   */
  BasRoomMusic.prototype.hasSettings = function () {

    return (
      this._zone &&
      this._zone.hasSettings
    ) || (
      this._avAudio && (
        this._avAudio.allowsWrite(BAS_API.AVAudio.C_STARTUP_VOLUME) ||
        this._avAudio.allowsWrite(BAS_API.AVAudio.C_BASS) ||
        this._avAudio.allowsWrite(BAS_API.AVAudio.C_TREBLE)
      )
    )
  }

  /**
   * @param {(Barp|Player|number|string)} sourceId
   * @returns {Promise}
   */
  BasRoomMusic.prototype.setSource = function setSource (sourceId) {

    if (this._avAudio) return this._avAudio.setSource(sourceId)
    if (this._zone) {
      this._zone.source = sourceId
      return Promise.resolve()
    }

    return Promise.reject('No avAudio or zone to set source on')
  }

  /**
   * Syncs the 'muted' property based on actual source
   *
   * @private
   */
  BasRoomMusic.prototype._syncMuted = function () {

    if (this._avAudio) {

      this.muted = this._avAudio.mute

    } else if (this._zone) {

      this.muted = this._zone.muted

    } else if (this._audioSource) {

      this.muted = this._audioSource.mute
    }
  }

  /**
   * Syncs the 'muted' property based on actual source
   *
   * @private
   */
  BasRoomMusic.prototype._syncStereoWidening = function () {

    if (this._avAudio) {

      this.stereoWidening = this._avAudio.stereoWidening

    }
  }

  /**
   * Syncs the volume visualisation based on actual source volume and
   *  'muted' property. Run 'this._syncMuted()' first for correct behaviour.
   *
   * @private
   */
  BasRoomMusic.prototype._syncVolume = function () {

    // Update UI volume

    if (this.muted) {

      this.volume = 0

    } else {

      if (this._avAudio) {

        this.volume = this._avAudio.volume

      } else if (this._zone) {

        this.volume = this._zone.volume

      } else if (this._audioSource) {

        this.volume = this._audioSource.volume
      }
    }

    this._cssSet(CSS_MUTED, this.volume === 0)
  }

  /**
   * Mutes/un-mutes the room
   */
  BasRoomMusic.prototype.toggleMute = function toggleMute () {

    // Update zone mute state
    this.muted = !this.muted
    this._syncVolume()

    if (this._avAudio) {

      this._avAudio.setMute(this.muted)

    } else if (this._zone) {

      this._zone.muted = this.muted

    } else if (this._audioSource) {

      this._audioSource.setMute(this.muted)
    }
  }

  /**
   * Turn stereo widening on/off
   */
  BasRoomMusic.prototype.toggleStereoWidening =
    function toggleStereoWidening () {

      this.stereoWidening = !this.stereoWidening

      this._avAudio?.setStereoWidening(this.stereoWidening)
    }

  /**
   * Updates the zone's volume with the UI volume
   *
   * @returns {Promise}
   */
  BasRoomMusic.prototype.volumeChange = function volumeChange () {

    if (this._avAudio) {

      return BasCommandQueue.roomAudioSetVolume(this._basRoom.id, this.volume)

    } else if (this._zone) {

      this._zone.volume = this.volume
      return Promise.resolve()

    } else if (this._audioSource) {

      return this._audioSource.setVolume(this.volume)
    }

    return Promise.reject(new Error('could not update volume'))
  }

  /**
   * Updates the zone's bass settings with the UI bass
   *
   * @returns {Promise}
   */
  BasRoomMusic.prototype.bassChange = function bassChange () {

    if (this._avAudio) {

      if (this._avAudio.bass !== this.bass) {

        return this._avAudio.setBass(this.bass)
      }

      return Promise.resolve()

    } else if (this._zone) {

      if (
        BasUtil.isNumber(this._zone.bass) &&
        this._zone.bass !== this.bass
      ) {

        this._zone.bass = this.bass
      }

      return Promise.resolve()
    }

    return Promise.reject(new Error('could not update bass'))
  }

  /**
   * Updates the zone's treble settings with the UI treble
   *
   * @returns {Promise}
   */
  BasRoomMusic.prototype.trebleChange = function trebleChange () {

    if (this._avAudio) {

      if (this._avAudio.treble !== this.treble) {

        return this._avAudio.setTreble(this.treble)
      }

      return Promise.resolve()

    } else if (this._zone) {

      if (
        BasUtil.isNumber(this._zone.treble) &&
        this._zone.treble !== this.treble
      ) {

        this._zone.treble = this.treble
      }

      return Promise.resolve()
    }

    return Promise.reject(new Error('could not update treble'))
  }

  /**
   * Updates the zone's startup volume settings with the UI startup volume
   *
   * @returns {Promise}
   */
  BasRoomMusic.prototype.startupVolumeChange = function startupVolumeChange () {

    if (this._avAudio) {

      if (this._avAudio.startupVolume !== this.startupVolume) {

        return this._avAudio.setStartupVolume(this.startupVolume)
      }

      return Promise.resolve()

    } else if (this._zone) {

      if (BasUtil.isNumber(this._zone.startupVolume)) {

        this._zone.startupVolume = this.startupVolume
      }

      return Promise.resolve()
    }

    return Promise.reject(new Error('could not startup volume'))
  }

  /**
   * @param {number} index
   * @param {number} gain
   * @returns {Promise}
   */
  BasRoomMusic.prototype.equaliserGainChange = function (
    index,
    gain
  ) {
    if (this._avAudio) {

      if (BasUtil.isPNumber(index, true)) {

        this.equalisers[index].gain = gain

        if (this.selectedDspProfile) {

          this.selectedDspProfile = this.getMatchingDspProfile()
        }

        return this._updateRoomDsp()
      }
    }

    return Promise.reject(new Error('could not update EQ'))
  }

  /**
   * @returns {string}
   */
  BasRoomMusic.prototype.getMatchingDspProfile = function () {

    var length, i, profileEqualisers, keys, dspProfileId

    keys = Object.keys(BAS_DSP.DSP_PROFILE_PRESETS)

    length = keys.length
    for (i = 0; i < length; i++) {
      dspProfileId = BAS_DSP.DSP_PROFILE_PRESETS[keys[i]].id
      profileEqualisers = BAS_DSP.DSP_PROFILE_PRESETS[dspProfileId].equalisers

      if (
        profileEqualisers.length > 0 &&
          this.equalisers.length > 0 &&
          BasEq.isEqualEqualisers(profileEqualisers, this.equalisers)
      ) {

        return dspProfileId
      }
    }

    return BAS_DSP.DSP_PROFILE_CUSTOM_ID
  }

  /**
   * @private
   * @returns {TRoomEqualiser[]} equalisers
   */
  BasRoomMusic.prototype._getApiEqualisers = function () {

    var i, length, entry, result

    result = []

    length = this.equalisers.length
    for (i = 0; i < length; i++) {

      entry = this.equalisers[i]
      if (entry) result.push(entry.getApiData())
    }

    return result
  }

  /**
   * @private
   * @returns {Promise}
   */
  BasRoomMusic.prototype._updateRoomDsp = function updateRoomDsp () {

    return this._avAudio && BasUtil.isFunction(this._avAudio.setDsp)
      ? this._avAudio.setDsp(this._getApiEqualisers())
      : Promise.reject(new Error('setDsp not available'))
  }

  /**
   * @private
   * @returns {Promise}
   */
  BasRoomMusic.prototype._resetAllEqualisers = function resetAllEqualisers () {

    if (
      this._avAudio &&
      BasUtil.isFunction(this._avAudio.reset)
    ) {

      this.selectedDspProfile = BAS_DSP.DSP_PROFILE_FLAT_ID

      return this._avAudio.reset()
    }

    return Promise.reject(new Error('could not reset EQ'))
  }

  /**
   * Toggles a (music) room on and off
   *
   * @param {boolean} [force]
   * @returns {Promise}
   */
  BasRoomMusic.prototype.toggle = function toggle (force) {

    var _newOnState, _newSourceId

    // Determine new desired "on" state

    if (BasUtil.isBool(force)) {

      _newOnState = force

    } else {

      this._syncOnState()

      _newOnState = !this.on
    }

    // Logic to reach desired "on" state

    if (this._avAudio) {

      // Instant feedback
      this.on = _newOnState

      // Just turn on the avAudio, logic for selecting a source is moved to
      //  the server side.
      return this._avAudio.setOn(_newOnState).catch(this._handleToggleError)

    } else if (this._zone) {

      if (_newOnState) {

        if (CurrentBasCore.has()) {

          _newSourceId =
            currentBasCoreState.core.getSourceToTurnOn()
        }

      } else {

        _newSourceId = 0
      }

      if (BasUtil.isPNumber(_newSourceId, true)) {

        this._zone.source = _newSourceId
      }
    } else if (this._audioSource) {

      // If server supports new 'on' property on audioSource, use it
      //  else
      if (this._audioSource.allowsWrite(BAS_API.AudioSource.C_ON)) {

        // Instant feedback
        this.on = _newOnState
        return this._audioSource.toggleOn(_newOnState)
          .catch(this._handleToggleError)

      } else {

        switch (this.type) {

          case BAS_ROOM.MUSIC_T_SONOS:
          case BAS_ROOM.MUSIC_T_BOSPEAKER:

            // Instant feedback
            this.on = _newOnState

            return this._audioSource.togglePlayPause()
              .catch(this._handleToggleError)
        }
      }
    }

    return Promise.resolve()
  }

  BasRoomMusic.prototype._onToggleError = function (error) {

    if (this._avAudio) {

      this.on = this._avAudio.isOn
      $rootScope.$applyAsync()

      // Reject to handle in UI
      return Promise.reject(error)

    } else if (this._audioSource) {

      switch (this.type) {
        case BAS_ROOM.MUSIC_T_ASANO:
          // Nothing needs to be done, 'on' state was not updated in first place
          break
        case BAS_ROOM.MUSIC_T_SONOS:
        case BAS_ROOM.MUSIC_T_BOSPEAKER:
          this.on = !this._audioSource.paused
          $rootScope.$applyAsync()
      }

      // Reject to handle in UI
      return Promise.reject(error)
    }
  }

  /**
   * @param {boolean} [force]
   */
  BasRoomMusic.prototype.uiToggleSettingsButton = function (force) {

    this._clearUiSettingsButtonTimeout()

    if (this._css[CSS_HAS_ROOM_SETTINGS]) {

      this._cssSet(
        CSS_SHOW_ROOM_SETTINGS_BUTTON,
        BasUtil.isBool(force)
          ? force
          : !this._css[CSS_SHOW_ROOM_SETTINGS_BUTTON]
      )

      if (this._css[CSS_SHOW_ROOM_SETTINGS_BUTTON]) {

        this._uiSettingsButtonTimeoutId = setTimeout(
          this._handleUiToggleSettingsButtonTimeout,
          ROOM_SETTINGS_BUTTON_VISIBLE_TIME_MS
        )
      }
    }
  }

  BasRoomMusic.prototype._onUiToggleSettingsButtonTimeout = function () {

    this._cssSet(
      CSS_SHOW_ROOM_SETTINGS_BUTTON,
      false
    )

    $rootScope.$applyAsync()
  }

  /**
   * Opens the DSP configuration modal for the room
   */
  BasRoomMusic.prototype.openDSPConfig = function openDSPConfig () {

    if (
      this._basRoom &&
      this.hasSettings()
    ) {

      this.uiToggleSettingsButton(false)

      ModalService.showModal({
        template: BAS_HTML.dspModal,
        controller: 'dspModalCtrl',
        controllerAs: 'modal',
        inputs: {
          roomId: this._basRoom.id,
          isAdmin: (
            CurrentBasCore.hasCore() &&
            currentBasCoreState.core.core.profile.admin
          )
        }
      })
    }
  }

  /**
   * Sync the source object with the current source for the zone
   */
  BasRoomMusic.prototype.syncSource = function () {

    var source, sourceId

    if (this._avAudio) {

      if (BasUtil.isString(this._avAudio.alert)) {

        this.basSource = Sources.getBasSource(this._basRoom.id)

        this.bitIcon.track(this.basSource.bitIcon)

        this._syncCapabilities()

      } else if (
        CurrentBasCore.hasAVPartialSupport() ||
        CurrentBasCore.hasAVFullSupport()
      ) {

        if (currentBasCoreState.core.core.avSourcesReceived) {

          source = this._avAudio.source

          this.basSource = source
            ? Sources.getBasSource(source)
            : Sources.getBasSource(BAS_SOURCE.V_EMPTY)

          if (this.basSource.type === BAS_SOURCE.T_EMPTY) {

            this._clearBits()

          } else {

            this.bitIcon.track(this.basSource.bitIcon)
            this.bit.track(this.basSource.bitBg)
          }

          this.syncAvailability()
          this._syncCompatibleSources()

        } else {

          // Wait for AV sources
        }

      } else {

        // Should not occur
      }

    } else if (this._roomIsSourceGroup()) {

      source = this._getSource()

      if (source && source.groupable) {

        this.basSource = source
        this._audioSource = source.source

        this._syncAudioSourceCapabilities()
        this._setSourceListeners()

        this.bitIcon.track(this.basSource.bitIcon)
        this.bit.track(this.basSource.bitBg)

      } else {

        this.basSource = Sources.getBasSource(BAS_SOURCE.V_EMPTY)

        this._clearSourceListeners()
        this._audioSource = null
      }

    } else if (this._zone) {

      source = this._zone.source
      sourceId = this._zone.sourceID

      if (BasUtil.isNumber(source)) {

        this.basSource = Sources.getBasSource(source)

      } else if (BasUtil.isObject(source)) {

        this.basSource = Sources.getBasSource(sourceId)

      } else {

        if (BasUtil.isPNumber(sourceId)) {

          this.basSource = Sources.getBasSource(sourceId)

        } else {

          this.basSource = Sources.getBasSource(BAS_SOURCE.V_EMPTY)
        }
      }

      if (this.basSource.type === BAS_SOURCE.T_EMPTY) {

        this.canAdjustVolume = false
        this._cssSet(CSS_CAN_ADJUST_VOLUME, false)

        this._clearBits()

      } else {

        this.canAdjustVolume = true
        this._cssSet(CSS_CAN_ADJUST_VOLUME, true)

        this.bitIcon.track(this.basSource.bitIcon)
        this.bit.track(this.basSource.bitBg)
      }
    }

    this._syncSourceName()
    this._syncOnState()
  }

  BasRoomMusic.prototype.syncAvailability = function () {

    var oldAvailable

    if (this._avAudio) {

      oldAvailable = this.isAvailable

      this.isAvailable = this._avAudio.reachable

      this._syncCapabilities()

      return oldAvailable !== this.isAvailable
    }

    return false
  }

  /**
   * Based on actual capabilities, isAvailable, and current track for avAudio
   *
   * @private
   * @returns {boolean} CSS or properties changed or not
   */
  BasRoomMusic.prototype._syncCapabilities = function () {

    var changed

    if (this._avAudio) {

      if (
        this.canAdjustVolume !==
        this._avAudio.allowsWrite(BAS_API.AVAudio.C_VOLUME)
      ) {

        changed = true
        this.canAdjustVolume =
          this._avAudio.allowsWrite(BAS_API.AVAudio.C_VOLUME)
      }

      if (this._cssSet(
        CSS_IS_UNAVAILABLE,
        !this.isAvailable
      )) {

        changed = true
      }

      if (this._cssSet(
        CSS_CAN_ADJUST_VOLUME,
        (
          this.canAdjustVolume &&
          this.isAvailable &&
          this.on
        )
      )) {

        changed = true
      }

      if (this._cssSet(
        CSS_HAS_ROOM_SETTINGS,
        (
          this._avAudio.allowsWrite(BAS_API.AVAudio.C_STARTUP_VOLUME) ||
          this._avAudio.allowsWrite(BAS_API.AVAudio.C_BASS) ||
          this._avAudio.allowsWrite(BAS_API.AVAudio.C_TREBLE)
        )
      )) {

        changed = true
      }

      if (this._cssSet(
        CSS_HAS_TREBLE,
        this._avAudio.allowsWrite(BAS_API.AVAudio.C_TREBLE)
      )) {

        changed = true
      }

      if (this._cssSet(
        CSS_HAS_BASS,
        this._avAudio.allowsWrite(BAS_API.AVAudio.C_BASS)
      )) {

        changed = true
      }

      if (this._cssSet(
        CSS_HAS_STARTUP_VOLUME,
        this._avAudio.allowsWrite(BAS_API.AVAudio.C_STARTUP_VOLUME)
      )) {

        changed = true
      }

      if (this._cssSet(
        CSS_HAS_STEREO_WIDENING,
        this._avAudio.allowsWrite(BAS_API.AVAudio.C_STEREO_WIDENING)
      )) {

        changed = true
      }

      if (this.syncCanGroup()) {

        changed = true
      }
    }

    return changed
  }

  BasRoomMusic.prototype.syncCanGroup = function () {

    var sourceAvailableAndGroupable

    if (this._avAudio || this.isActiveSourceGroup()) {

      sourceAvailableAndGroupable = (
        this.basSource &&
        this.basSource.isAudioSource &&
        this.basSource.available &&
        this.basSource.groupable &&
        (
          this.basSource.type !== BAS_SOURCE.T_BOSPEAKER ||
          // Only take basSource paused state into account when this is a
          //  source group (otherwise this is handled on basSource level)
          (
            this.on &&
            (
              this._roomIsSourceGroup()
                ? !this.basSource.paused
                : true
            )
          )
        )
      )

      return this._cssSet(
        CSS_CAN_GROUP,
        (
          this.isAvailable &&
          sourceAvailableAndGroupable &&
          this.basSource.compatibleRooms.length > 1
        )
      )
    }

    return false
  }

  /**
   * @private
   */
  BasRoomMusic.prototype._syncSourceName = function () {

    var _defaultSource, _room, _roomName, otherListeners

    this.sourceName.clear()

    if (this._avAudio) {

      // Source names will be room names

      _defaultSource = this._avAudio.defaultSource

      if (_defaultSource) {

        if (this.basSource) {

          if (this.basSource.uuid !== _defaultSource) {

            // Get room with default source for this source

            _room = RoomsHelper.getRoomWithDefaultSource(
              this.basSource.uuid
            )

            if (_room) {

              this.sourceName.setKey(_room.basTitle.key)
            }

          } else {

            // Do not show name
          }

        } else {

          // Empty
        }

      } else {

        // Legacy fallback

        if (this.basSource) {

          this.sourceName.setLiteral(this.basSource.name)
        }
      }

    } else if (this._zone) {

      // Source names are actual source names

      if (this.basSource) {

        this.sourceName.setLiteral(this.basSource.name)
      }

    } else if (this._audioSource) {

      this.sourceName.clear()

      // Sonos: If a room has this source as default source, use that room name,
      //  appended by the listener count if other rooms are listening to that
      //  source too.
      if (
        [BAS_API.AudioSource.T_SONOS, BAS_API.AudioSource.T_BOSPEAKER]
          .includes(this._audioSource.type)
      ) {

        _room = RoomsHelper.getRoomWithDefaultSource(this._audioSource.uuid)

        if (_room) {

          _roomName = _room.uiTitle

          otherListeners = Math.max(
            this._audioSource.listeningRooms.length - 1,
            0
          )

          if (otherListeners > 0) _roomName += ' + ' + otherListeners

        } else {

          _roomName = RoomsHelper.getUniqueSourceIdentifier(this._basRoom)
        }
      } else {

        _roomName = this._audioSource.name
      }

      // Sync generated source name to BasRoom instance
      if (this._basRoom) {

        this._basRoom.name = _roomName
        this._basRoom.syncBasTitle()
        this._basRoom.updateTranslation()
      }
    }

    this._cssSet(CSS_HAS_SOURCE_NAME, !!this.sourceName.value)
  }

  /**
   * @private
   */
  BasRoomMusic.prototype._syncOnState = function () {

    if (this._avAudio) {

      // Synced elsewhere

    } else if (this._zone) {

      this.on = false

      if (this.basSource) {

        if (this.basSource.type === BAS_SOURCE.T_EMPTY) {

          // Represents "off"

        } else {

          this.on = true
        }
      }

    } else if (this._audioSource) {

      if (this._audioSource.allowsRead(BAS_API.AudioSource.C_ON)) {

        this.on = this._audioSource.isOn

      } else {

        switch (this.type) {

          case BAS_ROOM.MUSIC_T_SONOS:
          case BAS_ROOM.MUSIC_T_BOSPEAKER:

            this.on = !this._audioSource.paused
            break
        }
      }
    }
  }

  /**
   * @returns {boolean}
   */
  BasRoomMusic.prototype.isSourcePlayerOrBarp = function () {
    return this.basSource && this.basSource.isPlayerOrBarp()
  }

  /**
   * @returns {boolean}
   */
  BasRoomMusic.prototype.isSourceEmpty = function () {
    return !this.basSource || this.basSource.isEmpty()
  }

  BasRoomMusic.prototype.onSourcesUpdated = function () {

    var _oldBasSource

    if (this._avAudio || this._roomIsSourceGroup()) {

      _oldBasSource = this.basSource

      this.syncSource()

      if (this.basSource !== _oldBasSource) {

        $rootScope.$emit(
          BAS_ROOM.EVT_SOURCE_CHANGED,
          this._basRoom.id
        )
      }
    }

    this._syncCompatibleSources()
  }

  BasRoomMusic.prototype.updateTranslation = function () {

    this.sourceName.updateTranslation()
  }

  /**
   * @private
   * @returns {boolean}
   */
  BasRoomMusic.prototype._syncAvAudio = function () {

    var _old, _oldExtra, _changed

    _changed = false

    if (this._avAudio) {
      _old = this.on
      this.on = this._avAudio.isOn
      if (_old !== this.on) _changed = true

      // Volume and muted
      _old = this.volume
      _oldExtra = this.muted
      this._syncMuted()
      this._syncVolume()
      if (_old !== this.volume || _oldExtra !== this.muted) {

        _changed = true
      }

      // Stereo widening
      _old = this.stereoWidening
      this._syncStereoWidening()
      if (_old !== this.stereoWidening) {

        _changed = true
      }

      // Startup volume
      _old = this.startupVolume
      this.startupVolume = this._avAudio.startupVolume
      if (_old !== this.startupVolume) _changed = true

      // Bass
      _old = this.bass
      this.bass = this._avAudio.bass
      if (_old !== this.bass) _changed = true

      // Treble
      _old = this.treble
      this.treble = this._avAudio.treble
      if (_old !== this.treble) _changed = true

      // Equalisers
      _old = this.equalisers
      this._syncEqualisers()
      if (_old !== this.equalisers) _changed = true

      this.selectedDspProfile = this.getMatchingDspProfile()

      // Source
      _old = this.basSource
      this.syncSource()
      if (_old !== this.basSource) _changed = true
    }

    return _changed
  }

  /**
   * @private
   */
  BasRoomMusic.prototype._onAvAudioCapabilities = function () {

    if (this._syncCapabilities()) {

      $rootScope.$applyAsync()
    }
  }

  /**
   * @private
   */
  BasRoomMusic.prototype._onAvAudioAttributes = function () {

    this._syncCompatibleSources()
  }

  /**
   * @private
   */
  BasRoomMusic.prototype._syncEqualisers = function () {
    var equalisers, i, length

    this.equalisers = []
    equalisers = this._basRoom.room.av.audio.getEqualisers()
    length = equalisers.length

    if (length) {

      for (i = 0; i < length; i++) {

        this.equalisers.push(new BasEq(equalisers[i]))
      }

      this.equalisers.sort(BasEq.compareEqualisersByFrequencies)
    }
  }

  /**
   * @private
   */
  BasRoomMusic.prototype._syncCompatibleSources = function () {

    var length, i, sourceUuid, source
    var basStreams, basExternals, basBluetooths, basVideos

    if (this._avAudio) {

      this.basCompatibleSources = []
      this.compatibleSourcesMap = {}

      basStreams = new BasCollection()
      basStreams.setId(BAS_SOURCES.COL_ID_STREAMS)
      basStreams.setTitleTranslationId(BAS_SOURCES.TRANS_ID_STREAMS)

      basExternals = new BasCollection()
      basExternals.setId(BAS_SOURCES.COL_ID_EXTERNALS)
      basExternals.setTitleTranslationId(BAS_SOURCES.TRANS_ID_EXTERNAL)

      basBluetooths = new BasCollection()
      basBluetooths.setId(BAS_SOURCES.COL_ID_BLUETOOTHS)
      basBluetooths.setTitleTranslationId(BAS_SOURCES.TRANS_ID_BLUETOOTH)

      basVideos = new BasCollection()
      basVideos.setId(BAS_SOURCES.COL_ID_VIDEO_SOURCES)
      basVideos.setTitleTranslationId(BAS_SOURCES.TRANS_ID_TV)

      length = this._avAudio.compatibleSources.length

      for (i = 0; i < length; i++) {

        sourceUuid = this._avAudio.compatibleSources[i]

        this.compatibleSourcesMap[sourceUuid] = true

        source = Sources.getBasSource(sourceUuid)

        if (source && source.type !== BAS_SOURCE.T_UNKNOWN_SOURCE) {

          switch (source.type) {
            case BAS_SOURCE.T_ASANO:

              switch (source.subType) {

                case BAS_SOURCE.ST_STREAM:

                  basStreams.items.push(sourceUuid)
                  break

                case BAS_SOURCE.ST_BLUETOOTH:

                  basBluetooths.items.push(sourceUuid)
                  break

                case BAS_SOURCE.ST_TV:

                  basVideos.items.push(sourceUuid)
                  break

                // Everything which is not bluetooth or a stream, is external,
                //  because Diederik says so.
                case BAS_SOURCE.ST_EXTERNAL:
                default:

                  basExternals.items.push(sourceUuid)
                  break
              }

              break

            case BAS_SOURCE.T_SONOS:
            case BAS_SOURCE.T_BOSPEAKER:

              // Sonos has no sources overview, silly

              break

            case BAS_SOURCE.T_VIDEO:

              basVideos.items.push(sourceUuid)

              break
          }
        }
      }

      if (basStreams.items.length) {

        this.basCompatibleSources.push(basStreams)
      }

      if (basExternals.items.length) {

        this.basCompatibleSources.push(basExternals)
      }

      if (basBluetooths.items.length) {

        this.basCompatibleSources.push(basBluetooths)
      }

      if (basVideos.items.length) {

        this.basCompatibleSources.push(basVideos)
      }

    } else {

      this.basCompatibleSources = BAS_SOURCES.SOURCES.uiActive
    }
  }

  /**
   * @private
   */
  BasRoomMusic.prototype._onAvAudioState = function () {

    var _oldBasSource

    _oldBasSource = this.basSource

    if (this._syncAvAudio()) {

      if (this.basSource !== _oldBasSource) {

        $rootScope.$emit(
          BAS_ROOM.EVT_SOURCE_CHANGED,
          this._basRoom.id
        )
      }

      $rootScope.$applyAsync()
    }
  }

  /**
   * @private
   */
  BasRoomMusic.prototype._onAvAudioReachable = function () {

    if (this.syncAvailability()) {

      $rootScope.$emit(
        BAS_ROOM.EVT_AVAILABLE_CHANGED,
        this._basRoom.id
      )
      $rootScope.$applyAsync()
    }
  }

  /**
   * @returns {string[]}
   */
  BasRoomMusic.prototype.getCompatibleSources = function () {

    return this._avAudio
      ? this._avAudio.compatibleSources
      : [] // TODO: In future maybe this can be used for Asano too?
  }

  /**
   * @param {string} sourceUuid
   * @returns {boolean}
   */
  BasRoomMusic.prototype.isCompatibleSource = function (sourceUuid) {

    return (
      this._avAudio &&
      BasUtil.isNEString(sourceUuid) &&
      this._avAudio.compatibleSources.indexOf(sourceUuid) !== -1
    )
  }

  /**
   * @returns {string}
   */
  BasRoomMusic.prototype.getDefaultSource = function () {

    return this._avAudio
      ? this._avAudio.defaultSource
      : ''
  }

  /**
   * @private
   * @param {string} profile
   * @returns {Promise}
   */
  BasRoomMusic.prototype._setDspProfile = function (profile) {
    this._updateEqualisersWithPreset(profile)

    return profile === BAS_DSP.DSP_PROFILE_FLAT_ID
      ? this._resetAllEqualisers()
      : this._updateRoomDsp()
  }

  /**
   * @private
   * @param {string} profileId
   */
  BasRoomMusic.prototype._updateEqualisersWithPreset = function (profileId) {
    var length, i, entry, dspProfileEq, equaliser

    dspProfileEq = BasRoomMusic.getDspProfileById(profileId).equalisers

    if (dspProfileEq) {

      length = dspProfileEq.length
      for (i = 0; i < length; i++) {

        entry = dspProfileEq[i]
        equaliser = BasEq.getEqualiserById(this.equalisers, entry.id)

        if (equaliser) equaliser.gain = entry.gain
      }
    }
  }

  /**
   * @param {string} selectedProfile
   * @returns {Promise}
   */
  BasRoomMusic.prototype.selectDspProfile = function (selectedProfile) {

    if (
      BAS_DSP.DSP_PROFILES.indexOf(selectedProfile) !== -1 ||
      selectedProfile === BAS_DSP.DSP_PROFILE_CUSTOM_ID
    ) {

      this.selectedDspProfile = selectedProfile

      return this.selectedDspProfile !== BAS_DSP.DSP_PROFILE_CUSTOM_ID
        ? this._setDspProfile(this.selectedDspProfile)
        : Promise.resolve()
    }

    return Promise.reject('Selected profile not valid')
  }

  /**
   * @private
   * @returns {?BasSource}
   */
  BasRoomMusic.prototype._getSource = function () {

    return this._roomIsSourceGroup()
      ? Sources.getBasSource(this._basRoom.sourceUuid)
      : null
  }

  /**
   * @private
   * @returns {boolean}
   */
  BasRoomMusic.prototype._roomIsSourceGroup = function () {

    return (
      this._basRoom &&
      this._basRoom.isSourceGroup() &&
      BasRoomMusic.hasAVSource(this._basRoom)
    )
  }

  BasRoomMusic.prototype._onAudioSourceCapabilities = function () {

    if (this._syncAudioSourceCapabilities()) {

      $rootScope.$applyAsync()
    }
  }

  BasRoomMusic.prototype._syncAudioSourceCapabilities = function () {

    var _changed

    _changed = false

    if (this._audioSource) {

      if (this._cssSet(
        CSS_CAN_ADJUST_VOLUME,
        this._audioSource.allowsWrite(BAS_API.AudioSource.C_VOLUME)
      )) {

        _changed = true
      }

      if (this.syncCanGroup()) {

        _changed = true
      }
    }

    return _changed
  }

  BasRoomMusic.prototype._onAudioSourceVolume = function () {

    this._syncVolume()
    $rootScope.$applyAsync()
  }

  BasRoomMusic.prototype._onAudioSourceMuted = function () {

    this._syncMuted()
    this._syncVolume()
    $rootScope.$applyAsync()
  }

  BasRoomMusic.prototype._onAudioSourcePlayback = function () {

    this._syncOnState()
    this.syncCanGroup()
    $rootScope.$applyAsync()
  }

  BasRoomMusic.prototype._onAudioSourceListeningRooms = function () {

    this._syncSourceName()
    this.syncCanGroup()
    $rootScope.$applyAsync()
  }

  BasRoomMusic.prototype._onAudioSourceIsOn = function () {

    this._syncOnState()
    $rootScope.$applyAsync()
  }

  /**
   * Zone EVT_SOURCE listener
   *
   * @private
   */
  BasRoomMusic.prototype._onZoneSource = function onSource () {

    var _oldBasSource

    _oldBasSource = this.basSource

    this.syncSource()

    if (this._basRoom) {

      if (this.basSource !== _oldBasSource) {

        $rootScope.$emit(
          BAS_ROOM.EVT_SOURCE_CHANGED,
          this._basRoom.id
        )
      }
    }

    $rootScope.$applyAsync()
  }

  /**
   * Zone EVT_VOLUME listener
   *
   * @private
   */
  BasRoomMusic.prototype._onZoneVolume = function onVolume () {

    this._syncVolume()
    $rootScope.$applyAsync()
  }

  /**
   * Zone EVT_MUTED listener
   *
   * @private
   */
  BasRoomMusic.prototype._onZoneMuted = function onMuted () {

    this._syncMuted()
    this._syncVolume()
    $rootScope.$applyAsync()
  }

  /**
   * Zone EVT_TREBLE listener
   *
   * @private
   * @param {number} treble
   */
  BasRoomMusic.prototype._onZoneTreble = function onTreble (treble) {

    this.treble = treble

    $rootScope.$applyAsync()
  }

  /**
   * Zone EVT_BASS listener
   *
   * @private
   * @param {number} bass
   */
  BasRoomMusic.prototype._onZoneBass = function onBass (bass) {

    this.bass = bass

    $rootScope.$applyAsync()
  }

  /**
   * Zone EVT_STARTUP_VOLUME listener
   *
   * @private
   * @param {number} startupVolume
   */
  BasRoomMusic.prototype._onZoneStartupVolume = function (
    startupVolume
  ) {
    this.startupVolume = startupVolume

    $rootScope.$applyAsync()
  }

  /**
   * @private
   */
  BasRoomMusic.prototype._setAvAudioListeners = function () {

    this._clearAvAudioListeners()

    if (this._avAudio) {

      this._avAudioListeners.push(BasUtil.setEventListener(
        this._avAudio,
        BAS_API.AVAudio.EVT_CAPABILITIES_CHANGED,
        this._handleAvAudioCapabilities
      ))
      this._avAudioListeners.push(BasUtil.setEventListener(
        this._avAudio,
        BAS_API.AVAudio.EVT_ATTRIBUTES_CHANGED,
        this._handleAvAudioAttribtues
      ))
      this._avAudioListeners.push(BasUtil.setEventListener(
        this._avAudio,
        BAS_API.AVAudio.EVT_STATE_CHANGED,
        this._handleAvAudioState
      ))
      this._avAudioListeners.push(BasUtil.setEventListener(
        this._avAudio,
        BAS_API.AVAudio.EVT_REACHABLE_CHANGED,
        this._handleAvAudioReachable
      ))
    }
  }

  /**
   * @private
   */
  BasRoomMusic.prototype._clearAvAudioListeners = function () {

    BasUtil.executeArray(this._sourceListeners)
    this._sourceListeners = []
  }

  /**
   * @private
   */
  BasRoomMusic.prototype._setSourceListeners = function () {

    this._clearSourceListeners()

    if (this._audioSource) {

      this._sourceListeners.push(BasUtil.setEventListener(
        this._audioSource,
        BAS_API.Device.EVT_CAPABILITIES_CHANGED,
        this._handleAudioSourceCapabilities
      ))
      this._sourceListeners.push(BasUtil.setEventListener(
        this._audioSource,
        BAS_API.AudioSource.EVT_VOLUME_CHANGED,
        this._handleAudioSourceVolume
      ))
      this._sourceListeners.push(BasUtil.setEventListener(
        this._audioSource,
        BAS_API.AudioSource.EVT_MUTE_CHANGED,
        this._handleAudioSourceMuted
      ))
      this._sourceListeners.push(BasUtil.setEventListener(
        this._audioSource,
        BAS_API.AudioSource.EVT_PLAYBACK_CHANGED,
        this._handleAudioSourcePlayback
      ))
      this._sourceListeners.push(BasUtil.setEventListener(
        this._audioSource,
        BAS_API.AudioSource.EVT_LISTENING_ROOMS_CHANGED,
        this._handleAudioSourceListeningRooms
      ))
      this._sourceListeners.push(BasUtil.setEventListener(
        this._audioSource,
        BAS_API.AudioSource.EVT_ON_CHANGED,
        this._handleAudioSourceIsOn
      ))
    }
  }

  /**
   * @private
   */
  BasRoomMusic.prototype._clearSourceListeners = function () {

    BasUtil.executeArray(this._avAudioListeners)
    this._avAudioListeners = []
  }

  /**
   * Set all listeners for the Zone
   *
   * @private
   */
  BasRoomMusic.prototype._setZoneListeners = function setZoneListeners () {

    this._clearZoneListeners()

    if (this._zone instanceof BAS_API.Zone) {

      this._zoneListeners.push(BasUtil.setEventListener(
        this._zone,
        BAS_API.Zone.EVT_SOURCE,
        this._handleZoneSource
      ))
      this._zoneListeners.push(BasUtil.setEventListener(
        this._zone,
        BAS_API.Zone.EVT_VOLUME,
        this._handleZoneVolume
      ))
      this._zoneListeners.push(BasUtil.setEventListener(
        this._zone,
        BAS_API.Zone.EVT_MUTED,
        this._handleZoneMuted
      ))
      this._zoneListeners.push(BasUtil.setEventListener(
        this._zone,
        BAS_API.Zone.EVT_TREBLE,
        this._handleZoneTreble
      ))
      this._zoneListeners.push(BasUtil.setEventListener(
        this._zone,
        BAS_API.Zone.EVT_BASS,
        this._handleZoneBass
      ))
      this._zoneListeners.push(BasUtil.setEventListener(
        this._zone,
        BAS_API.Zone.EVT_STARTUP_VOLUME,
        this._handleZoneStartupVolume
      ))
    }
  }

  /**
   * Clear all zone listeners
   *
   * @private
   */
  BasRoomMusic.prototype._clearZoneListeners = function clearZListeners () {

    BasUtil.executeArray(this._zoneListeners)
    this._zoneListeners = []
  }

  /**
   * @private
   */
  BasRoomMusic.prototype._clearBits = function () {

    this.bitIcon.unTrack()
    this.bitIcon.setImage(null)

    this.bit.unTrack()
    this.bit.setImage(null)
  }

  /**
   * @private
   */
  BasRoomMusic.prototype._clearUiSettingsButtonTimeout = function () {

    clearTimeout(this._uiSettingsButtonTimeoutId)
    this._uiSettingsButtonTimeoutId = 0
  }

  /**
   * Returns if changed
   *
   * @private
   * @param {string} key
   * @param {boolean} value
   * @returns {boolean}
   */
  BasRoomMusic.prototype._cssSet = function (key, value) {

    var _old

    _old = this._css[key]
    this._css[key] = value
    if (this._basRoom) this._basRoom.css[key] = this._css[key]
    return _old !== this._css[key]
  }

  /**
   * @private
   */
  BasRoomMusic.prototype._resetProperties = function () {

    this.volume = 0
    this.muted = false
    this.bass = 0
    this.treble = 0
    this.startupVolume = 0
    this.equalisers = []
  }

  /**
   * @private
   */
  BasRoomMusic.prototype._syncCssToBasRoom = function () {

    if (this._basRoom) {

      BasUtil.mergeObjects(this._basRoom.css, this._css)
    }
  }

  /**
   * @private
   */
  BasRoomMusic.prototype._resetCss = function () {

    this._css[CSS_HAS_MUSIC] = false
    this._css[CSS_CAN_GROUP] = false
    this._css[CSS_IS_UNAVAILABLE] = false
    this._css[CSS_MUTED] = false
    this._css[CSS_CAN_ADJUST_VOLUME] = false
    this._css[CSS_HAS_SOURCE_NAME] = false
    this._css[CSS_HAS_ROOM_SETTINGS] = false
    this._css[CSS_HAS_TREBLE] = false
    this._css[CSS_HAS_BASS] = false
    this._css[CSS_HAS_STARTUP_VOLUME] = false
    this._css[CSS_HAS_STEREO_WIDENING] = false
    this._css[CSS_SHOW_ROOM_SETTINGS_BUTTON] = false
  }

  BasRoomMusic.prototype.destroy = function destroy () {

    // Clear CSS on parent
    this._resetCss()
    this._syncCssToBasRoom()

    this.suspend()
    this._avAudio = null
    this._zone = null
    this._basRoom = null
  }

  return BasRoomMusic
}
