'use strict'
import * as BasUtil from '@basalte/bas-util'
angular
  .module('basalteApp')
  .controller('energyDetailCtrl', [
    '$rootScope',
    '$scope',
    '$uiRouterGlobals',
    'BAS_ENERGY',
    'BAS_UTILITIES',
    'BasEnergy',
    'BasContextModal',
    'BasUtilities',
    energyDetailCtrl
  ])
/**
 * @param $rootScope
 * @param $scope
 * @param $uiRouterGlobals
 * @param {BAS_ENERGY} BAS_ENERGY
 * @param {BAS_UTILITIES} BAS_UTILITIES
 * @param {BasEnergy} BasEnergy
 * @param {BasContextModal} BasContextModal
 * @param {BasUtilities} BasUtilities
 */
function energyDetailCtrl (
  $rootScope,
  $scope,
  $uiRouterGlobals,
  BAS_ENERGY,
  BAS_UTILITIES,
  BasEnergy,
  BasContextModal,
  BasUtilities
) {
  var energyDetail = this
  var _listeners = []
  /**
   * @private
   * @type {TBasEnergyRange[]}
   */
  var _ranges = []
  energyDetail.$uiRouterGlobals = $uiRouterGlobals
  /**
   * @type {TBasEnergyState}
   */
  energyDetail.basEnergyState = BasEnergy.get()
  energyDetail.currentRangeId = ''
  energyDetail.uiCurrentRange = '-'
  energyDetail.selectCurrentType = selectCurrentType
  energyDetail.openRangeMenu = openRangeMenu

  init()

  function init () {

    $scope.$on('$destroy', _onDestroy)

    _listeners.push($rootScope.$on(
      BAS_ENERGY.EVT_UI_UPDATE,
      _onEnergyUiUpdate
    ))

    _listeners.push($rootScope.$on(
      BAS_ENERGY.EVT_DATA_UPDATE,
      _onDataUpdate
    ))

    _initEnergyDetail()
  }
  /**
   * @param {string} historyType
   */
  function selectCurrentType (historyType) {
    const dev = _getEnergyMeter()

    if (!dev) return

    if (historyType !== dev.currentHistoryType) {
      dev.selectCurrentType(historyType)
      _syncRangeOptions()
      _setFirstRangeOption()
      _syncUiCurrentRange()
      _retrieveData()
    }
  }

  function openRangeMenu (event) {
    BasContextModal.show(
      event,
      {
        options: _ranges,
        selectedId: energyDetail.currentRangeId
      }
    ).then((modal) => modal.close.then(_onRangeModalClose))
  }

  function _onRangeModalClose (result) {
    var range
    if (
      BasUtil.isNEString(result) &&
      result !== energyDetail.currentRangeId
    ) {
      range = _getRangeForId(_ranges, result)
      if (range) {
        energyDetail.currentRangeId = result
        _syncUiCurrentRange()
        _retrieveData()
      }
    }
  }

  function _initEnergyDetail () {
    // Order is important
    _syncCurrentHistoryType()
    _syncRangeOptions()
    _setFirstRangeOption()
    _syncUiCurrentRange()
    _retrieveData()
  }

  function _retrieveData () {
    const range = _getRangeForId(_ranges, energyDetail.currentRangeId)
    const dev = _getEnergyMeter()

    if (dev && range) {
      dev.retrieveData({
        start: range.start,
        stop: range.stop,
        step: dev.currentHistoryType === BAS_ENERGY.T_ID_HIST_YEAR
          ? BAS_UTILITIES.T_3D_MS
          : BAS_UTILITIES.T_1D_MS
      })
    }
  }

  function _syncCurrentHistoryType () {
    const dev = _getEnergyMeter()

    if (dev) {
      dev.selectCurrentType(dev.uiDetailHistoryTypes[0])
    }
  }

  function _setFirstRangeOption () {
    if (_ranges[0]) energyDetail.currentRangeId = _ranges[0].id
  }

  function _syncUiCurrentRange () {
    var range
    energyDetail.uiCurrentRange = '-'
    range = _getRangeForId(_ranges, energyDetail.currentRangeId)

    if (range && BasUtil.isNEString(range.text)) {
      energyDetail.uiCurrentRange = range.text
      // Only specify textSuffix if different from current year
      if (
        BasUtil.isNEString(range.textSuffix) &&
        new Date().getFullYear().toString() !== range.textSuffix
      ) {
        energyDetail.uiCurrentRange += ' ' + range.textSuffix
      }
    }
  }

  function _syncRangeOptions () {
    _ranges = _getRangeOptions()
  }

  /**
   * @private
   * @returns {TBasEnergyRange[]}
   */
  function _getRangeOptions () {
    const result = []
    const dev = _getEnergyMeter()

    if (!dev) return result

    const oldestTimestamp = dev.getOldestTimestamp()
    const oldestDate = oldestTimestamp
      ? new Date(oldestTimestamp)
      : null
    const dNow = new Date()

    switch (dev.currentHistoryType) {
      case BAS_ENERGY.T_ID_HIST_MONTH:
        // This month
        addMonth(new Date(dNow.getFullYear(), dNow.getMonth(), 15))
        if (oldestDate) {
          // Past 12 months
          for (let i = 1; i <= 12; i++) {
            // Create Date object in the middle of the month
            const date = new Date(dNow.getFullYear(), dNow.getMonth() - i, 15)
            const year = date.getFullYear()
            const month = date.getMonth()
            const oldestYear = oldestDate.getFullYear()
            const oldestMonth = oldestDate.getMonth()
            // If month has no data, break
            if (
              oldestYear > year ||
              (
                oldestYear === year &&
                oldestMonth > month
              )
            ) {
              break
            }
            addMonth(date)
          }
        }
        break
      case BAS_ENERGY.T_ID_HIST_YEAR: {
        // Current year until oldest year with data
        const oldestYear = oldestDate?.getFullYear() ?? 0
        const currentYear = dNow.getFullYear()
        // This year
        result.push({
          id: '' + currentYear,
          type: BAS_ENERGY.T_ID_HIST_YEAR,
          text: '' + currentYear,
          start: '' + currentYear + '-01-01',
          stop: '' + currentYear + '-12-31'
        })
        if (oldestYear) {
          const yearDiff = currentYear - oldestYear
          for (let i = 1; i < (yearDiff + 1); i++) {
            const newYear = currentYear - i
            result.push({
              id: '' + newYear,
              type: BAS_ENERGY.T_ID_HIST_YEAR,
              text: '' + newYear,
              start: '' + newYear + '-01-01',
              stop: '' + newYear + '-12-31'
            })
          }
        }
        break
      }
    }
    return result

    /**
     * @param {Date} date
     */
    function addMonth (date) {
      const month = date.getMonth()
      const year = date.getFullYear()
      const paddedMonth = BasUtil.padLeft(month + 1, 2, '0')
      // Date 0 of next month to get last day of the given month
      const dNextMonth = new Date(year, month + 1, 0)
      const paddedLastDayOfMonth = BasUtil.padLeft(
        dNextMonth.getDate(),
        2,
        '0'
      )
      result.push({
        id: '' + year + '-' + month,
        type: BAS_ENERGY.T_ID_HIST_MONTH,
        textSuffix: '' + year,
        text: BasUtilities.translateMonth(month),
        start: '' + year + '-' + paddedMonth + '-01',
        stop: '' + year + '-' + paddedMonth + '-' + paddedLastDayOfMonth
      })
    }
  }

  /**
   * @private
   * @param {TBasEnergyRange[]} ranges
   * @param {string} id
   * @returns {?TBasEnergyRange}
   */
  function _getRangeForId (ranges, id) {
    var length, i, range
    length = ranges.length
    for (i = 0; i < length; i++) {
      range = ranges[i]
      if (range && range.id === id) return range
    }
    return null
  }

  /**
   * @private
   * @returns {?BasEnergyMeter}
   */
  function _getEnergyMeter () {
    const dev = energyDetail.basEnergyState
      .energyMeters[energyDetail.$uiRouterGlobals.params.energydeviceid]
    return (dev && dev.retrieveData) ? dev : null
  }

  function _onEnergyUiUpdate () {

    $scope.$applyAsync()
  }

  function _onDataUpdate () {

    _initEnergyDetail()
  }

  function _onDestroy () {
    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
