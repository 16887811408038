'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasEnergyHelper', [
    'BAS_ENERGY',
    'BAS_UTILITIES',
    BasEnergyHelper
  ])

/**
 * @callback CBasEnergyTransform
 * @param {*} input
 * @returns {*}
 */

/**
 * @typedef {Object} TBasEnergyRange
 * @property {string} id
 * @property {string} type
 * @property {string} text
 * @property {string} start
 * @property {string} stop
 */

/**
 * @typedef {Object} TBasEnergyMeterGetAllDataOptions
 * @property {string} start ISO 8601 string
 * @property {string} [stop] ISO 8601 string, by default "now"
 * @property {number} [step = 3600000] time in ms
 */

/**
 * @typedef {Object} TBasEnergyMeterResultAll
 * @property {string} start ISO 8601 with timezone offset
 * @property {string} stop ISO 8601 with timezone offset
 * @property {number} step
 * @property {Array<Array<(string|number)>>} result
 */

/**
 * @typedef {Object} TBasEnergyMeterGroupedResults
 * @property {string} start ISO 8601 with timezone offset
 * @property {string} stop ISO 8601 with timezone offset
 * @property {Array<Array<(string|number)>>} result
 * @property {string} groupBy
 */

/**
 * @constructor
 * @param {BAS_ENERGY} BAS_ENERGY
 */
function BasEnergyHelper (
  BAS_ENERGY
) {

  this.requestAll = requestAll
  this.filterData = filterData
  this.groupByDay = groupByDay
  this.groupByMonth = groupByMonth
  this.groupByYear = groupByYear
  this.getMonthId = getMonthId
  this.transformAllData = transformAllData
  this.transformData = transformData

  /**
   * @param {EnergyDevice} device
   * @param {TBasEnergyMeterGetAllDataOptions} options
   * @returns {Promise<TBasEnergyMeterResultAll>}
   */
  function requestAll (
    device,
    options
  ) {
    var offset, limit, entries

    if (device && device.getData) {

      entries = []

      offset = 0
      limit = 200

      return requestMore()
    }

    return Promise.reject(new Error('device or getData not available'))

    function requestMore () {
      return device.getData(BasUtil.mergeObjects(
        options,
        {
          offset: offset,
          limit: limit
        }
      )).then(onData)
    }

    /**
     * @param {TBasEnergyMeterResult} result
     */
    function onData (result) {

      var total, rLimit

      if (result && Array.isArray(result.result)) {

        rLimit = result.limit
        total = result.result.length

        entries = entries.concat(result.result)

        if (total >= rLimit) {

          // Need more requests

          offset += total
          return requestMore()

        } else {

          // All data retrieved

          return {
            start: BasUtil.stripTimezoneFromIso8601(result.start),
            stop: BasUtil.stripTimezoneFromIso8601(result.stop),
            step: result.step,
            result: entries
          }
        }
      }

      return Promise.reject(new Error('invalid result'))
    }
  }

  /**
   * @param {TBasEnergyMeterResultAll} input
   * @returns {?TBasEnergyMeterGroupedResults}
   */
  function groupByDay (input) {

    var result, entries, length, i, entry, timestamp, value
    var dateId, existingEntry, existingValue

    if (
      BasUtil.isObject(input) &&
        Array.isArray(input.result) &&
        BasUtil.isNEString(input.start) &&
        BasUtil.isNEString(input.stop)
    ) {
      entries = []

      result = {
        start: input.start,
        stop: input.stop,
        groupBy: BAS_ENERGY.T_ID_HIST_DAY
      }

      length = input.result.length
      for (i = 0; i < length; i++) {

        entry = input.result[i]

        if (Array.isArray(entry)) {

          timestamp = entry[0]
          value = entry[1]

          if (BasUtil.isNEString(timestamp)) {

            dateId = getDateId(timestamp)
            existingEntry = getEntryFromData(entries, dateId)

            if (existingEntry) {
              existingValue = existingEntry[1]
              existingEntry[1] = BasUtil.isVNumber(existingValue)
                ? BasUtil.isVNumber(value)
                  ? existingValue + value
                  : existingValue
                : BasUtil.isVNumber(value)
                  ? value
                  : 0
            } else {
              entries.push([
                dateId + 'T00:00:00',
                entry[1]
              ])
            }
          }
        }
      }

      result.result = entries

      return result
    }

    return null
  }

  /**
   * @param {TBasEnergyMeterResultAll} input
   * @returns {?TBasEnergyMeterGroupedResults}
   */
  function groupByMonth (input) {

    var result, entries, length, i, entry, timestamp, value
    var monthId, existingEntry, existingValue

    if (
      BasUtil.isObject(input) &&
      Array.isArray(input.result) &&
      BasUtil.isNEString(input.start) &&
      BasUtil.isNEString(input.stop)
    ) {
      entries = []

      result = {
        start: input.start,
        stop: input.stop,
        groupBy: BAS_ENERGY.T_ID_HIST_MONTH
      }

      length = input.result.length
      for (i = 0; i < length; i++) {

        entry = input.result[i]

        if (Array.isArray(entry)) {

          timestamp = entry[0]
          value = entry[1]

          if (BasUtil.isNEString(timestamp)) {

            monthId = getMonthId(timestamp)
            existingEntry = getEntryFromData(entries, monthId)

            if (existingEntry) {
              existingValue = existingEntry[1]
              existingEntry[1] = BasUtil.isVNumber(existingValue)
                ? BasUtil.isVNumber(value)
                  ? existingValue + value
                  : existingValue
                : BasUtil.isVNumber(value)
                  ? value
                  : 0
            } else {
              entries.push([
                monthId + '-01T00:00:00',
                entry[1]
              ])
            }
          }
        }
      }

      result.result = entries

      return result
    }

    return null
  }

  /**
   * @param {TBasEnergyMeterResultAll} input
   * @returns {?TBasEnergyMeterGroupedResults}
   */
  function groupByYear (input) {

    var result, entries, length, i, entry, timestamp, value
    var yearId, existingEntry, existingValue

    if (
      BasUtil.isObject(input) &&
      Array.isArray(input.result) &&
      BasUtil.isNEString(input.start) &&
      BasUtil.isNEString(input.stop)
    ) {
      entries = []

      result = {
        start: input.start,
        stop: input.stop,
        groupBy: BAS_ENERGY.T_ID_HIST_YEAR
      }

      length = input.result.length
      for (i = 0; i < length; i++) {

        entry = input.result[i]

        if (Array.isArray(entry)) {

          timestamp = entry[0]
          value = entry[1]

          if (BasUtil.isNEString(timestamp)) {

            yearId = getYearId(timestamp)
            existingEntry = getEntryFromData(entries, yearId)

            if (existingEntry) {
              existingValue = existingEntry[1]
              existingEntry[1] = BasUtil.isVNumber(existingValue)
                ? BasUtil.isVNumber(value)
                  ? existingValue + value
                  : existingValue
                : BasUtil.isVNumber(value)
                  ? value
                  : 0
            } else {
              entries.push([
                yearId + '-01-01T00:00:00',
                entry[1]
              ])
            }
          }
        }
      }

      result.result = entries

      return result
    }

    return null
  }

  /**
   * @param {TBasEnergyMeterResultAll} input
   * @returns {?TBasEnergyMeterResultAll}
   */
  function filterData (input) {

    var result, entries, length, i, entry

    if (
      BasUtil.isObject(input) &&
      Array.isArray(input.result) &&
      BasUtil.isNEString(input.start) &&
      BasUtil.isNEString(input.stop)
    ) {
      entries = []

      result = {
        start: input.start,
        stop: input.stop,
        step: input.step
      }

      length = input.result.length
      for (i = 0; i < length; i++) {

        entry = input.result[i]

        if (
          Array.isArray(entry) &&
          BasUtil.isNEString(entry[0]) &&
          BasUtil.isVNumber(entry[1])
        ) {
          entries.push(entry)
        }
      }

      result.result = entries

      return result
    }

    return null
  }

  /**
   * @param {(TBasEnergyMeterResultAll|TBasEnergyMeterGroupedResults)} input
   * @param {CBasEnergyTransform} transformFunction
   * @returns {(TBasEnergyMeterResultAll|TBasEnergyMeterGroupedResults)}
   */
  function transformAllData (
    input,
    transformFunction
  ) {

    var result

    if (
      BasUtil.isObject(input) &&
      Array.isArray(input.result) &&
      BasUtil.isNEString(input.start) &&
      BasUtil.isNEString(input.stop)
    ) {
      result = {
        start: input.start,
        stop: input.stop,
        result: transformData(input.result, transformFunction)
      }

      if (BasUtil.isVNumber(input.step)) result.step = input.step
      if (BasUtil.isNEString(input.groupBy)) result.groupBy = input.groupBy

      return result
    }

    return input
  }

  /**
   * @param {Array<Array<(string|number)>>} data
   * @param {CBasEnergyTransform} transformFunction
   * @returns {?Array}
   */
  function transformData (
    data,
    transformFunction
  ) {
    var result, length, i, entry, t, v

    if (Array.isArray(data)) {

      result = []

      length = data.length
      for (i = 0; i < length; i++) {
        entry = data[i]
        if (entry) {
          t = entry[0]
          v = entry[1]
          result.push([t, transformFunction(v)])
        }
      }

      return result
    }

    return null
  }

  /**
   * @param {Array<Array<(string|number)>>} data
   * @param {string} timestampPrefix
   * @returns {?Array}
   */
  function getEntryFromData (
    data,
    timestampPrefix
  ) {
    var length, i, entry, d

    length = data.length
    for (i = 0; i < length; i++) {
      entry = data[i]
      if (entry) {
        d = entry[0]
        if (BasUtil.isString(d) && d.indexOf(timestampPrefix) === 0) {
          return entry
        }
      }
    }

    return null
  }

  /**
   * @param {string} timestamp ISO 8601
   * @returns {string}
   */
  function getDateId (timestamp) {
    return BasUtil.isString(timestamp)
      ? timestamp.substring(0, 10)
      : ''
  }

  /**
   * @param {string} timestamp ISO 8601
   * @returns {string}
   */
  function getMonthId (timestamp) {
    return BasUtil.isString(timestamp)
      ? timestamp.substring(0, 7)
      : ''
  }

  /**
   * @param {string} timestamp ISO 8601
   * @returns {string}
   */
  function getYearId (timestamp) {
    return BasUtil.isString(timestamp)
      ? timestamp.substring(0, 4)
      : ''
  }
}
