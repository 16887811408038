{
  "12_hr": "12 valandų laikas",
  "24_hr": "24 valandų laikas",
  "24_hr_time": "24 valandų laikas",
  "about": "Apie",
  "account": "Paskyra",
  "account_exists": "Paskyra egzistuoja",
  "account_exists_l1": "Tokia paskyra jau egzistuoja",
  "account_exists_l2": "Pasirinkite kitą elektroninį paštą arba junkitės su esamu.",
  "account_not_confirmed": "Paskyra nepatvirtinta",
  "account_not_confirmed_l1": "Ši paskyra dar nepatvirtinta.",
  "account_not_confirmed_l2": "Patikrinkite elektroninį paštą registracijos patvirtinimui.",
  "account_not_confirmed_l3": "Tikrinti šią paskyrą?",
  "act_in": "Į",
  "activate": "Aktyvuoti",
  "active": "Aktyvus",
  "act_out": "Iš",
  "act_push": "SPAUSTI",
  "adaptive_brightness": "Pritaikytas ryškumas",
  "add": "Papildyti",
  "add_favourite": "Pridėti mėgstamiausią",
  "add_group": "Pridėti grupę",
  "add_home": "Pridėti namą",
  "address": "Adresas",
  "add_to_fav": "Pridėti prie mėgstamų",
  "add_to_playlist": "Pridėti į grojaraštį",
  "add_to_queue": "Pridėti į eilę",
  "air_conditioning": "Oro kondicionavimas",
  "air_pressure": "Oro slėgis",
  "alarm": "Signalizacija",
  "alarm_at": "Žadintuvas",
  "alarm_away": "Pasišalinęs",
  "alarm_disarm": "Išjungti",
  "alarm_good_night": "Labos nakties",
  "alarm_missing_txt": "Trūksta vieno ar daugiau užpildytų laukų",
  "alarm_not_selected_l1": "Nenustatytas žadintuvas",
  "alarm_not_selected_l2": "Prašau nustatykite žadintuvą",
  "alarms": "Žadintuvai",
  "album": "Albumas",
  "albums": "Albumai",
  "alert": "Įspėjimas",
  "all": "Viskas",
  "all_off": "Viską išjungti",
  "all_off_txt_l1": "Jūs norite visuose kambariuose išjungti garsą.",
  "all_off_txt_l2": "Ar jūs tikrai norite tęsti?",
  "all_on": "Įjungti viską",
  "almost_done": "Beveik baigta.",
  "almost_done_txt_l1": "Jūs kol kas neturite mėgstamų grojaraščių ar radijo stočių.",
  "almost_done_txt_l2": "Iš pradžių pridėkite kažką į savo mėgstamiausių grojaraštį.",
  "already_linked": "Susieta su Basalte paskyra",
  "already_verified": "Jau patikrinta",
  "alt_power": "Įjungti/išjungti",
  "alt_user": "Naudotojas",
  "application": "Programa",
  "application_version": "Programos versija",
  "app_update_required": "Būtinas programos atnaujinimas",
  "app_update_required_android_l2": "Prašome atnaujinti programą per Play Store.",
  "app_update_required_ios_l2": "Prašome atnaujinti programą per App Store.",
  "app_update_required_l2": "Prašome atnaujinti programą.",
  "arming_the_alarm": "Įjungti signalizaciją...",
  "artist": "Atlikėjas",
  "artists": "Atlikėjai",
  "attention": "Dėmesio!",
  "audio_unavailable": "Garso sistema nepasiekiama",
  "audio_zones": "Garso zonos",
  "author": "Autorius",
  "average": "Vidutinis",
  "back": "Atgal",
  "basalte_account": "Basalte paskyra",
  "basalte_info_l1": "Susiedami šį namą su Basalte paskyra galėsite valdyti jį už namų tinklo ribos.",
  "basalte_link_server_l1": "Susieti šį namą su Basalte paskyra?",
  "basalte_link_server_l2": "Šį namą galėsite valdyti už namų tinklo ribos.",
  "basalte_unlink_server_l1": "Atsieti šį namą nuo Basalte paskyros?",
  "basalte_unlink_server_l2": "Daugiau neturėsite galimybės pasiekti namo funkcijas už namų tinklo ribos.",
  "bass": "Žemi dažniai",
  "BE": "Belgija",
  "beta": "Beta",
  "bluetooth": "Bluetooth",
  "bluetooth_pairing_txt": "Dabar galite prisijungti prie savo prietaiso per Bluetooth.",
  "bospeaker": "B&O",
  "brightness": "Šviesumas",
  "browse": "Naršyti",
  "browse_deezer": "Deezer mėgstamiausi",
  "browse_library": "Muzikos biblioteka",
  "browser_app_update_required_l2": "Perkraukit puslapį arba atnaujinkite serverį.",
  "browse_stations": "Radijo stotys",
  "buffering": "buferizacija",
  "building_building": "Pastatas",
  "building_garage": "Garažas",
  "building_main_house": "Namas",
  "building_pool_house": "Baseinas",
  "building_stables": "Arklidės",
  "cameras": "Kameros",
  "cancel": "Atšaukti",
  "categories": "Kategorijos",
  "category": "Kategorija",
  "celsius": "Celsijus",
  "change_account": "Keisti sąskaitą",
  "change_home": "Keisti namą",
  "change_image": "Pakeisti atvaizdą",
  "change_password": "Keisti slaptažodį",
  "change_password_l1": "Ketinate pakeisti slaptažodį.",
  "change_user": "Pakeisti profilį",
  "channel_number": "Kanalo numeris",
  "charts": "Diagramos",
  "check_for_updates": "Tikrinti atnaujinimus",
  "checking": "tikrinama",
  "checking_for_updates": "Tikrinama, ar nėra atnaujinimų...",
  "choose_favourite": "Ar norėtumėte rinktis mėgstamiausią?",
  "choose_home": "Rinktis namą",
  "choose_music": "Ar norėtumėte rinktis mėgstamiausią?",
  "choose_preset": "Pasirinkite sąranką",
  "choose_source": "Ar norėtumėte rinktis šaltinį?",
  "clear": "Giedra",
  "cleared": "Išvalyta",
  "clear_queue_txt_l1": "Jūs norite ištrinti eiliškumą.",
  "clear_queue_txt_l2": "Tikrai norite tęsti?",
  "clear_selection": "Ištrinti parinktus",
  "clear_selection_txt_l1": "Ištrinsite savo rinkinį.",
  "clear_selection_txt_l2": "Tikrai norite tęsti?",
  "close": "Uždaryti",
  "closed": "Uždaryta",
  "code": "Kodas",
  "color": "Spalva",
  "color_temperature": "Spalvos temperatūra",
  "color_theme": "Spalvos tema",
  "color_white": "Balta",
  "comfort": "Komfortas",
  "configure": "Keisti",
  "confirmation_code_sent": "Patvirtinimo kodas išsiųstas",
  "confirmation_code_sent_l1": "Patvirtinimo kodas išsiųstas adresu:",
  "confirm_continue": "Ar tikrai norite tęsti?",
  "connect": "Prijungti",
  "connected": "Prijungta",
  "connecting": "Jungiama",
  "connecting_no_active_servers": "Ryšys su serveriu nepasiekiamas.",
  "connecting_no_integrator_access": "Nuotolinis serverio konfigūravimas išjungtas.",
  "connecting_not_authorized": "Neturite teisių jungtis į serverį.",
  "connecting_small": "jungiama",
  "connecting_stop_and_retry_text": "Nepavyko prisijungti prie serverio. Dar kartą bandoma per 10 sekundžių.",
  "connecting_stop_text": "Nepavyko prisijungti prie serverio.",
  "connecting_text": "Jungiama...",
  "connecting_text_small": "jungiama...",
  "connection_lost": "Nėra ryšio",
  "connection_lost_text": "Iš naujo jungiamasi prie serverio",
  "connection_unable": "Negalima prisijungti prie nurodyto serverio.",
  "connect_offline_server_l1": "Namas susietas, bet neprisijungęs",
  "connect_offline_server_l2": "Bandyti jungtis vis vien?",
  "connect_server": "Prisijungti prie serverio",
  "consumption": "Vartojimas",
  "contact_support": "Susisiekite su techninio palaikymo servisu.",
  "continue_anyway": "Tęsti vis tiek",
  "continue_without_basalte_account": "Tęsti be Basalte paskyros",
  "cover_art_background": "Albumo viršelis",
  "current_password": "Dabar naudojamas slaptažodis",
  "current_temperature": "Esamas",
  "custom": "\"Asmeninis\" režimas",
  "date": "Data",
  "day": "Diena",
  "day_friday": "Penktadienis",
  "day_monday": "Pirmadienis",
  "day_saturday": "Šeštadienis",
  "day_short2_friday": "Pn",
  "day_short2_monday": "Pr",
  "day_short2_saturday": "Še",
  "day_short2_sunday": "Se",
  "day_short2_thursday": "Ke",
  "day_short2_tuesday": "An",
  "day_short2_wednesday": "Tr",
  "day_sunday": "Sekmadienis",
  "day_thursday": "Ketvirtadienis",
  "day_tuesday": "Antradienis",
  "day_wednesday": "Trečiadienis",
  "debug": "Derinti",
  "deezer": "Deezer",
  "deezer_disconnect_txt": "Jūs ketinate atjungti Deezer paskyrą, nuo šio įrenginio.",
  "deezer_favourite": "Deezer mėgstamiausias",
  "deezer_flow": "Srautas",
  "deezer_flow_description": "Jūsų garso takelis",
  "deezer_flow_full": "Deezer srautas",
  "deezer_no_link": "Kol kas nėra susietos Deezer paskyros",
  "deezer_oauth_error": "Deezer atpažinimo klaida",
  "default_rooms": "Numatyti kambariai",
  "default_rooms_hint": "Norint, kad namų transliavimas vyktu tinkamai, įsitikinkit, kad nors 1 kambarys yra nustatytas numatytuoju.",
  "default_rooms_info_l1": "Patalpos pagal nutylėjimą skirtos trečiųjų šalių garso srautams (pavyzdžiui Spotify)",
  "default_rooms_info_l2": "Nei vienas kambarys neprijungtas prie transliacijos. Aktyvuokite pasirinktus kambarius.",
  "default_rooms_spotify_hint": "Kad Spotify Connect veiktų tinkamai, nustatykite nors vieną kambarį kaip numatytąjį.",
  "default_rooms_spotify_warning_l1": "Parinkite bent vieną patalpą kiekvienam garso srautui, kurį gros pagal nutylėjimą",
  "default_rooms_spotify_warning_l2": "užbaikite konfigūravimąjos pabaigimui",
  "default_rooms_spotify_warning_title": "Spotify Connect platforma dabar palaikoma",
  "default_view": "Numatytas vaizdas",
  "delay": "Vėlavimas",
  "delay_seconds_b": "Vėlavimas (sekundės)",
  "delete": "Ištrinti",
  "delete_account": "Naikinti paskyrą",
  "demo_enter": "Demonstracinis režimas",
  "demo_functionality_warning_txt": "Ši funkcija demonstracinėje versijoje nepalaikoma.",
  "demo_location": "Gentas",
  "demo_server_name": "Demo namas",
  "demo_title": "Demo",
  "demo_warning_txt": "Jūs norite įjungti demonstracinę versiją.",
  "detail": "detaliau",
  "device": "Prietaisas",
  "devices": "Prietaisai",
  "device_settings": "Prietaiso nustatymai",
  "device_volume": "Prietaiso garsumas",
  "dhcp": "DHCP",
  "direction_east": "Rytai",
  "direction_east-northeast": "Rytai-šiaurės rytai",
  "direction_east-southeast": "Rytai-pietryčiai",
  "direction_north": "Šiaurė",
  "direction_northeast": "Šiaurės rytai",
  "direction_north-northeast": "Šiaurė-šiaurės rytai",
  "direction_north-northwest": "Šiaurė-šiaurės vakarai",
  "direction_northwest": "Šiaurės vakarai",
  "direction_short_east": "R",
  "direction_short_east-northeast": "RŠR",
  "direction_short_east-southeast": "RPR",
  "direction_short_north": "Š",
  "direction_short_northeast": "ŠR",
  "direction_short_north-northeast": "ŠŠR",
  "direction_short_north-northwest": "ŠŠV",
  "direction_short_northwest": "ŠV",
  "direction_short_south": "P",
  "direction_short_southeast": "PR",
  "direction_short_south-southeast": "PPR",
  "direction_short_south-southwest": "PPV",
  "direction_short_southwest": "PV",
  "direction_short_west": "V",
  "direction_short_west-northwest": "VŠV",
  "direction_short_west-southwest": "VPV",
  "direction_south": "Pietūs",
  "direction_southeast": "Pietryčiai",
  "direction_south-southeast": "Pietūs-pietryčiai",
  "direction_south-southwest": "Pietūs-pietvakariai",
  "direction_southwest": "Pietvakariai",
  "direction_west": "Vakarai",
  "direction_west-northwest": "Vakarai-šiaurės vakarai",
  "direction_west-southwest": "Vakarai-pietvakariai",
  "disband_group": "Išformuoti grupę",
  "dns": "DNS",
  "domain": "Domenas",
  "done": "Atlikta",
  "do_not_disturb": "Netrukdyti",
  "do_not_disturb_off_l1": "Visi garso pranešimai šiuo metu įjungti.",
  "do_not_disturb_on_l1": "Visi garso pranešimai šiuo metu yra išjungti.",
  "do_not_show_again": "Daugiau neberodyti",
  "door": "Durys",
  "door_phone": "Telefonspynė",
  "doors": "Durys",
  "down": "Žemyn",
  "downloading": "siunčiama",
  "downloading_updates": "Siunčiami atnaujinimai",
  "do_you_want_to_continue": "Ar norite tęsti?",
  "drag_and_drop_images": "Pasirinkite paveiksliuką arba nutempkite jį čia",
  "drag_and_drop_lights": "Vilkite ir nuleiskite žibintus čia",
  "drag_to_reorder": "Nutempkite norint pertvarkyt",
  "dsp": "DSP",
  "dsp_configuration": "DSP konfigūracija",
  "dsp_profile_club": "Klubas",
  "dsp_profile_custom": "Pasirinktinis",
  "dsp_profile_flat": "Plokšti",
  "dsp_profile_jazz": "Džiazas",
  "dsp_profile_live": "Gyva",
  "dsp_profile_pop": "Pop",
  "dsp_profile_rock": "Roko",
  "dsp_shortcut_l1": "Jūs norite atidaryti DSP nustatymus.",
  "echo_cancellation": "Aido panaikinimas",
  "echo_limit": "Aido limitas",
  "edit": "Redaguoti",
  "edit_dns": "Redaguoti DNS",
  "edit_gateway": "Redaguoti tinklų sąsają",
  "edit_ip_address": "Pakeisti IP adresą",
  "edit_subnet_mask": "Pakeisti potinklio kaukę",
  "ellie_room": "Ellie kambarys",
  "ellie_system_update": "Ellie sistemos atnaujinimas",
  "ellie_system_update_l1": "Reikia perkrauti Ellie norint įrašyti sistemos atnaujinimą.",
  "ellie_update": "Ellie atnaujinimas",
  "email": "Elektroninio pašto adresas",
  "empty": "tuščia",
  "empty_queue": "Sąrašas tuščias",
  "empty_queue_txt_l1": "Atrodo, kad neturite dainų savo sąraše",
  "empty_queue_txt_l2": "Pridėkit dainų į grojaraštį.",
  "empty_song": "Atkūrimo prietaisas sustabdytas.",
  "empty_song_txt_l1": "Dainų sąrašas baigėsi.",
  "empty_song_txt_l2": "Norint pradėti iš naujo, paspauskit pradėti.",
  "empty_src": "Nepasirinktas garso transliavimo šaltinis",
  "empty_src_txt_l1": "Nepasirinkote garso šaltinio",
  "empty_src_txt_l2": "Pirmiausia pasirinkite patalpą.",
  "empty_zone": "Neparinkstas nei vienas kambarys",
  "empty_zone_txt_l1": "Nepasirinkote patalpos.",
  "empty_zone_txt_l2": "Pirmiausia pasirinkite patalpą",
  "energy": "Energijos sąnaudos",
  "energy_meter": "Skaitiklis",
  "enter_keycode": " Įveskite rakto kodą",
  "eq": "EQ",
  "error": "Klaida",
  "external": "Išorinis",
  "extra_permissions_required": "Kai kurioms funkcijoms gali reikėti papildomų prietaiso teisų.",
  "ext_src": "Išorinis garso šaltinis",
  "fahrenheit": "Farenheitai",
  "failed": "Nepavyko",
  "fan": "Ventiliatorius",
  "fan_auto": "Auto",
  "fan_high": "Aukštas",
  "fan_low": "Tylusis režimas",
  "fan_medium": "Vidutinis",
  "fan_off": "Išjungtas",
  "favourite": "Mėgstamiausias",
  "favourites": "Mėgstamiausi",
  "favourite_scenes_full": "Nebegalima išsaugoti mėgstamų scenų",
  "favourite_scenes_full_sub": "Vienoje patalpoje galite išsaugoti 4 savo mėgstamas scenas",
  "favourite_songs": "Mėgstamiausios dainos",
  "featured_playlists": "Rodomi grojaraščiai",
  "floor_attic": "Palėpė",
  "floor_basement": "Rūsys",
  "floor_floor": "Aukštas",
  "floor_garden": "Sodas",
  "floor_heating": "Grindų šildymas",
  "floor_loft": "Loftai",
  "floor_lower": "Apatinis aukštas",
  "floor_lvl_0": "Pirmas aukštas",
  "floor_lvl_1": "Antras aukštas",
  "floor_lvl_2": "Trečias aukštas",
  "floor_lvl_3": "Ketvirtas aukštas",
  "floor_lvl_4": "Penktas aukštas",
  "floor_lvl_5": "Šeštas aukštas",
  "floor_lvl_6": "Septintas aukštas",
  "floor_lvl_7": "Aštuntas aukštas",
  "floor_lvl_8": "Devintas aukštas",
  "floor_lvl_9": "Dešimtas aukštas",
  "floor_lvl_10": "Vienuoliktas aukštas",
  "floor_lvl_11": "Dvyliktas aukštas",
  "floor_lvl_12": "Tryliktas aukštas",
  "floor_lvl_13": "Keturioliktas aukštas",
  "floor_lvl_14": "Penkioliktas aukštas",
  "floor_lvl_15": "Šešioliktas aukštas",
  "floor_lvl_16": "Septynioliktas aukštas",
  "floor_lvl_17": "Aštuntas aukštas",
  "floor_lvl_18": "Devynioliktas aukštas",
  "floor_lvl_19": "Dvidešimtas aukštas",
  "floor_lvl_20": "Dvidešimt pirmas aukštas",
  "floor_upper": "Viršutinis aukštas",
  "forgot_password": "Pamiršau slaptažodį",
  "frequency": "Dažnis",
  "full_screen": "Viso ekrano režimas",
  "functions": "Funkcijos",
  "gas": "Dujos",
  "gateway": "Sąsaja",
  "general": "Bendrieji nustatymai",
  "generic_devices": "Prietaisai",
  "generic_devices_appliances": "Prietaisai",
  "generic_devices_artwork": "Meno kūrinys",
  "generic_devices_car_charger": "Automobilinis įkroviklis",
  "generic_devices_energy": "Energija",
  "generic_devices_fire_place": "Židinys",
  "generic_devices_fountain": "Fontanas",
  "generic_devices_garage": "Garažas",
  "generic_devices_garden": "Sodas",
  "generic_devices_irrigation": "Drėkinimas",
  "generic_devices_pool": "Baseinas",
  "generic_devices_security": "Saugumas",
  "generic_devices_socket": "Lizdas",
  "generic_devices_ventilation": "Ventiliacija",
  "generic_devices_wellness_spa": "Gerovė ir SPA",
  "genre": "Žanras",
  "genre_moods": "Žanras ir nuotaikos",
  "genres": "Žanrai",
  "go_to_login_l1": "Prisijungti arba atsijungti nuo Basalte paskyros",
  "go_to_manage_l1": "Valdyti Basalte paskyrą.",
  "group": "Grupė",
  "groups": "Grupės",
  "home": "Namų",
  "humidity": "Drėgmė",
  "hz": "Hz",
  "images": "Vaizdai",
  "image_smaller_than_5mb": "Vaizdo failo talpa negali būti didesnė negu 5MB",
  "in": "Į",
  "incorrect_album": "Albumas nerastas",
  "incorrect_artist": "Atlikėjas nerastas",
  "install": "Įdiegti",
  "installing": "Įdiegiama",
  "install_update": "Įdiegti atnaujinimą",
  "install_updates": "Įdiegti atnaujinimus",
  "integrator": "Integratorius",
  "integrator_access_allow": "Leisti nuotolinį konfigūravimą",
  "integrator_access_ask": "Paprašykite savininko įjungti nuotolinio konfigūravimo funkciją.",
  "integrator_access_disabled": "Nuotolinio konfigūravimo funkcija išjungta",
  "intercom": "Telefonspynė",
  "internal": "Vidinis",
  "invalid_address": "Klaidingas adresas",
  "invalid_code": "Neteisingas kodas",
  "invalid_code_l1": "Įvestas kodas neteisingas",
  "invalid_code_l2": "Įsitikinkite, kad kodas teisingas arba siųskite naują užklausą.",
  "invalid_input": "Klaidinga įvestis",
  "invalid_user": "Neteisingas vartotojas",
  "invalid_user_l1": "Įsitikinkite, kad elektroninio pašto adresas teisingas.",
  "ios_plist_string_NSCameraUsageDescription": "Kameros pagalba galima fotografuoti kambarius",
  "ios_plist_string_NSLocalNetworkUsageDescription": "Vietinio tinklo prieiga gali būti naudojama norint rasti serverius, IP kameras, ...",
  "ios_plist_string_NSPhotoLibraryAddUsageDescription": "Į nuotraukų biblioteką galima išsaugoti kambarių nuotraukas",
  "ios_plist_string_NSPhotoLibraryUsageDescription": "Iš nuotraukų bibliotekos galima parinkti nuotraukas kambariams",
  "ip": "IP",
  "ip_address": "IP adresas",
  "join": "+ Prijungti",
  "join_alt": "+ Prijungti",
  "keep_awake": "Palikti iPad įjungtą",
  "keep_awake_device": "Palikti prietaisą įjungtą",
  "kelvin": "Kelvinai",
  "Key": "lt_LT",
  "knx_presets": "Numatyti KNX nustatymai",
  "knx_presets_info": "Pakeiskite numatytuosius KNX nustatymus, kad paleistumėte kažką kito.",
  "language": "Kalba",
  "language_system": "Sistemos kalba",
  "last-7-days": "Paskutinės 7 dienos",
  "last_hour": "Paskutinė valanda",
  "last_room": "Paskiausia naudota patalpa",
  "last_view": "Paskutinis peržiūrėjimas",
  "last_week": "Paskutines 7 dienas",
  "learn_more": "Sužinoti daugiau",
  "leave_group": "Palikti grupę",
  "left": "Palikti",
  "lena_system_update": "Lena sistemos atnaujinimas",
  "lena_system_update_l1": "Reikia perkrauti Lena norint įrašyti sistemos atnaujinimą.",
  "library": "Biblioteka",
  "library_empty": "Biblioteka tuščia",
  "library_scanning": "Bibliotekos skenavimas",
  "light_accent_light": "Akcentinė šviesa",
  "light_art_light": "Meno šviesa",
  "light_cabinet": "Kabinetas",
  "light_ceiling_mounted_fixture": "Lubinis šviestuvas",
  "light_chandelier": "Sietynas",
  "light_desk": "Stalas",
  "light_down": "Žemyn",
  "light_floor_lamp": "Pastatomas šviestuvas",
  "light_lampshade": "Šviestuvas su gaubtu",
  "light_led": "LED šviesa",
  "light_led_strip": "LED juosta",
  "light_mirror": "Veidrodis",
  "light_mood_light": "Nuotaikingas apšvietimas",
  "light_pendant": "Pakabinamas šviestuvas",
  "light_reading_light": "Skaitymo šviesa",
  "lights": "Šviestuvai",
  "lights_off_all": "Išjungti visas šviesas",
  "light_spot": "Akcentinis šviestuvas",
  "light_table_lamp": "Stalo lempa",
  "light_track": "Bėgelis",
  "light_via": "Naktinis apšvietimas",
  "light_wall_mounted_fixture": "Sieninis šviestuvas",
  "link": "Nuoroda",
  "link_deezer_info_l1": "Susieti Deezer paskyrą su transliavimu.",
  "link_deezer_info_l2": "Ši funkcija leis naršyti ir leisti Deezer medijos bylas transliacijos sraute.",
  "linked_homes": "Susieti namai",
  "linked_projects": "Susieti projektai",
  "linked_with": "Susieta su",
  "link_home": "Atidarykite Basalte programėlę savo įrenginyje ir nustatymuose susiekite namą.",
  "link_server": "Susieti su Basalte",
  "link_server_full_sub": "Ar norite susieti projektą su Basalte paskyra?",
  "link_spotify_info_l1": "Susieti Spotify paskyrą su transliavimo srautu.",
  "link_spotify_info_l2": "Ši funkcija leis naršyti ir leisti Spotify medijos bylas transliacijos sraute.",
  "link_tidal_info_l1": "Susiekite TIDAL paskyrą su transliacija.",
  "link_tidal_info_l2": "Ši funkcija leis naršyti ir leisti \"TIDAL\" medijos bylas transliacijos sraute.",
  "lisa_order_hint": "Keisti puslapių ir pavadinimų išdėstymą",
  "lisa_system_update": "Lisa sistemos atnaujinimas",
  "lisa_system_update_l1": "Reikia perkrauti Lisa norint įrašyti sistemos atnaujinimą.",
  "listen_in": "Klausytis per",
  "liters": "Litrai",
  "live_adjustments": "Greiti nustatymai",
  "loading": "Kraunama...",
  "local": "vietinis",
  "location": "Vieta",
  "location_back": "Atgal",
  "location_bottom": "Apatinė",
  "location_center": "Centrinė",
  "location_chair": "Kėdė",
  "location_corner": "Kampas",
  "location_desk": "Stalas",
  "location_east": "Rytai",
  "location_front": "Priekis",
  "location_high": "Aukšta",
  "location_island": "Sala",
  "location_left": "Kairė",
  "location_low": "Žema",
  "location_mid": "Vidurys",
  "location_niche": "Niša",
  "location_north": "Šiaurinė",
  "location_right": "Dešinė",
  "location_shelves": "Lentynos",
  "location_south": "Pietinė",
  "location_stove": "Viryklė",
  "location_table": "Stalas",
  "location_top": "Viršus",
  "location_west": "Vakarinė",
  "logged_in_as": "Prisijungta kaip",
  "log_out": "Atsijungti",
  "log_out_everywhere": "Atsijungti visur",
  "long-press-save-scene": "Scenos atnaujinimui naudokite ilgą paspaudimą",
  "louver_30": "30 laipsnių",
  "louver_45": "45 laipsnius",
  "louver_60": "60 laipsnių",
  "louver_horizontal": "Horizontalus kreipimas",
  "louver_mode": "Žaliuzės režimas",
  "louver_off": "Išjungti",
  "louver_swing": "Kreipti",
  "louver_vertical": "Vertikalus kreipimas",
  "mac_address": "MAC adresas",
  "manage": "Tvarkyti",
  "manage_account": "Valdyti paskyrą",
  "manage_users": "Tvarkyti naudotojus",
  "manual_conn": "Rankinis prijungimas",
  "manual_text": "Prašome įvesti norimo serverio adresą.",
  "max": "Maks",
  "menu": "Meniu",
  "mf_device": "{count, plural, =0 {Prietaisas} =1 {Prietaisas} other {Prietaisai}}",
  "mf_feels_like": "Jutiminė {value}",
  "mf_minute": "{count} {count, plural, =1 {minutė} other {minutės}}",
  "mf_powered_by": "Palaikymą teikia {value}",
  "mf_second": "{count} {count, plural, =1 {sekundė} other {sekundės}}",
  "mf_server": "{count, plural, =1 {Serveris} other {Serveriai}}",
  "mf_song": "{count} {count, plural, =1 {daina} other {dainos}}",
  "mf_source": "Šaltinis: {value}",
  "microphone_gain": "Mikrofono stiprinimas",
  "min": "Min",
  "mix": "Maišyti",
  "mixed_sources": "Keli šaltiniai",
  "mixed_sources_txt_l1": "Šioje grupėje yra kambarių su keliais šaltiniais.",
  "mixed_sources_txt_l2": "Pasirinkite šaltinį",
  "mixes": "Atsitiktiniai kūriniai",
  "mixes_small": "atsitiktiniai kūriniai",
  "mode": "Režimas",
  "mode_auto": "Auto",
  "mode_cooling": "Vėsinimas",
  "mode_drying": "Džiovinimas",
  "mode_fan_only": "Tik ventiliatorius",
  "mode_heating": "Šildymas",
  "mode_off": "Išjungti",
  "month": "Mėnuo",
  "month_april": "Balandis",
  "month_august": "Rugpjūtis",
  "month_december": "Gruodis",
  "month_february": "Vasaris",
  "month_january": "Sausis",
  "month_july": "Liepa",
  "month_june": "Birželis",
  "month_march": "Kovas",
  "month_may": "Gegužė",
  "month_november": "Lapkritis",
  "month_october": "Spalis",
  "month_september": "Rugsėjis",
  "month_short_april": "Bal",
  "month_short_august": "Rugp",
  "month_short_december": "Gr",
  "month_short_february": "Vas",
  "month_short_january": "Saus",
  "month_short_july": "Liepa",
  "month_short_june": "Birz",
  "month_short_march": "Kovas",
  "month_short_may": "Geg",
  "month_short_november": "Lapkr",
  "month_short_october": "Spalis",
  "month_short_september": "Rugs",
  "mood": "Nuotaika",
  "moods": "Nuotaikos",
  "music": "Muzika",
  "music_choice": "Muzikos pasirinkimas",
  "music_go_to": "Muzika",
  "music_is_active": "Muzika aktyvuota",
  "music_is_playing": "Muzika groja",
  "music_off": "išjungti muziką",
  "music_off_all": "Išjungti visus kambarius",
  "music_off_all_rooms": "Išjungti muziką, visi kambariai",
  "music_off_current": "Išjungti muziką esamam kambaryje",
  "music_off_current_room": "Išjungti muziką, esamas kambarys",
  "music_off_txt_l1": "Išjungsite vieną arba visus kambarius.",
  "music_off_txt_l2": "Ar tikrai norite tęsti?",
  "music_on_current": "Įjungti esamą kambarį",
  "music_server": "Muzikos serveris",
  "mute_area_on_call": "Nutildyti muziką skambučio metu",
  "my_favorites": "Mano mėgstamiausi",
  "my_music": "Mano muzika",
  "name": "Vardas",
  "nds": "Ieškoma serverių",
  "nds_music": "Ieškoma muzikos serverių",
  "nds_none": "Serverių nerasta!",
  "nds_none_music": "Nerasta muzikos serverių!",
  "nds_text_l1": "Tai gali užtrukti kelias sekundes...",
  "nds_text_l2": "Įsitikinkite, kad jūsų prietaisas prijungtas prie namų Wi-Fi.",
  "network": "Tinklas",
  "network_input_invalid": "Visi laukai turi būti tinkamai užpildyti",
  "never": "Niekada",
  "new": "Naujas",
  "new_alarm": "Naujas žadintuvas",
  "new_image": "Naujas vaizdas",
  "new_password": "Naujas slaptažodis",
  "new_password_confirmation": "Įveskite naują slaptažodį dar kartą",
  "new_playlist": "naujas grojaraštis...",
  "new_releases": "Nauji leidimai",
  "new_releases_small": "nauji leidimai",
  "new_scene": "Naujas scenarijus",
  "new_schedule": "Naujas grafikas",
  "new_step": "Kitas žingsnis",
  "next": "Kitas",
  "next_song": "kita daina",
  "no": "Ne",
  "no_active_rooms": "Nėra aktyvių kambarių",
  "no_all_off": "Ne, neišjunkite",
  "no_av": "Nėra AV įvesties",
  "no_configuration": "Konfigūracija nepasiekiama",
  "no_content_available": "Nėra prieinamo turinio",
  "no_data": "Duomenų nėra",
  "no_deezer_dis": "Ne, neatsijungti nuo Deezer",
  "no_devices_capabilities": "Nėra reguliuojamų prietaisų",
  "no_doors_available": "Durys nepasiekiamos",
  "no_favourites": "Nėra mėgstamiausių",
  "no_keep_it": "Ne, palikti",
  "no_lights_capabilities": "Nėra reguliuojamų šviestuvų",
  "no_linked_homes": "Nerasta susietų namų.",
  "no_more_favourites": "Daugiau mėgstamiausiųjų nepridėti",
  "none": "Nei vienas",
  "no_network": "Nėra prisijungimo prie tinklo",
  "no_preview_available": "Peržiūra negalima",
  "no_projects": "Projektų nerasta",
  "no_rooms": "Nėra patalpų",
  "no_rooms_available": "Nėra pasiekiamų patalpų",
  "no_room_selected": "nepasirinkta jokia patalpa",
  "no_rooms_listening": "Šaltinis nenaudojamas",
  "no_scene": "Nėra scenos",
  "no_scenes_favourites": "Nėra mėgstamiausių scenų",
  "no_scenes_txt": "Pasirinkite scenas kurias rodytų čia.",
  "no_search_results": "Nerasta paieškos rezultatų",
  "no_servers": "Serverių nerasta",
  "no_shades_capabilities": "Nėra reguliuojamų užuolaidų",
  "no_source": "- išjungti -",
  "no_sources_available": "Nėra prieinamų šaltinių",
  "no_steps": "Ši scena neturi žingsnių",
  "not_allowed": "Neleistina",
  "not_allowed_l1": "Jūs neturite leidimo tai daryti",
  "notification": "Pranešimas",
  "notification_permission_missing": "Basalte Home pranešimų siuntimas išjungtas. Funkciją įjungti galite įrenginio sistemos nustatymuose.",
  "notifications": "Pranešimus",
  "not_linked": "Nesujungta",
  "not_now": "Ne dabar",
  "not_set": "Nenustatyta",
  "no_users_l1": "Nerasta naudotojų.",
  "no_users_l2": "Pasirinkite serverį su naudotojais",
  "now_playing": "Dabar groja",
  "number_of_songs": "Daina(-os)",
  "off": "Išjungti",
  "offline_server": "Serveris atsijungęs",
  "offset_minutes": "Perstūmimas (minutėmis)",
  "ok": "OK",
  "on": "įjungti",
  "onix_black": "Juoda",
  "online": "Internetas",
  "only_png_jpeg_supported": "Palaikomi tik PNG ir JPEG vaizdai",
  "open": "Atidaryti",
  "open_close_door": "Durys",
  "open_close_gate": "Vartai",
  "open_close_window": "Langas",
  "open_settings": "Atidaryti parametrus",
  "open_spotify": "Atidaryti Spotify",
  "open_spotify_l1": "Jūs ketinate atidaryti Spotify",
  "open_state": "Atidaryti",
  "or": "Arba",
  "order_cameras_by_holding_and_draging": "Nutempkite kameras norima tvarka",
  "other": "Kita",
  "out": "Iš",
  "page_order": "Puslapio/dalies išdėstymas",
  "pair": "Poruoti",
  "password": "Slaptažodis",
  "password_changed": "Slaptažodis pakeistas",
  "password_confirmation": "Įveskite slaptažodį dar kartą",
  "password_requirements": "Slaptažodis turi būti sudarytas bent iš 8 simbolių įskaitant bent vieną didžiąją ir mažąją raides bei vieną skaitmenį.",
  "permission_denied": "Leidimas nesuteiktas",
  "permission_denied_go_to_settings_l1": "Šitai funkcijai veikti reikia leidimo(-ų).",
  "permission_denied_go_to_settings_l2": "Keiskite šios programos leidimus(-us) įrenginio nustatymuose, kad įgalintumėte visas funkcijas.",
  "photo_from_camera": "Nufotografuokit",
  "photo_from_library": "Pasirinkit atvaizdą iš bibliotekos",
  "pick_room_function": "Pasirinkit kambarį ir funkciją",
  "play": "Groti",
  "playback_gain": "Atkūrimo stiptinimas",
  "playing_in": "grojama",
  "playlist": "Grojaraštis",
  "playlists": "Grojaraščiai",
  "playlists_itunes": "iTunes grojaraščiai",
  "playlists_l": "grojaraščiai",
  "playlists_local": "Vietiniai grojaraščiai",
  "playlists_shared": "Bendri grojaraščiai",
  "play_next": "Leisti kitą",
  "play_now": "Leisti dabar",
  "please_fill_in_keycode": "Prašome įvesti rakto kodą",
  "please_try_a_different_image": "Bandykite įkelti kitokį vaizdą",
  "please_try_again": "Prašau, bandyti iš naujo",
  "popular": "Populiarūs",
  "position": "Pozicija",
  "precipitation": "Krituliai",
  "previous": "Ankstesnis",
  "problems_continue_integrator": "Jei problema kartojasi, susisiekite su sistemos diegėjais",
  "project": "Projektas",
  "project_image": "Projekto vaizdas",
  "projects_network_error": "Nepavyko įkelti projektų. Tinklo klaida.",
  "proximity_activation": "Priartėjimo aktyvavimas",
  "proximity_far": "Iš toli",
  "proximity_medium": "Vidutiniškai",
  "proximity_near": "Arti",
  "queue": "Eilė",
  "radio": "Radijas",
  "radio_error": "Įvyko klaida užkraunant radijo stotis",
  "radio_stations": "Radijo stotys",
  "radio_stations_other": "Kitos stotys",
  "recently_played": "Neseniai grotos",
  "related_artists": "Susiję atlikėjai",
  "reload": "Perkrauti",
  "remember_me": "Įsiminti mane",
  "remote": "Pultelis",
  "remote_ellie_intercom_outdated": "Nutolusio Ellie valdiklio programinė įranga pasenusi",
  "remote_ellie_intercom_outdated_l1": "Ellie valdikliui, į kurį bandote skambinti, reikalingas programinis atnaujinimas.",
  "remote_ellie_intercom_outdated_l2": "Po atnaujinimo bandykite dar kartą.",
  "remove": "Pašalinti",
  "remove_alarm": "Pašalinti žadintuvą",
  "remove_basalte_favourite": "Pašalinti Basalte mėgstamiausią",
  "remove_deezer_favourite": "Pašalinti Deezer mėgstamiausią",
  "remove_from_fav": "pašalinti iš mėgstamų",
  "remove_schedule": "Pašalinti grafiką",
  "remove_spotify_favourite": "Pašalinti Spotify mėgstamiausią",
  "remove_spotify_preset": "Pašalinti Spotify išankstinius nustatymus",
  "remove_tidal_favourite": "Pašalinti TIDAL mėgstamiausią",
  "remove_txt_l1": "Jūs ketinate pašalinti šį elementą.",
  "remove_txt_l2": "Ar tikrai norite tęsti?",
  "rename": "pervadinti",
  "repeat": "Pakartoti",
  "replace_queue": "Pakeisti eilę",
  "resend_code": "Siųsti kodą dar kartą",
  "resend_confirmation_code": "Siųsti patvirtinimo kodą dar kartą",
  "reset_image": "Atstatyti paveiksliuką",
  "reset_password": "Atstatyti slaptažodį",
  "reset_password_l1": "Ar tikrai norite atstatyti slaptažodį?",
  "restart": "Perkrauti",
  "restart_app": "Paleisti programą iš naujo",
  "restart_app_q": "Paleisti programą iš naujo?",
  "restarting_server": "Serveris perkraunamas",
  "restart_music_server": "Perkrauti muzikos serverį",
  "restart_music_server_txt_l1": "Jūs ketinate perkrauti muzikos serverį.",
  "restart_server": "Serverio pekrovimas",
  "restart_server_txt_l1": "Jūs ketinate perkrauti serverį.",
  "restart_server_txt_l2": "Programa vėl prisijungs automatiškai.",
  "retrieving": "Gaunama...",
  "retrieving_users_stop_text": "Nepavyko rasti naudotojų, pakeiskit serverį?",
  "retrieving_users_text": "Kraunami vartotojai...",
  "right": "Dešinė",
  "room": "Kambarys",
  "room_attic": "Palėpė",
  "room_ball_room": "Šokių salė",
  "room_bar": "Baras",
  "room_basement": "Rūsys",
  "room_bathroom": "Vonia",
  "room_bedroom": "Miegamasis",
  "room_corridor": "Koridorius",
  "room_dining_room": "Valgomasis",
  "room_dressing_room": "Persirengimo kambarys",
  "room_driveway": "Privažiavimas",
  "room_entry": "Įėjimas",
  "room_family_room": "Šeimos kambarys",
  "room_foyer": "Fojė",
  "room_garage": "Garažas",
  "room_garden": "Sodas",
  "room_guest_room": "Svečių kambarys",
  "room_gym": "Sporto salė",
  "room_hallway": "Holas",
  "room_hobby_room": "Hobio kambarys",
  "room_home_cinema": "Namų kinas",
  "room_home_office": "Namų biuras",
  "room_images": "Kambario nuotraukos",
  "room_intercom_lost": "Nutrūko ryšys",
  "room_intercom_lost_sub": "Prarastas skambučio ryšys, bandykite per naujo",
  "room_intercom_unavailable": "Kambarys nepasiekiamas",
  "room_intercom_unavailable_sub": "Šis kambarys šiuo metu negalimas",
  "room_intercom_unreachable": "Kambarys nepasiekiamas",
  "room_intercom_unreachable_sub": "Kambarys šiuo metu nepasiekiamas",
  "room_kitchen": "Virtuvė",
  "room_laundry_room": "Skalbykla",
  "room_library": "Biblioteka",
  "room_living_room": "Svetainė",
  "room_master_bedroom": "Miegamasis",
  "room_media_room": "Muzikos klausymo kambarys",
  "room_meeting_room": "Susitikimų kambarys",
  "room_nook": "Ramybės kampelis",
  "room_nursery": "Vaikų kambarys",
  "room_pantry": "Sandėliukas",
  "room_parking_place": "Parkavimo aikštelė",
  "room_patio": "Veranda",
  "room_pool": "Baseinas",
  "room_pool_house": "Baseino patalpa",
  "room_rack": "Valdymo įranga",
  "room_room": "Kambarys",
  "rooms": "Kambariai",
  "room_scene_no_functions": "Šis kambarys neturi funkcijų",
  "room_settings": "Kambario nustatymai",
  "room_spa": "Spa",
  "room_stable": "Pastovus kambarys",
  "room_staircase": "Laiptinė",
  "room_terrace": "Terasa",
  "room_toilet": "Tualetas",
  "room_utility_room": "Sandėlis",
  "room_wine_cellar": "Vyno rūsys",
  "room_workshop": "Dirbtuvės",
  "rotation": "Pakartojimas",
  "save": "Išsaugoti",
  "save_as": "Išsaugoti kaip",
  "save_basalte_favourite": "Išsaugoti Basalte mėgstamą",
  "save_changes": "Išsaugoti pakeitimus",
  "save_changes_l1": "Kai kurie pakeitimai nebuvo išsaugoti",
  "save_changes_l2": "Ar norite juos išsaugoti dabar?",
  "save_deezer_favourite": "Išsaugoti Deezer mėgstamą",
  "save_spotify_favourite": "Išsaugoti Spotify mėgstamą",
  "save_spotify_preset": "Išsaugoti Spotify tipinius nustatymus",
  "save_tidal_favourite": "Išsaugoti TIDAL mėgstamą",
  "scene": "Scenarijus",
  "scene_activated": "Suaktyvinta scena",
  "scene_away": "Pasišalinęs",
  "scene_cooking": "Virimas",
  "scene_dinner": "Pietūs",
  "scene_error": "Scenos klaida",
  "scene_evening": "Vakaras",
  "scene_good_morning": "Labas rytas",
  "scene_good_night": "Labos nakties",
  "scene_guests": "Svečiai",
  "scene_home": "Namas",
  "scene_image_error": "Scenos vaizdo klaida",
  "scene_movie": "Filmas",
  "scene_not_available": "Nėra aktyvių scenų.",
  "scene_not_selected_l1": "Nėra pasirinktos scenos.",
  "scene_not_selected_l2": "Prašau, pasirinkite sceną.",
  "scene_party": "Vakarėlis",
  "scene_relax": "Poilsis",
  "scenes": "Scenarijai",
  "scene_select_or_new": "Prašau, pasirinkite arba sukurkite naują sceną.",
  "scene_vacation": "Atostogos",
  "scene_welcome": "Sveiki",
  "scene_workout": "Treniruotė",
  "schedule": "Laikmatis",
  "schedule_at": "Tvarkaraštis",
  "schedule_not_selected_l1": "Nepasirinktas tvarkaraštis",
  "schedule_not_selected_l2": "Prašome pasirinkti tvarkaraštį.",
  "scheduler": "Planuotojas",
  "schedules": "Grafikai",
  "schedule_select_or_new": "Pasirinkite arba sukurkite naują grafiką.",
  "screen_orientation": "Ekrano orientacija",
  "search": "Ieškoti",
  "search_again": "Ieškoti vėl",
  "search_ph": "Ieškoti atlikėjo, dainos arba albumo...",
  "search_result": "Ieškoti rezultato dėl",
  "security": "Apsauga",
  "select_admin": "Norėdami tęsti atnaujinimo procesą, pasirinkite administratoriaus profilį",
  "select_home": "Pasirinkite namą",
  "selection": "Pasirinkimas",
  "selection_all": "pridėti prie pasirinkimo",
  "select_photo": "Pasirinkti vaizdą",
  "select_server": "Pasirinkti serverį",
  "select_user": "Pasirinkite profilį prie kurio prisijungti",
  "serial_number": "Serijinis numeris",
  "server": "Serveris",
  "server_address": "Serverio adresas",
  "server_basalte_cloud_no_connection": "Serveris negali prisijungti prie Basalte Cloud",
  "server_exists_already": "Serveris jau egzistuoja!",
  "server_mac": "Serverio MAC adresas",
  "servers": "Serveriai",
  "server_status": "Serverio statusas",
  "server_update_info_l2": "prašau atnaujinkite Basalte serverį į naujausią versiją.",
  "server_update_required": "Reikalingas serverio atnaujinimas",
  "server_version": "Serverio versija",
  "set": "Nustatyti",
  "set_new_password": "Nustayti naują slaptažodį",
  "setpoint": "Nustatyta vertė",
  "settings": "Nustatymai",
  "shade_blind": "Užuolaidos",
  "shade_center": "Centras",
  "shade_curtain": "Užuolaida",
  "shade_left": "Kairė",
  "shade_pool_shutter": "Baseino uždangalas",
  "shade_right": "Dešinė",
  "shade_roman_blind": "Romanetės",
  "shades": "Žaliuzės",
  "shade_shade": "Žaliuzės",
  "shade_shutter": "Roletai",
  "shade_solar_protection": "Apsauga nuo saulės",
  "shade_venetian_blind": "Žaliuzės",
  "shade_vertical_blind": "Vertikalios žaliuzės",
  "share": "dalintis",
  "shared_playlist": "Pasidalintas grojaraštis",
  "show_album": "Parodyti albumą",
  "show_artist": "Parodyti atlikėją",
  "show_background_art": "Rodyti foną",
  "show_more": "Parodyti daugiau...",
  "show_status_bar": "Parodyti Ipad būsenos juostą",
  "show_status_bar_device": "Rodyti įrenginio juostą",
  "sign_in": "Prisijungti",
  "sign_in_info_tidal_l1": "Įveskite TIDAL prisijungimo įgaliojimus",
  "sign_up": "Registruotis",
  "single_room": "Kambarys",
  "sleep": "Miegojimo režimas",
  "software_update": "Programos atnaujinimas",
  "something_went_wrong": "Kažkas nutiko ne taip",
  "songs": "Dainos",
  "sonos": "Sonos",
  "sonos_unreachable": "Sonos neaptikta",
  "sonos_unreachable_txt_l1": "Patikrinkite ar jūsų įrenginys yra prijungtas.",
  "sound_settings": "Garso nustatymai",
  "source": "Šaltinis",
  "sources": "Šaltiniai",
  "spotify": "Spotify",
  "spotify_beta": "Spotify (beta)",
  "spotify_connect": "Spotify Connect",
  "spotify_connect_instructions": "Spotify Connect",
  "spotify_connect_instructions_1": "Įsitikinkite, kad Core serverį su Spotify leidžiantis prietaisas yra tame pačiame tinkle",
  "spotify_connect_instructions_2": "Pasirinkite Core serverį prietaisą grojimui Spotify programėlėje.",
  "spotify_connect_instructions_body": "Klausykite per garsiakalbius ar televizorių naudodami Spotify aplikaciją kaip nuotolinio valdymo pultą.",
  "spotify_connect_instructions_title": "Pasiruošę leisti muziką?",
  "spotify_disconnect_txt": "Jūs ketinate atjungti Spotify paskyrą nuo šio grotuvo.",
  "spotify_free_user_txt": "Reikalinga Spotify Premium prenumerata",
  "spotify_no_link": "Dar nėra prijungta Spotify paskyra",
  "spotify_oauth_error": "Spotify prisijungimo klaida",
  "spotify_preset_error": "Spotify klaida",
  "spotify_preset_error_l1": "Nepavyko užkrauti Spotify iš ankstinių nustatymų.",
  "spotify_preset_error_l2": "Įsitikinkite, kad Spotify paskyra buvo galiojanti su šia transliacija anksčiau",
  "spotify_presets": "Spotify nustatymai",
  "start": "Pradžia",
  "start_page": "Pradinis puslapis",
  "start_page_customisation": "Pradinio puslapio redagavimas",
  "start_time_date": "Data ir laikas",
  "startup_view": "Pradžios vaizdas",
  "startup_volume": "Numatytasis garsumo lygis",
  "start_weather": "Informacija apie orus",
  "state_active": "Aktyvus",
  "state_armed": "Suaktyvinta",
  "state_closed": "Uždaryta",
  "state_disabled": "Išjungta",
  "state_disarmed": "Išjungta",
  "state_down": "Žemyn",
  "state_enabled": "Įjungta",
  "state_false": "Klaida",
  "state_free": "Laisva",
  "state_high": "Aukštyn",
  "state_in": "Į vidų",
  "state_inactive": "Neaktyvu",
  "state_left": "Kairėn",
  "state_low": "Žemyn",
  "state_occupied": "Užimta",
  "state_off": "Išjungta",
  "state_on": "Įjungta",
  "state_opened": "Atidaryta",
  "state_out": "Išeiti",
  "state_right": "Dešinėn",
  "state_started": "Pradėta",
  "state_stopped": "Sustabdyta",
  "state_true": "Teisingai",
  "state_up": "Aukštyn",
  "static": "Statiškas",
  "stations": "Stotys",
  "status": "Statusas",
  "stop": "Stop",
  "stream": "Transliacija",
  "streaming": "Transliavimas",
  "streaming_services": "Transliavimo paslaugos",
  "streams": "Transliacijos",
  "streams_beta": "Transliacijos (beta)",
  "subnet_mask": "Potinklio kaukė",
  "sun": "Saulė",
  "sunrise": "Saulėtekis",
  "sunset": "Saulėlydis",
  "swipe_to_pan": "Braukite, norėdami pereiti",
  "switch_server": "Pakeisti serverį",
  "synchronise": "Skenuoti dabar",
  "system": "Pagal prietaisą",
  "system_setting": "Sisteminiai nustatymai",
  "temperature": "Temperatūra",
  "temperature_unit": "Temperatūros vienetas",
  "theme": "Tema",
  "thermostat": "Termostatas",
  "this_cannot_be_undone": "Šio veiksmo anuliuoti negalima.",
  "this_week": "Šią savaitę",
  "tidal": "TIDAL",
  "tidal_disconnect_txt": "Jūs ketinate atjungti TIDAL paskyrą nuo šio grotuvo.",
  "tidal_legacy_auth": "Būtinas TIDAL prisijungimas",
  "tidal_legacy_auth_l1": "Jei norite toliau naudoti TIDAL, prisijunkite dar kartą.",
  "tidal_no_link": "Dar nėra sujungtos TIDAL paskyros",
  "tidal_oauth_error": "TIDAL autentifikavimo klaida",
  "tidal_rising": "TIDAL Rising",
  "time": "Laikas",
  "time_format": "Laiko formatas",
  "timer": "Laikmatis",
  "timers": "Laikmačiai",
  "title": "Pavadinimas",
  "today": "Šiandien",
  "tomorrow": "Rytoj",
  "top_tracks": "Populiariausi kūriniai",
  "treble": "Aukšti dažniai",
  "try_again": "Bandykite iš naujo",
  "try_again_later": "Bandykite vėliau dar kartą",
  "try_again_later_or_contact_support": "Bandykite vėliau dar kartą arba kreipkitės į pagalbą",
  "turned_off": "Išjungta",
  "turned_on": "Įjungta",
  "turn_off": "Išjungti",
  "turn_on": "Įjungti",
  "turn_on_default_rooms": "Įjungti muzikos šaltinio priskirtą patalpą?",
  "turn_on_music": "Įjunkite muziką",
  "turn_on_tv": "Įjunkite televizorių",
  "tv": "TV",
  "tv_off": "Išjungti TV",
  "tv_off_current_room": "Išjungti TV, esama patalpa",
  "unavailable": "Nepasiekiamas",
  "unavailable_src": "Šaltinis nepasiekiamas",
  "unavailable_src_txt": "Dabartinis šaltinis nepasiekiamas",
  "ungroup": "Išgrupuoti",
  "unknown": "Nežinomas",
  "unknown_account": "Paskyra neegzistuoja",
  "unknown_account_l1": "Šis el. pašto adresas nesusietas su Basalte paskyra.",
  "unknown_av": "Nežinoma AV įvestis",
  "unknown_src": "Nežinomas šaltinis",
  "unknown_src_txt": "Esamas šaltinis šiam serveriui nežinomas",
  "unlink": "Atsieti",
  "unlink_server": "Atsieti nuo Basalte paskyros",
  "unlink_server_full_sub": "Atsieti šį projektą nuo Basalte paskyros? Jis nebebus pasiekiamas už jūsų tinklo ribų.",
  "unreachable": "Nepasiekiama",
  "unshare": "atšaukti bendrą priėjimą",
  "unsupported_browser": "Nepalaikoma naršyklė",
  "unsupported_browser_l1": "Kai kurios funkcijos gali veikti kitaip, nei tikėtasi.",
  "unsupported_browser_l2": "Geriau naudokite Chrome arba Safari naršyklę.",
  "unsupported_browser_live": "Ši naršyklė nepalaiko Basalte paskyros. Siūlome naudoti Chrome arba Safari naršykles.",
  "up": "Į viršų",
  "update": "Atnaujinti",
  "update_available": "Atnaujinti",
  "update_available_full": "Yra atnaujinimas",
  "update_info_l1": "Užtikrinti geriausią patirtį,",
  "update_library": "Nuskenuokit muzikos biblioteką iš naujo",
  "update_music_server": "Atnaujinti muzikos serverį",
  "update_scene": "Atnaujinti sceną",
  "update_scene_l1": "Išsaugoti dabartinę būseną prie šios scenos.",
  "update_server": "Atnaujinti serverį",
  "updating_server": "Atnaujinamas serveris",
  "updating_server_info": "Neišjunkite serverio",
  "up_to_date": "Naujausia",
  "username": "Vartotojo vardas",
  "uv": "Ultravioletiniai",
  "value_invalid": "Įvesta vertė neleistina",
  "verify": "Tikrinti",
  "verifying": "tikrinama",
  "version": "Versija",
  "video_background": "Vaizdo fonas",
  "video_go_to": "TV valdymas",
  "video_is_active": "TV pasiekiamas",
  "video_is_playing": "TV įjungtas",
  "video_unavailable": "TV arba garso grojimo sistema nepasiekiama",
  "view_details": "Peržiūrėkite išsamią informaciją",
  "volume": "Garsas",
  "warning": "Dėmesio",
  "water": "Vanduo",
  "weather": "Orai",
  "weather_daily_data_unavailable": "Kasdieniniai duomenys apie orus šiuo metu nepasiekiami",
  "weather_hourly_data_unavailable": "Kasvalandiniai duomenys apie orus šiuo metu nepasiekiami",
  "weather_information": "Informacija apie orus",
  "week": "Savaitė",
  "weekly": "Savaitinis",
  "what": "Kas",
  "what_music": "Kokia muzika",
  "when": "Kada",
  "where": "Kur",
  "who": "Kas",
  "wind": "Vėjas",
  "window": "Langas",
  "windows": "Langai",
  "wrong_password": "Neteisingas slaptažodis",
  "wrong_password_txt": "Slaptažodis kurį įvedėte neatitiko naudotojo slaptažodžio",
  "wrong_username_and_or_password": "Neteisingas prisijungimo vardas ir/arba slaptažodis",
  "year": "Metai",
  "yes": "Taip",
  "yes_all_off": "Taip, išjungti viską",
  "yes_cur_off": "Taip, išjungti einamąjį",
  "yes_deezer_dis": "Taip, atsijungti nuo Deezer paskyros",
  "yes_delete_it": "Taip, ištrinti",
  "yesterday": "Vakar",
  "zone": "Kambarys",
  "zones": "Kambariai"
}
