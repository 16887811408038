'use strict'

angular
  .module('basImageTransition')
  .constant('BAS_IMAGE', {
    C_BG_COVER: 'bas-image-background-cover',
    C_BG_CONTAIN: 'bas-image-background-contain',
    C_COLOR_FOREGROUND: 'bas-image-color-foreground',
    C_COLOR_HIGHLIGHT: 'bas-image-color-highlight',
    C_COLOR_MUTED: 'bas-image-color-muted',
    C_COLOR_LIGHT_CONTROL: 'bas-image-color-light-control',
    C_BOX_SHADOW: 'bas-image-box-shadow',
    C_DROP_SHADOW: 'bas-image-drop-shadow',
    C_BLUR_BRIGHTNESS_30: 'bas-image-blur-brightness-30',
    C_BRIGHTNESS_30: 'bas-image-brightness-30',
    C_BRIGHTNESS_50: 'bas-image-brightness-50',
    C_BRIGHTNESS_0: 'bas-image-brightness-0',
    C_BRIGHTNESS_75: 'bas-image-brightness-75',
    C_OPACITY_70: 'bas-image-opacity-70',
    C_SIZE_50: 'bas-image-size-50',
    C_SIZE_60: 'bas-image-size-60',
    C_SIZE_70: 'bas-image-size-70',
    C_SIZE_80: 'bas-image-size-80',
    C_SIZE_100: 'bas-image-size-100',
    C_TOP_40: 'bas-image-top-40',
    C_HEIGHT_90: 'bas-image-height-90',
    BC_MAIN: 'bas-image-transition',
    BC_NO_BOX_SHADOW: 'bas-image-trans-no-box-shadow',
    LOCAL_PREFIX: 'local:'
  })
