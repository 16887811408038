'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('timeInputModalCtrl', [
    '$scope',
    '$window',
    '$rootScope',
    'BAS_INTL',
    'BAS_SPLASH',
    'BasIntl',
    'close',
    'basModalConfig',
    timeInputModalCtrl
  ])

/**
 * @param $scope
 * @param $window
 * @param $rootScope
 * @param {BAS_INTL} BAS_INTL
 * @param {BAS_SPLASH} BAS_SPLASH
 * @param {BasIntl} BasIntl
 * @param close
 * @param {BasInputModalConfig} basInputModalConfig
 * @param {string} basInputModalConfig.title
 * @param {string} basInputModalConfig.time
 */
function timeInputModalCtrl (
  $scope,
  $window,
  $rootScope,
  BAS_INTL,
  BAS_SPLASH,
  BasIntl,
  close,
  basInputModalConfig
) {
  var modal = this

  var listeners = []
  var globalListeners = []

  var HOLD_INTERVAL_SLOW = 250
  var HOLD_INTERVAL_FAST = 175
  var HOLD_DELAY = 500
  var HOLD_FAST_DELAY_SECONDS = 5

  var _holdInterval = -1
  var _holdTimeout = -1

  var basIntlState = BasIntl.get()

  modal.basInputModalConfig = basInputModalConfig

  modal.title = basInputModalConfig.title
  modal.saveTextId = basInputModalConfig.saveTextId
  modal.timeFormat = basIntlState.timeFormat
  modal.uiHour = 0
  modal.BAS_INTL = BAS_INTL
  modal.basIntlState = basIntlState

  modal.ELEMENT_HOUR = 'hours'
  modal.ELEMENT_MINUTES = 'minutes'
  modal.ELEMENT_AMPM = 'ampm'

  modal.closeModal = closeModal
  modal.clickChangeValue = clickChangeValue
  modal.clearHold = clearHold
  modal.save = save

  init()

  function init () {

    syncTime()

    listeners.push($rootScope.$on(
      BAS_INTL.EVT_TIME_FORMAT_CHANGED,
      onTimeFormatChanged
    ))
    listeners.push($rootScope.$on(
      BAS_SPLASH.EVT_SPLASH_VISIBILITY_CHANGED,
      onSplashVisibility
    ))

    $scope.$on('$destroy', _onDestroy)
  }

  function syncTime () {

    var _time

    _time = basInputModalConfig.time

    modal.time = _time instanceof Date
      ? new Date(_time.getTime())
      : new Date()

    syncTimeUi()
  }

  function syncTimeUi () {

    var hours = modal.time.getHours()

    modal.uiMinutes = BasUtil.padLeft(modal.time.getMinutes(), 2)

    if (modal.timeFormat === BAS_INTL.TIME_FORMAT_12) {

      if (hours === 0) {
        // Midnight is 12 AM
        hours = 12
        modal.uiAMPM = basIntlState.uiAM
      } else if (hours < 12) {
        // Morning is AM
        modal.uiAMPM = basIntlState.uiAM
      } else if (hours === 12) {
        // Noon is PM
        modal.uiAMPM = basIntlState.uiPM
      } else {
        // Afternoon is PM
        hours = hours - 12
        modal.uiAMPM = basIntlState.uiPM
      }
    }

    modal.uiHour = BasUtil.padLeft(hours, 2)
  }

  function clickChangeValue (element, doIncrease, event) {

    var i

    if (event &&
      event.cancelable &&
      BasUtil.isFunction(event.preventDefault)) {

      event.preventDefault()
    }

    changeValue(element, doIncrease)

    _holdTimeout = setTimeout(onHold, HOLD_DELAY)

    setReleaseListeners()

    function onHold () {

      i = 0

      clearInterval(_holdInterval)
      _holdInterval = setInterval(onInterval, HOLD_INTERVAL_SLOW)
    }

    function onInterval () {

      // Don't support holding for PM/AM
      if (element === modal.ELEMENT_MINUTES || element === modal.ELEMENT_HOUR) {

        if (i++ === HOLD_FAST_DELAY_SECONDS) {

          clearInterval(_holdInterval)
          _holdInterval = setInterval(onInterval, HOLD_INTERVAL_FAST)
        }

        changeValue(element, doIncrease)
        $scope.$applyAsync()
      }
    }
  }

  function setReleaseListeners () {

    globalListeners.push(BasUtil.setDOMListener(
      $window.document,
      'mouseup',
      onRelease
    ))
    globalListeners.push(BasUtil.setDOMListener(
      $window.document,
      'touchend',
      onRelease
    ))
    globalListeners.push(BasUtil.setDOMListener(
      $window.document,
      'contextmenu',
      onContextMenu
    ))
  }

  function clearReleaseListeners () {

    BasUtil.executeArray(globalListeners)
    globalListeners = []
  }

  function onRelease (event) {

    if (event.cancelable) event.preventDefault()
    event.stopPropagation()

    clearReleaseListeners()
    clearHold()
  }

  function onContextMenu (event) {

    event.stopPropagation()
    event.preventDefault()
  }

  function changeValue (element, increase) {

    var hours, minutes

    if (!BasUtil.isNEString(element) ||
      !BasUtil.isBool(increase)) return

    hours = modal.time.getHours()
    minutes = modal.time.getMinutes()

    if (element === modal.ELEMENT_HOUR) {

      // Hours part
      hours = (hours + 24 + (increase ? 1 : -1)) % 24

    } else if (element === modal.ELEMENT_MINUTES) {

      // Minutes part
      minutes = (minutes + 60 + (increase ? 1 : -1)) % 60

    } else if (element === modal.ELEMENT_AMPM) {

      // AM / PM part
      hours = (hours + 12) % 24
    }

    modal.time.setHours(hours)
    modal.time.setMinutes(minutes)

    syncTimeUi()
  }

  function clearHold () {

    clearTimeout(_holdTimeout)
    clearInterval(_holdInterval)
    _holdInterval = -1
    _holdTimeout = -1
  }

  function closeModal (reason) {

    if (!modal.basInputModalConfig ||
      modal.basInputModalConfig.allowDismiss) {

      close(reason)
    }
  }

  function save () {

    close(modal.time)
  }

  function onTimeFormatChanged () {

    modal.timeFormat = basIntlState.timeFormat
    syncTimeUi()
  }

  function onSplashVisibility () {

    close()
  }

  function _onDestroy () {

    clearHold()
    clearReleaseListeners()
    BasUtil.executeArray(listeners)
    listeners = []
  }
}
