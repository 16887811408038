'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('Weather', [
    '$rootScope',
    'BAS_APP',
    'BAS_ROOM',
    'BAS_ROOMS',
    'BAS_UNITS',
    'BAS_WEATHER',
    'BAS_INTL',
    'BAS_CURRENT_CORE',
    'BasPreferences',
    'BasWeatherExternal',
    'CurrentBasCore',
    'RoomsHelper',
    'BasUtilities',
    Weather
  ])

/**
 * @typedef {Object} TWeatherState
 * @property {string} uiDefaultWeather
 * @property {string} uiCompleteWeather
 * @property {string[]} uiAllWeather
 * @property {Object<string, BasWeatherUi>} allWeather
 * @property {BasWeatherUi} externalWeather
 * @property {Object} css
 */

/**
 * @constructor
 * @param $rootScope
 * @param {BAS_APP} BAS_APP
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_UNITS} BAS_UNITS
 * @param {BAS_WEATHER} BAS_WEATHER
 * @param {BAS_INTL} BAS_INTL
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BasPreferences} BasPreferences
 * @param BasWeatherExternal
 * @param {CurrentBasCore} CurrentBasCore
 * @param {RoomsHelper} RoomsHelper
 * @param {BasUtilities} BasUtilities
 */
function Weather (
  $rootScope,
  BAS_APP,
  BAS_ROOM,
  BAS_ROOMS,
  BAS_UNITS,
  BAS_WEATHER,
  BAS_INTL,
  BAS_CURRENT_CORE,
  BasPreferences,
  BasWeatherExternal,
  CurrentBasCore,
  RoomsHelper,
  BasUtilities
) {
  var RECHECK_TIME = 3600000
  var RECHECK_DIRTY_TIME = 30000

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  var checkIntervalId = 0

  var checkDirtyIntervalId = 0

  /**
   * @type {TWeatherState}
   */
  var state = {
    uiDefaultWeather: '',
    uiCompleteWeather: '',
    uiAllWeather: [],
    allWeather: {},
    externalWeather: new BasWeatherExternal(),
    css: {}
  }

  this.get = get
  this.update = update
  this.getIndexOfDefault = getIndexOfDefault

  init()

  function init () {

    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_CORE_CONNECTED,
      _onCurrentCoreCoreConnected
    )

    $rootScope.$on(
      BAS_ROOM.EVT_WEATHER_STATIONS_UPDATED,
      _onWeatherStationsUpdated
    )

    $rootScope.$on(
      BAS_ROOMS.EVT_ROOMS_UPDATED,
      _onWeatherStationsUpdated
    )

    $rootScope.$on(
      BAS_WEATHER.EVT_WEATHER_DEFAULT_CHANGED,
      _onWeatherDefaultChanged
    )

    $rootScope.$on(
      BAS_UNITS.EVT_TEMPERATURE_UNIT_CHANGED,
      _onUnitChanged
    )

    $rootScope.$on(
      BAS_APP.EVT_PAUSE,
      _onPause
    )

    $rootScope.$on(
      '$translateChangeSuccess',
      _onLanguageChanged
    )

    $rootScope.$on(
      BAS_INTL.EVT_TIME_FORMAT_CHANGED,
      _onTimeFormatChanged
    )

    _syncWeatherStations()
  }

  /**
   * @returns {TWeatherState}
   */
  function get () {

    return state
  }

  function _onWeatherDefaultChanged (_event, index) {

    var uuid

    if (BasUtil.isPNumber(index, true) &&
      index < state.uiAllWeather.length) {

      uuid = state.uiAllWeather[index]

      state.uiDefaultWeather = uuid

      BasPreferences.setDefaultWeather(uuid, currentBasCoreState.core)

      _syncWeatherStations()
    }
  }

  function _onUnitChanged () {

    var keys, length, i

    keys = Object.keys(state.allWeather)
    length = keys.length

    for (i = 0; i < length; i++) {
      state.allWeather[keys[i]].makeUi()
    }

    _syncScope()
  }

  function _onWeatherStationsUpdated () {

    _syncWeatherStations()
  }

  function _syncWeatherStations () {

    var _defaultUuid, _externalUuid, uuid
    var keys, length, i

    _resetState()

    _externalUuid = state.externalWeather.uuid
    // Add external weather
    state.allWeather[_externalUuid] = state.externalWeather
    // Set it as complete weather
    state.uiCompleteWeather = _externalUuid

    RoomsHelper.forEachRoom(_onRoomDoWeatherSync)

    _defaultUuid = BasPreferences.getDefaultWeather(currentBasCoreState.core)

    if (state.allWeather[_defaultUuid]) {

      state.uiDefaultWeather = _defaultUuid

    } else {

      state.uiDefaultWeather = state.uiCompleteWeather
    }

    state.uiAllWeather.push(state.uiCompleteWeather)

    keys = Object.keys(state.allWeather)
    length = keys.length

    for (i = 0; i < length; i++) {
      uuid = keys[i]

      if (state.uiAllWeather.indexOf(uuid) < 0) {
        state.uiAllWeather.push(uuid)
      }
    }

    if (state.uiAllWeather.length > 1) {
      state.css[BAS_WEATHER.CSS_HAS_MULTIPLE_SERVICES] = true
    }

    _syncScope()
  }

  /**
   * @private
   * @param {BasRoom} room
   */
  function _onRoomDoWeatherSync (room) {

    var length, i, basWeatherStation, basWeatherStationUuid

    if (room.hasWeatherStations()) {

      length = room.weatherStations.weatherStations.length
      for (i = 0; i < length; i++) {

        basWeatherStation = room.weatherStations.weatherStations[i]

        basWeatherStationUuid = basWeatherStation.uuid

        state.allWeather[basWeatherStationUuid] = basWeatherStation
      }
    }
  }

  function getIndexOfDefault () {

    var defaultIndex

    defaultIndex = state.uiAllWeather.indexOf(state.uiDefaultWeather)

    return defaultIndex < 0 ? 0 : defaultIndex
  }

  function _onLanguageChanged () {

    update()
  }

  function _onTimeFormatChanged () {

    var i

    for (i = 0; i < state.uiAllWeather.length; i++) {

      state.allWeather[state.uiAllWeather[i]].onTimeFormatChanged()
    }
  }

  function _onCurrentCoreCoreConnected (
    _event,
    _basCoreContainer,
    isConnected
  ) {

    if (isConnected) {
      update()
      setCheck()
    } else {
      clearCheck()
    }
  }

  /**
   * Update forecast
   *
   * @param {boolean} [onlyDirty = false]
   */
  function update (onlyDirty) {

    var lang, locale, length, i, _uuid, basWeatherUi

    lang = BasPreferences.getLanguage()

    locale = BasUtil.isNEString(lang)
      ? lang
      : BasUtilities.getSystemLanguage()

    length = state.uiAllWeather.length
    for (i = 0; i < length; i++) {

      _uuid = state.uiAllWeather[i]
      basWeatherUi = state.allWeather[_uuid]

      if (
        BasUtil.isNEObject(basWeatherUi) &&
        BasUtil.isFunction(basWeatherUi.update)
      ) {

        if (onlyDirty !== true || basWeatherUi.dirty) {

          basWeatherUi.update(locale).then(_syncScope)
        }
      }
    }
  }

  function _onPause () {

    clearCheck()
  }

  function clearCheck () {

    clearInterval(checkIntervalId)
    clearInterval(checkDirtyIntervalId)
    checkIntervalId = 0
    checkDirtyIntervalId = 0
  }

  function setCheck () {

    clearCheck()
    checkIntervalId = setInterval(update, RECHECK_TIME)
    checkDirtyIntervalId = setInterval(update, RECHECK_DIRTY_TIME, true)
  }

  function _syncScope () {

    $rootScope.$applyAsync()
  }

  function _resetState () {

    state.uiAllWeather = []
    state.allWeather = {}

    _resetCss()
  }

  function _resetCss () {

    state.css = {}
    state.css[BAS_WEATHER.CSS_HAS_MULTIPLE_SERVICES] = false
  }
}
