[
  {
    "name": "Prime Video",
    "uri": "video:app:com.amazon.aiv.AIVApp"
  },
  {
    "name": "Arcade",
    "uri": "video:app:com.apple.Arcade"
  },
  {
    "name": "App Store",
    "uri": "video:app:com.apple.TVAppStore"
  },
  {
    "name": "Music",
    "uri": "video:app:com.apple.TVMusic"
  },
  {
    "name": "Photos",
    "uri": "video:app:com.apple.TVPhotos"
  },
  {
    "name": "Search",
    "uri": "video:app:com.apple.TVSearch"
  },
  {
    "name": "Settings",
    "uri": "video:app:com.apple.TVSettings"
  },
  {
    "name": "TV",
    "uri": "video:app:com.apple.TVWatchList"
  },
  {
    "name": "FaceTime",
    "uri": "video:app:com.apple.facetime"
  },
  {
    "name": "Podcasts",
    "uri": "video:app:com.apple.podcasts"
  },
  {
    "name": "Disney+",
    "uri": "video:app:com.disney.disneyplus"
  },
  {
    "name": "YouTube Kids",
    "uri": "video:app:com.google.ios.youtubekids"
  },
  {
    "name": "HBO Max",
    "uri": "video:app:com.wbd.hbomax"
  },
  {
    "name": "Netflix",
    "uri": "video:app:com.netflix.Netflix"
  },
  {
    "name": "YouTube",
    "uri": "video:app:com.google.ios.youtube"
  }
]
