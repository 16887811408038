'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basDevicePermissions', [])
  .service('BasDevicePermissions', [
    '$window',
    BasDevicePermissions
  ])

/**
 * @callback CBasDevicePermissions
 * @param {?Error} error
 * @param {boolean} [granted]
 * @param {boolean} [deniedAlways] user asked not to show native popup
 *
 * deniedAlways should result in an UI action to redirect user to settings
 */

/**
 * @constructor
 * @param $window
 */
function BasDevicePermissions ($window) {

  var ERR_NO_PLUGIN = 'No permission plugin'
  var PLATFORM_ANDROID = 'android'
  var PLATFORM_IOS = 'ios'
  var PLATFORM_BROWSER = 'browser'

  this.PLATFORM_ANDROID = PLATFORM_ANDROID
  this.PLATFORM_BROWSER = PLATFORM_BROWSER
  this.PLATFORM_IOS = PLATFORM_IOS

  this.requestIntercomPermissions = _requestIntercomPermissions
  this.requestBasDeviceCameraPermissions = _requestBasDeviceCameraPermissions
  this.requestLocalNetworkPermission = _requestLocalNetworkPermission

  /**
   * Requests all needed permissions for intercom permissions
   * Only supported by android
   *
   * if askIfInvalid is set, the user will be prompted by a permission
   * dialog if the permission is not yet granted
   *
   * @private
   * @param {boolean} microphone
   * @param {boolean} camera
   * @param {CBasDevicePermissions} callback
   * @param {boolean} askIfInvalid
   */
  function _requestIntercomPermissions (
    microphone,
    camera,
    callback,
    askIfInvalid
  ) {
    var plugin, list

    if (BasUtil.isFunction(callback)) {

      plugin = _getPermissionsPlugin()

      if (plugin) {

        list = []

        if (microphone === true) {

          list.push(plugin.permission.RECORD_AUDIO)
        }

        if (camera === true) {

          list.push(plugin.permission.CAMERA)
        }

        _checkAndroidPermissions(
          list,
          callback,
          askIfInvalid
        )

      } else {

        callback(new Error(ERR_NO_PLUGIN))
      }
    }
  }

  /**
   * Request device camera permissions
   *
   * cameraSrc defines if permissions are for camera or photo album
   *
   * askIfInvalid defines if user needs to be prompted if permissions are
   * not granted
   *
   * @private
   * @param {CBasDevicePermissions} callback
   * @param {boolean} cameraSrc
   * @param {boolean} askIfInvalid
   * @param {string} platform
   */
  function _requestBasDeviceCameraPermissions (
    callback,
    cameraSrc,
    askIfInvalid,
    platform
  ) {
    var plugin

    if (BasUtil.isFunction(callback)) {

      if (PLATFORM_BROWSER === platform) {

        callback(null, true)

      } else {

        plugin = _getPermissionsPlugin()

        if (plugin) {

          if (PLATFORM_ANDROID === platform) {
            callback(null, true)
          } else if (PLATFORM_IOS === platform) {

            _checkIosMediaPermissions(
              cameraSrc,
              callback,
              askIfInvalid
            )
          }
        } else {

          if (BasUtil.isFunction(callback)) {

            callback(new Error(ERR_NO_PLUGIN))
          }
        }
      }
    }
  }

  /**
   * Request local network permission (only needed on ios)
   *
   * askIfInvalid defines if user needs to be prompted if permissions are
   * not granted
   *
   * @private
   * @param {CBasDevicePermissions} callback
   * @param {boolean} askIfInvalid
   * @param {string} platform
   */
  function _requestLocalNetworkPermission (
    callback,
    askIfInvalid,
    platform
  ) {
    var plugin

    if (BasUtil.isFunction(callback)) {

      if (PLATFORM_BROWSER === platform || PLATFORM_ANDROID === platform) {

        callback(null, true)

      } else {

        plugin = _getPermissionsPlugin()

        if (plugin && PLATFORM_IOS === platform) {

          _checkIosLocalNetworkPermission(
            callback,
            askIfInvalid
          )
        } else {

          if (BasUtil.isFunction(callback)) {

            callback(new Error(ERR_NO_PLUGIN))
          }
        }
      }
    }
  }

  /**
   * Request device camera/camera roll permissions (ios specific)
   *
   * cameraSrc defines if permissions are for camera or camera roll
   *
   * askIfInvalid defines if user needs to be prompted if permissions are
   * not granted
   *
   * @private
   * @param {boolean} cameraSrc
   * @param {CBasDevicePermissions} callback
   * @param {boolean} askIfInvalid
   */
  function _checkIosMediaPermissions (
    cameraSrc,
    callback,
    askIfInvalid
  ) {
    var plugin

    if (BasUtil.isFunction(callback)) {

      plugin = _getPermissionsPlugin()

      if (plugin) {

        if (cameraSrc) {

          plugin.getCameraAuthorizationStatus(
            _onPermissionCheckResult,
            _onPluginError,
            { externalStorage: false }
          )

        } else {

          plugin.getCameraRollAuthorizationStatus(
            _onPermissionCheckResult,
            _onPluginError
          )
        }

      } else {

        callback(new Error(ERR_NO_PLUGIN))
      }
    }

    function _onPermissionCheckResult (status) {

      var granted, deniedAlways

      granted = _convertPluginResult([status])

      deniedAlways = _isDeniedAlways([status])

      if (!granted && askIfInvalid) {

        if (cameraSrc) {

          plugin.requestCameraAuthorization(
            _onPermissionResult,
            _onPluginError,
            { externalStorage: false }
          )

        } else {

          plugin.requestCameraRollAuthorization(
            _onPermissionResult,
            _onPluginError
          )
        }

      } else {

        callback(null, granted, deniedAlways)
      }
    }

    function _onPermissionResult (status) {

      callback(
        null,
        _convertPluginResult([status]),
        _isDeniedAlways([status])
      )
    }

    function _onPluginError (error) {

      callback(new Error(error))
    }
  }

  /**
   * Request local network permission (ios specific)
   **
   * askIfInvalid defines if user needs to be prompted if permissions are
   * not granted
   *
   * @private
   * @param {CBasDevicePermissions} callback
   * @param {boolean} _askIfInvalid
   */
  function _checkIosLocalNetworkPermission (
    callback,
    _askIfInvalid
  ) {
    var plugin

    if (BasUtil.isFunction(callback)) {

      plugin = _getPermissionsPlugin()

      if (plugin) {

        // TODO add permission check
        // Feature not available in cordova.plugins.diagnostic@6.0.3
        // https://github.com/dpa99c/cordova-diagnostic-plugin/issues/426

        // plugin.getLocalNetworkAccessStatus(
        //    _onPermissionCheckResult,
        //    _onPluginError
        // );

        callback(null, true, false)

      } else {

        callback(new Error(ERR_NO_PLUGIN))
      }
    }

    // Wait for availability in plugin
    // function _onPermissionCheckResult (status) {
    //
    //   var granted, deniedAlways
    //
    //   granted = _convertPluginResult([status])
    //
    //   deniedAlways = _isDeniedAlways([status])
    //
    //   if (!granted && askIfInvalid) {
    //
    //     // TODO add permission request
    //     // Feature not available in cordova.plugins.diagnostic@6.0.3
    //     // https://github.com/dpa99c/cordova-diagnostic-plugin/issues/426
    //
    //     // plugin.requestLocalNetworkAccess(
    //     //    _onPermissionResult,
    //     //    _onPluginError
    //     // );
    //
    //   } else {
    //
    //     callback(null, granted, deniedAlways)
    //   }
    // }
    //
    // function _onPermissionResult (status) {
    //
    //   callback(null,
    //     _convertPluginResult([status]),
    //     _isDeniedAlways([status])
    //   )
    // }
    //
    // function _onPluginError (error) {
    //
    //   callback(new Error(error))
    // }
  }

  /**
   * Generic method to request permissions (android specific)
   *
   * askIfInvalid defines if user needs to be prompted if permissions are
   * not granted
   *
   * @private
   * @param {string[]} permissionList
   * @param {CBasDevicePermissions} callback
   * @param {boolean} askIfInvalid
   */
  function _checkAndroidPermissions (permissionList, callback, askIfInvalid) {

    var plugin

    if (BasUtil.isFunction(callback)) {

      plugin = _getPermissionsPlugin()

      if (plugin) {

        plugin.getPermissionsAuthorizationStatus(
          _onPermissionCheckResult,
          _onPluginError,
          permissionList
        )

      } else {

        callback(new Error(ERR_NO_PLUGIN))
      }
    }

    function _onPermissionCheckResult (statuses) {

      var granted, deniedAlways

      granted = _convertPluginResult(statuses)

      deniedAlways = _isDeniedAlways(statuses)

      if (!granted && askIfInvalid) {

        plugin.requestRuntimePermissions(
          onPermissionResult,
          _onPluginError,
          permissionList
        )

      } else {

        callback(null, granted, deniedAlways)
      }
    }

    function _onPluginError (error) {

      callback(error)
    }

    function onPermissionResult (statuses) {

      callback(
        null,
        _convertPluginResult(statuses),
        _isDeniedAlways(statuses)
      )
    }
  }

  function _convertPluginResult (statuses) {

    var i, keys, length, valid, plugin, permissionStatus

    valid = true

    if (statuses) {

      plugin = _getPermissionsPlugin()

      if (plugin) {

        permissionStatus = plugin.permissionStatus

        keys = Object.keys(statuses)
        length = keys.length

        for (i = 0; i < length; i++) {

          if (permissionStatus.NOT_REQUESTED === statuses[keys[i]] ||
            permissionStatus.DENIED_ONCE === statuses[keys[i]] ||
            permissionStatus.DENIED_ALWAYS === statuses[keys[i]]) {

            valid = false
            break
          }
        }
      }
    }

    return valid
  }

  function _isDeniedAlways (statuses) {

    var i, keys, length, deniedAlways, plugin, permissionStatus

    deniedAlways = false

    if (statuses) {

      plugin = _getPermissionsPlugin()

      if (plugin) {

        permissionStatus = plugin.permissionStatus

        keys = Object.keys(statuses)
        length = keys.length

        for (i = 0; i < length; i++) {

          if (permissionStatus.DENIED_ALWAYS === statuses[keys[i]]) {

            deniedAlways = true
            break
          }
        }
      }
    }

    return deniedAlways
  }

  /**
   * Get Permission plugin instance
   *
   * @private
   * @returns {?Object}
   */
  function _getPermissionsPlugin () {

    if (BasUtil.isObject($window['cordova']) &&
      BasUtil.isObject($window['cordova']['plugins'])) {

      return $window['cordova']['plugins']['diagnostic']
    }

    return null
  }
}
