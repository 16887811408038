'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasRoomLights', [
    '$rootScope',
    'BAS_API',
    'BAS_ROOM',
    'BasSharedServerStorage',
    'BasUtilities',
    'CurrentBasCore',
    'RoomsHelper',
    'BasLight',
    basRoomLightsFactory
  ])

/**
 * @param $rootScope
 * @param BAS_API
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BasSharedServerStorage} BasSharedServerStorage
 * @param {BasUtilities} BasUtilities
 * @param {CurrentBasCore} CurrentBasCore
 * @param {RoomsHelper} RoomsHelper
 * @param BasLight
 * @returns BasRoomLights
 */
function basRoomLightsFactory (
  $rootScope,
  BAS_API,
  BAS_ROOM,
  BasSharedServerStorage,
  BasUtilities,
  CurrentBasCore,
  RoomsHelper,
  BasLight
) {
  const K_GROUPS = 'groups'
  const K_LIGHTS = 'lights'

  const GROUP_UUID_PREFIX = 'group'

  /**
   * @type {TBasSharedServerStorageState}
   */
  const basSharedServerStorage = BasSharedServerStorage.get()

  /**
   * @type {TCurrentBasCoreState}
   */
  const currentBasCoreState = CurrentBasCore.get()

  /**
   * @constructor
   * @param {BasRoom} basRoom
   */
  function BasRoomLights (basRoom) {

    /**
     * All BasLights
     *
     * @type {Object<string, BasLight>}
     */
    this.basLights = {}

    /**
     * Group lights
     * Represents an array of group uuids
     *
     * @type {string[]}
     */
    this.uiLightGroups = []

    /**
     * Lights without a group
     * Represents an array of lightUuids that don't have a group
     *
     * @type {string[]}
     */
    this.uiLightsWithoutGroup = []

    /**
     * @type {boolean}
     */
    this.hasLightGroupOrder = false

    /**
     * @private
     * @type {BasRoom}
     */
    this._basRoom = basRoom

    this._basRoom.cssSetHasLights(
      RoomsHelper.roomHasFunctionLights(this._basRoom)
    )

    this.parseRoom()
  }

  /**
   * @param {BasRoom} room
   * @returns {boolean}
   */
  BasRoomLights.hasLights = function (room) {

    return (
      room &&
      room.room &&
      BasUtil.isNEArray(room.room.lights)
    )
  }

  /**
   * Parse the raw room from the parent BasRoom
   */
  BasRoomLights.prototype.parseRoom = function () {

    if (BasRoomLights.hasLights(this._basRoom)) {
      this.syncLights()
    }
  }

  BasRoomLights.prototype.syncLights = function () {
    const lightsDimmable = []
    const lightsToggle = []
    const lightsColor = []

    this.resetLights()

    if (BasRoomLights.hasLights(this._basRoom)) {

      const length = this._basRoom.room.lights.length
      for (let i = 0; i < length; i++) {

        const uuid = this._basRoom.room.lights[i]
        const device = CurrentBasCore.getDevice(uuid)

        if (device) {

          const light = new BasLight(device, this)
          this.basLights[uuid] = light

          if (device.allowsRead(BAS_API.LightDevice.C_COLOR) ||
            device.allowsRead(BAS_API.LightDevice.C_COLOR_TEMPERATURE)) {

            lightsColor.push(light.uuid)

          } else if (device.allowsRead(
            BAS_API.LightDevice.C_BRIGHTNESS
          )) {

            lightsDimmable.push(light.uuid)

          } else {

            lightsToggle.push(light.uuid)
          }
        }
      }

      if (lightsDimmable.length > 0) {
        this.uiLightsWithoutGroup.push(...lightsDimmable)
      }

      if (lightsColor.length > 0) {
        this.uiLightsWithoutGroup.push(...lightsColor)
      }

      if (lightsToggle.length > 0) {
        this.uiLightsWithoutGroup.push(...lightsToggle)
      }
    }

    this.checkLightChange()
  }

  /**
   * Checks if this BasRoomLights instance has any lights
   *
   * @returns {boolean}
   */
  BasRoomLights.prototype.hasLights = function () {

    return Object.keys(this.basLights).length > 0
  }

  /**
   * Checks whether a light in the room is active
   *
   * @returns {boolean}
   */
  BasRoomLights.prototype.areLightsActive = function () {
    const keys = Object.keys(this.basLights)
    const length = keys.length

    if (length > 0) {

      for (const key of keys) {

        const light = this.basLights[key]

        if (light?.onState === true) return true
      }
    }

    return false
  }

  /**
   * Checks whether a light in the room is active
   */
  BasRoomLights.prototype.checkLightChange = function () {

    if (this._basRoom) {

      this._basRoom.cssSetActiveLights(this.areLightsActive())
      $rootScope.$emit(BAS_ROOM.EVT_ACTIVITY_CHANGED, this._basRoom.id)
      $rootScope.$emit(BAS_ROOM.EVT_ACTIVITY_LIGHTS, this._basRoom.id)
    }
  }

  /**
   * Gets a BasLight for the uuid
   *
   * @param {string} uuid
   * @returns {?BasLight}
   */
  BasRoomLights.prototype.getLight = function (uuid) {

    return this.basLights[uuid]
  }

  BasRoomLights.prototype.turnOffAllLights = function () {

    Object.values(this.basLights)
      .filter(light => light?.onState)
      .forEach(light => light.toggle())
  }

  /**
   * Creates new BasLight instances from current real BasLight instances
   *
   * @returns {BasLight[]}
   */
  BasRoomLights.prototype.createEditableClones = function () {

    const result = []
    const keys = Object.keys(this.basLights)
    const length = keys.length

    if (length > 0) {

      for (const key of keys) {

        const light = this.basLights[key]

        if (light?.isEditable()) {

          const clone = light.clone()
          clone.fillInUuid()

          result.push(clone)
        }
      }
    }

    return result
  }

  /**
   * Clears all lights
   */
  BasRoomLights.prototype.clearLights = function () {

    Object.values(this.basLights).forEach(light => light.clear())
  }

  BasRoomLights.prototype.suspendLights = function () {

    Object.values(this.basLights).forEach(light => light.suspend())
  }

  /**
   * Resets the lights, removing them
   */
  BasRoomLights.prototype.resetLights = function () {

    this.clearLights()

    this.lights = []
  }

  BasRoomLights.prototype.suspend = function () {

    this.suspendLights()
  }

  BasRoomLights.prototype.destroy = function () {

    this.resetLights()
  }

  BasRoomLights.prototype.updateTranslation = function () {

    Object.values(this.basLights).forEach(light => light.updateTranslation())
  }

  BasRoomLights.prototype.createGroup = function () {

    const uiLightGroupIndex = this.uiLightGroups.length + 1
    const groupName =
      BasUtilities.translate('group') + ' ' + uiLightGroupIndex

    const groupUuid = GROUP_UUID_PREFIX + '-' + BasUtil.uuidv4()

    this.basLights[groupUuid] = new BasLight(
      {
        name: groupName,
        uuid: groupUuid,
        groupLightUuids: []
      },
      this
    )

    this.uiLightGroups.push(groupUuid)
  }

  BasRoomLights.prototype.removeGroup = function (uiLightGroupUuid) {

    this.basLights[uiLightGroupUuid].groupLightUuids = []

    const index = this.uiLightGroups.indexOf(uiLightGroupUuid)

    if (index >= 0) {

      this.uiLightGroups.splice(index, 1)
      delete this.basLights[uiLightGroupUuid]
    }
  }

  BasRoomLights.prototype.syncLightGroupOrderFromStorage = function (room) {

    const lightGroupOrder = basSharedServerStorage.lightGroupOrder
    const copyOfRoomLights = BasUtil.copyObjectShallow(this.basLights)
    const keys = Object.keys(copyOfRoomLights)
    let length = keys.length

    this.uiLightGroups = []
    this.uiLightsWithoutGroup = []
    this.hasLightGroupOrder = !!lightGroupOrder

    // Remove old group keys
    this.basLights = Object.fromEntries(
      Object.entries(this.basLights)
        .filter(([key]) => !key.includes(GROUP_UUID_PREFIX))
    )

    if (_hasCurrentRoomLightGroupsInStorage(room)) {

      const lightGroups = lightGroupOrder[room.id]
        ? lightGroupOrder[room.id].groups
        : null

      const lightsWithoutGroup = lightGroupOrder[room.id]
        ? lightGroupOrder[room.id].lights
        : null

      if (lightGroups) {

        for (const lightGroup of lightGroups) {

          if (lightGroup.lights.length) {

            const groupLightUuids = []

            for (const lightUuid of lightGroup.lights) {
              _syncWithRoomLights(lightUuid, groupLightUuids)
            }

            if (groupLightUuids.length) {
              this.uiLightGroups.push(lightGroup.uuid)
            }

            this.basLights[lightGroup.uuid] = new BasLight(
              {
                name: lightGroup.name,
                uuid: lightGroup.uuid,
                groupLightUuids: groupLightUuids
              },
              this
            )
            this.updateGroupToggleStateWithWhiteSupport(lightGroup.uuid)
          }
        }
      }

      if (lightsWithoutGroup) {

        for (const lightUuid of lightsWithoutGroup) {

          _syncWithRoomLights(lightUuid, this.uiLightsWithoutGroup)
        }

        if (length > 0) {

          for (const key of keys) {

            if (!key.includes(GROUP_UUID_PREFIX)) {

              const light = copyOfRoomLights[key]

              if (light) this.uiLightsWithoutGroup.push(light.uuid)
            }
          }
        }
      }
    } else {

      this.syncLights()
    }

    function _syncWithRoomLights (lightUuid, lightsArray) {

      if (copyOfRoomLights[lightUuid]) {
        lightsArray.push(lightUuid)
        delete copyOfRoomLights[lightUuid]
        length = Object.keys(copyOfRoomLights).length
      }
    }
  }

  BasRoomLights.prototype.updateGroupToggleStateWithWhiteSupport =
    function (uiLightGroupUuid) {

      const virtualBasLight = this.basLights[uiLightGroupUuid]

      if (virtualBasLight instanceof BasLight) {

        virtualBasLight.onState = false

        for (const lightUuid of virtualBasLight.groupLightUuids) {

          const light = this.basLights[lightUuid]

          if (light && light.canToggleOn && light.onState) {

            virtualBasLight.onState = true

            if (light._supportsWhiteViaBrightness) {

              virtualBasLight._supportsWhiteViaBrightness = true
            }
          }

          if (!virtualBasLight.onState) virtualBasLight.syncModeUI()
        }

        this.checkLightChange()
      }
    }

  BasRoomLights.prototype.syncGroupBrightnessSlider = function () {

    for (const uiLightGroupUuid of this.uiLightGroups) {

      const virtualBasLight = this.basLights[uiLightGroupUuid]

      if (virtualBasLight?.canChangeBrightness) {

        virtualBasLight.updateGroupBrightness()
      }
    }
  }

  BasRoomLights.prototype.updateServerStorageWithGroups = function () {
    const obj = {}

    obj[K_GROUPS] = []
    obj[K_LIGHTS] = []

    for (const uiLightGroupUuid of this.uiLightGroups) {

      const virtualBasLight = this.basLights[uiLightGroupUuid]

      if (virtualBasLight.groupLightUuids) {

        obj[K_GROUPS].push(
          {
            name: virtualBasLight.name,
            lights: virtualBasLight.groupLightUuids,
            uuid: uiLightGroupUuid
          }
        )
      }
    }

    for (const lightUuid of this.uiLightsWithoutGroup) {
      if (lightUuid) obj[K_LIGHTS].push(lightUuid)
    }

    currentBasCoreState.core.core.sharedServerStorage
      .updateLightGroupOrder(this._basRoom.id, obj)
  }

  /**
   * Get group of lights
   *
   * @param {string} uuid
   * @returns {?BasLight}
   */
  BasRoomLights.prototype.getGroupByUuid = function (uuid) {

    if (this.uiLightGroups.includes(uuid)) return this.basLights[uuid]

    return null
  }

  function _hasCurrentRoomLightGroupsInStorage (room) {
    const lightGroupOrder = basSharedServerStorage.lightGroupOrder

    return (
      lightGroupOrder[room.id]?.groups &&
      lightGroupOrder[room.id]?.lights
    )
  }

  return BasRoomLights
}
