'use strict'

import * as AmazonCognitoIdentityJs from 'amazon-cognito-identity-js'
import * as operators from 'rxjs/operators'
import * as BasUtil from '@basalte/bas-util'
import BasAppSyncClient from '../basappsync/basappsyncclient'

angular
  .module('basalteApp')
  .service('BasLiveAccount', [
    '$window',
    '$rootScope',
    'ModalService',
    'BAS_HTML',
    'BAS_APP',
    'BAS_CURRENT_CORE',
    'BAS_LIVE_ACCOUNT',
    'BAS_PREFERENCES',
    'BAS_MODAL',
    'BAS_ERRORS',
    'STATES',
    'BasAppDevice',
    'BasModal',
    'CurrentBasCore',
    'BasServerStorage',
    'BasPreferences',
    'BasProfilePreferences',
    'BasLiveProject',
    'BasString',
    'BasStorage',
    'BasState',
    'BasError',
    'BasInAppBrowser',
    'BasUtilities',
    'Logger',
    BasLiveAccount
  ])

/**
 * @callback CBasAppSyncTokenGenerator
 * @returns {Promise<string>}
 */

/**
 * @typedef {Object} TBasRemoteMetaData
 * @property {?TBasRemoteLocation} location
 */

/**
 * @typedef {Object} TBasRemoteImage
 * @property {string} variant
 * @property {string} url
 */

/**
 * @typedef {Object} TBasRemoteLocation
 * @property {string} city
 * @property {string} country
 */

/**
 * @typedef {Object} TBasRemoteCore
 * @property {string} uuid
 * @property {?string} name
 * @property {boolean} online
 * @property {boolean} integratorAccess
 * @property {?(TBasRemoteImage[])} images
 * @property {?TBasRemoteMetaData} metadata
 */

/**
 * @typedef {Object} TBasLiveServerState
 * @property {boolean} liveAvailable
 * @property {boolean} linked
 * @property {boolean} linkedToMe
 * @property {string} owner
 */

/**
 * @typedef {Object} TBasLiveAccountState
 * @property {boolean} isRegistering
 * @property {boolean} isVerifying
 * @property {boolean} isResetting
 * @property {boolean} isSendingConfirmation
 * @property {boolean} isChangingPassword
 * @property {boolean} isLoggingIn
 * @property {boolean} continueWithoutLive
 * @property {boolean} isGettingSession
 * @property {boolean} isPerformingAnAction
 * @property {boolean} isLoggedIn
 * @property {boolean} hasNotificationPermission
 * @property {boolean} isJWTOverridden
 * @property {AmazonCognitoIdentityJs.CognitoUserSession} session
 * @property {string} email
 * @property {string} username
 * @property {string} uiEmail
 * @property {string[]} uiProjects
 * @property {string[]} uiProjectsAll
 * @property {string[]} uiProjectsOnline
 * @property {Object<string, BasLiveProject>} projects
 * @property {number} projectsCheckedTime Time of last check
 * @property {boolean} projectsChecked Projects successfully checked last time
 * @property {Object<string, boolean>} css
 */

/**
 * @typedef {Object} BasCognitoUserAttribute
 * @property {string} Name
 * @property {string} Value
 */

/**
 * @typedef {Object} BasOverrideJWT
 * @property {string} token
 * @property {boolean} isOverride
 */

/**
 * @constructor
 * @param $window
 * @param $rootScope
 * @param ModalService
 * @param {BAS_HTML} BAS_HTML
 * @param {BAS_APP} BAS_APP
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_LIVE_ACCOUNT} BAS_LIVE_ACCOUNT
 * @param {BAS_PREFERENCES} BAS_PREFERENCES
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BAS_ERRORS} BAS_ERRORS
 * @param {STATES} STATES
 * @param {BasAppDevice} BasAppDevice
 * @param {BasModal} BasModal
 * @param {CurrentBasCore} CurrentBasCore
 * @param {BasServerStorage} BasServerStorage
 * @param {BasPreferences} BasPreferences
 * @param {BasProfilePreferences} BasProfilePreferences
 * @param BasLiveProject
 * @param BasString
 * @param BasStorage
 * @param {BasState} BasState
 * @param BasError
 * @param BasInAppBrowser
 * @param {BasUtilities} BasUtilities
 * @param Logger
 */
function BasLiveAccount (
  $window,
  $rootScope,
  ModalService,
  BAS_HTML,
  BAS_APP,
  BAS_CURRENT_CORE,
  BAS_LIVE_ACCOUNT,
  BAS_PREFERENCES,
  BAS_MODAL,
  BAS_ERRORS,
  STATES,
  BasAppDevice,
  BasModal,
  CurrentBasCore,
  BasServerStorage,
  BasPreferences,
  BasProfilePreferences,
  BasLiveProject,
  BasString,
  BasStorage,
  BasState,
  BasError,
  BasInAppBrowser,
  BasUtilities,
  Logger
) {
  var K_TITLE = 'title'
  var K_BODY = 'body'
  var K_NOTIFICATION_TITLE = 'notification_title'
  var K_NOTIFICATION_BODY = 'notification_body'
  var K_APS = 'aps'
  var K_ALERT = 'alert'

  var GRAPH_ERR_NOT_AUTHORIZED = 'NotAuthorized'
  var GRAPH_ERR_NO_INTEGRATOR_ACCESS = 'NoIntegratorAccess'
  var GRAPH_ERR_NO_ACTIVE_SERVERS_FOUND = 'NoActiveServersFound'

  var CSS_BAS_LIVE_UNSUPPORTED_SHOW = 'bas-live--unsupported-show'
  var CSS_BAS_LIVE_LOGIN_SHOW = 'bas-live--login-show'
  var CSS_BAS_LIVE_LOGOUT_SHOW = 'bas-live--logout-show'
  var CSS_BAS_LIVE_CHECKING_SESSION = 'bas-live--checking-session'
  var CSS_BAS_LIVE_RETRIEVING_ACCOUNT_DETAILS =
    'bas-live--retrieving-account-details'
  var CSS_BAS_LIVE_RETRIEVING_PROJECTS = 'bas-live--retrieving-projects'
  var CSS_BAS_LIVE_LOGGING_IN = 'bas-live--logging-in'
  var CSS_BAS_LIVE_REGISTERING = 'bas-live--registering'
  var CSS_BAS_LIVE_VERIFYING = 'bas-live--verifying'
  var CSS_BAS_LIVE_RESETTING = 'bas-live--resetting'
  var CSS_BAS_LIVE_IS_PERFORMING_AN_ACTION =
    'bas-live--is-performing-an-action'
  var CSS_BAS_LIVE_SHOW_BACK = 'bas-live--show-back'
  var CSS_BAS_LIVE_HAS_NOTIFICATION_PERMISSION =
    'bas-live--has-notification-permission'

  /**
   * @typedef {Object} TCloudEnvironment
   * @property {string} appSyncRegion
   * @property {string} appSyncLinkId
   * @property {string} homePoolId
   * @property {string} homeClientId
   * @property {string} proPoolId
   * @property {string} proClientId
   */

  const K_ENV_PROD = 'prod'
  const K_ENV_DEV = 'dev'

  /**
   *
   * @type {Object<string, TCloudEnvironment>}
   */
  const CLOUD_ENVIRONMENTS = {
    [K_ENV_PROD]: {
      appSyncRegion: BAS_LIVE_ACCOUNT.APP_SYNC_REGION,
      appSyncLinkId: BAS_LIVE_ACCOUNT.APP_SYNC_LINK_ID,
      homePoolId: BAS_LIVE_ACCOUNT.HOME_POOL_ID,
      homeClientId: BAS_LIVE_ACCOUNT.HOME_CLIENT_ID,
      proPoolId: BAS_LIVE_ACCOUNT.PRO_POOL_ID,
      proClientId: BAS_LIVE_ACCOUNT.PRO_CLIENT_ID
    },
    [K_ENV_DEV]: {
      appSyncRegion: BAS_LIVE_ACCOUNT.APP_SYNC_REGION,
      appSyncLinkId: BAS_LIVE_ACCOUNT.DEV_APP_SYNC_LINK_ID,
      homePoolId: BAS_LIVE_ACCOUNT.DEV_HOME_POOL_ID,
      homeClientId: BAS_LIVE_ACCOUNT.DEV_HOME_CLIENT_ID,
      proPoolId: BAS_LIVE_ACCOUNT.DEV_PRO_POOL_ID,
      proClientId: BAS_LIVE_ACCOUNT.DEV_PRO_CLIENT_ID
    }
  }

  var HOME_POOL_DATA = {
    UserPoolId: _getHomePoolId(),
    ClientId: _getHomeClientId(),
    Storage: BasStorage.getStorageHandle()
  }

  var PRO_POOL_DATA = {
    UserPoolId: _getProPoolId(),
    ClientId: _getProClientId(),
    Storage: BasStorage.getStorageHandle()
  }

  var Q_LIST_MY_PROJECTS = BAS_LIVE_ACCOUNT.Q_LIST_MY_PROJECTS
  var Q_LINK_REQUEST = BAS_LIVE_ACCOUNT.Q_LINK_REQUEST
  var M_UNLINK_PROJECT = BAS_LIVE_ACCOUNT.M_UNLINK_PROJECT
  var Q_ICE_SERVERS = BAS_LIVE_ACCOUNT.Q_ICE_SERVERS
  var S_LIST_MY_PROJECTS = BAS_LIVE_ACCOUNT.S_LIST_MY_PROJECTS
  var S_PROJECT_STATUS = BAS_LIVE_ACCOUNT.S_PROJECT_STATUS
  var S_ANSWERS = BAS_LIVE_ACCOUNT.S_ANSWERS
  var S_ICE_CANDIDATES = BAS_LIVE_ACCOUNT.S_ICE_CANDIDATES
  var M_SEND_OFFER = BAS_LIVE_ACCOUNT.M_SEND_OFFER
  var M_SEND_ICE_CANDIDATE = BAS_LIVE_ACCOUNT.M_SEND_ICE_CANDIDATE

  var _CHECK_LINK_STATE_DELAY_MS = 2000
  var _WAIT_FOR_RESET_SKIP_LIVE_MODAL_MS = _CHECK_LINK_STATE_DELAY_MS + 2000

  var _resetSkipLiveModalTimeoutId = 0
  var _checkLinkStateTimeoutId = 0

  var _userPool = null
  var _cognitoUser = null

  /**
   * @private
   * @type {Array<any>}
   */
  var _projectStatusInterests = []

  /**
   * Whether a subscription is being set up or not
   *
   * @private
   * @type {boolean}
   */
  var _projectStatusSubscriptionInProgress = false

  /**
   * Whether a subscription is active or not
   *
   * @private
   * @type {boolean}
   */
  var _projectStatusSubscriptionActive = false

  var _projectStatusProjectListIdContext = {}
  var _projectStatusProjectListObservable = null
  var _projectStatusProjectListSubscription = null

  var _projectStatusRetryTimeoutId = 0
  var _PROJECT_STATUS_ERR_RETRY_TIMEOUT_MS = 5 * 1000
  var _PROJECT_STATUS_ERR_WAF_RETRY_TIMEOUT_MS = 2 * 60 * 1000

  var _jwtTokenRefreshTimeoutId = 0

  var _linkServerModal = null
  var _unlinkServerModal = null

  var _fcmToken = ''

  /**
   * @private
   * @type {?Object}
   */
  var _notificationModal = null

  /**
   * @private
   * @type {?BasAppSyncClient}
   */
  var _appSyncClient = null

  /**
   * @private
   * @type {?Object}
   */
  var _appSyncEventsSubscription = null

  /**
   * @private
   * @type {?Promise}
   */
  var _graphQlClientPromise = null

  /**
   * @private
   * @type {?Promise}
   */
  var _getSessionPromise = null

  /**
   * @private
   * @type {?Promise}
   */
  var _listProjectsPromise = null

  /**
   * @private
   * @type {?Promise}
   */
  var _lastListProjectsPromise = null

  /**
   * @private
   * @type {?Promise}
   */
  var _checkPromise = null

  /**
   * @private
   * @type {?BasInAppBrowser}
   */
  let _inAppBrowser = null

  /**
   * @type {BasAppDeviceState}
   */
  var basAppDeviceState = BasAppDevice.get()

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {TBasLiveServerState}
   */
  var serverState = {}

  /**
   * @type {number}
   */
  var syncHasNotificationPermissionIntervalId = 0

  /**
   * @type {TBasLiveAccountState}
   */
  var state = {}

  this.get = get
  this.getAppSyncClient = getAppSyncClient
  this.getServerState = getServerState
  this.getCognitoUser = getCognitoUser
  // Get the JWT used for authenticating with a WebSocket/DataChannel
  this.getJWT = getJWT

  // Get the "raw" JWTs for e.g. passing credentials
  //  to another context (e.g. basalte.live)
  this.getIdToken = getIdToken
  this.getAccessToken = getAccessToken
  this.getRefreshToken = getRefreshToken

  this.check = check

  this.signUp = signUp
  this.confirmRegistration = confirmRegistration
  this.resendConfirmation = resendConfirmation
  this.login = login
  this.logout = logout
  this.showLogoutModal = showLogoutModal
  this.forgotPassword = forgotPassword
  this.confirmNewPassword = confirmNewPassword
  this.changePassword = changePassword
  this.saveContinueWithoutLive = saveContinueWithoutLive

  this.listProjects = listProjects
  this.getLastListProjects = getLastListProjects
  this.linkServer = linkServer
  this.unlinkServer = unlinkServer
  this.registerFcmToken = registerFcmToken
  this.unregisterFcmToken = unregisterFcmToken
  this.subscribeToProjectStatus = subscribeToProjectStatus

  this.registerForProjectStatus = registerForProjectStatus
  this.unregisterForProjectStatus = unregisterForProjectStatus

  this.getAnyOnlineProject = getAnyOnlineProject
  this.getOnlineProject = getOnlineProject

  this.toggleShowBackButton = toggleShowBackButton

  this.openManageWindow = openManageWindow

  this.isUsingDevEnvironment = isUsingDevLiveEnvironment

  // WebRTC signaling
  this.getIceServers = getIceServers
  this.subscribeToAnswers = subscribeToAnswers
  this.subscribeToIceCandidates = subscribeToIceCandidates
  this.sendIceCandidate = sendIceCandidate
  this.sendOffer = sendOffer

  init()

  function init () {

    serverState.liveAvailable = false
    serverState.linked = false
    serverState.linkedToMe = false
    serverState.owner = ''

    state.isRegistering = false
    state.isVerifying = false
    state.isResetting = false
    state.isSendingConfirmation = false
    state.isChangingPassword = false
    state.isLoggingIn = false
    state.isGettingSession = false
    state.isPerformingAnAction = false
    state.isLoggedIn = false
    state.session = null
    state.email = ''
    state.username = ''
    state.uiEmail = '-'
    state.uiProjects = []
    state.uiProjectsAll = []
    state.uiProjectsOnline = []
    state.projects = {}
    state.projectsCheckedTime = 0
    state.projectsChecked = false
    state.isJWTOverridden = false
    state.css = {}
    _resetCss()

    _syncFromStorage()

    _syncHasNotificationPermission()

    _userPool = new AmazonCognitoIdentityJs.CognitoUserPool(
      basAppDeviceState.isProLiveHosted
        ? PRO_POOL_DATA
        : HOME_POOL_DATA
    )

    if ($window.basFirebase) {

      if ($window.basFirebase.addTokenRefreshListener) {

        $window.basFirebase.addTokenRefreshListener(_onFcmTokenRefresh)
      }

      if ($window.basFirebase.addMessageReceivedListener) {

        $window.basFirebase.addMessageReceivedListener(_onFcmMessage)
      }
    }

    $rootScope.$on(
      BAS_APP.EVT_RESUME,
      _onResume
    )

    $rootScope.$on(
      BAS_APP.EVT_PAUSE,
      _onPause
    )

    $rootScope.$on(
      BAS_APP.EVT_NETWORK_CONNECTION_CHANGED,
      _onNetworkConnectionChanged
    )

    $rootScope.$on(
      BAS_APP.EVT_NETWORK_CONNECTION_OFFLINE,
      _onNetworkConnectionOffline
    )

    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CURRENT_CORE_CHANGED,
      _onCurrentCoreChanged
    )

    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_CORE_CONNECTED,
      _onCurrentCoreCoreConnected
    )

    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_LIVE_INFO,
      _onCoreLiveInfo
    )

    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_IS_ADMIN,
      _onCoreIsAdmin
    )

    $rootScope.$on(
      BAS_PREFERENCES.EVT_ENABLE_NOTIFICATIONS_CHANGED,
      _onPreferenceEnableNotificationsChanged
    )
  }

  /**
   * @returns {TBasLiveAccountState}
   */
  function get () {

    return state
  }

  /**
   * @returns {?BasAppSyncClient}
   */
  function getAppSyncClient () {

    return _appSyncClient
  }

  /**
   * @returns {TBasLiveServerState}
   */
  function getServerState () {

    return serverState
  }

  /**
   * Returns a BasLiveProject if any is online
   *
   * @returns {?BasLiveProject}
   */
  function getAnyOnlineProject () {

    var length, i, id, project

    length = state.uiProjectsOnline.length
    for (i = 0; i < length; i++) {

      id = state.uiProjectsOnline[i]

      if (id) {

        project = state.projects[id]
        if (project) return project
      }
    }

    return null
  }

  /**
   * Checks current collection of live projects for the given project id.
   *
   * @param {string} id Project id
   * @returns {?BasLiveProject}
   */
  function getOnlineProject (id) {

    var idx, project

    idx = state.uiProjectsOnline.indexOf(id)
    if (idx > -1) project = state.projects[id]

    return project || null
  }

  /**
   * TODO Find a better solution for hiding the back button while logging in.
   *
   * @param {boolean} value
   */
  function toggleShowBackButton (value) {

    state.css[CSS_BAS_LIVE_SHOW_BACK] = BasUtil.isBool(value)
      ? value
      : !state.css[CSS_BAS_LIVE_SHOW_BACK]
  }

  function openManageWindow () {
    _closeInAppBrowser()

    const url = isUsingDevLiveEnvironment()
      ? 'https://dev.basalte.live/account'
      : 'https://basalte.live/account'
    let loaded = false
    _inAppBrowser = new BasInAppBrowser(url, {
      onLoadStop: () => {
        // Sometimes 'loadstop' event is executed multiple times, only handle
        //  the first time
        if (loaded) return
        loaded = true

        // The in-app-browser has it's own separate storage, which we can not
        //  access directly. We do however have the ability to execute scripts
        //  in it -> via script execution we can transfer the needed values from
        //  (native) local storage. In browser, we don't use the in-app-browser
        //  due to Content-Secure-Policy restrictions, so no need to handle that
        const storageHandle = BasStorage.getStorageHandle()
        if (BasUtil.isFunction(storageHandle.keys)) {

          const keys = storageHandle.keys()

          const cognitoStorage = keys
            .filter(key => key.startsWith('CognitoIdentityServiceProvider'))
            .reduce((acc, curr) => {
              acc[curr] = storageHandle.getItem(curr)
              return acc
            }, {})
          const code =
            'window.localStorage.clear();\n' +
            Object.entries(cognitoStorage)
              .filter(([_key, value]) => BasUtil.isNEString(value))
              .map(([key, value]) => `window.localStorage.setItem(\`${key}\`, \`${value}\`);`)
              .join('\n') + '\n' +
            'window.location.reload();'
          _inAppBrowser.executeScript(code)
        }
      },
      onExit: () => {
        // We could have been logged out by an action in the in-app browser
        check()
      }
    })
  }

  /**
   * @returns boolean
   */
  function isUsingDevLiveEnvironment () {
    const envSetting = BasPreferences.getCloudEnvironment()

    switch (envSetting) {
      case BAS_PREFERENCES.CLOUD_ENVIRONMENT_AUTO:
        return BasAppDevice.isDevLiveEnvironment()
      case BAS_PREFERENCES.CLOUD_ENVIRONMENT_DEV:
        return true
      case BAS_PREFERENCES.CLOUD_ENVIRONMENT_PROD:
      default:
        return false
    }
  }

  function _closeInAppBrowser () {

    if (_inAppBrowser && _inAppBrowser.closeInAppBrowser) {

      _inAppBrowser.closeInAppBrowser()
      _inAppBrowser = null
    }
  }

  function _checkLinkState () {

    var _live, _basServer

    if (CurrentBasCore.hasCore()) {

      _live = currentBasCoreState.core.core.live
      _basServer = currentBasCoreState.core.core.server

      _syncLive(_live)

      if (basAppDeviceState.supportsBasalteLive &&
        _basServer &&
        !_basServer.isDemo() &&
        !_basServer.isRemote() &&
        state.isLoggedIn &&
        !_live.dirty &&
        _live.available &&
        !serverState.linked &&
        !BasProfilePreferences.getSkipLinkLiveModal() &&
        currentBasCoreState.core.core.supportsLive &&
        currentBasCoreState.core.core.profile &&
        currentBasCoreState.core.core.profile.admin) {

        linkServer()
      }
    }
  }

  function _checkLinkStateDelayed () {

    _clearLinkServerDelayedTimeout()
    _checkLinkStateTimeoutId = setTimeout(
      _checkLinkState,
      _CHECK_LINK_STATE_DELAY_MS
    )
  }

  /**
   * Shows modal to user after 2 seconds
   */
  function _clearLinkServerDelayedTimeout () {

    clearTimeout(_checkLinkStateTimeoutId)
    _linkServerModal = 0
  }

  /**
   * Shows modal to user
   */
  function linkServer () {

    if (
      basAppDeviceState.supportsBasalteLive &&
      CurrentBasCore.hasCore() &&
      serverState.liveAvailable &&
      !serverState.linked
    ) {

      BasUtilities.closeModal(_linkServerModal)
      _linkServerModal = null

      // Show modal
      BasModal.show(
        BAS_MODAL.T_LINK_SERVER,
        {
          subtitle: new BasString({
            literal: state.email
          })
        }
      ).then(_onLinkServerModal)
    }
  }

  function _onLinkServerModal (modal) {

    _linkServerModal = modal

    modal.close.then(_onLinkServerModalClose)
  }

  function _onLinkServerModalClose (result) {

    var _basServer, projectId

    _linkServerModal = null

    if (result === BAS_MODAL.C_YES) {

      if (CurrentBasCore.hasCore()) {

        _basServer = currentBasCoreState.core.core.server

        if (_basServer) {

          projectId = _basServer.cid

          if (projectId) {

            linkRequest(projectId).then(_onLinkRequest)

          } else {

            _basServer.getProjectInfo()
              .then(_onLinkServerProjectInfo)
          }
        }
      }

    } else if (result === BAS_MODAL.C_NO) {

      BasProfilePreferences.setSkipLinkLiveModal(
        true,
        currentBasCoreState.core
      )
    }
  }

  function _onLinkServerProjectInfo () {

    var _basServer, projectId

    if (CurrentBasCore.hasCore()) {

      _basServer = currentBasCoreState.core.core.server

      if (_basServer) {

        projectId = _basServer.cid

        if (projectId) {

          linkRequest(projectId).then(_onLinkRequest)
        }
      }
    }
  }

  /**
   * @private
   * @param {Object} result
   */
  function _onLinkRequest (result) {

    var _live

    if (CurrentBasCore.hasCore()) {

      _live = currentBasCoreState.core.core.live

      if (_live) {

        // Add 'cloudAccount' key to link request so server knows which
        //  code path to take
        _live.link({ ...result, cloudAccount: true })
          .then(_onServerLink, _onServerLinkError)
      }
    }
  }

  /**
   * @private
   * @param {Live} result
   */
  function _onServerLink (result) {

    _syncLive(result)

    // Refresh projects
    listProjects().catch(_empty)
  }

  function _onServerLinkError () {

    BasModal.show(
      BAS_MODAL.T_LINK_SERVER_ERROR
    ).catch(_empty)

    // Refresh projects
    listProjects().catch(_empty)
  }

  /**
   * Shows modal to user
   */
  function unlinkServer () {

    _clearResetSkipLiveModalTimeout()

    if (CurrentBasCore.hasCore()) {

      BasUtilities.closeModal(_unlinkServerModal)
      _unlinkServerModal = null

      // Show modal
      BasModal.show(
        BAS_MODAL.T_UNLINK_SERVER,
        {
          subtitle: new BasString({
            literal: state.email
          })
        }
      )
        .then(_onUnlinkServerModal)
    }
  }

  function _onUnlinkServerModal (modal) {

    _unlinkServerModal = modal

    modal.close.then(_onUnlinkServerModalClose)
  }

  function _onUnlinkServerModalClose (result) {

    var _basServer, projectId

    _unlinkServerModal = null

    if (result === BAS_MODAL.C_YES) {

      if (CurrentBasCore.hasCore()) {

        _basServer = currentBasCoreState.core.core.server

        if (_basServer) {

          projectId = _basServer.cid

          if (projectId) {

            _tempDisableSkipLiveModal()

            unlinkProject(projectId)
              .then(_onUnlinkProject, _onUnlinkProjectError)

          } else {

            _basServer.getProjectInfo()
              .then(_onUnLinkServerProjectInfo)
          }
        }
      }
    }
  }

  function _onUnLinkServerProjectInfo () {

    var _basServer, projectId

    if (CurrentBasCore.hasCore()) {

      _basServer = currentBasCoreState.core.core.server

      if (_basServer) {

        projectId = _basServer.cid

        if (projectId) {

          _tempDisableSkipLiveModal()

          unlinkProject(projectId)
            .then(_onUnlinkProject, _onUnlinkProjectError)
        }
      }
    }
  }

  function _onUnlinkProject () {

    // Refresh projects
    listProjects().catch(_empty)
  }

  function _onUnlinkProjectError () {

    BasModal.show(
      BAS_MODAL.T_UNLINK_SERVER_ERROR
    ).catch(_empty)

    // Refresh projects
    listProjects().catch(_empty)
  }

  function _tempDisableSkipLiveModal () {

    var _lastStoredServer, _serverSettings, _lastStoredUser, _userId

    _clearResetSkipLiveModalTimeout()

    _lastStoredServer = BasServerStorage.getLastServer()

    if (_lastStoredServer && _lastStoredServer.serverSettingsKey) {

      _serverSettings = BasServerStorage
        .getServerSettings(_lastStoredServer.serverSettingsKey)

      if (_serverSettings) {

        _lastStoredUser = _serverSettings.getLastUser()
      }
    }

    if (_lastStoredUser) {

      _userId =
        _lastStoredServer.getKey() +
        _lastStoredUser.name

      BasProfilePreferences.setSkipLinkLiveModal(
        true,
        currentBasCoreState.core
      )

      _resetSkipLiveModalTimeoutId = setTimeout(
        _onResetSkipLiveModalTimeout,
        _WAIT_FOR_RESET_SKIP_LIVE_MODAL_MS
      )
    }

    function _onResetSkipLiveModalTimeout () {

      var __lastStoredServer, __serverSettings, __lastStoredUser
      var __userId

      __lastStoredServer = BasServerStorage.getLastServer()

      if (__lastStoredServer && __lastStoredServer.serverSettingsKey) {

        __serverSettings = BasServerStorage
          .getServerSettings(__lastStoredServer.serverSettingsKey)

        if (__serverSettings) {

          __lastStoredUser = __serverSettings.getLastUser()
        }
      }

      if (__lastStoredUser) {

        __userId =
          __lastStoredServer.getKey() +
          __lastStoredUser.name

        if (_userId === __userId) {

          BasProfilePreferences.setSkipLinkLiveModal(
            false,
            currentBasCoreState.core
          )
        }
      }
    }
  }

  function _clearResetSkipLiveModalTimeout () {

    clearTimeout(_resetSkipLiveModalTimeoutId)
    _resetSkipLiveModalTimeoutId = 0
  }

  /**
   * @private
   * @param {Live} live
   */
  function _syncLive (live) {

    if (live) {

      serverState.liveAvailable = live.available

      if (!live.dirty) {

        serverState.owner = live.ownerId
        serverState.linked = !!serverState.owner
        serverState.linkedToMe = serverState.owner === state.username
      }
    }
  }

  function _syncPerformingActionState () {

    state.isPerformingAnAction = (
      state.isGettingSession ||
      state.isLoggingIn ||
      state.isRegistering ||
      state.isVerifying ||
      state.isResetting ||
      state.isSendingConfirmation ||
      state.isChangingPassword
    )
    state.css[CSS_BAS_LIVE_IS_PERFORMING_AN_ACTION] =
      state.isPerformingAnAction
  }

  /**
   * @param {string} username
   * @returns {AmazonCognitoIdentityJs.CognitoUser}
   */
  function getCognitoUser (username) {

    if (BasUtil.isNEString(username)) {

      if (_cognitoUser && _cognitoUser.getUsername() === username) {

        return _cognitoUser

      } else {

        return new AmazonCognitoIdentityJs.CognitoUser({
          Username: username,
          Pool: _userPool,
          Storage: BasStorage.getStorageHandle()
        })
      }
    }

    return null
  }

  /**
   * @returns {BasOverrideJWT}
   */
  function getJWT () {

    Logger.debug('JWT - getJWT')

    // JWT from query param (from Studio)
    const overrideJwt = _getOverrideJWT()
    if (overrideJwt) {
      Logger.debug('JWT - using override JWT')
      return {
        token: overrideJwt,
        isOverride: true
      }
    }

    if (state.isLoggedIn) {
      const token = state.session?.getAccessToken()?.getJwtToken()
      if (!token || !state.session.isValid()) {
        Logger.debug('JWT - session not valid, refreshing JWT')
        // Try refreshing the token in the background
        check().catch(_empty)
      }

      if (!token) {
        Logger.warn('JWT - could not get token from Cognito, using cached JWT')
        return {
          token: getLastValidJWT(),
          isOverride: false
        }
      }

      Logger.debug('JWT - using cognito JWT')
      return {
        token: token,
        isOverride: false
      }
    }

    return {
      token: null,
      isOverride: false
    }
  }

  function getIdToken () {

    return state.session?.getIdToken().jwtToken
  }

  function getAccessToken () {

    return state.session?.getAccessToken().jwtToken
  }

  function getRefreshToken () {

    return state.session?.getRefreshToken().token
  }

  /**
   * @returns {Promise}
   */
  function check () {

    if (_checkPromise) return _checkPromise

    _checkPromise = _check()

    _checkPromise.then(_clearCheckPromise, _clearCheckPromise)

    return _checkPromise
  }

  function _clearCheckPromise () {

    _checkPromise = null
  }

  /**
   *
   * @param {CognitoUserSession} session
   * @returns {Promise}
   */
  function _refreshSession (session) {
    return new Promise((resolve, reject) => {
      Logger.debug('JWT - refreshing session')
      _cognitoUser.refreshSession(
        session.getRefreshToken(),
        (refreshErr, refreshResult) => {
          Logger.debug(
            'JWT - refresh session result',
            refreshErr,
            refreshResult
          )
          if (refreshErr) return reject(refreshErr)
          if (refreshResult) {
            // Schedule a token refresh 2 minutes before expiration
            const tokenExpiration = refreshResult
              .getAccessToken()
              .getExpiration()
            const twoMinutesInSecs = 2 * 60
            const refreshAt = tokenExpiration - twoMinutesInSecs
            const mSecsUntilRefresh =
              new Date(refreshAt * 1000) - Date.now()

            if (mSecsUntilRefresh > 0) {

              Logger.debug(
                'JWT - refresh token in',
                mSecsUntilRefresh / 1000 / 60,
                'mins'
              )

              _jwtTokenRefreshTimeoutId = setTimeout(
                () => check().catch(_empty),
                mSecsUntilRefresh
              )
            }

            return resolve(refreshResult)
          }
          return resolve()
        }
      )
    })
  }

  function _getOverrideJWT () {
    return new URLSearchParams(window.location.search).get('jwt')
  }

  /**
   * @returns {Promise}
   */
  function _check () {

    window.basTModule.tBeforeBasLiveCheck = Date.now()

    const overrideJWT = _getOverrideJWT()

    state.isJWTOverridden = !!overrideJWT

    if (overrideJWT) {

      state.isLoggedIn = true
      state.session = null
      state.email = ''
      state.username = ''
      state.uiEmail = '-'
      _onSessionFinished()

      return Promise.resolve()
    }

    _cognitoUser = _userPool.getCurrentUser()

    if (_cognitoUser) {

      state.isGettingSession = true
      state.css[CSS_BAS_LIVE_CHECKING_SESSION] = true

      _syncPerformingActionState()

      $rootScope.$emit(BAS_LIVE_ACCOUNT.EVT_LIVE_UI_STATE)

      // Flow
      //
      // First get session
      // - If cached and valid it will return the cached session.
      //   \-> Force refresh the session using refresh token from cached session
      //     - If session is revoked -> not logged in and emit revoked event
      //     - If network error -> consider as logged in and use JWT from local
      //       storage
      // - If cached and expired it will auto refresh the session
      //   - If session is revoked -> not logged in and emit revoked event
      //   - If network error -> consider as logged in and use JWT from local
      //     storage
      return _getSession()
        .then(
          session => {
            return _refreshSession(session).then(
              _onSession,
              _onSessionError
            )
          },
          _onSessionError
        )
        .finally(_onSessionFinished)

    } else {

      state.isLoggedIn = false
      state.session = null
      state.email = ''
      state.username = ''
      state.uiEmail = '-'

      _clearGraphQlClient()
      _clearSubscriptions()
      _syncUiLoginState()

      $rootScope.$emit(BAS_LIVE_ACCOUNT.EVT_AUTH_STATE_CHECKED)

      window.basTModule.tBasLiveCheckDone = Date.now()

      return Promise.resolve()
    }

    /**
     * @private
     * @param {AmazonCognitoIdentityJs.CognitoUserSession} result
     * @returns {Promise}
     */
    function _onSession (result) {
      state.isLoggedIn = true
      state.session = result
      state.email = ''
      state.username = ''
      state.uiEmail = '-'

      setLastValidJWT(result.getAccessToken()?.getJwtToken() ?? '')

      window.basTModule.tBasLiveSessionCheckDone = Date.now()

      return _onValidSession().catch(_empty)
    }

    function _onSessionError (error) {
      switch (error?.code) {
        case 'NotAuthorizedException':
          // JWT Revoked
          _clearSession()
          setLastValidJWT('')
          $rootScope.$emit(BAS_LIVE_ACCOUNT.EVT_NOT_AUTHORIZED)
          break
        case 'NetworkError':
          // No network, logged in if we have a saved JWT
          state.isLoggedIn = !!getLastValidJWT()
          state.session = null
          state.email = ''
          state.username = ''
          state.uiEmail = '-'
          break
        default:
          _clearSession()
          break
      }
    }

    /**
     * @private
     */
    function _clearSession () {

      window.basTModule.tBasLiveSessionCheckDone = Date.now()

      state.isLoggedIn = false
      state.session = null
      state.email = ''
      state.username = ''
      state.uiEmail = '-'

      _clearGraphQlClient()
    }

    function _onSessionFinished () {

      state.isGettingSession = false
      state.css[CSS_BAS_LIVE_CHECKING_SESSION] = false

      _syncUiLoginState()

      _syncPerformingActionState()

      $rootScope.$emit(BAS_LIVE_ACCOUNT.EVT_AUTH_STATE_CHECKED)

      window.basTModule.tBasLiveCheckDone = Date.now()
    }
  }

  /**
   * @private
   * @returns {Promise}
   */
  function _getSession () {

    if (_getSessionPromise) return _getSessionPromise

    if (_cognitoUser) {

      _getSessionPromise = new Promise(_getSessionPromiseConstructor)

      _getSessionPromise
        .then(_clearGetSessionPromise, _clearGetSessionPromise)

      return _getSessionPromise
    }

    return Promise.reject(new BasError(
      BAS_ERRORS.T_LIVE_AUTHENTICATION,
      _cognitoUser,
      'No Cognito user'
    ))
  }

  function _clearGetSessionPromise () {

    _getSessionPromise = null
  }

  function _getSessionPromiseConstructor (resolve, reject) {

    _cognitoUser.getSession(_onSession)

    /**
     * @private
     * @param {*} error
     * @param {AmazonCognitoIdentityJs.CognitoUserSession} session
     */
    function _onSession (error, session) {

      if (error) {

        reject(error)

      } else {

        if (session && session.isValid && session.isValid()) {

          resolve(session)

        } else {

          reject(new BasError(
            BAS_ERRORS.T_LIVE_AUTHENTICATION,
            session,
            'Invalid session'
          ))
        }
      }
    }
  }

  /**
   * @param {string} email
   * @param {string} password
   * @param callback
   */
  function signUp (email, password, callback) {

    var attributes, _attributes, length, i, _attribute

    state.isRegistering = true
    state.css[CSS_BAS_LIVE_REGISTERING] = true

    _syncPerformingActionState()

    $rootScope.$emit(BAS_LIVE_ACCOUNT.EVT_LIVE_UI_STATE)

    attributes = [
      {
        Name: 'email',
        Value: email
      }
    ]

    _attributes = []

    if (Array.isArray(attributes)) {

      length = attributes.length
      for (i = 0; i < length; i++) {

        _attribute = attributes[i]

        if (BasUtil.isObject(_attribute) &&
          BasUtil.isNEString(_attribute.Name) &&
          BasUtil.isNEString(_attribute.Value)) {

          _attributes.push(
            new AmazonCognitoIdentityJs.CognitoUserAttribute(
              _attribute
            )
          )
        }
      }
    }

    _userPool.signUp(
      email,
      password,
      _attributes,
      null,
      _onSignUp
    )

    function _onSignUp (error, result) {

      state.isRegistering = false
      state.css[CSS_BAS_LIVE_REGISTERING] = false

      _syncPerformingActionState()

      if (BasUtil.isFunction(callback)) callback(error, result)

      $rootScope.$emit(BAS_LIVE_ACCOUNT.EVT_LIVE_UI_STATE)
    }
  }

  /**
   * @param {AmazonCognitoIdentityJs.CognitoUser} cognitoUser
   * @param {string} code
   * @param callback
   */
  function confirmRegistration (cognitoUser, code, callback) {

    if (cognitoUser) {

      state.isVerifying = true
      state.css[CSS_BAS_LIVE_VERIFYING] = true

      _syncPerformingActionState()

      $rootScope.$emit(BAS_LIVE_ACCOUNT.EVT_LIVE_UI_STATE)

      cognitoUser.confirmRegistration(
        code,
        false,
        _onConfirmation
      )

    } else {

      if (BasUtil.isFunction(callback)) callback('Invalid user')
    }

    function _onConfirmation (error, result) {

      state.isVerifying = false
      state.css[CSS_BAS_LIVE_VERIFYING] = false

      _syncPerformingActionState()

      if (BasUtil.isFunction(callback)) callback(error, result)

      $rootScope.$emit(BAS_LIVE_ACCOUNT.EVT_LIVE_UI_STATE)
    }
  }

  /**
   * @param {AmazonCognitoIdentityJs.CognitoUser} cognitoUser
   * @param callback
   */
  function resendConfirmation (cognitoUser, callback) {

    if (cognitoUser) {

      state.isSendingConfirmation = true
      _syncPerformingActionState()

      $rootScope.$emit(BAS_LIVE_ACCOUNT.EVT_LIVE_UI_STATE)

      cognitoUser.resendConfirmationCode(_onResendConfirmationCode)

    } else {

      if (BasUtil.isFunction(callback)) callback('Invalid user')
    }

    function _onResendConfirmationCode (error, result) {

      state.isSendingConfirmation = false
      _syncPerformingActionState()

      if (BasUtil.isFunction(callback)) callback(error, result)

      $rootScope.$emit(BAS_LIVE_ACCOUNT.EVT_LIVE_UI_STATE)
    }
  }

  /**
   * @param {string} username
   * @param {string} password
   * @param callback
   */
  function login (username, password, callback) {

    var _cbCalled
    var _authenticationData, _authenticationDetails
    var _userData

    _cbCalled = false

    state.isLoggingIn = true
    state.css[CSS_BAS_LIVE_LOGGING_IN] = true

    _syncPerformingActionState()

    $rootScope.$emit(BAS_LIVE_ACCOUNT.EVT_LIVE_UI_STATE)

    _authenticationData = {
      Username: username,
      Password: password
    }

    _userData = {
      Username: username,
      Pool: _userPool,
      Storage: BasStorage.getStorageHandle()
    }

    _authenticationDetails =
      new AmazonCognitoIdentityJs.AuthenticationDetails(
        _authenticationData
      )

    _cognitoUser = new AmazonCognitoIdentityJs.CognitoUser(_userData)

    _cognitoUser.authenticateUser(
      _authenticationDetails,
      {
        onSuccess: _onSuccess,
        onFailure: _onFailure,
        newPasswordRequired: _newPasswordRequired
      }
    )

    /**
     * @private
     * @param {AmazonCognitoIdentityJs.CognitoUserSession} session
     * @param {(boolean|undefined)} _userConfirmationNecessary
     */
    function _onSuccess (
      session,
      _userConfirmationNecessary
    ) {
      if (BasUtil.isObject(session) && session.isValid()) {

        state.isLoggedIn = true
        state.session = session

        setLastValidJWT(session.getAccessToken()?.getJwtToken() ?? '')

        _syncUiLoginState()

        _syncPerformingActionState()

        _onValidSession()
          .then(_onListProjects, _onListProjectsError)

      } else {

        state.isLoggedIn = false

        _syncUiLoginState()

        _syncPerformingActionState()

        _callback(session)
      }

      function _onListProjects () {

        _callback(null, session)
      }

      function _onListProjectsError () {

        _callback(null, session)
      }
    }

    function _onFailure (error) {

      if (BasUtil.isObject(error)) {

        if (error.code === BAS_LIVE_ACCOUNT.A_ERR_USER_NOT_AUTHORIZED) {

          _callback(BAS_LIVE_ACCOUNT.ERR_CREDENTIALS)

        } else {

          _callback(error)
        }

      } else {

        _callback(error)
      }
    }

    function _newPasswordRequired (userAttributes, requiredAttributes) {

      _callback(new BasError(
        BAS_ERRORS.T_AUTHENTICATION,
        {
          userAttributes: userAttributes,
          requiredAttributes: requiredAttributes
        },
        'New password required'
      ))
    }

    function _callback (error, result) {

      if (!_cbCalled) {

        _cbCalled = true

        state.isLoggingIn = false
        state.css[CSS_BAS_LIVE_LOGGING_IN] = false

        _syncPerformingActionState()

        if (BasUtil.isFunction(callback)) {

          callback(error, result)
        }

        $rootScope.$emit(BAS_LIVE_ACCOUNT.EVT_LIVE_UI_STATE)
      }
    }
  }

  /**
   * @param {boolean} [everywhere]
   * @returns {Promise}
   */
  function logout (everywhere) {

    var promise

    promise = retrieveFcmToken()
      .then(_unregisterFcmToken, _unregisterFcmToken)
      .then(_signOutCognitoUser, _signOutCognitoUser)

    state.isLoggedIn = false
    state.session = null
    state.email = ''
    state.username = ''
    state.uiEmail = '-'

    setLastValidJWT('')
    _clearSubscriptions()
    _clearProjects()
    _syncUiLoginState()

    $rootScope.$emit(BAS_LIVE_ACCOUNT.EVT_PROJECTS_UPDATED)
    $rootScope.$emit(BAS_LIVE_ACCOUNT.EVT_ACCOUNT_NAME_UPDATED)

    return promise

    /**
     * @private
     */
    function _signOutCognitoUser () {

      if (_cognitoUser) return _logOutPromise(everywhere)
    }
  }

  /**
   * @private
   * @param {boolean} everywhere
   * @param callback
   */
  function _logout (everywhere, callback) {

    _clearJwtTokenRefreshTimeout()

    if (_cognitoUser) {

      if (everywhere) {

        _cognitoUser.globalSignOut({
          onFailure: _onGlobalSignOutFailure,
          onSuccess: _onGlobalSignOutSuccess
        })

      } else {

        _cognitoUser.signOut(_onSignOut)
      }

    } else {

      _cb(new BasError(
        BAS_ERRORS.T_INTERNAL,
        _cognitoUser,
        'No Cognito user'
      ))
    }

    function _onSignOut () {

      _cognitoUser = null
      _cb(null)
    }

    function _onGlobalSignOutFailure (error) {

      if (_cognitoUser) _cognitoUser.signOut(_onSignOutAfterGlobal)

      function _onSignOutAfterGlobal () {

        _cognitoUser = null
        _cb(error)
      }
    }

    function _onGlobalSignOutSuccess () {

      if (_cognitoUser) _cognitoUser.signOut(_onSignOut)
    }

    function _cb (error) {

      if (BasUtil.isFunction(callback)) callback(error)
    }
  }

  /**
   * @private
   * @param {boolean} [everywhere]
   */
  function _logOutPromise (everywhere) {

    return new Promise(promiseConstructor)

    function promiseConstructor (resolve, reject) {

      _logout(everywhere, _onLogout)

      function _onLogout (error) {

        if (error) {
          reject(error)
        } else {
          resolve()
        }
      }
    }
  }

  function showLogoutModal () {

    BasModal.show(BAS_MODAL.T_ABOUT_TO_LOG_OUT).then(modal => {
      modal.close.then((result) => {

        if (result === BAS_MODAL.C_YES) {
          logout().then(() => {
            BasState.go(STATES.CONNECT_LIVE)
          })
        }
      })
    })
  }

  /**
   * @param {string} email
   * @param callback
   */
  function forgotPassword (email, callback) {

    if (email) {

      state.isResetting = true
      state.css[CSS_BAS_LIVE_RESETTING] = true

      _syncPerformingActionState()

      $rootScope.$emit(BAS_LIVE_ACCOUNT.EVT_LIVE_UI_STATE)

      new BasAppSyncClient(
        _getAppSyncLinkId(),
        _getAppSyncRegion()
      ).query(
        {
          query: `
            mutation forgotPasswordMutation($clientId: ID!, $email: String!) {
              forgotPassword(clientId: $clientId, email: $email)
            }`,
          variables: {
            clientId: _getHomeClientId(),
            email
          }
        },
        { multipleRequestOffsets: [] }
      ).then(_onForgotPasswordSuccess, _onForgotPasswordFailure)

    } else {

      if (BasUtil.isFunction(callback)) callback('Invalid user')
    }

    function _onForgotPasswordSuccess (data) {

      state.isResetting = false
      state.css[CSS_BAS_LIVE_RESETTING] = false

      _syncPerformingActionState()

      if (BasUtil.isFunction(callback)) {
        callback(data?.errors, data?.data?.forgotPassword)
      }

      $rootScope.$emit(BAS_LIVE_ACCOUNT.EVT_LIVE_UI_STATE)
    }

    function _onForgotPasswordFailure (error) {

      state.isResetting = false
      state.css[CSS_BAS_LIVE_RESETTING] = false

      _syncPerformingActionState()

      if (BasUtil.isFunction(callback)) callback(error)

      $rootScope.$emit(BAS_LIVE_ACCOUNT.EVT_LIVE_UI_STATE)
    }
  }

  /**
   * @param {AmazonCognitoIdentityJs.CognitoUser} cognitoUser
   * @param {string} verificationCode
   * @param {string} newPassword
   * @param callback
   */
  function confirmNewPassword (
    cognitoUser,
    verificationCode,
    newPassword,
    callback
  ) {

    if (cognitoUser) {

      state.isResetting = true
      state.css[CSS_BAS_LIVE_RESETTING] = true

      _syncPerformingActionState()

      $rootScope.$emit(BAS_LIVE_ACCOUNT.EVT_LIVE_UI_STATE)

      cognitoUser.confirmPassword(
        verificationCode,
        newPassword,
        {
          onSuccess: _onConfirmPasswordSuccess,
          onFailure: _onConfirmPasswordFailure
        }
      )

    } else {

      if (BasUtil.isFunction(callback)) callback('Invalid user')
    }

    function _onConfirmPasswordSuccess (data) {

      state.isResetting = false
      state.css[CSS_BAS_LIVE_RESETTING] = false

      _syncPerformingActionState()

      if (BasUtil.isFunction(callback)) callback(null, data)

      $rootScope.$emit(BAS_LIVE_ACCOUNT.EVT_LIVE_UI_STATE)
    }

    function _onConfirmPasswordFailure (error) {

      state.isResetting = false
      state.css[CSS_BAS_LIVE_RESETTING] = false

      _syncPerformingActionState()

      if (BasUtil.isFunction(callback)) callback(error)

      $rootScope.$emit(BAS_LIVE_ACCOUNT.EVT_LIVE_UI_STATE)
    }
  }

  /**
   * @param {string} oldPassword
   * @param {string} newPassword
   * @param callback
   */
  function changePassword (oldPassword, newPassword, callback) {

    if (state.isLoggedIn && _cognitoUser) {

      state.isChangingPassword = true
      _syncPerformingActionState()

      $rootScope.$emit(BAS_LIVE_ACCOUNT.EVT_AUTH_STATE_CHECKED)

      _cognitoUser.changePassword(
        oldPassword,
        newPassword,
        _onChangePassword
      )

    } else {

      if (BasUtil.isFunction(callback)) callback('Not logged in')
    }

    function _onChangePassword (error, result) {

      state.isChangingPassword = false
      _syncPerformingActionState()

      if (BasUtil.isFunction(callback)) callback(error, result)

      $rootScope.$emit(BAS_LIVE_ACCOUNT.EVT_AUTH_STATE_CHECKED)
    }
  }

  function saveContinueWithoutLive () {

    state.continueWithoutLive = true
    _syncToStorage()
  }

  /**
   * @param {TBasAppSyncQueryOptions} [options]
   * @returns {Promise}
   */
  function listProjects (
    options
  ) {
    if (_listProjectsPromise) return _listProjectsPromise

    if (_appSyncClient) {

      // TODO Do not always ask for metadata and images
      _listProjectsPromise = _appSyncClient.query(
        {
          query: 'query {\n' +
            '    ' + Q_LIST_MY_PROJECTS + ' {\n' +
            '        uuid\n' +
            '        online\n' +
            '        name\n' +
            '        images\n' +
            '           {\n' +
            '               variant\n' +
            '               url\n' +
            '           }\n' +
            '        metadata\n' +
            '           {\n' +
            '               location\n' +
            '                   {\n' +
            '                       city\n' +
            '                       country\n' +
            '                   }\n' +
            '           }\n' +
            (
              BasAppDevice.isProLive()
                ? '        integratorAccess\n'
                : ''
            ) +
            '    }\n' +
            '}'
        },
        options
      )
        .then(_onResult, _onError)
        .catch(_onAppSyncCheckForError)

      _lastListProjectsPromise = _listProjectsPromise

      _listProjectsPromise.then(
        _cleanUpPromise,
        _cleanUpPromise
      )

      return _listProjectsPromise
    }

    return Promise.reject(new Error('no AppSync client'))

    function _onResult (result) {

      var projects, length, i, project, uuid

      // No longer logged in, discard result
      if (!state.isLoggedIn) return

      Logger.debug('listProjects RESULT', result)

      _clearProjects()

      if (BasUtil.isObject(result) &&
        BasUtil.isObject(result.data)) {

        projects = result.data[Q_LIST_MY_PROJECTS]

        if (Array.isArray(projects)) {

          state.projectsCheckedTime = Date.now()
          state.projectsChecked = true

          length = projects.length
          for (i = 0; i < length; i++) {

            project = projects[i]

            if (BasUtil.isObject(project) &&
              BasUtil.isNEString(project.uuid)) {

              uuid = project.uuid

              state.projects[uuid] = new BasLiveProject(project)

              _syncProjectUi()
            }
          }
        }
      }

      state.css[CSS_BAS_LIVE_RETRIEVING_PROJECTS] = false

      $rootScope.$emit(BAS_LIVE_ACCOUNT.EVT_PROJECTS_UPDATED)
    }

    function _onError (error) {

      Logger.debug('listProjects ERROR', error)

      _clearProjects()

      state.css[CSS_BAS_LIVE_RETRIEVING_PROJECTS] = false

      $rootScope.$emit(BAS_LIVE_ACCOUNT.EVT_PROJECTS_UPDATED)

      return Promise.reject(error)
    }

    function _cleanUpPromise () {

      _listProjectsPromise = null
    }
  }

  /**
   * @param {TBasAppSyncQueryOptions} [options]
   * @returns {Promise}
   */
  function getLastListProjects (options) {
    if (_listProjectsPromise) return _listProjectsPromise
    if (_lastListProjectsPromise) return _lastListProjectsPromise
    return listProjects(options)
  }

  /**
   * @param {string} projectId
   * @param {TBasAppSyncQueryOptions} [options]
   * @returns {Promise}
   */
  function linkRequest (
    projectId,
    options
  ) {
    return _appSyncClient
      ? _appSyncClient.query(
        {
          query: 'query(' +
            '          $project: ID!' +
            '      ) {\n' +
            '    ' + Q_LINK_REQUEST + '(' +
            '              project: $project' +
            '          ) {\n' +
            '        username' +
            '        project' +
            '        timestamp' +
            '        hash' +
            '    }\n' +
            '}',
          variables: {
            project: projectId
          }
        },
        options
      )
        .then(_onResult)
        .catch(_onAppSyncCheckForError)
      : Promise.reject(new Error('no AppSync client'))

    function _onResult (result) {

      var obj

      if (BasUtil.isObject(result) &&
        BasUtil.isObject(result.data)) {

        obj = result.data[Q_LINK_REQUEST]

        if (BasUtil.isObject(obj)) return obj
      }

      return Promise.reject(new Error('invalid result'))
    }
  }

  /**
   * @param {string} projectId
   * @param {TBasAppSyncQueryOptions} [options]
   * @returns {Promise}
   */
  function unlinkProject (
    projectId,
    options
  ) {
    return _appSyncClient
      ? _appSyncClient.query(
        {
          query: 'mutation(' +
            '          $project: ID!' +
            '      ) {\n' +
            '    ' + M_UNLINK_PROJECT + '(' +
            '              project: $project' +
            '          )\n' +
            '}',
          variables: {
            project: projectId
          }
        },
        options
      )
        .then(_onResult)
        .catch(_onAppSyncCheckForError)
      : Promise.reject(new Error('no AppSync client'))

    function _onResult (result) {

      return (
        result &&
        result.data &&
        BasUtil.isBool(result.data[M_UNLINK_PROJECT])
      )
        ? result.data[M_UNLINK_PROJECT]
        : Promise.reject(new Error('invalid result'))
    }
  }

  /**
   * @param {TBasAppSyncQueryOptions} [options]
   * @returns {Promise}
   */
  function getIceServers (
    options
  ) {
    return _appSyncClient
      ? _appSyncClient.query(
        {
          query: 'query {\n' +
            '    ' + Q_ICE_SERVERS + ' {\n' +
            '        url\n' +
            '        urls\n' +
            '        username\n' +
            '        credential\n' +
            '    }\n' +
            '}'
        },
        options
      )
        .then(_onResult)
        .catch(_onAppSyncCheckForError)
      : Promise.reject(new Error('no AppSync client'))

    function _onResult (result) {

      return (
        result &&
        result.data &&
        Array.isArray(result.data[Q_ICE_SERVERS])
      )
        ? result.data[Q_ICE_SERVERS]
        : Promise.reject(new Error('invalid result'))
    }
  }

  /**
   * @param {string} uuid Connection identifier
   * @param {string} projectUuid Project identifier
   * @param {RTCSessionDescription} sdp
   * @param {Array} iceServers
   * @param {TBasAppSyncQueryOptions} [options]
   * @returns {Promise}
   */
  function sendOffer (
    uuid,
    projectUuid,
    sdp,
    iceServers,
    options
  ) {
    return _appSyncClient
      ? _appSyncClient.query(
        {
          query: 'mutation(' +
            '           $peerId: ID!, ' +
            '           $project: ID!, ' +
            '           $sdp: SdpMessageInput!, ' +
            '           $iceServers: [IceServerInput!]!' +
            '       ) {\n' +
            '    ' + M_SEND_OFFER + '(' +
            '               peerId: $peerId, ' +
            '               project: $project, ' +
            '               sdp: $sdp, ' +
            '               iceServers: $iceServers' +
            '            ) {\n' +
            '        sdp\n' +
            '        type\n' +
            '        peerId\n' +
            '    }\n' +
            '}',
          variables: {
            peerId: uuid,
            project: projectUuid,
            sdp: sdp,
            iceServers: iceServers
          }
        },
        options
      )
        .then(_onGraphCheckForErrors)
        .catch(_onAppSyncCheckForError)
      : Promise.reject(new Error('no AppSync client'))
  }

  /**
   * @param {string} uuid
   * @param {string} projectUuid
   * @param {RTCIceCandidate} iceCandidate
   * @param {TBasAppSyncQueryOptions} [options]
   * @returns {Promise}
   */
  function sendIceCandidate (
    uuid,
    projectUuid,
    iceCandidate,
    options
  ) {
    return _appSyncClient
      ? _appSyncClient.query(
        {
          query: 'mutation(' +
            '           $peerId: ID!, ' +
            '           $project: ID!, ' +
            '           $iceCandidate: IceCandidateInput!' +
            '       ) {\n' +
            '    ' + M_SEND_ICE_CANDIDATE + '(' +
            '               peerId: $peerId, ' +
            '               project: $project, ' +
            '               iceCandidate: $iceCandidate' +
            '           ) {\n' +
            '        candidate\n' +
            '        sdpMLineIndex\n' +
            '        usernameFragment\n' +
            '        peerId\n' +
            '    }\n' +
            '}',
          variables: {
            peerId: uuid,
            project: projectUuid,
            iceCandidate: {
              candidate: iceCandidate.candidate,
              sdpMid: iceCandidate.sdpMid,
              sdpMLineIndex: iceCandidate.sdpMLineIndex,
              usernameFragment: iceCandidate.usernameFragment
            }
          }
        },
        options
      )
        .then(_onGraphCheckForErrors)
        .catch(_onAppSyncCheckForError)
      : Promise.reject(new Error('no AppSync client'))
  }

  /**
   * @param {string} token
   * @param {TBasAppSyncQueryOptions} [options]
   * @returns {Promise}
   */
  function registerFcmToken (
    token,
    options
  ) {
    return _appSyncClient
      ? _appSyncClient.query(
        {
          query: 'mutation(' +
            '           $token: ID!' +
            '       ) {\n' +
            '    ' + BAS_LIVE_ACCOUNT.M_REGISTER_FCM_TOKEN + '(' +
            '               token: $token' +
            '           ) \n' +
            '}',
          variables: {
            token: token
          }
        },
        options
      )
        .catch(_onAppSyncCheckForError)
      : Promise.reject(new Error('no AppSync client'))
  }

  /**
   * @param {string} token
   * @param {TBasAppSyncQueryOptions} [options]
   * @returns {Promise}
   */
  function unregisterFcmToken (
    token,
    options
  ) {
    return _appSyncClient
      ? _appSyncClient.query(
        {
          query: 'mutation(' +
            '           $token: ID!' +
            '       ) {\n' +
            '    ' + BAS_LIVE_ACCOUNT.M_UNREGISTER_FCM_TOKEN + '(' +
            '               token: $token' +
            '           ) \n' +
            '}',
          variables: {
            token: token
          }
        },
        options
      )
        .catch(_onAppSyncCheckForError)
      : Promise.reject(new Error('no AppSync client'))
  }

  /**
   * @param {string} uuid
   * @param {?TBasAppSyncSubscribeOptions} [options]
   * @param {?TBasAppSyncSubscribeIdContext} [idContext]
   * @returns {?Object}
   */
  function subscribeToAnswers (
    uuid,
    options,
    idContext
  ) {
    return _appSyncClient
      ? _appSyncClient.subscribe(
        {
          query: 'subscription($peerId: ID!) {\n' +
            '    ' + S_ANSWERS + '(peerId: $peerId) {\n' +
            '        sdp\n' +
            '        type\n' +
            '        peerId\n' +
            '    }\n' +
            '}',
          variables: {
            peerId: uuid
          }
        },
        S_ANSWERS,
        options,
        idContext
      ).pipe(operators.map(_map))
      : null

    function _map (result) {

      return (result && result.data)
        ? result.data[S_ANSWERS]
        : undefined
    }
  }

  /**
   * @param {string} uuid
   * @param {?TBasAppSyncSubscribeOptions} [options]
   * @param {?TBasAppSyncSubscribeIdContext} [idContext]
   * @returns {?Object}
   */
  function subscribeToIceCandidates (
    uuid,
    options,
    idContext
  ) {
    return _appSyncClient
      ? _appSyncClient.subscribe(
        {
          query: 'subscription($peerId: ID!) {\n' +
            '    ' + S_ICE_CANDIDATES + '(peerId: $peerId) {\n' +
            '        candidate\n' +
            '        sdpMid\n' +
            '        sdpMLineIndex\n' +
            '        usernameFragment\n' +
            '        peerId\n' +
            '    }\n' +
            '}',
          variables: {
            peerId: uuid
          }
        },
        S_ICE_CANDIDATES,
        options,
        idContext
      ).pipe(operators.map(_map))
      : null

    function _map (result) {

      return (result && result.data)
        ? result.data[S_ICE_CANDIDATES]
        : undefined
    }
  }

  /**
   * Subscribe to project status events
   *
   * @private
   * @param {string} projectId
   * @param {?TBasAppSyncSubscribeOptions} [options]
   * @param {?TBasAppSyncSubscribeIdContext} [idContext]
   * @returns {?Object}
   */
  function subscribeToProjectStatus (
    projectId,
    options,
    idContext
  ) {
    return _appSyncClient
      ? _appSyncClient.subscribe(
        {
          query: 'subscription($projectId: ID!) {\n' +
            '    ' + S_PROJECT_STATUS + '(uuid: $projectId) {\n' +
            '        uuid\n' +
            '        name\n' +
            '        online\n' +
            (
              BasAppDevice.isProLive()
                ? '        integratorAccess\n'
                : ''
            ) +
            '    }\n' +
            '}',
          variables: {
            projectId: projectId
          }
        },
        S_PROJECT_STATUS,
        options,
        idContext
      ).pipe(operators.map(_map))
      : null

    function _map (result) {

      return (result && result.data)
        ? result.data[S_PROJECT_STATUS]
        : undefined
    }
  }

  /**
   * Subscribe to project list events
   *
   * @private
   * @param {string} ownerId
   * @param {?TBasAppSyncSubscribeOptions} [options]
   * @param {?TBasAppSyncSubscribeIdContext} [idContext]
   * @returns {?Object}
   */
  function subscribeToProjectList (
    ownerId,
    options,
    idContext
  ) {
    return _appSyncClient
      ? _appSyncClient.subscribe(
        {
          query: 'subscription($ownerId: ID!) {\n' +
            '    ' + S_LIST_MY_PROJECTS + '(owner: $ownerId) {\n' +
            '        uuid\n' +
            '        name\n' +
            '        online\n' +
            (
              BasAppDevice.isProLive()
                ? '        integratorAccess\n'
                : ''
            ) +
            '    }\n' +
            '}',
          variables: {
            ownerId: ownerId
          }
        },
        S_LIST_MY_PROJECTS,
        options,
        idContext
      ).pipe(operators.map(_map))
      : null

    function _map (result) {

      return (result && result.data)
        ? result.data[S_LIST_MY_PROJECTS]
        : undefined
    }
  }

  function _onGraphCheckForErrors (result) {

    var length, i, error

    if (Array.isArray(result.errors)) {

      length = result.errors.length
      for (i = 0; i < length; i++) {
        error = result.errors[i]

        if (error) {

          // Check for "Not Authorized" error
          if (error.message === GRAPH_ERR_NOT_AUTHORIZED) {
            return Promise.reject(new BasError(
              BAS_ERRORS.T_LIVE_NOT_AUTHORIZED,
              error,
              GRAPH_ERR_NOT_AUTHORIZED
            ))
          }

          // Check for "No integrator access" error
          if (error.message === GRAPH_ERR_NO_INTEGRATOR_ACCESS) {
            return Promise.reject(new BasError(
              BAS_ERRORS.T_LIVE_NO_INTEGRATOR_ACCESS,
              error,
              GRAPH_ERR_NO_INTEGRATOR_ACCESS
            ))
          }

          // Check for "No active servers found" error
          if (error.message === GRAPH_ERR_NO_ACTIVE_SERVERS_FOUND) {
            return Promise.reject(new BasError(
              BAS_ERRORS.T_LIVE_NO_ACTIVE_SERVERS_FOUND,
              error,
              GRAPH_ERR_NO_ACTIVE_SERVERS_FOUND
            ))
          }
        }
      }

      // General error check
      if (length && !result.data) {

        return Promise.reject(new BasError(
          BAS_ERRORS.T_INVALID_RESULT,
          result.errors
        ))
      }
    }

    return result
  }

  function _onAppSyncCheckForError (error) {

    return Promise.reject(
      (error && error.basType === BasAppSyncClient.ERR_WAF)
        ? new BasError(
          BAS_ERRORS.T_LIVE_WAF,
          error,
          BasAppSyncClient.ERR_WAF
        )
        : error
    )
  }

  /**
   * @private
   * @returns {(Promise|void)}
   */
  function _onValidSession () {

    // JWT token available via
    // getJWT()

    if (_cognitoUser) {

      state.css[CSS_BAS_LIVE_RETRIEVING_ACCOUNT_DETAILS] = true

      _cognitoUser.getUserAttributes(_onUserAttributes)
      state.username = _cognitoUser.username
    }

    if (!_appSyncClient) {

      if (!_graphQlClientPromise) {

        _graphQlClientPromise = _initGraphQlClient()
      }
    }

    return _appSyncClient
      ? _onValidSessionAppsyncClient()
      : _graphQlClientPromise.then(
        _onValidSessionAppsyncClient,
        _onValidSessionAppsyncClient
      )
  }

  /**
   * @private
   * @returns {Promise}
   */
  function _onValidSessionAppsyncClient () {

    var _firebase

    if (_appSyncClient) {

      if (BasPreferences.getEnableNotifications()) {

        // Notification permission

        _firebase = _getFirebasePlugin()

        if (_firebase && _firebase.grantPermission) {

          _firebase.grantPermission(
            _onGrantPermission,
            _onGrantPermissionError
          )
        }
      }

      retrieveFcmToken().then(
        _onValidSessionFcmToken,
        _onValidSessionFcmTokenError
      )

      _checkProjectStatusInterest()

      return listProjects().catch(_empty)
    }

    return Promise.reject(new Error('no AppSync client'))
  }

  function _onGrantPermission (result) {

    _syncHasNotificationPermission()

    Logger.info('_onGrantPermission', result)
  }

  function _onGrantPermissionError (error) {

    Logger.error('_onGrantPermissionError', error)
  }

  function _syncHasNotificationPermission () {

    var _firebase

    _firebase = _getFirebasePlugin()

    if (_firebase && _firebase.hasPermission) {

      _firebase.hasPermission(
        _onHasPermission,
        _onHasPermissionError
      )
    }
  }

  function _onHasPermission (result) {

    state.hasNotificationPermission = result
    state.css[CSS_BAS_LIVE_HAS_NOTIFICATION_PERMISSION] = result
  }

  function _onHasPermissionError (error) {

    state.hasNotificationPermission = false
    state.css[CSS_BAS_LIVE_HAS_NOTIFICATION_PERMISSION] = false

    Logger.error('_onHasPermissionError', error)
  }

  function _onValidSessionFcmToken () {

    _registerForNotifications()
  }

  function _onValidSessionFcmTokenError (error) {

    Logger.warn('Valid session FCM token ERROR', error)
  }

  function _onUserAttributes (error, result) {

    var length, i, item

    state.email = ''
    state.uiEmail = '-'

    if (error) {

      // Empty

    } else {

      if (Array.isArray(result)) {

        length = result.length
        for (i = 0; i < length; i++) {

          item = result[i]

          if (BasUtil.isObject(item)) {

            if (item.getName() === 'email') {

              state.email = item.getValue()
              state.uiEmail = state.email ? state.email : '-'
            }
          }
        }
      }
    }

    state.css[CSS_BAS_LIVE_RETRIEVING_ACCOUNT_DETAILS] = false

    $rootScope.$emit(BAS_LIVE_ACCOUNT.EVT_ACCOUNT_NAME_UPDATED)
  }

  /**
   * @param {*} instance
   */
  function registerForProjectStatus (instance) {

    var idx

    idx = _projectStatusInterests.indexOf(instance)
    if (idx < 0) {

      _projectStatusInterests.push(instance)
      _checkProjectStatusInterest()
    }
  }

  /**
   * @param {*} instance
   */
  function unregisterForProjectStatus (instance) {

    var idx

    idx = _projectStatusInterests.indexOf(instance)
    if (idx > -1) {

      _projectStatusInterests.splice(idx, 1)
      _checkProjectStatusInterest()
    }
  }

  function _checkProjectStatusInterest () {

    if (_projectStatusInterests.length) {

      if (!(
        _projectStatusSubscriptionInProgress &&
        _projectStatusSubscriptionActive
      )) {

        _startProjectStatusSubscription()
      }

    } else {

      _clearProjectStatusSubscription()
    }
  }

  function _checkProjectStatusActive () {

    if (_projectStatusInterests.length) {

      if (!_projectStatusSubscriptionActive) _startProjectStatusSubscription()

    } else {

      _clearProjectStatusSubscription()
    }
  }

  function _startProjectStatusSubscription () {

    if (
      state.isLoggedIn &&
      _appSyncClient &&
      !_projectStatusSubscriptionInProgress &&
      !_projectStatusSubscriptionActive
    ) {
      _projectStatusSubscriptionInProgress = true

      if (!_projectStatusProjectListObservable) {

        _projectStatusProjectListObservable =
          subscribeToProjectList(
            state.username,
            null,
            _projectStatusProjectListIdContext
          )
      }

      if (_projectStatusProjectListObservable) {

        _projectStatusProjectListSubscription =
          _projectStatusProjectListObservable.subscribe({
            next: _onProjectStatusProjectListNext,
            error: _onProjectStatusProjectListError
          })

      } else {

        _projectStatusSubscriptionInProgress = false
        _projectStatusSubscriptionActive = false
      }
    }
  }

  /**
   * @private
   * @param {Object} event
   */
  function _onProjectStatusProjectListSubscriptionEvent (event) {

    if (
      event &&
      event.event === BasAppSyncClient.SE_START &&
      event.id === _projectStatusProjectListIdContext.id
    ) {

      // If waiting for subscription and not yet active
      if (
        _projectStatusSubscriptionInProgress &&
        !_projectStatusSubscriptionActive
      ) {

        _projectStatusSubscriptionActive = true

        _clearProjectStatusRetryTimeout()

        if (_projectStatusInterests.length) listProjects().catch(_empty)
      }
    }
  }

  function _onProjectStatusProjectListNext (p) {

    var project

    _clearProjectStatusRetryTimeout()

    if (_projectStatusInterests.length < 1) return

    if (p && BasUtil.isNEString(p.uuid)) {

      project = state.projects[p.uuid]

      if (
        project &&
        project.setOnlineState &&
        project.online !== !!p.online
      ) {
        project.setOnlineState(p.online)
        _syncProjectUi()
        $rootScope.$emit(BAS_LIVE_ACCOUNT.EVT_PROJECTS_UPDATED)
      }
    }
  }

  function _onProjectStatusProjectListError (error) {

    _projectStatusSubscriptionActive = false

    _clearProjectStatusRetryTimeout()

    if (_projectStatusInterests.length) {

      if (error && error.basType === BasAppSyncClient.ERR_WAF) {

        // WAF error

        _projectStatusRetryTimeoutId = setTimeout(
          _checkProjectStatusActive,
          // Delayed retry with random offset
          _PROJECT_STATUS_ERR_WAF_RETRY_TIMEOUT_MS +
            (Math.random() * _PROJECT_STATUS_ERR_WAF_RETRY_TIMEOUT_MS)
        )

      } else {

        _projectStatusRetryTimeoutId = setTimeout(
          _checkProjectStatusActive,
          _PROJECT_STATUS_ERR_RETRY_TIMEOUT_MS
        )
      }
    }
  }

  function _clearProjectStatusRetryTimeout () {

    clearTimeout(_projectStatusRetryTimeoutId)
    _projectStatusRetryTimeoutId = 0
  }

  function _clearProjectStatusSubscription () {

    _projectStatusSubscriptionInProgress = false
    _projectStatusSubscriptionActive = false

    // Clear any retry timeouts
    _clearProjectStatusRetryTimeout()

    // Clear subscription
    if (_projectStatusProjectListSubscription) {
      _projectStatusProjectListSubscription.unsubscribe()
    }
    _projectStatusProjectListSubscription = null

    // Clear observable
    _projectStatusProjectListObservable = null
  }

  function _syncProjectUi () {

    var length, keys, i, project, uuid

    _clearUiProjects()

    keys = Object.keys(state.projects)
    length = keys.length
    for (i = 0; i < length; i++) {

      project = state.projects[keys[i]]

      if (project && BasUtil.isNEString(project.uuid)) {

        uuid = project.uuid

        state.uiProjectsAll.push(uuid)
        if (BasUtil.isNEString(project.name)) state.uiProjects.push(uuid)
        if (project.online) state.uiProjectsOnline.push(uuid)
      }
    }
  }

  function _onResume () {

    check().catch(_empty)
    _syncHasNotificationPermission()
    _setSyncHasNotificationPermissionInterval()
  }

  function _setSyncHasNotificationPermissionInterval () {

    if (BasAppDevice.supportsNotifications()) {

      _clearSyncHasNotificationPermissionInterval()

      syncHasNotificationPermissionIntervalId = setInterval(
        _syncHasNotificationPermission,
        10000
      )
    }
  }

  function _clearSyncHasNotificationPermissionInterval () {

    clearInterval(syncHasNotificationPermissionIntervalId)
    syncHasNotificationPermissionIntervalId = 0
  }

  function _onPause () {

    _clearSyncHasNotificationPermissionInterval()
    syncHasNotificationPermissionIntervalId = -1

    _clearSubscriptions()
  }

  function _onNetworkConnectionChanged () {

    listProjects().catch(_empty)
  }

  function _onNetworkConnectionOffline () {

    _clearProjects()
    $rootScope.$emit(BAS_LIVE_ACCOUNT.EVT_PROJECTS_UPDATED)
  }

  function _onCurrentCoreChanged () {

    _clearResetSkipLiveModalTimeout()

    _clearServerState()
  }

  function _onCurrentCoreCoreConnected (
    _event,
    _basCoreContainer,
    isConnected
  ) {

    if (!isConnected) _clearLinkServerDelayedTimeout()
  }

  function _onCoreLiveInfo () {

    _checkLinkStateDelayed()

    $rootScope.$applyAsync()
  }

  function _onCoreIsAdmin () {

    _checkLinkStateDelayed()
  }

  function _onPreferenceEnableNotificationsChanged () {

    _registerForNotifications()
  }

  function _onFcmTokenRefresh (token) {

    _fcmToken = BasUtil.isString(token) ? token : ''

    _registerForNotifications()
  }

  function _onFcmMessage (message) {

    Logger.info('FCM Message', message)

    if (BasUtil.isObject(message)) _handleFcmNotification(message)
  }

  function _registerForNotifications () {

    if (_fcmToken) {

      if (BasPreferences.getEnableNotifications()) {

        if (state.isLoggedIn) {

          registerFcmToken(_fcmToken).catch(_empty)

        } else if (
          !state.isLoggedIn &&
          !state.isLoggingIn &&
          !state.isGettingSession
        ) {

          unregisterFcmToken(_fcmToken).catch(_empty)
        }

      } else {

        unregisterFcmToken(_fcmToken).catch(_empty)
      }
    }
  }

  /**
   * @returns {Promise<string>}
   */
  function retrieveFcmToken () {

    return _getFcmToken().then(_onFcmTokenRetrieved)
  }

  /**
   * @private
   * @param {(string|null)} result
   * @returns {string}
   */
  function _onFcmTokenRetrieved (result) {

    return (_fcmToken = BasUtil.isString(result) ? result : '')
  }

  /**
   * @private
   * @returns {Promise<(string|null)>}
   */
  function _getFcmToken () {

    return new Promise(_fcmTokenPromiseConstructor)
  }

  function _fcmTokenPromiseConstructor (resolve, reject) {

    var _firebase

    _firebase = _getFirebasePlugin()

    if (_firebase && _firebase.getToken) {

      _firebase.getToken(resolve, reject)

    } else {

      reject(new Error('getToken not available'))
    }
  }

  /**
   * @private
   * @returns {Promise<boolean>}
   */
  function _unregisterFcmToken () {

    return _fcmToken
      ? unregisterFcmToken(_fcmToken)
      : Promise.resolve(true)
  }

  /**
   * @private
   * @param {Object} message
   */
  function _handleFcmNotification (message) {

    var _title, _body

    _clearNotificationModal()

    if (BasUtil.isObject(message)) {

      _title = ''
      _body = ''

      if (BasUtil.isString(message[K_TITLE])) _title = message[K_TITLE]
      if (BasUtil.isString(message[K_BODY])) _body = message[K_BODY]

      if (BasUtil.isObject(message[K_APS])) {

        if (BasUtil.isObject(message[K_APS][K_ALERT])) {

          if (BasUtil.isString(message[K_APS][K_ALERT][K_TITLE])) {

            _title = message[K_APS][K_ALERT][K_TITLE]
          }

          if (BasUtil.isString(message[K_APS][K_ALERT][K_BODY])) {

            _body = message[K_APS][K_ALERT][K_BODY]
          }
        }
      }

      if (!_title) {

        if (BasUtil.isNEString(message[K_NOTIFICATION_TITLE])) {

          _title = message[K_NOTIFICATION_TITLE]
        }
      }

      if (!_body) {

        if (BasUtil.isNEString(message[K_NOTIFICATION_BODY])) {

          _body = message[K_NOTIFICATION_BODY]
        }
      }

      if (_title || _body) {

        if (!_title) _title = BasUtilities.translate('notification')

        ModalService.showModal({
          template: BAS_HTML.notificationModal,
          controller: 'notificationModalCtrl',
          controllerAs: 'modal',
          inputs: {
            title: _title,
            body: _body
          },
          locationChangeSuccess: false
        }).then(_onNotificationModal)
      }
    }
  }

  function _onNotificationModal (modal) {

    _clearNotificationModal()

    _notificationModal = modal
  }

  /**
   * @private
   */
  function _clearNotificationModal () {

    if (_notificationModal &&
      _notificationModal.controller &&
      BasUtil.isFunction(_notificationModal.controller.close)) {

      _notificationModal.controller.close()
    }

    _notificationModal = null
  }

  function _onAppSyncSubscriptionNext (event) {

    _onProjectStatusProjectListSubscriptionEvent(event)

    if (event) {

      if (event.event === BasAppSyncClient.SE_START) {

        // Subscription started

        $rootScope.$emit(BAS_LIVE_ACCOUNT.EVT_SUBSCRIPTION_STARTED, event)
      }

      if (event.event === BasAppSyncClient.SE_COMPLETE) {

        // Subscription stopped

        $rootScope.$emit(BAS_LIVE_ACCOUNT.EVT_SUBSCRIPTION_STOPPED, event)
      }
    }
  }

  /**
   * @private
   * @returns {Promise}
   */
  function _initGraphQlClient () {

    if (_appSyncClient) return Promise.resolve()

    _clearAppSyncEventSubscription()

    _appSyncClient = new BasAppSyncClient(
      _getAppSyncLinkId(),
      _getAppSyncRegion(),
      _retrieveJwtToken
    )

    _appSyncEventsSubscription = _appSyncClient.subscriptionEvents.subscribe({
      next: _onAppSyncSubscriptionNext
    })

    return Promise.resolve()
  }

  /**
   * @private
   * @returns {Promise<string>}
   */
  function _retrieveJwtToken () {

    return _getSession().then(_onSessionForToken)
  }

  /**
   * @private
   * @param {CognitoUserSession} result
   * @returns {Promise}
   */
  function _onSessionForToken (result) {

    return result.getAccessToken().getJwtToken()
  }

  function _clearJwtTokenRefreshTimeout () {

    clearTimeout(_jwtTokenRefreshTimeoutId)
    _jwtTokenRefreshTimeoutId = 0
  }

  /**
   * @returns {TCloudEnvironment}
   * @private
   */
  function _getCloudEnvironment () {

    return CLOUD_ENVIRONMENTS[
      isUsingDevLiveEnvironment() ? K_ENV_DEV : K_ENV_PROD
    ]
  }

  /**
   * @private
   * @returns {string}
   */
  function _getAppSyncRegion () {
    return _getCloudEnvironment().appSyncRegion
  }

  /**
   * @private
   * @returns {string}
   */
  function _getAppSyncLinkId () {
    return _getCloudEnvironment().appSyncLinkId
  }

  /**
   * @private
   * @returns {string}
   */
  function _getHomePoolId () {
    return _getCloudEnvironment().homePoolId
  }

  /**
   * @private
   * @returns {string}
   */
  function _getHomeClientId () {
    return _getCloudEnvironment().homeClientId
  }

  /**
   * @private
   * @returns {string}
   */
  function _getProPoolId () {
    return _getCloudEnvironment().proPoolId
  }

  /**
   * @private
   * @returns {string}
   */
  function _getProClientId () {
    return _getCloudEnvironment().proClientId
  }

  function _clearAppSyncEventSubscription () {

    if (_appSyncEventsSubscription) _appSyncEventsSubscription.unsubscribe()
    _appSyncEventsSubscription = null
  }

  function _clearGraphQlClient () {

    _graphQlClientPromise = null

    _clearAppSyncEventSubscription()

    // Clear App Sync client
    if (_appSyncClient) {
      _appSyncClient.stop()
      _appSyncClient = null
    }
  }

  function _syncUiLoginState () {

    if (basAppDeviceState.supportsBasalteLive) {

      state.css[CSS_BAS_LIVE_LOGIN_SHOW] = !state.isLoggedIn
      state.css[CSS_BAS_LIVE_LOGOUT_SHOW] = state.isLoggedIn

    } else {

      state.css[CSS_BAS_LIVE_UNSUPPORTED_SHOW] = true
    }
  }

  /**
   * @returns {string|null}
   */
  function getLastValidJWT () {

    const lastValidJWT = BasStorage.get(
      BAS_LIVE_ACCOUNT.STORAGE_KEY_BAS_LIVE_LAST_JWT
    )

    if (BasUtil.isNEString(lastValidJWT)) return lastValidJWT

    return null
  }

  function setLastValidJWT (jwt) {
    if (BasUtil.isString(jwt)) {
      BasStorage.set(BAS_LIVE_ACCOUNT.STORAGE_KEY_BAS_LIVE_LAST_JWT, jwt)
    }
  }

  function _syncFromStorage () {

    const _basLiveState = BasStorage.get(
      BAS_LIVE_ACCOUNT.STORAGE_KEY_BAS_LIVE_STATE
    )

    if (BasUtil.isBool(_basLiveState?.continueWithoutLive)) {

      state.continueWithoutLive = _basLiveState.continueWithoutLive
    }
  }

  function _syncToStorage () {

    const _storageData = {
      continueWithoutLive: state.continueWithoutLive
    }

    BasStorage.set(
      BAS_LIVE_ACCOUNT.STORAGE_KEY_BAS_LIVE_STATE,
      _storageData
    )
  }

  function _clearServerState () {

    serverState = {}
    serverState.liveAvailable = false
    serverState.linked = false
    serverState.linkedToMe = false
    serverState.owner = ''
  }

  function _clearSubscriptions () {

    _clearProjectStatusSubscription()
  }

  function _clearProjects () {

    state.projects = {}

    _clearUiProjects()
  }

  function _clearUiProjects () {

    state.uiProjects = []
    state.uiProjectsAll = []
    state.uiProjectsOnline = []
  }

  function _resetCss () {

    state.css[CSS_BAS_LIVE_UNSUPPORTED_SHOW] = false
    state.css[CSS_BAS_LIVE_LOGIN_SHOW] = false
    state.css[CSS_BAS_LIVE_LOGOUT_SHOW] = false
    state.css[CSS_BAS_LIVE_CHECKING_SESSION] = false
    state.css[CSS_BAS_LIVE_RETRIEVING_ACCOUNT_DETAILS] = false
    state.css[CSS_BAS_LIVE_RETRIEVING_PROJECTS] = false
    state.css[CSS_BAS_LIVE_LOGGING_IN] = false
    state.css[CSS_BAS_LIVE_REGISTERING] = false
    state.css[CSS_BAS_LIVE_VERIFYING] = false
    state.css[CSS_BAS_LIVE_RESETTING] = false
    state.css[CSS_BAS_LIVE_IS_PERFORMING_AN_ACTION] = false
    state.css[CSS_BAS_LIVE_HAS_NOTIFICATION_PERMISSION] = false
    state.css[CSS_BAS_LIVE_SHOW_BACK] = true
  }

  function _getFirebasePlugin () {

    if (
      $window['basalteCordova'] &&
      $window['basalteCordova']['firebase']
    ) {
      return $window['basalteCordova']['firebase']
    }
  }

  function _empty () {
  }
}
