'use strict'

angular
  .module('basalteApp')
  .factory('BasSecurityAlarm', [
    'ICONS',
    'SECURITY',
    'BasUtilities',
    basSecurityAlarmFactory
  ])

/**
 * @param ICONS
 * @param SECURITY
 * @param BasUtilities
 * @returns BasSecurityAlarm
 */
function basSecurityAlarmFactory (
  ICONS,
  SECURITY,
  BasUtilities
) {

  // TODO: Ensure the naming of these properties aligns with the server-side
  //  implementation once alarms are supported.
  /**
   * @constructor
   * @param {TBasSecurityAlarm} alarm
   */
  function BasSecurityAlarm (alarm) {
    /**
     * @type {string}
     */
    this.name = ''

    /**
     * @type {boolean}
     */
    this.isArmed = false

    /**
     * @type {string}
     */
    this.type = ''

    /**
     * @type {string}
     */
    this.uiArmedState = ''

    /**
     * @type {string}
     */
    this.uiIcon = ''

    if (alarm) this.parse(alarm)
  }

  /**
   * @param {TBasSecurityAlarm} securityAlarm
   */
  BasSecurityAlarm.prototype.parse = function (securityAlarm) {

    this.name = securityAlarm.name
    this._syncAlarmState(securityAlarm.type)
  }

  /**
   * @param {string} type
   */
  BasSecurityAlarm.prototype.changeType = function (type) {

    this._syncAlarmState(type)
  }

  /**
   * @param {string} type
   */
  BasSecurityAlarm.prototype._syncAlarmState = function (type) {

    if (type === SECURITY.BAS_ALARM_STATE_GOOD_NIGHT) {

      this.isArmed = true
      this.uiArmedState = BasUtilities.translate('alarm_good_night')
      this.uiIcon = ICONS['shieldStay']
      this.type = type

    } else if (type === SECURITY.BAS_ALARM_STATE_AWAY) {

      this.isArmed = true
      this.uiArmedState = BasUtilities.translate('alarm_away')
      this.uiIcon = ICONS['shieldCheck']
      this.type = type

    } else if (type === SECURITY.BAS_ALARM_STATE_DISARMED) {

      this.isArmed = false
      this.uiArmedState = BasUtilities.translate('state_disarmed')
      this.uiIcon = ICONS['shield']
      this.type = type
    }
  }

  return BasSecurityAlarm
}
