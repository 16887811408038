'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasSecurity', [
    '$rootScope',
    'SECURITY',
    'BAS_INPUT_MODAL',
    'BAS_ROOMS',
    'BasInputModal',
    'BasSecurityAlarm',
    'BasUtilities',
    BasSecurity
  ])

/**
 * @typedef {Object} TBasSecurityState
 * @property {BasSecurityAlarm} alarm
 * @property {TBasSecurityRoom[]} rooms
 */

/**
 * @typedef {Object} TBasSecurityAlarm
 * @property {string} name
 * @property {boolean} isArmed
 * @property {string} type
 */

/**
 * @typedef {Object} TBasSecurityRoom
 * @property {string} uuid
 * @property {string} floorId
 * @property {string} name
 * @property {TBasSecurityOpenCloseDevice[]} openCloseDevices
 * @property {boolean} areDoorsOpen
 * @property {boolean} areWindowsOpen
 * @property {string} status
 * @property {boolean} hidden
 */

/**
 * @typedef {Object} TBasSecurityOpenCloseDevice
 * @property {string} uuid
 * @property {string} name
 * @property {boolean} opened
 * @property {string} type
 */

/**
 * @constructor
 * @param $rootScope
 * @param {SECURITY} SECURITY
 * @param {BAS_INPUT_MODAL} BAS_INPUT_MODAL
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BasInputModal} BasInputModal
 * @param {BasSecurityAlarm} BasSecurityAlarm
 * @param {BasUtilities} BasUtilities
 */
function BasSecurity (
  $rootScope,
  SECURITY,
  BAS_INPUT_MODAL,
  BAS_ROOMS,
  BasInputModal,
  BasSecurityAlarm,
  BasUtilities
) {

  const T_DOOR = 'door'
  const T_WINDOW = 'window'
  let selectedAlarmType = null
  let countdownInterval = null
  let countdown = 10
  /**
   * @type {TBasSecurityState}
   */
  const state = {}
  state.alarm = {}
  state.rooms = []

  this.get = get
  this.startCountdown = startCountdown
  this.cancelCountdown = cancelCountdown
  this.handleAlarm = handleAlarm
  this.changeAlarmType = changeAlarmType

  init()

  function init () {

    $rootScope.$on(
      '$translateChangeSuccess',
      _syncSecurity
    )

    _syncSecurity()
  }

  /**
   * @returns {TBasSecurityState}
   */
  function get () {

    return state
  }

  function startCountdown () {
    if (countdownInterval != null) return

    // Wait for 2 frames before triggering the first decrement
    // to ensure smoother initial rendering with setInterval().
    BasUtilities.waitForFrames(2, decrementCounter)
    countdownInterval = setInterval(decrementCounter, 1000)
  }

  function decrementCounter () {
    if (countdown < 0) {

      changeAlarmType()
      cancelCountdown()
      $rootScope.$applyAsync()

    } else {
      countdown--
      $rootScope.$emit(SECURITY.EVENT_COUNTDOWN_UPDATED, countdown)
    }
  }

  function cancelCountdown () {
    if (countdownInterval) {
      clearInterval(countdownInterval)
      countdownInterval = null
      countdown = 10
    }
  }

  /**
   * @private
   * @param {string} type
   */
  function handleAlarm (type) {

    if (_isAlarmTypeValid(type) && countdownInterval === null) {

      const showCircleProgress =
        type === SECURITY.BAS_ALARM_STATE_GOOD_NIGHT ||
        type === SECURITY.BAS_ALARM_STATE_AWAY

      selectedAlarmType = type

      BasInputModal.show(
        BAS_INPUT_MODAL.T_SECURITY_NUMBER,
        {
          title: BasUtilities.translate('enter_pin_code'),
          subTitle: state.alarm.name + ' ' + _translateToLowerCase('alarm'),
          min: 0,
          max: 10,
          showCircleProgress: showCircleProgress
        }
      )
    }
  }

  function changeAlarmType () {
    if (selectedAlarmType === state.alarm.type) return

    state.alarm.changeType(selectedAlarmType)
    selectedAlarmType = null
  }

  /**
   * @private
   */
  function _syncSecurity () {
    const basRooms = BAS_ROOMS.ROOMS.rooms
    const roomKeys = Object.keys(basRooms)
    state.rooms = []

    roomKeys.forEach(key => {
      const room = basRooms[key]

      if (room.openCloseDevices) {

        const securityRoom = {
          uuid: room.id,
          name: room.uiTitle,
          floorId: BasUtil.isNEString(room.floorId)
            ? basRooms[room.floorId].uiTitle
            : BasUtilities.translate('general'),
          openCloseDevices: [],
          areDoorsOpen: false,
          areWindowsOpen: false,
          status: 'OK',
          hidden: true
        }

        _setDoorsOrWindows(room, securityRoom)
      }
    })

    _setAlarm()
    state.rooms.sort((a, b) => a.floorId - b.floorId)
  }

  /**
   * @private
   * @param {BasRoom} room
   * @param {TBasSecurityRoom} securityRoom
   */
  function _setDoorsOrWindows (room, securityRoom) {
    const openCloseDevices = room.openCloseDevices.openCloseDevices

    Object.values(openCloseDevices).forEach(({ uuid, name, opened }) => {
      const device = { uuid, name, opened }

      // TODO: When security feature gets implemented, we should check on
      //  type of openClose device instead of name.
      if (name.includes('window') || name.includes('skylight')) {
        device.type = T_WINDOW
        securityRoom.openCloseDevices.push(device)
      } else {
        device.type = T_DOOR
        securityRoom.openCloseDevices.push(device)
      }
    })

    _setSecurityRoomStatus(securityRoom)

    state.rooms.push(securityRoom)
  }

  /**
   * @private
   */
  function _setAlarm () {
    // TODO: Make name dynamic, this will probably be based on studio config?
    const alarm = {
      name: 'Main House',
      isArmed: false,
      type: SECURITY.BAS_ALARM_STATE_DISARMED
    }

    state.alarm = new BasSecurityAlarm(alarm)
  }

  /**
   * @private
   * @param {TBasSecurityRoom} securityRoom
   */
  function _setSecurityRoomStatus (securityRoom) {
    const amountOfWindowsOpen = securityRoom.openCloseDevices
      .filter(openCloseDevice => {
        return openCloseDevice.type === T_WINDOW && openCloseDevice.opened
      })
      .length

    const amountOfDoorsOpen = securityRoom.openCloseDevices
      .filter(openCloseDevice => {
        return openCloseDevice.type === T_DOOR && openCloseDevice.opened
      })
      .length

    const areDoorsOpen = amountOfDoorsOpen > 0
    const areWindowsOpen = amountOfWindowsOpen > 0
    securityRoom.areDoorsOpen = areDoorsOpen
    securityRoom.areWindowsOpen = areWindowsOpen

    const doorText = amountOfDoorsOpen === 1
      ? _translateToLowerCase('door')
      : _translateToLowerCase('doors')
    const windowText = amountOfWindowsOpen === 1
      ? _translateToLowerCase('window')
      : _translateToLowerCase('windows')
    const openText = _translateToLowerCase('open')

    if (areDoorsOpen && areWindowsOpen) {

      securityRoom.status = `${amountOfDoorsOpen ?? ''} ${doorText} ${openText} , ${amountOfWindowsOpen ?? ''} ${windowText} ${openText}`

    } else if (areDoorsOpen) {

      securityRoom.status = `${amountOfDoorsOpen ?? ''} ${doorText} ${openText}`

    } else if (areWindowsOpen) {

      securityRoom.status = `${amountOfWindowsOpen ?? ''} ${windowText} ${openText}`

    } else {

      securityRoom.status = BasUtilities.translate('ok')
    }
  }

  /**
   * @private
   * @param {string} type
   * @returns boolean
   */
  function _isAlarmTypeValid (type) {
    return type === SECURITY.BAS_ALARM_STATE_GOOD_NIGHT ||
      type === SECURITY.BAS_ALARM_STATE_AWAY ||
      type === SECURITY.BAS_ALARM_STATE_DISARMED
  }

  /**
   * @private
   * @param {string} key
   * @returns string
   */
  function _translateToLowerCase (key) {
    return BasUtilities.translate(key).toLowerCase()
  }
}
