'use strict'

var BasUtil = require('@basalte/bas-util')

var CONSTANTS = require('./constants')
var P = require('./parser_constants')
var SPOTIFY = require('./spotify_constants')

var BasCoreSocketDemo = require('./bas_core_socket_demo')
var Temperature = require('./temperature')
var BasTrack = require('./bas_track')
var Scene = require('./scene')
var AudioSource = require('./audio_source')
var SharedServerStorage = require('./shared_server_storage')

var BasCrypto = require('./bas_crypto')
var requestUtil = require('./request_util')

var log = require('./logger')

var _DEMO_TICK_INTERVAL_MS = 500

var URI_PREFIX_TUNEIN = 'tunein:'
var URI_PREFIX_RADIO = 'radio:'
var URI_PREFIX_LOCAL_PLAYLIST = 'local:playlist:'
var URI_PREFIX_LOCAL_TRACK = 'local:track:'
var URI_PREFIX_LOCAL_ALBUM = 'local:album:'
var URI_PREFIX_LOCAL_ARTIST = 'local:artist:'

var KEY_LOGO = 'logo'

var _demoApiVersion = CONSTANTS.getHighestSupportedApiVersion()
var _demoHttpHeadersStr =
  P.H_X_BASALTE_API + ': ' + _demoApiVersion + '\r\n'
var _demoHttpHeadersObj = {}
_demoHttpHeadersObj[P.H_X_BASALTE_API] = _demoApiVersion

/**
 * @constructor
 * @param {Object} data
 * @since 2.0.0
 */
function Demo (data) {

  /**
   * @private
   * @type {BasCoreSocketDemo}
   */
  this._socket = new BasCoreSocketDemo()
  this._socket.handleServerSendInData = this.handleRequest.bind(this)

  /**
   * @private
   * @type {BasCoreSocketDemo}
   */
  this._v2Socket = new BasCoreSocketDemo()
  this._v2Socket.handleServerSendInData = this.handleV2Request.bind(this)

  this.version = {
    BL: 'Demo',
    COBRA: 'Demo',
    CONFIG: 'Demo',
    DSPAPP: 'Demo',
    KERNEL: 'Demo',
    KNX: 'Demo',
    PROGRAM: 'Demo',
    SYSTEM: 'Demo',
    WEBAPP: 'Demo',
    type: 'Demo'
  }

  this.project = {}
  this.project[P.CORE_NAME] = 'Demo Core'
  this.project[P.MASTER] = true
  this.project[P.NAME] = 'Demo'
  this.project[P.UUID] = Demo.DEMO_CID

  this.status = {}
  this.status[P.ERRORS] = []
  this.status[P.HAS_UPDATE] = false

  this.users = []
  this.profiles = {}
  this.system = null
  this.messages = []

  this.data = data

  this.queueId = 0
  this.songId = 0

  this._tickId = 0
  this._tickCount = 0

  this._lastSentMessages = null

  this._handleDemoTick = this._onDemoTick.bind(this)

  this.initializeData()
}

/**
 * @constant {number}
 */
Demo.DEMO_MAC_N = 1099511627776

/**
 * @constant {string}
 */
Demo.DEMO_MAC = '01:00:00:00:00:00'

/**
 * @constant {string}
 */
Demo.DEMO_HOST = 'Demo'

/**
 * @constant {string}
 */
Demo.DEMO_CID = 'demo'

/**
 * @private
 * @constant {string}
 */
Demo._DEF_USER_UUID = 'userDemo'

/**
 * @private
 * @constant {string}
 */
Demo._DEF_USER_USERNAME = 'Demo'

/**
 * @private
 * @constant {number}
 */
Demo._API_VERSION = _demoApiVersion

/**
 * @private
 * @constant {string}
 */
Demo._HTTP_HEADERS = _demoHttpHeadersStr

/**
 * @private
 * @constant {Object<string, (string|number)>}
 */
Demo._HTTP_HEADERS_OBJ = _demoHttpHeadersObj

/**
 * Checks whether the given MAC matches the Demo MAC address
 *
 * @param {(string|number)} mac
 * @returns {boolean}
 */
Demo.isDemoMac = function (mac) {

  if (BasUtil.isString(mac)) {

    return Demo.DEMO_MAC === mac

  } else if (BasUtil.isNumber(mac)) {

    return Demo.DEMO_MAC_N === mac
  }

  return false
}

/**
 * Convert Demo HTTP result to HTTP result from requestUtil.
 *
 * @param {*} result
 * @returns {Object}
 */
Demo.convertToHttpResponse = function (result) {

  return {
    status: 200,
    statusText: '',
    headers: Demo._HTTP_HEADERS,
    data: result
  }
}

/**
 * Copies properties ready for serving the user object.
 *
 * @private
 * @param {Object} user
 * @returns {Object}
 */
Demo._copyUser = function (user) {

  var newUser

  newUser = {}
  newUser[P.USERNAME] = user[P.USERNAME]

  if (P.DEFAULT in user) {
    newUser[P.DEFAULT] = user[P.DEFAULT]
  }
  if (P.AVATAR in user) {
    newUser[P.AVATAR] = user[P.AVATAR]
  }
  if (P.ADMIN in user) {
    newUser[P.ADMIN] = user[P.ADMIN]
  }
  if (P.PASSWORD in user) {
    newUser[P.PASSWORD] = user[P.PASSWORD]
  }

  return newUser
}

/**
 * @private
 * @param {Object} user
 * @returns {Object}
 */
Demo._generateProfile = function (user) {

  var newProfile

  newProfile = {}

  newProfile[P.UUID] = BasUtil.isNEString(user[P.UUID])
    ? user[P.UUID]
    : Demo._DEF_USER_UUID
  newProfile[P.USERNAME] = BasUtil.isNEString(user[P.USERNAME])
    ? user[P.USERNAME]
    : Demo._DEF_USER_USERNAME
  newProfile[P.ADMIN] = BasUtil.isBool(user[P.ADMIN])
    ? user[P.ADMIN]
    : false
  newProfile[P.DEFAULT] = BasUtil.isPNumber(user[P.DEFAULT])
    ? user[P.DEFAULT]
    : 0
  newProfile[P.PERMISSIONS] = BasUtil.isObject(user[P.PERMISSIONS])
    ? BasUtil.copyObject(user[P.PERMISSIONS])
    : {}

  return newProfile
}

/**
 * @param {Array} devices
 * @param {string} uuid
 * @returns {?Object}
 */
Demo.getDemoDevice = function (devices, uuid) {

  var i, length

  length = devices.length
  for (i = 0; i < length; i++) {

    if (devices[i][P.UUID] === uuid) return devices[i]
  }

  return null
}

/**
 * @param {Array} devices
 * @param {string} uuid
 * @returns {number}
 */
Demo.getDemoDeviceIndex = function (devices, uuid) {

  var i, length

  length = devices.length
  for (i = 0; i < length; i++) {

    if (devices[i][P.UUID] === uuid) return i
  }

  return -1
}

/**
 * @param {TRoomEqualiser[]} equalisers
 * @param {number} id
 * @returns {?TRoomEqualiser}
 */
Demo.getEqualiser = function (equalisers, id) {

  var length, i, eq

  length = equalisers.length
  for (i = 0; i < length; i++) {

    eq = equalisers[i]
    if (eq && eq[P.ID] === id) return eq
  }
  return null
}

/**
 * @param {TRoomEqualiser[]} equalisers
 * @param {number} id
 * @param {number} gain
 */
Demo.setEqualiserGain = function (
  equalisers,
  id,
  gain
) {
  var eq

  eq = Demo.getEqualiser(equalisers, id)
  if (eq) eq[P.GAIN] = gain
}

/**
 * @param {TRoomEqualiser[]} equalisers
 * @param {number} gain
 */
Demo.setAllEqualisers = function (equalisers, gain) {

  var length, i, eq

  length = equalisers.length
  for (i = 0; i < length; i++) {

    eq = equalisers[i]
    if (eq) eq[P.GAIN] = gain
  }
}

/**
 * @private
 * @param {number} limit
 * @param {number} tStart Start time
 * @param {number} tStop Stop time
 * @param {number} tStep Time interval in ms
 * @param {number} max
 * @param {number} min
 * @returns {Array<Array<(string|number)>>}
 */
Demo._generateEnergyData = function (
  limit,
  tStart,
  tStop,
  tStep,
  max,
  min
) {
  var result, i, tNew

  result = []

  tNew = tStart
  for (i = 0; i < limit && tNew <= tStop; i++) {

    result.push([
      BasUtil.toISO8601Extended(tNew),
      Math.floor(Math.random() * (max - min) + min)
    ])
    tNew = tStart + (i + 1) * tStep
  }

  return result
}

/**
 * The demo socket
 *
 * @name Demo#socket
 * @type {BasCoreSocketDemo}
 * @readonly
 * @since 3.0.0
 */
Object.defineProperty(Demo.prototype, 'socket', {
  get: function () {
    return this._socket
  }
})

/**
 * The demo V2 socket
 *
 * @name Demo#v2Socket
 * @type {BasCoreSocketDemo}
 * @readonly
 */
Object.defineProperty(Demo.prototype, 'v2Socket', {
  get: function () {
    return this._v2Socket
  }
})

/**
 * @private
 * @param {string} username
 * @returns {?Object}
 */
Demo.prototype._getUser = function (username) {

  var users, user, i, length

  if (BasUtil.isNEString(username)) {

    users = this.users

    if (Array.isArray(users)) {

      length = users.length
      for (i = 0; i < length; i++) {

        user = users[i]

        if (BasUtil.isObject(user) &&
          user[P.USERNAME] === username) {

          return user
        }
      }
    }
  }

  return null
}

Demo.prototype.initializeData = function () {

  this._initSystem()
  this._initMessages()
  this._initUsers()
  this._initPlaylists()
  this._initPresets()
  this._initQueues()
  this._initPlayers()
  this._initLocalSongs()
  this._initScenes()
  this._initAVSources()
  this._initCallHistory()
}

/**
 * Initialize user and profile info
 *
 * @private
 */
Demo.prototype._initUsers = function () {

  var users, i, length, user, newUser, _password, profile

  users = this.data.users

  if (Array.isArray(users)) {

    length = users.length
    for (i = 0; i < length; i++) {

      user = users[i]

      if (BasUtil.isObject(user)) {

        newUser = Demo._copyUser(user)

        // Make sure to set a salt
        newUser[P.SALT] = BasUtil.isString(user[P.SALT])
          ? user[P.SALT]
          : ''

        _password = BasUtil.isNEString(newUser[P.PASSWORD])
          ? newUser[P.PASSWORD]
          : ''

        newUser[P.HASH] = BasCrypto.sha256(
          newUser[P.USERNAME] +
          BasCrypto.sha256(
            newUser[P.SALT] +
            _password
          )
        )

        // Overwrite password
        if (_password) newUser[P.PASSWORD] = true

        this.users.push(newUser)

        // Generate profile

        profile = Demo._generateProfile(user)

        this.profiles[profile[P.UUID]] = profile
      }
    }
  }
}

Demo.prototype._initSystem = function () {

  this.system = this.data.system[P.SYSTEM]
}

Demo.prototype._initMessages = function () {

  this.messages = this.data.messages[P.MESSAGES]
}

/**
 * @private
 */
Demo.prototype._initPlaylists = function () {

  var i, length, p, a, keys, array
  var playlists, playerKeys, playlist

  playerKeys = Object.keys(this.data.playlists)

  length = playerKeys.length

  for (i = 0; i < length; i++) {
    playlists = this.data.playlists[playerKeys[i]]

    // Playlist types
    keys = Object.keys(playlists)

    for (p = 0; p < keys.length; p++) {

      // List of a type
      array = playlists[keys[p]]

      for (a = 0; a < array.length; a++) {

        // One playlist
        playlist = array[a]

        if (BasUtil.isObject(playlist)) {

          // Copy all info
          BasUtil.mergeObjects(
            playlist,
            this.data.playlistInfo[playlist[P.ID]]
          )

          // Amount of numbers
          playlist[P.SIZE] = playlist[P.SONGS].length
        }
      }
    }
  }
}

/**
 * @private
 */
Demo.prototype._initPresets = function () {

  var i, j, length, lengthJ, playerKeys, preset, array

  playerKeys = Object.keys(this.data.presets)
  length = playerKeys.length
  for (i = 0; i < length; i++) {

    array = this.data.presets[playerKeys[i]]
    lengthJ = array.length
    for (j = 0; j < lengthJ; j++) {

      preset = array[j]
      preset[P.ID] = j
    }
  }
}

/**
 * @private
 */
Demo.prototype._initQueues = function () {

  var i, length, playerKeys, key

  playerKeys = Object.keys(this.data.queue)
  length = playerKeys.length
  for (i = 0; i < length; i++) {

    key = playerKeys[i]
    if (BasUtil.isObject(this.data.queue[key])) {

      this.generateQueue(key)
    }
  }
}

/**
 * @private
 */
Demo.prototype._initPlayers = function () {

  var queues, players, i, length, player, queue, pos

  queues = this.data.queue
  players = this.data.players

  length = players.length
  for (i = 0; i < length; i++) {

    player = players[i]
    queue = queues[player[P.ID]]
    pos = BasUtil.isVNumber(queue[P.INIT_POS])
      ? queue[P.INIT_POS]
      : 0
    this.syncPlayerStatus(player[P.ID], pos)
  }
}

/**
 * @private
 */
Demo.prototype._initLocalSongs = function () {

  var songs, i, length, song

  songs = this.data.songs
  length = songs.length
  for (i = 0; i < length; i++) {

    song = songs[i]
    song[P.PRIO] = 0
    song[P.TRACK] = '' + i
    song[P.CONTENT_SRC] = 'local'
  }
}

/**
 * Converts readable json scene object to pb base64 string
 * Only do this once
 *
 * @private
 */
Demo.prototype._initScenes = function () {

  var devices, device, length, i
  var scenes, scene, length2, j
  var setPoint, temperature, step, length3, k

  devices = this.data.devices
  length = devices.length
  for (i = 0; i < length; i++) {

    device = devices[i]
    if (device[P.DEVICE] &&
      device[P.DEVICE][P.TYPE] &&
      device[P.DEVICE][P.TYPE] === P.SCENE_CONTROLLER &&
      device[P.DEVICE][P.SCENES] &&
      Array.isArray(device[P.DEVICE][P.SCENES])) {

      scenes = device[P.DEVICE][P.SCENES]
      length2 = scenes.length
      for (j = 0; j < length2; j++) {

        scene = scenes[j]

        // TODO do not change data object
        if (scene[P.CONTENT] &&
          !BasUtil.isString(scene[P.CONTENT])) {

          length3 = scene[P.CONTENT].length
          for (k = 0; k < length3; k++) {

            step = scene[P.CONTENT][k]

            if (step.target &&
              step.target.thermostat &&
              step.target.thermostat.setPoint) {

              setPoint = step.target.thermostat.setPoint
              temperature = new Temperature()

              temperature.setKelvin(setPoint._kelvin)

              step.target.thermostat.setPoint = temperature
            }
          }

          scene[P.CONTENT] =
            Scene.generateContent(scene[P.CONTENT])
        }
      }
    }
  }
}

/**
 * Sync state of all AV sources: state, capabilities
 *
 * @private
 */
Demo.prototype._initAVSources = function () {

  this.syncAVSourcesState()
}

/**
 * @private
 */
Demo.prototype._initCallHistory = function () {

  this.callHistory = this.data.callHistory
}

/**
 * @param {string} uuid
 * @returns {?Object}
 */
Demo.prototype.getDevice = function (uuid) {

  var i, length

  length = this.data.devices.length
  for (i = 0; i < length; i++) {

    if (this.data.devices[i][P.DEVICE][P.UUID] === uuid) {

      return this.data.devices[i][P.DEVICE]
    }
  }

  return null
}

/**
 * @private
 * @param {string} username
 * @returns {?Object}
 */
Demo.prototype._getProfile = function (username) {

  var keys, length, i, uuid, profile

  if (BasUtil.isNEString(username)) {

    keys = Object.keys(this.profiles)
    length = keys.length
    for (i = 0; i < length; i++) {

      uuid = keys[i]
      profile = this.profiles[uuid]

      if (BasUtil.isObject(profile) &&
        profile[P.USERNAME] === username) {

        return profile
      }
    }
  }

  return null
}

/**
 * @param {TBasServerRequestConfig} config
 * @returns {Promise<TBasServerResponse>}
 */
Demo.prototype.handleHttpRequest = function (config) {

  var _response, _path, _msg

  if (BasUtil.isObject(config)) {

    _path = config.path

    _response = {}
    _response.headers = Demo._HTTP_HEADERS_OBJ
    _response.apiVersion = Demo._API_VERSION
    _response.status = 404

    switch (_path) {
      case CONSTANTS.PATH_API_PROJECT:
        this.processRequestProject(_response, config)
        break
      case CONSTANTS.PATH_API_STATUS:
        this.processRequestStatus(_response, config)
        break
      case CONSTANTS.PATH_API_VERSION:
        this.processRequestVersion(_response, config)
        break
      case CONSTANTS.PATH_API_USERS:
        this.processRequestUsers(_response, config)
        break
      case CONSTANTS.PATH_API_LOGIN:
        this.processRequestLogin(_response, config)
        break
      case CONSTANTS.PATH_API_RESTART:
        this.processRequestRestart(_response, config)
        break
      case CONSTANTS.PATH_API_LOGOUT:
        this.processRequestLogout(_response, config)
        break
      case CONSTANTS.PATH_API_FORECAST:
        this.processRequestForecast(_response, config)
        break
      case CONSTANTS.PATH_API_RADIOS:
        this.processRequestRadios(_response, config)
        break
    }

    if (_response.status > 199 && _response.status < 300) {

      return Promise.resolve(_response)

    } else {

      _msg = requestUtil.parseErrorStatus(_response.status)

      return Promise.reject(_msg || _response)
    }
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * @param {TBasServerResponse} response
 * @param {TBasServerRequestConfig} config
 */
Demo.prototype.processRequestProject = function (response, config) {

  var _method

  _method = config.method || P.C_GET

  if (_method === P.C_GET) {

    response.status = 200
    response.data = this.project
  }
}

/**
 * @param {TBasServerResponse} response
 * @param {TBasServerRequestConfig} config
 */
Demo.prototype.processRequestStatus = function (response, config) {

  var _method

  _method = config.method || P.C_GET

  if (_method === P.C_GET) {

    response.status = 200
    response.data = this.status
  }
}

/**
 * @param {TBasServerResponse} response
 * @param {TBasServerRequestConfig} config
 */
Demo.prototype.processRequestVersion = function (response, config) {

  var _method

  _method = config.method || P.C_GET

  if (_method === P.C_GET) {

    response.status = 200
    response.data = this.version
  }
}

/**
 * @param {TBasServerResponse} response
 * @param {TBasServerRequestConfig} config
 */
Demo.prototype.processRequestUsers = function (response, config) {

  var _method, _responseData, users, user, i, length

  _method = config.method || P.C_GET

  if (_method === P.C_GET) {

    _responseData = []
    users = this.users

    length = users.length
    for (i = 0; i < length; i++) {

      user = users[i]

      if (BasUtil.isObject(user)) {

        _responseData.push(Demo._copyUser(user))
      }
    }

    response.status = 200
    response.data = _responseData
  }
}

/**
 * @param {TBasServerResponse} response
 * @param {TBasServerRequestConfig} config
 */
Demo.prototype.processRequestLogin = function (response, config) {

  var _method, _params, _data, _responseData
  var _user, _challenge, _cNonce, _hash
  var _userObj, _serverHash

  _method = config.method || P.C_GET
  _params = config.params
  _data = config.data

  if (_method === P.C_GET) {

    if (BasUtil.isObject(_params)) {

      _userObj = this._getUser(_params[P.U])

      if (_userObj) {

        _responseData = {}
        _responseData[P.CHALLENGE] =
          BasUtil.randomString(24)
        _responseData[P.SALT] = _userObj[P.SALT]
        _responseData[P.USER] = _userObj[P.USERNAME]

        response.status = 200
        response.data = _responseData

        return
      }
    }

    response.status = 403

  } else if (_method === P.C_POST) {

    if (BasUtil.isObject(_data)) {

      _user = _data[P.USER]
      _challenge = _data[P.CHALLENGE]
      _cNonce = _data[P.CNONCE]
      _hash = _data[P.HASH]

      _userObj = this._getUser(_user)

      if (_userObj) {

        _serverHash = BasCrypto.sha256(
          _userObj[P.HASH] + _challenge + _cNonce
        )

        if (_hash === _serverHash) {

          _responseData = {}
          _responseData[P.TOKEN] = P.LOGIN_OK
          _responseData[P.USER] = _userObj[P.USERNAME]

          response.status = 200
          response.data = _responseData

          return
        }
      }

      response.status = 403
    }
  }
}

/**
 * @param {TBasServerResponse} response
 * @param {TBasServerRequestConfig} config
 */
Demo.prototype.processRequestRestart = function (response, config) {

  var _method

  _method = config.method || P.C_GET

  if (_method === P.C_POST) {

    response.status = 200
    response.data = {}
  }
}

/**
 * @param {TBasServerResponse} response
 * @param {TBasServerRequestConfig} config
 */
Demo.prototype.processRequestLogout = function (response, config) {

  var _method

  _method = config.method || P.C_GET

  if (_method === P.C_POST) {

    response.status = 200
    response.data = {}
  }
}

/**
 * @param {TBasServerResponse} response
 * @param {TBasServerRequestConfig} config
 */
Demo.prototype.processRequestForecast = function (response, config) {

  var _method

  _method = config.method || P.C_GET

  if (_method === P.C_GET) {

    response.status = 200
    response.data = this.data.weather.data
  }
}

/**
 * @param {TBasServerResponse} response
 * @param {TBasServerRequestConfig} config
 */
Demo.prototype.processRequestRadios = function (response, config) {

  var _method

  _method = config.method || P.C_GET

  if (_method === P.C_GET) {

    response.status = 200
    response.data = this.data.radios
  }
}

/**
 * @param {Object} config
 * @param {string} config.path
 * @returns {Promise}
 */
Demo.prototype.handleSpotifyHTTP = function (config) {

  var userData, split

  userData = this.data.spotifyUsers

  if (BasUtil.isObject(config) &&
    BasUtil.isNEString(config[P.PATH])) {

    split = config[P.PATH].split('/')

    switch (split[0]) {

      case SPOTIFY.USERS:

        if (BasUtil.isNEString(split[1]) &&
          BasUtil.isObject(userData[split[1]])) {

          return Promise.resolve(userData[split[1]])
        }

        break

      case SPOTIFY.ME:

        return this.handleSpotifyMe(config)

      case SPOTIFY.BROWSE :

        return this.handleSpotifyBrowse(config)
    }
  }

  return Promise.reject(CONSTANTS.ERR_BAD_REQUEST)
}

/**
 * @param {Object} config
 * @param {string} config.path
 * @returns {Promise}
 */
Demo.prototype.handleSpotifyMe = function (config) {

  var userData, musicData, split, token

  userData = this.data.spotifyUsers
  musicData = this.data.spotifyMusic

  if (BasUtil.isObject(config) && BasUtil.isNEString(config[P.PATH])) {

    split = config[P.PATH].split('/')

    if (BasUtil.isNEString(split[1])) {

      switch (split[1]) {

        case SPOTIFY.PLAYLISTS:
          return Promise.resolve(musicData[P.PLAYLISTS])

        case SPOTIFY.ALBUMS:
          return Promise.resolve(musicData[P.ALBUMS])

        case SPOTIFY.FOLLOWING:
          return Promise.resolve(musicData[P.ARTISTS])

        case P.TRACKS:
          return Promise.resolve(musicData[P.SONGS])

        case P.PLAYER:

          if (split[2] === SPOTIFY.RECENTLY_PLAYED) {

            return Promise.resolve(musicData[P.RECENTLY])
          }
      }

    } else if (BasUtil.isObject(config[SPOTIFY.HEADERS]) &&
      BasUtil.isNEString(config[SPOTIFY.HEADERS][P.TOKEN])) {

      token = config[SPOTIFY.HEADERS][P.TOKEN]

      if (userData[token]) {

        return Promise.resolve(userData[token])
      }
    }
  }

  return Promise.reject(CONSTANTS.ERR_BAD_REQUEST)
}

/**
 * @param {Object} config
 * @param {string} config.path
 * @returns {Promise}
 */
Demo.prototype.handleSpotifyBrowse = function (config) {

  var musicData, split

  musicData = this.data.spotifyMusic

  if (BasUtil.isObject(config) && BasUtil.isNEString(config[P.PATH])) {

    split = config[P.PATH].split('/')

    switch (split[1]) {

      case SPOTIFY.CATEGORIES:

        if (!BasUtil.isNEString(split[2])) {

          return Promise.resolve(musicData[P.GENRES])
        }

        if (split[2] === SPOTIFY.TOPLISTS) {

          if (split[3] === SPOTIFY.PLAYLISTS) {

            return Promise.resolve(musicData[P.CHARTS])
          }

        }
        break

      case SPOTIFY.FEATURED_PLAYLISTS:

        return Promise.resolve(musicData[P.FEATURED])

      case SPOTIFY.NEW_RELEASES:

        return Promise.resolve(musicData[P.RELEASES])
    }
  }

  return Promise.reject(CONSTANTS.ERR_BAD_REQUEST)
}

/**
 * Handle WebSocket messages sent to the server (V2)
 *
 * @param {Object} request
 * @returns {boolean}
 */
Demo.prototype.handleV2Request = function (request) {

  const response = {}

  // Check for id
  if (BasUtil.isPNumber(request[P.ID], true)) {

    response[P.ID] = request[P.ID]
  }

  if (request[P.TOPIC] === P.DOOR_PHONE) {

    this.handleCallHistory(request, response)
  }

  // Send answer
  this.sendMessageV2(response)

  return true
}

/**
 * Handle WebSocket messages sent to the server
 *
 * @param {Object} request
 * @returns {boolean}
 */
Demo.prototype.handleRequest = function (request) {

  var response = {}

  // Check for id
  if (BasUtil.isPNumber(request[P.ID], true)) {

    response[P.ID] = request[P.ID]
  }

  // User
  if (BasUtil.isObject(request[P.USER])) {

    this.handleUser(request[P.USER], response)
  }

  // Player
  if (BasUtil.isObject(request[P.PLAYER])) {

    this.handlePlayer(request[P.PLAYER], response)
  }

  // Stream
  if (BasUtil.isObject(request[P.STREAM])) {

    this.handleStream(request[P.STREAM], response)
  }

  // Device
  if (BasUtil.isObject(request[P.DEVICE])) {

    this.handleDevice(request[P.DEVICE], response)
  }

  // Zone
  if (BasUtil.isObject(request[P.ZONE])) {

    this.handleZone(request[P.ZONE], response)
  }

  // Shared Server Storage
  if (BasUtil.isObject(request[P.STORAGE])) {

    this.handleSharedServerStorage(request[P.STORAGE], response)
  }

  // Room
  if (BasUtil.isObject(request[P.ROOM])) {

    this.handleRoom(request[P.ROOM], response)
  }

  // AV Source
  if (BasUtil.isObject(request[P.AV_SOURCE])) {

    this.handleAVSource(request[P.AV_SOURCE], response)
  }

  // Library
  if (BasUtil.isObject(request[P.LIBRARY])) {

    this.handleLibrary(request[P.LIBRARY], response)
  }

  // Send answer
  this.sendMessage(response)

  return true
}

/**
 * @param {TCoreCredentials} credentials
 * @returns {Promise} Resolved promise
 */
Demo.prototype.openSocket = function (credentials) {

  // Send connected

  this._socket.open()
  this._v2Socket.open()
  // This._basCore._emitConnected(true);

  // Send Initial messages

  this.sendInitialMessages(credentials)

  // Start demo ticker
  this._clearDemoTicker()
  this._tickId = setInterval(this._handleDemoTick, _DEMO_TICK_INTERVAL_MS)

  return Promise.resolve()
}

/**
 * @param {TCoreCredentials} credentials
 */
Demo.prototype.sendInitialMessages = function (credentials) {

  var msg, data

  // Profile + System

  msg = {}

  if (BasUtil.isObject(credentials)) {

    data = this._getProfile(credentials.user)

    if (BasUtil.isObject(data)) msg[P.PROFILE] = data
  }

  msg[P.SYSTEM] = this.system

  this.sendMessage(msg)

  // Rooms
  this.sendMessage(this.data.rooms)

  // Music
  this.sendMessage(this.data.music)

  // AV
  this.sendMessage(this.data.avSources)

  // Library
  this.sendLibraryState()

  // Devices
  this.sendDevices()

  // Messages
  this.sendMessages()
}

Demo.prototype.sendDevices = function () {

  var i, length, devices

  devices = this.data.devices

  length = devices.length
  for (i = 0; i < length; i++) {

    this.sendMessage(devices[i])
  }
}

Demo.prototype.sendMessages = function () {

  var msg, i, length, messages, message, condition
  var j, jLength, device, addMessage

  messages = []

  length = this.messages.length
  for (i = 0; i < length; i++) {

    message = this.messages[i]

    if (message && Array.isArray(message.conditions)) {

      addMessage = false

      jLength = message.conditions.length
      for (j = 0; j < jLength; j++) {

        condition = message.conditions[j]

        if (condition && BasUtil.isNEString(condition.deviceUuid)) {

          device = this.getDevice(condition.deviceUuid)

          if (
            device &&
            BasUtil.isEqualPartialObject(
              device,
              condition.device
            )
          ) {
            addMessage = true
            break
          }
        }
      }

      if (addMessage) messages.push(message.message)
    }
  }

  // Only send out new messages if new messages are different from last sent
  //  messages
  if (!BasUtil.isEqualObject(messages, this._lastSentMessages)) {

    msg = {}
    msg[P.SYSTEM] = {}
    msg[P.SYSTEM][P.MESSAGES] = messages

    this.sendMessage(msg)

    this._lastSentMessages = messages
  }
}

/**
 * Update state and capabilities of all AV sources
 */
Demo.prototype.syncAVSourcesState = function () {

  var i, length, sources, keys

  // Loop over all AV audio-sources

  sources = this.data.avSources.avSources.audio

  keys = Object.keys(sources)
  length = keys.length
  for (i = 0; i < length; i++) {

    this.syncAVSourceState(keys[i])
  }
}

/**
 * Bring state and capabilities of AVSource with given uuid up to date
 *
 * @param {string} sourceUuid
 * @param {Object?} [nowPlaying]
 */
Demo.prototype.syncAVSourceState = function (sourceUuid, nowPlaying) {

  var source, currentTrack, nextTrack, queue, playerId
  var songObjs, pos, room, tuneInGid, radioStation, isRadioPlaying

  // Audio-source

  source = this.getAVAudioSourceForSourceUuid(sourceUuid)

  if (source) {

    playerId = this.getPlayerIdForSourceUuid(sourceUuid)

    if (BasUtil.isVNumber(playerId)) {

      isRadioPlaying =
        (
          BasUtil.isObject(this.data.queue[playerId].songs[0]) &&
          this.data.queue[playerId].songs[0][P.CONTENT_SRC] === P.TUNEIN
        )
    }

    source[P.CAPABILITIES] = {}

    source[P.CAPABILITIES][P.NOW_PLAYING] = 'r'
    source[P.CAPABILITIES][P.MUTE] = 'rw'
    source[P.CAPABILITIES][P.VOLUME] = 'rw'
    source[P.CAPABILITIES][P.PLAYBACK] = 'rw'
    source[P.CAPABILITIES][P.ON] = 'rw'
    source[P.CAPABILITIES][P.LISTENING_ROOMS] = 'r'
    source[P.CAPABILITIES][P.PLAY_URI] = 'x'

    if (source[P.TYPE] === P.ASANO) {

      source[P.CAPABILITIES][P.DEFAULT_ROOMS] = {}
      source[P.CAPABILITIES][P.DEFAULT_ROOMS][P.LIST] = 'x'
      source[P.CAPABILITIES][P.DEFAULT_ROOMS][P.SET] = 'x'

      if (source[P.SUB_TYPE] === P.STREAM) {

        source[P.CAPABILITIES][P.FAVOURITES] = {}
        source[P.CAPABILITIES][P.FAVOURITES][P.LIST] = 'x'
      }
    }

    if (source[P.TYPE] === P.SONOS) {
      source[P.CAPABILITIES][P.FAVOURITES] = {}
      source[P.CAPABILITIES][P.FAVOURITES][P.LIST] = 'x'
      source[P.CAPABILITIES][P.FAVOURITES][P.QUICK] = {}
      source[P.CAPABILITIES][P.FAVOURITES][P.QUICK][P.LIST] = 'x'
      source[P.CAPABILITIES][P.FAVOURITES][P.QUICK][P.SET] = 'x'
      source[P.CAPABILITIES][P.REPEAT] = 'rw'
      source[P.CAPABILITIES][P.SHUFFLE] = 'rw'
    }

    queue = this.data.queue[playerId]

    if (queue) {

      if (isRadioPlaying) {

        // Only works for custom radios,
        // to avoid relying on a network connection
        currentTrack = queue.songs[0]

        tuneInGid = currentTrack.tunein_gid.slice(URI_PREFIX_RADIO.length)
        radioStation = this.data.radios[tuneInGid]

        if (radioStation) {

          source[P.STATE][P.NOW_PLAYING][P.CURRENT] =
            this.convertRadioToTrack(radioStation, tuneInGid)
        }
      } else {

        songObjs = queue.songObjs

        pos = this.getCurrentPlayerPos(playerId)

        currentTrack = songObjs[pos]
        nextTrack = songObjs[pos + 1]

        source[P.STATE][P.NOW_PLAYING][P.CURRENT] =
          this.convertSongToTrack(currentTrack)
        source[P.STATE][P.NOW_PLAYING][P.NEXT] =
          this.convertSongToTrack(nextTrack)

        // Capabilities unlocked when queue is available and active

        source[P.CAPABILITIES][P.QUEUE] = {}
        source[P.CAPABILITIES][P.QUEUE][P.LIST] = 'x'
        source[P.CAPABILITIES][P.QUEUE][P.ADD] = 'x'
        source[P.CAPABILITIES][P.SHUFFLE] = 'rw'
        source[P.CAPABILITIES][P.REPEAT] = 'rw'
        source[P.CAPABILITIES][P.SKIP_NEXT] = 'x'
        source[P.CAPABILITIES][P.SKIP_PREVIOUS] = 'x'
        source[P.CAPABILITIES][P.POSITION_MS] = 'rw'
      }

    } else if (source[P.TYPE] === P.SONOS) {
      // Currently, we select the first room that has a Sonos speaker.
      // For now, this works fine, but if we want to implement the
      // grouping feature for Sonos, this code will need to be more dynamic.
      room = this.getRoomForId(source[P.STATE][P.LISTENING_ROOMS][0])

      // Set initial now playing state
      if (!BasUtil.safeHasOwnProperty(source[P.STATE], P.NOW_PLAYING)) {

        const keys = Object.keys(this.data.favorites[sourceUuid])
        source[P.STATE][P.NOW_PLAYING] =
          this.data.favorites[sourceUuid][keys[0]]

      } else if (nowPlaying) {
        // Update now playing state
        source[P.STATE][P.NOW_PLAYING] = nowPlaying
      }

      if (room) {
        source[P.STATE][P.PLAYBACK] = room[P.AV][P.AUDIO][P.STATE][P.ON]
          ? P.PLAYING
          : P.PAUSED
      }

    } else {

      source[P.STATE][P.NOW_PLAYING] = {}
    }

    source[P.STATE][P.LISTENING_ROOMS] = this.getListeningRooms(sourceUuid)
    source[P.STATE][P.ON] = source[P.STATE][P.LISTENING_ROOMS].length !== 0

    if (BasUtil.isNEString(source[P.FOLLOW_ROOM_NAME_UUID])) {

      room = this.getRoomForId(source[P.FOLLOW_ROOM_NAME_UUID])

      source[P.REACHABLE] = (
        room &&
        room[P.AV] &&
        room[P.AV][P.VIDEO] &&
        room[P.AV][P.VIDEO][P.STATE] &&
        BasUtil.isNEString(room[P.AV][P.VIDEO][P.STATE][P.SOURCE])
      )
    }
  }

  // Video-source

  // Video-sources do not currently have any dynamic behaviour that should be
  //  handled in demo (only dynamic favourites so far)
}

/**
 * Bring state and capabilities of AVAudio of room with given uuid up to date
 *
 * @param {string} roomUuid
 * @param {boolean} [force]
 */
Demo.prototype.syncAVAudioState = function (roomUuid, force) {

  var room, avAudioState

  room = this.data.rooms.rooms[roomUuid]

  if (
    room &&
    room[P.AV] &&
    room[P.AV][P.AUDIO] &&
    room[P.AV][P.AUDIO][P.STATE]
  ) {

    avAudioState = room[P.AV][P.AUDIO][P.STATE]
    avAudioState[P.ON] = BasUtil.isNEString(avAudioState[P.SOURCE])
    if (BasUtil.isBool(force)) avAudioState[P.ON] = force
  }
}

/**
 * Bring state and capabilities of AVVideo of room with given uuid up to date
 *
 * @param {string} roomUuid
 */
Demo.prototype.syncAVVideoState = function (roomUuid) {

  var room, avVideoState

  room = this.data.rooms.rooms[roomUuid]

  if (
    room &&
    room[P.AV] &&
    room[P.AV][P.VIDEO] &&
    room[P.AV][P.VIDEO][P.STATE]
  ) {

    avVideoState = room[P.AV][P.VIDEO][P.STATE]
    avVideoState[P.ON] = BasUtil.isNEString(avVideoState[P.SOURCE])
  }
}

/**
 * Send message with all AVSource info of source with given uuid
 *
 * @param {string} sourceUuid
 */
Demo.prototype.sendAVSource = function (sourceUuid) {

  var source, msg

  source = this.getAVAudioSourceForSourceUuid(sourceUuid)

  if (source) {

    msg = {}
    msg[P.AV_SOURCE] = source

    this.sendMessage(msg)
  }
}

/**
 * Send message with all AVAudio info of room with given uuid
 *
 * @param {string} roomUuid
 */
Demo.prototype.sendAVAudio = function (roomUuid) {

  var room, msg

  room = this.data.rooms.rooms[roomUuid]

  if (room) {

    msg = {}
    msg[P.ROOM] = {}
    msg[P.ROOM][P.UUID] = roomUuid
    msg[P.ROOM][P.AV] = {}
    msg[P.ROOM][P.AV][P.AUDIO] = room[P.AV][P.AUDIO]

    this.sendMessage(msg)
  }
}

/**
 * Send message with only DSP info of room with given uuid
 *
 * @param {string} roomUuid
 */
Demo.prototype.sendAvAudioDsp = function (roomUuid) {
  var room, msg

  room = this.data.rooms.rooms[roomUuid]

  if (room) {

    msg = {}
    msg[P.ROOM] = {}
    msg[P.ROOM][P.UUID] = roomUuid
    msg[P.ROOM][P.AV] = {}
    msg[P.ROOM][P.AV][P.AUDIO] = {}
    msg[P.ROOM][P.AV][P.AUDIO][P.STATE] = {}
    msg[P.ROOM][P.AV][P.AUDIO][P.STATE][P.DSP] =
      room[P.AV][P.AUDIO][P.STATE][P.DSP]

    this.sendMessage(msg)
  }
}

/**
 * Send message with all AVVideo info of room with given uuid
 *
 * @param {string} roomUuid
 */
Demo.prototype.sendAVVideo = function (roomUuid) {

  var room, msg

  room = this.data.rooms.rooms[roomUuid]

  if (room) {

    msg = {}
    msg[P.ROOM] = {}
    msg[P.ROOM][P.UUID] = roomUuid
    msg[P.ROOM][P.AV] = {}
    msg[P.ROOM][P.AV][P.VIDEO] = room[P.AV][P.VIDEO]

    this.sendMessage(msg)
  }
}

/**
 * Send library state (available and not scanning)
 */
Demo.prototype.sendLibraryState = function () {

  var msg

  msg = {}

  msg[P.LIBRARY] = {}
  msg[P.LIBRARY][P.STATE] = {}
  msg[P.LIBRARY][P.STATE][P.AVAILABLE] = true
  msg[P.LIBRARY][P.STATE][P.SCANNING] = false

  this.sendMessage(msg)
}

/**
 * Get list of room uuids that are currently listingen to av audio-source with
 *  given uuid
 *
 * @param {string} sourceUuid
 * @returns {string[]}
 */
Demo.prototype.getListeningRooms = function (sourceUuid) {

  var listeningRooms, i, length, keys, room

  listeningRooms = []

  keys = Object.keys(this.data.rooms.rooms)
  length = keys.length
  for (i = 0; i < length; i++) {

    room = this.data.rooms.rooms[keys[i]]

    if (
      room &&
      room[P.AV] &&
      room[P.AV][P.AUDIO] &&
      room[P.AV][P.AUDIO][P.STATE] &&
      room[P.AV][P.AUDIO][P.STATE][P.SOURCE] === sourceUuid
    ) {

      listeningRooms.push(room[P.UUID])
    }
  }

  return listeningRooms
}

Demo.prototype.handleAlarmList = function () {

  return this.data.alarms
}

Demo.prototype.handleLight = function (device, demoDevice, returnObj) {

  var state, returnState, demoState

  if (demoDevice) demoState = demoDevice[P.STATE]

  // State
  if (BasUtil.isObject(device[P.STATE])) {

    state = device[P.STATE]
    returnState = returnObj[P.DEVICE][P.STATE] = {}

    // On
    if (BasUtil.safeHasOwnProperty(state, P.ON)) {

      returnState[P.ON] = state[P.ON]

      if (demoState &&
        BasUtil.safeHasOwnProperty(demoState, P.BRIGHTNESS)) {

        if (state[P.ON]) {

          returnState[P.BRIGHTNESS] =
            demoState[P.BRIGHTNESS]

          if (returnState[P.BRIGHTNESS] === 0) {

            returnState[P.BRIGHTNESS] = 0.8
          }

        } else {

          returnState[P.BRIGHTNESS] = 0
        }
      }
    }

    // Brightness
    if (BasUtil.safeHasOwnProperty(state, P.BRIGHTNESS)) {

      returnState[P.BRIGHTNESS] = state[P.BRIGHTNESS]

      if (demoState &&
        BasUtil.safeHasOwnProperty(demoState, P.BRIGHTNESS)) {

        demoState[P.BRIGHTNESS] = state[P.BRIGHTNESS]
      }

      returnState[P.ON] = state[P.BRIGHTNESS] > 0
    }

    // Hue
    if (BasUtil.safeHasOwnProperty(state, P.HUE)) {

      returnState[P.HUE] = state[P.HUE]
      returnState[P.MODE] = P.COLOR
    }

    // Saturation
    if (BasUtil.safeHasOwnProperty(state, P.SATURATION)) {

      returnState[P.SATURATION] = state[P.SATURATION]
      returnState[P.MODE] = P.COLOR
    }

    // Color temperature
    if (BasUtil.safeHasOwnProperty(state, P.COLOR_TEMPERATURE)) {

      returnState[P.COLOR_TEMPERATURE] =
        state[P.COLOR_TEMPERATURE]
      returnState[P.MODE] = P.COLOR_TEMPERATURE
    }

    // Generic controls
    const genericControlEntries = Object.entries(state)
      .filter(([key, _]) => key.startsWith('generic_control_'))
    for (const [key, value] of genericControlEntries) {
      returnState[key] = value
    }

    BasUtil.mergeObjectsDeep(demoState, returnState)
  }
}

Demo.prototype.handleShade = function (device, demoDevice, returnObj) {

  var state, returnState, demoCapabilities, demoState

  if (demoDevice) {

    demoCapabilities = demoDevice[P.CAPABILITIES]
    demoState = demoDevice[P.STATE]
  }

  // State
  if (BasUtil.isObject(device[P.STATE])) {

    state = device[P.STATE]
    returnState = returnObj[P.DEVICE][P.STATE] = {}

    // Position
    if (BasUtil.safeHasOwnProperty(state, P.POSITION)) {

      returnState[P.POSITION] = state[P.POSITION]

      if (demoCapabilities[P.OPEN_CLOSE]) {

        returnState[P.OPEN_CLOSE] = state[P.POSITION] === 1
          ? P.CLOSE
          : P.OPEN
      }
    }

    // Rotation
    if (BasUtil.safeHasOwnProperty(state, P.ROTATION)) {

      returnState[P.ROTATION] = state[P.ROTATION]
    }
  }

  if (BasUtil.safeHasOwnProperty(device, P.ACTION)) {

    returnState = returnObj[P.DEVICE][P.STATE] = {}

    switch (device[P.ACTION]) {
      case P.OPEN:

        if (demoCapabilities) {

          if (demoCapabilities[P.ROTATION]) {

            returnState[P.ROTATION] = 0
          }

          if (demoCapabilities[P.POSITION]) {

            returnState[P.POSITION] = 0
          }

          if (demoCapabilities[P.OPEN_CLOSE]) {

            returnState[P.OPEN_CLOSE] = P.OPEN
          }
        }

        break
      case P.CLOSE:

        if (demoCapabilities) {

          if (demoCapabilities[P.ROTATION]) {

            returnState[P.ROTATION] = 1
          }

          if (demoCapabilities[P.POSITION]) {

            returnState[P.POSITION] = 1
          }

          if (demoCapabilities[P.OPEN_CLOSE]) {

            returnState[P.OPEN_CLOSE] = P.CLOSE
          }
        }

        break
    }
  }

  if (demoState && returnState) {

    BasUtil.mergeObjectsDeep(demoState, returnState)
  }
}

Demo.prototype.handleGeneric = function (device, _demoDevice, returnObj) {

  var i, length, demoControl, finalDemoControl

  if (device[P.ACTION] === P.UPDATE && device[P.CONTROL]) {

    if (
      _demoDevice &&
      Array.isArray(_demoDevice[P.CONTROLS])
    ) {

      length = _demoDevice[P.CONTROLS].length
      for (i = 0; i < length; i++) {

        demoControl = _demoDevice[P.CONTROLS][i]

        if (
          demoControl &&
          demoControl.uuid === device[P.CONTROL][P.UUID]
        ) {

          finalDemoControl = demoControl
          break
        }
      }
    }

    if (finalDemoControl) {

      BasUtil.mergeObjects(finalDemoControl, device[P.CONTROL])
      returnObj[P.DEVICE] = _demoDevice
    }
  }
}

Demo.prototype.handleThermostat = function (device, _demoDevice, returnObj) {

  var _thermostatDemoDevice, temperature, state, returnState
  var demoState, demoSetpoint, control

  temperature = new Temperature()

  _thermostatDemoDevice = this.getDevice(device[P.UUID])

  if (_thermostatDemoDevice) {

    demoState = _thermostatDemoDevice[P.STATE]

    if (demoState) {

      demoSetpoint = demoState[P.SETPOINT]
    }
  }

  // State
  if (BasUtil.isObject(device[P.STATE])) {

    state = device[P.STATE]
    returnState = returnObj[P.DEVICE][P.STATE] = {}

    // Setpoint
    if (BasUtil.safeHasOwnProperty(state, P.SETPOINT)) {

      if (demoSetpoint) {

        if (BasUtil.isVNumber(
          state[P.SETPOINT][P.CELSIUS]
        )) {

          temperature.setCelsius(
            state[P.SETPOINT][P.CELSIUS]
          )

          demoSetpoint[P.CELSIUS] =
            temperature.getTemperature(CONSTANTS.TU_CELSIUS)
          demoSetpoint[P.FAHRENHEIT] =
            temperature.getTemperature(CONSTANTS.TU_FAHRENHEIT)

        } else if (BasUtil.isVNumber(
          state[P.SETPOINT][P.FAHRENHEIT]
        )) {

          temperature.setFahrenheit(
            state[P.SETPOINT][P.FAHRENHEIT]
          )

          demoSetpoint[P.CELSIUS] =
            temperature.getTemperature(CONSTANTS.TU_CELSIUS)
          demoSetpoint[P.FAHRENHEIT] =
            temperature.getTemperature(CONSTANTS.TU_FAHRENHEIT)
        }

        returnState[P.SETPOINT] = demoSetpoint
      }

      this.handleThermostatStatus(demoState, returnState)
    }

    // Fan mode
    if (BasUtil.safeHasOwnProperty(state, P.FAN_MODE)) {

      returnState[P.FAN_MODE] = state[P.FAN_MODE]
    }

    // Thermostat mode
    if (BasUtil.safeHasOwnProperty(state, P.THERMOSTAT_MODE)) {

      if (demoState) {

        demoState[P.THERMOSTAT_MODE] =
          state[P.THERMOSTAT_MODE]
      }

      returnState[P.THERMOSTAT_MODE] = state[P.THERMOSTAT_MODE]

      this.handleThermostatStatus(demoState, returnState)
    }

    BasUtil.mergeObjectsDeep(demoState, returnState)
  }

  // Thermostat controls
  if (BasUtil.isNEString(device[P.ACTION]) &&
    BasUtil.isObject(device[P.CONTROL])) {

    if (device[P.ACTION] === P.UPDATE) {

      control = device[P.CONTROL]

      returnObj[P.DEVICE][P.CONTROLS] = []
      returnObj[P.DEVICE][P.CONTROLS].push(control)
    }
  }
}

Demo.prototype.handleSceneController = function (
  device,
  demoDevice,
  returnObj
) {
  if (BasUtil.isObject(device[P.FAVOURITES]) &&
    device[P.ACTION] === P.UPDATE) {

    demoDevice[P.FAVOURITES] = device[P.FAVOURITES]
  }

  if (BasUtil.isObject(device[P.SCENE])) {

    this.handleScenes(device, demoDevice, returnObj)
  }

  if (BasUtil.isObject(device[P.JOB])) {

    this.handleJob(device, demoDevice, returnObj)
  }
}

Demo.prototype.handleJob = function (device, demoDevice, returnObj) {

  var demoJobs, demoJob, deviceJob, index, job

  if (demoDevice && demoDevice[P.JOBS]) {

    demoJobs = demoDevice[P.JOBS]
  }

  switch (device[P.ACTION]) {
    case P.ADD:

      if (demoJobs) {

        job = {}
        job[P.ENABLED] = false
        job[P.NAME] = ''
        job[P.UUID] = '' + Date.now()
        job[P.HOUR] = 15
        job[P.MIN] = 12
        job[P.SCENE] = ''
        job[P.TYPE] = P.REPEATED
        job[P.MONDAY] = true
        job[P.TUESDAY] = true
        job[P.WEDNESDAY] = true
        job[P.THURSDAY] = true
        job[P.FRIDAY] = true
        job[P.SATURDAY] = true
        job[P.SUNDAY] = true
        job[P.CAPABILITIES] = {}
        job[P.CAPABILITIES][P.ENABLE] = 'rw'
        job[P.CAPABILITIES][P.NAME] = 'rw'
        job[P.CAPABILITIES][P.REMOVE] = 'x'
        job[P.CAPABILITIES][P.SCENE] = 'rw'
        job[P.CAPABILITIES][P.TIME] = 'rw'

        demoJobs.push(job)
      }

      if (demoDevice) this.sendMessage({ device: demoDevice })

      returnObj[P.DEVICE][P.JOBS] = [job]

      break

    case P.REMOVE:

      if (demoJobs &&
        device &&
        device[P.JOB] &&
        device[P.JOB][P.UUID]) {

        index = Demo.getDemoDeviceIndex(
          demoJobs,
          device[P.JOB][P.UUID]
        )

        if (index !== -1) demoJobs = demoJobs.splice(index, 1)
      }

      returnObj[P.DEVICE] = device

      break

    case P.UPDATE:

      if (demoJobs &&
        device &&
        device[P.JOB] &&
        device[P.JOB][P.UUID]) {

        demoJob = Demo.getDemoDevice(
          demoJobs,
          device[P.JOB][P.UUID]
        )
        deviceJob = device[P.JOB]

        if (demoJob) {

          demoJob[P.ENABLED] = deviceJob[P.ENABLED]
          demoJob[P.NAME] = deviceJob[P.NAME]
          demoJob[P.UUID] = deviceJob[P.UUID]
          demoJob[P.HOUR] = deviceJob[P.HOUR]
          demoJob[P.MIN] = deviceJob[P.MIN]
          demoJob[P.SCENE] = deviceJob[P.SCENE]
          demoJob[P.TYPE] = deviceJob[P.TYPE]
          demoJob[P.MONDAY] = deviceJob[P.MONDAY]
          demoJob[P.TUESDAY] = deviceJob[P.TUESDAY]
          demoJob[P.WEDNESDAY] = deviceJob[P.WEDNESDAY]
          demoJob[P.THURSDAY] = deviceJob[P.THURSDAY]
          demoJob[P.FRIDAY] = deviceJob[P.FRIDAY]
          demoJob[P.SATURDAY] = deviceJob[P.SATURDAY]
          demoJob[P.SUNDAY] = deviceJob[P.SUNDAY]
        }

        returnObj[P.DEVICE][P.JOBS] = [demoJob]
      }

      break
  }
}

Demo.prototype.handleScenes = function (device, demoDevice, returnObj) {

  var demoScenes, demoScene, deviceScene, index, scene, message

  if (demoDevice && demoDevice[P.SCENES]) {

    demoScenes = demoDevice[P.SCENES]
  }

  switch (device[P.ACTION]) {
    case P.ADD:

      if (demoScenes &&
        device &&
        device[P.SCENE] &&
        device[P.SCENE][P.NAME]) {

        scene = {}
        scene[P.NAME] = device[P.SCENE][P.NAME]
        scene[P.CONTENT] = ''
        scene[P.FAVOURITE] = false
        scene[P.TEMPLATE] = 0
        scene[P.UUID] = scene[P.NAME] + Date.now()
        scene[P.CAPABILITIES] = {}
        scene[P.CAPABILITIES][P.ACTIVATE] = 'x'
        scene[P.CAPABILITIES][P.CONTENT] = 'rw'
        scene[P.CAPABILITIES][P.FAVOURITE] = 'rw'
        scene[P.CAPABILITIES][P.NAME] = 'rw'
        scene[P.CAPABILITIES][P.REMOVE] = 'x'
        scene[P.CAPABILITIES][P.TEMPLATE] = 'rw'

        demoScenes.push(scene)

        message = {}
        message[P.DEVICE] = {}
        message[P.DEVICE][P.ACTION] = P.ADD
        message[P.DEVICE][P.SCENE] = {}
        message[P.DEVICE][P.SCENE][P.UUID] =
          scene[P.UUID]
        message[P.DEVICE][P.UUID] = device[P.UUID]

        this.sendMessage(message)

        returnObj[P.DEVICE][P.SCENES] = [scene]
      }

      break

    case P.STATUS:

      if (demoScenes &&
        device &&
        device[P.SCENE] &&
        device[P.SCENE][P.UUID]) {

        deviceScene = Demo.getDemoDevice(
          demoScenes,
          device[P.SCENE][P.UUID]
        )

        returnObj[P.DEVICE][P.SCENES] = [deviceScene]
      }

      break

    case P.REMOVE:

      if (demoScenes &&
        device &&
        device[P.SCENE] &&
        device[P.SCENE][P.UUID]) {

        index = Demo.getDemoDeviceIndex(
          demoScenes,
          device[P.SCENE][P.UUID]
        )

        if (index !== -1) demoScenes = demoScenes.splice(index, 1)
      }

      returnObj[P.DEVICE] = device
      break

    case P.UPDATE:

      if (demoScenes &&
        device &&
        device[P.SCENE] &&
        device[P.SCENE][P.UUID]) {

        deviceScene = device[P.SCENE]

        if (deviceScene) {

          demoScene = Demo.getDemoDevice(
            demoScenes,
            deviceScene[P.UUID]
          )

          if (demoScene) {

            if (P.CONTENT in deviceScene) {

              demoScene[P.CONTENT] =
                deviceScene[P.CONTENT]
            }
            if (P.FAVOURITE in deviceScene) {

              demoScene[P.FAVOURITE] =
                deviceScene[P.FAVOURITE]
            }
            if (P.NAME in deviceScene) {

              demoScene[P.NAME] = deviceScene[P.NAME]
            }
            if (P.TEMPLATE in deviceScene) {

              demoScene[P.TEMPLATE] =
                deviceScene[P.TEMPLATE]
            }
          }

          message = {}
          message[P.DEVICE] = {}
          message[P.DEVICE][P.SCENES] = []
          message[P.DEVICE][P.SCENES].push(deviceScene)
          message[P.DEVICE][P.UUID] = device[P.UUID]

          this.sendMessage(message)

          returnObj[P.DEVICE][P.RESULT] = P.OK
        }
      }

      break

    case P.CLEAR_IMAGE:
    case P.SET_IMAGE:

      returnObj[P.DEVICE][P.RESULT] = P.OK

      break
  }
}

/**
 * @returns {Promise<Object>}
 */
Demo.prototype.getWeatherData = function () {

  var result = {}
  result.status = 200
  result.body = this.data.weather.data

  return Promise.resolve(result)
}

Demo.prototype.handleThermostatStatus = function (deviceState, returnState) {

  var thermostatMode, setPoint, temperature

  if (deviceState) {

    thermostatMode = deviceState[P.THERMOSTAT_MODE]

    if (deviceState[P.SETPOINT] &&
      BasUtil.isVNumber(deviceState[P.SETPOINT][P.CELSIUS])) {

      setPoint = deviceState[P.SETPOINT][P.CELSIUS]
    }

    if (deviceState[P.TEMPERATURE] &&
      BasUtil.isVNumber(
        deviceState[P.TEMPERATURE][P.CELSIUS]
      )) {

      temperature = deviceState[P.TEMPERATURE][P.CELSIUS]
    }

    returnState[P.COOLING_ACTIVE] = (
      temperature > setPoint &&
      (
        thermostatMode === P.COOLING ||
        thermostatMode === P.AUTO
      )
    )

    returnState[P.HEATING_ACTIVE] = (
      temperature < setPoint &&
      (
        thermostatMode === P.HEATING ||
        thermostatMode === P.AUTO
      )
    )

    if (thermostatMode === P.HEATING ||
      (
        thermostatMode === P.AUTO &&
        temperature < setPoint
      )) {

      returnState[P.HEAT_COOL_MODE] = P.HEATING
    }

    if (thermostatMode === P.COOLING ||
      (
        thermostatMode === P.AUTO &&
        temperature > setPoint
      )) {

      returnState[P.HEAT_COOL_MODE] = P.COOLING
    }

    if (thermostatMode === P.OFF) {

      returnState[P.HEAT_COOL_MODE] = P.OFF
    }

    if (
      thermostatMode === P.AUTO &&
      temperature === setPoint
    ) {

      returnState[P.HEAT_COOL_MODE] = ''
    }
  }
}

Demo.prototype.handleEnergyMeter = function (device, _demoDevice, returnObj) {

  var _energyMeterDevice, reqStart
  var dNow, dStart, dStop, tNow, tStart, tStop, tStopGen
  var _stop, _step, _limit, _offset, tDiff, total, reqNum

  _energyMeterDevice = this.getDevice(device[P.UUID])

  if (
    _energyMeterDevice &&
    device[P.ACTION] === P.GET_AGGREGATE_DATA &&
    device[P.DATA]
  ) {
    reqStart = device[P.DATA][P.START]
    _stop = device[P.DATA][P.STOP]
    _step = device[P.DATA][P.STEP]
    _limit = device[P.DATA][P.LIMIT]
    _offset = device[P.DATA][P.OFFSET]

    if (!BasUtil.isPNumber(_step, false)) _step = 3600000
    if (!BasUtil.isPNumber(_limit, false)) _limit = 50
    if (!BasUtil.isPNumber(_offset, true)) _offset = 0

    dNow = new Date()
    tNow = dNow.getTime()
    dStart = new Date(reqStart)
    dStop = _stop ? new Date(_stop) : dNow

    tStart = dStart.getTime()
    tStop = dStop.getTime()

    tStopGen = Math.min(tStop, tNow)

    if (
      BasUtil.isPNumber(tStart, false) &&
      BasUtil.isPNumber(tStop, false) &&
      tStop > tStart
    ) {
      tDiff = tStop - tStart
      total = Math.floor(tDiff / _step) + 1
      reqNum = total - _offset

      returnObj[P.DEVICE][P.ACTION] = P.GET_AGGREGATE_DATA
      returnObj[P.DEVICE][P.DATA] = {}
      returnObj[P.DEVICE][P.DATA][P.LIMIT] = _limit
      returnObj[P.DEVICE][P.DATA][P.OFFSET] = _offset
      returnObj[P.DEVICE][P.DATA][P.START] = BasUtil.toISO8601Extended(dStart)
      returnObj[P.DEVICE][P.DATA][P.STOP] = BasUtil.toISO8601Extended(dStop)
      returnObj[P.DEVICE][P.DATA][P.STEP] = _step
      switch (_energyMeterDevice[P.SUB_TYPE]) {
        case P.ENERGY:
          returnObj[P.DEVICE][P.DATA][P.RESULT] = (reqNum > 0)
            ? Demo._generateEnergyData(
              Math.min(_limit, reqNum),
              tStart + _offset * _step,
              tStopGen,
              _step,
              _step === 3600000 ? 877 : 43390,
              _step === 3600000 ? 23 : 2380
            )
            : []
          break
        case P.GAS:
          returnObj[P.DEVICE][P.DATA][P.RESULT] = (reqNum > 0)
            ? Demo._generateEnergyData(
              Math.min(_limit, reqNum),
              tStart + _offset * _step,
              tStopGen,
              _step,
              _step === 3600000 ? 6 : 8,
              _step === 3600000 ? 0 : 0
            )
            : []
          break
        case P.WATER:
          returnObj[P.DEVICE][P.DATA][P.RESULT] = (reqNum > 0)
            ? Demo._generateEnergyData(
              Math.min(_limit, reqNum),
              tStart + _offset * _step,
              tStopGen,
              _step,
              _step === 3600000 ? 6 : 300,
              _step === 3600000 ? 0 : 40
            )
            : []
          break
        default:
          returnObj[P.DEVICE][P.DATA][P.RESULT] = (reqNum > 0)
            ? Demo._generateEnergyData(
              Math.min(_limit, reqNum),
              tStart + _offset * _step,
              tStopGen,
              _step,
              _step === 3600000 ? 600 : 11400,
              _step === 3600000 ? 211 : 2590
            )
            : []
      }

    } else {

      // TODO Return ERROR
    }
  }
}

Demo.prototype.handlePlayerStatus = function (playerId, pos) {

  return this.syncPlayerStatus(playerId, pos)
}

Demo.prototype.syncPlayerStatus = function (playerId, pos) {

  var songObjs, player, song, next, queue

  player = this.getPlayerForId(playerId)

  // Check if player exists
  if (player) {

    queue = this.data.queue[playerId]
    songObjs = queue.songObjs

    if (BasUtil.isPNumber(pos, true)) {

      // Current song
      song = songObjs[pos]

      if (BasUtil.isObject(song)) {

        player[P.SONG] = song
        player[P.STATE] = P.PLAY

      } else {

        player[P.SONG] = null
        player[P.STATE] = P.STOP
      }

      // Next song
      next = songObjs[pos + 1]

      player[P.NEXT] = BasUtil.isObject(next) ? next : null
    }

    return player
  }
}

Demo.prototype.handleNext = function (playerId) {

  var queue, songObjs, pos

  pos = this.getCurrentPlayerPos(playerId)

  if (pos !== -1) {

    queue = this.data.queue[playerId]
    songObjs = queue.songObjs

    pos = pos < songObjs.length - 1 ? pos + 1 : 0

  } else {

    pos = 0
  }

  return this.handlePlayerStatus(playerId, pos)
}

Demo.prototype.handlePrevious = function (playerId) {

  var pos = this.getCurrentPlayerPos(playerId)

  pos = pos > 0 ? pos - 1 : 0

  return this.handlePlayerStatus(playerId, pos)
}

Demo.prototype.getCurrentPlayerPos = function (playerId) {

  var i, length, queue, songObjs, player

  player = this.getPlayerForId(playerId)

  if (player) {

    if (player[P.SONG]) {

      queue = this.data.queue[playerId]
      songObjs = queue.songObjs
      length = songObjs.length

      for (i = 0; i < length; i++) {

        if (songObjs[i][P.ID] ===
          player[P.SONG][P.ID]) {

          return i
        }
      }
    }
  }

  return -1
}

Demo.prototype.handleSong = function (playerId, songId) {

  var queue, songObjs, pos, i, length

  queue = this.data.queue[playerId]
  songObjs = queue.songObjs
  pos = 0

  length = songObjs.length
  for (i = 0; i < length; i++) {

    if (songObjs[i][P.ID] === songId) pos = i
  }

  return this.handlePlayerStatus(playerId, pos)
}

Demo.prototype.handleSongList = function (limit, page) {

  var data, start, end, songList

  data = {}

  start = limit * page
  end = start + limit
  songList = this.data.songs.slice(start, end)

  data[P.OVERVIEW] = songList

  return data
}

Demo.prototype.handleArtistList = function () {

  var data

  data = {}
  data[P.OVERVIEW] = this.getArtistsList()

  return data
}

Demo.prototype.handleAlbumList = function () {

  var data

  data = {}
  data[P.OVERVIEW] = this.getAlbumsList()

  return data
}

Demo.prototype.handlePlaylistList = function (playerId) {

  var playlistsData = this.data.playlists

  return playlistsData[playerId]
}

Demo.prototype.handleAlbumDetail = function (artist, name) {

  var data, songs, songList, i, length, song

  data = {}

  songs = this.data.songs

  songList = []

  length = songs.length
  for (i = 0; i < length; i++) {

    song = songs[i]

    if (BasUtil.isObject(song[P.ALBUM]) &&
      song[P.ALBUM][P.ARTIST] === artist &&
      song[P.ALBUM][P.NAME] === name) {

      songList.push(song)
    }
  }

  data[P.DETAIL] = songList

  return data
}

Demo.prototype.handleArtistDetail = function (name) {

  var data, songs, songList, song, i, length

  data = {}

  songs = this.data.songs

  songList = []

  length = songs.length
  for (i = 0; i < length; i++) {

    song = songs[i]

    if (song[P.ARTIST] === name) songList.push(song)
  }

  data[P.DETAIL] = songList

  return data
}

Demo.prototype.handlePlaylistCoverart = function (uuid) {

  var data, covers, playlistInfo, ids, i, length, song, cover

  data = {}

  playlistInfo = this.data.playlistInfo
  ids = playlistInfo[uuid].songs

  covers = []

  length = ids.length
  for (i = 0; i < length; i++) {

    song = this.getOriginalSongForId(ids[i])
    cover = song[P.COVERART]

    if (covers.indexOf(cover) === -1) {

      covers.push(cover)

      if (covers.length === 4) break
    }
  }

  data[P.COVERART] = covers

  return data
}

Demo.prototype.handlePlaylistSongs = function (uuid) {

  var data, playlist, songs

  data = {}

  playlist = this.data.playlistInfo[uuid]
  songs = this.processSongsArray(playlist.songs)

  data[P.PLAYLIST] = {}
  data[P.PLAYLIST][P.ID] = uuid
  data[P.PLAYLIST][P.SONGS] = songs

  return data
}

Demo.prototype.handlePlaylistDetail = function (playerId, uuid) {

  var data, playlistsData, playlists, keys, array, a, i, length

  playlistsData = this.data.playlists
  playlists = playlistsData[playerId]

  if (!BasUtil.isObject(playlists)) return null

  // Find playlist mapping to uuid

  keys = Object.keys(playlists)
  length = keys.length
  for (i = 0; i < length; i++) {

    array = playlists[keys[i]]

    for (a = 0; a < array.length; a++) {

      if (array[a].id === uuid) {

        data = {}
        data[P.PLAYLIST] = array[a]

        return data
      }
    }
  }
}

Demo.prototype.handleQueue = function (playerId) {

  var result, queue, songs

  queue = this.data.queue[playerId]

  if (queue) {

    if (queue[P.QUEUE_TYPE] === P.QUEUE) {

      songs = queue.songObjs

    } else if (queue[P.QUEUE_TYPE] === P.STREAM) {

      songs = queue.songs
    }
  }

  result = {}
  result[P.ID] = this.queueId++
  result[P.LENGTH] = songs ? songs.length : 0
  result[P.QUEUE_TYPE] = queue ? queue[P.QUEUE_TYPE] : P.QUEUE
  result[P.SONGS] = songs || []

  return result
}

Demo.prototype.handleFavourites = function (playerId) {

  var favourites = this.data.favorites

  return favourites[playerId]
}

Demo.prototype.handleDeezerLink = function () {

  var data = {}

  data[P.LINKED] = false

  return data
}

Demo.prototype.handleTidalLink = function () {

  var data = {}

  data[P.SESSION] = null

  return data
}

Demo.prototype.handleOtherRadios = function () {

  return {}
}

Demo.prototype.handlePresets = function (playerId) {

  return this.data.presets[playerId]
}

Demo.prototype.generateQueue = function (playerId) {

  var queue, songs, i, length

  queue = this.data.queue[playerId]

  if (queue[P.QUEUE_TYPE] === P.QUEUE) {

    songs = queue.songObjs = this.processSongsArray(queue.songs)

    length = songs.length
    for (i = 0; i < length; i++) {

      songs[i][P.POS] = i
      songs[i][P.ID] = this.songId++
    }
  }
}

/**
 * @param {number} playerId
 * @param {string} uuid
 * @returns {Object}
 */
Demo.prototype.handleAddPlaylistReplace = function (playerId, uuid) {

  var playlistInfo, songs, queue, i, length, player

  playlistInfo = this.data.playlistInfo
  songs = playlistInfo[uuid].songs
  queue = this.data.queue[playerId]

  queue.songs = []
  queue[P.QUEUE_TYPE] = P.QUEUE

  length = songs.length
  for (i = 0; i < length; i++) {

    queue.songs.push(songs[i])
  }

  this.generateQueue(playerId)

  player = this.handlePlayerStatus(playerId, 0)
  player[P.PLAYLIST] = {}
  player[P.PLAYLIST][P.CHANGED] = true

  return player
}

/**
 * @param {number} playerId
 * @param {string} uuid
 * @returns {Object}
 */
Demo.prototype.handleAddPlaylistEnd = function (playerId, uuid) {

  var playlistInfo, songs, queue, i, length, player, pos

  playlistInfo = this.data.playlistInfo
  songs = playlistInfo[uuid].songs
  queue = this.data.queue[playerId]

  queue[P.QUEUE_TYPE] = P.QUEUE

  length = songs.length
  for (i = 0; i < length; i++) {

    queue.songs.push(songs[i])
  }

  this.generateQueue(playerId)

  pos = this.getCurrentPlayerPos(playerId)

  player = this.handlePlayerStatus(playerId, pos)
  player[P.PLAYLIST] = {}
  player[P.PLAYLIST][P.CHANGED] = true

  return player
}

/**
 * @param {number} playerId
 * @param {string} uuid
 * @returns {Object}
 */
Demo.prototype.handleAddPlaylistNext = function (playerId, uuid) {

  var queue, newSongs, oldSongs, oldLength, playlistInfo, songs, pos
  var i, length, player

  queue = this.data.queue[playerId]
  oldSongs = queue.songs
  oldLength = oldSongs.length

  playlistInfo = this.data.playlistInfo
  songs = playlistInfo[uuid].songs

  queue[P.QUEUE_TYPE] = P.QUEUE

  newSongs = []

  pos = this.getCurrentPlayerPos(playerId)

  for (i = 0; i <= pos; i++) {

    newSongs.push(oldSongs[i])
  }

  length = songs.length
  for (i = 0; i < length; i++) {

    newSongs.push(songs[i])
  }

  for (i = pos + 1; i < oldLength; i++) {

    newSongs.push(oldSongs[i])
  }

  queue.songs = newSongs

  this.generateQueue(playerId)

  player = this.handlePlayerStatus(playerId, pos)
  player[P.PLAYLIST] = {}
  player[P.PLAYLIST][P.CHANGED] = true

  return player
}

/**
 * @param {number} playerId
 * @param {string} uuid
 * @returns {Object}
 */
Demo.prototype.handleAddPlaylistNow = function (playerId, uuid) {

  var queue, newSongs, oldSongs, oldLength, playlistInfo, songs, pos
  var i, length, player

  queue = this.data.queue[playerId]
  oldSongs = queue.songs
  oldLength = oldSongs.length

  playlistInfo = this.data.playlistInfo
  songs = playlistInfo[uuid].songs

  queue[P.QUEUE_TYPE] = P.QUEUE

  newSongs = []

  pos = this.getCurrentPlayerPos(playerId)

  for (i = 0; i <= pos; i++) {

    newSongs.push(oldSongs[i])
  }

  length = songs.length
  for (i = 0; i < length; i++) {

    newSongs.push(songs[i])
  }

  for (i = pos + 1; i < oldLength; i++) {

    newSongs.push(oldSongs[i])
  }

  queue.songs = newSongs

  this.generateQueue(playerId)

  player = this.handlePlayerStatus(playerId, pos + 1)
  player[P.PLAYLIST] = {}
  player[P.PLAYLIST][P.CHANGED] = true

  return player
}

/**
 * @param {number} playerId
 * @param {string[]} songs (files)
 * @returns {Object}
 */
Demo.prototype.handleAddSongsReplace = function (playerId, songs) {

  var queue, i, length, song, player

  queue = this.data.queue[playerId]

  queue.songs = []
  queue[P.QUEUE_TYPE] = P.QUEUE

  length = songs.length
  for (i = 0; i < length; i++) {

    song = this.getOriginalSongForFile(songs[i])
    if (song) queue.songs.push(song.id)
  }

  this.generateQueue(playerId)

  player = this.handlePlayerStatus(playerId, 0)
  player[P.PLAYLIST] = {}
  player[P.PLAYLIST][P.CHANGED] = true

  return player
}

/**
 * @param {number} playerId
 * @param {string[]} songs (files)
 * @returns {Object}
 */
Demo.prototype.handleAddSongsEnd = function (playerId, songs) {

  var queue, i, length, song, player, pos

  queue = this.data.queue[playerId]

  queue[P.QUEUE_TYPE] = P.QUEUE

  length = songs.length
  for (i = 0; i < length; i++) {

    song = this.getOriginalSongForFile(songs[i])
    if (song) queue.songs.push(song.id)
  }

  this.generateQueue(playerId)

  pos = this.getCurrentPlayerPos(playerId)

  player = this.handlePlayerStatus(playerId, pos)
  player[P.PLAYLIST] = {}
  player[P.PLAYLIST][P.CHANGED] = true

  return player
}

/**
 * @param {number} playerId
 * @param {string[]} songs (files)
 * @returns {Object}
 */
Demo.prototype.handleAddSongsNext = function (playerId, songs) {

  var queue, newSongs, oldSongs, oldLength, pos, i, length, song, player

  queue = this.data.queue[playerId]
  oldSongs = queue.songs
  oldLength = oldSongs.length

  newSongs = []

  queue[P.QUEUE_TYPE] = P.QUEUE

  pos = this.getCurrentPlayerPos(playerId)

  for (i = 0; i <= pos; i++) {

    newSongs.push(oldSongs[i])
  }

  length = songs.length
  for (i = 0; i < length; i++) {

    song = this.getOriginalSongForFile(songs[i])
    if (song) newSongs.push(song.id)
  }

  for (i = pos + 1; i < oldLength; i++) {

    newSongs.push(oldSongs[i])
  }

  queue.songs = newSongs

  this.generateQueue(playerId)

  player = this.handlePlayerStatus(playerId, pos)
  player[P.PLAYLIST] = {}
  player[P.PLAYLIST][P.CHANGED] = true

  return player
}

/**
 * @param {number} playerId
 * @param {string[]} songs (files)
 * @returns {Object}
 */
Demo.prototype.handleAddSongsNow = function (playerId, songs) {

  var queue, newSongs, oldSongs, oldLength, pos, i, length, song, player

  queue = this.data.queue[playerId]
  oldSongs = queue.songs
  oldLength = oldSongs.length

  newSongs = []

  queue[P.QUEUE_TYPE] = P.QUEUE

  pos = this.getCurrentPlayerPos(playerId)

  for (i = 0; i <= pos; i++) {

    newSongs.push(oldSongs[i])
  }

  length = songs.length
  for (i = 0; i < length; i++) {

    song = this.getOriginalSongForFile(songs[i])
    if (song) newSongs.push(song.id)
  }

  for (i = pos + 1; i < oldLength; i++) {

    newSongs.push(oldSongs[i])
  }

  queue.songs = newSongs

  this.generateQueue(playerId)

  player = this.handlePlayerStatus(playerId, pos + 1)
  player[P.PLAYLIST] = {}
  player[P.PLAYLIST][P.CHANGED] = true

  return player
}

/**
 * @param {number} playerId
 * @param {string} path (file)
 * @returns {Object}
 */
Demo.prototype.handleAddSongNow = function (playerId, path) {

  var queue, newSongs, oldSongs, oldLength, pos, i, song, player

  queue = this.data.queue[playerId]
  oldSongs = queue.songs
  oldLength = oldSongs.length

  newSongs = []

  queue[P.QUEUE_TYPE] = P.QUEUE

  pos = this.getCurrentPlayerPos(playerId)

  for (i = 0; i <= pos; i++) {

    newSongs.push(oldSongs[i])
  }

  song = this.getOriginalSongForFile(path)
  if (song) newSongs.push(song.id)

  for (i = pos + 1; i < oldLength; i++) {

    newSongs.push(oldSongs[i])
  }

  queue.songs = newSongs

  this.generateQueue(playerId)

  player = this.handlePlayerStatus(playerId, pos + 1)
  player[P.PLAYLIST] = {}
  player[P.PLAYLIST][P.CHANGED] = true

  return player
}

/**
 * @param {number} playerId
 * @returns {Object}
 */
Demo.prototype.handleClearQueue = function (playerId) {

  var player, queue

  queue = this.data.queue[playerId]
  queue.songs = []

  this.generateQueue(playerId)

  player = this.handlePlayerStatus(playerId, 0)
  player[P.PLAYLIST] = {}
  player[P.PLAYLIST][P.CHANGED] = true

  return player
}

Demo.prototype.handleDefaultRooms = function (playerId) {

  return this.data.defaultRooms[playerId]
}

Demo.prototype.handleTuneIn = function (playerId, uuid) {

  var result, data, radio, queue

  result = {}

  queue = this.data.queue[playerId]

  radio = {}

  radio[P.ARTIST] = 'Radio'
  radio[P.CONTENT_SRC] = P.TUNEIN
  radio[P.COVERART] = ''
  radio[BasTrack.K_COVERART_URL] = ''
  radio[P.FILE] = ''
  radio[P.ID] = 1
  radio[P.LENGTH] = 0
  radio[P.POS] = 0
  radio[P.PRIO] = 0
  radio[BasTrack.K_THUMBNAIL_URL] = ''
  radio[P.TITLE] = ''
  radio[P.TUNEIN_GID] = uuid

  queue[P.QUEUE_TYPE] = P.STREAM
  queue.songs = [radio]

  data = {}

  data[P.PLAYER] = {}
  data[P.PLAYER][P.ID] = playerId
  data[P.PLAYER][P.NEXT] = null
  data[P.PLAYER][P.PLAYLIST] = {}
  data[P.PLAYER][P.PLAYLIST][P.CHANGED] = true
  data[P.PLAYER][P.SONG] = radio
  data[P.PLAYER][P.STATE] = P.PLAY

  this.sendMessage(data, 0)

  result[P.QUEUE_TYPE] = P.STREAM

  return result
}

Demo.prototype.sendFavouriteChanged = function (playerId) {

  var data = {}

  data[P.PLAYER] = {}
  data[P.PLAYER][P.FAVOURITE] = {}
  data[P.PLAYER][P.FAVOURITE][P.CHANGED] = true
  data[P.PLAYER][P.ID] = playerId

  this.sendMessage(data, 0)
}

Demo.prototype.handleRadioFavourite = function (playerId, uuid, sourceUuid) {

  var favourites, favourite, radio, newRadio, data

  favourites = this.data.favorites
  favourite = favourites[playerId]
  radio = this.data.radios[uuid]

  if (radio) {

    newRadio = {}
    newRadio[P.GID] = uuid
    newRadio[P.NAME] = radio[P.DESCRIBE][P.NAME]

    favourite.radios.push(newRadio)
    this.sendFavouriteChanged(playerId)

    if (BasUtil.isNEString(sourceUuid)) {

      data = {}
      data[P.AV_SOURCE] = {}
      data[P.AV_SOURCE][P.UUID] = sourceUuid
      data[P.AV_SOURCE][P.FAVOURITES] = {}
      data[P.AV_SOURCE][P.FAVOURITES][P.EVENT] = P.ADDED
      data[P.AV_SOURCE][P.FAVOURITES][P.FAVOURITE] = {}
      data[P.AV_SOURCE][P.FAVOURITES][P.FAVOURITE][P.IMAGES] = {}
      data[P.AV_SOURCE][P.FAVOURITES][P.FAVOURITE][P.IMAGES][P.ORIGIN] =
        radio[P.DESCRIBE][KEY_LOGO]
      data[P.AV_SOURCE][P.FAVOURITES][P.FAVOURITE][P.NAME] =
        radio[P.DESCRIBE][P.NAME]
      data[P.AV_SOURCE][P.FAVOURITES][P.FAVOURITE][P.REMOVABLE] = true
      data[P.AV_SOURCE][P.FAVOURITES][P.FAVOURITE][P.SERVICE] = P.TUNEIN
      data[P.AV_SOURCE][P.FAVOURITES][P.FAVOURITE][P.TYPE] = P.RADIO
      data[P.AV_SOURCE][P.FAVOURITES][P.FAVOURITE][P.URI] =
        URI_PREFIX_TUNEIN + URI_PREFIX_RADIO + uuid

      this.sendMessage(data, 0)
    }
  }
  return P.OK
}

Demo.prototype.handlePlaylistFavourite = function (playerId, uuid, sourceUuid) {

  var favourites, favourite, playlists, playlistName, data, coverArtsArray

  favourites = this.data.favorites
  favourite = favourites[playerId]

  playlists = {}
  playlists[P.PLAYLIST] = uuid
  playlists[P.NAME] = uuid

  playlistName = this.data.playlistInfo[uuid]
    ? this.data.playlistInfo[uuid].name
    : ''

  favourite.playlists.push(playlists)

  this.sendFavouriteChanged(playerId)

  if (BasUtil.isNEString(sourceUuid)) {
    coverArtsArray = this.handlePlaylistCoverart(uuid)[P.COVERART]

    data = {}
    data[P.AV_SOURCE] = {}
    data[P.AV_SOURCE][P.UUID] = sourceUuid
    data[P.AV_SOURCE][P.FAVOURITES] = {}
    data[P.AV_SOURCE][P.FAVOURITES][P.EVENT] = P.ADDED
    data[P.AV_SOURCE][P.FAVOURITES][P.FAVOURITE] = {}
    data[P.AV_SOURCE][P.FAVOURITES][P.FAVOURITE][P.IMAGES] = {}

    if (coverArtsArray.length > 0) {
      data[P.AV_SOURCE][P.FAVOURITES][P.FAVOURITE][P.IMAGES][P.ORIGIN] =
          JSON.parse(coverArtsArray[0])[P.COVER]
    }

    data[P.AV_SOURCE][P.FAVOURITES][P.FAVOURITE][P.NAME] = playlistName
    data[P.AV_SOURCE][P.FAVOURITES][P.FAVOURITE][P.REMOVABLE] = true
    data[P.AV_SOURCE][P.FAVOURITES][P.FAVOURITE][P.SERVICE] = P.LOCAL
    data[P.AV_SOURCE][P.FAVOURITES][P.FAVOURITE][P.TYPE] = P.PLAYLIST
    data[P.AV_SOURCE][P.FAVOURITES][P.FAVOURITE][P.URI] =
        URI_PREFIX_LOCAL_PLAYLIST + uuid

    this.sendMessage(data, 0)
  }

  return P.OK
}

Demo.prototype.handleRemoveFavourite =
  function (playerId, name, sourceUuid, uri) {

    var favorites, favorite, playlists, radios, i, length, data

    favorites = this.data.favorites
    favorite = favorites[playerId]

    playlists = favorite.playlists
    radios = favorite.radios

    this.sendFavouriteChanged(playerId)

    length = playlists.length
    for (i = 0; i < length; i++) {

      if (playlists[i][P.NAME] === name) {

        data = {}
        data[P.AV_SOURCE] = {}
        data[P.AV_SOURCE][P.FAVOURITES] = {}
        data[P.AV_SOURCE][P.FAVOURITES][P.EVENT] = P.REMOVED
        data[P.AV_SOURCE][P.FAVOURITES][P.FAVOURITE] = {}
        data[P.AV_SOURCE][P.FAVOURITES][P.FAVOURITE][P.REMOVABLE] = true
        data[P.AV_SOURCE][P.FAVOURITES][P.FAVOURITE][P.URI] = uri
        data[P.AV_SOURCE][P.UUID] = sourceUuid

        playlists.splice(i, 1)

        this.sendMessage(data, 0)
        return P.OK
      }
    }

    length = radios.length

    for (i = 0; i < length; i++) {

      if (radios[i][P.NAME] === name) {

        data = {}

        data[P.AV_SOURCE] = {}
        data[P.AV_SOURCE][P.FAVOURITES] = {}
        data[P.AV_SOURCE][P.FAVOURITES][P.EVENT] = P.REMOVED
        data[P.AV_SOURCE][P.FAVOURITES][P.FAVOURITE] = {}
        data[P.AV_SOURCE][P.FAVOURITES][P.FAVOURITE][P.REMOVABLE] = true
        data[P.AV_SOURCE][P.FAVOURITES][P.FAVOURITE][P.URI] = uri
        data[P.AV_SOURCE][P.UUID] = sourceUuid

        radios.splice(i, 1)
        this.sendMessage(data, 0)
        return P.OK
      }
    }

    return P.OK
  }

Demo.prototype.handleDatabase = function (database, returnObj) {

  var player, playerId, overview, detail, playlist, coverart, playlistDetail

  player = returnObj.player
  playerId = player.id

  if (BasUtil.isObject(database[P.OVERVIEW])) {

    overview = database[P.OVERVIEW]

    switch (overview[P.TYPE]) {
      // Database songs
      case P.SONG:

        player[P.DATABASE] = this.handleSongList(
          overview[P.LIMIT],
          overview[P.PAGE]
        )

        break

      // Database artists
      case P.ARTIST:

        player[P.DATABASE] = this.handleArtistList()

        break

      // Database albums
      case P.ALBUM:

        player[P.DATABASE] = this.handleAlbumList()

        break
    }

  } else if (BasUtil.isObject(database[P.DETAIL])) {

    detail = database[P.DETAIL]

    switch (detail[P.TYPE]) {

      // Detail artists
      case P.ARTIST:

        player[P.DATABASE] =
          this.handleArtistDetail(detail[P.NAME])

        break

      // Detail albums
      case P.ALBUM:

        player[P.DATABASE] = this.handleAlbumDetail(
          detail.artist,
          detail.name
        )

        break
    }

  } else if (BasUtil.isObject(database[P.PLAYLIST])) {

    playlist = database[P.PLAYLIST]

    switch (playlist[P.ACTION]) {

      // List of playlists
      case P.LIST:

        player[P.DATABASE] = this.handlePlaylistList(playerId)

        break

      // Playlist songs
      case P.CONTENT:

        player[P.DATABASE] =
          this.handlePlaylistSongs(playlist[P.ID])

        break

      // Playlist detail
      case P.DETAIL:

        playlistDetail = this.handlePlaylistDetail(
          playerId,
          playlist[P.ID]
        )

        if (BasUtil.isObject(playlistDetail)) {

          player[P.DATABASE] = playlistDetail
          player[P.RESULT] = P.OK
        }

        break
    }

  } else if (BasUtil.isObject(database[P.COVERART])) {

    coverart = database[P.COVERART]

    //  Playlist coverart
    if (BasUtil.safeHasOwnProperty(coverart, P.PLAYLIST)) {

      player[P.DATABASE] =
        this.handlePlaylistCoverart(coverart[P.PLAYLIST])
    }
  }
}

Demo.prototype.handlePlayer = function (player, returnObj) {

  var song

  returnObj[P.PLAYER] = {}
  returnObj[P.PLAYER][P.ID] = player[P.ID]

  if (BasUtil.safeHasOwnProperty(player, P.ACTION)) {

    switch (player[P.ACTION]) {

      // Player status
      case P.STATUS:

        returnObj[P.PLAYER] =
          this.handlePlayerStatus(player[P.ID])

        break

      // Other radios
      case P.TUNEIN_OTHER_RADIOS:

        returnObj[P.PLAYER][P.RADIOS] =
          this.handleOtherRadios()

        break
    }

  } else if (BasUtil.safeHasOwnProperty(player, P.TUNEIN_GID)) {

    // Radio
    returnObj.player[P.PLAYLIST] =
      this.handleTuneIn(player[P.ID], player[P.TUNEIN_GID])

  } else if (BasUtil.safeHasOwnProperty(player, P.STATE)) {

    switch (player[P.STATE]) {

      // Next
      case P.NEXT:

        returnObj[P.PLAYER] = this.handleNext(player[P.ID])

        break

      // Pause
      case P.PAUSE:

        returnObj[P.PLAYER][P.STATE] = P.PAUSE

        break

      // Play
      case P.PLAY:

        returnObj[P.PLAYER][P.STATE] = P.PLAY

        break

      // Previous
      case P.PREVIOUS:

        returnObj[P.PLAYER] =
          this.handlePrevious(player[P.ID])

        break
    }

  } else if (BasUtil.isObject(player[P.SONG])) {

    // Song

    song = player[P.SONG]
    returnObj[P.PLAYER] = this.handleSong(
      player[P.ID],
      song[P.ID]
    )

  } else if (BasUtil.isObject(player[P.DATABASE])) {

    // Database

    this.handleDatabase(player[P.DATABASE], returnObj)

  } else if (BasUtil.isObject(player[P.PLAYLIST])) {

    // Playlists

    switch (player[P.PLAYLIST][P.ACTION]) {

      // Queue
      case P.CHANGES:

        returnObj[P.PLAYER][P.PLAYLIST] =
          this.handleQueue(player[P.ID])

        break

      // Adding
      case P.ADD:

        // Playlists
        if (player[P.PLAYLIST][P.PLAYLIST]) {

          switch (player[P.PLAYLIST][P.OPTION]) {

            case P.REPLACE_NOW:

              returnObj[P.PLAYER] =
                this.handleAddPlaylistReplace(
                  player[P.ID],
                  player[P.PLAYLIST][P.PLAYLIST]
                )

              break

            case P.END:

              returnObj[P.PLAYER] =
                this.handleAddPlaylistEnd(
                  player[P.ID],
                  player[P.PLAYLIST][P.PLAYLIST]
                )

              break

            case P.NEXT:

              returnObj[P.PLAYER] =
                this.handleAddPlaylistNext(
                  player[P.ID],
                  player[P.PLAYLIST][P.PLAYLIST]
                )

              break

            case P.NOW:

              returnObj[P.PLAYER] =
                this.handleAddPlaylistNow(
                  player[P.ID],
                  player[P.PLAYLIST][P.PLAYLIST]
                )

              break
          }

        } else if (player[P.PLAYLIST][P.SONGS]) {

          // Songs

          switch (player[P.PLAYLIST][P.OPTION]) {

            case P.REPLACE_NOW:

              returnObj[P.PLAYER] =
                this.handleAddSongsReplace(
                  player[P.ID],
                  player[P.PLAYLIST][P.SONGS]
                )

              break

            case P.END:

              returnObj[P.PLAYER] =
                this.handleAddSongsEnd(
                  player[P.ID],
                  player[P.PLAYLIST][P.SONGS]
                )

              break

            case P.NEXT:

              returnObj[P.PLAYER] =
                this.handleAddSongsNext(
                  player[P.ID],
                  player[P.PLAYLIST][P.SONGS]
                )

              break

            case P.NOW:

              returnObj[P.PLAYER] =
                this.handleAddSongsNow(
                  player[P.ID],
                  player[P.PLAYLIST][P.SONGS]
                )

              break
          }

        } else if (player[P.PLAYLIST][P.PATH]) {

          // Single Song

          if (player[P.PLAYLIST][P.OPTION] === P.NOW) {

            returnObj[P.PLAYER] =
              this.handleAddSongNow(
                player[P.ID],
                player[P.PLAYLIST][P.PATH]
              )
          }
        }
        break

      // Clear queue
      case P.CLEAR:

        returnObj[P.PLAYER] =
          this.handleClearQueue(player[P.ID])

        break
    }

  } else if (BasUtil.isObject(player[P.PRESET])) {

    // Presets

    if (player[P.PRESET][P.ACTION] === P.LIST) {

      returnObj[P.PLAYER][P.PRESET] =
        this.handlePresets(player[P.ID])
    }

  } else if (BasUtil.isObject(player[P.FAVOURITE])) {

    // Favourites

    switch (player[P.FAVOURITE][P.ACTION]) {

      case P.LIST:

        returnObj[P.PLAYER][P.FAVOURITE] =
          this.handleFavourites(player[P.ID])

        break

      case P.ADD_RADIO_STATION:

        returnObj[P.PLAYER][P.RESULT] =
          this.handleRadioFavourite(
            player[P.ID],
            player[P.FAVOURITE][P.GID]
          )

        break

      case P.ADD_PLAYLIST:

        returnObj[P.PLAYER][P.RESULT] =
          this.handlePlaylistFavourite(
            player[P.ID],
            player[P.FAVOURITE][P.PLAYLIST]
          )

        break

      case P.REMOVE:

        returnObj[P.PLAYER][P.RESULT] =
          this.handleRemoveFavourite(
            player[P.ID],
            player[P.FAVOURITE][P.NAME]
          )

        break
    }

  } else if (BasUtil.isObject(player[P.DEEZER])) {

    // Deezer

    if (player[P.DEEZER][P.TYPE] === P.STATUS) {

      returnObj[P.PLAYER][P.DEEZER] = this.handleDeezerLink()
    }

  } else if (BasUtil.isObject(player[P.TIDAL])) {

    // Tidal

    if (player[P.TIDAL][P.TYPE] === P.STATUS) {

      returnObj[P.PLAYER][P.TIDAL] = this.handleTidalLink()
    }
  }
}

Demo.prototype.handleUser = function (user, returnObj) {

  // Alarms

  if (BasUtil.isObject(user[P.ALARM])) {

    switch (user[P.ALARM][P.ACTION]) {

      case P.LIST:
        returnObj[P.USER] =
          this.handleAlarmList(returnObj[P.ID])
        break

      case P.UPDATE:
        returnObj[P.USER] = {}
        returnObj[P.USER][P.NAME] = user[P.NAME]
        returnObj[P.USER][P.RESULT] = P.OK
        break
    }
  }
}

Demo.prototype.handleStream = function (stream, returnObj) {

  returnObj[P.STREAM] = {}
  returnObj[P.STREAM][P.ID] = stream[P.ID]

  // Default rooms
  if (stream[P.ACTION] === P.LIST_DEFAULT_ROOMS) {

    returnObj[P.STREAM][P.DEFAULT_ROOMS] =
      this.handleDefaultRooms(stream[P.ID])
  }
}

Demo.prototype.handleDevice = function (device, returnObj) {

  var demoDevice = this.getDevice(device[P.UUID])

  returnObj[P.DEVICE] = {}
  returnObj[P.DEVICE][P.UUID] = device[P.UUID]

  if (demoDevice) {

    switch (demoDevice[P.TYPE]) {
      case P.LIGHT:
        this.handleLight(device, demoDevice, returnObj)
        break
      case P.WINDOW_TREATMENT:
        this.handleShade(device, demoDevice, returnObj)
        break
      case P.THERMOSTAT:
        this.handleThermostat(device, demoDevice, returnObj)
        break
      case P.SCENE_CONTROLLER:
        this.handleSceneController(device, demoDevice, returnObj)
        break
      case P.ENERGY_METER:
        this.handleEnergyMeter(device, demoDevice, returnObj)
        break
      case P.GENERIC:
        this.handleGeneric(device, demoDevice, returnObj)
        break
    }

    // Devices might have updated, resend messages
    this.sendMessages()
  }
}

Demo.prototype.handleZone = function (newZone, returnObj) {

  returnObj[P.ZONE] = {}
  returnObj[P.ZONE][P.ID] = newZone[P.ID]

  if (BasUtil.isNumber(newZone[P.VOLUME])) {

    returnObj[P.ZONE][P.VOLUME] = newZone[P.VOLUME]
    returnObj[P.ZONE][P.MUTED] = newZone[P.VOLUME] === 0

  } else if (BasUtil.isBool(newZone[P.MUTED])) {

    returnObj[P.ZONE][P.MUTED] = newZone[P.MUTED]
  }
}

Demo.prototype.handleSharedServerStorage = function (request, response) {

  var result, _keys, _storage, keys, length, i, _key, data

  if (BasUtil.isObject(request)) {

    response[P.STORAGE] = {}

    if (request[P.ACTION] === P.GET) {

      result = {}

      _keys = request[P.KEYS]

      if (Array.isArray(_keys)) {

        _storage = this.data.storage

        if (BasUtil.isObject(_storage)) {

          keys = Object.keys(_storage)
          length = _keys.length
          for (i = 0; i < length; i++) {

            _key = _keys[i]

            if (keys.indexOf(_key) !== -1) {

              data = _storage[_key]

              result[_key] = !BasUtil.isUndefined(data)
                ? JSON.parse(JSON.stringify(data))
                : null

            } else {

              result[_key] = null
            }
          }
        }
      }

      response[P.STORAGE][P.DATA] = result
      response[P.STORAGE][P.RESULT] = P.OK

    } else if (request[P.ACTION] === P.UPDATE) {

      data = request[P.DATA]

      if (
        data &&
        (
          data[SharedServerStorage.K_SCENES_FAVOURITES] ||
          data[SharedServerStorage.K_LIGHT_GROUP_ORDER]
        )
      ) {

        response[P.STORAGE][P.ACTION] = P.UPDATE
        response[P.STORAGE][P.DATA] = data
        response[P.STORAGE][P.RESULT] = P.OK
      }
    }
  }
}

Demo.prototype.handleCallHistory = function (request, response) {

  response[P.DATA] = {}

  if (BasUtil.isObject(request)) {

    switch (request[P.DATA][P.ACTION]) {
      case P.GET_SIP_ADDRESSES: {
        response[P.SUCCESS] = true
        response[P.DATA][P.SIP_ADDRESSES] = this.data.sipAddresses
        break
      }
      case P.GET_CALL_HISTORY:
        response[P.SUCCESS] = true
        response[P.DATA][P.CALL_HISTORY] = this.data.callHistory
        break
      case P.GET_CALL_DETAILS: {
        // Get images based on contact uuid and timestamp
        const entry = this.callHistory.find(el =>
          el[P.CONTACT][P.UUID] === request[P.DATA][P.UUID] &&
          el[P.TIMESTAMP] === request[P.DATA][P.TIMESTAMP])

        // Create response
        response[P.SUCCESS] = true
        response[P.DATA][P.IMAGES] = entry.images ?? null
        response[P.DATA][P.OFFSET] = request[P.DATA][P.OFFSET]
        response[P.DATA][P.LIMIT] = request[P.DATA][P.LIMIT]
        break
      }
      case P.GET_EXTRA_CAMERAS: {
        response[P.SUCCESS] = true
        response[P.DATA][P.CAMERAS] = []
        break
      }
    }
  }
}

Demo.prototype.handleRoom = function (request, response) {

  if (BasUtil.isObject(request)) {

    if (request[P.ACTION] === P.SET_IMAGE ||
      request[P.ACTION] === P.CLEAR_IMAGE) {

      response[P.ROOM] = {}

      response[P.ROOM][P.UUID] = request[P.UUID]
      response[P.ROOM][P.RESULT] = P.OK
    }

    if (request[P.AV]) {

      this.handleRoomAV(
        request[P.UUID],
        request[P.AV],
        response
      )
    }
  }
}

Demo.prototype.handleRoomAV = function (roomUuid, request, response) {

  var length, i, el
  var room, roomAudioState, oldSource, newSource, stateHasSource, stateHasOn
  var reqDsp, stateHasDsp, stateHasDspAction, roomEqualisers, reqEqualisers

  room = this.data.rooms.rooms[roomUuid]

  if (room) {

    roomAudioState = (
      room[P.AV] &&
      room[P.AV][P.AUDIO] &&
      room[P.AV][P.AUDIO][P.STATE]
    )
      ? room[P.AV][P.AUDIO][P.STATE]
      : null

    // Audio state

    if (
      request[P.AUDIO] &&
      request[P.AUDIO][P.STATE]
    ) {

      stateHasSource = BasUtil.isString(request[P.AUDIO][P.STATE][P.SOURCE])
      stateHasOn = BasUtil.isBool(request[P.AUDIO][P.STATE][P.ON])

      reqDsp = request[P.AUDIO][P.STATE][P.DSP]
      stateHasDsp = BasUtil.isObject(reqDsp)
      stateHasDspAction = stateHasDsp && BasUtil.isString(reqDsp[P.ACTION])

      if (stateHasDsp) {

        if (stateHasDspAction) {

          if (reqDsp[P.ACTION] === P.RESET) {

            // Reset dsp equalisers

            if (
              roomAudioState &&
              roomAudioState[P.DSP]
            ) {
              roomEqualisers = roomAudioState[P.DSP][P.EQUALISERS]

              if (Array.isArray(roomEqualisers)) {

                Demo.setAllEqualisers(roomEqualisers, 0)

                this.sendAvAudioDsp(roomUuid)
              }
            }
          }

        } else {

          // Update gains of dsp equalisers

          reqEqualisers = reqDsp[P.EQUALISERS]

          if (Array.isArray(reqEqualisers)) {

            if (
              roomAudioState &&
              roomAudioState[P.DSP]
            ) {
              roomEqualisers = roomAudioState[P.DSP][P.EQUALISERS]

              if (Array.isArray(roomEqualisers)) {

                length = reqEqualisers.length
                for (i = 0; i < length; i++) {

                  el = reqEqualisers[i]

                  if (
                    el &&
                    BasUtil.isPNumber(el[P.ID], true) &&
                    BasUtil.isVNumber(el[P.GAIN])
                  ) {
                    Demo.setEqualiserGain(roomEqualisers, el[P.ID], el[P.GAIN])
                  }
                }

                this.sendAvAudioDsp(roomUuid)
              }
            }
          }
        }
      }

      if (stateHasSource || stateHasOn) {

        oldSource = room[P.AV][P.AUDIO][P.STATE][P.SOURCE]

        if (stateHasSource) {

          newSource = request[P.AUDIO][P.STATE][P.SOURCE]

        } else if (stateHasOn) {

          newSource = request[P.AUDIO][P.STATE][P.ON]
            // Use first AV audio-source as default source
            ? Object.keys(this.data.avSources.avSources.audio)[0]
            : ''
        }

        const isSonos = room[P.AV][P.AUDIO][P.TYPE] === P.SONOS

        // When using the toggle switch on Sonos, we don't want to change
        // the source for Sonos. Sonos only pauses or plays the current source
        // when toggling it.
        if (!isSonos) {
          // Update source
          room[P.AV][P.AUDIO][P.STATE][P.SOURCE] = newSource
        }

        // If video is available, turn off
        if (
          room[P.AV] &&
          room[P.AV][P.VIDEO] &&
          room[P.AV][P.VIDEO][P.STATE]
        ) {
          room[P.AV][P.VIDEO][P.STATE][P.SOURCE] = ''
        }

        // Update AV Audio and AV Video and send out
        this.syncAVAudioState(roomUuid, isSonos
          ? !room[P.AV][P.AUDIO][P.STATE][P.ON]
          : null)

        // Update old and new source and send them out
        this.syncAVSourceState(oldSource)
        this.syncAVSourceState(newSource)
        this.sendAVSource(oldSource)
        this.sendAVSource(newSource)

        this.sendAVAudio(roomUuid)
        this.syncAVVideoState(roomUuid)
        this.sendAVVideo(roomUuid)

        this.updateVirtualSourceForRoomForId(roomUuid)
      }
    }

    // Video state

    if (
      request[P.VIDEO] &&
      request[P.VIDEO][P.STATE]
    ) {

      stateHasSource = BasUtil.isString(request[P.VIDEO][P.STATE][P.SOURCE])
      stateHasOn = BasUtil.isBool(request[P.VIDEO][P.STATE][P.ON])

      if (stateHasSource || stateHasOn) {

        if (stateHasSource) {

          newSource = request[P.VIDEO][P.STATE][P.SOURCE]

        } else if (stateHasOn) {

          newSource = request[P.VIDEO][P.STATE][P.ON]
            ? Object.keys(this.data.avSources.avSources.video)[0]
            : ''
        }

        // Update source
        room[P.AV][P.VIDEO][P.STATE][P.SOURCE] = newSource

        // If Audio is available, also set source there (Audio and video linked)
        if (
          room[P.AV] &&
          room[P.AV][P.AUDIO] &&
          room[P.AV][P.AUDIO][P.STATE]
        ) {
          room[P.AV][P.AUDIO][P.STATE][P.SOURCE] = newSource
        }

        // Update AV Audio and AV Video and send out
        this.syncAVVideoState(roomUuid)
        this.syncAVAudioState(roomUuid)
        this.sendAVVideo(roomUuid)
        this.sendAVAudio(roomUuid)

        this.updateVirtualSourceForRoomForId(roomUuid)
      }
    }
  }

  // Return OK result to avoid errors in UI
  response[P.ROOM] = {}
  response[P.ROOM][P.RESULT] = P.OK
}

Demo.prototype.handleAVSource = function (request, response) {

  var source, room, i, keys, length, playerId, defaultRooms, defaultRoomsMap
  var queue, favourites, favouritesOverview, favouritesList, playlists
  var playlistList, uri, playlist, tracks, sourceUuid, uuid
  var radio, radioName

  // Audio Source

  source = this.getAVAudioSourceForSourceUuid(request[P.UUID])
  playerId = this.getPlayerIdForSourceUuid(request[P.UUID])

  if (source && playerId !== null) {

    // Handle state changes

    if (request[P.STATE]) {

      if (BasUtil.isBool(request[P.STATE][P.ON])) {

        if (request[P.STATE][P.ON]) {

          // Turn on all default rooms for this source

          defaultRooms = this.data.defaultRooms[playerId]

          if (defaultRooms) {

            length = defaultRooms.length
            for (i = 0; i < length; i++) {

              room = this.data.rooms.rooms[defaultRooms[i]]

              if (
                room &&
                room[P.AV] &&
                room[P.AV][P.AUDIO] &&
                room[P.AV][P.AUDIO][P.STATE]
              ) {

                room[P.AV][P.AUDIO][P.STATE][P.SOURCE] = request[P.UUID]

                this.syncAVAudioState(room.uuid)
                this.sendAVAudio(room.uuid)
                this.syncAVSourceState(request[P.UUID])
                this.sendAVSource(request[P.UUID])
              }
            }
          }

        } else {

          // Turn off all rooms listening to this source

          keys = Object.keys(this.data.rooms.rooms)
          length = keys.length
          for (i = 0; i < length; i++) {

            room = this.data.rooms.rooms[keys[i]]

            if (
              room &&
              room[P.AV] &&
              room[P.AV][P.AUDIO] &&
              room[P.AV][P.AUDIO][P.STATE] &&
              room[P.AV][P.AUDIO][P.STATE][P.SOURCE] === request[P.UUID]
            ) {
              room[P.AV][P.AUDIO][P.STATE][P.SOURCE] = ''

              this.syncAVAudioState(keys[i])
              this.sendAVAudio(keys[i])
            }
          }
        }

        this.syncAVSourceState(request[P.UUID])
        this.sendAVSource(request[P.UUID])
      }

      if (AudioSource.isPlaybackMode(request[P.STATE][P.PLAYBACK])) {

        source[P.STATE][P.PLAYBACK] = request[P.STATE][P.PLAYBACK]

        this.sendAVSource(request[P.UUID])
      }
    }

    // Handle actions

    if (request[P.ACTION]) {

      switch (request[P.ACTION]) {

        case P.SKIP_PREVIOUS:
          this.handlePrevious(playerId)
          break

        case P.SKIP_NEXT:
          this.handleNext(playerId)
          break

        case P.PLAY_URI:

          uri = request[P.DATA][P.URI]

          // PlayUri: new context, should replace queue

          if (BasUtil.startsWith(uri, URI_PREFIX_TUNEIN)) {

            this.handleTuneIn(playerId, uri.slice(URI_PREFIX_TUNEIN.length))

          } else if (BasUtil.startsWith(uri, URI_PREFIX_LOCAL_PLAYLIST)) {

            this.handleAddPlaylistReplace(
              playerId,
              uri.slice(URI_PREFIX_LOCAL_PLAYLIST.length)
            )
          } else if (BasUtil.startsWith(uri, URI_PREFIX_LOCAL_TRACK)) {

            this.handleAddSongsReplace(
              playerId,
              [uri.slice(URI_PREFIX_LOCAL_TRACK.length)]
            )
          }
          break
      }
      this.syncAVSourceState(request[P.UUID])
      this.sendAVSource(request[P.UUID])
    }

    // Default rooms

    if (request[P.DEFAULT_ROOMS]) {

      if (request[P.DEFAULT_ROOMS][P.ACTION] === P.LIST) {

        defaultRooms = this.data.defaultRooms[playerId]
        defaultRoomsMap = {}

        keys = Object.keys(this.data.rooms.rooms)
        length = keys.length
        for (i = 0; i < length; i++) {

          defaultRoomsMap[keys[i]] = {}
          defaultRoomsMap[keys[i]][P.EDITABLE] = true
          defaultRoomsMap[keys[i]][P.DEFAULT_ROOM] = (
            defaultRooms &&
            defaultRooms.indexOf(keys[i]) !== -1
          )
        }

        response[P.AV_SOURCE] = {}
        response[P.AV_SOURCE][P.UUID] = request[P.UUID]
        response[P.AV_SOURCE][P.DEFAULT_ROOMS] = {}
        response[P.AV_SOURCE][P.DEFAULT_ROOMS][P.LIST] = defaultRoomsMap
      }
    }

    // Streaming services

    if (request[P.STREAMING_SERVICES]) {

      if (
        request[P.STREAMING_SERVICES][P.ACTION] === P.DETAIL &&
        request[P.STREAMING_SERVICES][P.DATA] &&
        request[P.STREAMING_SERVICES][P.DATA][P.STREAMING_SERVICE] === P.SPOTIFY
      ) {

        // Return dummy spotify token so app thinks spotify is connected

        response[P.AV_SOURCE] = {}
        response[P.AV_SOURCE][P.UUID] = request[P.UUID]
        response[P.AV_SOURCE][P.STREAMING_SERVICES] = {}
        response[P.AV_SOURCE][P.STREAMING_SERVICES][P.DETAIL] = {}
        response[P.AV_SOURCE][P.STREAMING_SERVICES][P.DETAIL][P.TOKEN] = ':)'
        response[P.AV_SOURCE][P.STREAMING_SERVICES][P.STREAMING_SERVICE] =
          P.SPOTIFY
      }
    }

    // Queue

    if (request[P.QUEUE]) {

      queue = this.data.queue[playerId]

      if (queue) {

        if (request[P.QUEUE][P.ACTION] === P.LIST) {

          response[P.AV_SOURCE] = {}
          response[P.AV_SOURCE][P.UUID] = request[P.UUID]
          response[P.AV_SOURCE][P.QUEUE] = {}
          response[P.AV_SOURCE][P.QUEUE][P.CONTENT_TYPE] = 'local'
          response[P.AV_SOURCE][P.QUEUE][P.LIST] = []
          response[P.AV_SOURCE][P.QUEUE][P.TOTAL] = queue.songs.length
          response[P.AV_SOURCE][P.QUEUE][P.OFFSET] = 0

          length = queue.songs.length
          for (i = 0; i < length; i++) {

            response[P.AV_SOURCE][P.QUEUE][P.LIST]
              .push(this.convertSongToTrack(queue.songObjs[i]))
          }
        } else if (request[P.QUEUE][P.ACTION] === P.PLAY) {

          this.handlePlayerStatus(
            playerId,
            request[P.QUEUE][P.DATA][P.POSITION]
          )
          this.syncAVSourcesState(request[P.UUID])
          this.sendAVSource(request[P.UUID])

          response[P.AV_SOURCE] = {}
          response[P.AV_SOURCE][P.UUID] = request[P.UUID]
          response[P.AV_SOURCE][P.QUEUE] = {}
          response[P.AV_SOURCE][P.QUEUE][P.CONTENT_TYPE] = 'local'
          response[P.AV_SOURCE][P.QUEUE][P.LIST] = []
          response[P.AV_SOURCE][P.QUEUE][P.TOTAL] = queue.songs.length
          response[P.AV_SOURCE][P.QUEUE][P.OFFSET] = 0

          length = queue.songs.length
          for (i = 0; i < length; i++) {

            response[P.AV_SOURCE][P.QUEUE][P.LIST]
              .push(this.convertSongToTrack(queue.songObjs[i]))
          }
        } else if (request[P.QUEUE][P.ACTION] === P.ADD) {

          uri = request[P.QUEUE][P.DATA][P.URI]

          // TODO: respect 'option' parameter.

          if (BasUtil.isNEString(request[P.QUEUE][P.DATA][P.URI])) {

            if (BasUtil.startsWith(uri, URI_PREFIX_TUNEIN)) {

              this.handleTuneIn(playerId, uri.slice(URI_PREFIX_TUNEIN.length))

            } else if (BasUtil.startsWith(uri, URI_PREFIX_LOCAL_PLAYLIST)) {

              this.handleAddPlaylistReplace(
                playerId,
                uri.slice(URI_PREFIX_LOCAL_PLAYLIST.length)
              )
            } else if (BasUtil.startsWith(uri, URI_PREFIX_LOCAL_TRACK)) {

              this.handleAddSongNow(
                playerId,
                uri.slice(URI_PREFIX_LOCAL_TRACK.length)
              )
            }

          } else if (Array.isArray(request[P.QUEUE][P.DATA][P.TRACKS])) {

            this.handleAddSongNow(
              playerId,
              request[P.QUEUE][P.DATA][P.TRACKS][0]
                .slice(URI_PREFIX_LOCAL_TRACK.length)
            )
          }

          this.syncAVSourceState(request[P.UUID])
          this.sendAVSource(request[P.UUID])
        }
      }
    }

    // Favourites

    if (request[P.FAVOURITES]) {

      favourites = this.data.favorites[playerId]

      if (request[P.FAVOURITES][P.ACTION] === P.LIST && favourites) {

        if (request[P.FAVOURITES][P.DATA]) {

          // Data available: service specific listing

          favouritesList = []

          switch (request[P.FAVOURITES][P.DATA][P.SERVICE]) {
            case P.LOCAL:

              if (favourites.playlists) {

                length = favourites.playlists.length
                for (i = 0; i < length; i++) {

                  favouritesList.push(
                    this.getFavouriteForPlaylistId(
                      favourites.playlists[i].playlist
                    )
                  )
                }
              }
              break

            case P.TUNEIN:

              if (favourites.radios) {

                length = favourites.radios.length
                for (i = 0; i < length; i++) {

                  favouritesList.push(
                    this.getFavouriteForRadioGid(
                      favourites.radios[i].gid
                    )
                  )
                }
              }
              break
          }

          response[P.AV_SOURCE] = {}
          response[P.AV_SOURCE][P.UUID] = request[P.UUID]
          response[P.AV_SOURCE][P.FAVOURITES] = {}
          response[P.AV_SOURCE][P.FAVOURITES][P.LIST] = favouritesList
          response[P.AV_SOURCE][P.FAVOURITES][P.TOTAL] = favouritesList.length
          response[P.AV_SOURCE][P.FAVOURITES][P.OFFSET] = 0

        } else {

          // No data available, send favourites services overview

          favouritesOverview = {}

          if (favourites.radios) {
            favouritesOverview[P.TUNEIN] = favourites.radios.length
          }

          if (favourites.playlists) {
            favouritesOverview[P.LOCAL] = favourites.playlists.length
          }

          response[P.AV_SOURCE] = {}
          response[P.AV_SOURCE][P.UUID] = request[P.UUID]
          response[P.AV_SOURCE][P.FAVOURITES] = {}
          response[P.AV_SOURCE][P.FAVOURITES][P.SERVICES] = favouritesOverview
        }
      } else if (request[P.FAVOURITES][P.ACTION] === P.ADD) {

        sourceUuid = request[P.UUID]
        uri = request[P.FAVOURITES][P.DATA][P.URI]

        if (BasUtil.startsWith(uri, URI_PREFIX_TUNEIN)) {

          uuid = uri.slice((URI_PREFIX_TUNEIN + URI_PREFIX_RADIO).length)

          this.handleRadioFavourite(playerId, uuid, sourceUuid)

        } else if (BasUtil.startsWith(uri, URI_PREFIX_LOCAL_PLAYLIST)) {

          uuid = uri.slice(URI_PREFIX_LOCAL_PLAYLIST.length)

          this.handlePlaylistFavourite(playerId, uuid, sourceUuid)

        }
      } else if (request[P.FAVOURITES][P.ACTION] === P.REMOVE) {

        uri = request[P.FAVOURITES][P.DATA][P.URI]
        sourceUuid = request[P.UUID]

        if (BasUtil.startsWith(uri, URI_PREFIX_TUNEIN)) {

          uuid = uri.slice((URI_PREFIX_TUNEIN + URI_PREFIX_RADIO).length)
          radio = this.data.radios[uuid]

          if (radio) {
            radioName = radio[P.DESCRIBE][P.NAME]

            this.handleRemoveFavourite(playerId, radioName, sourceUuid, uri)
          }
        } else if (BasUtil.startsWith(uri, URI_PREFIX_LOCAL_PLAYLIST)) {

          uuid = uri.slice(URI_PREFIX_LOCAL_PLAYLIST.length)

          if (BasUtil.isNEString(uuid)) {

            this.handleRemoveFavourite(playerId, uuid, sourceUuid, uri)
          }
        }
      }
    }

    // Playlists

    if (request[P.PLAYLISTS]) {

      playlists = this.data.playlists[playerId]

      if (playlists) {

        // List action

        if (request[P.PLAYLISTS][P.ACTION] === P.LIST) {

          if (
            request[P.PLAYLISTS][P.DATA] &&
            request[P.PLAYLISTS][P.DATA][P.TYPE]
          ) {

            playlistList = []

            switch (request[P.PLAYLISTS][P.DATA][P.TYPE]) {
              case P.ITUNES:

                playlistList = this.convertPlaylistsToNewApi(
                  playlists.iTunesPlaylists,
                  P.ITUNES
                )
                break

              case P.LOCAL:

                playlistList = this.convertPlaylistsToNewApi(
                  playlists.playlists,
                  P.LOCAL
                )
                break

              case P.SHARED:

                playlistList = this.convertPlaylistsToNewApi(
                  playlists.sharedPlaylists,
                  P.SHARED
                )
                break

              default:

                playlistList = []
            }

            response[P.AV_SOURCE] = {}
            response[P.AV_SOURCE][P.UUID] = request[P.UUID]
            response[P.AV_SOURCE][P.PLAYLISTS] = {}
            response[P.AV_SOURCE][P.PLAYLISTS][P.LIST] = playlistList
            response[P.AV_SOURCE][P.PLAYLISTS][P.TOTAL] = playlistList.length
            response[P.AV_SOURCE][P.PLAYLISTS][P.OFFSET] = 0
          }
        }

        // Detail action

        if (
          request[P.PLAYLISTS][P.ACTION] === P.DETAIL &&
          request[P.PLAYLISTS][P.DATA] &&
          BasUtil.isNEString(request[P.PLAYLISTS][P.DATA][P.URI])
        ) {

          uri = request[P.PLAYLISTS][P.DATA][P.URI]
            .slice(URI_PREFIX_LOCAL_PLAYLIST.length)

          playlist = this.data.playlistInfo[uri]

          if (playlist) {

            tracks = []

            length = playlist.songs.length
            for (i = 0; i < length; i++) {

              tracks.push(
                this.convertSongToTrack(
                  this.getSongForId(playlist.songs[i])
                )
              )
            }

            response[P.AV_SOURCE] = {}
            response[P.AV_SOURCE][P.UUID] = request[P.UUID]
            response[P.AV_SOURCE][P.PLAYLISTS] = {}
            response[P.AV_SOURCE][P.PLAYLISTS][P.PLAYLIST] =
              this.convertPlaylistToNewApi(playlist, P.LOCAL)
            response[P.AV_SOURCE][P.PLAYLISTS][P.PLAYLIST][P.TRACKS] = tracks
            response[P.AV_SOURCE][P.PLAYLISTS][P.TOTAL] = tracks.length
            response[P.AV_SOURCE][P.PLAYLISTS][P.OFFSET] = 0
          }
        }
      }
    }
  }

  // Sonos

  if (source?.type === P.SONOS) {
    room = Object.values(this.data.rooms.rooms)
      .filter((el) => el.av?.audio.type === P.SONOS)[0]
    uuid = request[P.UUID]

    switch (request[P.ACTION]) {

      case P.LIST_FAVOURITES: {
        favourites = this.data.favorites[uuid]
        const mappedFavourites = Object.keys(favourites).map(key => {
          const favourite = favourites[key]
          return {
            images: favourite.current.album.images,
            name: favourite.current.title,
            removable: true,
            service: P.SONOS,
            uri: favourite.current.uri
          }
        })

        response[P.AV_SOURCE] = {}
        response[P.AV_SOURCE][P.UUID] = uuid
        response[P.AV_SOURCE][P.FAVOURITES] = {}
        response[P.AV_SOURCE][P.FAVOURITES][P.LIST] =
          mappedFavourites
        response[P.AV_SOURCE][P.FAVOURITES][P.OFFSET] =
          request[P.DATA][P.OFFSET]
        response[P.AV_SOURCE][P.FAVOURITES][P.TOTAL] =
          mappedFavourites.length

        break
      }

      case P.PLAY_URI: {

        uri = request[P.DATA][P.URI]
        const favourite = this.data.favorites[uuid][uri]

        if (favourite) {

          const msg = {}
          msg[P.AV_SOURCE] = {}
          msg[P.AV_SOURCE][P.REACHABLE] = true
          msg[P.AV_SOURCE][P.UUID] = uuid
          msg[P.AV_SOURCE][P.STATE] = {}
          msg[P.AV_SOURCE][P.STATE][P.ON] = true
          msg[P.AV_SOURCE][P.STATE][P.NOW_PLAYING] = {}
          msg[P.AV_SOURCE][P.STATE][P.NOW_PLAYING][P.CURRENT] =
            favourite.current
          msg[P.AV_SOURCE][P.STATE][P.NOW_PLAYING][P.NEXT] =
            favourite.next
          this.sendMessage(msg)

          this.syncAVSourceState(uuid, favourite.current)
        }

        break
      }
      case P.PLAY_PAUSE: {

        if (room) {

          this.syncAVAudioState(
            room.uuid,
            !room[P.AV][P.AUDIO][P.STATE][P.ON]
          )
          this.syncAVSourceState(uuid)
          this.sendAVAudio(room.uuid)
        }

        break
      }
    }
  }

  // Video source

  source = this.getAVVideoSourceForSourceUuid(request[P.UUID])

  if (source) {

    if (request[P.FAVOURITES]) {

      if (request[P.FAVOURITES][P.ACTION] === P.LIST) {

        favourites = this.data.favorites[request[P.UUID]]

        if (favourites.video) {

          response[P.AV_SOURCE] = {}
          response[P.AV_SOURCE][P.UUID] = request[P.UUID]
          response[P.AV_SOURCE][P.FAVOURITES] = {}
          response[P.AV_SOURCE][P.FAVOURITES][P.LIST] = favourites.video
          response[P.AV_SOURCE][P.FAVOURITES][P.TOTAL] = favourites.video.length
          response[P.AV_SOURCE][P.FAVOURITES][P.OFFSET] = 0
        }

      } else if (request[P.FAVOURITES][P.ACTION] === P.LIST_CANDIDATES) {

        const data = this.data.candidates

        response[P.AV_SOURCE] = {}
        response[P.AV_SOURCE][P.UUID] = request[P.UUID]
        response[P.AV_SOURCE][P.FAVOURITES] = {}
        response[P.AV_SOURCE][P.FAVOURITES][P.CANDIDATES] = data
        response[P.AV_SOURCE][P.FAVOURITES][P.OFFSET] = 0
        response[P.AV_SOURCE][P.FAVOURITES][P.TOTAL] = data.length

      } else if (request[P.FAVOURITES][P.ACTION] === P.REMOVE) {

        const data = request.favourites.data
        favourites = this.data.favorites[request[P.UUID]].video

        // Keep this.data.favorites up to date
        favourites.forEach((fav, idx) => {
          if (fav[P.URI] === data[P.URI]) {
            favourites.splice(idx, 1)
          }
        })

        this.sendMessage({
          [P.AV_SOURCE]: {
            [P.FAVOURITES]: {
              [P.EVENT]: P.REMOVED,
              [P.FAVOURITE]: {
                [P.REMOVABLE]: true,
                [P.URI]: request.favourites.data[P.URI]
              }
            },
            [P.UUID]: request[P.UUID]
          }
        })

      } else if (request[P.FAVOURITES][P.ACTION] === P.ADD) {

        const data = request.favourites.data
        favourites = this.data.favorites[request[P.UUID]]

        // Keep this.data.favorites up to date
        favourites.video.push({
          [P.NAME]: data[P.NAME],
          [P.URI]: data[P.URI],
          [P.REMOVABLE]: true
        })

        this.sendMessage({
          [P.AV_SOURCE]: {
            [P.FAVOURITES]: {
              [P.EVENT]: P.ADDED,
              [P.FAVOURITE]: {
                [P.NAME]: request.favourites.data[P.NAME],
                [P.REMOVABLE]: true,
                [P.URI]: request.favourites.data[P.URI]
              }
            },
            [P.UUID]: request[P.UUID]
          }
        })
      }
    }
  }

  response[P.SUCCESS] = true
}

/**
 * @param {Object} request
 * @param {Object} response
 */
Demo.prototype.handleLibrary = function (request, response) {

  var offset, limit, i, length, songs, artists, artistName, artist
  var list, dataElement, name, tracks, song

  // Tracks requests

  if (request[P.TRACKS]) {

    if (
      request[P.TRACKS][P.ACTION] === P.LIST &&
      request[P.TRACKS][P.DATA] &&
      BasUtil.isPNumber(request[P.TRACKS][P.DATA][P.OFFSET], true) &&
      BasUtil.isPNumber(request[P.TRACKS][P.DATA][P.LIMIT], false)
    ) {

      offset = request[P.TRACKS][P.DATA][P.OFFSET]
      limit = request[P.TRACKS][P.DATA][P.LIMIT]

      if (offset < this.data.songs.length) {

        response[P.LIBRARY] = {}
        response[P.LIBRARY][P.TRACKS] = {}
        response[P.LIBRARY][P.TRACKS][P.TOTAL] = this.data.songs.length
        response[P.LIBRARY][P.TRACKS][P.OFFSET] = offset
        response[P.LIBRARY][P.TRACKS][P.LIST] = []

        songs = this.data.songs.slice(offset, offset + limit)
        length = songs.length
        for (i = 0; i < length; i++) {

          response[P.LIBRARY][P.TRACKS][P.LIST]
            .push(this.convertSongToTrack(songs[i]))
        }
      }
    }
  }

  // Artists requests

  if (request[P.ARTISTS]) {

    // List action

    if (
      request[P.ARTISTS][P.ACTION] === P.LIST &&
      request[P.ARTISTS][P.DATA] &&
      BasUtil.isPNumber(request[P.ARTISTS][P.DATA][P.OFFSET], true) &&
      BasUtil.isPNumber(request[P.ARTISTS][P.DATA][P.LIMIT], false)
    ) {

      offset = request[P.ARTISTS][P.DATA][P.OFFSET]
      limit = request[P.ARTISTS][P.DATA][P.LIMIT]

      artists = this.getArtistsList()

      response[P.LIBRARY] = {}
      response[P.LIBRARY][P.ARTISTS] = {}
      response[P.LIBRARY][P.ARTISTS][P.TOTAL] = artists.length
      response[P.LIBRARY][P.ARTISTS][P.OFFSET] = offset
      response[P.LIBRARY][P.ARTISTS][P.LIST] = []

      artists = artists.slice(offset, offset + limit)
      length = artists.length

      for (i = 0; i < length; i++) {

        artistName = artists[i]

        artist = {}
        artist[P.NAME] = artistName
        artist[P.URI] = URI_PREFIX_LOCAL_ARTIST + artistName

        response[P.LIBRARY][P.ARTISTS][P.LIST].push(artist)
      }
    }

    // Detail action

    if (
      request[P.ARTISTS][P.ACTION] === P.DETAIL &&
      request[P.ARTISTS][P.DATA] &&
      BasUtil.isNEString(request[P.ARTISTS][P.DATA][P.URI]) &&
      BasUtil.isPNumber(request[P.ARTISTS][P.DATA][P.OFFSET], true) &&
      BasUtil.isPNumber(request[P.ARTISTS][P.DATA][P.LIMIT], false)
    ) {

      offset = request[P.ARTISTS][P.DATA][P.OFFSET]
      limit = request[P.ARTISTS][P.DATA][P.LIMIT]

      name = request[P.ARTISTS][P.DATA][P.URI]
        .slice(URI_PREFIX_LOCAL_ARTIST.length)
      list = this.getArtistsList()

      if (list.indexOf(name) !== -1) {

        tracks = []

        length = this.data.songs.length
        for (i = 0; i < length; i++) {

          song = this.data.songs[i]

          if (song[P.ARTIST] === name) {

            tracks.push(this.convertSongToTrack(song))
          }
        }

        tracks = tracks.slice(offset, offset + limit)

        response[P.LIBRARY] = {}
        response[P.LIBRARY][P.ARTISTS] = {}
        response[P.LIBRARY][P.ARTISTS][P.DETAIL] = {}
        response[P.LIBRARY][P.ARTISTS][P.DETAIL][P.NAME] = name
        response[P.LIBRARY][P.ARTISTS][P.DETAIL][P.URI] =
          request[P.ARTISTS][P.DATA][P.URI]
        response[P.LIBRARY][P.ARTISTS][P.DETAIL][P.TRACKS] = tracks
        response[P.LIBRARY][P.ARTISTS][P.DETAIL][P.OFFSET] = 0
        response[P.LIBRARY][P.ARTISTS][P.DETAIL][P.TOTAL] = tracks.length
      }
    }
  }

  // Album requests

  if (request[P.ALBUMS]) {

    // List action

    if (
      request[P.ALBUMS][P.ACTION] === P.LIST &&
      request[P.ALBUMS][P.DATA] &&
      BasUtil.isPNumber(request[P.ALBUMS][P.DATA][P.OFFSET], true) &&
      BasUtil.isPNumber(request[P.ALBUMS][P.DATA][P.LIMIT], false)
    ) {

      offset = request[P.ALBUMS][P.DATA][P.OFFSET]
      limit = request[P.ALBUMS][P.DATA][P.LIMIT]

      list = this.getAlbumsList()

      response[P.LIBRARY] = {}
      response[P.LIBRARY][P.ALBUMS] = {}
      response[P.LIBRARY][P.ALBUMS][P.TOTAL] = list.length
      response[P.LIBRARY][P.ALBUMS][P.OFFSET] = offset
      response[P.LIBRARY][P.ALBUMS][P.LIST] = []

      list = list.slice(offset, offset + limit)
      length = list.length

      for (i = 0; i < length; i++) {

        dataElement = this.convertAlbumToNewApi(list[i])

        response[P.LIBRARY][P.ALBUMS][P.LIST].push(dataElement)
      }
    }

    // Detail action

    if (
      request[P.ALBUMS][P.ACTION] === P.DETAIL &&
      request[P.ALBUMS][P.DATA] &&
      BasUtil.isNEString(request[P.ALBUMS][P.DATA][P.URI]) &&
      BasUtil.isPNumber(request[P.ALBUMS][P.DATA][P.OFFSET], true) &&
      BasUtil.isPNumber(request[P.ALBUMS][P.DATA][P.LIMIT], false)
    ) {

      offset = request[P.ALBUMS][P.DATA][P.OFFSET]
      limit = request[P.ALBUMS][P.DATA][P.LIMIT]

      name = request[P.ALBUMS][P.DATA][P.URI]
        .slice(URI_PREFIX_LOCAL_ALBUM.length)
      list = this.getAlbumsList()

      length = list.length
      for (i = 0; i < length; i++) {

        if (list[i].name === name) {

          dataElement = list[i]
          break
        }
      }

      if (dataElement) {

        tracks = []

        length = this.data.songs.length
        for (i = 0; i < length; i++) {

          song = this.data.songs[i]

          if (
            BasUtil.isObject(song[P.ALBUM]) &&
            song[P.ALBUM][P.NAME] === name
          ) {

            tracks.push(this.convertSongToTrack(song))
          }
        }

        tracks = tracks.slice(offset, offset + limit)

        response[P.LIBRARY] = {}
        response[P.LIBRARY][P.ALBUMS] = {}
        response[P.LIBRARY][P.ALBUMS][P.DETAIL] =
          this.convertAlbumToNewApi(dataElement)
        response[P.LIBRARY][P.ALBUMS][P.DETAIL][P.TRACKS] = tracks
        response[P.LIBRARY][P.ALBUMS][P.DETAIL][P.OFFSET] = 0
        response[P.LIBRARY][P.ALBUMS][P.DETAIL][P.TOTAL] = tracks.length
      }
    }
  }
}

// region Helper functions

/**
 * @param {number} id
 * @returns {?Object}
 */
Demo.prototype.getOriginalSongForId = function (id) {

  var songs, i, length

  songs = this.data.songs

  length = songs.length
  for (i = 0; i < length; i++) {

    if (songs[i][P.ID] === id) return songs[i]
  }

  return null
}

/**
 * @param {string} file
 * @returns {?Object}
 */
Demo.prototype.getOriginalSongForFile = function (file) {

  var songs, i, length

  songs = this.data.songs

  length = songs.length
  for (i = 0; i < length; i++) {

    if (songs[i][P.FILE] === file) return songs[i]
  }

  return null
}

/**
 * @param {number} playerId
 * @returns {?Object}
 */
Demo.prototype.getPlayerForId = function (playerId) {

  var players, i, length

  players = this.data.players

  length = players.length
  for (i = 0; i < length; i++) {

    if (players[i][P.ID] === playerId) return players[i]
  }

  return null
}

/**
 * @param {Array} array
 * @returns {Array}
 */
Demo.prototype.processSongsArray = function (array) {

  var songs, i, length, song, copy

  songs = []

  length = array.length
  for (i = 0; i < length; i++) {

    song = this.getOriginalSongForId(array[i])

    if (BasUtil.isObject(song)) {

      copy = BasUtil.copyObject(song)
      songs.push(copy)
    }
  }

  return songs
}

/**
 * @param {Object} song
 * @returns {?Object}
 */
Demo.prototype.convertSongToTrack = function (song) {

  var track

  if (!song) return null

  track = {}

  track[P.ID] = song.id
  track[P.URI] = song.file
  track[P.ALBUM] = {}

  // Album

  track[P.ALBUM][P.ARTIST] = {}
  track[P.ALBUM][P.ARTIST][P.NAME] = song.album.artist
  track[P.ALBUM][P.ARTIST][P.URI] = ''

  track[P.ALBUM][P.NAME] = song.album.name
  track[P.ALBUM][P.URI] = ''
  track[P.ALBUM][P.IMAGES] = this.getImagesForSong(song)

  // Artist

  track[P.ARTIST] = {}
  track[P.ARTIST][P.NAME] = song.artist
  track[P.ARTIST][P.URI] = ''
  track[P.DURATION_MS] = song.length * 100
  track[P.TITLE] = song.title
  track[P.URI] = URI_PREFIX_LOCAL_TRACK + song.file

  return track
}

/**
 * @param {Object} radio
 * @param {string} tuneInGid
 * @returns {?Object}
 */
Demo.prototype.convertRadioToTrack = function (radio, tuneInGid) {
  var track = {}

  if (!radio && !BasUtil.isNEString(tuneInGid)) return null

  track[P.ALBUM] = {}

  track[P.ALBUM][P.IMAGES] = this.getImagesFromRadioStation(radio)
  track[P.ARTIST] = {}
  track[P.ARTIST][P.NAME] = ''
  track[P.ARTIST][P.URI] = ''
  track[P.TITLE] = radio[P.DESCRIBE][P.NAME]
  track[P.URI] = URI_PREFIX_TUNEIN + URI_PREFIX_RADIO + tuneInGid

  return track
}

/**
 * @param {string} sourceUuid
 * @returns {?number}
 */
Demo.prototype.getPlayerIdForSourceUuid = function (sourceUuid) {

  var length, i

  length = this.data.music.music.sources.length
  for (i = 0; i < length; i++) {

    if (
      this.data.music.music.sources[i].uuid === sourceUuid &&
      this.data.music.music.sources[i].type === 'player'
    ) {

      return this.data.music.music.sources[i].id
    }
  }

  return null
}
/**
 * @param {number} playerId
 * @returns {string}
 */
Demo.prototype.getSourceUuidForPlayerId = function (playerId) {

  var length, i

  length = this.data.music.music.sources.length
  for (i = 0; i < length; i++) {

    if (
      this.data.music.music.sources[i].id === playerId &&
      this.data.music.music.sources[i].type === 'player'
    ) {

      return this.data.music.music.sources[i].uuid
    }
  }

  return ''
}

/**
 * @param {string} sourceUuid
 * @returns {?number}
 */
Demo.prototype.getAVAudioSourceForSourceUuid = function (sourceUuid) {

  var source

  if (
    this.data.avSources &&
    this.data.avSources.avSources &&
    this.data.avSources.avSources.audio
  ) {

    source = this.data.avSources.avSources.audio[sourceUuid]
    if (source) return source
  }

  return null
}

/**
 * @param {string} sourceUuid
 * @returns {?number}
 */
Demo.prototype.getAVVideoSourceForSourceUuid = function (sourceUuid) {

  var source

  if (
    this.data.avSources &&
    this.data.avSources.avSources &&
    this.data.avSources.avSources.video
  ) {

    source = this.data.avSources.avSources.video[sourceUuid]
    if (source) return source
  }

  return null
}

/**
 * @param {string} playlistId
 * @returns {Object}
 */
Demo.prototype.getFavouriteForPlaylistId = function (playlistId) {

  var favourite, playlist, song

  playlist = this.data.playlistInfo[playlistId]

  if (playlist) {

    favourite = {}

    favourite[P.NAME] = playlist.name
    favourite[P.REMOVABLE] = true
    favourite[P.SERVICE] = P.LOCAL
    favourite[P.SIZE] = playlist.songs.length
    favourite[P.TYPE] = P.PLAYLIST
    favourite[P.URI] = URI_PREFIX_LOCAL_PLAYLIST + playlistId

    song = this.getSongForId(playlist.songs[0])

    if (song) {

      favourite[P.IMAGES] = this.getImagesForSong(song)
    }

    return favourite
  }

  return null
}

/**
 * @param {string} radioGid
 * @returns {Object}
 */
Demo.prototype.getFavouriteForRadioGid = function (radioGid) {

  var radio = this.data.radios[radioGid]
  var newRadioObj = {}

  if (radio) {

    newRadioObj[P.URI] = URI_PREFIX_TUNEIN + URI_PREFIX_RADIO + radioGid
    newRadioObj[P.SERVICE] = P.TUNEIN
    newRadioObj[P.IMAGES] = {}
    newRadioObj[P.IMAGES][P.ORIGIN] = radio[P.DESCRIBE][KEY_LOGO]
    newRadioObj[P.NAME] = radio[P.DESCRIBE][P.NAME]

    return newRadioObj
  }

  return null
}

/**
 * @param {Object} song
 * @returns {Object}
 */
Demo.prototype.getImagesForSong = function (song) {

  var images, covers

  images = {}

  if (song) {

    covers = JSON.parse(song.coverart)

    images[P.ORIG] = covers.cover
    images.imgw500 = covers.cover
    images.imgw100 = covers.thumb
  }

  return images
}

/**
 * @param {Object} radioStation
 * @returns {Object}
 */
Demo.prototype.getImagesFromRadioStation = function (radioStation) {

  var images = {}

  if (radioStation) images[P.ORIG] = radioStation[P.DESCRIBE][KEY_LOGO]

  return images
}
/**
 * @param {string} playlistId
 * @returns {Object}
 */
Demo.prototype.getImagesForPlaylist = function (playlistId) {

  var playlist, images, songImages, length, i, keys, j, jLength

  playlist = this.data.playlistInfo[playlistId]

  images = {}

  if (playlist) {

    length = Math.min(4, playlist.songs.length)
    for (i = 0; i < length; i++) {

      songImages = this.getImagesForSong(this.getSongForId(playlist.songs[i]))

      keys = Object.keys(songImages)
      jLength = keys.length

      for (j = 0; j < jLength; j++) {

        if (!Array.isArray(images[keys[j]])) {
          images[keys[j]] = []
        }

        images[keys[j]].push(songImages[keys[j]])
      }
    }
  }

  return images
}

/**
 * @param {number} songId
 * @returns {Object}
 */
Demo.prototype.getSongForId = function (songId) {

  var i, length

  length = this.data.songs.length
  for (i = 0; i < length; i++) {

    if (this.data.songs[i].id === songId) {

      return this.data.songs[i]
    }
  }

  return null
}

Demo.prototype.getArtistsList = function () {

  var artists, artist, i, length

  artists = []

  length = this.data.songs.length
  for (i = 0; i < length; i++) {

    artist = this.data.songs[i][P.ARTIST]

    if (artists.indexOf(artist) === -1) artists.push(artist)
  }

  return artists
}

Demo.prototype.getAlbumsList = function () {

  var songs, albumList, i, length, album

  songs = this.data.songs

  albumList = []

  length = songs.length
  for (i = 0; i < length; i++) {

    album = songs[i][P.ALBUM]

    if (contains(albumList, album, P.NAME) === -1) {

      album[P.COVERART] = songs[i][P.COVERART]
      albumList.push(album)
    }
  }

  function contains (array, object, property) {
    var _length, _i

    _length = array.length
    for (_i = 0; _i < _length; _i++) {
      if (array[_i][property] === object[property]) return 1
    }
    return -1
  }

  return albumList
}

/**
 * @param {Object[]} playlists
 * @param type
 * @returns {Object[]}
 */
Demo.prototype.convertPlaylistsToNewApi = function (playlists, type) {

  var newPlaylists, i, length

  newPlaylists = []

  if (playlists) {

    length = playlists.length
    for (i = 0; i < length; i++) {

      newPlaylists.push(this.convertPlaylistToNewApi(playlists[i], type))
    }
  }

  return newPlaylists
}

/**
 * @param {Object} playlist
 * @param type
 * @returns {?Object}
 */
Demo.prototype.convertPlaylistToNewApi = function (playlist, type) {

  var newPlaylist

  newPlaylist = {}

  if (playlist) {

    newPlaylist[P.TYPE] = type
    newPlaylist[P.TITLE] = playlist[P.NAME]
    newPlaylist[P.SIZE] = playlist[P.SONGS].length
    newPlaylist[P.URI] = URI_PREFIX_LOCAL_PLAYLIST + playlist[P.ID]
    newPlaylist[P.FAVOURITE] = false
    newPlaylist[P.IMAGES] = this.getImagesForPlaylist(playlist[P.ID])
    if (type === P.SHARED) {
      newPlaylist[P.OWNER] = this.getPlaylistOwnerId(playlist[P.ID])
    }
  }

  return newPlaylist
}

/**
 * @param {Object} album
 * @returns {Object}
 */
Demo.prototype.convertAlbumToNewApi = function (album) {

  var newAlbum

  newAlbum = {}

  if (album) {

    newAlbum[P.IMAGES] = this.getImagesForSong(album)
    newAlbum[P.NAME] = album.name
    newAlbum[P.URI] = URI_PREFIX_LOCAL_ALBUM + album.name
    newAlbum[P.ARTIST] = {}
    newAlbum[P.ARTIST][P.NAME] = album.artist
    newAlbum[P.ARTIST][P.URI] = album.artist
  }

  return newAlbum
}

/**
 * @param {string} playlistId
 * @returns {string}
 */
Demo.prototype.getPlaylistOwnerId = function (playlistId) {

  var keys, i, length, j, jLength

  keys = Object.keys(this.data.playlists)
  length = keys.length
  for (i = 0; i < length; i++) {

    if (this.data.playlists[keys[i]].playlists) {

      jLength = this.data.playlists[keys[i]].playlists.length
      for (j = 0; j < jLength; j++) {

        if (this.data.playlists[keys[i]].playlists[j].id === playlistId) {

          return this.getSourceUuidForPlayerId(parseInt(keys[i]))
        }
      }
    }
  }

  return ''
}

/**
 * @param {string} roomId
 * @returns {?Object}
 */
Demo.prototype.getRoomForId = function (roomId) {

  var i, length, keys

  keys = Object.keys(this.data.rooms.rooms)
  length = keys.length
  for (i = 0; i < length; i++) {

    if (keys[i] === roomId) {

      return this.data.rooms.rooms[keys[i]]
    }
  }

  return null
}

/**
 * @param {string} roomId
 * @returns {?Object}
 */
Demo.prototype.getVirtualSourceForRoomForId = function (roomId) {

  var i, length, keys, source

  keys = Object.keys(this.data.avSources.avSources.audio)
  length = keys.length
  for (i = 0; i < length; i++) {

    source = this.data.avSources.avSources.audio[keys[i]]

    if (
      source &&
      source[P.FOLLOW_ROOM_NAME_UUID] === roomId
    ) {

      return source
    }
  }

  return null
}

/**
 * @param {string} roomId
 * @returns {?Object}
 */
Demo.prototype.updateVirtualSourceForRoomForId = function (roomId) {

  var source

  source = this.getVirtualSourceForRoomForId(roomId)

  if (source) {

    this.syncAVSourceState(source.uuid)
    this.sendAVSource(source.uuid)
  }
}

/**
 * Send a message (as server) back to the client
 *
 * @param {Object} message
 * @param {number} [timeout]
 */
Demo.prototype.sendMessage = function (message, timeout) {

  setTimeout(
    this._socket.handleServerSendOutData,
    BasUtil.isPNumber(timeout, true) ? timeout : 0,
    BasUtil.copyObject(message)
  )
}

Demo.prototype.sendMessageV2 = function (message, timeout) {

  setTimeout(
    this._v2Socket.handleServerSendOutData,
    BasUtil.isPNumber(timeout, true) ? timeout : 0,
    BasUtil.copyObject(message)
  )
}

// endregion

/**
 * @param {string} file
 * @param {boolean} [thumb]
 * @returns {string}
 */
Demo.prototype.getCoverArtUrl = function (file, thumb) {

  var fileInfo

  try {

    fileInfo = JSON.parse(file)

  } catch (e) {

    log.warn('BasCore.getCoverArtUrl - Invalid JSON string', file)

    return ''
  }

  return thumb === true ? fileInfo[P.THUMB] : fileInfo[P.COVER]
}

Demo.prototype._demoTickDoEnergy = function () {

  var length, i, device, data

  if (this._tickCount % 2) {

    if (this.data && Array.isArray(this.data.devices)) {

      length = this.data.devices.length
      for (i = 0; i < length; i++) {

        device = this.data.devices[i]

        if (
          device &&
          device[P.DEVICE] &&
          device[P.DEVICE][P.TYPE] === P.ENERGY_METER &&
          device[P.DEVICE][P.STATE] &&
          BasUtil.isVNumber(device[P.DEVICE][P.STATE][P.VALUE])
        ) {
          data = {}
          data[P.DEVICE] = {}
          data[P.DEVICE][P.UUID] = device[P.DEVICE][P.UUID]

          switch (device[P.DEVICE][P.SUB_TYPE]) {
            case P.GAS:
              data[P.DEVICE][P.STATE] = {}
              if (this._tickCount % 16 <= 8) {
                data[P.DEVICE][P.STATE][P.VALUE] = Math.round(
                  device[P.DEVICE][P.STATE][P.VALUE] +
                  (
                    device[P.DEVICE][P.STATE][P.VALUE] *
                    Math.random() *
                    0.3 *
                    (Math.random() < 0.5 ? -1 : 1)
                  )
                )
              } else {
                data[P.DEVICE][P.STATE][P.VALUE] = 0
              }
              data[P.DEVICE][P.STATE][P.TIMESTAMP] = (new Date()).toISOString()
              this.sendMessage(data)
              break
            case P.WATER:
              data[P.DEVICE][P.STATE] = {}
              if (this._tickCount % 8 >= 4) {
                data[P.DEVICE][P.STATE][P.VALUE] = Math.round(
                  device[P.DEVICE][P.STATE][P.VALUE] +
                  (
                    device[P.DEVICE][P.STATE][P.VALUE] *
                    Math.random() *
                    0.3 *
                    (Math.random() < 0.5 ? -1 : 1)
                  )
                )
              } else {
                data[P.DEVICE][P.STATE][P.VALUE] = 0
              }
              data[P.DEVICE][P.STATE][P.TIMESTAMP] = (new Date()).toISOString()
              this.sendMessage(data)
              break
            default: {
              data[P.DEVICE][P.STATE] = {}
              data[P.DEVICE][P.STATE][P.VALUE] = Math.round(
                device[P.DEVICE][P.STATE][P.VALUE] +
                (
                  device[P.DEVICE][P.STATE][P.VALUE] *
                  Math.random() *
                  0.3 *
                  (Math.random() < 0.5 ? -1 : 1)
                )
              )
              data[P.DEVICE][P.STATE][P.TIMESTAMP] = (new Date()).toISOString()

              this.sendMessage(data)
            }
          }
        }
      }
    }
  }
}

Demo.prototype._onDemoTick = function () {

  this._tickCount++
  // Reset tick count at 65000 to avoid very large numbers
  this._tickCount = this._tickCount % 65000

  this._demoTickDoEnergy()
}

Demo.prototype._clearDemoTicker = function () {

  if (this._tickId) clearInterval(this._tickId)
  this._tickId = 0
}

Demo.prototype.destroy = function () {

  this._clearDemoTicker()
}

module.exports = Demo
