'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('energyCtrl', [
    '$rootScope',
    '$scope',
    'BAS_ENERGY',
    'STATES',
    'BasEnergy',
    'BasState',
    energyCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param {BAS_ENERGY} BAS_ENERGY
 * @param {STATES} STATES
 * @param {BasEnergy} BasEnergy
 * @param {BasState} BasState
 */
function energyCtrl (
  $rootScope,
  $scope,
  BAS_ENERGY,
  STATES,
  BasEnergy,
  BasState
) {
  const energy = this

  let _listeners = []

  /**
   * @type {TBasEnergyState}
   */
  energy.basEnergyState = BasEnergy.get()

  energy.currentFilter = ''

  energy.types = []

  energy.selectType = selectType
  energy.selectHistoryType = selectHistoryType
  energy.goDetail = goDetail

  init()

  function init () {

    $scope.$on('$destroy', _onDestroy)

    _listeners.push($rootScope.$on(
      BAS_ENERGY.EVT_UI_UPDATE,
      _onEnergyUiUpdate
    ))

    _listeners.push($rootScope.$on(
      BAS_ENERGY.EVT_DATA_UPDATE,
      _onDataUpdate
    ))

    _retrieveUsageForCurrentMeters()
  }

  /**
   * @param {string} uuid
   */
  function goDetail (uuid) {
    var dev
    dev = energy.basEnergyState.energyMeters[uuid]
    if (dev) {
      BasState.go(
        STATES.ENERGY_DETAIL,
        {
          energydeviceid: uuid
        }
      )
    }
  }

  /**
   * @param {string} type
   */
  function selectType (type) {

    BasEnergy.selectType(type)

    _retrieveUsageForCurrentMeters()
  }

  /**
   * @param {string} uuid
   * @param {string} historyType
   */
  function selectHistoryType (uuid, historyType) {
    const energyMeter = energy.basEnergyState.energyMeters[uuid]

    if (!energyMeter) return

    energyMeter.selectCurrentType(historyType)

    if (energyMeter?.retrieveData) {
      energyMeter.retrieveData()
    }
  }

  function _retrieveUsageForCurrentMeters () {

    let i, uuid, energyMeter

    const length = energy.basEnergyState.uiEnergyMetersCurrent.length
    for (i = 0; i < length; i++) {

      uuid = energy.basEnergyState.uiEnergyMetersCurrent[i]
      energyMeter = energy.basEnergyState.energyMeters[uuid]

      _syncCurrentHistoryType(energyMeter)

      if (energyMeter?.retrieveData) {
        energyMeter.retrieveData()
      }
    }
  }

  function _syncCurrentHistoryType (energyMeter) {
    if (energyMeter) {
      energyMeter.selectCurrentType(BAS_ENERGY.T_ID_HIST_LAST_WEEK)
    }
  }

  function _onEnergyUiUpdate () {

    $scope.$applyAsync()
  }

  function _onDataUpdate () {

    _retrieveUsageForCurrentMeters()
  }

  function _onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
