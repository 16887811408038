'use strict'

const BasUtil = require('@basalte/bas-util')

angular
  .module('basalteApp')
  .controller('securityCtrl', [
    'SECURITY',
    'BasSecurity',
    'BasSecurityAlarm',
    'BasUtilities',
    securityCtrl
  ])

/**
 * @typedef {Object} TSecurityFilter
 * @property {string} name
 * @property {boolean} active
 */

/**
 * @param SECURITY
 * @param {BasSecurity} BasSecurity
 * @param {BasSecurityAlarm} BasSecurityAlarm
 * @param {BasUtilities} BasUtilities
 */
function securityCtrl (
  SECURITY,
  BasSecurity,
  BasSecurityAlarm,
  BasUtilities
) {
  const security = this

  /**
   * @type {TBasSecurityState}
   */
  security.basSecurity = BasSecurity.get()

  // TODO: Make project name dynamic
  security.filters = [BasUtilities.translate('alarm'), 'Main house']
  security.alarmTiles = []
  security.currentFilter = security.filters[0]

  security.alarmTiles = [
    new BasSecurityAlarm(
      {
        name: 'goodNightTile',
        type: SECURITY.BAS_ALARM_STATE_GOOD_NIGHT
      }
    ),
    new BasSecurityAlarm(
      {
        name: 'awayTile',
        type: SECURITY.BAS_ALARM_STATE_AWAY
      }
    )
  ]

  security.toggleHideSecurityRoom = toggleHideSecurityRoom
  security.selectFilter = selectFilter
  security.handleArmingAlarm = handleArmingAlarm
  security.disarm = disarm

  /**
   * @param {string} roomUuid
   * @param {boolean} [force]
   */
  function toggleHideSecurityRoom (roomUuid, force) {

    const room =
      security.basSecurity.rooms.find(element => element.uuid === roomUuid)

    if (room) {
      room.hidden = BasUtil.isBool(force) ? force : !room.hidden
    }
  }

  /**
   * @param {number} index
   */
  function selectFilter (index) {
    if (security.filters[index]) {
      security.currentFilter = security.filters[index]
    }
  }

  /**
   * @param {string} type
   */
  function handleArmingAlarm (type) {
    const { alarm } = security.basSecurity

    if (alarm.isArmed) return

    BasSecurity.handleAlarm(type)
  }

  function disarm () {
    BasSecurity.handleAlarm(SECURITY.BAS_ALARM_STATE_DISARMED)
  }
}
