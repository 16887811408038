'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .service('BasCameras', [
    '$window',
    '$rootScope',
    'BAS_CURRENT_CORE',
    'BAS_CAMERAS',
    'BAS_ROOM',
    'BAS_ROOMS',
    'BAS_OPEN_CLOSE_DEVICE',
    'BAS_LIGHT',
    'BAS_MODAL',
    'BAS_APP',
    'BAS_PREFERENCES',
    'STATES',
    'CurrentBasCore',
    'RoomsHelper',
    'BasAppDevice',
    'BasDevicePermissions',
    'BasModal',
    'BasOpenCloseDevices',
    'BasState',
    'BasUtilities',
    'BasPreferences',
    BasCameras
  ])

/**
 * @typedef {Object} TCamerasState
 * @property {boolean} hasCameras
 * @property {Object<string, BasCamera>} cameras
 * @property {boolean} uiHasCameras
 * @property {string[]} uiCameras
 * @property {Object<string, boolean>} css
 */

/**
 * Service for constructing a list of all the cameras and
 * sending it to the security camera plugin
 *
 * @constructor
 * @param $window
 * @param $rootScope
 * @param {BAS_CURRENT_CORE} BAS_CURRENT_CORE
 * @param {BAS_CAMERAS} BAS_CAMERAS
 * @param {BAS_ROOM} BAS_ROOM
 * @param {BAS_ROOMS} BAS_ROOMS
 * @param {BAS_OPEN_CLOSE_DEVICE} BAS_OPEN_CLOSE_DEVICE
 * @param {BAS_LIGHT} BAS_LIGHT
 * @param {BAS_MODAL} BAS_MODAL
 * @param {BAS_APP} BAS_APP
 * @param {BAS_PREFERENCES} BAS_PREFERENCES
 * @param {STATES} STATES
 * @param {CurrentBasCore} CurrentBasCore
 * @param {RoomsHelper} RoomsHelper
 * @param {BasAppDevice} BasAppDevice
 * @param {BasDevicePermissions} BasDevicePermissions
 * @param {BasModal} BasModal
 * @param {BasOpenCloseDevices} BasOpenCloseDevices
 * @param {BasState} BasState
 * @param {BasUtilities} BasUtilities
 * @param {BasPreferences} BasPreferences
 */
function BasCameras (
  $window,
  $rootScope,
  BAS_CURRENT_CORE,
  BAS_CAMERAS,
  BAS_ROOM,
  BAS_ROOMS,
  BAS_OPEN_CLOSE_DEVICE,
  BAS_LIGHT,
  BAS_MODAL,
  BAS_APP,
  BAS_PREFERENCES,
  STATES,
  CurrentBasCore,
  RoomsHelper,
  BasAppDevice,
  BasDevicePermissions,
  BasModal,
  BasOpenCloseDevices,
  BasState,
  BasUtilities,
  BasPreferences
) {
  var CSS_CAMERAS_HAS = 'bas-cameras-has'
  var K_SIP_REPLACEMENT_CAMERA_PREFIX = 'SIP_'

  var basTm = $window.basTModule

  /**
   * @type {TCurrentBasCoreState}
   */
  var currentBasCoreState = CurrentBasCore.get()

  /**
   * @type {BasRooms}
   */
  var rooms = BAS_ROOMS.ROOMS

  /**
   * @type {TCamerasState}
   */
  var state = {
    hasCameras: false,
    cameras: {},
    uiHasCameras: false,
    uiCameras: [],
    css: {}
  }
  _resetCss()

  this.get = get
  this.shouldShowCameras = shouldShowCameras
  this.goToCameras = goToCameras
  this.syncCameras = syncCameras
  this.saveNewCamerasOrder = saveNewCamerasOrder
  this.clear = clear
  this.close = close

  init()

  function init () {

    var ipCamPlugin

    $rootScope.$on(
      BAS_ROOMS.EVT_ROOMS_UPDATED,
      syncCameras
    )

    $rootScope.$on(
      BAS_ROOM.EVT_SECURITY_UPDATED,
      syncCameras
    )

    $rootScope.$on(
      BAS_APP.EVT_PAUSE,
      _onPause
    )

    $rootScope.$on(
      BAS_CURRENT_CORE.EVT_CORE_CAMERAS_ORDER,
      _onUpdateOrder
    )

    $rootScope.$on(
      BAS_OPEN_CLOSE_DEVICE.EVT_OPEN_CLOSE_DEVICE_UPDATED,
      _onOpenCloseDeviceUpdated
    )

    $rootScope.$on(
      BAS_LIGHT.EVT_LIGHT_DEVICE_TOGGLE_UPDATED,
      _onLightDeviceUpdated
    )

    $rootScope.$on(
      '$translateChangeSuccess',
      _onLanguageChanged
    )

    // Native basButton example
    ipCamPlugin = _getIPCamPlugin()

    if (ipCamPlugin) {

      ipCamPlugin.addListener(_onIPCamMessage)
    }
  }

  /**
   * @returns {TCamerasState}
   */
  function get () {
    return state
  }

  /**
   * @returns {boolean}
   */
  function shouldShowCameras () {

    return (
      CurrentBasCore.hasCore() &&
      RoomsHelper.hasCameras() &&
      (
        currentBasCoreState.core.isDemo() ||
        !BasAppDevice.isBrowser()
      )
    )
  }

  /**
   * Fired when ipcam plugin sends messages
   *
   * @private
   * @param {?Object} message
   * @param {string} message.type
   * @param {*} [message.data]
   */
  function _onIPCamMessage (message) {

    var type, data, subtype, uuid, visibility
    var ipCamPlugin, cameraUuid
    var openCloseDevice
    var lightDevice, pluginObj
    var activeState, buttonUuid

    ipCamPlugin = _getIPCamPlugin()

    if (ipCamPlugin &&
      BasUtil.isObject(message)) {

      type = message[ipCamPlugin.K_TYPE]
      data = message[ipCamPlugin.K_DATA]

      if (type === ipCamPlugin.T_VISIBILITY_EVENT) {

        if (BasUtil.isNEObject(data)) {

          visibility = data[ipCamPlugin.K_PLUGIN_VISIBLE]

          if (visibility === ipCamPlugin.K_DETAIL) {

            cameraUuid = data[ipCamPlugin.K_UUID]

            if (!BasUtil.isNEString(cameraUuid)) return

            // Open close

            openCloseDevice = _getOpenCloseDeviceOfCamera(
              cameraUuid
            )

            if (openCloseDevice) {

              pluginObj =
                openCloseDevice.getCordovaPluginObject()

              if (pluginObj) {

                ipCamPlugin.setBottomWidget(
                  pluginObj,
                  cameraUuid
                )
              }

              pluginObj =
                openCloseDevice.getCordovaPluginStateObject()

              if (pluginObj) {

                ipCamPlugin.setBottomWidgetState(
                  pluginObj,
                  cameraUuid
                )
              }
            }

            // Light

            lightDevice = _getLightDeviceOfCamera(
              cameraUuid
            )

            if (lightDevice) {

              pluginObj = lightDevice.getCordovaPluginObject()

              if (pluginObj) {

                ipCamPlugin.setToolbarWidget(
                  pluginObj,
                  cameraUuid
                )
              }

              pluginObj =
                lightDevice.getCordovaPluginStateObject()

              if (pluginObj) {

                ipCamPlugin.setToolbarWidgetState(
                  pluginObj,
                  cameraUuid
                )
              }
            }
          }
        }
      } else if (type === ipCamPlugin.T_CLICK_EVENT) {

        subtype = data[ipCamPlugin.K_SUBTYPE]
        uuid = data[ipCamPlugin.K_DEVICE_UUID]

        if (subtype === ipCamPlugin.T_CLICK_EVENT_OPEN_CLOSE) {

          openCloseDevice = BasOpenCloseDevices.getOpenCloseDeviceByUuid(uuid)

          if (openCloseDevice) {

            activeState = data[ipCamPlugin.K_STATE]
            buttonUuid = data[ipCamPlugin.K_UUID]

            _setOpenCloseChange(
              buttonUuid,
              activeState,
              openCloseDevice
            )
          }

        } else if (subtype === ipCamPlugin.T_CLICK_EVENT_LIGHT) {

          lightDevice = _getLightDevice(uuid)

          if (lightDevice) {

            activeState = data[ipCamPlugin.K_STATE]

            _setLightChange(
              lightDevice,
              activeState
            )
          }
        }
      }
    }
  }

  /**
   * @private
   * @param {string} buttonUuid
   * @param {boolean} pressed
   * @param {BasOpenCloseDevice} openCloseDevice
   */
  function _setOpenCloseChange (
    buttonUuid,
    pressed,
    openCloseDevice
  ) {
    if (BasUtil.isNEString(buttonUuid)) {

      if (pressed) {

        switch (buttonUuid) {
          case BAS_OPEN_CLOSE_DEVICE.DEF_CLOSE_UUID:
            openCloseDevice.close()
            break
          case BAS_OPEN_CLOSE_DEVICE.DEF_STOP_UUID:
            openCloseDevice.stop()
            break
          case BAS_OPEN_CLOSE_DEVICE.DEF_OPEN_UUID:
            openCloseDevice.open()
            break
          case BAS_OPEN_CLOSE_DEVICE.DEF_TRIGGER_UUID:
            openCloseDevice.onTriggerActive()
            break
        }

      } else {

        if (BAS_OPEN_CLOSE_DEVICE.DEF_TRIGGER_UUID === buttonUuid) {
          openCloseDevice.onTriggerRelease()
        }
      }
    }
  }

  /**
   * @private
   * @param {BasLight} lightDevice
   * @param {boolean} _pressed
   */
  function _setLightChange (lightDevice, _pressed) {

    lightDevice.toggle()
  }

  function _onOpenCloseDeviceUpdated () {

    var ipCamPlugin, cameraUuid, openClose, pluginObj

    ipCamPlugin = _getIPCamPlugin()

    if (ipCamPlugin) {

      cameraUuid = ipCamPlugin.detailUuid

      if (!BasUtil.isNEString(cameraUuid)) return

      openClose = _getOpenCloseDeviceOfCamera(cameraUuid)

      if (!openClose) return

      pluginObj = openClose.getCordovaPluginStateObject()

      if (!pluginObj) return

      ipCamPlugin.setBottomWidgetState(
        pluginObj,
        cameraUuid
      )
    }
  }

  function _onLightDeviceUpdated () {

    var ipCamPlugin, cameraUuid, light, pluginObj

    ipCamPlugin = _getIPCamPlugin()

    if (ipCamPlugin) {

      cameraUuid = ipCamPlugin.detailUuid

      if (!BasUtil.isNEString(cameraUuid)) return

      light = _getLightDeviceOfCamera(cameraUuid)

      if (!light) return

      pluginObj = light.getCordovaPluginStateObject()

      if (!pluginObj) return

      ipCamPlugin.setToolbarWidgetState(
        pluginObj,
        cameraUuid
      )
    }
  }

  /**
   * @private
   * @param {string} cameraUuid
   * @returns {?BasOpenCloseDevice}
   */
  function _getOpenCloseDeviceOfCamera (cameraUuid) {

    var cameraDevice, openCloseUuid

    if (!BasUtil.isNEString(cameraUuid)) return null

    cameraDevice = state.cameras[cameraUuid]

    if (!cameraDevice) return null

    openCloseUuid = cameraDevice.openClose

    if (!BasUtil.isNEString(openCloseUuid)) return null

    return BasOpenCloseDevices.getOpenCloseDeviceByUuid(openCloseUuid)
  }

  /**
   * @private
   * @param {string} cameraUuid
   * @returns {?BasLight}
   */
  function _getLightDeviceOfCamera (cameraUuid) {

    var cameraDevice, lightUuid

    if (!BasUtil.isNEString(cameraUuid)) return null

    cameraDevice = state.cameras[cameraUuid]

    if (!cameraDevice) return null

    lightUuid = cameraDevice.light

    if (!BasUtil.isNEString(lightUuid)) return null

    return _getLightDevice(lightUuid)
  }

  /**
   * @private
   * @param {string} lightUuid
   * @returns {?BasLight}
   */
  function _getLightDevice (lightUuid) {

    var keys, length, i, roomId, room, light

    if (BasUtil.isNEString(lightUuid)) {

      keys = Object.keys(rooms.rooms)
      length = keys.length
      for (i = 0; i < length; i++) {

        roomId = keys[i]

        if (roomId) {

          room = rooms.rooms[roomId]

          if (room && room.lights && room.lights.getLight) {

            light = room.lights.getLight(lightUuid)

            if (light) return light
          }
        }
      }
    }

    return null
  }

  /**
   * @private
   */
  function _onLanguageChanged () {

    updateTranslation()
    syncCameras()
  }

  function updateTranslation () {

    var length, i, camera

    length = state.uiCameras.length
    for (i = 0; i < length; i++) {

      camera = state.cameras[state.uiCameras[i]]

      if (camera) {

        camera.updateTranslation()
      }
    }
  }

  function _onUpdateOrder () {

    syncCameras()
  }

  /**
   * Displays Cameras if plugin is not running.
   * Updates Cameras view if plugin is already running.
   *
   * @returns {?Object}
   */
  function goToCameras () {

    // Don't do anything unless we have a core
    if (CurrentBasCore.hasCore()) {

      // Check which camera page we should go to
      if (currentBasCoreState.core.isDemo()) {

        // Show demo camera view
        return BasState.go(
          BasAppDevice.isLisa()
            ? STATES.LISA_CAMERAS
            : STATES.CAMERAS
        )
      } else if (BasAppDevice.isAndroid() || BasAppDevice.isIos()) {

        // This will open a custom native view to support camera streams
        _openNativeView()
      }
    }
    return null
  }

  /**
   * Check for local network permission before opening native view
   * (Not yet supported in cordova.plugins.diagnostic)
   *
   * @private
   * @param platform
   */
  // eslint-disable-next-line no-unused-vars
  function _openNativeViewWithPermissionCheck (platform) {

    // Check permission
    BasDevicePermissions.requestLocalNetworkPermission(
      _permissionCallback,
      true,
      platform
    )

    function _permissionCallback (
      _error,
      granted,
      _deniedAlways
    ) {
      if (!granted) {

        BasModal.show(BAS_MODAL.T_PERMISSION_DENIED_ALWAYS)

      } else {

        _openNativeView()
      }
    }
  }

  function _openNativeView () {

    var ipCamPlugin, pluginArray
    var mjpegAndRtspCount, rtspOnlyCount, mjpegOnlyCount

    // Show native camera view
    ipCamPlugin = _getIPCamPlugin()

    if (ipCamPlugin) {

      pluginArray = buildPluginArray()

      ipCamPlugin.setCameras(
        {
          cameras: BasUtilities.translate('cameras'),
          no_preview_available: BasUtilities.translate('no_preview_available'),
          camera_loading_error: BasUtilities.translate('camera_loading_error')
        },
        pluginArray,
        BasAppDevice.isLisa()
          ? ipCamPlugin.SWIPER_VIEW
          : ipCamPlugin.LIST_VIEW
      )

      if (basTm) {

        mjpegAndRtspCount = getCameraTypeCount(true, true)
        mjpegOnlyCount = getCameraTypeCount(true, false)
        rtspOnlyCount = getCameraTypeCount(false, true)

        basTm.logEvt({
          evtType: basTm.T_APP_IP_CAMERAS,
          count: pluginArray.length,
          mjpegAndRtsp: mjpegAndRtspCount,
          mjpegOnly: mjpegOnlyCount,
          rtspOnly: rtspOnlyCount
        })
      }
    }
  }

  /**
   * Close Cameras view
   */
  function close () {

    var ipCamPlugin = _getIPCamPlugin()

    if (ipCamPlugin) {

      ipCamPlugin.close()
    }
  }

  function _onPause () {

    const ipCamPlugin = _getIPCamPlugin()
    const shouldClose =
      BasPreferences.getDefaultView() !== BAS_PREFERENCES.VIEW_LAST_VIEW

    if (ipCamPlugin && ipCamPlugin.isVisible() && shouldClose) {
      close()
    }
  }

  /**
   * Iterate all rooms to collect all cameras
   */
  function syncCameras () {

    var i, keys, length, cameraUuid, camerasOrder

    state.hasCameras = false
    state.cameras = {}
    state.uiHasCameras = false
    state.uiCameras = []

    RoomsHelper.forEachRoom(_onRoomForSync)

    camerasOrder = _getCamerasOrder()

    if (Array.isArray(camerasOrder)) {

      // Add cameras known by server to uiCameras
      length = camerasOrder.length
      for (i = 0; i < length; i++) {

        cameraUuid = camerasOrder[i]

        if (state.cameras[cameraUuid]) {

          state.uiCameras.push(cameraUuid)
        }
      }
    }

    // Add cameras not known by server to uiCameras
    keys = Object.keys(state.cameras)
    length = keys.length

    for (i = 0; i < length; i++) {

      cameraUuid = keys[i]

      if (state.uiCameras.indexOf(cameraUuid) === -1) {

        state.uiCameras.push(cameraUuid)
      }
    }

    state.uiHasCameras = state.uiCameras.length > 0
    state.css[CSS_CAMERAS_HAS] = state.uiHasCameras

    $rootScope.$emit(
      BAS_CAMERAS.EVT_CAMERAS_UPDATED,
      state
    )
  }

  /**
   * @private
   * @param {BasRoom} room
   */
  function _onRoomForSync (room) {

    var length, i, basCamera, uuid

    if (room.hasCameras()) {

      state.hasCameras = true

      length = room.cameras.cameras.length
      for (i = 0; i < length; i++) {

        basCamera = room.cameras.cameras[i]
        uuid = basCamera.uuid

        if (
          !BasUtil.startsWith(basCamera.name, K_SIP_REPLACEMENT_CAMERA_PREFIX)
        ) {
          state.cameras[uuid] = basCamera
        }
      }
    }
  }

  /**
   * @private
   * @returns {?(string[])}
   */
  function _getCamerasOrder () {

    var _sharedServerStorage

    if (CurrentBasCore.hasCore()) {

      _sharedServerStorage =
        currentBasCoreState.core.core.sharedServerStorage

      if (_sharedServerStorage) {

        return _sharedServerStorage.camerasOrder
      }
    }

    return null
  }

  /**
   * Construct array of camera objects for plugin
   *
   * @returns {Object[]}
   */
  function buildPluginArray () {

    var result, i, length, camera, isRemote

    result = []

    if (CurrentBasCore.hasCore() &&
      currentBasCoreState.core.basServer) {

      isRemote = currentBasCoreState.core.basServer.isRemote()

      length = state.uiCameras.length
      for (i = 0; i < length; i++) {

        camera = state.cameras[state.uiCameras[i]]

        if (camera) {

          result.push(camera.getPluginObj(isRemote))
        }
      }
    }

    return result
  }

  /**
   * Count the number of camera's
   * Must exactly match provided parameters
   *
   * @param hasMjpeg
   * @param hasRtsp
   * @returns {number}
   */
  function getCameraTypeCount (hasMjpeg, hasRtsp) {

    var count, length, i, camera, _hasMjpeg, _hasRtsp

    count = 0

    if (CurrentBasCore.hasCore() &&
      currentBasCoreState.core.basServer) {

      length = state.uiCameras.length
      for (i = 0; i < length; i++) {

        camera = state.cameras[state.uiCameras[i]]

        if (camera) {

          _hasMjpeg = camera.hasMjpeg()
          _hasRtsp = camera.hasRtsp()

          if (_hasMjpeg === hasMjpeg && _hasRtsp === hasRtsp) {
            count++
          }
        }
      }
    }

    return count
  }

  /**
   * Send array of camera uuids to server
   *
   * @param {string[]} uuids
   */
  function saveNewCamerasOrder (uuids) {

    if (CurrentBasCore.hasCore() &&
      currentBasCoreState.core.core.sharedServerStorage) {

      currentBasCoreState.core.core.sharedServerStorage
        .updateCamerasOrder(uuids)
    }
  }

  function _getIPCamPlugin () {

    if ($window['basalteCordova'] &&
      $window['basalteCordova']['ipcam']) {

      return $window['basalteCordova']['ipcam']
    }

    return null
  }

  function _resetCss () {

    state.css[CSS_CAMERAS_HAS] = false
  }

  function clear () {

    state.hasCameras = false
    state.cameras = {}
    state.uiHasCameras = false
    state.uiCameras = []

    _resetCss()
  }
}
