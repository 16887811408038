'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .factory('BasEnergyMeter', [
    '$rootScope',
    'BAS_API',
    'BAS_ENERGY',
    'BAS_UTILITIES',
    'BasEnergyHelper',
    'BasUtilities',
    'moment',
    basEnergyMeterFactory
  ])

/**
 * @param $rootScope
 * @param BAS_API
 * @param {BAS_ENERGY} BAS_ENERGY
 * @param {BAS_UTILITIES} BAS_UTILITIES
 * @param {BasEnergyHelper} BasEnergyHelper
 * @param {BasUtilities} BasUtilities
 * @param moment
 * @returns {BasEnergyMeter}
 */
function basEnergyMeterFactory (
  $rootScope,
  BAS_API,
  BAS_ENERGY,
  BAS_UTILITIES,
  BasEnergyHelper,
  BasUtilities,
  moment
) {
  const CSS_TYPE_ENERGY = 'bas-energy-meter--energy'
  const CSS_TYPE_GAS = 'bas-energy-meter--gas'
  const CSS_TYPE_WATER = 'bas-energy-meter--water'
  const CSS_FLOW_UP = 'bas-energy-meter--flow-up'
  const CSS_FLOW_DOWN = 'bas-energy-meter--flow-down'

  /**
   * @constructor
   * @param {EnergyDevice} device
   */
  function BasEnergyMeter (device) {

    /**
     * @type {string}
     */
    this.uuid = ''

    /**
     * @type {string}
     */
    this.name = ''

    /**
     * @type {string}
     */
    this.type = BAS_ENERGY.T_ID_ENERGY

    /**
     * @type {boolean}
     */
    this.virtual = false

    /**
     * @type {boolean}
     */
    this.consuming = true

    /**
     * @type {boolean}
     */
    this.loading = false

    /**
     * @type {boolean}
     */
    this.hasData = false

    /**
     * @type {string}
     */
    this.uiRealTimeUnit = ''

    /**
     * @type {string}
     */
    this.uiTotalUnit = ''

    /**
     * @type {string}
     */
    this.oldestTimestamp = ''

    /**
     * @type {string}
     */
    this.uiCurrent = ''

    /**
     * @type {string[]}
     */
    this.uiHistoryTypes = []

    /**
     * @type {string[]}
     */
    this.uiDetailHistoryTypes = []

    /**
     * @type {string}
     */
    this.currentHistoryType = BAS_ENERGY.T_ID_HIST_LAST_WEEK

    /**
     * @type {Object<string, boolean>}
     */
    this.css = {}

    /**
     * @type {?TBasEnergyMeterResult}
     */
    this.data = null

    /**
     * @private
     * @type {EnergyDevice}
     */
    this._device = device

    this._deviceListeners = []

    /**
     * @private
     * @type {TBasEnergyRange[]}
     */
    this._ranges = []

    this._handleState = this._onState.bind(this)

    this.parseEnergyDevice(device)
  }

  /**
   * @returns {string}
   */
  BasEnergyMeter.prototype.getOldestTimestamp = function () {

    let value

    if (this._device?.getData) {

      value = this._device.stateOldestTimestamp
      if (BasUtil.isNEString(value)) return value

      value = this._device.oldestTimestamp
      if (BasUtil.isNEString(value)) return value
    }

    return ''
  }

  /**
   * @param options
   * @returns {Promise<TBasEnergyMeterResultAll>}
   */
  BasEnergyMeter.prototype.retrieveData = function (options) {
    let range = null

    if (options) {
      range = {
        start: options.start,
        stop: options.stop
      }
    } else {
      range = this._getRange()
    }

    const step = options?.step ? options.step : this._getPromiseStep()

    const _this = this

    this.loading = true

    BasEnergyHelper.requestAll(
      this._device,
      {
        start: range.start,
        stop: range.stop,
        step: step
      }
    )
      .then(_onRequestSuccess)
      .catch(_onRequestError)
      .finally(_onRequestDone)

    function _onRequestSuccess (data) {
      _this.data = data
    }

    function _onRequestError () {
      // Empty
    }

    function _onRequestDone () {
      _this.loading = false

      const results = _this.data?.result?.map((d) => d[1])
      _this.hasData =
        results && results.length > 0 && results.some((d) => d !== null)

      $rootScope.$emit(
        BAS_ENERGY.EVT_UI_UPDATE
      )
    }
  }

  /**
   * @param {string} historyType
   */
  BasEnergyMeter.prototype.selectCurrentType = function (historyType) {

    if (historyType !== this.currentHistoryType) {

      this.currentHistoryType = historyType
    }
  }

  /**
   * @param {EnergyDevice} device
   */
  BasEnergyMeter.prototype.parseEnergyDevice = function (device) {

    this.clearDeviceListeners()
    this._resetCss()

    if (device?.getData) {

      this._device = device

      this.uuid = device.uuid
      this.name = device.name

      this.virtual = device.virtual
      this.consuming =
        device.resourceType !== BAS_API.EnergyDevice.RT_GENERATOR
      this.uiRealTimeUnit = device.realTimeUnit
      this.uiTotalUnit = device.totalUnit

      switch (device.subType) {
        case BAS_API.EnergyDevice.T_GAS:
          this.type = BAS_ENERGY.T_ID_GAS
          this.css[CSS_TYPE_GAS] = true
          break
        case BAS_API.EnergyDevice.T_WATER:
          this.type = BAS_ENERGY.T_ID_WATER
          this.css[CSS_TYPE_WATER] = true
          break
        default:
          this.type = BAS_ENERGY.T_ID_ENERGY
          this.css[CSS_TYPE_ENERGY] = true
      }

      this._setDeviceListeners()
    }

    this._syncName()
    this._syncState()
    this._syncHistoryState()
    this._syncDetailHistoryState()
  }

  BasEnergyMeter.prototype._syncName = function () {

    this.name = (this._device && BasUtil.isNEString(this._device.name))
      ? this._device.name
      : BasUtilities.basTranslate('energy_meter')
  }

  BasEnergyMeter.prototype._getPromiseStep = function () {
    switch (this.currentHistoryType) {
      case BAS_ENERGY.T_ID_HIST_TODAY:
        return BAS_UTILITIES.T_1H_MS
      case BAS_ENERGY.T_ID_HIST_MONTH:
        return BAS_UTILITIES.T_3D_MS
      case BAS_ENERGY.T_ID_HIST_YEAR:
        return BAS_UTILITIES.T_1W_MS
      case BAS_ENERGY.T_ID_HIST_LAST_WEEK:
      default:
        return BAS_UTILITIES.T_1D_MS
    }
  }

  BasEnergyMeter.prototype._syncState = function () {

    let value, length, absValue

    if (this._device) {

      // Oldest timestamp
      value = this._device.stateOldestTimestamp
      if (this.oldestTimestamp !== value) {
        // TODO update range options
      }
      this.oldestTimestamp = value

      // Current value
      value = this._device.stateValue
      absValue = Math.abs(value)
      this.uiCurrent = '' + absValue

      this.css[CSS_FLOW_UP] = value < 0
      this.css[CSS_FLOW_DOWN] = value > 0

      // "Correct" JavaScript number rounding and handle very "large" numbers

      length = this.uiCurrent.length
      if (length > 5) {

        this.uiCurrent = absValue < 1
          ? '0'
          : BasUtil.numberToString(absValue, 6)
      }
    }
  }

  BasEnergyMeter.prototype._syncHistoryState = function () {

    if (this._device) {

      this.uiHistoryTypes = [
        BAS_ENERGY.T_ID_HIST_TODAY,
        BAS_ENERGY.T_ID_HIST_LAST_WEEK,
        BAS_ENERGY.T_ID_HIST_MONTH,
        BAS_ENERGY.T_ID_HIST_YEAR
      ]
    }
  }

  BasEnergyMeter.prototype._syncDetailHistoryState = function () {

    if (this._device) {

      this.uiDetailHistoryTypes = [
        BAS_ENERGY.T_ID_HIST_MONTH,
        BAS_ENERGY.T_ID_HIST_YEAR
      ]
    }
  }

  BasEnergyMeter.prototype._onState = function () {

    this._syncState()

    $rootScope.$emit(BAS_ENERGY.EVT_STATE_UPDATED, this)
  }

  BasEnergyMeter.prototype.updateTranslation = function () {

    this._syncName()
  }

  BasEnergyMeter.prototype._setDeviceListeners = function () {

    this.clearDeviceListeners()

    if (this._device) {

      this._deviceListeners.push(BasUtil.setEventListener(
        this._device,
        BAS_API.EnergyDevice.EVT_STATE,
        this._handleState
      ))
    }
  }

  BasEnergyMeter.prototype._resetCss = function () {

    this.css[CSS_TYPE_ENERGY] = false
    this.css[CSS_TYPE_GAS] = false
    this.css[CSS_TYPE_WATER] = false
    this.css[CSS_FLOW_UP] = false
    this.css[CSS_FLOW_DOWN] = false
  }

  BasEnergyMeter.prototype._getRange = function () {

    const dNow = new Date()

    switch (this.currentHistoryType) {

      // Today

      case BAS_ENERGY.T_ID_HIST_TODAY:
        return {
          type: BAS_ENERGY.T_ID_HIST_TODAY,
          start: moment(dNow).format('YYYY-MM-DD'),
          stop: moment(dNow).add(1, 'days').format('YYYY-MM-DD')
        }

      case BAS_ENERGY.T_ID_HIST_LAST_WEEK:

        // Last 7 days

        return {
          type: BAS_ENERGY.T_ID_HIST_LAST_WEEK,
          start: moment(dNow).subtract(7, 'days').format('YYYY-MM-DD')
        }

      case BAS_ENERGY.T_ID_HIST_MONTH: {

        // Months of the current year

        return {
          type: BAS_ENERGY.T_ID_HIST_MONTH,
          start: moment(dNow).startOf('year').format('YYYY-MM-DD'),
          stop: moment(dNow).format('YYYY-MM-DD')
        }
      }
      case BAS_ENERGY.T_ID_HIST_YEAR:
      {
        const oldestTimestamp = this.getOldestTimestamp()
        const oldestDate = oldestTimestamp
          ? new Date(oldestTimestamp)
          : null

        // All data grouped by year

        return {
          type: BAS_ENERGY.T_ID_HIST_YEAR,
          start: moment(oldestDate).format('YYYY-MM-DD'),
          stop: moment(dNow).format('YYYY-MM-DD')
        }
      }
    }
  }

  BasEnergyMeter.prototype.clearDeviceListeners = function () {

    BasUtil.executeArray(this._deviceListeners)
    this._deviceListeners = []
  }

  BasEnergyMeter.prototype.clear = function () {

    this.clearDeviceListeners()

    this._device = null
  }

  return BasEnergyMeter
}
