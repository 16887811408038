'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .directive('basPlayerMessage', basPlayerMessageDirective)

function basPlayerMessageDirective () {

  return {
    restrict: 'AE',
    controller: [
      '$rootScope',
      '$scope',
      'BAS_MESSAGE',
      'BAS_ROOM',
      'BAS_SOURCE',
      'BAS_SOURCES',
      'ICONS',
      'BasAppDevice',
      'CurrentBasCore',
      'BasMessageHelper',
      'SourcesHelper',
      'Sources',
      'RoomsHelper',
      'BasUtilities',
      controller
    ],
    controllerAs: 'basPlayerMsgDir',
    bindToController: {
      basMessageContext: '@?',
      basRoom: '<?',
      basSource: '<?',
      basSupportContextChange: '<?'
    }
  }

  /**
   * @param $rootScope
   * @param $scope
   * @param {BAS_MESSAGE} BAS_MESSAGE
   * @param {BAS_ROOM} BAS_ROOM
   * @param {BAS_SOURCE} BAS_SOURCE
   * @param {BAS_SOURCES} BAS_SOURCES
   * @param {ICONS} ICONS
   * @param {BasAppDevice} BasAppDevice
   * @param {CurrentBasCore} CurrentBasCore
   * @param {BasMessageHelper} BasMessageHelper
   * @param {SourcesHelper} SourcesHelper
   * @param {Sources} Sources
   * @param {RoomsHelper} RoomsHelper
   * @param {BasUtilities} BasUtilities
   */
  function controller (
    $rootScope,
    $scope,
    BAS_MESSAGE,
    BAS_ROOM,
    BAS_SOURCE,
    BAS_SOURCES,
    ICONS,
    BasAppDevice,
    CurrentBasCore,
    BasMessageHelper,
    SourcesHelper,
    Sources,
    RoomsHelper,
    BasUtilities
  ) {
    var basPlayerMsgDir = this

    var CSS_HIDE = 'bpy-message-hide'
    var CSS_MSG_ICON_HAS = 'bas-tile-message-img--has'
    var CSS_MSG_ICON_MUSIC = 'bas-tile-message-img--music'

    var _listeners = []

    /**
     * @type {boolean}
     */
    var isLisa = BasAppDevice.isLisa()

    /**
     * @type {TCurrentBasCoreState}
     */
    var currentBasCoreState = CurrentBasCore.get()

    /**
     * @type {number}
     */
    var msgType = BAS_MESSAGE.MESSAGE_NONE

    var registeredSource

    /**
     * @type {TBasMessageData}
     */
    basPlayerMsgDir.basMessageData = {}
    basPlayerMsgDir.basMessageData.img = null
    basPlayerMsgDir.basMessageData.txtMsgTitle = ''
    basPlayerMsgDir.basMessageData.txtMsgLine1 = ''
    basPlayerMsgDir.basMessageData.txtMsgLine2 = ''
    basPlayerMsgDir.basMessageData.txtMsgLine3 = ''
    basPlayerMsgDir.basMessageData.txtAction = ''
    basPlayerMsgDir.basMessageData.css = {}

    basPlayerMsgDir.css = {}
    basPlayerMsgDir.css[CSS_HIDE] = false

    basPlayerMsgDir.onMessageAction = onMessageAction
    basPlayerMsgDir.onMessageActionAlt = onMessageActionAlt

    basPlayerMsgDir.$onInit = _onInit
    basPlayerMsgDir.$onChanges = _onChanges
    basPlayerMsgDir.$onDestroy = _onDestroy

    function _onInit () {

      _listeners.push($rootScope.$on(
        BAS_SOURCE.EVT_CURRENT_SONG,
        _onSongUpdated
      ))
      _listeners.push($rootScope.$on(
        BAS_SOURCE.EVT_QUEUE_CHANGE,
        _onQueueUpdated
      ))
      _listeners.push($rootScope.$on(
        BAS_SOURCE.EVT_STATE_CHANGE,
        _onStateUpdated
      ))
      _listeners.push($rootScope.$on(
        BAS_SOURCES.EVT_SOURCES_UPDATED,
        _onSourcesUpdated
      ))
      _listeners.push($rootScope.$on(
        BAS_SOURCE.EVT_LISTENING_ROOMS_CHANGE,
        _onListeningRoomsChanged
      ))
      _listeners.push($rootScope.$on(
        BAS_SOURCE.EVT_DEFAULT_ROOMS_UPDATED,
        _onDefaultRoomsUpdated
      ))
      _listeners.push($rootScope.$on(
        BAS_SOURCE.EVT_CAPABILITIES_CHANGED,
        _onCapabilitiesChanged
      ))
      _listeners.push($rootScope.$on(
        BAS_SOURCE.EVT_STATUS_UPDATED,
        _onStatusUpdated
      ))
      _listeners.push($rootScope.$on(
        BAS_ROOM.EVT_AVAILABLE_CHANGED,
        _onAvailableChanged
      ))
      _listeners.push($rootScope.$on(
        BAS_ROOM.EVT_SOURCE_CHANGED,
        _onSourceChanged
      ))
      _listeners.push($rootScope.$on('$translateChangeSuccess', _onTranslate))
    }

    function _onChanges () {

      _syncMessage()
    }

    /**
     * @private
     * @param _event
     * @param {BasSource} basSource
     */
    function _onSongUpdated (_event, basSource) {

      var currBasSource

      currBasSource = _getBasSource()

      if (currBasSource && basSource.isSame(currBasSource)) _syncMessage()
    }

    /**
     * @private
     * @param _event
     * @param {(string|undefined)} sourceUuid
     * @param {(number|undefined)} sourceId
     */
    function _onQueueUpdated (
      _event,
      sourceUuid,
      sourceId
    ) {
      var currBasSource, evtBasSource

      currBasSource = _getBasSource()
      evtBasSource = SourcesHelper.getBasSource(
        BasUtil.isString(sourceUuid) ? sourceUuid : sourceId
      )

      if (
        currBasSource &&
        evtBasSource &&
        evtBasSource.isSame(currBasSource)
      ) {
        _syncMessage()
      }
    }

    /**
     * @private
     * @param _event
     * @param {number} sourceId
     */
    function _onStateUpdated (_event, sourceId) {

      var currBasSource, evtBasSource

      currBasSource = _getBasSource()
      evtBasSource = SourcesHelper.getBasSource(sourceId)

      if (
        currBasSource &&
        evtBasSource &&
        evtBasSource.isSame(currBasSource)
      ) {

        _syncMessage()
      }
    }

    /**
     * @private
     */
    function _onSourcesUpdated () {

      _syncMessage()
    }

    /**
     * @private
     */
    function _onListeningRoomsChanged () {

      _syncMessage()
    }

    /**
     * @private
     */
    function _onDefaultRoomsUpdated () {

      _syncMessage()
      $scope.$applyAsync()
    }

    /**
     * @private
     */
    function _onCapabilitiesChanged () {

      _syncMessage()
      $scope.$applyAsync()
    }

    /**
     * @private
     */
    function _onStatusUpdated () {

      _syncMessage()
      $scope.$applyAsync()
    }

    /**
     * @private
     * @param {Object} _evt
     * @param {string} roomId
     */
    function _onAvailableChanged (_evt, roomId) {

      var room = _getRoom()

      if (room && room.id === roomId) {

        _syncMessage()
        $scope.$applyAsync()
      }
    }

    /**
     * @private
     * @param {Object} _evt
     * @param {string} roomId
     */
    function _onSourceChanged (_evt, roomId) {

      var room = _getRoom()

      if (room && room.id === roomId) {

        _syncMessage()
        $scope.$applyAsync()
      }
    }

    function _onTranslate () {

      _syncUi()
    }

    function onMessageAction () {

      BasMessageHelper.handleMessageAction(
        _getRoom(),
        msgType,
        _isVideoContext()
      )
    }

    function onMessageActionAlt () {

      BasMessageHelper.handleMessageAction(
        _getRoom(),
        msgType,
        _isVideoContext(),
        true
      )
    }

    function _syncMessage () {

      msgType = BasMessageHelper.getMessageType(
        _getRoom(),
        _isVideoContext()
      )

      if (
        !_supportsContextChange() &&
        (
          msgType === BAS_MESSAGE.MESSAGE_VIDEO_IN_NON_VIDEO_CTX ||
          msgType === BAS_MESSAGE.MESSAGE_AUDIO_IN_NON_AUDIO_CTX ||
          msgType === BAS_MESSAGE.MESSAGE_VIDEO_IN_NON_VIDEO_CTX_TURN_ON ||
          msgType === BAS_MESSAGE.MESSAGE_AUDIO_IN_NON_AUDIO_CTX_TURN_ON
        )
      ) {
        msgType = BAS_MESSAGE.MESSAGE_NONE
      }

      basPlayerMsgDir.css[CSS_HIDE] = msgType === BAS_MESSAGE.MESSAGE_NONE
      _syncUi()
    }

    function _syncUi () {

      var data, music, source, canSelectSource, isNotAsano, shouldUnregister

      data = basPlayerMsgDir.basMessageData

      music = _getRoomMusic()
      source = _getBasSource()

      canSelectSource = true
      isNotAsano = false
      shouldUnregister = true

      if (music &&
          [BAS_ROOM.MUSIC_T_SONOS, BAS_ROOM.MUSIC_T_BOSPEAKER]
            .includes(music.type)) {

        canSelectSource = false
        isNotAsano = true
      }

      data.img = null
      data.txtMsgTitle = ''
      data.txtMsgLine1 = ''
      data.txtMsgLine2 = ''
      data.txtMsgLine3 = ''
      data.txtAction = ''
      data.txtActionOr = ''
      data.txtAction2 = ''
      data.css[CSS_MSG_ICON_HAS] = false
      data.css[CSS_MSG_ICON_MUSIC] = false

      switch (msgType) {
        case BAS_MESSAGE.MESSAGE_QUEUE_END:

          data.txtMsgTitle =
            BasUtilities.translate('empty_song')
          data.txtMsgLine1 =
            BasUtilities.translate('empty_song_txt_l1')
          data.txtMsgLine2 =
            BasUtilities.translate('empty_song_txt_l2')
          data.txtAction =
            BasUtilities.translate('play')

          break
        case BAS_MESSAGE.MESSAGE_QUEUE_EMPTY:

          data.txtMsgTitle =
            BasUtilities.translate('empty_queue')
          data.txtMsgLine1 =
            BasUtilities.translate('empty_queue_txt_l1')
          data.txtMsgLine2 = (isLisa || isNotAsano)
            ? ''
            : BasUtilities.translate('empty_queue_txt_l2')
          data.txtAction = isLisa
            ? ''
            : BasUtilities.translate(
              isNotAsano
                ? 'favourite_songs'
                : 'my_music'
            )

          break
        case BAS_MESSAGE.MESSAGE_SOURCE_UNKNOWN:

          data.txtMsgTitle =
            BasUtilities.translate('unknown_src')
          data.txtMsgLine1 =
            BasUtilities.translate('unknown_src_txt')
          data.txtAction = (isLisa || !canSelectSource)
            ? ''
            : BasUtilities.translate('sources')

          break
        case BAS_MESSAGE.MESSAGE_SOURCE_UNAVAILABLE:

          data.txtMsgTitle =
            BasUtilities.translate('unavailable_src')
          data.txtMsgLine1 =
            BasUtilities.translate('unavailable_src_txt')
          data.txtAction = (isLisa || !canSelectSource)
            ? ''
            : BasUtilities.translate('sources')

          break
        case BAS_MESSAGE.MESSAGE_SOURCE_EMPTY:

          data.txtMsgTitle =
            BasUtilities.translate('empty_src')
          data.txtMsgLine1 =
            BasUtilities.translate('empty_src_txt_l1')
          data.txtMsgLine2 = (isLisa || !canSelectSource)
            ? ''
            : BasUtilities.translate('empty_src_txt_l2')
          data.txtAction = (isLisa || !canSelectSource)
            ? ''
            : BasUtilities.translate('sources')

          break
        case BAS_MESSAGE.MESSAGE_ZONE_EMPTY:

          if (
            CurrentBasCore.hasCore() &&
            currentBasCoreState.core.core.singleAudioRoomId
          ) {

            data.txtAction =
              BasUtilities.translate('turn_on')

          } else {

            data.txtMsgTitle =
              BasUtilities.translate('empty_zone')
            data.txtMsgLine1 =
              BasUtilities.translate('empty_zone_txt_l1')
            data.txtMsgLine2 = isLisa
              ? ''
              : BasUtilities.translate('empty_zone_txt_l2')
            data.txtAction = isLisa
              ? ''
              : BasUtilities.translate('zones')
          }

          break
        case BAS_MESSAGE.MESSAGE_UNKNOWN_AV:

          data.txtMsgTitle =
            BasUtilities.translate('unknown_av')
          data.txtAction = (isLisa || !canSelectSource)
            ? ''
            : BasUtilities.translate('sources')

          break
        case BAS_MESSAGE.MESSAGE_NO_AV:

          data.txtMsgTitle =
            BasUtilities.translate('no_av')
          data.txtAction = (isLisa || !canSelectSource)
            ? ''
            : BasUtilities.translate('sources')

          break
        case BAS_MESSAGE.MESSAGE_SONOS_UNREACHABLE:

          data.txtMsgTitle =
            BasUtilities.translate('sonos_unreachable')
          data.txtMsgLine1 =
            BasUtilities.translate('sonos_unreachable_txt_l1')
          data.txtMsgLine2 =
            BasUtilities.translate('problems_continue_integrator')

          break
        case BAS_MESSAGE.MESSAGE_VIDEO_IN_NON_VIDEO_CTX:

          data.img = ICONS.externalTv

          if (_supportsContextChange()) {
            data.txtMsgTitle = BasUtilities.translate('video_is_active')
            data.txtAction = BasUtilities.translate('video_go_to')
          }

          data.css[CSS_MSG_ICON_HAS] = true

          break
        case BAS_MESSAGE.MESSAGE_AUDIO_IN_NON_AUDIO_CTX:

          data.img = ICONS.musicFull

          if (_supportsContextChange()) {
            data.txtMsgTitle = BasUtilities.translate('music_is_active')
            data.txtAction = BasUtilities.translate('music_go_to')
          }

          break
        case BAS_MESSAGE.MESSAGE_NO_ROOMS_LISTENING:

          data.txtMsgTitle =
            BasUtilities.translate('no_rooms')
          data.txtMsgLine1 =
            BasUtilities.translate('no_rooms_listening')

          if (source.canToggleOn) {

            _registerForDefaultRooms()
            shouldUnregister = false

            data.txtMsgLine2 =
              BasUtilities.translate('turn_on_default_rooms')

            data.hasThirdMsgLine = true
            data.txtMsgLine3 = (
              source &&
              source.defaultRooms &&
              source.defaultRooms.roomIds.length > 0
            )
              ? _generateRoomsString(source.defaultRooms.roomIds)
              : ''

            data.txtAction = BasUtilities.translate('turn_on')

          } else {

            data.txtAction = BasUtilities.translate('rooms')
          }

          break
        case BAS_MESSAGE.MESSAGE_TURN_ON:

          data.img = _isVideoContext() ? ICONS.externalTv : ICONS.musicFull
          data.txtAction =
            BasUtilities.translate('turn_on')
          data.css[CSS_MSG_ICON_HAS] = true
          data.css[CSS_MSG_ICON_MUSIC] = !_isVideoContext()

          break
        case BAS_MESSAGE.MESSAGE_VIDEO_IN_NON_VIDEO_CTX_TURN_ON:

          data.img = ICONS.externalTv

          if (_supportsContextChange()) {
            data.txtMsgTitle = BasUtilities.translate('video_is_active')
            data.txtAction = BasUtilities.translate('video_go_to')
            data.txtActionOr = BasUtilities.translate('or')
            data.txtAction2 = BasUtilities.translate('turn_on_music')
          }

          data.css[CSS_MSG_ICON_HAS] = true

          break
        case BAS_MESSAGE.MESSAGE_AUDIO_IN_NON_AUDIO_CTX_TURN_ON:

          data.img = ICONS.musicFull

          if (_supportsContextChange()) {
            data.txtMsgTitle = BasUtilities.translate('music_is_active')
            data.txtAction = BasUtilities.translate('music_go_to')
            data.txtActionOr = BasUtilities.translate('or')
            data.txtAction2 = BasUtilities.translate('turn_on_tv')
          }

          data.css[CSS_MSG_ICON_HAS] = true
          data.css[CSS_MSG_ICON_MUSIC] = true

          break
        case BAS_MESSAGE.MESSAGE_AUDIO_UNAVAILABLE:

          data.txtMsgTitle =
            BasUtilities.translate('audio_unavailable')
          data.txtMsgLine1 =
            BasUtilities.translate('problems_continue_integrator')

          break
        case BAS_MESSAGE.MESSAGE_VIDEO_UNAVAILABLE:

          data.txtMsgTitle =
            BasUtilities.translate('video_unavailable')
          data.txtMsgLine1 =
            BasUtilities.translate('problems_continue_integrator')

          break
      }

      if (shouldUnregister) _unregisterForDefaultRooms()
    }

    /**
     * @param {string[]} roomIds
     * @param {number} [maxNameCount = 2]
     */
    function _generateRoomsString (roomIds, maxNameCount) {

      var i, length, room, roomNames, _maxNameCount

      _maxNameCount = BasUtil.isNumber(maxNameCount) ? maxNameCount : 2

      if (Array.isArray(roomIds) && roomIds.length) {

        roomNames = []

        length = roomIds.length
        for (i = 0; i < Math.min(_maxNameCount, length); i++) {

          room = RoomsHelper.getRoomForId(roomIds[i])

          if (room.basTitle.value) {

            roomNames.push(room.basTitle.value)
          }
        }

        if (roomIds.length > _maxNameCount) {
          roomNames.push('...')
        }

        return roomNames.join(', ')
      }

      return ''
    }

    /**
     * @private
     * @returns {?BasSource}
     */
    function _getBasSource () {

      if (_isVideoContext()) {

        return (
          basPlayerMsgDir.basRoom &&
          basPlayerMsgDir.basRoom.getVideoBasSource
        )
          ? basPlayerMsgDir.basRoom.getVideoBasSource()
          : null
      }

      return (
        basPlayerMsgDir.basRoom &&
        basPlayerMsgDir.basRoom.getMusicBasSource
      )
        ? basPlayerMsgDir.basRoom.getMusicBasSource()
        : null
    }

    /**
     * @private
     * @returns {?BasRoomMusic}
     */
    function _getRoomMusic () {

      var basRoom

      basRoom = _getRoom()

      return (basRoom && basRoom.music && basRoom.music.volumeChange)
        ? basRoom.music
        : null
    }

    /**
     * @private
     * @returns {?BasRoom}
     */
    function _getRoom () {
      return (basPlayerMsgDir.basRoom && basPlayerMsgDir.basRoom.isRoom)
        ? basPlayerMsgDir.basRoom
        : null
    }

    /**
     * @private
     * @returns {boolean}
     */
    function _isVideoContext () {
      return basPlayerMsgDir.basMessageContext === BAS_ROOM.K_VIDEO
    }

    /**
     * @private
     * @returns {boolean}
     */
    function _supportsContextChange () {
      return basPlayerMsgDir.basSupportContextChange === true
    }

    function _registerForDefaultRooms () {

      const source = _getBasSource()

      if (source) {

        const sourceId = source.getId()

        if (registeredSource !== sourceId) {

          _unregisterForDefaultRooms()
          registeredSource = sourceId
          Sources.registerFor(
            BAS_SOURCE.COL_EVT_DEFAULT_ROOMS,
            sourceId
          )
        }
      }
    }

    function _unregisterForDefaultRooms () {

      if (registeredSource) {

        Sources.unregisterFor(
          BAS_SOURCE.COL_EVT_DEFAULT_ROOMS,
          registeredSource
        )
        registeredSource = undefined
      }
    }

    function _onDestroy () {

      BasUtil.executeArray(_listeners)
      _listeners = []

      _unregisterForDefaultRooms()
    }
  }
}
