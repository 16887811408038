'use strict'

angular
  .module('basalteApp')
  .constant('SECURITY', {
    EVENT_COUNTDOWN_UPDATED: 'countdownUpdated',
    BAS_ALARM_STATE_GOOD_NIGHT: 'basAlarmTypeGoodNight',
    BAS_ALARM_STATE_DISARMED: 'basAlarmTypeDisarmed',
    BAS_ALARM_STATE_AWAY: 'basAlarmTypeAway'
  })
