'use strict'

/*
When calling 'basCore.requestRetry(...)', we will always pass the
CONSTANTS.RETRY_OPTS_ONE_SHOT options, since the server can take a bit longer
to send reply messages. In this case we don't want to double send actions/state
updates since this can result in unwanted behavior.
 */

var BasUtil = require('@basalte/bas-util')

var Device = require('./device')
var BasTrack = require('./bas_track')
var Queue = require('./queue')

var P = require('./parser_constants')
var CONSTANTS = require('./constants')

/**
 * @typedef {Object} TNowPlaying
 * @property {?BasTrack} current
 * @property {?BasTrack} next
 * @property {?TNowPlayingContext} context
 */
/**
 * @typedef {Object} TNowPlayingContext
 * @property {string} name
 * @property {string} uri
 */

/**
 * @typedef {Object} TAudioSourceState
 * @property {boolean} [on]
 * @property {string} [playback]
 * @property {string} [repeat]
 * @property {boolean} [shuffle]
 * @property {number} [positionMs]
 * @property {TNowPlaying} [nowPlaying]
 * @property {boolean} [mute]
 * @property {number} [volume]
 * @property {string[]} [listeningRooms]
 * @property {boolean} [pairing]
 * @property {string} [status]
 */

/**
 * @typedef {Object} TAudioSourceQueueResult
 * @property {BasTrack[]} list
 * @property {number} offset
 * @property {number} total
 * @property {string} contentType
 */

/**
 * @typedef {Object} TAudioSourceFavouritesResult
 * @property {TAudioSourceFavourite[]} list
 * @property {number} offset
 * @property {number} total
 * @property {string} service
 */

/**
 * @typedef {Object<string, number>} TAudioSourceFavouritesServicesResult
 */

/**
 * @typedef {Object} TAudioSourceFavourite
 * @property {string} uri
 * @property {string} name
 * @property {string} type
 * @property {?Object} images
 * @property {number} size
 * @property {string} service
 * @property {number} index
 * @property {string} caption
 * @property {boolean} removable
 */

/**
 * @typedef {Object} TAudioSourcePlaylistsResult
 * @property {TAudioSourcePlaylist[]} list
 * @property {number} offset
 * @property {number} total
 */

/**
 * @typedef {Object} TAudioSourcePlaylist
 * @property {string} uri
 * @property {string} title
 * @property {string} type
 * @property {number} size
 * @property {Object} images
 * @property {string} owner
 */

/**
 * @typedef {Object} TAudioSourcePlaylistDetailsResult
 * @property {BasTrack[]} list
 * @property {number} offset
 * @property {number} total
 */

/**
 * @typedef {Object} TAudioSourceDefaultRoomsResult
 * @property {Object<string, TAudioSourceDefaultRoomInfo>} list
 */

/**
 * @typedef {Object} TAudioSourceDefaultRoomInfo
 * @property {boolean} default
 * @property {boolean} editable
 */

/**
 * @typedef {Object} TAudioSourceStreamingServiceLoginResult
 * @property {string} linkUrl
 * @property {string} streamingService
 */

/**
 * @typedef {Object} TAudioSourceStreamingServiceDetailsResult
 * @property {string} token
 * @property {string} streamingService
 */

/**
 * @typedef {Object} TAudioSourcePresetsResult
 * @property {Array<TAudioSourcePreset>} list
 * @property {number} offset
 * @property {number} total
 */

/**
 * @typedef {Object} TAudioSourcePreset
 * @property {number} id
 * @property {string} name
 * @property {string} uri
 */

/**
 * @typedef {Object} TAudioSourcePresetLinkData
 * @property {number} id
 * @property {string} uri
 */

/**
 * Class representing an audio-source
 *
 * @constructor
 * @extends Device
 * @param {BasCore} basCore
 * @param {Object} device
 * @since 3.4.0
 */
function AudioSource (
  basCore,
  device
) {
  Device.call(this, device, basCore)

  /**
   * @private
   * @type {string}
   */
  this._defaultName = ''

  /**
   * @private
   * @type {?string}
   */
  this._colour = null

  /**
   * @private
   * @type {?string}
   */
  this._followRoomNameUuid = null

  /**
   * @private
   * @type {?number}
   */
  this._sequence = null

  /**
   * @private
   * @type {TAudioSourceState}
   */
  this._state = {
    nowPlaying: {
      current: null,
      next: null,
      context: null
    }
  }

  this._handleListQueue = this._onListQueue.bind(this)
  this._handleFavourites = this._onFavourites.bind(this)
  this._handleFavouritesServices = this._onFavouritesServices.bind(this)
  this._handleQuickFavourites = this._onQuickFavourites.bind(this)
  this._handlePlaylists = this._onPlaylists.bind(this)
  this._handlePlaylistDetails = this._onPlaylistDetails.bind(this)
  this._handleDefaultRooms = this._onDefaultRooms.bind(this)
  this._handleStreamingServiceLogin = this._onStreamingServiceLogin.bind(this)
  this._handleStreamingServiceDetails =
    this._onStreamingServiceDetails.bind(this)
  this._handleStreamingServiceList = this._onStreamingServiceList.bind(this)
  this._handlePresetsList = this._onPresetsList.bind(this)

  this.parse(device, { emit: false })
}

AudioSource.prototype = Object.create(Device.prototype)
AudioSource.prototype.constructor = AudioSource

// region Constants

/**
 * @constant {string}
 */
AudioSource.T_ASANO = P.ASANO

/**
 * @constant {string}
 */
AudioSource.T_SONOS = P.SONOS

/**
 * @constant {string}
 */
AudioSource.T_BOSPEAKER = P.BOSPEAKER

/**
 * @constant {string}
 */
AudioSource.C_ON = P.ON

/**
 * @constant {string}
 */
AudioSource.C_PLAYBACK = P.PLAYBACK

/**
 * @constant {string}
 */
AudioSource.C_REPEAT = P.REPEAT

/**
 * @constant {string}
 */
AudioSource.C_SHUFFLE = P.SHUFFLE

/**
 * @constant {string}
 */
AudioSource.C_POSITION_MS = P.POSITION_MS

/**
 * @constant {string}
 */
AudioSource.C_NOW_PLAYING = P.NOW_PLAYING

/**
 * @constant {string}
 */
AudioSource.C_VOLUME = P.VOLUME

/**
 * @constant {string}
 */
AudioSource.C_MUTE = P.MUTE

/**
 * @constant {string}
 */
AudioSource.C_LISTENING_ROOMS = P.LISTENING_ROOMS

/**
 * @constant {string}
 */
AudioSource.C_PLAY_URI = P.PLAY_URI

/**
 * @constant {string}
 */
AudioSource.C_SKIP_NEXT = P.SKIP_NEXT

/**
 * @constant {string}
 */
AudioSource.C_SKIP_PREVIOUS = P.SKIP_PREVIOUS

/**
 * @constant {string}
 */
AudioSource.C_LIST_FAVOURITES = P.LIST_FAVOURITES

/**
 * @constant {string}
 */
AudioSource.C_FAVOURITES = P.FAVOURITES

/**
 * @constant {string}
 */
AudioSource.C_PLAYLISTS = P.PLAYLISTS

/**
 * @constant {string}
 */
AudioSource.C_ADD = P.ADD

/**
 * @constant {string}
 */
AudioSource.C_DETAIL = P.DETAIL

/**
 * @constant {string}
 */
AudioSource.C_LIST = P.LIST

/**
 * @constant {string}
 */
AudioSource.C_MOVE = P.MOVE

/**
 * @constant {string}
 */
AudioSource.C_REMOVE = P.REMOVE

/**
 * @constant {string}
 */
AudioSource.C_RENAME = P.RENAME

/**
 * @constant {string}
 */
AudioSource.C_SHARE = P.SHARE

/**
 * @constant {string}
 */
AudioSource.C_DEFAULT_ROOMS = P.DEFAULT_ROOMS

/**
 * @constant {string}
 */
AudioSource.C_SET = P.SET

/**
 * @constant {string}
 */
AudioSource.C_STREAMING_SERVICES = P.STREAMING_SERVICES

/**
 * @constant {string}
 */
AudioSource.C_LOGIN = P.LOGIN

/**
 * @constant {string}
 */
AudioSource.C_LOGOUT = P.LOGOUT

/**
 * @constant {string}
 */
AudioSource.C_QUEUE = P.QUEUE

/**
 * @constant {string}
 */
AudioSource.A_PM_PLAYING = P.PLAYING

/**
 * @constant {string}
 */
AudioSource.A_PM_PAUSED = P.PAUSED

/**
 * @constant {string}
 */
AudioSource.A_PM_BUFFERING = P.BUFFERING

/**
 * @constant {string}
 */
AudioSource.A_PM_IDLE = P.IDLE

/**
 * @constant {string}
 */
AudioSource.A_PM_UNKNOWN = P.UNKNOWN

/**
 * @constant {string}
 */
AudioSource.A_RM_NONE = P.NONE

/**
 * @constant {string}
 */
AudioSource.A_RM_ALL = P.ALL

/**
 * @constant {string}
 */
AudioSource.A_RM_TRACK = P.TRACK

/**
 * Queue option
 *
 * @constant
 */
AudioSource.A_QO_APPEND = P.APPEND

/**
 * Queue option
 *
 * @constant
 */
AudioSource.A_QO_NEXT = P.NEXT

/**
 * Queue option
 *
 * @constant
 */
AudioSource.A_QO_NOW = P.NOW

/**
 * Queue option
 *
 * @constant
 */
AudioSource.A_QO_REPLACE = P.REPLACE

/**
 * Queue option
 *
 * @constant
 */
AudioSource.A_QO_REPLACE_NOW = P.REPLACE_NOW

/**
 * Streaming service
 *
 * @constant
 */
AudioSource.A_SS_SPOTIFY = P.SPOTIFY

/**
 * Streaming service
 *
 * @constant
 */
AudioSource.A_SS_SPOTIFY_CONNECT = P.SPOTIFY_CONNECT

/**
 * Streaming service
 *
 * @constant
 */
AudioSource.A_SS_TIDAL = P.TIDAL

/**
 * Streaming service
 *
 * @constant
 */
AudioSource.A_SS_DEEZER = P.DEEZER

/**
 * Queue empty status
 *
 * @constant
 */
AudioSource.A_ST_QUEUE_EMPTY = P.QUEUE_EMPTY

/**
 * Queue empty status
 *
 * @constant
 */
AudioSource.A_ST_END_OF_QUEUE = P.END_OF_QUEUE

/**
 * @constant {string}
 */
AudioSource.EVT_STATE_CHANGED = 'evtAudioSourceStateChanged'

/**
 * @constant {string}
 */
AudioSource.EVT_ON_CHANGED = 'evtAudioSourceStateOnChanged'

/**
 * @constant {string}
 */
AudioSource.EVT_PLAYBACK_CHANGED = 'evtAudioSourceStatePlaybackChanged'

/**
 * @constant {string}
 */
AudioSource.EVT_REPEATED_CHANGED = 'evtAudioSourceStateRepeatedChanged'

/**
 * @constant {string}
 */
AudioSource.EVT_POSITION_CHANGED = 'evtAudioSourceStatePositionChanged'

/**
 * @constant {string}
 */
AudioSource.EVT_RANDOM_CHANGED = 'evtAudioSourceStateRandomChanged'

/**
 * @constant {string}
 */
AudioSource.EVT_NOW_PLAYING_CHANGED = 'evtAudioSourceStateNowPlayingChanged'

/**
 * @constant {string}
 */
AudioSource.EVT_CURRENT_SONG_CHANGED = 'evtAudioSourceStateCurrentSongChanged'

/**
 * @constant {string}
 */
AudioSource.EVT_NEXT_SONG_CHANGED = 'evtAudioSourceStateNextSongChanged'

/**
 * @constant {string}
 */
AudioSource.EVT_MUTE_CHANGED = 'evtAudioSourceStateMuteChanged'

/**
 * @constant {string}
 */
AudioSource.EVT_VOLUME_CHANGED = 'evtAudioSourceStateVolumeChanged'

/**
 * @constant {string}
 */
AudioSource.EVT__STARTUP_VOLUME_CHANGED = 'evtAudioSourceStateVolumeChanged'

/**
 * @constant {string}
 */
AudioSource.EVT_LISTENING_ROOMS_CHANGED = 'evtAudioSourceListeningRoomsChanged'

/**
 * @constant {string}
 */
AudioSource.EVT_STATUS_CHANGED = 'evtAudioSourceStatusChanged'

/**
 * @constant {string}
 */
AudioSource.EVT_DEFAULT_ROOMS_RESET = 'evtAudioSourceDefaultRoomsReset'

/**
 * @constant {string}
 */
AudioSource.EVT_QUEUE_RESET = 'evtAudioSourceQueueReset'

/**
 * @constant {string}
 */
AudioSource.EVT_QUEUE_MOVED = 'evtAudioSourceQueueMoved'

/**
 * @constant {string}
 */
AudioSource.EVT_QUEUE_REMOVED = 'evtAudioSourceQueueRemoved'

/**
 * @constant {string}
 */
AudioSource.EVT_QUEUE_ADDED = 'evtAudioSourceQueueAdded'

/**
 * @constant {string}
 */
AudioSource.EVT_FAVOURITES_RESET = 'evtAudioSourceFavouritesReset'

/**
 * @constant {string}
 */
AudioSource.EVT_FAVOURITE_ADDED = 'evtAudioSourceFavouriteAdded'

/**
 * @constant {string}
 */
AudioSource.EVT_FAVOURITE_REMOVED = 'evtAudioSourceFavouriteRemoved'

/**
 * @constant {string}
 */
AudioSource.EVT_FAVOURITE_UPDATED = 'evtAudioSourceFavouriteUpdated'

/**
 * @constant {string}
 */
AudioSource.EVT_QUICK_FAVOURITE_RESET = 'evtAudioSourceQuickFavouritesReset'

/**
 * @constant {string}
 */
AudioSource.EVT_PLAYLISTS_RESET = 'evtAudioSourcePlaylistsReset'

/**
 * @constant {string}
 */
AudioSource.EVT_PLAYLIST_RENAMED = 'evtAudioSourcePlaylistRenamed'

/**
 * @constant {string}
 */
AudioSource.EVT_PLAYLIST_REMOVED = 'evtAudioSourcePlaylistRemoved'

/**
 * @constant {string}
 */
AudioSource.EVT_PLAYLIST_TRACK_MOVED = 'evtAudioSourcePlaylistTrackMoved'

/**
 * @constant {string}
 */
AudioSource.EVT_PLAYLIST_ADDED = 'evtAudioSourcePlaylistAdded'

/**
 * @constant {string}
 */
AudioSource.EVT_PLAYLIST_TRACK_ADDED = 'evtAudioSourcePlaylistTrackAdded'

/**
 * @constant {string}
 */
AudioSource.EVT_PLAYLIST_TYPE_CHANGED = 'evtAudioSourcePlaylistTypeChanged'

/**
 * @constant {string}
 */
AudioSource.EVT_DEFAULT_NAME_CHANGED = 'evtAudioSourceDefaultNameChanged'

/**
 * @constant {string}
 */
AudioSource.EVT_STREAMING_SERVICE_LINK_FINISHED =
  'evtAudioSourceStreamingServiceLinkFinished'

/**
 * @constant {string}
 */
AudioSource.EVT_STREAMING_SERVICE_TOKEN_CHANGED =
  'evtAudioSourceStreamingServiceToken'

/**
 * @constant {string}
 */
AudioSource.EVT_STREAMING_SERVICE_VALUE_UPDATED =
  'evtAudioSourceStreamingServiceValue'

/**
 * @constant {string}
 */
AudioSource.EVT_PRESET_LINKED = 'evtAudioSourcePresetLinked'

// endregion

/**
 * Tells if a value is a possible value for 'playback' property
 *
 * @param {string} playback
 * @param {boolean} [outgoing = false] Distinguish correct incoming values from
 * correct outgoing values
 * @returns {boolean}
 */
AudioSource.isPlaybackMode = function (playback, outgoing) {
  return (
    playback === AudioSource.A_PM_PLAYING ||
    playback === AudioSource.A_PM_PAUSED ||
    playback === AudioSource.A_PM_IDLE ||
    (
      !outgoing && (
        playback === AudioSource.A_PM_BUFFERING ||
        playback === AudioSource.A_PM_UNKNOWN
      )
    )
  )
}

/**
 * Tells if a value is a possible value for 'repeated' property
 *
 * @param {string} repeated
 * @returns {boolean}
 */
AudioSource.isRepeatedMode = function (repeated) {
  return (
    repeated === AudioSource.A_RM_ALL ||
    repeated === AudioSource.A_RM_NONE ||
    repeated === AudioSource.A_RM_TRACK
  )
}

/**
 * @param {(string|number)} repeatMode
 * @returns {(number|string)}
 */
AudioSource.convertRepeatMode = function (repeatMode) {
  switch (repeatMode) {
    case AudioSource.A_RM_NONE: return CONSTANTS.REPEAT_OFF
    case AudioSource.A_RM_ALL: return CONSTANTS.REPEAT_CURRENT_CONTEXT
    case AudioSource.A_RM_TRACK: return CONSTANTS.REPEAT_CURRENT_TRACK
    case CONSTANTS.REPEAT_OFF: return AudioSource.A_RM_NONE
    case CONSTANTS.REPEAT_CURRENT_CONTEXT: return AudioSource.A_RM_ALL
    case CONSTANTS.REPEAT_CURRENT_TRACK: return AudioSource.A_RM_TRACK
  }
}

/**
 * @private
 * @param {?Object} obj
 * @returns {TAudioSourceFavourite}
 */
AudioSource._parseFavourite = function (obj) {

  var result, value

  /**
   * @type {TAudioSourceFavourite}
   */
  result = {
    images: null,
    name: '',
    type: '',
    uri: '',
    size: -1,
    service: '',
    index: -1,
    caption: '',
    removable: false
  }

  if (!BasUtil.isObject(obj)) return result

  // Uri

  value = obj[P.URI]
  if (BasUtil.isNEString(value)) result.uri = value

  // Name

  value = obj[P.NAME]
  if (BasUtil.isNEString(value)) result.name = value

  // Type

  value = obj[P.TYPE]
  if (BasUtil.isNEString(value)) result.type = value

  // Images

  value = obj[P.IMAGES]
  if (BasUtil.isObject(value)) result.images = value

  // Size

  value = obj[P.SIZE]
  if (BasUtil.isPNumber(value, true)) result.size = value

  // Service

  value = obj[P.SERVICE]
  if (BasUtil.isNEString(value)) result.service = value

  // Index

  value = obj[P.INDEX]
  if (BasUtil.isPNumber(value, true)) result.index = value

  // Caption

  value = obj[P.CAPTION]
  if (BasUtil.isNEString(value)) result.caption = value

  // Removable

  value = obj[P.REMOVABLE]
  if (BasUtil.isBool(value)) result.removable = value

  return result
}

/**
 * @private
 * @param {Object} obj
 * @returns {TAudioSourcePlaylist}
 */
AudioSource._parsePlaylist = function (obj) {

  var result, value, length, i, keys, length2, j, arr

  /**
   * @type {TAudioSourcePlaylist}
   */
  result = {
    title: '',
    type: '',
    uri: '',
    size: -1,
    images: {},
    owner: ''
  }

  // Uri

  value = obj[P.URI]
  if (BasUtil.isNEString(value)) result.uri = value

  // Name

  value = obj[P.TITLE]
  if (BasUtil.isNEString(value)) result.title = value

  // Type

  value = obj[P.TYPE]
  if (BasUtil.isNEString(value)) result.type = value

  // Size

  value = obj[P.SIZE]
  if (BasUtil.isPNumber(value, true)) result.size = value

  // Owner

  value = obj[P.OWNER]
  if (BasUtil.isNEString(value)) result.owner = value

  // Images

  value = obj[P.IMAGES]
  if (BasUtil.isObject(value)) {

    result.images = {}

    keys = Object.keys(value)
    length = keys.length

    for (i = 0; i < length; i++) {

      arr = value[keys[i]]

      result.images[keys[i]] = []

      length2 = arr.length
      for (j = 0; j < length2; j++) {

        result.images[keys[i]].push(this._basCore.getHTTPUrl(arr[j]))
      }
    }
  }

  return result
}

Object.defineProperties(AudioSource.prototype, {

  /**
   * @name AudioSource#isOn
   * @type {boolean}
   * @readonly
   */
  isOn: {
    get: function () {
      return BasUtil.isBool(this._state.on)
        ? this._state.on
        : false
    }
  },

  /**
   * @name AudioSource#defaultName
   * @type {string}
   * @readonly
   */
  defaultName: {
    get: function () {
      return BasUtil.isNEString(this._defaultName)
        ? this._defaultName
        : ''
    }
  },

  /**
   * @name AudioSource#colour
   * @type {?string}
   * @readonly
   */
  colour: {
    get: function () {
      return this._colour
    }
  },

  /**
   * @name AudioSource#followRoomNameUuid
   * @type {?string}
   * @readonly
   */
  followRoomNameUuid: {
    get: function () {
      return this._followRoomNameUuid
    }
  },

  /**
   * @name AudioSource#sequence
   * @type {?number}
   * @readonly
   */
  sequence: {
    get: function () {
      return this._sequence
    }
  },

  /**
   * @name AudioSource#playbackMode
   * @type {string}
   * @readonly
   */
  playbackMode: {
    get: function () {
      return BasUtil.isNEString(this._state.playback)
        ? this._state.playback
        : AudioSource.A_PM_UNKNOWN
    }
  },

  /**
   * @name AudioSource#paused
   * @type {boolean}
   * @readonly
   */
  paused: {
    get: function () {
      return !(
        this._state.playback !== AudioSource.A_PM_PAUSED &&
        this._state.playback !== AudioSource.A_PM_IDLE &&
        this._state.playback !== AudioSource.A_PM_UNKNOWN
      )
    }
  },

  /**
   * @name AudioSource#repeatState
   * @type {string}
   * @readonly
   */
  repeatState: {
    get: function () {
      return BasUtil.isNEString(this._state.repeat)
        ? this._state.repeat
        : AudioSource.A_RM_NONE
    }
  },

  /**
   * @name AudioSource#repeatMode
   * @type {number}
   * @readonly
   */
  repeatMode: {
    get: function () {
      return AudioSource.convertRepeatMode(
        this.repeatState
      )
    }
  },

  /**
   * @name AudioSource#possibleRepeatStates
   * @type {string[]}
   * @readonly
   */
  possibleRepeatStates: {
    get: function () {
      return BasUtil.isNEArray(this._attributes[P.REPEAT])
        ? this._attributes[P.REPEAT]
        : []
    }
  },

  /**
   * @name AudioSource#possibleRepeatModes
   * @type {number[]}
   * @readonly
   */
  possibleRepeatModes: {
    get: function () {
      var result, states, length, i

      result = []

      states = this.possibleRepeatStates

      length = states.length
      for (i = 0; i < length; i++) {

        result.push(AudioSource.convertRepeatMode(states[i]))
      }

      return result
    }
  },

  /**
   * @name AudioSource#random
   * @type {boolean}
   * @readonly
   */
  random: {
    get: function () {
      return BasUtil.isBool(this._state.shuffle)
        ? this._state.shuffle
        : false
    }
  },

  /**
   * @name AudioSource#positionMs
   * @type {number}
   * @readonly
   */
  positionMs: {
    get: function () {
      return BasUtil.isPNumber(this._state.positionMs)
        ? this._state.positionMs
        : 0
    }
  },

  /**
   * Symlink for AudioSource#positionMs
   *
   * @name AudioSource#position
   * @type {number}
   * @readonly
   */
  position: {
    get: function () {
      return this.positionMs
    }
  },

  /**
   * @name AudioSource#nowPlaying
   * @type {TNowPlaying}
   * @readonly
   */
  nowPlaying: {
    get: function () {
      return this._state.nowPlaying
    }
  },

  /**
   * @name AudioSource#currentSong
   * @type {?BasTrack}
   * @readonly
   */
  currentSong: {
    get: function () {
      return this._state.nowPlaying.current
    }
  },

  /**
   * @name AudioSource#nextSong
   * @type {?BasTrack}
   * @readonly
   */
  nextSong: {
    get: function () {
      return this._state.nowPlaying.next
    }
  },

  /**
   * @name AudioSource#mute
   * @type {boolean}
   * @readonly
   */
  mute: {
    get: function () {
      return BasUtil.isBool(this._state.mute)
        ? this._state.mute
        : false
    }
  },

  /**
   * @name AudioSource#volume
   * @type {number}
   * @readonly
   */
  volume: {
    get: function () {
      return BasUtil.isPNumber(this._state.volume)
        ? this._state.volume
        : 0
    }
  },

  /**
   * @name AudioSource#listeningRooms
   * @type {string[]}
   * @readonly
   */
  listeningRooms: {
    get: function () {
      return Array.isArray(this._state.listeningRooms)
        ? this._state.listeningRooms
        : []
    }
  },

  /**
   * @name AudioSource#pairing
   * @type {boolean}
   * @readonly
   */
  pairing: {
    get: function () {
      return BasUtil.isBool(this._state.pairing)
        ? this._state.pairing
        : false
    }
  },

  /**
   * @name AudioSource#streamingServices
   * @type {string[]}
   * @readonly
   */
  streamingServices: {
    get: function () {
      return Array.isArray(this._state.streamingServices)
        ? this._state.streamingServices
        : []
    }
  },

  /**
   * @name AudioSource#possibleStreamingServices
   * @type {string[]}
   * @readonly
   */
  possibleStreamingServices: {
    get: function () {
      return BasUtil.isNEArray(this._attributes[P.STREAMING_SERVICE])
        ? this._attributes[P.STREAMING_SERVICE]
        : []
    }
  },

  /**
   * @name AudioSource#status
   * @type {string}
   * @readonly
   */
  status: {
    get: function () {
      return BasUtil.isString(this._state.status)
        ? this._state.status
        : ''
    }
  },

  /**
   * @name AudioSource#statusIsQueueEmpty
   * @type {boolean}
   * @readonly
   */
  statusIsQueueEmpty: {
    get: function () {
      return this._state.status === AudioSource.A_ST_QUEUE_EMPTY
    }
  },

  /**
   * @name AudioSource#statusIsEndOfQueue
   * @type {boolean}
   * @readonly
   */
  statusIsEndOfQueue: {
    get: function () {
      return this._state.status === AudioSource.A_ST_END_OF_QUEUE
    }
  }
})

/**
 * Parse an AV source message
 *
 * @param {Object} msg
 * @param {TDeviceParseOptions} [options]
 * @returns {boolean}
 */
AudioSource.prototype.parse = function (
  msg,
  options
) {

  var emit, valid, state, value, oldValue, track, oldCurrent, oldNext, event
  var subValue

  var nowPlayingChanged, currentSongChanged, nextSongChanged, stateChanged
  var positionChanged, repeatedChanged, playbackChanged, shuffledChanged
  var volumeChanged, muteChanged, listeningRoomsChanged, onChanged
  var statusChanged

  var queueReset, queueMoved, queueRemoved, queueAdded, queueEvtData
  var defaultNameChanged, defaultRoomsReset

  var favouritesReset, favouriteAdded, favouriteRemoved, favouriteUpdated
  var favouriteData, quickFavouritesReset

  var presetLinkedData

  var playlistRenamed, playlistTrackMoved, playlistRemoved, playlistAdded
  var playlistsReset, playlistTypeChanged, playlistEventData, playlistTrackAdded

  var streamingService, linkSuccess, linkError, token, data

  var i, length

  emit = true

  stateChanged = false
  nowPlayingChanged = false
  currentSongChanged = false
  nextSongChanged = false
  playbackChanged = false
  shuffledChanged = false
  positionChanged = false
  repeatedChanged = false

  favouritesReset = false
  defaultNameChanged = false
  listeningRoomsChanged = false
  defaultRoomsReset = false

  queueMoved = false
  queueRemoved = false
  queueAdded = false
  queueReset = false

  valid = Device.prototype.parse.call(this, msg, options)

  if (valid) {

    if (BasUtil.isObject(options)) {

      if (BasUtil.isBool(options.emit)) emit = options.emit
    }

    // State

    state = msg[P.STATE]

    if (BasUtil.isObject(state)) {

      // On

      value = state[P.ON]
      oldValue = this._state.on

      if (
        P.ON in state &&
        oldValue !== value
      ) {

        this._state.on = BasUtil.isBool(value)
          ? value
          : null

        if (oldValue !== this._state.on) {

          onChanged = true
          stateChanged = true
        }
      }

      // Playback

      value = state[P.PLAYBACK]
      oldValue = this._state.playback

      if (
        P.PLAYBACK in state &&
        oldValue !== value
      ) {

        this._state.playback = AudioSource.isPlaybackMode(value, false)
          ? value
          : null

        if (oldValue !== this._state.repeat) {

          playbackChanged = true
          stateChanged = true
        }
      }

      // Status

      value = state[P.STATUS]
      oldValue = this._state.status

      if (
        P.STATUS in state &&
        oldValue !== value
      ) {

        this._state.status = BasUtil.isString(value)
          ? value
          : null

        if (oldValue !== this._state.status) {

          statusChanged = true
          stateChanged = true
        }
      }

      // Repeated

      value = state[P.REPEAT]
      oldValue = this._state.repeat

      if (
        P.REPEAT in state &&
        oldValue !== value
      ) {

        this._state.repeat = AudioSource.isRepeatedMode(value)
          ? value
          : null

        if (oldValue !== this._state.repeat) {

          repeatedChanged = true
          stateChanged = true
        }
      }

      // Shuffled

      value = state[P.SHUFFLE]
      oldValue = this._state.shuffle

      if (
        P.SHUFFLE in state &&
        oldValue !== value
      ) {

        this._state.shuffle = BasUtil.isBool(value)
          ? value
          : null

        if (oldValue !== this._state.shuffle) {

          shuffledChanged = true
          stateChanged = true
        }
      }

      // PositionMs

      value = state[P.POSITION_MS]
      oldValue = this._state.positionMs

      if (
        P.POSITION_MS in state &&
        oldValue !== value
      ) {

        this._state.positionMs = BasUtil.isPNumber(value, true)
          ? value
          : null

        if (oldValue !== this._state.positionMs) {

          positionChanged = true
          stateChanged = true
        }
      }

      // Now playing

      value = state[P.NOW_PLAYING]
      oldCurrent = this._state.nowPlaying.current
      oldNext = this._state.nowPlaying.next

      if (P.NOW_PLAYING in state) {

        if (BasUtil.isObject(value)) {

          if (P.CURRENT in value) {

            oldValue = this._state.nowPlaying.current

            track = BasTrack.parseIfSame(
              value[P.CURRENT],
              oldValue,
              {
                coverArtPrefix: CONSTANTS.CORE_PROXY_SCHEME
              }
            )

            if (track !== oldValue) {

              this._state.nowPlaying.current = track
            }
          }

          if (P.NEXT in value) {

            oldValue = this._state.nowPlaying.next

            track = BasTrack.parseIfSame(
              value[P.NEXT],
              oldValue,
              {
                coverArtPrefix: CONSTANTS.CORE_PROXY_SCHEME
              }
            )

            if (track !== oldValue) {

              this._state.nowPlaying.next = track
            }
          }

          if (value[P.CONTEXT]) {

            this._state.nowPlaying.context = {}

            this._state.nowPlaying.context.name =
              BasUtil.isNEString(value[P.CONTEXT][P.NAME])
                ? value[P.CONTEXT][P.NAME]
                : ''

            this._state.nowPlaying.context.uri =
              BasUtil.isNEString(value[P.CONTEXT][P.URI])
                ? value[P.CONTEXT][P.URI]
                : ''

            stateChanged = true

          } else {

            this._state.nowPlaying.context = null
          }
        } else {

          this._state.nowPlaying.current = null
          this._state.nowPlaying.next = null
        }

        if (oldCurrent !== this._state.nowPlaying.current) {

          currentSongChanged = true
          nowPlayingChanged = true
          stateChanged = true
        }

        if (oldNext !== this._state.nowPlaying.next) {

          nextSongChanged = true
          nowPlayingChanged = true
          stateChanged = true
        }
      }

      // Mute

      value = state[P.MUTE]
      oldValue = this._state.mute

      if (P.MUTE in state &&
        oldValue !== value) {

        this._state.mute = BasUtil.isBool(value)
          ? value
          : null

        if (oldValue !== this._state.mute) {

          muteChanged = true
          stateChanged = true
        }
      }

      // Volume

      value = state[P.VOLUME]
      oldValue = this._state.volume

      if (P.VOLUME in state &&
        oldValue !== value) {

        if (BasUtil.isPNumber(value, true)) {

          this._state.volume = value > 100 ? 100 : value

        } else {

          // Invalid volume level, reset to "0"
          this._state.volume = 0
        }

        if (oldValue !== this._state.volume) {

          volumeChanged = true
          stateChanged = true
        }
      }

      // ListeningRooms

      value = state[P.LISTENING_ROOMS]
      oldValue = this._state.listeningRooms

      if (P.LISTENING_ROOMS in state) {

        this._state.listeningRooms = Array.isArray(value)
          ? BasUtil.filter(value, BasUtil.isNEString)
          : null

        if (!BasUtil.isEqualArrayUn(oldValue, this._state.listeningRooms)) {

          listeningRoomsChanged = true
          stateChanged = true
        }
      }

      // Pairing

      value = state[P.PAIRING]
      oldValue = this._state.pairing

      if (P.PAIRING in state) {

        this._state.pairing = BasUtil.isBool(value)
          ? value
          : null

        if (oldValue !== this._state.pairing) {

          stateChanged = true
        }
      }
    }

    // Default name

    value = msg[P.DEFAULT_NAME]
    oldValue = this._defaultName

    if (P.DEFAULT_NAME in msg &&
      oldValue !== value) {

      this._defaultName = BasUtil.isNEString(value)
        ? value
        : null

      if (oldValue !== this._defaultName) {

        defaultNameChanged = true
      }
    }

    // Colour

    value = msg[P.COLOUR]

    if (P.COLOUR in msg) {

      this._colour = BasUtil.isNEString(value)
        ? value
        : null
    }

    // Room UUID

    value = msg[P.FOLLOW_ROOM_NAME_UUID]

    if (P.FOLLOW_ROOM_NAME_UUID in msg) {

      this._followRoomNameUuid = BasUtil.isNEString(value)
        ? value
        : null
    }

    // Sequence

    value = msg[P.SEQUENCE]

    if (P.SEQUENCE in msg) {

      this._sequence = BasUtil.isInteger(value)
        ? value
        : null
    }

    // Queue

    value = msg[P.QUEUE]

    if (BasUtil.isObject(value)) {

      event = value[P.EVENT]

      switch (event) {
        case P.ADDED:
          queueAdded = true
          queueEvtData = {
            tracks: [],
            contentType: ''
          }

          if (Array.isArray(value[P.TRACKS])) {

            length = value[P.TRACKS].length
            for (i = 0; i < length; i++) {
              queueEvtData.tracks.push(BasTrack.parse(value[P.TRACKS][i]))
            }
          }

          if (BasUtil.isNEString(value[P.CONTENT_TYPE])) {

            queueEvtData.contentType = value[P.CONTENT_TYPE]
          }
          break
        case P.REMOVED:
          queueRemoved = true
          queueEvtData = value[P.IDS]
          break
        case P.MOVED:
          queueMoved = true
          queueEvtData = {
            from: value[P.FROM],
            track: BasTrack.parse(value[P.TRACK])
          }
          break
        case P.RESET:
          queueReset = true
          break
      }
    }

    // Playlist

    value = msg[P.PLAYLISTS]

    if (BasUtil.isObject(value)) {

      event = value[P.EVENT]

      switch (event) {
        case P.ADDED:

          if (Array.isArray(value[P.TRACKS])) {
            // Tracks added to existing playlist
            playlistTrackAdded = true
            playlistEventData = value[P.PLAYLIST]
          } else {
            // New playlist added
            playlistAdded = true
            playlistEventData = value[P.PLAYLIST]
          }
          break
        case P.REMOVED:
          playlistRemoved = true
          playlistEventData = value[P.PLAYLIST]
          break
        case P.MOVED:
          playlistTrackMoved = true
          break
        case P.RENAME:
          playlistRenamed = true
          playlistEventData = value[P.PLAYLIST]
          break
        case P.RESET:
          playlistsReset = true
          break
        case P.TYPE:
          playlistTypeChanged = true
          playlistEventData = value[P.PLAYLIST]
          break
      }
    }

    // Favourites

    value = msg[P.FAVOURITES]

    if (BasUtil.isObject(value)) {

      event = value[P.EVENT]

      switch (event) {
        case P.RESET:
          favouritesReset = true
          break
        case P.ADDED:
          favouriteAdded = true
          favouriteData = AudioSource._parseFavourite(value[P.FAVOURITE])
          break
        case P.REMOVED:
          favouriteRemoved = true
          favouriteData = value[P.FAVOURITE]
          break
        case P.UPDATED:
          favouriteUpdated = true
          favouriteData = AudioSource._parseFavourite(value[P.FAVOURITE])
          break
      }

      if (value[P.QUICK]) {

        if (value[P.QUICK][P.EVENT] === P.RESET) {

          quickFavouritesReset = true
        }
      }
    }

    // Default rooms

    value = msg[P.DEFAULT_ROOMS]

    if (BasUtil.isObject(value)) {

      event = value[P.EVENT]

      switch (event) {
        case P.RESET:
          defaultRoomsReset = true
          break
      }
    }

    // Streaming services

    value = msg[P.STREAMING_SERVICES]

    if (BasUtil.isObject(value)) {

      if (BasUtil.isNEString(value[P.STREAMING_SERVICE])) {

        streamingService = value[P.STREAMING_SERVICE]

        if (BasUtil.isBool(value[P.LINK_SUCCESS])) {

          linkSuccess = value[P.LINK_SUCCESS]
        }

        if (BasUtil.isObject(value[P.DETAIL])) {

          subValue = value[P.DETAIL]

          if (BasUtil.isNEString(subValue[P.LINK_ERROR])) {

            linkError = subValue[P.LINK_ERROR]
          }

          // We can have explicit null session, so use 'in' operator
          if (P.SESSION in subValue) {

            // Tidal

            if (subValue[P.SESSION] === null) {

              token = ''

            } else if (subValue[P.SESSION]) {

              if (BasUtil.isNEString(
                subValue[P.SESSION][P.ACCESS_TOKEN]
              )) {

                token = subValue[P.SESSION][P.ACCESS_TOKEN]
              }

              this.handleCustomStreamingServiceProperties(
                value[P.STREAMING_SERVICE],
                subValue[P.SESSION]
              )
            }

          } else {

            // Spotify token

            if (BasUtil.isString(value[P.DETAIL][P.TOKEN])) {

              token = subValue[P.TOKEN]
            }

            // Deezer token

            if (subValue[P.TOKEN] === null) {

              token = ''
            }

            this.handleCustomStreamingServiceProperties(
              value[P.STREAMING_SERVICE],
              subValue
            )
          }
        }
      }
    }

    // Presets

    value = msg[P.PRESETS]

    if (BasUtil.isObject(value)) {

      event = value[P.EVENT]

      switch (event) {
        case P.LINKED:

          if (
            BasUtil.isPNumber(value[P.ID], true) &&
            BasUtil.isString(value[P.URI])
          ) {
            presetLinkedData = {
              id: value[P.ID],
              uri: value[P.URI]
            }
          }

          break
      }
    }
  }

  if (emit) {

    // State

    if (onChanged) {

      this.emit(AudioSource.EVT_ON_CHANGED, this.isOn)
    }

    if (playbackChanged) {

      this.emit(AudioSource.EVT_PLAYBACK_CHANGED, this.playbackMode)
    }

    if (shuffledChanged) {

      this.emit(AudioSource.EVT_RANDOM_CHANGED, this.random)
    }

    if (repeatedChanged) {

      this.emit(AudioSource.EVT_REPEATED_CHANGED, this.repeatMode)
    }

    if (positionChanged) {

      this.emit(AudioSource.EVT_POSITION_CHANGED, this.positionMs)
    }

    if (currentSongChanged) {

      this.emit(
        AudioSource.EVT_CURRENT_SONG_CHANGED,
        this.nowPlaying.current
      )
    }

    if (nextSongChanged) {

      this.emit(AudioSource.EVT_NEXT_SONG_CHANGED, this.nowPlaying.next)
    }

    if (nowPlayingChanged) {

      this.emit(AudioSource.EVT_NOW_PLAYING_CHANGED, this.nowPlaying)
    }

    if (muteChanged) {

      this.emit(AudioSource.EVT_MUTE_CHANGED, this.mute)
    }

    if (volumeChanged) {

      this.emit(AudioSource.EVT_VOLUME_CHANGED, this.volume)
    }

    if (listeningRoomsChanged) {

      this.emit(AudioSource.EVT_LISTENING_ROOMS_CHANGED, this.listeningRooms)
    }

    if (statusChanged) {

      this.emit(AudioSource.EVT_STATUS_CHANGED, this.status)
    }

    if (stateChanged) {

      this.emit(AudioSource.EVT_STATE_CHANGED)
    }

    // Playlists

    if (playlistsReset) {

      this.emit(AudioSource.EVT_PLAYLISTS_RESET)
    }

    if (playlistRenamed) {

      this.emit(AudioSource.EVT_PLAYLIST_RENAMED, playlistEventData)
    }

    if (playlistTrackMoved) {

      this.emit(AudioSource.EVT_PLAYLIST_TRACK_MOVED, playlistEventData)
    }

    if (playlistAdded) {

      this.emit(AudioSource.EVT_PLAYLIST_ADDED, playlistEventData)
    }

    if (playlistTrackAdded) {

      this.emit(AudioSource.EVT_PLAYLIST_TRACK_ADDED, playlistEventData)
    }

    if (playlistRemoved) {

      this.emit(AudioSource.EVT_PLAYLIST_REMOVED, playlistEventData)
    }

    if (playlistTypeChanged) {

      this.emit(AudioSource.EVT_PLAYLIST_TYPE_CHANGED, playlistEventData)
    }

    // Queue

    if (queueReset) {

      this.emit(AudioSource.EVT_QUEUE_RESET)
    }

    if (queueAdded) {

      this.emit(AudioSource.EVT_QUEUE_ADDED, queueEvtData)
    }

    if (queueMoved) {

      this.emit(AudioSource.EVT_QUEUE_MOVED, queueEvtData)
    }

    if (queueRemoved) {

      this.emit(AudioSource.EVT_QUEUE_REMOVED, queueEvtData)
    }

    // Favourites

    if (favouritesReset) {

      this.emit(AudioSource.EVT_FAVOURITES_RESET)
    }

    if (favouriteAdded) {

      this.emit(AudioSource.EVT_FAVOURITE_ADDED, favouriteData)
    }

    if (favouriteRemoved) {

      this.emit(AudioSource.EVT_FAVOURITE_REMOVED, favouriteData)
    }

    if (favouriteUpdated) {

      this.emit(AudioSource.EVT_FAVOURITE_UPDATED, favouriteData)
    }

    if (quickFavouritesReset) {

      this.emit(AudioSource.EVT_QUICK_FAVOURITE_RESET, favouriteData)
    }

    // Default rooms

    if (defaultRoomsReset) {

      this.emit(AudioSource.EVT_DEFAULT_ROOMS_RESET)
    }

    if (defaultNameChanged) {

      this.emit(AudioSource.EVT_DEFAULT_NAME_CHANGED, this.defaultName)
    }

    // Streaming services

    if (BasUtil.isBool(linkSuccess)) {

      data = {
        streamingService: streamingService,
        finished: linkSuccess
      }

      if (BasUtil.isNEString(linkError)) {
        data.error = linkError
      }

      this.emit(AudioSource.EVT_STREAMING_SERVICE_LINK_FINISHED, data)
    }

    if (BasUtil.isString(token)) {

      this.emit(AudioSource.EVT_STREAMING_SERVICE_TOKEN_CHANGED, {
        streamingService: streamingService,
        token: token
      })
    }

    // Presets

    if (presetLinkedData) {

      this.emit(AudioSource.EVT_PRESET_LINKED, presetLinkedData)
    }
  }

  return valid
}

/**
 * Update a single or multiple properties
 *
 * @param {Object} newState
 * @returns {Promise}
 */
AudioSource.prototype.updateState = function (newState) {

  var msg

  if (!this._basCore) return Promise.reject(CONSTANTS.ERR_NO_CORE)

  msg = this._getBasCoreMessage()
  msg[P.AV_SOURCE][P.STATE] = newState

  return this._basCore.requestRetry(msg, CONSTANTS.RETRY_OPTS_ONE_SHOT)
    .then(Device.handleResponse)
}

/**
 * @param {string} action
 * @param {*} [data]
 * @returns {Promise}
 */
AudioSource.prototype.action = function (
  action,
  data
) {

  var msg

  if (!this._basCore) return Promise.reject(CONSTANTS.ERR_NO_CORE)

  msg = AudioSource.getActionMessage(this._uuid, action, data)

  return this._basCore.requestRetry(msg, CONSTANTS.RETRY_OPTS_ONE_SHOT)
    .then(Device.handleResponse)
}

/**
 * @param {(string|string[])} category
 * @param {string} action
 * @param {*} [data]
 * @returns {Promise}
 */
AudioSource.prototype.nestedAction = function (
  category,
  action,
  data
) {

  var msg, lastLevel, i, length

  if (!this._basCore) return Promise.reject(CONSTANTS.ERR_NO_CORE)

  msg = this._getBasCoreMessage()

  if (Array.isArray(category)) {

    lastLevel = msg[P.AV_SOURCE]

    length = category.length
    for (i = 0; i < length; i++) {

      lastLevel[category[i]] = {}
      lastLevel = lastLevel[category[i]]
    }

  } else {

    msg[P.AV_SOURCE][category] = {}
    lastLevel = msg[P.AV_SOURCE][category]
  }

  lastLevel[P.ACTION] = action

  if (!BasUtil.isUndefined(data)) {

    lastLevel[P.DATA] = data
  }

  return this._basCore.requestRetry(msg, CONSTANTS.RETRY_OPTS_ONE_SHOT)
    .then(Device.handleResponse)
}

/**
 * Sets playback mode of the source
 *
 * @param {string} playback
 * @returns {Promise}
 */
AudioSource.prototype.setPlayback = function (playback) {

  var state

  if (AudioSource.isPlaybackMode(playback, true)) {

    state = {}
    state[P.PLAYBACK] = playback

    return this.updateState(state)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Toggles the 'isOn' state of the source
 *
 * @param {boolean} force
 * @returns {Promise}
 */
AudioSource.prototype.toggleOn = function (force) {

  var state

  state = {}
  state[P.ON] = BasUtil.isBool(force) ? force : !this.isOn

  return this.updateState(state)
}

/**
 * Play the source playback
 *
 * @returns {Promise}
 */
AudioSource.prototype.play = function () {

  return this.setPlayback(AudioSource.A_PM_PLAYING)
}

/**
 * Pause the source playback
 *
 * @returns {Promise}
 */
AudioSource.prototype.pause = function () {

  return this.setPlayback(AudioSource.A_PM_PAUSED)
}

/**
 * Stop the source playback
 *
 * @returns {Promise}
 */
AudioSource.prototype.stop = function () {

  return this.setPlayback(AudioSource.A_PM_IDLE)
}

/**
 * @returns {Promise}
 */
AudioSource.prototype.togglePlayPause = function () {

  return this.action(P.PLAY_PAUSE)
}

/**
 * @returns {Promise}
 */
AudioSource.prototype.next = function () {

  return this.action(P.SKIP_NEXT)
}

/**
 * @returns {Promise}
 */
AudioSource.prototype.previous = function () {

  return this.action(P.SKIP_PREVIOUS)
}

/**
 * Sets repeated mode of the source
 *
 * @param {(string|number)} repeatMode
 * @returns {Promise}
 */
AudioSource.prototype.setRepeated = function (repeatMode) {

  var state, _repeatMode

  _repeatMode = BasUtil.isPNumber(repeatMode, true)
    ? AudioSource.convertRepeatMode(repeatMode)
    : repeatMode

  if (AudioSource.isRepeatedMode(_repeatMode)) {

    state = {}
    state[P.REPEAT] = _repeatMode

    return this.updateState(state)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * @param {boolean} value
 * @returns {Promise}
 */
AudioSource.prototype.setRandom = function (value) {

  var state

  if (BasUtil.isBool(value)) {

    state = {}
    state[P.SHUFFLE] = value

    return this.updateState(state)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * @param {number} positionMs
 * @returns {Promise}
 */
AudioSource.prototype.setPositionMs = function (positionMs) {

  var state

  if (BasUtil.isPNumber(positionMs, true)) {

    state = {}
    state[P.POSITION_MS] = positionMs

    return this.updateState(state)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * @param {number} volume
 * @returns {Promise}
 */
AudioSource.prototype.setVolume = function (volume) {

  var state

  if (BasUtil.isPNumber(volume, true)) {

    state = {}
    state[P.VOLUME] = volume

    return this.updateState(state)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * @param {boolean} mute
 * @returns {Promise}
 */
AudioSource.prototype.setMute = function (mute) {

  var state

  if (BasUtil.isBool(mute)) {

    state = {}
    state[P.MUTE] = mute
    return this.updateState(state)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Plays track with given uri
 *
 * @param {(string|Array<string>)} uri
 * @param {?string} [contextUri]
 * @param {?number} [contextOffset]
 * @returns {Promise}
 */
AudioSource.prototype.playUri = function (
  uri,
  contextUri,
  contextOffset
) {

  var msg = AudioSource.getPlayUriMessage(
    this._uuid,
    uri,
    contextUri,
    contextOffset
  )

  if (msg) {

    return this._basCore.requestRetry(msg, CONSTANTS.RETRY_OPTS_ONE_SHOT)
      .then(Device.handleResponse)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

// Queue

/**
 * @param {number} offset
 * @param {number} limit
 * @returns {Promise<TAudioSourceQueueResult>}
 */
AudioSource.prototype.queueList = function (
  offset,
  limit
) {
  var data

  if (
    BasUtil.isPNumber(offset, true) &&
    BasUtil.isPNumber(limit)
  ) {

    data = {}
    data[P.OFFSET] = offset
    data[P.LIMIT] = limit

    return this.nestedAction(P.QUEUE, P.LIST, data)
      .then(this._handleListQueue)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Get full queue, using paginated calls
 *
 * @returns {Promise<BasTrack[]>}
 */
AudioSource.prototype.queueListAll = function () {

  var _this, tracks, currentOffset, total, limit

  _this = this
  tracks = []
  currentOffset = 0
  total = -1
  limit = 100

  return this.queueList(currentOffset, limit)
    .then(onQueueList)

  /**
   * @param {TAudioSourceQueueResult} queueData
   */
  function onQueueList (queueData) {

    // If the total queue size is not yet know (first 'queueList' action) and
    //  the data contains a valid value for total, we use this total to
    //  determine if we should continue listing the queue in successive
    //  'queueList' calls within this 'queueListAll' function.
    if (total === -1 && queueData.total > -1) {
      total = queueData.total
    }

    tracks = tracks.concat(queueData.list)

    if (currentOffset + queueData.list.length < total) {

      currentOffset += queueData.list.length

      return _this.queueList(currentOffset, limit)
        .then(onQueueList)

    } else {

      return Promise.resolve(tracks)
    }
  }
}

/**
 * @private
 * @param {Object} msg
 * @returns {TAudioSourceQueueResult}
 */
AudioSource.prototype._onListQueue = function (msg) {

  var result, queue, value, length, i, item

  result = {
    list: [],
    offset: 0,
    total: 0,
    contentType: ''
  }

  if (
    BasUtil.isObject(msg[P.AV_SOURCE]) &&
    msg[P.AV_SOURCE][P.UUID] === this.uuid &&
    BasUtil.isObject(msg[P.AV_SOURCE][P.QUEUE])
  ) {
    queue = msg[P.AV_SOURCE][P.QUEUE]

    // List

    value = queue[P.LIST]
    if (Array.isArray(value)) {

      length = value.length
      for (i = 0; i < length; i++) {

        item = BasTrack.parse(value[i])
        if (item) result.list.push(item)
      }
    }

    // Offset

    value = queue[P.OFFSET]
    if (BasUtil.isPNumber(value, true)) result.offset = value

    // Total

    value = queue[P.TOTAL]
    if (BasUtil.isPNumber(value, true)) result.total = value

    // ContentType

    value = queue[P.CONTENT_TYPE]
    if (BasUtil.isNEString(value)) result.contentType = value
  }

  return result
}

/**
 * @param {number} position
 * @returns {Promise}
 */
AudioSource.prototype.queuePlayItem = function (position) {

  var data

  if (BasUtil.isPNumber(position, true)) {

    data = {}
    data[P.POSITION] = position

    return this.nestedAction(P.QUEUE, P.PLAY, data)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Options:
 * - append      Append tracks to end of queue
 * - next        Insert tracks after current song
 * - now         Insert tracks after current song and start first of new
 * - replace     Replace queue with tracks
 * - replaceNow  Replace queue with tracks and start playing
 *
 * @param {(string|string[])} tracks
 * @param {string} [option]
 * @param {boolean} [isLegacyOption]
 * @returns {Promise}
 */
AudioSource.prototype.queueAddItems = function (
  tracks,
  option,
  isLegacyOption
) {
  var _tracks, data

  if (BasUtil.isNEString(tracks)) {
    _tracks = [tracks]
  } else if (Array.isArray(tracks)) {
    _tracks = tracks
  }

  if (Array.isArray(_tracks)) {

    data = {}
    data[P.TRACKS] = _tracks
    data[P.OPTION] = BasUtil.isNEString(option)
      ? isLegacyOption
        ? this._getAudioSourceQueueOption(option)
        : option
      : AudioSource.A_QO_APPEND

    return this.nestedAction(P.QUEUE, P.ADD, data)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Options:
 * - append      Append tracks to end of queue
 * - next        Insert tracks after current song
 * - now         Insert tracks after current song and start first of new
 * - replace     Replace queue with tracks
 * - replaceNow  Replace queue with tracks and start playing
 *
 * @param {string} uri
 * @param {string} [option]
 * @param {boolean} [legacyOption]
 * @returns {Promise}
 */
AudioSource.prototype.queueAddUri = function (
  uri,
  option,
  legacyOption
) {
  var data

  if (BasUtil.isNEString(uri)) {

    data = {}
    data[P.URI] = uri
    data[P.OPTION] = BasUtil.isNEString(option)
      ? legacyOption
        ? this._getAudioSourceQueueOption(option)
        : option
      : AudioSource.A_QO_APPEND

    return this.nestedAction(P.QUEUE, P.ADD, data)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * @param {number[]} ids
 * @returns {Promise}
 */
AudioSource.prototype.queueRemoveItems = function (ids) {

  var data

  if (Array.isArray(ids)) {

    data = {}
    data[P.IDS] = ids

    return this.nestedAction(P.QUEUE, P.REMOVE, data)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * @returns {Promise}
 */
AudioSource.prototype.queueClear = function () {

  return this.nestedAction(P.QUEUE, P.REMOVE)
}

/**
 * @param {number} from
 * @param {number} to
 * @returns {Promise}
 */
AudioSource.prototype.queueMoveItem = function (
  from,
  to
) {
  var data

  if (
    BasUtil.isPNumber(from, true) &&
    BasUtil.isPNumber(to, true)
  ) {

    data = {}
    data[P.FROM] = from
    data[P.TO] = to

    return this.nestedAction(P.QUEUE, P.MOVE, data)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

// Favourites

/**
 * Requests favourites
 *
 * @param {number} offset
 * @param {number} limit
 * @returns {Promise<TAudioSourceFavouritesResult>}
 */
AudioSource.prototype.listFavouritesLegacy = function (
  offset,
  limit
) {
  var data

  if (
    BasUtil.isPNumber(offset, true) &&
    BasUtil.isPNumber(limit, false)
  ) {

    data = {}
    data[P.OFFSET] = offset
    data[P.LIMIT] = limit

    return this.action(P.LIST_FAVOURITES, data)
      .then(this._handleFavourites)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Requests favourites services
 *
 * @returns {Promise<TAudioSourceFavouritesServicesResult>}
 */
AudioSource.prototype.listFavouritesServices = function () {

  return this.nestedAction(P.FAVOURITES, P.LIST)
    .then(this._handleFavouritesServices)
}

/**
 * Requests favourites for a service
 *
 * @param {string} service
 * @param {number} offset
 * @param {number} limit
 * @returns {Promise<TAudioSourceFavouritesResult>}
 */
AudioSource.prototype.listFavourites = function (
  service,
  offset,
  limit
) {
  var data

  if (
    BasUtil.isNEString(service) &&
    BasUtil.isPNumber(offset, true) &&
    BasUtil.isPNumber(limit, false)
  ) {

    data = {}
    data[P.SERVICE] = service
    data[P.OFFSET] = offset
    data[P.LIMIT] = limit

    return this.nestedAction(P.FAVOURITES, P.LIST, data)
      .then(addService)
      .then(this._handleFavourites)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)

  function addService (msg) {

    if (
      BasUtil.isObject(msg[P.AV_SOURCE]) &&
      BasUtil.isObject(msg[P.AV_SOURCE][P.FAVOURITES])
    ) {

      msg[P.AV_SOURCE][P.FAVOURITES][P.SERVICE] = service
    }
    return msg
  }
}

/**
 * Add favourite by uri
 *
 * @param {string} uri
 * @returns {Promise<TAudioSourceFavouritesResult>}
 */
AudioSource.prototype.addFavourite = function (uri) {

  var data

  if (BasUtil.isNEString(uri)) {

    data = {}
    data[P.URI] = uri

    return this.nestedAction(
      P.FAVOURITES,
      P.ADD,
      data
    )
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Remove favourite by uri
 *
 * @param {string} uri
 * @returns {Promise<TAudioSourceFavouritesResult>}
 */
AudioSource.prototype.removeFavourite = function (uri) {

  var data

  if (BasUtil.isNEString(uri)) {

    data = {}
    data[P.URI] = uri

    return this.nestedAction(
      P.FAVOURITES,
      P.REMOVE,
      data
    )
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * @private
 * @param {Object} msg
 * @returns {TAudioSourceFavouritesResult}
 */
AudioSource.prototype._onFavourites = function (msg) {

  var result, favourites, value, length, i

  /**
   * @type {TAudioSourceFavouritesResult}
   */
  result = {
    list: [],
    offset: 0,
    total: 0,
    service: ''
  }

  if (
    BasUtil.isObject(msg[P.AV_SOURCE]) &&
    BasUtil.isObject(msg[P.AV_SOURCE][P.FAVOURITES]) &&
    msg[P.AV_SOURCE][P.UUID] === this.uuid
  ) {

    favourites = msg[P.AV_SOURCE][P.FAVOURITES]

    // List

    value = favourites[P.LIST]

    if (Array.isArray(value)) {

      length = value.length
      for (i = 0; i < length; i++) {

        result.list.push(AudioSource._parseFavourite(value[i]))
      }
    }

    // Offset

    value = favourites[P.OFFSET]
    if (BasUtil.isPNumber(value, true)) result.offset = value

    // Total

    value = favourites[P.TOTAL]
    if (BasUtil.isPNumber(value, true)) result.total = value

    // Service

    value = favourites[P.SERVICE]
    if (BasUtil.isNEString(value)) result.service = value
  }

  return result
}

/**
 * @private
 * @param {Object} msg
 * @returns {TAudioSourceFavouritesServicesResult}
 */
AudioSource.prototype._onFavouritesServices = function (msg) {

  var services, favourites, value, length, i, keys

  /**
   * @type {TAudioSourceFavouritesServicesResult}
   */
  services = {}

  if (
    BasUtil.isObject(msg[P.AV_SOURCE]) &&
    BasUtil.isObject(msg[P.AV_SOURCE][P.FAVOURITES]) &&
    msg[P.AV_SOURCE][P.UUID] === this.uuid
  ) {

    favourites = msg[P.AV_SOURCE][P.FAVOURITES]

    // Services

    value = favourites[P.SERVICES]

    if (BasUtil.isObject(value)) {

      keys = Object.keys(value)

      length = keys.length
      for (i = 0; i < length; i++) {

        if (
          BasUtil.isNEString(keys[i]) &&
          BasUtil.isPNumber(value[keys[i]], true)
        ) {

          services[keys[i]] = value[keys[i]]
        }
      }

      return services
    }
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_RESPONSE)
}

/**
 * List quick favourites
 *
 * @returns {Promise<TAudioSourceFavourite[]>}
 */
AudioSource.prototype.listQuickFavourites = function () {

  return this.nestedAction(
    [P.FAVOURITES, P.QUICK],
    P.LIST
  ).then(this._handleQuickFavourites)
}

/**
 * @private
 * @param {Object} msg
 * @returns {TAudioSourceFavourite[]}
 */
AudioSource.prototype._onQuickFavourites = function (msg) {

  var list, i, length

  /**
   * @type {TAudioSourceFavourite[]}
   */
  var result = []

  if (
    BasUtil.isObject(msg[P.AV_SOURCE]) &&
    msg[P.AV_SOURCE][P.UUID] === this.uuid &&
    msg[P.AV_SOURCE][P.FAVOURITES] &&
    msg[P.AV_SOURCE][P.FAVOURITES][P.QUICK] &&
    Array.isArray(msg[P.AV_SOURCE][P.FAVOURITES][P.QUICK][P.LIST])
  ) {

    list = msg[P.AV_SOURCE][P.FAVOURITES][P.QUICK][P.LIST]

    length = list.length
    for (i = 0; i < length; i++) {

      result.push(AudioSource._parseFavourite(list[i]))
    }

    return result
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_RESPONSE)
}

/**
 * Set quick favourites
 *
 * @param {string[]} uris
 * @returns {Promise<TAudioSourceFavourite[]>}
 */
AudioSource.prototype.setQuickFavourites = function (uris) {

  var data

  if (Array.isArray(uris)) {

    data = {}
    data[P.URIS] = uris

    return this.nestedAction(
      [P.FAVOURITES, P.QUICK],
      P.SET,
      data
    )
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

// Playlists

/**
 * Requests playlist types
 *
 * @param {string} type
 * @returns {Promise<TAudioSourcePlaylistsResult>}
 */
AudioSource.prototype.listPlaylistTypes = function (type) {

  var data

  if (BasUtil.isNEString(type)) {

    data = {}

    return this.nestedAction(P.PLAYLISTS, P.LIST, data)
      .then(this._handlePlaylists)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Requests playlists
 *
 * @param {string} type
 * @param {?number} [offset]
 * @param {?number} [limit]
 * @returns {Promise<TAudioSourcePlaylistsResult>}
 */
AudioSource.prototype.listPlaylists = function (
  type,
  offset,
  limit
) {
  var data

  if (BasUtil.isNEString(type)) {

    data = {}
    data[P.TYPE] = type

    if (BasUtil.isPNumber(offset, true)) data[P.OFFSET] = offset
    if (BasUtil.isPNumber(limit, false)) data[P.LIMIT] = limit

    return this.nestedAction(P.PLAYLISTS, P.LIST, data)
      .then(this._handlePlaylists)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Search playlists
 *
 * @param {string} query
 * @param {?number} offset
 * @param {?number} limit
 * @returns {Promise<TAudioSourcePlaylistsResult>}
 */
AudioSource.prototype.searchPlaylists = function (
  query,
  offset,
  limit
) {
  var data

  if (
    BasUtil.isPNumber(offset, true) &&
    BasUtil.isPNumber(limit, true) &&
    BasUtil.isNEString(query)
  ) {

    data = {}
    data[P.OFFSET] = offset
    data[P.LIMIT] = limit
    data[P.QUERY] = query

    return this.nestedAction(
      P.PLAYLISTS,
      P.SEARCH,
      data
    ).then(this._handlePlaylists)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Get playlist
 *
 * @param {string} uri
 * @param {number} [offset]
 * @param {number} [limit]
 * @returns {Promise<TAudioSourcePlaylistDetailsResult>}
 */
AudioSource.prototype.getPlaylistDetails = function (
  uri,
  offset,
  limit
) {
  var data

  if (BasUtil.isNEString(uri)) {

    data = {}

    if (BasUtil.isPNumber(offset, true)) {

      data[P.OFFSET] = offset
    }

    if (BasUtil.isPNumber(limit, true)) {

      data[P.LIMIT] = limit
    }

    data[P.URI] = uri

    return this.nestedAction(
      P.PLAYLISTS,
      P.DETAIL,
      data
    ).then(this._handlePlaylistDetails)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Get playlist
 *
 * @param {string} uri
 * @returns {Promise<BasTrack[]>}
 */
AudioSource.prototype.getAllPlaylistTracks = function (uri) {

  var _this, tracks, currentOffset, total, limit

  _this = this
  tracks = []
  currentOffset = 0
  total = -1
  limit = 100

  if (BasUtil.isNEString(uri)) {

    return this.getPlaylistDetails(uri, currentOffset, limit)
      .then(onPlaylistDetails)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)

  /**
   * @param {TAudioSourcePlaylistDetailsResult} playlistDetails
   */
  function onPlaylistDetails (playlistDetails) {

    if (total === -1 && playlistDetails.total > -1) {
      total = playlistDetails.total
    }

    tracks = tracks.concat(playlistDetails.list)

    // Keep retrieving playlists until we reached the total length
    if (currentOffset + playlistDetails.list.length < total) {

      currentOffset += playlistDetails.list.length

      return _this.getPlaylistDetails(uri, currentOffset, limit)
        .then(onPlaylistDetails)

    }

    return Promise.resolve(tracks)
  }
}

/**
 * Move track within a playlist
 *
 * @param {string} uri
 * @param {number} from
 * @param {number} to
 * @returns {Promise}
 */
AudioSource.prototype.movePlaylistTrack = function (
  uri,
  from,
  to
) {
  var data

  if (
    BasUtil.isPNumber(from, true) &&
    BasUtil.isPNumber(to, true) &&
    BasUtil.isNEString(uri)
  ) {

    data = {}
    data[P.URI] = uri
    data[P.FROM] = from
    data[P.TO] = to

    return this.nestedAction(
      P.PLAYLISTS,
      P.MOVE,
      data
    )
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Add track to a playlist
 *
 * @param {string} name
 * @param {string[]} tracks
 * @returns {Promise}
 */
AudioSource.prototype.addSongsToNewPlaylist = function (
  name,
  tracks
) {
  var data

  if (
    BasUtil.isNEString(name) &&
    BasUtil.isNEArray(tracks)
  ) {

    data = {}
    data[P.NAME] = name
    data[P.TRACKS] = tracks

    return this.nestedAction(
      P.PLAYLISTS,
      P.ADD,
      data
    )
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Add a playlist
 *
 * @param {string} playlistUri
 * @param {string[]} tracks
 * @returns {Promise}
 */
AudioSource.prototype.addSongsToPlaylist = function (
  playlistUri,
  tracks
) {
  var data

  if (
    BasUtil.isNEString(playlistUri) &&
    BasUtil.isNEArray(tracks)
  ) {

    data = {}
    data[P.URI] = playlistUri
    data[P.TRACKS] = tracks

    return this.nestedAction(
      P.PLAYLISTS,
      P.ADD,
      data
    )
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Remove playlist
 *
 * @param {string} uri
 * @returns {Promise}
 */
AudioSource.prototype.removePlaylist = function (uri) {

  var data

  if (BasUtil.isNEString(uri)) {

    data = {}
    data[P.URI] = uri

    return this.nestedAction(
      P.PLAYLISTS,
      P.REMOVE,
      data
    )
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Remove playlist
 *
 * @param {string} uri
 * @param {number[]} positions
 * @returns {Promise}
 */
AudioSource.prototype.removePlaylistTracks = function (
  uri,
  positions
) {
  var data

  if (
    BasUtil.isNEString(uri) &&
    BasUtil.isNEArray(positions)
  ) {

    data = {}
    data[P.URI] = uri
    data[P.POSITIONS] = positions

    return this.nestedAction(
      P.PLAYLISTS,
      P.REMOVE,
      data
    )
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Rename playlist
 *
 * @param {string} uri
 * @param {string} name
 * @returns {Promise}
 */
AudioSource.prototype.renamePlaylist = function (
  uri,
  name
) {
  var data

  if (
    BasUtil.isNEString(uri) &&
    BasUtil.isNEString(name)
  ) {

    data = {}
    data[P.URI] = uri
    data[P.NAME] = name

    return this.nestedAction(
      P.PLAYLISTS,
      P.RENAME,
      data
    )
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Share playlist
 *
 * @param {string} uri
 * @param {boolean} shared
 * @returns {Promise}
 */
AudioSource.prototype.sharePlaylist = function (
  uri,
  shared
) {
  var data

  if (
    BasUtil.isNEString(uri) &&
    BasUtil.isBool(shared)
  ) {

    data = {}
    data[P.URI] = uri
    data[P.SHARED] = shared

    return this.nestedAction(
      P.PLAYLISTS,
      P.SHARE,
      data
    )
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

AudioSource.prototype._onPlaylistDetails = function (msg) {

  var length, i, tracks, result, detail

  /**
   * @type {TAudioSourcePlaylistDetailsResult}
   */
  result = {
    list: [],
    offset: 0,
    total: 0
  }

  if (
    BasUtil.isObject(msg[P.AV_SOURCE]) &&
    BasUtil.isObject(msg[P.AV_SOURCE][P.PLAYLISTS]) &&
    BasUtil.isObject(msg[P.AV_SOURCE][P.PLAYLISTS][P.PLAYLIST])
  ) {
    detail = msg[P.AV_SOURCE][P.PLAYLISTS][P.PLAYLIST]

    if (Array.isArray(detail[P.TRACKS])) {

      tracks = detail[P.TRACKS]

      length = tracks.length

      for (i = 0; i < length; i++) {
        result.list.push(BasTrack.parse(tracks[i]))
      }

      this._basCore.processCoverArts(result.list, false)
    }

    if (BasUtil.isPNumber(msg[P.AV_SOURCE][P.PLAYLISTS][P.TOTAL], true)) {

      result.total = msg[P.AV_SOURCE][P.PLAYLISTS][P.TOTAL]
    }

    if (BasUtil.isPNumber(msg[P.AV_SOURCE][P.PLAYLISTS][P.OFFSET], true)) {

      result.offset = msg[P.AV_SOURCE][P.PLAYLISTS][P.OFFSET]
    }

    return result
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_RESPONSE)
}

/**
 * @private
 * @param {Object} msg
 * @returns {TAudioSourcePlaylistsResult}
 */
AudioSource.prototype._onPlaylists = function (msg) {

  var result, playlists, value, length, i

  /**
   * @type {TAudioSourcePlaylistsResult}
   */
  result = {
    list: [],
    offset: 0,
    total: 0
  }

  if (
    BasUtil.isObject(msg[P.AV_SOURCE]) &&
    BasUtil.isObject(msg[P.AV_SOURCE][P.PLAYLISTS]) &&
    msg[P.AV_SOURCE][P.UUID] === this.uuid
  ) {
    playlists = msg[P.AV_SOURCE][P.PLAYLISTS]

    // List

    value = playlists[P.LIST]

    if (Array.isArray(value)) {

      length = value.length
      for (i = 0; i < length; i++) {

        if (BasUtil.isObject(value[i])) {

          result.list.push(AudioSource._parsePlaylist.bind(this)(value[i]))
        }
      }
    }

    // Offset

    value = playlists[P.OFFSET]
    if (BasUtil.isPNumber(value, true)) result.offset = value

    // Total

    value = playlists[P.TOTAL]
    if (BasUtil.isPNumber(value, true)) result.total = value
  }

  return result
}

/**
 * Requests default rooms
 *
 * @returns {Promise<TAudioSourceDefaultRoomsResult>}
 */
AudioSource.prototype.listDefaultRooms = function () {

  return this.nestedAction(P.DEFAULT_ROOMS, P.LIST)
    .then(this._handleDefaultRooms)
}

/**
 * @private
 * @param {Object} msg
 * @returns {TAudioSourceDefaultRoomsResult}
 */
AudioSource.prototype._onDefaultRooms = function (msg) {

  var result, defaultRooms, value, subValue, defaultRoomInfo, length, i, keys

  /**
   * @type {TAudioSourceDefaultRoomsResult}
   */
  result = {
    list: {}
  }

  if (
    BasUtil.isObject(msg[P.AV_SOURCE]) &&
    BasUtil.isObject(msg[P.AV_SOURCE][P.DEFAULT_ROOMS]) &&
    msg[P.AV_SOURCE][P.UUID] === this.uuid
  ) {
    defaultRooms = msg[P.AV_SOURCE][P.DEFAULT_ROOMS]

    // List

    value = defaultRooms[P.LIST]

    if (BasUtil.isObject(value)) {

      // Loop over all room keys

      keys = Object.keys(value)
      length = keys.length
      for (i = 0; i < length; i++) {

        if (BasUtil.isObject(value[[keys[i]]])) {

          /**
           * @type {TAudioSourceDefaultRoomInfo}
           */
          defaultRoomInfo = {
            default: false,
            editable: false
          }

          // Uri

          subValue = value[[keys[i]]][P.DEFAULT_ROOM]
          if (BasUtil.isBool(subValue)) defaultRoomInfo.default = subValue

          // Name

          subValue = value[[keys[i]]][P.EDITABLE]
          if (BasUtil.isBool(subValue)) defaultRoomInfo.editable = subValue

          result.list[keys[i]] = defaultRoomInfo
        }
      }
    }
  }

  return result
}

/**
 * @param {string[]} rooms
 * @returns {Promise}
 */
AudioSource.prototype.setDefaultRooms = function (rooms) {

  var data

  if (Array.isArray(rooms)) {

    data = {}
    data[P.DEFAULT_ROOMS] = rooms

    return this.nestedAction(
      P.DEFAULT_ROOMS,
      P.SET,
      data
    ).then(this._handleDefaultRooms)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * @param {boolean} pairing
 * @returns {Promise}
 */
AudioSource.prototype.setPairing = function (pairing) {

  var data

  if (BasUtil.isBool(pairing)) {

    data = {}
    data[P.PAIRING] = pairing

    return this.updateState(data)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * @returns {Promise<string[]>}
 */
AudioSource.prototype.listStreamingServices = function () {

  return this.nestedAction(
    P.STREAMING_SERVICES,
    P.LIST
  ).then(this._handleStreamingServiceList)
}

/**
 * @param {?Object} msg
 * @returns {?string[]}
 */
AudioSource.prototype._onStreamingServiceList = function (msg) {

  var streamingService

  if (
    BasUtil.isObject(msg[P.AV_SOURCE]) &&
    msg[P.AV_SOURCE][P.UUID] === this.uuid &&
    BasUtil.isObject(msg[P.AV_SOURCE][P.STREAMING_SERVICES])
  ) {

    streamingService = msg[P.AV_SOURCE][P.STREAMING_SERVICES]

    if (Array.isArray(streamingService[P.LIST])) {

      return streamingService[P.LIST]
    }
  }
}

/**
 * @param {string} service
 * @returns {Promise<TAudioSourceStreamingServiceDetailsResult>}
 */
AudioSource.prototype.getStreamingServiceDetails = function (service) {

  var data

  if (BasUtil.isNEString(service)) {

    data = {}
    data[P.STREAMING_SERVICE] = service

    return this.nestedAction(
      P.STREAMING_SERVICES,
      P.DETAIL,
      data
    ).then(this._handleStreamingServiceDetails)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * @param {Object} msg
 * @returns {TAudioSourceStreamingServiceDetailsResult}
 */
AudioSource.prototype._onStreamingServiceDetails = function (msg) {

  var result, streamingService, detail

  /**
   * @type {TAudioSourceStreamingServiceDetailsResult}
   */
  result = {
    streamingService: '',
    token: ''
  }

  if (
    BasUtil.isObject(msg[P.AV_SOURCE]) &&
    msg[P.AV_SOURCE][P.UUID] === this.uuid &&
    msg[P.AV_SOURCE][P.STREAMING_SERVICES] &&
    msg[P.AV_SOURCE][P.STREAMING_SERVICES][P.DETAIL]
  ) {
    streamingService = msg[P.AV_SOURCE][P.STREAMING_SERVICES]
    detail = streamingService[P.DETAIL]

    if (BasUtil.isNEString(streamingService[P.STREAMING_SERVICE])) {

      result.streamingService = streamingService[P.STREAMING_SERVICE]

      // Tidal token

      if (BasUtil.isNEString(detail[P.ACCESS_TOKEN])) {

        result.token = detail[P.ACCESS_TOKEN]
      }

      // Spotify token

      if (BasUtil.isString(detail[P.TOKEN])) {

        result.token = detail[P.TOKEN]
      }

      this.handleCustomStreamingServiceProperties(
        result.streamingService,
        detail
      )

      return result
    }

    return Promise.reject('Could not determine streaming service')
  }

  return Promise.reject('No streaming service details found in message')
}

/**
 * @param {string} streamingService
 * @param {Object} obj
 */
AudioSource.prototype.handleCustomStreamingServiceProperties = function (
  streamingService,
  obj
) {
  var valueUpdatedEvt

  if (obj) {

    // Extra properties

    // Tidal uses 'userId'
    if (BasUtil.isPNumber(obj[P.USER_ID])) {

      valueUpdatedEvt = {}
      valueUpdatedEvt.streamingService = streamingService
      valueUpdatedEvt.key = P.USER_ID
      valueUpdatedEvt.value = obj[P.USER_ID]

      this.emit(
        AudioSource.EVT_STREAMING_SERVICE_VALUE_UPDATED,
        valueUpdatedEvt
      )
    }

    // Deezer uses 'userid'
    if (BasUtil.isPNumber(obj[P.USERID])) {

      valueUpdatedEvt = {}
      valueUpdatedEvt.streamingService = streamingService
      valueUpdatedEvt.key = P.USER_ID
      valueUpdatedEvt.value = obj[P.USERID]

      this.emit(
        AudioSource.EVT_STREAMING_SERVICE_VALUE_UPDATED,
        valueUpdatedEvt
      )
    }

    if (BasUtil.isNEString(obj[P.COUNTRY_CODE])) {

      valueUpdatedEvt = {}
      valueUpdatedEvt.streamingService = streamingService
      valueUpdatedEvt.key = P.COUNTRY_CODE
      valueUpdatedEvt.value = obj[P.COUNTRY_CODE]

      this.emit(
        AudioSource.EVT_STREAMING_SERVICE_VALUE_UPDATED,
        valueUpdatedEvt
      )
    }

    if (BasUtil.isNEString(obj[P.LINKED])) {

      valueUpdatedEvt = {}
      valueUpdatedEvt.streamingService = streamingService
      valueUpdatedEvt.key = P.LINKED
      valueUpdatedEvt.value = obj[P.LINKED]

      this.emit(
        AudioSource.EVT_STREAMING_SERVICE_VALUE_UPDATED,
        valueUpdatedEvt
      )
    }

    if (BasUtil.isNEString(obj[P.STATUS])) {

      valueUpdatedEvt = {}
      valueUpdatedEvt.streamingService = streamingService
      valueUpdatedEvt.key = P.STATUS
      valueUpdatedEvt.value = obj[P.STATUS]

      this.emit(
        AudioSource.EVT_STREAMING_SERVICE_VALUE_UPDATED,
        valueUpdatedEvt
      )
    }

    if (BasUtil.isNEString(obj[P.USERNAME])) {

      valueUpdatedEvt = {}
      valueUpdatedEvt.streamingService = streamingService
      valueUpdatedEvt.key = P.USERNAME
      valueUpdatedEvt.value = obj[P.USERNAME]

      this.emit(
        AudioSource.EVT_STREAMING_SERVICE_VALUE_UPDATED,
        valueUpdatedEvt
      )
    }
  }
}

/**
 * @param {string} service
 * @returns {Promise}
 */
AudioSource.prototype.logoutStreamingService = function (service) {

  var data

  if (BasUtil.isNEString(service)) {

    data = {}
    data[P.STREAMING_SERVICE] = service

    return this.nestedAction(
      P.STREAMING_SERVICES,
      P.LOGOUT,
      data
    )
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * @param {string} service
 * @returns {Promise}
 */
AudioSource.prototype.loginStreamingService = function (service) {

  var data

  if (BasUtil.isNEString(service)) {

    data = {}
    data[P.STREAMING_SERVICE] = service

    return this.nestedAction(
      P.STREAMING_SERVICES,
      P.LOGIN,
      data
    ).then(this._handleStreamingServiceLogin)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * @param {Object} msg
 * @returns {string}
 */
AudioSource.prototype._onStreamingServiceLogin = function (msg) {

  var streamingServices, value

  if (
    BasUtil.isObject(msg[P.AV_SOURCE]) &&
    BasUtil.isObject(msg[P.AV_SOURCE][P.STREAMING_SERVICES]) &&
    msg[P.AV_SOURCE][P.UUID] === this.uuid
  ) {

    streamingServices = msg[P.AV_SOURCE][P.STREAMING_SERVICES]

    // Link URL

    value = streamingServices[P.LINK_URL]

    if (BasUtil.isNEString(value)) {

      return this._basCore.getHTTPUrl(value)
    }
  }

  return ''
}

/**
 * @param {string} legacyOption
 * @returns {string}
 */
AudioSource.prototype._getAudioSourceQueueOption = function (legacyOption) {

  switch (legacyOption) {
    case Queue.ADD_OPTIONS.next:
      return AudioSource.A_QO_NEXT
    case Queue.ADD_OPTIONS.end:
      return AudioSource.A_QO_APPEND
    case Queue.ADD_OPTIONS.now:
      return AudioSource.A_QO_NOW
    case Queue.ADD_OPTIONS.replace:
      return AudioSource.A_QO_REPLACE
    case Queue.ADD_OPTIONS.replaceNow:
      return AudioSource.A_QO_REPLACE_NOW
    default:
      return AudioSource.A_QO_APPEND
  }
}

/**
 * @param {number} offset
 * @param {number} limit
 * @returns {Promise<TAudioSourcePresetsResult>}
 */
AudioSource.prototype.listPresets = function (offset, limit) {

  var data

  data = {}

  if (
    BasUtil.isPNumber(offset, true) &&
    BasUtil.isPNumber(limit)
  ) {

    data = {}
    data[P.OFFSET] = offset
    data[P.LIMIT] = limit

    return this.nestedAction(
      P.PRESETS,
      P.LIST,
      data
    ).then(this._handlePresetsList)
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * @param {?Object} msg
 * @returns {?TAudioSourcePresetsResult}
 */
AudioSource.prototype._onPresetsList = function (msg) {

  var streamingService

  if (
    BasUtil.isObject(msg[P.AV_SOURCE]) &&
    msg[P.AV_SOURCE][P.UUID] === this.uuid &&
    BasUtil.isObject(msg[P.AV_SOURCE][P.PRESETS])
  ) {

    streamingService = msg[P.AV_SOURCE][P.PRESETS]

    if (
      Array.isArray(streamingService[P.LIST]) &&
      BasUtil.isNumber(streamingService[P.TOTAL]) &&
      BasUtil.isNumber(streamingService[P.OFFSET])
    ) {

      return streamingService

    } else {

      return Promise.reject('Invalid presets message')
    }
  }

  return null
}

/**
 * @param {number} id
 * @param {string} uri
 * @returns {Promise}
 */
AudioSource.prototype.linkPreset = function (id, uri) {

  var data

  data = {}

  if (
    BasUtil.isPNumber(id, true) &&
    BasUtil.isString(uri)
  ) {

    data = {}
    data[P.ID] = id
    data[P.URI] = uri

    return this.nestedAction(
      P.PRESETS,
      P.LINK,
      data
    )
  }

  return Promise.reject(CONSTANTS.ERR_INVALID_PARAMETERS)
}

/**
 * Creates a template basCore message for this audio source
 *
 * @protected
 * @returns {TDeviceMessage}
 */
AudioSource.prototype._getBasCoreMessage = function () {

  return AudioSource.getBasCoreMessage(this.uuid)
}

/**
 * Creates a template basCore message for this audio source
 *
 * @protected
 * @param {string} audioSourceUuid
 * @returns {Object}
 */
AudioSource.getBasCoreMessage = function (audioSourceUuid) {

  var msg = {}

  msg[P.AV_SOURCE] = {}
  msg[P.AV_SOURCE][P.UUID] = audioSourceUuid

  return msg
}

/**
 * @param {string} audioSourceUuid
 * @param {string} action
 * @param {*} [data]
 * @returns {Object}
 */
AudioSource.getActionMessage = function (
  audioSourceUuid,
  action,
  data
) {
  var msg = AudioSource.getBasCoreMessage(audioSourceUuid)
  msg[P.AV_SOURCE][P.ACTION] = action
  if (!BasUtil.isUndefined(data)) msg[P.AV_SOURCE][P.DATA] = data

  return msg
}

/**
 * @param {string} audioSourceUuid
 * @returns {Object}
 */
AudioSource.getTogglePlayPauseMessage = function (audioSourceUuid) {

  return AudioSource.getActionMessage(audioSourceUuid, P.PLAY_PAUSE)
}

/**
 * @param {string} audioSourceUuid
 * @returns {Object}
 */
AudioSource.getNextMessage = function (audioSourceUuid) {

  return AudioSource.getActionMessage(audioSourceUuid, P.SKIP_NEXT)
}

/**
 * @param {string} audioSourceUuid
 * @returns {Object}
 */
AudioSource.getPreviousMessage = function (audioSourceUuid) {

  return AudioSource.getActionMessage(audioSourceUuid, P.SKIP_PREVIOUS)
}

/**
 * Gets websocket message to send for playing a track with given uri
 *
 * @param {string} audioSourceUuid
 * @param {(string|Array<string>)} uri
 * @param {?string} [contextUri]
 * @param {?number} [contextOffset]
 * @returns {?Object}
 */
AudioSource.getPlayUriMessage = function (
  audioSourceUuid,
  uri,
  contextUri,
  contextOffset
) {
  var data

  if (BasUtil.isNEString(uri) || Array.isArray(uri)) {

    data = {}
    data[Array.isArray(uri) ? P.URIS : P.URI] = uri

    if (
      BasUtil.isNEString(contextUri) ||
      BasUtil.isPNumber(contextOffset, true)
    ) {

      data[P.CONTEXT] = {}
    }

    if (BasUtil.isNEString(contextUri)) {

      data[P.CONTEXT][P.URI] = contextUri
    }

    if (BasUtil.isPNumber(contextOffset, true)) {

      data[P.CONTEXT][P.OFFSET] = contextOffset
    }

    return AudioSource.getActionMessage(
      audioSourceUuid,
      P.PLAY_URI,
      data
    )
  }

  return null
}

module.exports = AudioSource
