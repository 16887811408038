'use strict'

import * as BasUtil from '@basalte/bas-util'

angular
  .module('basalteApp')
  .controller('numberInputSecurityModalCtrl', [
    '$rootScope',
    '$scope',
    'SECURITY',
    'BasSecurity',
    'close',
    'basModalConfig',
    'BasUtilities',
    numberInputSecurityModalCtrl
  ])

/**
 * @param $rootScope
 * @param $scope
 * @param SECURITY
 * @param {BasSecurity} BasSecurity
 * @param close
 * @param {BasInputModalConfig} basInputModalConfig
 * @param {string} basInputModalConfig.title
 * @param {TBasModalInput[]} basInputModalConfig.inputs
 * @param {string} basInputModalConfig.errorTextId
 * @param {BasUtilities} BasUtilities
 */
function numberInputSecurityModalCtrl (
  $rootScope,
  $scope,
  SECURITY,
  BasSecurity,
  close,
  basInputModalConfig,
  BasUtilities
) {
  const modal = this
  const CSS_SHOW_CIRCLE_PROGRESS = 'im-show-circle-progress'
  const CSS_SHOW_CANCEL_BUTTON = 'im-show-cancel-button'

  let _listeners = []
  let updateErrorHaloTimeoutId = null

  modal.closeModal = closeModal
  modal.submit = submit
  modal.enterCharacter = enterCharacter
  modal.backSpace = backSpace
  modal.clickHandler = clickHandler

  modal.basInputModalConfig = basInputModalConfig
  modal.title = basInputModalConfig.title
  modal.subTitle = basInputModalConfig.subTitle
  modal.inputValue = ''
  modal.secretInputValue = ''
  modal.min = basInputModalConfig.min
  modal.max = basInputModalConfig.max
  modal.progressText = basInputModalConfig.max
  modal.value = basInputModalConfig.max
  modal.subtitle = BasUtilities.translate('arming_the_alarm')
  modal.submitText = 'Submit'
  // Step 1 = keycode, Step 2 = countdown
  modal.step = 1
  modal.showCircleProgress = modal.basInputModalConfig.showCircleProgress
  modal.showErrorHalo = false
  modal.css = {}

  init()

  function init () {

    $scope.$on('$destroy', _onDestroy)
    _listeners.push($rootScope.$on(
      SECURITY.EVENT_COUNTDOWN_UPDATED,
      _onCountdownUpdate
    ))
    _resetCss()
  }

  function closeModal (reason) {

    if (
      !modal.basInputModalConfig ||
      modal.basInputModalConfig.allowDismiss
    ) {

      close(reason)
    }
  }

  function submit () {
    if (modal.inputValue.length === 0) {
      _updateErrorHalo()
      return
    }

    if (modal.showCircleProgress) {

      modal.css[CSS_SHOW_CIRCLE_PROGRESS] = true
      modal.css[CSS_SHOW_CANCEL_BUTTON] = true
      modal.step = 2
      modal.submitText = BasUtilities.translate('cancel')
      startCountdown()

    } else {

      BasSecurity.changeAlarmType()
      closeModal()
    }
  }

  function enterCharacter (character) {

    const newInputValue = modal.inputValue + character
    const newInputValueLength = newInputValue.length

    // After a character was entered, we should have a valid number with a max
    //  length of 7, excluding minus sign or comma/point.
    // If not: ignore
    if (!isNaN(newInputValue) && newInputValueLength < 8) {

      modal.inputValue = newInputValue
      _updateErrorHalo()
      modal.secretInputValue = newInputValue.replaceAll(/\d/g, '*')
    }
  }

  function backSpace () {

    modal.inputValue = modal.inputValue.slice(0, modal.inputValue.length - 1)
    modal.secretInputValue = modal.inputValue.replaceAll(/\d/g, '*')
  }

  function startCountdown () {
    BasSecurity.startCountdown()
  }

  function cancelCountdown () {
    BasSecurity.cancelCountdown()
    closeModal()
  }

  function clickHandler () {
    modal.step === 1 ? submit() : cancelCountdown()
  }

  function _onCountdownUpdate (_, countdown) {
    if (countdown < modal.min) {

      closeModal()

    } else {

      // +1 => Make text synced with transition duration: 1s of progress bar
      modal.progressText = countdown + 1
      modal.value = countdown
    }

    $scope.$applyAsync()
  }

  /**
   * Update showErrorHalo variable instantly
   *
   * @private
   */
  function _updateErrorHalo () {

    _clearErrorHaloTimeout()

    modal.showErrorHalo = modal.inputValue.length === 0
    _syncErrorText()

    $scope.$applyAsync()
  }

  function _clearErrorHaloTimeout () {

    clearTimeout(updateErrorHaloTimeoutId)
    updateErrorHaloTimeoutId = null
  }

  function _syncErrorText () {

    if (modal.inputValue.length === 0) {

      modal.uiError = BasUtilities.translate('please_fill_in_keycode')
    }
  }

  function _resetCss () {

    modal.css[CSS_SHOW_CIRCLE_PROGRESS] = false
    modal.css[CSS_SHOW_CANCEL_BUTTON] = false
  }

  function _onDestroy () {

    BasUtil.executeArray(_listeners)
    _listeners = []
  }
}
